import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {useMutation, useQuery} from 'react-query';
import * as api from './Utils/apiCall';
import {sleep} from './Utils/Common';
import "./Login.css";
import { useNavigate, useSearchParams } from "react-router-dom";

const ResetPassword = () => {
  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [frmstate, setFrmstate] = useState(
    {
        id:"",
        token:"",
        password: "",
        password_confirmation:""
    }
  );

  const [errstate, setErrstate] = useState({
    errpassword: "",
    errpassword_confirmation: "",
  });

  const [errorlist, setErrorlist] = useState([]);

  const [checkToken, setCheckToken] = useState("");

  const [passwordShown, setPasswordShown] = useState(false);

  const [cpasswordShown, setCpasswordShown] = useState(false);

  useEffect(() => {

    const currentParams = Object.fromEntries([...searchParams]);
    console.log("currentParams :::: ",currentParams); // get new values onchange

    if(currentParams){

       //mutate({"email_token":currentParams.verifycode, "id": currentParams.id});
       let resetpayload = {...frmstate};
       resetpayload.id = currentParams.id;
       resetpayload.token = currentParams.email_token;
       setFrmstate(resetpayload);
       setFrmstate(frmstate => {
           console.log("fmstate:::",frmstate);
           return frmstate;
       })
        
    }
    else{
        return navigate("/", { replace: true });
    }

}, [searchParams]);

  const {isError, isSuccess, error, isLoading, mutate, data} = useMutation(api.resetpassword, {
    onSuccess: (data) => {
      console.log("onSuccess data",data);

      sleep(4000).then(() => {
        navigate("/", {replace: true});
        });
    },
    onError: (error) => {
      console.log("errors",error.response.data); 
      const errlist = []
      const errObject = error.response.data?.errors
      if(error.response?.data?.errors !== undefined && typeof error.response?.data?.errors === 'object')
       {
            for(var propName in errObject) {
              if(errObject.hasOwnProperty(propName)) {
                  errlist.push(errObject[propName]);
              }
            }
            setErrorlist(errlist);
       }
       else if(error.response?.data?.message) {
        errlist.push(error.response?.data?.message);
        setErrorlist(errlist);
       }
       else
       {
         errlist.push(error.message)
         setErrorlist(errlist);
       }

       console.log("errlist",errlist); 
      
    }
  });



  ////////Company Information :////////////
  const [companyDetails, setCompanyDetails] = useState({});

  const getCompanyInfoNav = useQuery("get_companyinfo", api.companyInfo, {
    enabled: true,
    refetchOnMount:true,
    refetchOnWindowFocus:true,
    onSuccess: (data) => {
      console.log("users data:::", data);

      const apd = {...data?.Info[0]};
      apd.URL = data?.URL;
      console.log("@@@@@@@@@@@@@@@@@@", apd)
      setCompanyDetails(apd);
      setCompanyDetails(companyDetails => {
        console.log("companyDetails ===>>>",companyDetails);
        return companyDetails;
      })
    },
    onError: (error) => {
        console.log("Error Message ::: ",error); 
    }
  });

  //////////////////////////////////////////

  const handleChange = async (e) => {
    e.persist();
    const { name, value } = e.target;
    const formstate = { ...frmstate };
    formstate[name] = value;
    setFrmstate(formstate);

    setFrmstate((frmstate) => {
      console.log("updated frmstate", frmstate);
      return frmstate;
    });
  };

  const handleforgottenpassword = async () => {
    console.log(frmstate);

    const errors = [];
    const errfield = { ...errstate };

    if (frmstate.password.length < 8) {
        errfield.errpassword = "Password should be at least 8 characters long";
        setErrstate(errfield);
        setErrstate((errstate) => {
          console.log("updated errstate", errstate);
          return errstate;
        });
        errors.push("Password should be at least 8 characters long");
      }
  
      if (frmstate.password !== frmstate.password_confirmation) {
        errfield.errpassword_confirmation = "Passwords do not match";
        setErrstate(errfield);
        setErrstate((errstate) => {
          console.log("updated errstate", errstate);
          return errstate;
        });
        errors.push("Passwords do not match");
      }
  
      if (errors.length === 0) {
  
        mutate(frmstate);
        
      }

  };

   // Password toggle handler
   const togglePassword = (e) => {
    e.preventDefault();
    setPasswordShown(!passwordShown);
  };

  // Password toggle handler
  const toggleCPassword = (e) => {
    e.preventDefault();
    setCpasswordShown(!cpasswordShown);
  };

  const handleOnBlur = (e) => {
    e.persist();
    const errlist = { ...errstate };

     //////password start
     if (e.target.name === "password") {
        if (!frmstate.password) {
          errlist.errpassword = "Password is required";
          setErrstate(errlist);
          setErrstate((errstate) => {
            console.log("updated errstate", errstate);
            return errstate;
          });
        } else if (frmstate.password.length < 8) {
          errlist.errpassword = "Password must be 8 or more  Characters long";
          setErrstate(errlist);
          setErrstate((errstate) => {
            console.log("updated errstate", errstate);
            return errstate;
          });
        } else if (frmstate.password !== frmstate.password_confirmation) {
          errlist.errpassword_confirmation = "Passwords do not match";
          setErrstate(errlist);
          setErrstate((errstate) => {
            console.log("updated errstate", errstate);
            return errstate;
          });
        } else {
          errlist.errpassword = "";
          setErrstate(errlist);
          setErrstate((errstate) => {
            console.log("updated errstate", errstate);
            return errstate;
          });
        }
      }
      //////password end
  
      //////password_confirmation start
      if (e.target.name === "password_confirmation") {
        if (!frmstate.password_confirmation) {
          errlist.errpassword = "confirm password is required";
          setErrstate(errlist);
          setErrstate((errstate) => {
            console.log("updated errstate", errstate);
            return errstate;
          });
        } else if (frmstate.password !== frmstate.password_confirmation) {
          errlist.errpassword_confirmation = "Passwords do not match";
          setErrstate(errlist);
          setErrstate((errstate) => {
            console.log("updated errstate", errstate);
            return errstate;
          });
        } else {
          errlist.errpassword_confirmation = "";
          setErrstate(errlist);
          setErrstate((errstate) => {
            console.log("updated errstate", errstate);
            return errstate;
          });
        }
      }
      //////password_confirmation end
  }


  const handleOnKeyPress = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault()
      handleforgottenpassword();
      console.log('submit')
    }
  }

  return (
    <div className="auth-page">
      <div className="container-fluid p-0">
        <div className="rowL g-0">
          <div className="col-xxl-9 col-lg-8 col-md-6 fmpane">
            <div className="auth-full-page-content d-flexL p-sm-5L">
              <div className="w-100">
                <div className="d-flexL flex-columnL h-100">
                  <div className="mb-4 mb-md-5 text-center">
                    <a href="index.html" className="d-block auth-logo">
                      {/* <img src="assets/images/logo.png" alt="" height="20%" />{" "} */}
                      <img src={companyDetails?.URL || "assets/images/logo.png"} alt="logo" width="217px" height="92px" /* height="20%" */ />
                      <span className="logo-txt"></span>
                    </a>
                  </div>
                  <div className="auth-content my-autoL">
                    <div className="text-center">
                      <h5 className="text-muted mt-2">Paasword Reset</h5>
                    </div>

                    {isSuccess ? 
                      (
                        <div className="p-2 mt-4">
                          <p className="text-muted presend emailnotify">{data.message}</p>
                        </div>
                      ):(
                        <div className="alert alert-success text-center my-4" role="alert">
                            Please enter new password!
                        </div>
                      )}

                    <form
                      className="mt-4 pt-2"
                      onSubmit={handleSubmit(handleforgottenpassword)}>
                      <div className="mb-3L">
                        
                      <label htmlFor="toglepassword" className="form-label">
                            Password
                          </label>
                          <div className="input-groupR">
                            <input
                              type={passwordShown ? "text" : "password"}
                              minLength="8"
                              className={
                                errstate.errpassword
                                  ? "is-invalid"
                                  : "form-control"
                              }
                              id="password"
                              name="password"
                              placeholder="Enter password"
                              required
                              value={frmstate.password || ""}
                              onBlur={handleOnBlur}
                              onKeyDown={handleOnKeyPress}
                              onChange={handleChange}
                            />
                            <button
                              className="btn btn-light shadow-none ms-0"
                              onClick={togglePassword}>
                              <i
                                className={
                                  !passwordShown
                                    ? "mdi mdi-eye-outline"
                                    : "mdi mdi-eye-off-outline"
                                }></i>
                            </button>
                          </div>
                          <div
                            className={
                              errstate.errpassword
                                ? "invalid-feedback"
                                : "hide-error"
                            }>
                            {errstate.errpassword}
                          </div>

                      </div>

                      <div className="mb-3L">
                      <label htmlFor="toglepassword" className="form-label">
                            Confirm Password
                          </label>
                          <div className="input-groupR">
                            <input
                              type={cpasswordShown ? "text" : "password"}
                              minLength="8"
                              className={
                                errstate.errpassword_confirmation
                                  ? "is-invalid"
                                  : "form-control"
                              }
                              id="password"
                              name="password_confirmation"
                              placeholder="Confirm password"
                              required
                              value={frmstate.password_confirmation || ""}
                              onBlur={handleOnBlur}
                              onKeyDown={handleOnKeyPress}
                              onChange={handleChange}
                            />
                            <button
                              className="btn btn-light shadow-none ms-0"
                              onClick={toggleCPassword}>
                              <i
                                className={
                                  !cpasswordShown
                                    ? "mdi mdi-eye-outline"
                                    : "mdi mdi-eye-off-outline"
                                }></i>
                            </button>
                          </div>
                          <div
                            className={
                              errstate.errpassword_confirmation
                                ? "invalid-feedback"
                                : "hide-error"
                            }>
                            {errstate.errpassword_confirmation}
                          </div>
                      </div>

                      <div className="mb-3L text-center">
                      {!isLoading ? (
                        <input
                          className="btn btn-primary w-100 waves-effect waves-light"
                          type="submit"
                          value="Reset"/>
                          ):( 
                          <button
                          type="button"
                          className="btn waves-effect waves-light">
                          <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                          Sending Request...
                        </button>
                        )}
                      </div>
                    </form>

                    {isError && (
                      <div className="mt-5 text-center">
                        <ul className="errorlist">
                      {/* <p className="text-muted-error mb-0"></p> */}
                        
                        {
                          errorlist.map((errorItem, i) =>{
                              return(
                                  <li key={i} className="text-muted-error">
                                      {errorItem}
                                  </li>
                              )
                          })
                        }
                      
                      </ul>
                    </div>
                    )
                    
                    }

                   {checkToken && (
                      <div className="mt-5 text-center">
                        <ul className="errorlist">
                              <li className="text-muted-error">
                                  {checkToken}
                              </li>
                      </ul>
                    </div>
                    )}

                    <div className="mt-5 text-center">
                      <p className="text-muted mb-0 presendl">
                        Don't have an account ?{" "}
                        <a
                          href="/register"
                          className="text-primary fw-semibold">
                          {" "}
                          Signup now{" "}
                        </a>{" "}
                      </p>
                    </div>

                    <div className="mt-5 text-center">
                      <p className="text-muted mb-0 presendl">
                       
                        <a
                          href="/admin_login"
                          className="text-primary fw-semibold">
                           <i className="bx bx bx-dialpad-alt adminLogin" style={{display:"inline-flex", border:"solid 1px #e8f2e0"}}></i>  Admin Login
                        </a>
                      </p>
                    </div>
                  </div>
                  <div className="mt-4 mt-md-5 text-center">
                    <p className="mb-0 presend">
                      {/* © {new Date().getFullYear()} i-visas.com */}
                      © {new Date().getFullYear()} {companyDetails?.COMPANY_NAME ?? "i-visas.com"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/*  end auth full page content */}
          </div>
          {/* end col */}
          <div className="col-xxl-3 col-lg-4 col-md-6">
            <div className="auth-bg pt-md-5 p-4 d-flexL">
              <div className="bg-overlay bg-primary"></div>
              <ul className="bg-bubbles">
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
              </ul>
              {/* end bubble effect */}
              <div className="rowL justify-content-center align-items-center">
                <div className="col-xl-7">
                  <div className="p-0 p-sm-4 px-xl-0">
                    <div
                      id="reviewcarouselIndicators"
                      className="carousel slide"
                      data-bs-ride="carousel">
                      <div className="carousel-indicators carousel-indicators-rounded justify-content-start ms-0 mb-0">
                        <button
                          type="button"
                          data-bs-target="#reviewcarouselIndicators"
                          data-bs-slide-to="0"
                          className="active"
                          aria-current="true"
                          aria-label="Slide 1"></button>
                        <button
                          type="button"
                          data-bs-target="#reviewcarouselIndicators"
                          data-bs-slide-to="1"
                          aria-label="Slide 2"></button>
                        <button
                          type="button"
                          data-bs-target="#reviewcarouselIndicators"
                          data-bs-slide-to="2"
                          aria-label="Slide 3"></button>
                      </div>
                      {/*  end carouselIndicators  */}
                      <div className="carousel-inner">
                        <div className="carousel-item active">
                          <div className="testi-contain text-white">
                            <i className="bx bxs-quote-alt-left text-success display-6"></i>

                            <h4 className="mt-4 fw-medium lh-base text-white">
                              “Expert Business Consultancy Assisting With
                              Tailored Personalised Applications And Continuous
                              Support Until Indefinite Leave To Remain.”
                            </h4>
                            <div className="mt-4 pt-3 pb-5">
                              <div className="d-flexL align-items-start"></div>
                            </div>
                          </div>
                        </div>

                        <div className="carousel-item">
                          <div className="testi-contain text-white">
                            <i className="bx bxs-quote-alt-left text-success display-6"></i>

                            <h4 className="mt-4 fw-medium lh-base text-white">
                              “Issuing Of CoS - Advising On All Aspects Of CoS
                              Stages For ICT/New Hires Including Advertising
                              Criteria, SOC Codes, Proof Reading.”
                            </h4>
                            <div className="mt-4 pt-3 pb-5">
                              <div className="d-flexL align-items-start"></div>
                            </div>
                          </div>
                        </div>

                        <div className="carousel-item">
                          <div className="testi-contain text-white">
                            <i className="bx bxs-quote-alt-left text-success display-6"></i>

                            <h4 className="mt-4 fw-medium lh-base text-white">
                              “Advice And Training To Clients HR Team To Ensure
                              The Prevention Of Illegal Workers And Provide A
                              Full Service For Auditing HR Records.”
                            </h4>
                            <div className="mt-4 pt-3 pb-5">
                              <div className="d-flexL align-items-start"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* end carousel-inner  */}
                    </div>
                    {/*   end review carousel  */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* end col  */}
        </div>
        {/* end row */}
      </div>
      {/* end container fluid */}
    </div>
  );
  //     }
};

export default ResetPassword;
