import React, { useState, useEffect, Fragment } from "react";
import { getUser, removeUserSession } from "./Utils/Common";
import "./Invoices.css";
import { Modal } from 'react-bootstrap';

const Invoices = () => {
  const [invoiceview, setInvoiceview] = useState(0);

  const [userData, setUserData] = useState({});

  useEffect(() => {
    document
      .getElementById("toglepanel")
      .addEventListener("click", togglePanel);

      function checkUserData() {
        const item = getUser();
    
        if (item) {
          console.log("user item",item);
          setUserData(item);
  
          setUserData((userData) => {
            console.log("updated details", userData);
            return userData;
          });
  
        }
      }
      checkUserData();
  
      window.addEventListener('storage', checkUserData)
    
      return () => {
        window.removeEventListener('storage', checkUserData)
      }

  }, []);

  const togglePanel = () => {
    const hpanel = document.getElementById("tpanel");

    if (hpanel.classList.contains("small-header")) {
      hpanel.classList.remove("small-header");
    } else {
      hpanel.classList.add("small-header");
    }
  };

  ///////////Modal view tracker //////
  const [invoiceid, setInvoiceid] = useState("");
  const [viewinvoiceopen, setViewInvoiceOpen] = useState(false);
  const [invoiceLoading, setInvoiceLoading] = useState(false);

  const handleViewInvoiceShow = (e) => {
    setInvoiceid(e.target.getAttribute("data-invoiceid"));
    setViewInvoiceOpen(true);
  };
  
  const handleViewInvoiceClose= (e) => {
    setViewInvoiceOpen(false);
  };

  const handleInvoice= (e) => {
    console.log(e.target.getAttribute("data-invoiceid"));
  };
  
  ////////////////

  const viewInvoice = (invoiceid) => {
    console.log("invoice ID", invoiceid);
    setInvoiceview(1);
  };

  return (
    <Fragment>
      <div className="normalheader" id="tpanel">
        <div className="hpanel">
          <div className="panel-body">
            <a className="small-header-action" id="toglepanel" href={void(0)}>
              <div className="clip-header">
                <i className="fa fa-arrow-up"></i>
              </div>
            </a>

            <div id="hbreadcrumb" className="pull-right m-t-lg">
              <ol className="hbreadcrumb breadcrumb">
                <li>
                  <a href={void(0)}>i-visa</a>
                </li>
                <li>
                  <span>Client</span>
                </li>
                <li className="active">
                  <span>Invoices </span>
                </li>
              </ol>
            </div>
            <h2 className="font-light m-b-xs">Invoices</h2>
            <small>
              View paid and pending <strong>Invoices</strong> & make{" "}
              <strong>Payments</strong>
            </small>
          </div>
        </div>
      </div>

      <div className="content">
        <div className="row">
          <div className="col-lg-12">
            <div
              className={invoiceview !== 1 ? "hpanel email-compose" : "hpanel"}>
              {invoiceview === 0 ? (
                <>
                  <div className="panel-heading hbuilt">
                    <div className="text-center p-xs font-normal">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control input-sm"
                          placeholder="Search for Invoice..."
                        />{" "}
                        <span className="input-group-btn">
                          {" "}
                          <button
                            type="button"
                            className="btn btn-sm btn-default">
                            Search
                          </button>{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="panel-body">
                    <div className="row">
                      <div className="col-md-6 m-b-md">
                        <div className="btn-group">
                          <button className="btn btn-default btn-sm">
                            <i className="fa fa-refresh"></i> Refresh
                          </button>
                        </div>
                      </div>
                      <div className="col-md-6 mailbox-pagination m-b-md">
                        <span style={{ fontSize: "1.1rem" }}>
                          Showing 1 to 3 of 3 entries &nbsp; &nbsp;
                        </span>
                        <div className="btn-group">
                          <button className="btn btn-default btn-sm previous disabled">
                            <i className="fa fa-arrow-left"></i>
                          </button>
                          <button className="btn btn-default btn-sm active">
                            1
                          </button>
                          <button className="btn btn-default btn-sm">2</button>
                          <button className="btn btn-default btn-sm next">
                            <i className="fa fa-arrow-right"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table className="table table-hover table-mailbox">
                        <thead>
                          <tr>
                            <th style={{ width: "120px" }}>Invoice Number</th>
                            <th>Service Type</th>
                            <th>Amount</th>
                            <th>Created on</th>
                            <th>Due by</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="unread">
                            <td>IN-6119247</td>
                            <td>UK Visa for Student.</td>
                            <td> $ 4,000.00</td>
                            <td>04/01/2022</td>
                            <td>04/02/2022</td>
                            <td>
                              {" "}
                              <span className="label label-success">Paid</span>
                            </td>
                            <td>
                              <div className="btn-group">
                                <button
                                  className="btn btn-default btn-xs"
                                  onClick={() => viewInvoice(Math.random(3))}>
                                  View Invoice
                                </button>
                              </div>
                            </td>
                          </tr>

                          <tr className="unread">
                            <td>IN-5123283</td>
                            <td>Immigration Matter.</td>
                            <td> $ 3,000.00</td>
                            <td>04/01/2022</td>
                            <td>04/02/2022</td>
                            <td>
                              {" "}
                              <span className="label label-danger">
                                Cancelled
                              </span>
                            </td>
                            <td>
                              <div className="btn-group">
                                <button
                                  className="btn btn-default btn-xs"
                                  onClick={() => viewInvoice(Math.random(3))}>
                                  View Invoice
                                </button>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>IN-9177283</td>
                            <td>University Placement in the UK.</td>
                            <td> $ 6,000.00</td>
                            <td className="mail-date">04/01/2022</td>
                            <td className="mail-date">04/02/2022</td>
                            <td>
                              {" "}
                              <span className="label label-info">Pending</span>
                            </td>
                            <td>
                              <div className="btn-group">
                                <button
                                  className="btn btn-default btn-xs"
                                  onClick={() => viewInvoice(Math.random(3))}>
                                  View Invoice
                                </button>

                                <button
                                  className="btn btn-default btn-xs"
                                  onClick={handleViewInvoiceShow}>
                                  Cancel
                                </button>

                                <button
                                  className="btn btn-default btn-xs"
                                  onClick={handleViewInvoiceShow}>
                                  Make Payment
                                </button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="panel-footer">
                    <i className="fa fa-eye"> </i> 3 unread
                  </div>
                </>
              ) : invoiceview === 1 ? (
                <>
                  <div className="panel-body">
                    <div className="row">
                      <div className="col-md-6">
                        <h4>
                          Invoice <small>IN-9177283-2016</small> | Status
                          <small>&nbsp;Pending</small>
                        </h4>
                      </div>
                      <div className="col-md-6">
                        <div className="text-right">
                          <a className="btn btn-default btn-sm" href="invoice">
                            <i className="fa fa-check "></i> Back to List
                          </a>
                          &nbsp;
                          <button
                            className="btn btn-warning btn-sm"
                            data-invoiceid={1}
                            onClick={handleViewInvoiceShow}>
                            <i className="fa fa-dollar"></i> Make Payment
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="panel-body p-xl"
                    style={{ borderTop: "solid 1px #d6e3d5" }}>
                    <div className="row m-b-xl">
                      <div className="col-sm-6">
                        <h4>IN-9177283-2016</h4>

                        <address>
                          <strong>I-Visas, Ltd.</strong>
                          <br />
                          192A Camden Hill Road
                          <br />
                          London, W8 7TH
                          <br />
                          <abbr title="Phone">P:</abbr> (44) 207 101 4929
                        </address>
                      </div>
                      <div className="col-sm-6 text-right">
                        <span>To:</span>
                        <address>
                          <strong>Sulaman, Inc.</strong>
                          <br />
                          Sorby House, Spital Hill
                          <br />
                          Sheffield, S4 7LG
                          <br />
                          <abbr title="Phone">P:</abbr> (44) 114 213 2550
                        </address>
                        <p>
                          <span>
                            <strong>Invoice Date:</strong> Feb 06, 2022
                          </span>
                          <br />
                          <span>
                            <strong>Due Date:</strong> March 05, 2022
                          </span>
                        </p>
                      </div>
                    </div>

                    <div className="table-responsive m-t">
                      <table className="table table-striped">
                        <thead>
                          <tr
                            style={{
                              backgroundColor: "#d2ead2",
                              borderBottom: "solid 2px #808084",
                            }}>
                            <th>Service Name</th>
                            <th>Quantity</th>
                            <th>Unit Price</th>
                            <th>Tax</th>
                            <th>Total Price</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <div>
                                <strong>
                                  Lorem Ipsum is that it has a more-or-less
                                  normal
                                </strong>
                              </div>
                              <small>
                                Sed do eiusmod tempor incididunt ut labore.
                              </small>
                            </td>
                            <td>1</td>
                            <td>$26.00</td>
                            <td>$5.98</td>
                            <td>$31,98</td>
                          </tr>

                          <tr>
                            <td>
                              <div>
                                <strong>That it has a more-or-less</strong>
                              </div>
                              <small>
                                Lorem ipsum dolor sit amet, consectetur magna
                                aliqua.
                              </small>
                            </td>
                            <td>4</td>
                            <td>$320.00</td>
                            <td>$233.10</td>
                            <td>$193.10</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="row m-t">
                      <div className="col-md-4 col-md-offset-8">
                        <table className="table table-striped text-right">
                          <tbody style={{ border: "solid 1px #dddddd" }}>
                            <tr>
                              <td style={{ color: "#5e911b" }}>
                                <strong>Sub Total :</strong>
                              </td>
                              <td style={{ color: "#5e911b" }}>$1026.00</td>
                            </tr>
                            <tr>
                              <td style={{ color: "#5e911b" }}>
                                <strong>TAX :</strong>
                              </td>
                              <td style={{ color: "#5e911b" }}>$235.98</td>
                            </tr>
                            <tr>
                              <td style={{ color: "#5e911b" }}>
                                <strong>TOTAL :</strong>
                              </td>
                              <td style={{ color: "#5e911b" }}>$1261.98</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="m-t">
                          <strong>Terms & conditions</strong>
                          <br />
                          <span style={{ color: "#5e911b" }}>
                            Lorem ipsum dolor sit amet, consectetur adipisicing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      
      
        <Modal show={viewinvoiceopen} onHide={handleViewInvoiceClose} animation={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        >
           <div className="color-line"></div>
        <Modal.Header className="modal-header text-center" style={{padding: "10px 10px"}}>
          <Modal.Title>Make Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{padding: "5px"}}>
         
        
        <div className="panel-body">
      <p>
      Select Mode of Payment. And Effect Payment
      </p>
                    </div>
        </Modal.Body>
        <Modal.Footer>
           <div className="text-right">
           {invoiceLoading ? (
             <>
                <span className="btn">
                  <i className="fa fa-paper-plane" style={{color: "#5ac557"}}></i>
                  &nbsp; Saving...
                  <span className="lds-facebook"><span></span><span></span><span></span></span>
                </span>
                &nbsp; &nbsp; &nbsp; &nbsp;
                </>
                    ) : (
                <button type="button"
                  className="btn btn-success btn-sm"
                  
                  data-invoiceid={invoiceid}
                  onClick={handleInvoice}
                  >
                  <i className="fa fa-credit-card-alt"></i> Apply
                </button>
            )}
              <button
                type="button"
                className="btn btn-default"
                onClick={handleViewInvoiceClose}>
                <i className="fa fa-trash"></i>  Close
              </button>
          </div>
        </Modal.Footer>
      </Modal>

        </div>
    </Fragment>
  );
};

export default Invoices;
