import React, { useState, useEffect, Fragment } from "react";
import { useQuery, useMutation } from "react-query";
import { useForm } from "react-hook-form";
import { setAdminUserSession, setWithExpiry } from "../Utils/Common";
import * as api from '../Utils/adminApiCalls';
import { useNavigate, useLocation, Link } from "react-router-dom";
import { formatPhoneNumberIntl } from "react-phone-number-input";

const AdminLogin = () => {

    const {
        register,
        handleSubmit,
        trigger,
        formState: { errors },
      } = useForm();
    
      const navigate = useNavigate();
      let location = useLocation();
    
      const [errorlist, setErrorlist] = useState([]);
    
      //let from = location.state?.from?.pathname || "/admin_dashboard";

      let from = (window.history.state && window.history.state.idx > 0) ? -1 : "/admin_nav/admin_dashboard";

      console.log("window loc href:", window.location.href, "doc referrer:", document.referrer);
    
      const {isError, isSuccess, error, isLoading, mutate} = useMutation(api.adminlogin, {
        onSuccess: (data) => {
          console.log("onSuccess data",data, "id: ", data.id);
    
          const ttl = 1000 * 60 * 60 * 24;
          
          setAdminUserSession(data?.token, data, ttl);
    
         /*  if(remember === "remember")
     */   //   {
          //setWithExpiry("token", data, 36000);
            //localStorage.setItem("name", JSON.stringify(name));
          //}  
    
          navigate(from, { replace: true });
    
        },
        onError: (error) => {
          console.log("errors",error.response.data); 
          //console.log("error response data errors: ",error.response.data.errors?.phone[0]);
          const errlist = []
          const errObject = error.response.data?.errors
          //console.log("typeof: ",typeof error.response.data.errors);
          if(error.response?.data?.errors !== undefined && typeof error.response?.data?.errors === 'object')
           {
                for(var propName in errObject) {
                  if(errObject.hasOwnProperty(propName)) {
                      //console.log("within iteration",errObject[propName]); 
                      errlist.push(errObject[propName]);
                  }
                }
                setErrorlist(errlist);
           }
           else if(error.response?.data?.message) {
            errlist.push(error.response?.data?.message);
            setErrorlist(errlist);
           }
           else
           {
             errlist.push(error.message)
             setErrorlist(errlist);
           }
    
           console.log("errlist",errlist); 
    
          //console.log("error response status: ", error.response.status);
          
        }
      });
    
      /* const { login, authed } = useAuth(); */
    
      const [fmstate, setFmtate] = useState([
        {
          islogged: false,
          loginParams: {
            user_email: "",
            user_password: "",
            auth_error: "",
          },
        },
      ]);
    
      const [showPassword, setShowPassword] = useState(false);
    
      const [remember, setRemember] = useState("");
    
      const toggleShowpassword = () => {
        setShowPassword(!showPassword);
      };
    
      const handleFormChange = async (e) => {
        let loginParamsNew = { ...fmstate };
        let val = e.target.value;
        loginParamsNew[0].loginParams[e.target.name] = val;
        setFmtate(loginParamsNew);
        ///await trigger([e.target.name]);
        console.log(fmstate);
      };
    
      const handlelogin = async () => {
        console.log(fmstate);
    
        //try {
          let email = fmstate[0].loginParams.user_email;
          let password = fmstate[0].loginParams.user_password;
    
          mutate({"email":email, "password":password});
    
      };

      const handleOnKeyPress = (e) => {
        if (e.keyCode === 13) {
          e.preventDefault()
          handlelogin();
          console.log('submit')
        }
      }
    
      const handleRemember = (e) => {
        console.log("checked: ", e.target.checked);
        let rm = e.target.checked;
        if (rm === true) {
          setRemember("remember");
          console.log("state where true: ", remember);
        } else {
          setRemember("");
          console.log("state not true: ", remember);
        }
      };

       ////////Company Information :////////////
  const [companyDetails, setCompanyDetails] = useState({});

  const getCompanyInfoNav = useQuery("get_companyinfo", api.companyInfo, {
    enabled: true,
    refetchOnMount:true,
    refetchOnWindowFocus:true,
    onSuccess: (data) => {
      console.log("users data:::", data);

      const apd = {...data?.Info[0]};
      apd.URL = data?.URL;
      console.log("@@@@@@@@@@@@@@@@@@", apd)
      setCompanyDetails(apd);
      setCompanyDetails(companyDetails => {
        console.log("companyDetails ===>>>",companyDetails);
        return companyDetails;
      })
    },
    onError: (error) => {
        console.log("Error Message ::: ",error); 
    }
  });

    return (
       <>
<div className="color-line"></div>

<div className="login-container">
    <div className="row">
        <div className="col-md-12">
            <div className="text-center m-b-md">


            <div className="mb-4 mb-md-5 text-center">
                    <a href="index.html" className="d-block auth-logo">
                      <img src={companyDetails?.URL || "assets/images/logo.png"} alt="logo" width="217px" height="92px" /* height="20%" */ />
                      <span className="logo-txt"></span>
                    </a>
                  </div>

                <h3>SYSTEM USER LOGIN</h3>
                <small>Kindly login to Administer the System</small>
            </div>
            <div className="hpanel">
                <div className="panel-body" style={{border: "solid 1px #c5c5c973"}}>
                        <form id="loginForm" onSubmit={handleSubmit(handlelogin)}>
                            <div className="form-group">
                                <label className="control-label" htmlFor="user_email">Username</label>
                                <input 
                                
                                type="email"
                                className={`form-controlL ${
                                  errors.user_email ? "redborder" : ""
                                }`}
                                {...register("user_email", {
                                  required: true,
                                  pattern:
                                    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                })}
                                id="user_email"
                                name="user_email"
                                aria-label="email"
                                onChange={handleFormChange}
                                onKeyDown={handleOnKeyPress}
                                placeholder="Please enter your email"
                                title="Please enter you username" 
                                />
                                {errors.user_email && (
                                <span className="errorValidate help-block small">
                                    Please enter a valid e-mail address
                                </span>
                                )}
                            </div>
                            <label className="control-label" htmlFor="user_password">Password</label>
                            <div className="input-group" style={{width: "100%"}}>
                                
                                <input 
                                type={showPassword ? "text" : "password"}
                                className={`form-controlL ${errors.user_password ? "redborder" : ""}`}
                                {...register("user_password", { required: true })}
                                name="user_password"
                                id="user_password"
                                autoComplete="current-password"
                                onChange={handleFormChange}
                                onKeyDown={handleOnKeyPress}
                                placeholder="Please enter your password"
                                aria-label="Password"
                                aria-describedby="password-addon"
                                />
                                <button
                                    className="btn btn-light shadow-none ms-0"
                                    type="button"
                                    id="password-addon"
                                    onClick={toggleShowpassword}>
                                    <i className="mdi mdi-eye-outline"></i>
                                </button>

                            </div>
                            {errors.user_password && (
                                <span className="help-block small errorValidate">
                                    This field is required
                                </span>
                                )}

                            <div className="checkbox">
                                <input 
                                type="checkbox" 
                                className="i-checks" 
                                name="remember"
                                checked={remember === "remember"}
                                onChange={handleRemember}
                                />
                                        &nbsp; &nbsp; Remember login
                               {/*  <p className="help-block small">(if this is a private computer)</p> */}
                            </div>
                            <button className="btn btn-success btn-block">Login</button>
                            <Link className="btn btn-default btn-block" to="/admin_forgotten_password" style={{borderColor: "#61cb31", color: "#757578"}}>Forgotten Password ?</Link> 
                        </form>

                        {isError && (
                            <div className="mt-5 text-center">
                                <ul className="errorlist">
                                {
                                errorlist.map((errorItem, i) =>{
                                    return(
                                        <li key={i} className="text-muted-error">
                                            {errorItem}
                                        </li>
                                    )
                                })
                                }
                            </ul>
                            </div>
                        )}
                </div>
            </div>
        </div>
    </div>
    <div className="row">
        <div className="col-md-12 text-center">
            <strong>{companyDetails?.COMPANY_NAME ?? "I-VISAS"}</strong> - APPLICATION <br/>  © {new Date().getFullYear()} {companyDetails?.COMPANY_NAME ?? "i-visas.com"}
        </div>
    </div>
</div>
</>
    )
}

export default AdminLogin;