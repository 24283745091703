
import React, { useState, useEffect, useRef, Fragment} from "react";
import { useQuery } from "react-query";
import Logo from "./images/print.png";
import { removeUserSession, sleep } from "./Utils/Common";
import { useNavigate, useParams } from "react-router-dom";
import * as api from './Utils/apiCall';
import moment from "moment";

import "./Messages.css";

import {
    Document,
    Page,
    Image,
    Text,
    View,
    StyleSheet,
    PDFViewer,
  } from "@react-pdf/renderer";
  // Create styles
  const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 11,
        paddingTop: 30,
        paddingLeft:35,
        paddingRight:35,
        lineHeight: 1.5,
        flexDirection: 'column',
    }, 
    logo: {
        width: 135,
        height: 57,
        /* marginLeft: '1',
        marginRight: 'auto' */
    },
    titleContainer:{
        flexDirection: 'row',
        marginTop: 24,
    },
    reportTitle:{
        color: 'black',
        letterSpacing: 4,
        fontSize: 16,
       /*  textAlign: 'center', */
        marginLeft: 'auto',
        marginRight: 'auto' ,
        textTransform: 'uppercase',
    },
    containerheader: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderBottomColor: '#acacb5',
        paddingBottom: 15,
        alignItems: 'center',
        justifyContent: 'space-between',
        /* height: 24,  */
        fontStyle: 'bold',
        /* flexGrow: 1, */
    },
    containerappfooter:{
      position:'absolute',
      fontSize:'12',
      bottom:'0',
      left:'0',
      right:'0',
      textAlign:'center',
      color:'grey'
    },
    containerfooterChild: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      width:'auto',
      borderTopWidth: 1,
      borderTopColor: '#e4e6e2',
      /* paddingBottom: 15, */
      alignItems: 'center',
      justifyContent: 'space-around',
      /* height: 24,  */
      fontStyle: 'bold',
      /* flexGrow: 1, */
    },
    headertextcompanyname: {
      width: 'auto',
      fontWeight: 'bold',
      /* textAlign: 'right', */
  },
    footertextdate:{
      width: 'auto',
     /*  textAlign: 'right',
      paddingTop:5, */
      fontWeight: 'bold',
      /* paddingBottom:3 */
  },
    containerfooter: {
        flexDirection: 'row',
        borderTopWidth: 1,
        borderTopColor: '#acacb5',
        marginTop:10,
        alignItems: 'right',
        justifyContent: 'end',
    },
    footertext:{
        width: '100%',
        textAlign: 'right',
        paddingTop:5,
        fontWeight: 'bold',
        paddingBottom:3
    },
    containerapp: {
        flexDirection: 'row',
        borderWidth: 1,
        borderColor: '#acacb5',
        paddingTop: 10, 
        paddingBottom: 5, 
        marginTop:30,
        alignItems: 'center',
        /* alignContent: 'center', */
        justifyContent: 'space-around',
        /* height: 24,  */
        /* fontStyle: 'bold', */
        /* flexGrow: 1, */
    },
    containerappbody:{
        /* flexDirection: 'row', */
        marginTop:30,
       /*  alignItems: 'center', */
       /*  alignContent: 'center', */
       /*  justifyContent: 'space-between', */
    },
    containerappbodypadd:{
      marginTop:15,
    },
    containerapprowbody:{
        flexDirection: 'row',
        marginTop:5,
        alignItems: 'center',
       /*  alignContent: 'center', */
        justifyContent: 'start',
        borderWidth: 1,
        borderRadius:3,
        borderColor:'#e4e6e2'
    },
    containerapprow:{
        flexDirection: 'row',
        marginTop:5,
        alignItems: 'center',
       /*  alignContent: 'center', */
        justifyContent: 'start',
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderRadius:0,
        borderTopColor:'#e4e6e2',
        borderBottomColor:'#e4e6e2'
    },
    headerimg: {
        width: '50%',
    },
    headertext: {
        width: '50%',
        textAlign: 'right',
    },
    headerapptext: {
        width: '50%',
        textAlign: 'center',
        paddingTop:5,
        fontWeight: 'bold',
        paddingBottom:3
    },
    apptextleft: {
        width: 'auto',
        height: 'auto',
        textAlign: 'left',
        color: '#579927',
        paddingTop: '5px',
        paddingLeft:10,
        backgroundColor: '#e5efdf',
        /* marginTop:'12', */
        fontStyle: '900',
        fontFamily: 'Helvetica'
    },
    apptextright: {
        width: 'auto',
        height: 'auto',
        textAlign: 'left',
        paddingTop: '5px',
        paddingLeft:5,
        color: 'black'
    },
    apptextrightl: {
      width: '100%',
      textAlign: 'left',
      paddingTop: '5px',
      paddingLeft:5,
      color: 'black'
  },
    container: {
        flexDirection: 'row',
        borderColor: '#5d9632',
        backgroundColor: '#5c973147',
        borderWidth: 1,
        marginTop:15,
       /*  alignItems: 'center', */
        /* height: 24,  */
        textAlign: 'center',
        paddingTop: 5,
        fontStyle: 'bold',
        /* flexGrow: 1, */
    },
    othernamescontainer: {
        flexDirection: 'column',
        borderColor: '#e4e6e2',
        borderRadius:3,
        borderWidth: 1,
        marginTop:15,
       /*  alignItems: 'center', */
        /* height: 24,  */
        textAlign: 'center',
        /* flexGrow: 1, */
        paddingBottom:5
    },
    description: {
        width: '100%',
        /* marginLeft: 'auto',
        marginRight: 'auto' ,  */
        margin: 'auto',
        color: '#487e21',
        fontSize: 18,
        fontStyle: 'bold',
    },
    section: {
      margin: 10,
      padding: 10,
    },
    viewer: {
      width: window.innerWidth, //the pdf viewer will take up all of the width and height
      height: window.innerHeight,
    },
  });

const GenerateApplicationPDF = () => {

    const navigate = useNavigate();

    const params = useParams();

    const [appid, setAppid] = useState('');

    const [appdata, setAppdata] = useState([]);

    const [permit, setPermit] = useState(false);

    const [details, setDetails] = useState({});

    const appidRef = useRef();


useEffect(() => {
    if (params?.appid === null || params?.appid=== undefined) {
        console.log("inside sideEffect edit 1", params?.appid,);
      return navigate("/dashboard/application_list", { replace: true });
    }
    else {
      appidRef.current = params?.appid;
            setAppid(params?.appid);

            setAppid((appid) => {
                console.log("returning AppID", appid);
                return appid;
            });      
    }
  }, [params?.appid]);


  ////////Company Information :////////////
  const [companyDetails, setCompanyDetails] = useState({});

  const getCompanyInfoNav = useQuery("get_companyinfo", api.showCompany, {
    enabled: true,
    refetchOnMount:true,
    refetchOnWindowFocus:true,
    onSuccess: (data) => {
      console.log("users data:::", data);

      const apd = {...data?.Info[0]};
      apd.URL = data?.URL;
      console.log("@@@@@@@@@@@@@@@@@@", apd)
      setCompanyDetails(apd);
      setCompanyDetails(companyDetails => {
        console.log("companyDetails ===>>>",companyDetails);
        return companyDetails;
      })
    },
    onError: (error) => {
        console.log("Error Message",error); 
        setPermit(false);
    
      if(error?.response?.status === 401){
       console.log("Session Expired : Please Re-Login");
       removeUserSession();
        navigate("/", {replace: false});
      }
      else {
        console.log("Something went wrong");
      }
  
      }
  });
  const {isLoading, data : companyData} = getCompanyInfoNav;


  //////////////////////////////////////////

  
  const editingApp = useQuery(['getApplicationbyID', appidRef.current], () => api.editApp(appidRef.current), {
    refetchInterval: false,
    onSuccess: (data) => {

      console.log("edit app Successfull", data);
      console.log("edit app Successfull http status", data?.response?.status);

      const AppDetails = {...data};

      console.log(" AppDetails:", AppDetails);

      console.log("Other Names:", data?.name_change_details[0]?.ATTRIBUTES);

      //console.log("Other Names length", (JSON.parse(details?.name_change_details[0]?.ATTRIBUTES).length > 0))

      setDetails(AppDetails);

      
      setDetails((details) => {
        console.log(" edit details value:", details);
        return details;
      }); 
        
      setPermit(true);

  },
  onError: (error) => {
      console.log("Error Message",error); 
      setPermit(false);
  
    if(error?.response?.status === 401){
     console.log("Session Expired : Please Re-Login");
     removeUserSession();
      navigate("/", {replace: false});
    }
    else {
      console.log("Something went wrong");
    }

    }
  });

  const { loading: loadingedit } = editingApp;

  const getdatedif = (s) => {

    let d = new Date(s);

    let oldDateMoment, newDateMoment, numYears, numMonths, numDays;

    oldDateMoment = moment(d);
    newDateMoment = moment(new Date());

    numYears = newDateMoment.diff(oldDateMoment, "years");
    oldDateMoment = oldDateMoment.add(numYears, "years");
    numMonths = newDateMoment.diff(oldDateMoment, "months");
    oldDateMoment = oldDateMoment.add(numMonths, "months");
    numDays = newDateMoment.diff(oldDateMoment, "days");
    let difcount = numYears + " years |" + numMonths + " months |" + numDays + " days";
      
    return difcount; 
  };

  const checkAppStatus = (statusval = 0) => {

    let statusText = 'Not Set';

    console.log("statusval:",statusval, "type of statusval", typeof statusval);

    if(statusval !== undefined || statusval !== null){
      
      switch(statusval) {
        case 0:
          statusText = 'Initiated'
          break;
        case 1:
            statusText = 'Draft'
          break;
        case 2:
            statusText = 'Under Review'
          break;
        case 3:
            statusText = 'Approved'
          break;
        default:
            statusText = 'Not set'
      }
      return statusText;
    }
    else {
        statusText = 'Not Set'
    }
}

  if( editingApp.isLoading){
    return (
      <div style={{display:"grid", justifyContent: "center", alignContent: "center"}}>
          
          <div className="mt-5 text-center">
                <h4>Loading ...a</h4>    
            </div>
          
      </div>
    )
  }

  const OtherNames = () => {

    let view = "";   
    if(!!details?.name_change_details[0]?.ATTRIBUTES && details?.application_details[0]?.NAME_CHANGE_QUESTION === 'Yes'){

      const names = JSON.parse(details?.name_change_details[0]?.ATTRIBUTES);
      
      const viewer = names.map((prevNames,i) => (
      
        <Fragment key={i}>
      <View style={styles.containerapprow} wrap={false}>
          <Text style={styles.apptextleft}>Previous Name: : </Text>
          <Text style={styles.apptextright}>{prevNames[`oldname_${i}`] ?? "Not Set"}</Text>
        </View>
       <View style={styles.containerapprow} wrap={false}>
          <Text style={styles.apptextleft}>From: : </Text>
          <Text style={styles.apptextright}>{ prevNames[`startdate_${i}`] ?? "Not Set"}</Text>
        </View>
        <View style={styles.containerapprow} wrap={false}>
          <Text style={styles.apptextleft}>To : </Text>
          <Text style={styles.apptextright}>{ prevNames[`enddate_${i}`] ?? "Not Set"}</Text>
        </View>
        </Fragment>
      ))
      return viewer;
    }
    else{
        return view
    }
  }

  const OtherNationality = () => {
    let view = "";   
    if(!!details?.nationality_details[0]?.ATTRIBUTES && details?.application_details[0]?.OTHER_NATIONALITY_QUESTION === 'Yes'){

      const names = JSON.parse(details?.nationality_details[0]?.ATTRIBUTES);
      
      const viewer = names.map((prevNational,i) => (
      
        <Fragment key={i}>
      <View style={styles.containerapprow} wrap={false}>
          <Text style={styles.apptextleft}>Previous Nationality : </Text>
          <Text style={styles.apptextright}>{prevNational[`othernation_${i}`] ?? "Not Set"}</Text>
        </View>
       <View style={styles.containerapprow} wrap={false}>
          <Text style={styles.apptextleft}>From: : </Text>
          <Text style={styles.apptextright}>{ prevNational[`otherstartdate_${i}`] ?? "Not Set"}</Text>
        </View>
        <View style={styles.containerapprow} wrap={false}>
          <Text style={styles.apptextleft}>To : </Text>
          <Text style={styles.apptextright}>{ prevNational[`otherenddate_${i}`] ?? "Not Set"}</Text>
        </View>
        </Fragment>
      ))
      return viewer;
    }
    else{
        return view
    }
  }


  const children = () => {
    let view = "";   
    if(!!details?.children_details[0]?.ATTRIBUTES && details?.application_details[0]?.DO_YOU_HAVE_CHILDREN === 'Yes'){

      const names = JSON.parse(details?.children_details[0]?.ATTRIBUTES);
      
      const viewer = names.map((children,i) => (
      
        <Fragment key={i}>
      <View style={styles.containerapprow} wrap={false}>
          <Text style={styles.apptextleft}>Child's Name : </Text>
          <Text style={styles.apptextright}>{children[`childname_${i}`] ?? "Not Set"}</Text>
        </View>
       <View style={styles.containerapprow} wrap={false}>
          <Text style={styles.apptextleft}>Gender : </Text>
          <Text style={styles.apptextright}>{ children[`gender_${i}`] ?? "Not Set"}</Text>
        </View>
        <View style={styles.containerapprow} wrap={false}>
          <Text style={styles.apptextleft}>Date of Birth : </Text>
          <Text style={styles.apptextright}>{ children[`dob_${i}`] ?? "Not Set"}</Text>
        </View>
        <View style={styles.containerapprow} wrap={false}>
          <Text style={styles.apptextleft}>Nationality of Child : </Text>
          <Text style={styles.apptextright}>{ children[`childnationality_${i}`] ?? "Not Set"}</Text>
        </View>
        <View style={styles.containerapprow} wrap={false}>
          <Text style={styles.apptextleft}>Passport Number : </Text>
          <Text style={styles.apptextright}>{ children[`passportno_${i}`] ?? "Not Set"}</Text>
        </View>
        <View style={styles.containerapprow} wrap={false}>
          <Text style={styles.apptextleft}>Passport Issued Date : </Text>
          <Text style={styles.apptextright}>{ children[`passportissueddate_${i}`] ?? "Not Set"}</Text>
        </View>
        <View style={styles.containerapprow} wrap={false}>
          <Text style={styles.apptextleft}>Passport Expiry Date : </Text>
          <Text style={styles.apptextright}>{ children[`passportexpiry_${i}`] ?? "Not Set"}</Text>
        </View>
        
        <View style={styles.containerapprow} wrap={false}>
          <Text style={styles.apptextleft}>Visa Type : </Text>
          <Text style={styles.apptextright}>{ children[`visatype_${i}`] ?? "Not Set"}</Text>
        </View>
        <View style={styles.containerapprow} wrap={false}>
          <Text style={styles.apptextleft}>Visa Issued Date : </Text>
          <Text style={styles.apptextright}>{ children[`visaissueddate_${i}`] ?? "Not Set"}</Text>
        </View>
        <View style={styles.containerapprow} wrap={false}>
          <Text style={styles.apptextleft}>Visa Expiry Date: </Text>
          <Text style={styles.apptextright}>{ children[`visaexpiry_${i}`] ?? "Not Set"}</Text>
        </View>
        </Fragment>
      ))
      return viewer;
    }
    else{
        return view
    }
  }

  
  const previousPartner = () => {
    let view = "";   
    if(!!details?.previous_marriage_details[0]?.PREVIOUS_MARRIAGE && details?.application_details[0]?.MARRIED_BEFORE_QUESTION === 'Yes'){

      const names = JSON.parse(details?.previous_marriage_details[0]?.PREVIOUS_MARRIAGE);
      
      const viewer = names.map((previousMarriage,i) => (
      
        <Fragment key={i}>
      <View style={styles.containerapprow} wrap={false}>
          <Text style={styles.apptextleft}>Name of Ex : </Text>
          <Text style={styles.apptextright}>{previousMarriage[`exname_${i}`] ?? "Not Set"}</Text>
        </View>
       <View style={styles.containerapprow} wrap={false}>
          <Text style={styles.apptextleft}>Date of Birth : </Text>
          <Text style={styles.apptextright}>{ previousMarriage[`exdob_${i}`] ?? "Not Set"}</Text>
        </View>
        <View style={styles.containerapprow} wrap={false}>
          <Text style={styles.apptextleft}>Nationality of Ex : </Text>
          <Text style={styles.apptextright}>{ previousMarriage[`exnationality_${i}`] ?? "Not Set"}</Text>
        </View>
        <View style={styles.containerapprow} wrap={false}>
          <Text style={styles.apptextleft}>Date of Marriage : </Text>
          <Text style={styles.apptextright}>{ previousMarriage[`exdateofmarriage_${i}`] ?? "Not Set"}</Text>
        </View>
        <View style={styles.containerapprow} wrap={false}>
          <Text style={styles.apptextleft}>Date of Divorce : </Text>
          <Text style={styles.apptextright}>{ previousMarriage[`exdateofdivorce_${i}`] ?? "Not Set"}</Text>
        </View>
        </Fragment>
      ))
      return viewer;
    }
    else{
        return view
    }
  }


  const PartnerExes = () => {

    let view = "";   
    if(!!details?.partner_married_before_details[0]?.PARTNERS_EX && details?.application_details[0]?.PARTNER_MARRIED_BEFORE === 'Yes'){

      const names = JSON.parse(details?.partner_married_before_details[0]?.PARTNERS_EX);
      
      const viewer = names.map((partnerExes,i) => (
      
        <Fragment key={i}>
      <View style={styles.containerapprow} wrap={false}>
          <Text style={styles.apptextleft}>Name of Ex : </Text>
          <Text style={styles.apptextright}>{partnerExes[`partnersExName_${i}`] ?? "Not Set"}</Text>
        </View>
       <View style={styles.containerapprow} wrap={false}>
          <Text style={styles.apptextleft}>Date of Birth : </Text>
          <Text style={styles.apptextright}>{ partnerExes[`partnersExDob_${i}`] ?? "Not Set"}</Text>
        </View>
        <View style={styles.containerapprow} wrap={false}>
          <Text style={styles.apptextleft}>Nationality of Ex : </Text>
          <Text style={styles.apptextright}>{ partnerExes[`partnersExNationality_${i}`] ?? "Not Set"}</Text>
        </View>
        <View style={styles.containerapprow} wrap={false}>
          <Text style={styles.apptextleft}>Date of Marriage : </Text>
          <Text style={styles.apptextright}>{ partnerExes[`partnersExDateofmarriage_${i}`] ?? "Not Set"}</Text>
        </View>
        <View style={styles.containerapprow} wrap={false}>
          <Text style={styles.apptextleft}>Date of Divorce : </Text>
          <Text style={styles.apptextright}>{ partnerExes[`partnersExDateofdivorce_${i}`] ?? "Not Set"}</Text>
        </View>
        </Fragment>
      ))
      return viewer;
    }
    else{
        return view
    }

  }

  const familyAndfriends = () => {
    let view = "";   
    if(!!details?.names_of_people_at_address_details[0]?.ATTRIBUTES && details?.application_details[0]?.DO_YOU_HAVE_FAMILY_IN_HOME_COUNTRY === 'Yes'){

      const names = JSON.parse(details?.names_of_people_at_address_details[0]?.ATTRIBUTES);
      
      const viewer = names.map((familyFriends,i) => (
      
        <Fragment key={i}>
      <View style={styles.containerapprow} wrap={false}>
          <Text style={styles.apptextleft}>Name of Relative : </Text>
          <Text style={styles.apptextright}>{familyFriends[`relativeName_${i}`] ?? "Not Set"}</Text>
        </View>
       <View style={styles.containerapprow} wrap={false}>
          <Text style={styles.apptextleft}>Relationship : </Text>
          <Text style={styles.apptextright}>{ familyFriends[`relationship_${i}`] ?? "Not Set"}</Text>
        </View>
        </Fragment>
      ))
      return viewer;
    }
    else{
        return view
    }
  }

  const qualification = () => {
    let view = "";   
    if(!!details?.qualification_details[0]?.ATTRIBUTES && details?.application_details[0]?.ANY_OTHER_CERTIFICATE === 'Yes'){

      const names = JSON.parse(details?.qualification_details[0]?.ATTRIBUTES);
      
      const viewer = names.map((qualify,i) => (
      
        <Fragment key={i}>
      <View style={styles.containerapprow} wrap={false}>
          <Text style={styles.apptextleft}>Qualification: </Text>
          <Text style={styles.apptextright}>{qualify[`qualification_${i}`] ?? "Not Set"}</Text>
        </View>
       <View style={styles.containerapprow} wrap={false}>
          <Text style={styles.apptextleft}>Awarding Institute : </Text>
          <Text style={styles.apptextright}>{ qualify[`awardinginstitute_${i}`] ?? "Not Set"}</Text>
        </View>
        <View style={styles.containerapprow} wrap={false}>
          <Text style={styles.apptextleft}>Grade : </Text>
          <Text style={styles.apptextright}>{ qualify[`grade_${i}`] ?? "Not Set"}</Text>
        </View>
        <View style={styles.containerapprow} wrap={false}>
          <Text style={styles.apptextleft}> Course Subject: </Text>
          <Text style={styles.apptextright}>{ qualify[`coursesubj_${i}`] ?? "Not Set"}</Text>
        </View>
        <View style={styles.containerapprow} wrap={false}>
          <Text style={styles.apptextleft}>Course Length : </Text>
          <Text style={styles.apptextright}>{ qualify[`courselen_${i}`] ?? "Not Set"}</Text>
        </View>
        <View style={styles.containerapprow} wrap={false}>
          <Text style={styles.apptextleft}>Year of Award : </Text>
          <Text style={styles.apptextright}>{ qualify[`yearofaward_${i}`] ?? "Not Set"}</Text>
        </View>
        <View style={styles.containerapprow} wrap={false}>
          <Text style={styles.apptextleft}>Country of Award : </Text>
          <Text style={styles.apptextright}>{ qualify[`countryofaward_${i}`] ?? "Not Set"}</Text>
        </View>
        <View style={styles.containerapprow} wrap={false}>
          <Text style={styles.apptextleft}>State : </Text>
          <Text style={styles.apptextright}>{ qualify[`countrystate_${i}`] ?? "Not Set"}</Text>
        </View>
        </Fragment>
      ))
      return viewer;
    }
    else{
        return view
    }

  }


  const visitedCountries = () => {
    let view = "";   
    if(!!details?.location_details[0]?.COUNTRIES_TRAVELLED && details?.application_details[0]?.OUT_OF_THE_UK_BEFORE === 'Yes'){

      const names = JSON.parse(details?.location_details[0]?.COUNTRIES_TRAVELLED);
      
      const viewer = names.map((ctravel,i) => (
      
        <Fragment key={i}>
      <View style={styles.containerapprow} wrap={false}>
          <Text style={styles.apptextleft}>Country Visited : </Text>
          <Text style={styles.apptextright}>{ctravel[`countryname_${i}`] ?? "Not Set"}</Text>
        </View>
       <View style={styles.containerapprow} wrap={false}>
          <Text style={styles.apptextleft}>What date did you leave the UK : </Text>
          <Text style={styles.apptextright}>{ ctravel[`dateoftravel_${i}`] ?? "Not Set"}</Text>
        </View>
        <View style={styles.containerapprow} wrap={false}>
          <Text style={styles.apptextleft}>What date did you return : </Text>
          <Text style={styles.apptextright}>{ ctravel[`dateofreturn_${i}`] ?? "Not Set"}</Text>
        </View>
        <View style={styles.containerapprow} wrap={false}>
          <Text style={styles.apptextleft}>Reason for your visit : </Text>
          <Text style={styles.apptextright}>{ ctravel[`reasonfortravel_${i}`] ?? "Not Set"}</Text>
        </View>
        </Fragment>
      ))
      return viewer;
    }
    else{
        return view
    }
  }


  const lastfiveVisits = () => {
    let view = "";   
    if(!!details?.location_details[0]?.LAST_FIVE_VISITS && JSON.parse(details?.location_details[0]?.LAST_FIVE_VISITS).length > 0){

      const names = JSON.parse(details?.location_details[0]?.LAST_FIVE_VISITS);
      
      const viewer = names.map((lastfivevisits,i) => (
      
        <Fragment key={i}>
      <View style={styles.containerapprow} wrap={false}>
          <Text style={styles.apptextleft}>Date of Entry : </Text>
          <Text style={styles.apptextright}>{lastfivevisits[`dateofentry_${i}`] ?? "Not Set"}</Text>
        </View>
       <View style={styles.containerapprow} wrap={false}>
          <Text style={styles.apptextleft}>Date of Departure : </Text>
          <Text style={styles.apptextright}>{ lastfivevisits[`dateofdeparture_${i}`] ?? "Not Set"}</Text>
        </View>
        <View style={styles.containerapprow} wrap={false}>
          <Text style={styles.apptextleft}>Reason for Visit : </Text>
          <Text style={styles.apptextright}>{ lastfivevisits[`reasonforvisit_${i}`] ?? "Not Set"}</Text>
        </View>
        </Fragment>
      ))
      return viewer;
    }
    else{
        return view
    }
  }



  if(!permit){
    return (
              <div style={{display:"grid", justifyContent: "center", alignContent: "center"}}>
                {editingApp.isError && (
                    <div className="mt-5 text-center">
                      <h4>Encountered an Error Pulling Application Data</h4>
                      <p>Kindly contact Administrator</p>
                      </div>
                    )}
                    </div>
            )
            
          }


  return (
    <PDFViewer style={styles.viewer}>
      {/* Start of the document*/}
      <Document>
        {/*render a single page*/}
        <Page size="A4" style={styles.page} >

{/* --------------------------------------------------------------------First Page-------------------------------------- */}
        <View style={styles.containerheader} fixed>
        <View style={styles.headerimg}>
        <Image style={styles.logo} src={companyDetails?.URL || Logo} />
        </View>
        <Text style={styles.headertext}>Application Details</Text>
        </View>

        <View style={styles.containerapp} fixed>
        <Text style={styles.headerapptext}>Application ID : {details?.application_details[0]?.APPTYPE_ID ?? "Not Set"}</Text>
        <Text style={styles.headerapptext}>Application Status : {checkAppStatus(details?.application_details[0]?.APPSTATUS)}</Text>
        </View>

        <View style={styles.containerappfooter} fixed> 

        <View style={styles.containerfooterChild}>
         <Text style={styles.headertextcompanyname}>{companyDetails.COMPANY_NAME || 'I-VISAS'}</Text>
         <Text render={({pageNumber, totalPages}) => (`Page ${pageNumber} of ${totalPages}`)}></Text>
         <Text style={styles.footertextdate}>{moment().format()}</Text>
        </View>
          
        </View>

        <View style={styles.containerappbodypadd}> </View>
        
        <View style={styles.container} wrap={false}>
          <Text style={styles.description}>Applicant Details</Text>
        </View>

        <View style={styles.containerappbody}> </View>

        <View style={styles.containerapprowbody}  wrap={false}>
          <Text style={styles.apptextleft}>First Name : </Text>
          <Text style={styles.apptextright}>{details?.application_details[0]?.FIRSTNAME ?? "Not Set"}</Text>
       </View>

       <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Middle Name : </Text>
          <Text style={styles.apptextright}>{details?.application_details[0]?.MIDDLENAME ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Last Name : </Text>
          <Text style={styles.apptextright}>{details?.application_details[0]?.LASTNAME ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Have you been known by any other names ? : </Text>
          <Text style={styles.apptextright}>{details?.application_details[0]?.NAME_CHANGE_QUESTION ?? "Not Set"}</Text>
        </View>
        
        {details?.application_details[0]?.NAME_CHANGE_QUESTION === 'Yes' &&

            
                <>
                <View style={styles.othernamescontainer}>
                <Text style={styles.apptextleft}> Details of Other Names: </Text>
                {OtherNames()}
                </View>
                </>

        }

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Country of Birth : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.COUNTRY_OF_BIRTH ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Place of Birth : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.PLACE_OF_BIRTH ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Current nationality : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.NATIONALITY ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Any other nationalities : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.OTHER_NATIONALITY_QUESTION ?? "Not Set"}</Text>
        </View>

        {details?.application_details[0]?.OTHER_NATIONALITY_QUESTION === 'Yes' &&

            
                <>
                <View style={styles.othernamescontainer}>
                <Text style={styles.apptextleft}> Details of Other Nationality: </Text>
                {OtherNationality()}
                </View>
                </>

        }

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Current passport number : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.PASSPORT_NO ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Passport Issue date : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.PASSPORT_ISSUED ?? "Not Set"}</Text>
        </View>

       {/*  <View style={styles.containerfooter} >
          <Text style={styles.footertext}>{moment().format()}</Text>
        </View>
 */}
        

        {/* ---------------------------------------------------------Second Page----------------------------------------------- */}

       {/*  <View style={styles.containerheader} break>
        <View style={styles.headerimg}>
        <Image style={styles.logo} src={companyDetails?.URL || Logo} />
        </View>
        <Text style={styles.headertext}>Application Details</Text>
        </View>

        <View style={styles.containerapp}>
        <Text style={styles.headerapptext}>Application ID : {details?.application_details[0]?.APPTYPE_ID ?? "Not Set"}</Text>
        <Text style={styles.headerapptext}>Application Status : {checkAppStatus(details?.application_details[0]?.APPSTATUS)}</Text>
        </View> */}
        
        <View style={styles.container} wrap={false}>
          <Text style={styles.description}>Applicant Details</Text>
        </View>

        <View style={styles.containerappbody}> </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Passport Expiry date : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.ISSUING_AUTHORITY ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Issuing authority : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.ISSUING_AUTHORITY ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Do you have a national ID card for your country of nationality : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.NATIONAL_ID_QUESTION ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Do you have BRP : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.BRP_QUESTION ?? "Not Set"}</Text>
        </View>

        {details?.application_details[0]?.BRP_QUESTION === 'Yes' &&
        
        <>
        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>BRP Number : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.BRP_NUMBER ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>BRP Expiry : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.BRP_ISSUE_DATE ?? "Not Set"}</Text>
        </View>
        </>
        }
        

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Name of Mother : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.NAME_MOTHER ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Mother's Date of Birth : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.DOB_MOTHER ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Mother's Nationality : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.NATIONALITY_MOTHER ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Mother's Place of birth : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.PLACE_OF_BIRTH_MOTHER ?? "Not Set"}</Text>
        </View>


        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Name of Father : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.NAME_FATHER ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Father's Date of Birth : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.DOB_FATHER ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Father's Nationality : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.NATIONALITY_FATHER ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Father's Place of birth : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.PLACE_OF_BIRTH_FATHER ?? "Not Set"}</Text>
        </View>


        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Do you have a UK NI Number : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.UK_NI_QUESTION ?? "Not Set"}</Text>
        </View>

        {details?.application_details[0]?.UK_NI_QUESTION === 'Yes' &&
        
        <>
        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>UK NI : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.UK_NI ?? "Not Set"}</Text>
        </View>
        </> 
        }
        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Do you have a UK driving licence : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.UK_DRIVER_LICENSE_QUESTION ?? "Not Set"}</Text>
        </View>

        {details?.application_details[0]?.UK_DRIVER_LICENSE_QUESTION === 'Yes' &&
        
        <>
        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Licence No : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.UK_DRIVER_LICENSE ?? "Not Set"}</Text>
        </View>
        </> 
        }

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Email Address : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.EMAIL ?? "Not Set"}</Text>
        </View>

       {/*  <View style={styles.containerfooter}>
          <Text style={styles.footertext}>{moment().format()}</Text>
        </View>  */}

         {/* ---------------------------------------------------------Third Page----------------------------------------------- */}

        {/*  <View style={styles.containerheader} break>
        <View style={styles.headerimg}>
        <Image style={styles.logo} src={companyDetails?.URL || Logo} />
        </View>
        <Text style={styles.headertext}>Application Details</Text>
        </View>

        <View style={styles.containerapp}>
        <Text style={styles.headerapptext}>Application ID : {details?.application_details[0]?.APPTYPE_ID ?? "Not Set"}</Text>
        <Text style={styles.headerapptext}>Application Status : {checkAppStatus(details?.application_details[0]?.APPSTATUS)}</Text>
        </View> */}
        
        <View style={styles.container} wrap={false}>
          <Text style={styles.description}>Applicant Details</Text>
        </View>

        <View style={styles.containerappbody}> </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Mobile Number : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.MOBILE ?? "Not Set"}</Text>
        </View>


        <View style={styles.containerappbody}> </View>

        <View style={styles.container} wrap={false}>
          <Text style={styles.description}>Applicant Accommodation</Text>
        </View>

        <View style={styles.containerappbody}> </View>


        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Address 1 : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.ADDRESS1 ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Address 2 : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.ADDRESS2 ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Location Name : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.LOCATION_NAME ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Location Code : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.LOCATION_CODE ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Town : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.TOWN ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>County : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.COUNTY ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Post Code : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.POSTCODE ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Country Prefix : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.COUNTRY_PREFIX ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>What date did you move in ? : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.DATE_MOVED_IN_ADDRESS ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Time lived at current address ? : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.DATE_MOVED_IN_ADDRESS ? getdatedif(details?.application_details[0]?.DATE_MOVED_IN_ADDRESS) : "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Is your home owned/rented/other ? : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.HOME_QUESTION_ANSWER ?? "Not Set"}</Text>
        </View>

        {details?.application_details[0]?.HOME_QUESTION_ANSWER === 'rented' &&

        <>
        <View style={styles.containerapprowbody} wrap={false}>
        <Text style={styles.apptextleft}>Landlord's Name : </Text>
        <Text style={styles.apptextright}>{ details?.application_details[0]?.LANDLORD_NAME ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
        <Text style={styles.apptextleft}>Landlord's Email : </Text>
        <Text style={styles.apptextright}>{ details?.application_details[0]?.LANDLORD_EMAIL ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
        <Text style={styles.apptextleft}>Landlord's Mobile Number : </Text>
        <Text style={styles.apptextright}>{ details?.application_details[0]?.LANDLORD_MOBILE ?? "Not Set"}</Text>
        </View>

        {/* <View style={styles.containerfooter}>
          <Text style={styles.footertext}>{moment().format()}</Text>
        </View>  */}

         {/* ---------------------------------------------------------Fourth Page--1--If--rented--is--selected--------------------------------------------- */}

        {/*  <View style={styles.containerheader} break>
        <View style={styles.headerimg}>
        <Image style={styles.logo} src={companyDetails?.URL || Logo} />
        </View>
        <Text style={styles.headertext}>Application Details</Text>
        </View>

        <View style={styles.containerapp}>
        <Text style={styles.headerapptext}>Application ID : {details?.application_details[0]?.APPTYPE_ID ?? "Not Set"}</Text>
        <Text style={styles.headerapptext}>Application Status : {checkAppStatus(details?.application_details[0]?.APPSTATUS)}</Text>
        </View> */}
        
        <View style={styles.container} wrap={false}>
          <Text style={styles.description}>Applicant Accommodation</Text>
        </View>

        <View style={styles.containerappbody}> </View>

        <View style={styles.containerapprowbody} wrap={false}>
        <Text style={styles.apptextleft}>Landlord's Address 1 : </Text>
        <Text style={styles.apptextright}>{ details?.application_details[0]?.LANDLORD_ADDRESS1 ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
        <Text style={styles.apptextleft}>Landlord's Address 2 : </Text>
        <Text style={styles.apptextright}>{ details?.application_details[0]?.LANDLORD_ADDRESS2 ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
        <Text style={styles.apptextleft}>Landlord's Location Name : </Text>
        <Text style={styles.apptextright}>{ details?.application_details[0]?.LANDLORD_LOCATION_NAME ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
        <Text style={styles.apptextleft}>Landlord's Location Code : </Text>
        <Text style={styles.apptextright}>{ details?.application_details[0]?.LANDLORD_LOCATION_CODE ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
        <Text style={styles.apptextleft}>Landlord's Town : </Text>
        <Text style={styles.apptextright}>{ details?.application_details[0]?.LANDLORD_TOWN ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
        <Text style={styles.apptextleft}>Landlord's County: </Text>
        <Text style={styles.apptextright}>{ details?.application_details[0]?.LANDLORD_COUNTY ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
        <Text style={styles.apptextleft}>Landlord's Post Code : </Text>
        <Text style={styles.apptextright}>{ details?.application_details[0]?.LANDLORD_POSTCODE ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
        <Text style={styles.apptextleft}>Landlord's Country Prefix : </Text>
        <Text style={styles.apptextright}>{ details?.application_details[0]?.LANDLORD_COUNTRY_PREFIX ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
        <Text style={styles.apptextleft}>Landlord's Country : </Text>
        <Text style={styles.apptextright}>{ details?.application_details[0]?.LANDLORD_COUNTRY ?? "Not Set"}</Text>
        </View>

        </>    

        }

        <View style={styles.containerapprowbody} wrap={false}>
        <Text style={styles.apptextleft}>How many bedrooms are in your house ? : </Text>
        <Text style={styles.apptextright}>{ details?.application_details[0]?.NUMBER_OF_BEDROOMS ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
        <Text style={styles.apptextleft}>How many other rooms are in your house ? : </Text>
        <Text style={styles.apptextright}>{ details?.application_details[0]?.NUMBER_OF_OTHERROOMS ?? "Not Set"}</Text>
        </View>

    {/*  { details?.application_details[0]?.HOME_QUESTION_ANSWER !== 'rented' &&
        
        <>
        <View style={styles.containerappbody}> </View>

        <View style={styles.containerfooter}>
          <Text style={styles.footertext}>{moment().format()}</Text>
        </View>

        

        <View style={styles.containerheader} break>
        <View style={styles.headerimg}>
        <Image style={styles.logo} src={companyDetails?.URL || Logo} />
        </View>
        <Text style={styles.headertext}>Application Details</Text>
        </View>

        <View style={styles.containerapp}>
        <Text style={styles.headerapptext}>Application ID : {details?.application_details[0]?.APPTYPE_ID ?? "Not Set"}</Text>
        <Text style={styles.headerapptext}>Application Status : {checkAppStatus(details?.application_details[0]?.APPSTATUS)}</Text>
        </View>
        
        <View style={styles.container}>
          <Text style={styles.description}>Applicant Accommodation</Text>
        </View>

        <View style={styles.containerappbody}> </View>

        </>

     } */}

        <View style={styles.containerapprowbody} wrap={false}>
        <Text style={styles.apptextleft}>Who else lives there and their names ? : </Text>
        <Text style={styles.apptextright}>{ details?.application_details[0]?.WHO_LIVES_THERE ?? "Not Set"}</Text>
        </View>


        <View style={styles.containerappbody}> </View>

        <View style={styles.container} wrap={false}>
          <Text style={styles.description}>Applicant Family</Text>
        </View>

        <View style={styles.containerappbody}> </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>MARITAL_STATUS : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.MARITAL_STATUS ?? "Not Set"}</Text>
        </View>

{ details?.application_details[0]?.MARITAL_STATUS !== "single" &&

       <>
        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Name of Spouse : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.NAME_OF_SPOUSE ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Where you got married : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.WHERE_YOU_GOT_MARRIED ?? "Not Set"}</Text>
        </View>


       {/*  <View style={styles.containerfooter}>
          <Text style={styles.footertext}>{moment().format()}</Text>
        </View>  */}

        {/* ---------------------------------------------------------Fifth Page 1------single not selected----------------------------------------- */}

       {/*  <View style={styles.containerheader} break>
        <View style={styles.headerimg}>
        <Image style={styles.logo} src={companyDetails?.URL || Logo} />
        </View>
        <Text style={styles.headertext}>Application Details</Text>
        </View>

        <View style={styles.containerapp}>
        <Text style={styles.headerapptext}>Application ID : {details?.application_details[0]?.APPTYPE_ID ?? "Not Set"}</Text>
        <Text style={styles.headerapptext}>Application Status : {checkAppStatus(details?.application_details[0]?.APPSTATUS)}</Text>
        </View> */}
        
        <View style={styles.container} wrap={false}>
          <Text style={styles.description}>Applicant Family</Text>
        </View>

        <View style={styles.containerappbody}> </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Date of marriage if married : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.DATE_OF_MARRIAGE ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Date of birth of spouse : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.DOB_SPOUSE ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Nationality of spouse : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.NATIONALITY_SPOUSE ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Passport number for spouse : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.PASSPORT_SPOUSE ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Where you met : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.WHERE_YOU_MET ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Where your relationship began : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.WHERE_RELATIONSHIP_BEGAN ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>When you last saw each other : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.WHEN_LAST_YOU_SAW_EACHOTHER ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Do you live together : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.LIVE_TOGETHER_QUESTION ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>What date you started living together : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.DATE_LIVING_TOGETHER ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Do you have any children : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.DO_YOU_HAVE_CHILDREN ?? "Not Set"}</Text>
        </View>

        { details?.application_details[0]?.DO_YOU_HAVE_CHILDREN === "Yes" && 
        (
        <>
        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>How many dependant children do you have ? : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.NUMBER_OF_DEPENDENT_CHILDREN ?? "Not Set"}</Text>
        </View>
        </>
        )
        }


        {details?.application_details[0]?.DO_YOU_HAVE_CHILDREN === 'Yes' &&
                    
        <>
        <View style={styles.othernamescontainer} wrap={true}> 
        <Text style={styles.apptextleft}> Details of Dependant Children: </Text>
        {children()}
        </View>
        </>

        }

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Have you been married before : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.MARRIED_BEFORE_QUESTION ?? "Not Set"}</Text>
        </View>

        {details?.application_details[0]?.MARRIED_BEFORE_QUESTION === 'Yes' &&
                    
        <>
        <View style={styles.othernamescontainer}> 
        <Text style={styles.apptextleft}> Details of Previous Partner(s): </Text>
        {previousPartner()}
        </View>
        </>

        }


        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Has your current partner been married before : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.PARTNER_MARRIED_BEFORE ?? "Not Set"}</Text>
        </View>

        {details?.application_details[0]?.PARTNER_MARRIED_BEFORE === 'Yes' &&
                    
        <>
        <View style={styles.othernamescontainer}> 
        <Text style={styles.apptextleft}> Details of Current Partner's Ex: </Text>
        {PartnerExes()}
        </View>
        </>

        }


        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Do you have any family/friends in your home country : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.DO_YOU_HAVE_FAMILY_IN_HOME_COUNTRY ?? "Not Set"}</Text>
        </View>

        {details?.application_details[0]?.DO_YOU_HAVE_FAMILY_IN_HOME_COUNTRY === 'Yes' &&
                    
        <>
        <View style={styles.othernamescontainer}> 
        <Text style={styles.apptextleft}> Details of Current Partner's Ex: </Text>
        {familyAndfriends()}
        </View>
        </>

        }

        </>
      }

        <View style={styles.container} wrap={false}>
          <Text style={styles.description}>Applicant English Proficiency</Text>
        </View>

        <View style={styles.containerappbody}> </View>


        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Do you have a degree taught in English </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.HAVE_DEGREE_IN_ENGLISH ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Have you passed any English Test : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.PASSED_RECOGNIZED_TEST ?? "Not Set"}</Text>
        </View>


        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Test passed : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.WHAT_TEST_DID_YOU_PASS ?? "Not Set"}</Text>
        </View>


        { details?.application_details[0]?.WHAT_TEST_DID_YOU_PASS === "Other" && 
        (
        <>
        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Other Test passed : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.OTHER_CERTIFICATE ?? "Not Set"}</Text>
        </View>
        </>
          )
        }


        <View style={styles.container} wrap={false}>
          <Text style={styles.description}>Applicant Qualification</Text>
        </View>

        <View style={styles.containerappbody}> </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Do you have any other qualification  : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.ANY_OTHER_CERTIFICATE ?? "Not Set"}</Text>
        </View>

        {details?.application_details[0]?.ANY_OTHER_CERTIFICATE === 'Yes' &&
                    
        <>
        <View style={styles.othernamescontainer}> 
        <Text style={styles.apptextleft}> Details of Qualification: </Text>
        {qualification()}
        </View>
        </>

        }


        <View style={styles.container} wrap={false}>
          <Text style={styles.description}>Applicant Employment</Text>
        </View>

        <View style={styles.containerappbody}> </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Employment Status  : </Text>
          <Text style={styles.apptextright}>{ details?.employment_details[0]?.EMPOYMENT_STATUS ?? "Not Set"}</Text>
        </View>

        { (details?.employment_details[0]?.EMPOYMENT_STATUS === "Employed" || details?.employment_details[0]?.EMPOYMENT_STATUS === "Self-Employed")   && 
        <>
        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>When did you start your job : </Text>
          <Text style={styles.apptextright}>{ details?.employment_details[0]?.JOB_START_DATE ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>What is the name of your employer  : </Text>
          <Text style={styles.apptextright}>{ details?.employment_details[0]?.EMPLOYER_NAME ?? "Not Set"}</Text>
        </View>


        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Telephone number of your employer : </Text>
          <Text style={styles.apptextright}>{ details?.employment_details[0]?.EMPLOYER_PHONE ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Email address of your employer : </Text>
          <Text style={styles.apptextright}>{ details?.employment_details[0]?.EMPLOYER_EMAIL ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>What is your annual salary : </Text>
          <Text style={styles.apptextright}>{ details?.employment_details[0]?.SALARY ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>What is your job title : </Text>
          <Text style={styles.apptextright}>{ details?.employment_details[0]?.JOB_TITLE ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Address 1  : </Text>
          <Text style={styles.apptextright}>{ details?.employment_details[0]?.EMPLOYER_ADDRESS1 ?? "Not Set"}</Text>
        </View>


        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Address 2 : </Text>
          <Text style={styles.apptextright}>{ details?.employment_details[0]?.EMPLOYER_ADDRESS2 ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Location Name  : </Text>
          <Text style={styles.apptextright}>{ details?.employment_details[0]?.EMPLOYER_LOCATION ?? "Not Set"}</Text>
        </View>


        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Location Code : </Text>
          <Text style={styles.apptextright}>{ details?.employment_details[0]?.EMPLOYER_LOCATION_CODE ?? "Not Set"}</Text>
        </View>


        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Town : </Text>
          <Text style={styles.apptextright}>{ details?.employment_details[0]?.EMPLOYER_TOWN ?? "Not Set"}</Text>
        </View>


        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>County : </Text>
          <Text style={styles.apptextright}>{ details?.employment_details[0]?.EMPLOYER_COUNTY ?? "Not Set"}</Text>
        </View>


        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Post Code : </Text>
          <Text style={styles.apptextright}>{ details?.employment_details[0]?.EMPLOYER_POSTCODE ?? "Not Set"}</Text>
        </View>


        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Country Prefix : </Text>
          <Text style={styles.apptextright}>{ details?.employment_details[0]?.EMPLOYER_COUNTRYPREFIX ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Country  : </Text>
          <Text style={styles.apptextright}>{ details?.employment_details[0]?.EMPLOYER_COUNTRY ?? "Not Set"}</Text>
        </View>

        </>
        }

        <View style={styles.container} wrap={false}>
          <Text style={styles.description}>Applicant Maintenance</Text>
        </View>

        <View style={styles.containerappbody}> </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Name of Bank  : </Text>
          <Text style={styles.apptextright}>{ details?.maintenance_details[0]?.BANK_NAME ?? "Not Set"}</Text>
        </View>


        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Is it a registered recognized financial institute  : </Text>
          <Text style={styles.apptextright}>{ details?.maintenance_details[0]?.REGISTERED ?? "Not Set"}</Text>
        </View>


        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Country funds held in  : </Text>
          <Text style={styles.apptextright}>{ details?.maintenance_details[0]?.HELD_COUNTRY ?? "Not Set"}</Text>
        </View>


        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Currency funds held in  : </Text>
          <Text style={styles.apptextright}>{ details?.maintenance_details[0]?.HELD_CURRENCY ?? "Not Set"}</Text>
        </View>


        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Amount held  : </Text>
          <Text style={styles.apptextright}>{ details?.maintenance_details[0]?.HELD_AMOUNT ?? "Not Set"}</Text>
        </View>


        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Date the funds have been held from  : </Text>
          <Text style={styles.apptextright}>{ details?.maintenance_details[0]?.HELD_DATE ?? "Not Set"}</Text>
        </View>

        <View style={styles.container} wrap={false}>
          <Text style={styles.description}>Applicant Travel History</Text>
        </View>

        <View style={styles.containerappbody}> </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Are you currently in the UK : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.ARE_YOU_IN_UK ?? "Not Set"}</Text>
        </View>

        { details?.application_details[0]?.ARE_YOU_IN_UK === "Yes" && 
          (
          <>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>When did you enter the UK if you are applying in-country : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.WHEN_DID_YOU_ENTER_UK ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Did you enter legally : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.DID_YOU_ENTER_LEGALLY ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>When did your visa start : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.VISA_START_DATE ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>When does your visa expire : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.VISA_END_DATE ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>What is your current visa status : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.VISA_STATUS ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Have you left the UK since you have been living in the UK : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.OUT_OF_THE_UK_BEFORE ?? "Not Set"}</Text>
        </View>


        { details?.application_details[0]?.OUT_OF_THE_UK_BEFORE === "Yes" && 
          (
          <>
          <View style={styles.othernamescontainer}> 
          <Text style={styles.apptextleft}> Countries Visited : </Text>
          {visitedCountries()}
          </View>
          </>
          )
        }

         </>
          )
        }

       { details?.location_details[0]?.LAST_FIVE_VISITS !== "" && 
          (
          <>
          <View style={styles.othernamescontainer}> 
          <Text style={styles.apptextleft}> Last Five Visits to the UK : </Text>
          {lastfiveVisits()}
          </View>
          </>
          )
        }


        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Have you ever entered the UK illegally  : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.ENTERED_UK_MEANS ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Reason  : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.REASON_FOR_ILEGAL_ENTRY ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Have you ever stayed beyond the expiry date of your visa in the UK  : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.EVER_STAYED_BEYOND_EXPIRY ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Reason  : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.REASON_FOR_STAYING_BEYOND_EXPIRY ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Have you ever breached the conditions of your leave  : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.BREACHED_CONDITION_FOR_LEAVE ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Reason  : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.REASON_FOR_BREACH ?? "Not Set"}</Text>
        </View>

{/* ------ */}

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Worked without permission  : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.WORK_WITHOUT_PERMIT ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Reason  : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.REASON_FOR_WORK_WITHOUT_PERMIT ?? "Not Set"}</Text>
        </View>


        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Received public funds  : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.RECEIVED_PUBLIC_FUNDS ?? "Not Set"}</Text>
        </View>


        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Reason  : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.REASON_RECEIVING_FUNDS ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Give false information when applying for a visa  : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.GIVE_FALSE_INFO ?? "Not Set"}</Text>
        </View>


        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Reason  : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.REASON_FOR_FALSE_INFO ?? "Not Set"}</Text>
        </View>


        {/* ----- */}

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Used deception in a previous visa application  : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.USED_DECEPTION ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Reason  : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.REASON_FOR_DECEPTION ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Breached any other immigration law  : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.BREACHED_OTHER_LAWS ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Reason  : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.REASON_FOR_BREACHING__LAWS ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Have you been Refused a visa or Refused entry at the border  : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.VISA_REFUSAL_QUESTION ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Reason  : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.REASON_FOR_REFUSAL ?? "Not Set"}</Text>
        </View>

{/* ----- */}


       <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Have you been Refused permission to stay or remain  : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.PERMISSION_REFUSAL ?? "Not Set"}</Text>
        </View>


        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Reason  : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.REASON_FOR_PERMISSION_REFUSAL ?? "Not Set"}</Text>
        </View>


        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Have you been Refused asylum  : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.ASYLUM_REFUSAL ?? "Not Set"}</Text>
        </View>


        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Reason  : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.REASON_FOR_ASYLUM_REFUSAL ?? "Not Set"}</Text>
        </View>


        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Have you ever been deported, removed or been required to leave any country  : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.EVER_DEPORTED ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Reason  : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.REASON_FOR_DEPORTATION ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Have you been excluded or banned from any country  : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.EVER_BANNED ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Reason  : </Text>
          <Text style={styles.apptextright}>{ details?.application_details[0]?.REASON_FOR_BAN ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerappbody}> </View>

        <View style={styles.container}>
          <Text style={styles.description}>Applicant Character</Text>
        </View>

        <View style={styles.containerappbody}> </View>


        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Have you ever been charged with a criminal offence  : </Text>
          <Text style={styles.apptextright}>{ details?.character_details[0]?.CRIMINAL_OFFENSE ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Reason  : </Text>
          <Text style={styles.apptextright}>{ details?.character_details[0]?.CRIMINAL_OFFENCE_ANSWER ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Do you have any pending prosecutions  : </Text>
          <Text style={styles.apptextright}>{ details?.character_details[0]?.PENDING_PERSECUTION ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Reason  : </Text>
          <Text style={styles.apptextright}>{ details?.character_details[0]?.DETAILS_OF_PROSECUTIONS ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Ever had any terrorist views, charges or been questioned in relation to terrorism:</Text>
          <Text style={styles.apptextright}>{ details?.character_details[0]?.TERRORIST_VIEW ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Reason  : </Text>
          <Text style={styles.apptextright}>{ details?.character_details[0]?.DETAILS_OF_TERRORIST_CHARGES ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Ever worked for the Judiciary/Security Services/Media/Intelligence Agencies/Armed Forces:</Text>
          <Text style={styles.apptextright}>{ details?.character_details[0]?.WORKED_FOR_SECURITY ?? "Not Set"}</Text>
        </View>

        <View style={styles.containerapprowbody} wrap={false}>
          <Text style={styles.apptextleft}>Reason  : </Text>
          <Text style={styles.apptextright}>{ details?.character_details[0]?.DETAILS_OF_WORK ?? "Not Set"}</Text>
        </View>

      {/*   <View style={styles.containerfooter}>
          <Text style={styles.footertext}>{moment().format()}</Text>
        </View>

        

        <View style={styles.containerheader} break>
        <View style={styles.headerimg}>
        <Image style={styles.logo} src={companyDetails?.URL || Logo} />
        </View>
        <Text style={styles.headertext}>Application Details</Text>
        </View>

        <View style={styles.containerapp}>
        <Text style={styles.headerapptext}>Application ID : {details?.application_details[0]?.APPTYPE_ID ?? "Not Set"}</Text>
        <Text style={styles.headerapptext}>Application Status : {checkAppStatus(details?.application_details[0]?.APPSTATUS)}</Text>
        </View>
        
        <View style={styles.container}>
          <Text style={styles.description}>Applicant Accommodation</Text>
        </View>

        <View style={styles.containerappbody}> </View> */}

        

         {/*  <View style={styles.section}>
            <Text>Hello</Text>
          </View>
          <View style={styles.section}>
            <Text>World</Text>
          </View> */}
        </Page>
      </Document>
    </PDFViewer>
  );

}


export default GenerateApplicationPDF;