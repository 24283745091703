import React, { useState, useEffect, Fragment } from "react";
import { useMutation, useQuery} from "react-query";
import { useForm } from "react-hook-form";
import * as api from '../Utils/adminApiCalls';
import { useNavigate } from "react-router-dom";

const AdminForgottenPass = () => {

    const {
        register,
        handleSubmit,
        trigger,
        formState: { errors },
      } = useForm();
    
      const navigate = useNavigate();
    
      const [errorlist, setErrorlist] = useState([]);
    
      const {isError, isSuccess, error, isLoading, mutate, data} = useMutation(api.adminforgottenpassword, {
        onSuccess: (data) => {
          console.log("forgotten password ", data);
    
        },
        onError: (error) => {
          console.log("errors",error.response.data); 
          const errlist = []
          const errObject = error.response.data?.errors
          if(error.response?.data?.errors !== undefined && typeof error.response?.data?.errors === 'object')
           {
                for(var propName in errObject) {
                  if(errObject.hasOwnProperty(propName)) {
                      errlist.push(errObject[propName]);
                  }
                }
                setErrorlist(errlist);
           }
           else if(error.response?.data?.message) {
            errlist.push(error.response?.data?.message);
            setErrorlist(errlist);
           }
           else
           {
             errlist.push(error.message)
             setErrorlist(errlist);
           }
    
           console.log("errlist",errlist); 
          
        }
      });


       ////////Company Information :////////////
  const [companyDetails, setCompanyDetails] = useState({});

  const getCompanyInfoNav = useQuery("get_companyinfo", api.companyInfo, {
    enabled: true,
    refetchOnMount:true,
    refetchOnWindowFocus:true,
    onSuccess: (data) => {
      console.log("users data:::", data);

      const apd = {...data?.Info[0]};
      apd.URL = data?.URL;
      console.log("@@@@@@@@@@@@@@@@@@", apd)
      setCompanyDetails(apd);
      setCompanyDetails(companyDetails => {
        console.log("companyDetails ===>>>",companyDetails);
        return companyDetails;
      })
    },
    onError: (error) => {
        console.log("Error Message ::: ",error); 
    }
  });
    
      const [fmstate, setFmtate] = useState(
        {
          islogged: false,
            user_email: "",
        }
      );
    
    
      const handleFormChange = async (e) => {
        let loginParamsNew = { ...fmstate };
        let val = e.target.value;
        loginParamsNew[e.target.name] = val;
        setFmtate(loginParamsNew);
        console.log(fmstate);
      };
    
      const handleforgottenpassword = async () => {
        console.log(fmstate);
          let email = fmstate.user_email;
          mutate({"email":email});
      };

      const handleOnKeyPress = (e) => {
        if (e.keyCode === 13) {
          e.preventDefault()
          handleforgottenpassword();
          console.log('submit')
        }
      }

    return (
       <>
<div className="color-line"></div>

<div className="login-container">
    <div className="row">
        <div className="col-md-12">
            <div className="text-center m-b-md">


            <div className="mb-4 mb-md-5 text-center">
                    <a href="index.html" className="d-block auth-logo">
                      {/* <img src="assets/images/logo.png" alt="" height="20%" />{" "} */}
                      <img src={companyDetails?.URL || "assets/images/logo.png"} alt="logo" width="217px" height="92px" /* height="20%" */ />
                      <span className="logo-txt"></span>
                    </a>
                  </div>

                <h3>SYSTEM USER : Forgotten Password</h3>
                <small>Enter your Email and instructions will be sent to you!</small>
                {isSuccess && (
                  <h4>{data.message}</h4>
                )}
            </div>
            <div className="hpanel">
                <div className="panel-body" style={{border: "solid 1px #c5c5c973"}}>
                        <form id="loginForm" onSubmit={handleSubmit(handleforgottenpassword)}>
                            <div className="form-group">
                                <input 
                                type="email"
                                style={{textAlign:"center"}}
                                className={`form-controlL ${
                                  errors.user_email ? "redborder" : ""
                                }`}
                                {...register("user_email", {
                                  required: true,
                                  pattern:
                                    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                })}
                                id="user_email"
                                name="user_email"
                                aria-label="email"
                                onChange={handleFormChange}
                                onKeyDown={handleOnKeyPress}
                                placeholder="Please enter your email"
                                title="Please enter you username" 
                                />
                                {errors.user_email && (
                                <span className="errorValidate help-block small">
                                    Please enter a valid e-mail address
                                </span>
                                )}
                            </div>
                            {isLoading ? 
                            (
                               <div className="p-2 mt-4 text-center"><p className="text-muted presend">...Sending Request</p></div>
                            ):(
                               <button className="btn btn-success btn-block">Send</button>
                            )}
                        </form>

                        {isError && (
                            <div className="mt-5 text-center">
                                <ul className="errorlist">
                                {
                                errorlist.map((errorItem, i) =>{
                                    return(
                                        <li key={i} className="text-muted-error">
                                            {errorItem}
                                        </li>
                                    )
                                })
                                }
                            </ul>
                            </div>
                        )}
                </div>
            </div>
        </div>
    </div>
    <div className="row">
        <div className="col-md-12 text-center">
            {/* <strong>I-VISAS</strong> - APPLICATION <br/>  © {new Date().getFullYear()} i-visas.com */}
            <strong>{companyDetails?.COMPANY_NAME ?? "I-VISAS"}</strong> - APPLICATION <br/>  © {new Date().getFullYear()} {companyDetails?.COMPANY_NAME ?? "i-visas.com"}
        </div>
    </div>
</div>
</>
    )
}

export default AdminForgottenPass;