import React from "react";
import {
  Link,
  useNavigate, useLocation 
} from "react-router-dom";
const NotFoundInner = () => {
  const navigate = useNavigate();
  let location = useLocation();

  let from = -1; //(window.history.state && window.history.state.idx > 0) ? -1 : "/dashboard/overview";

  console.log("window history state: ", window.history.state);  
  console.log("window.history.state.idx", window.history.state.idx, window.history.state.idx > 0);

  console.log("history URL",from);
  console.log("location state:",location);

  const goBack = () => {
    navigate(from, { replace: false });
  }


  return (
    <>
<div className="content">

<div className="error-container">
    <i className="pe-7s-way text-success big-icon"></i>
    <h1>404</h1>
    <strong>Page Not Found</strong>
    <p>
        Sorry, It lloks like you are trying to reach a URL that does not exist. Please use our navigation on the side to find your way back or click the green button to back to a previous page.
    </p>
    <a href={void(0)} onClick={()=> goBack()} className="btn btn-xs btn-success">Go back to Previous Page</a>
</div>
</div>
 {/* <h2>404 page not found </h2>; */}
    </>
   
  )
};

export default NotFoundInner;
