import React, { useState, useEffect, Fragment, useRef } from "react";
import { useQuery, useMutation } from "react-query";
import toast, { Toaster } from 'react-hot-toast';
import { getUser, removeUserSession, useDebounce, sleep } from "./Utils/Common";
import { useNavigate, Link } from "react-router-dom";
import * as api from './Utils/apiCall';
import { useForm } from "react-hook-form";
import moment from "moment";
import { Modal } from 'react-bootstrap';

import "./Messages.css";


const pageLimit = 5;
const dataLimit = 10;

const ApplicationList = () => {

  const {
    register,
    handleSubmit,
    trigger,
    reset,
    formState: { errors },
  } = useForm({mode: "onBlur"});

  const {
    register:uploadRegister,
    trigger:uploadTrigger,
    reset:uploadRest,
    formState: { errors:uploaderrors },
  } = useForm({mode: "onChange"});

const navigate = useNavigate();
  
const [userData, setUserData] = useState({});

const [appID, setAppID] = useState("");

const [appdata, setAppdata] = useState([]);

const getAppID = (e) => {
  setAppID(e.target.getAttribute("data-appid"));
};

///////////Modal upload Image //////
const [uploadopen, setUploadOpen] = useState(false);

const [InputKey, setInputKey] = useState(Math.random().toString(36));

const [filetype, setFiletype] = useState("");
const [selectedFile, setSelectedFile] = useState(null);

const handleUploadShow = (e) => {
  setAppID(e.target.getAttribute("data-appid"));
  setUploadOpen(true);
};

const handleUploadClose= (e) => {
  setUploadOpen(false);
};

const handleUpload= async (e) => {
  const appid = e.target.getAttribute("data-appid");
   
  const frmvalidate = await uploadTrigger(); 

  const formData = new FormData();
  formData.append("APPTYPE_ID", appid);
  formData.append([filetype], true);
  formData.append("file", selectedFile);

    console.log("formData ::", formData);

    if(frmvalidate && appid !== ""){
        fileUploadMutate.mutate(formData);
    }
    else {
      toast.error("Invalid file upload Data",{
        duration: 6000,
        });

        console.log("frmvalidate :::", frmvalidate);
    }
};

const resetsFileInput =() => {
  let randomString = Math.random().toString(36);
  setInputKey(randomString) 
}

const fileUploadMutate = useMutation('uploadfile', api.uploadFile, {
  onSuccess: (data) => {
      console.log("data", data);
      
      toast.success("File Uploaded Successfully",{
        duration: 6000,
        });

      sleep(2100).then(() => {
          
          setFiletype("")
          setSelectedFile("");
          uploadRest();
          resetsFileInput();
          fileUploadMutate.reset();
          
      });
        
  },
  onError: (error) => {
      
    console.log("Error Message",error); 

    if(error?.response?.status === 401){
      toast.error("Session expired : Kindly Re-login",{
        duration: 6000,
        })
      removeUserSession();
      navigate("/", {replace: true});
    }
    else {
      toast.error("Something went wrong : Error Uploading File",{
        duration: 6000,
        });
    }

  } 
})
////////////////





///////////Modal Add Tracker //////

const [trackeropen, setTrackerOpen] = useState(false);
const [courier, setCourier] = useState([]);

const getCouriers = useQuery("users", () => api.getCourier(), {
  enabled: trackeropen,
  onSuccess: (data) => {
    console.log("users data:::", data);
    setCourier([...data]);
    setCourier(courier => {
      return courier;
    })
  },
  onError: (error) => {
    
      console.log("Error Message ::: ",error); 
    
      if(error?.response?.status === 401){
        toast.error("Session Expired : Please Re-Login",{
          duration: 6000,
          });
       removeUserSession();
        navigate("/", {replace: true});
      }
      else {
        toast.error("Something went wrong",{
          duration: 6000,
          });
      }
  }
})

const handleTrackerShow = (e) => {
  setAppID(e.target.getAttribute("data-appid"));
  setTrackerOpen(true);
};

const handleTrackerClose= (e) => {
  setTrackerOpen(false);
};


const handleTracker = async (e) => {
    
    const appid = e.target.getAttribute("data-appid");
    //console.log("tracking details", details, " appid", appid);
    const frmvalidate = await trigger();
    
      if(frmvalidate && appid !== ""){
          addTracking.mutate({
            'APPTYPE_ID': appid,
            'CLIENT_ID': userData?.id,
            'TRACKING_TYPE_ID': details.courier,
            'DIRECTION': details.direction,
            'TRACKING_DATE': details.tracking_date,
            'TRACKING_NOTE': details.note,
            'TRACKING_ID': details.trackingid,
          });
    }
    else {
      toast.error("Invalid Tracking Data",{
        duration: 6000,
        });

        console.log("tracking details", details, " appid", appid);

        console.log("frmvalidate :::", frmvalidate);
    }
};


const addTracking = useMutation('tracking', api.addTracking, {
  onSuccess: (data) => {
      console.log("data", data);
      
      const dt = {
        direction:"",
        courier:"",
        trackingid: "",
        tracking_date:"",
        note:"",
      }

      setDetails(dt);
      setDetails(details => {
        return details;
      });

      toast.success("Tracker Added Successfully",{
        duration: 6000,
        });
  },
  onError: (error) => {
      
    console.log("Error Message",error); 

    if(error?.response?.status === 401){
      toast.error("Session expired : Kindly Re-login",{
        duration: 6000,
        })
      removeUserSession();
      navigate("/", {replace: true});
    }
    else {
      toast.error("Something went wrong : tracking",{
        duration: 6000,
        });
    }

  } 
})
////////////////

const [details, setDetails] = useState({
  direction:"",
  courier:"",
  trackingid: "",
  tracking_date:"",
  note:"",
  filetype:"",
  loadfile:""
});

  
useEffect(() => {
    document
      .getElementById("toglepanel")
      .addEventListener("click", togglePanel);

      const checkUserData = () => {
        const item = getUser();
    
        if (item) {
          console.log("user item",item);
          setUserData(item);
  
          setUserData((userData) => {
            console.log("updated details", userData);
            return userData;
          });
  
        }
      }
      checkUserData();
  
      window.addEventListener('storage', checkUserData)
    
      return () => {
        window.removeEventListener('storage', checkUserData)
      }
  }, []);

  const clientApplications = useQuery(['clientapplications', userData?.id], () => api.getClientApplications(userData?.id), {
    onSuccess: (data) => {
      console.log("client application",data)
      const apd = [...data];
      setAppdata(apd);
      setAppdata((appdata) => {
        return appdata;
      })
    },
    onError: (error) => {
      
      console.log("Error Message",error); 

      toast.error("Something went wrong",{
        duration: 6000,
        });

      if(error?.response?.status === 401){
        removeUserSession();
        navigate("/", {replace: true});
      }

    }
  });
   
  const { loading: loadingapp, data: dataapp, refetch:appRefetch } = clientApplications;


  //////////useQuery for PreScreen Status by Client ID ////////////
const [getPrescreen, setPrescreen] = useState(userData?.prescreened_status);

console.log("from User data",userData?.prescreened_status)
const preScreenStatusQuery = useQuery(["prescreenstatus", userData?.id], () => api.getPrescreenByClientID(userData?.id), {
  onSuccess: (data) => {
         console.log("prescreen data", data);
         if(data[0] && data[0].prescreened_status) {
            setPrescreen(data[0].prescreened_status)
         
         setPrescreen(getPrescreen => {
            console.log("Prescreen Status #1: ",getPrescreen);
            return getPrescreen;
         })
        }

        console.log("Prescreen Status #2: ",getPrescreen);
  },
  onError: (error) => {
      
    console.log("Error Message",error); 

    if(error?.response?.status === 401){
      toast.error("Session expired : Kindly Re-login",{
        duration: 6000,
        })
      removeUserSession();
      navigate("/", {replace: true});
    }
    else {
      toast.error("Something went wrong : Error Uploading File",{
        duration: 6000,
        });
    }

  } 
})

const {data : prescreendata} = preScreenStatusQuery;

//////////////////////////////////////////


  const togglePanel = () => {
    const hpanel = document.getElementById("tpanel");

    if (hpanel.classList.contains("small-header")) {
      hpanel.classList.remove("small-header");
    } else {
      hpanel.classList.add("small-header");
    }
  };

  const handleChange = (event) => {
    event.persist();

    if(event.target.name === 'loadfile'){

      const file = event.target.files[0];
      console.log("file size", file?.size );

      const mimes = ['doc','docx','pdf','txt','csv', 'png', 'jpeg', 'jpg', ];

      const acceptableFileType = mimes.includes(file?.type.substring(file?.type.indexOf('/') + 1));

      console.log("file type", file?.type, "  acceptableFileType ::", acceptableFileType, "sliced:", file?.type.substring(file?.type.indexOf('/') + 1));

      if(acceptableFileType){

         setSelectedFile(event.target.files[0]);
      }
      else {

        resetsFileInput();
        toast.error("Invalid File Type",{
          duration: 6000,
          });
      }

    }
    else if(event.target.name ===  'filetype'){
       setFiletype(event.target.value);
    }
    else{

    setDetails((details) => ({
      ...details,
      [event.target.name]: event.target.value,
    }));

  }
    console.log(details);
  };

  const viewApp = async (appid) => {
    console.log("appid ID", appid);
    
  };

  const createApp = (clientid) => {
      console.log("client id", clientid);

      if(!isNaN(clientid)){
      navigate("/dashboard/creat_application", {
        state: {
          clientid: clientid,
          action:"create"
        }, 
        replace: true,
      });
    }
    else{
        toast.error("Invalid Client ID",{
            duration: 6000,
        });
    }
  }

  const appdate = (datestr) => {
    const start = moment(datestr).format(
      "dddd, MMMM Do YYYY, h:mm:ss a"
    );
    
    return start;
  };

  const TagIcon = (tag) => {
    if(tag === 0){
      return <><i className="fa fa-star-half text-info"></i><span> INITIATED</span></>
    }
    else if(tag === 1 ){
      return <><i className="fa fa-star-half-empty text-info"></i><span> DRAFT</span></>
    }
    else if(tag === 2 ){
      return <><i className="fa fa-search-plus text-default"></i><span> UNDER REVIEW</span></>
    }
    else if(tag === 3 ){
      return <><i className="fa fa-handshake-o text-success"></i><span> APPROVED</span></>
    }
    else if(tag === 4 ){
      return <><i className="fa fa-cart-plus text-info"></i><span> BILLED</span></>
    }
    else if(tag === 5 ){
      return <><i className="fa fa-leaf text-success"></i><span> COMPLETED</span></>
    }
    else{
      return ""
    }
  }

  const getTodaysDate = () => {
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth()+1; //January is 0 so need to add 1 to make it 1!
    let yyyy = today.getFullYear();
    if(dd<10){
      dd='0'+dd
    } 
    if(mm<10){
      mm='0'+mm
    } 

    today = yyyy+'-'+mm+'-'+dd;

    //console.log("todays date",today);

    return today;
  }


 /////////////Pagination ////////////

  //const [pages] = useState(Math.ceil(datamsg?.length / dataLimit) || 1);
  const pages = useRef(Math.ceil(appdata?.length / dataLimit) || 1);

  //console.log("dataLimit::", dataLimit," datamsg length::", datamsg?.length, " pages::: ", Math.ceil(dataapp?.length / dataLimit));

  const [currentPage, setCurrentPage] = useState(1);

  const goToNextPage = () => {
      if(currentPage <= pages.current) {
      setCurrentPage((page) => page + 1);
      }
  }

  const goToPreviousPage = () => {
      if(currentPage > 1 ){
      setCurrentPage((page) => page - 1);
      }
  }

  const changePage = (event) => {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage(pageNumber);
  }

  const getPaginatedData = () => {
    const startIndex = currentPage * dataLimit - dataLimit;
    const endIndex = startIndex + dataLimit;
    //console.log("getPaginationData", dataapp?.slice(startIndex, endIndex))
    return appdata?.slice(startIndex, endIndex);
  };

  const getPaginationGroup = () => { 
    let start = Math.floor((currentPage - 1) / pageLimit) * pageLimit;
    
    let page = Math.ceil(appdata?.length / dataLimit) || 1;
    //console.log("local page ", Math.ceil(appdata?.length / dataLimit));
    //console.log("Array Return", new Array(pages).fill().map((_, idx) => start + idx + 1), "  pages : ", pages);

    pages.current = page;
    
     return new Array(page).fill().map((_, idx) => start + idx + 1);
  }

  ////////////////////////////////////


  /////////////////Search function ///////////////
const [searchquery, setSearchquery] = useState("");

 const [searchApi, setSearchAPi] = useState("");

const searchApplication = (q) => {
  //console.log("query: ", q.target.value);

  const query = q.target.value;

  setSearchquery(query);

  setSearchquery(searchquery => {
    return searchquery;
  });

  if(query.length === 0){
   
      console.log("Refetching Application list", query, " query length: ", query.length);
      appRefetch();
      //console.log("searchApi",);      
  }
}

const debouncedFilter = useDebounce(searchquery, 500);

console.log("debouncedFilter", debouncedFilter); 

const { data:searchData, isLoading:searchLoading } = useQuery(
  ['applications', debouncedFilter], 
  () => api.searchApplication(debouncedFilter),
  { enabled: Boolean(debouncedFilter),
  onSuccess: (data) => {
      console.log("data", data);
      const apd = [...data];
      setAppdata(apd);
      setAppdata((appdata) => {
        return appdata;
      })
  },
  onError: (error) => {
      
    console.log("Error Message",error); 

    toast.error("Something went wrong",{
      duration: 6000,
      });

    if(error?.response?.status === 401){
      removeUserSession();
      navigate("/", {replace: true});
    }

  } 
})

  /////////////////////////////////


  ////////////////////////////////////////////////////////////////////
const[allbills, setallbills]= useState([]);

const getBills = useQuery("billings", api.activeBills, {
  onSuccess: (data) => {
    console.log("bills data", data);
    setallbills(data);
    setallbills(allbills => {
      return allbills;
    })
  },
  onError: (error) => {
      
    console.log("Error Message",error); 
    InvoicegetRef.current = false;

    toast.error("Something went wrong",{
      duration: 6000,
      });

    if(error?.response?.status === 401){
      removeUserSession();
      navigate("/", {replace: true});
    }

  } 
})

const [invoiceviewopen, setInvoiceViewOpen] = useState(false);

const[invoicedetail, setInvoiceDetail]= useState([]);

const [invoiceItems, setInvoiceItems] = useState([
  {
    billingitem_0: "",
    quantity_0: "",
    amount_0: "",
    vat_0: "",
    paid_0: "0",
    cap_0: false,
  },
]);

const InvoiceRef = useRef(null);
const InvoiceType = useRef(null);
const InvoicegetRef = useRef(false);

let totalCost = useRef(0);
let totalTax = useRef(0);
let paidAmount = useRef(0);
let balance = useRef(0);
let VATRATE = useRef(0);

const roundToTwo = (num) => {
  return +(Math.round(num + "e+2")  + "e-2");
}


const getInvoice = useQuery(["invoice",InvoiceRef.current], () => api.getInvoicebyAppID(InvoiceRef.current), {
  enabled : Boolean(InvoicegetRef.current),
  onSuccess: (data) => {
    console.log("invoice data", data);

    setInvoiceDetail(data[0]);
    setInvoiceDetail(invoicedetail => {
      return invoicedetail;
    });

    VATRATE.current = parseFloat(parseInt(data[0]?.VAT_RATE)/100);

    console.log("VAT RATE :::: ", VATRATE.current);

    if(data[0]?.INVOICE_DETAILS){
      console.log("INVOICE_DETAILS from DB parsed", JSON.parse(data[0]?.INVOICE_DETAILS));
     setInvoiceItems(JSON.parse(data[0]?.INVOICE_DETAILS));
     setInvoiceItems((invoiceItems) => { console.log("invoiceItems:", invoiceItems); return invoiceItems;});

     JSON.parse(data[0]?.INVOICE_DETAILS).forEach((invo,i) => {

      console.log(` ###### : ${invo[`amount_${i}`]}`);

     if(invo[`vat_${i}`] === "1") {

        totalCost.current += roundToTwo(parseInt(invo[`quantity_${i}`]) * parseFloat(invo[`amount_${i}`]) + parseInt(invo[`quantity_${i}`]) * parseFloat(invo[`amount_${i}`])*(parseFloat(parseInt(data[0]?.VAT_RATE)/100)));
        totalTax.current +=  roundToTwo(parseInt(invo[`quantity_${i}`])  * parseFloat(invo[`amount_${i}`]) * (parseFloat(parseInt(data[0]?.VAT_RATE)/100)));

        if(invo[`paid_${i}`] === "1") {
          paidAmount.current += roundToTwo(parseInt(invo[`quantity_${i}`]) * parseFloat(invo[`amount_${i}`]) + parseInt(invo[`quantity_${i}`]) * parseFloat(invo[`amount_${i}`])*(parseFloat(parseInt(data[0]?.VAT_RATE)/100)));
        }
     }
     else {

        totalCost.current += roundToTwo(parseInt(invo[`quantity_${i}`]) * parseFloat(invo[`amount_${i}`]), 2);

        if(invo[`paid_${i}`] === "1") {
          paidAmount.current += roundToTwo(parseInt(invo[`quantity_${i}`]) * parseFloat(invo[`amount_${i}`]),2); // + parseInt(invo[`quantity_${i}`]) * parseFloat(invo[`amount_${i}`])*(parseFloat(parseInt(data[0]?.VAT_RATE)/100)));
        }
     }
      
     console.log(" tamount", totalCost.current, "ttax", totalTax.current);
      /* for (let key in invo) {
        console.log(` ###### ${key}: ${invo[key]}`);
        
      } */
     
     });
   
    }

    console.log(" balance ", balance.current, "subtraction", roundToTwo(parseFloat(totalCost.current - paidAmount.current)));
    balance.current = roundToTwo(parseFloat(totalCost.current - paidAmount.current));

    InvoicegetRef.current = false;
  },
  onError: (error) => {
      
    console.log("Error Message",error); 
    InvoicegetRef.current = false;

    toast.error("Something went wrong",{
      duration: 6000,
      });

    if(error?.response?.status === 401){
      removeUserSession();
      navigate("/", {replace: true});
    }

  } 
})

const handleInvoiceViewShow = (e) => {
  setAppID(e.target.getAttribute("data-appid"));

    InvoicegetRef.current = true;
    InvoiceRef.current = e.target.getAttribute("data-appid");
    getInvoice.refetch();
  

  setInvoiceViewOpen(true);

  const list = [
    {
      billingitem_0: "",
      quantity_0: "",
      amount_0: "",
      vat_0: "",
      paid_0: "0",
      cap_0: false,
    },
  ]

  setInvoiceItems(list);

  setInvoiceItems((invoiceItems) => {
    console.log("updated invoiceItems view open", invoiceItems);
    return invoiceItems;
  });
}

const handleInvoiceViewClose = (e) => {
  setInvoiceViewOpen(false);

  totalCost.current = 0;
  totalTax.current = 0;
  paidAmount.current = 0;
  balance.current = 0;
  VATRATE.current = 0;

  const list = [
    {
      billingitem_0: "",
      quantity_0: "",
      amount_0: "",
      vat_0: "",
      paid_0: "0",
      cap_0: false,
    },
  ]

  setInvoiceItems(list);

  setInvoiceItems((invoiceItems) => {
    console.log("updated invoiceItems view close", invoiceItems);
    return invoiceItems;
  });
}

const handleInvoicePDF = (e) => { 

  console.log("APP ID",e.target.getAttribute("data-appid"));

  const appid = e.target.getAttribute("data-appid");
  
  if(appid !== ""){
    genInvoice.mutate({
          'APPTYPE_ID': appid,
          'INVOICE_NUMBER': invoicedetail.INVOICE_NUMBER,
          'CLIENT_ID': userData?.id,
         // 'InvoiceItems': JSON.stringify(invoiceItems),
          //'UserID': userData?.id,
        });
  }
  else {
    toast.error("Invalid Invoice Data",{
      duration: 6000,
      });

      console.log("Invoice details", invoiceItems, " appid", appid);

  }

}

const genInvoice = useMutation('invoice_pdf_gen', api.genInvoicePDF, {
  onSuccess: (data) => {
      console.log("data", data);

      toast.success("Invoice pdf generated Successfully",{
        duration: 6000,
        });

      const dt = [{
        billingitem_0: "",
        quantity_0: "",
        amount_0: "",
        vat_0: "",
        paid_0: "0",
        cap_0: false,
      },];

      setInvoiceItems(dt);
      setInvoiceItems(invoiceItems => {
        return invoiceItems;
      }); 

      let fileUrl = data?.url?.INVOICE; 
       let fileName = data?.filename;
       console.log(" fileName", fileName);
       let a = document.getElementById('invoicegen'); //document.createElement("a");
       a.setAttribute("id", "invoicegen");
 
      // a.href = fileUrl;
       a.setAttribute('href', fileUrl);
       a.setAttribute('target', '_blank');
       a.setAttribute("download", fileName);
       a.click(); 
       //document.body.removeChild(a);

       //setInvoiceOpen(false);
       setInvoiceViewOpen(false);
  },
  onError: (error) => {
      
    console.log("Error Message",error); 
    InvoicegetRef.current = false;

    toast.error("Something went wrong",{
      duration: 6000,
      });

    if(error?.response?.status === 401){
      removeUserSession();
      navigate("/", {replace: true});
    }

  } 
});

/////////////////////////////////////////////////////////////////////

  return (
    <Fragment>
      <Toaster />
      <div className="normalheader" id="tpanel">
        <div className="hpanel">
          <div className="panel-body">
            <a className="small-header-action" id="toglepanel" href={void(0)}>
              <div className="clip-header">
                <i className="fa fa-arrow-up"></i>
              </div>
            </a>

            <div id="hbreadcrumb" className="pull-right m-t-lg">
              <ol className="hbreadcrumb breadcrumb">
                <li>
                  <a href={void(0)}>i-visas</a>
                </li>
                <li>
                  <span>Client</span>
                </li>
                <li className="active">
                  <span>Applications </span>
                </li>
              </ol>
            </div>
            <h2 className="font-light m-b-xs">Applications Listing</h2>
            <small>
              <strong>Manage</strong> all <strong>Applications</strong> under your profile
            </small>
          </div>
        </div>
      </div>

      <div className="content">
        <div className="row">
         
          <div className="col-md-12">
            <div className="hpanel email-compose">
             
                <>
                  <div className="panel-heading hbuilt">
                    <div className="text-center p-xs font-normal">
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={searchApplication}
                          value={searchquery || ""}
                          className="form-control input-sm"
                          placeholder="Search by APP ID, First Name, and Last Name..."
                        />
                        <span className="input-group-btn">
                          <button
                            type="button"
                            disabled
                            className="btn btn-sm btn-default">
                            <i className="fa fa-search"></i> 
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="panel-body">
                    {searchLoading ? (
                       <>
                       <div className="row text-center">
                       <div className="col-md-6 m-b-md">
                        <button type="button" className="btn btnprime btn-sm" disabled >Create Application</button>
                      </div>
                           <div className="col-md-6 mailbox-pagination m-b-md">
                             <span style={{ fontSize: "1.1rem" }}>
                             Showing 0 of 0 pages   &nbsp; &nbsp;
                             </span>
                             <div className="btn-group">
                               <button className="btn btn-default btn-sm previous disabled"
                              
                               disabled
                               >
                                 <i className="fa fa-arrow-left"></i>
                               </button>
                               {getPaginationGroup().map((item, index) => (
                               <button 
                               key={index}
                               className="btn btn-default btn-sm disabled"
                               >
                               {item}
                               </button>
                               ))}
                               <button className="btn btn-default btn-sm next disabled"
                               disabled
                               >
                                 <i className="fa fa-arrow-right"></i>
                               </button>
                             </div>
                           </div>
                       </div>
                         <div className="table-responsive" style={{overflowX: "inherit"}}>
                         <table className="table table-hover table-mailbox apptable">
                           <thead style={{borderBottom: "solid 2px #64d246"}}>
                           <tr>
                            <th style={{width: "100px"}} className="text-center">APP ID</th>
                            <th className="text-center">CLIENT NAME</th>
                            <th className="text-center">LOCATION</th>
                            <th className="text-center">APP STATUS</th>
                            <th className="text-center">CREATED DATE</th>
                            <th className="text-center">ACTION</th>
                          </tr>
                             </thead>
                             <tbody>
                             <tr className="active"><td colSpan="6" style={{textAlign:"center"}}>Searching...</td></tr>
                         </tbody>
                       </table>
                         </div>
                         </>
                    ):(
                      <>
                    <div className="row">
                      <div className="col-md-6 m-b-md">
                        <button type="button" className="btn btnprime btn-sm" onClick={() => createApp(userData?.id)} disabled={(getPrescreen === 3) && (appdata?.filter(app => app?.APPSTATUS === 5).length === appdata?.length) ? false : true}>Create Application</button>
                      </div>
                      <div className="col-md-6 mailbox-pagination m-b-md">
                        <span style={{ fontSize: "1.1rem" }}>
                        {!!appdata && (Object.keys(appdata).length > 0) ? `Showing ${currentPage} of ${pages.current} pages` : "Showing 0 pages"}   &nbsp; &nbsp;
                        </span>
                        <div className="btn-group">
                          <button className={`btn btn-default btn-sm previous ${currentPage === 1 ? 'disabled' : ''}`}
                          onClick={goToPreviousPage}
                          disabled={!!appdata && (currentPage === 1) ? true : false} 
                          >
                            <i className="fa fa-arrow-left"></i>
                          </button>
                          {getPaginationGroup().map((item, index) => (
                          <button 
                          key={index}
                          onClick={changePage}
                          className={`btn btn-default btn-sm  ${currentPage === item ? 'active' : null}`}
                          >
                           {item}
                          </button>
                          ))}
                          <button className={`btn btn-default btn-sm next ${currentPage === pages.current ? 'disabled' : ''}`}
                          onClick={goToNextPage} 
                          disabled={!!appdata && (currentPage === pages.current) ? true : false} 
                          >
                            <i className="fa fa-arrow-right"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="table-responsive" style={{overflowX: "inherit"}}>
                      <table className="table table-hover table-mailbox apptable">
                      <thead style={{borderBottom: "solid 2px #64d246"}}>
                          <tr>
                            <th style={{width: "100px"}} className="text-center">APP ID</th>
                            <th className="text-center">CLIENT NAME</th>
                            <th className="text-center">LOCATION</th>
                            <th className="text-center">APP STATUS</th>
                            <th className="text-center">CREATED DATE</th>
                            <th className="text-center">ACTION</th>
                          </tr>
                        </thead>
                        <tbody>

                        {!!appdata && (Object.keys(appdata).length > 0) ? 
                            
                            getPaginatedData().map((item,i) =>{
                                         return (
                          <tr className={item.MESSAGE_STATUS === "unread" ? "unread active" : ""} key={i} style={{borderBottom:"solid 1px #e8ebef"}}>
                            <td className="text-center" style={{width: "100px"}} >
                            <a
                                href={void(0)}
                                onClick={() => viewApp(item.APPTYPE_ID)}>
                                <span>{item.APPTYPE_ID}</span>
                              </a>
                                </td>
                            <td className="text-center">
                              <a
                                href={void(0)}
                                onClick={() => viewApp(item.APPTYPE_ID)}>
                                {item.FIRSTNAME} {item.MIDDLENAME} {item.LASTNAME}
                              </a>
                            </td>
                            <td className="text-center">
                              <a
                                href={void(0)}
                                onClick={() => viewApp(item.APPTYPE_ID)}>
                                  {item.LOCATION ?? "LONDON"}
                              </a>
                            </td>
                            <td className="text-center">
                              <a
                                href={void(0)}
                                onClick={() => viewApp(item.APPTYPE_ID)}>
                                     {TagIcon(item.APPSTATUS)} 
                               {/* {item.APPSTATUS === 2 ? " UNDER REVIEW" : " DRAFT"} */}
                              </a>
                            </td>
                          
                            <td className="text-center mail-date">
                            {appdate(item?.created_at)}
                            </td>

                            <td className="text-center">
                                <div className="btn-group">
                                    <button data-toggle="dropdown" className="btn btn-default dropdown-toggle">Action <span className="caret"></span></button>
                                    <ul className="dropdown-menu">
                                        <li> <a href={void(0)} data-appid={item.APPTYPE_ID} onClick={handleUploadShow}> Upload Docs </a> </li>
                                        <li><Link to={`/dashboard/view_application/${item.APPTYPE_ID}`} >View</Link></li>
                                        {(item.APPSTATUS === 0 || item.APPSTATUS === 1) &&
                                        <li><Link to={`/dashboard/edit_application/${item.APPTYPE_ID}`} >Edit</Link></li>
                                        }
                                        <li><Link to={`/dashboard/pdf_application/${item.APPTYPE_ID}`} target="_blank" >Generate Pdf</Link></li>
                                        <li className="divider"></li>
                                        <li> <a href={void(0)} data-appid={item.APPTYPE_ID} onClick={handleTrackerShow}> Add Tracking </a> </li>
                                        {(item.INVOICE_NUMBER) &&
                                       <>
                                        <li className="divider"></li>
                                       <li> <a href={void(0)} data-appid={item.APPTYPE_ID} data-type="view" onClick={handleInvoiceViewShow}> View Invoice </a> </li>
                                       </>
                                       }
                                    </ul>
                                </div>
                            </td>
                        </tr>
                                         )
                                     }) : (
                                      <tr className="unread active"><td colSpan="6" style={{textAlign:"center"}}>No application found under {userData?.first_name?.toUpperCase()} {userData?.last_name?.toUpperCase()}</td></tr>
                                    )}

                        </tbody>
                      </table>
                    </div>
                    </>
                      )}
                  </div>
                
                  <div className="panel-footer">
                   {!!appdata && (Object.keys(appdata).length > 0) ? appdata.length : 0}  Applications
                  </div>
                </>
                
            </div>
          </div>
        </div>
     

        <Modal show={trackeropen} onHide={handleTrackerClose} animation={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        >
           <div className="color-line"></div>
        <Modal.Header className="modal-header text-center" style={{padding: "10px 10px"}}>
          <Modal.Title>Add Tracking</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{padding: "5px"}}>
         
        <div className="panel-body no-padding">
        <div className="row">
                  <div className="col-lg-12">

                        <div className="row col-bb">
                          
                        <div className="form-group col-lg-3">
                              <label className="label-clr">
                                Direction
                              </label>
                              <select
                                name="direction"
                               {...register("direction", { 
                                          required: { value: true, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                value={details.direction || ""} // Prop: The email input data
                               
                                className="form-select m-b-3"
                                style={{ width: "100%" }}>
                                <option value=''>select direction</option>
                                <option value="send">Send</option>
                                <option value="receive">Receive</option>
                              </select>
                              {errors.direction && (
                                  <p className="errorValidate">{errors.direction.message}</p>
                                  )}
                            </div>

                            <div className="form-group col-lg-3">
                              <label className="label-clr">
                                Courier
                              </label>
                              <select
                                name="courier"
                               {...register("courier", { 
                                          required: { value: true, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                value={details.courier || ""} // Prop: The email input data
                               
                                className="form-select m-b-3"
                                style={{ width: "100%" }}>
                                <option value=''>select courier</option>
                                {courier?.map(cour => 
                                (
                                  <option key={cour.RECID} value={cour.RECID}>{cour.TRACK_CODE}</option>
                                ))

                                }
                               {/*  <option value="1">DHL</option>
                                <option value="2">UPS</option>
                                <option value="3">EMS</option>
                                <option value="4">FedEx</option>
                                <option value="5">TNT Express</option> */}
                              </select>
                              {errors.courier && (
                                  <p className="errorValidate">{errors.courier.message}</p>
                                  )}
                            </div>

                            <div className="form-group col-lg-3">
                                <label className="label-clr">
                                  Tracking ID
                                </label>
                                <input
                                  type="text"
                                  {...register("trackingid", { 
                                    required: { value: true, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                  value={details.trackingid || ""}
                                  className="form-control"
                                  name="trackingid"
                                />
                                {errors.trackingid && (
                                  <p className="errorValidate">{errors.trackingid.message}</p>
                                  )}
                              </div>

                              <div className="form-group col-lg-3">
                                  <label className="label-clr">
                                    Tracking Date
                                  </label>
                                  <input
                                    value={details.tracking_date || ""}
                                    name="tracking_date"
                                    className="form-control"
                                    min={getTodaysDate()}
                                    type="date"
                                    {...register('tracking_date', { required: { value: true, message: "Tracking Date is required" }, onChange: (e) => {handleChange(e)} })}
                                  />
                                  {errors.tracking_date && (
                                  <p className="errorValidate">{errors.tracking_date.message}</p>
                                  )}
                                </div>

                        </div>

                        <div className="row">
                          
                        <div className="form-group col-lg-12">
                        <label className="list-group-item-heading label-clr" style={{fontSize: "13px"}}>
                                   Note
                                  </label>
                                  <div className="form-outline">
                                    <textarea
                                      className="form-control txtareabb"
                                      id="textArea"
                                      name="note"
                                      value={details.note || ""}
                                      maxLength="100"
                                      {...register('note', { required: { value: false}, onChange: (e) => {handleChange(e)} })}
                                      rows="2"></textarea>
                                  </div>
                                  {errors.note && (
                                    <span className="errorValidate">This field is required</span>
                                  )}
                        </div>

                        </div>
                  </div>
              </div>
        </div>
        </Modal.Body>
        <Modal.Footer>
         
           <div className="text-right">
           { addTracking.isLoading ? (
             <>
                <span className="btn">
                  <i className="fa fa-paper-plane" style={{color: "#5ac557"}}></i>
                  &nbsp; Saving...
                  <span className="lds-facebook"><span></span><span></span><span></span></span>
                </span>
                &nbsp; &nbsp; &nbsp; &nbsp;
                </>
                    ) : (
                <button type="button"
                  className="btn btn-success btn-sm"
                  
                  data-appid={appID}
                  onClick={handleTracker}
                  >
                  <i className="fa fa-check-square-o"></i> Add Tracker
                </button>
            )}
              <button
                type="button"
                className="btn btn-default"
                onClick={handleTrackerClose}>
                <i className="fa fa-trash"></i>  Close
              </button>
          </div>
        </Modal.Footer>
      </Modal>

      
      <Modal show={uploadopen} onHide={handleUploadClose} animation={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        >
           <div className="color-line"></div>
        <Modal.Header className="modal-header text-center" style={{padding: "10px 10px"}}>
          <Modal.Title>Upload File</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{padding: "5px"}}>
        <p style={{fontSize: "15px", textAlign:"center"}}>
                  Application with ID : <span className="font-bold" ><strong>{appID}</strong></span>
                </p>
        <div className="row">
                  <div className="col-lg-12">

                        <div className="row col-bb">
                          
                        <div className="form-group col-lg-4">
                              <label className="label-clr">
                                File Type
                              </label>
                              <select
                                name="filetype"
                               {...uploadRegister("filetype", { 
                                          required: { value: true, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                value={filetype || ""} // Prop: The email input data
                               
                                className="form-select m-b-3"
                                style={{ width: "100%" }}>
                                <option value=''>select file type</option>
                                <option value="PASSPORT_UPLOAD">Passport</option>
                                <option value="DEPENDENT_PASSPORT_UPLOAD">Dependent Passport</option>
                                <option value="UTILITY_BILL_UPLOAD">Utility Bill</option>
                                <option value="BRP_UPLOAD">BRP</option>
                                <option value="PREVIOUS_VISA_UPLOAD">Previous VISA</option>
                                <option value="REFUSAL_LETTER_UPLOAD">Refusal Letter</option>
                               
                                <option value="ENGLISH_CERT_UPLOAD">English Certificate</option>
                                <option value="MARRIAGE_CERT_UPLOAD">Marriage Certificate</option>
                                <option value="BANK_STATEMENT_UPLOAD">Bank Statement</option>
                                <option value="MOTIVATIONAL_LETTER_UPLOAD">Motivational Letter</option>

                                <option value="RESUME_UPLOAD">Resume/Curriculum Vitae (CV)</option>
                                <option value="DEGREE_CERTIFICATE_UPLOAD">Degree Certificates</option>
                                <option value="ACADEMIC_TRANSCRIPTS_UPLOAD">Academic Transcripts</option>
                                <option value="CAS_LETTERS_UPLOAD">Previous CAS Letters</option>
                                <option value="RECOMMENDATION_LETTERS_UPLOAD">Recommendation letters</option>
                                <option value="RESEARCH_PROPOSAL_UPLOAD">Research proposal</option>
                                
                                <option value="EDUCATIONAL_CERT_UPLOAD">Other Qualification Certificates </option>
                                <option value="OTHER_UPLOAD">Other</option>
                              </select>
                              {uploaderrors.filetype && (
                                  <p className="errorValidate">{uploaderrors.filetype.message}</p>
                                  )}
                            </div>

                            

                            <div className="form-group col-lg-8">
                                <label className="label-clr">
                                  Load File
                                </label>
                                <input
                                  type="file"
                                  key={InputKey || '' }
                                  {...uploadRegister("loadfile", { 
                                    required: { value: true, message: "This field is required" }, 
                                    onChange: (e) => {handleChange(e)} })}
                                  className="form-control"
                                  name="loadfile"
                                />
                                <span className="text-center"
                                style={{olor: "green", fontStyle: "italic"}}
                                >
                                  Allowed file types: pdf, docx, doc, jpeg, jpg, png, txt
                                </span>
                                 {uploaderrors.loadfile && (
                                  <p className="errorValidate">{uploaderrors.loadfile.message}</p>
                                  )}
                              </div>

                        </div>

                        <div className="row col-bb">
                          <div className="form-group col-lg-12">
                                <div className="progress m-t-xs full progress-small" style={{border: "solid 1px #2f343936"}}>
                                    <div style={{width: fileUploadMutate.isIdle ? "0%" : fileUploadMutate.isLoading ? "65%" : fileUploadMutate.isSuccess ? "100%" : "70%"}} 
                                    aria-valuemax="100" 
                                    aria-valuemin="0" 
                                    aria-valuenow="45" 
                                    role="progressbar" 
                                    className={`progress-bar ${ fileUploadMutate.isIdle && "progress-bar-info"} ${ fileUploadMutate.isSuccess && "progress-bar-success"} ${ fileUploadMutate.isError && "progress-bar-danger"} `}>
                                    </div>
                                </div>
                          </div>
                        </div>
                  </div>
              </div>
        </Modal.Body>
        <Modal.Footer>
         
           <div className="text-right">
           {fileUploadMutate.isLoading ? (
             <>
                <span className="btn">
                  <i className="fa fa-hourglass" style={{color: "#5ac557"}}></i>
                  &nbsp; Uploading...
                  <span className="lds-facebook"><span></span><span></span><span></span></span>
                </span>
                &nbsp; &nbsp; &nbsp; &nbsp;
                </>
                    ) : (
                <button type="button"
                  className="btn btn-success btn-sm"
                  
                  data-appid={appID}
                  onClick={handleUpload}
                  >
                  <i className="fa fa-upload"></i> Upload
                </button>
            )}
              <button
                type="button"
                className="btn btn-default"
                onClick={handleUploadClose}>
                <i className="fa fa-trash"></i>  Close
              </button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* =================================================View Invoice=================================================================== */}


<Modal show={invoiceviewopen} onHide={handleInvoiceViewClose} animation={false}
        dialogClassName="modal-85w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        >
           <div className="color-line"></div>
        <Modal.Header className="modal-header text-center" style={{padding: "10px 10px"}}>
          <Modal.Title>Invoice Status</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{padding: "5px"}}>
        <div className="panel-body no-padding">
        <div className="row">
            <div className="col-lg-12">

            <div className="panel-body">
                    <div className="row">
                        <div className="col-md-2">
                            <h4>Invoice # <small>{invoicedetail.INVOICE_NUMBER}</small></h4>
                        </div>

                        <div className="col-md-8">
                            <div className="text-center">
                            <h4>{invoicedetail?.first_name ?? ""} {invoicedetail?.middle_name ?? ""} {invoicedetail?.last_name ?? ""}</h4>
                            </div>

                        </div>

                        <div className="col-md-2">
                            <div className="text-right">
                                <button className={`btn ${invoicedetail.PAYMENT_STATUS === null ? `btn-default` : `btn-primary`} btn-sm`}><i className="fa fa-tags"></i> {invoicedetail.PAYMENT_STATUS === null ? " UNPAID" : " PAID"}</button>
                            </div>

                        </div>
                    </div>
                </div>
            
            <div className="row" style={{marginTop:"10px"}}>
                     <div className="form-group col-lg-2 text-center">
                      <label className="label-clr">
                         ITEM(s)
                       </label>
                     </div>

                     <div className="form-group col-lg-2 text-center">
                      <label className="label-clr">
                         QUANTITY
                       </label>
                     </div>

                     <div className="form-group col-lg-2 text-center">
                      <label className="label-clr">
                         UNIT PRICE
                       </label>
                     </div>

                     <div className="form-group col-lg-2 text-center">
                      <label className="label-clr">
                         TAX
                       </label>
                     </div>

                     <div className="form-group col-lg-2 text-center">
                      <label className="label-clr">
                         SUBTOTAL
                       </label>
                     </div>

                     <div className="form-group col-lg-2 text-center">
                      <label className="label-clr">
                         PAYMENT STATUS :
                       </label>
                     </div>
                  </div>

            
            {  invoiceItems.map((x, i) => {
 
                return (
                  <Fragment key={i}>
                  
                  <div className={`row ${ x[`paid_${i}`] === "1" ? `invoiceRowpaid` : `invoiceRow`}`}>
                     <div className="form-group col-lg-2 text-center" style={{marginBottom: '7px', marginTop: '7px'}}>
                       <span>
                       {allbills.find(bills => bills.id == x[`billingitem_${i}`])?.DESCRIPTION}
                       </span>
                     </div>

                     <div className="form-group col-lg-2 text-center">
                       <span>
                       {x[`quantity_${i}`] || ""}
                       </span>
                     </div>

                     <div className="form-group col-lg-2 text-center">
                       <span>
                       {x[`amount_${i}`] || ""}
                       </span>
                     </div>

                     <div className="form-group col-lg-2 text-center">
                       <span>
                       {x[`vat_${i}`] === "1" ? roundToTwo(parseFloat(x[`amount_${i}`] || 0)* parseInt(x[`quantity_${i}`] || 0)*(parseFloat(invoicedetail?.VAT_RATE || 0.00)/100)) : 0.00 }
                       </span>
                     </div>

                     <div className="form-group col-lg-2 text-center">
                       <span>
                       {x[`vat_${i}`] === "1" ?  roundToTwo(parseInt(x[`quantity_${i}`]) * parseFloat(x[`amount_${i}`]) + parseInt(x[`quantity_${i}`]) * parseFloat(x[`amount_${i}`])*(parseFloat(invoicedetail?.VAT_RATE || 0.00)/100)) : roundToTwo(parseInt(x[`quantity_${i}`]) * parseFloat(x[`amount_${i}`]))} 
                       </span>
                     </div>
                    
                     <div className="form-group col-lg-2 text-center">
                       {x[`paid_${i}`] === "1" ? <span style={{color:"#30a530"}}><strong>PAID</strong></span> : "UNPAID"} 
                     </div>
                  </div>

                  <hr className="slash-3"/>
         </Fragment>
     );
   })
  
  }

   <div className="row" style={{marginTop:"10px"}}>
          <div className="form-group col-lg-2 text-center"></div>
          <div className="form-group col-lg-2 text-center"></div>
          <div className="form-group col-lg-2 text-center"></div>
          <div className="form-group col-lg-2 text-center totallsmetric"><strong>Total Taxes</strong></div>
          <div className="form-group col-lg-2 text-center totallsmetricval"><span className="totalls">	&#163; {roundToTwo(totalTax.current)}</span></div>  
          <div className="form-group col-lg-2 text-center"></div>            
   </div>

   <div className="row" style={{marginTop:"10px"}}>
          <div className="form-group col-lg-2 text-center"></div>
          <div className="form-group col-lg-2 text-center"></div>
          <div className="form-group col-lg-2 text-center"></div>
          <div className="form-group col-lg-2 text-center totallsmetric"><strong>Total Cost</strong></div>
          <div className="form-group col-lg-2 text-center totallsmetricval"><span className="totalls">	&#163; {roundToTwo(totalCost.current)}</span></div>   
          <div className="form-group col-lg-2 text-center"></div>           
   </div>

   <div className="row" style={{marginTop:"10px"}}>
          <div className="form-group col-lg-2 text-center"></div>
          <div className="form-group col-lg-2 text-center"></div>
          <div className="form-group col-lg-2 text-center"></div>
          <div className="form-group col-lg-2 text-center totallsmetric"><strong>Paid Amount</strong></div>
          <div className="form-group col-lg-2 text-center totallsmetricval"><span className="totallpaid">	&#163; {roundToTwo(paidAmount.current)}</span></div>    
          <div className="form-group col-lg-2 text-center"></div>          
   </div>

   <div className="row" style={{marginTop:"10px"}}>
          <div className="form-group col-lg-2 text-center"></div>
          <div className="form-group col-lg-2 text-center"></div>
          <div className="form-group col-lg-2 text-center"></div>
          <div className="form-group col-lg-2 text-center totallsmetric"><strong>Outstanding Balance</strong></div>
          <div className="form-group col-lg-2 text-center totallsmetricval"><span className="outstanding">	&#163; {roundToTwo(balance.current)}</span></div>    
          <div className="form-group col-lg-2 text-center"></div>          
   </div>

              </div>
            </div> 
        </div>            


        </Modal.Body>
        <Modal.Footer>
         
           <div className="text-right">
            {genInvoice.isLoading ? (
              <>
                  <span className="btn">
                    <i className="fa fa-paper-plane" style={{color: "#5ac557"}}></i>
                    &nbsp; Generating Invoice PDF...
                    <span className="lds-facebook"><span></span><span></span><span></span></span>
                  </span>
                  &nbsp; &nbsp; &nbsp; &nbsp;
                  </>
                      ) : (
                  <button type="button"
                    className="btn btn-success btn-sm"
                    
                    data-appid={appID}
                    onClick={handleInvoicePDF}
                    >
                    <i className="fa fa-file-pdf-o"></i> Generating Invoice PDF
                  </button>
              )}
              <button
                type="button"
                className="btn btn-default"
                onClick={handleInvoiceViewClose}>
                <i className="fa fa-trash"></i>  Discard
              </button>
          </div>
        </Modal.Footer>
      </Modal>

      <a href={void(0)} id="invoicegen" style={{display:"none"}}></a>
        </div>

    </Fragment>
  );
};
export default ApplicationList; ///withRouter(Master);
