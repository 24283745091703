import React, { useState, useEffect, Fragment, useRef } from "react";
import { useQuery, useMutation } from "react-query";
import toast, { Toaster } from 'react-hot-toast';
import { useForm } from "react-hook-form";
import { getUser, removeUserSession, UpdatePrescreenStatus, sleep } from "./Utils/Common";
import * as api from './Utils/apiCall';
import "./Overview.css";
import { useNavigate, Link } from "react-router-dom";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import moment from "moment";

const Overview = () => {

  const [countries, setCountries] = useState([]);
  const [userData, setUserData] = useState({});

  const elapsedTime = useRef();

const [elapsedtime, setElapsedtime] = useState("");

  const [details, setDetails] = useState({
    how_we_can_help_you_question: "",
    how_we_can_help_you: "",
    fullname: "",
    client_email: "",
    client_phone: "",
    country_of_residence: "",
    residency_question: "",
    type_of_permission: "",
    expiry: "",
    dob: "",
    english_proficiency: "",
    english_proficiency_level: "",
    other_languages: "",
    visa_refusal: "",
    visa_refusal_reason: "",
    in_your_own_words: ""
  });

  const [errorls, setErrorls] = useState([]);

  const submitMutation = useMutation(api.submitPrescreen, {
    onSuccess: (data) => {
      console.log("onSuccess data",data, "id: ", data.id);
      //dataprescreenfetch();
      sleep(2000).then(() => {
        dataprescreenfetch();
          toast.success('Input Submitted Successfully!',{
            duration: 6000,
          });
  
        // do what you want
        UpdatePrescreenStatus(2);
      const userdatalist = {...userData};
      userdatalist.prescreened_status = 2;
      setUserData(userdatalist);
      setUserData((userData) => {
        //console.log("updated details", userData);
        return userData;
      });

      clientMessages.refetch();

      });
      

    },
    onError: (error) => {
      
      const errObject = error.response.data?.errors

      console.log("errObject",errObject); 

        if(error?.response?.status === 401){
          toast.error("Session expired: Kindly re-login",{
            duration: 6000,
            });
          removeUserSession();
          navigate("/", {replace: true});
        }
        else {
          toast.error("Something went wrong :: submission",{
            duration: 6000,
            });
        }

    }
  });

  const saveDraftMutation = useMutation(api.savePrescreen, {
    onSuccess: (data) => {

       console.log("prescreenStatus data:: ",data);

        const timeLapsed = "Saved " + moment(elapsedTime.current).fromNow();

        setElapsedtime(timeLapsed);
       
         // do what you want
         UpdatePrescreenStatus(1);

      sleep(1000).then(() => {
        dataprescreenfetch();
        toast.success('Input Saved Successfully!',{
          duration: 6000,
        });

      }); 
      

    },
    oonError: (error) => {
      
      const errObject = error.response.data?.errors

      console.log("errObject",errObject); 

        if(error?.response?.status === 401){
          toast.error("Session expired: Kindly re-login",{
            duration: 6000,
            });
          removeUserSession();
          navigate("/", {replace: true});
        }
        else {
          toast.error("Something went wrong :: saveDraft",{
            duration: 6000,
            });
        }

    }
  });

 
  const maildate = (datestr, l=0) => {
    /* const start = moment(datestr).format(
      "dddd, MMMM Do YYYY, h:mm:ss a"
    );
    return start; */
    if(l === 0){
      const start = moment(datestr).format("dddd, MMMM Do YYYY, h:mm:ss a");
      return start;
    }
    else if(l === 1){
      const start = moment(datestr).format("llll");
      return start;
    }
  };

  useEffect(() => {
    document
      .getElementById("toglepanel")
      .addEventListener("click", togglePanel);

      const data = require('./Utils/CountryData.json')
        console.log(data)
        setCountries(data);

      async function checkUserData() {
       const item = await getUser();
       console.log("user item :::::::::::::::::::::::::",item);
        
        if (item) {
         // console.log("user item",item);
          setUserData(item);
  
          setUserData((userData) => {
            console.log("updated userData :::::::::::::::::::::::::", userData);
            return userData;
          });

         // if(userData){
            const userDetails = {...details};
            userDetails.fullname = userData?.first_name + " " + userData?.middle_name + " " + userData?.last_name;
            userDetails.client_email = userData?.email;
            userDetails.client_phone = userData?.phone;
            userDetails.country_of_residence = userData?.country;
            setDetails(userDetails);

            setDetails((details) => {
              console.log("updated details in useEfect", details);
              return details;
            });
        //  }
  
        } 
      }
      checkUserData();
  
      window.addEventListener('storage', checkUserData)
    
      return () => {
        window.removeEventListener('storage', checkUserData)
      }

  }, []);


  const clientApplications = useQuery(['clientapplications', userData?.id], async () => { return await api.getClientApplications(userData?.id)}, {
    onSuccess: (data) => {
      console.log ("clientApplications", data)
    },
    onError: (error) => {
      
      const errObject = error.response.data?.errors

      console.log("errObject",errObject); 

        if(error?.response?.status === 401){
          toast.error("Session expired: Kindly re-login",{
            duration: 6000,
            });
          removeUserSession();
          navigate("/", {replace: true});
        }
        else {
          toast.error("Something went wrong :: clientApplications",{
            duration: 6000,
            });
        }

    }
  });

  const clientMessages = useQuery(['clientmessages', userData?.id], async () => {return await api.getCLientMessages(userData?.id)},
  { onSuccess: (data) => {
   
      console.log("clientMessages data:: ",data);
    },
    onError: (error) => {
      
      const errObject = error.response.data?.errors

      console.log("errObject",errObject); 

        if(error?.response?.status === 401){
          toast.error("Session expired: Kindly re-login",{
            duration: 6000,
            });
          removeUserSession();
          navigate("/", {replace: true});
        }
        else {
          toast.error("Something went wrong :: clientMessages",{
            duration: 6000,
            });
        }

    }
  });
   
  const { loading: loadingapp, data: dataapp } = clientApplications;
  const { loading: loadingmsg, data: datamsg } = clientMessages;

  const [pscreninfo, setPscreninfo] = useState([]);
  const prescreenStatus = useQuery(['prescreenStatus', userData?.id], () => api.getPrescreenByClientID(userData?.id), {
    onSuccess: (data) => {
        console.log("prescreenStatus data:: ",data);

        setPscreninfo([...data]);

        setPscreninfo(pscreninfo => {
          return pscreninfo;
        });

        const newDetail = {...details};

        newDetail.client_email = data[0]?.client_email || "";
        newDetail.fullname =  data[0]?.client_first_name + " " + (data[0]?.client_middle_name ?? "") +  " " + data[0]?.client_last_name;
        newDetail.client_phone =  data[0]?.client_phone || "";
        newDetail.country_of_residence =  data[0]?.country_of_residence || "";
        newDetail.dob =  data[0]?.dob || "";
        newDetail.english_proficiency =  data[0]?.english_proficiency || "";
        newDetail.english_proficiency_level =  data[0]?.english_proficiency_level || "";
        newDetail.expiry =  data[0]?.expiry || "";
        newDetail.how_we_can_help_you =  data[0]?.how_we_can_help_you || "";
        newDetail.how_we_can_help_you_question =  data[0]?.how_we_can_help_you_question || "";
        newDetail.in_your_own_words =  data[0]?.in_your_own_words || "";
        newDetail.other_languages =  data[0]?.other_languages || "";
        newDetail.residency_question =  data[0]?.residency_question || "";
        newDetail.visa_refusal =  data[0]?.visa_refusal || "";
        newDetail.visa_refusal_reason =  data[0]?.visa_refusal_reason || "";
        newDetail.type_of_permission = data[0]?.type_of_permission || "";

        setDetails(newDetail);

        setDetails((details) => {
          console.log("details after prescreenstatus query:", details);
          return details;
        })
    },

    onError: (error) => {
      
      const errObject = error.response.data?.errors

      console.log("errObject",errObject); 

        if(error?.response?.status === 401){
          toast.error("Session expired: Kindly re-login",{
            duration: 6000,
            });
          removeUserSession();
          navigate("/", {replace: true});
        }
        else {
          toast.error("Something went wrong :: prescreenStatus",{
            duration: 6000,
            });
        }

    }
  });
  const { loading: loadingprescreen, data: dataaprescreen, refetch:dataprescreenfetch } = prescreenStatus;

  console.log("dataaprescreen",dataaprescreen);
  
  //console.log("appdata",dataapp); 

  //const { data: messagedata, isLoading : isLoadingmsg, isError : isErrormsg, error : errormsg } = useQuery(['clientmessages', userData?.id], () => api.getCLientMessages(userData?.id));

  //console.log("messagedata",datamsg);

  //console.log("userdata: ",userData); 

  const navigate = useNavigate();
  const {
    register,
    trigger,
    formState: { errors },
  } = useForm({mode: "onBlur"});

  const [wizardstep, setWizardstep] = useState(1);

  const togglePanel = () => {
    const hpanel = document.getElementById("tpanel");

    if (hpanel.classList.contains("small-header")) {
      hpanel.classList.remove("small-header");
    } else {
      hpanel.classList.add("small-header");
    }
  };

  const onSubmit = async () => {
    
    console.log("submitted details data", details);

    let formDetaild = {...details};

    formDetaild.fullname = userData?.first_name + " "+ userData?.middle_name + " " + userData?.last_name;
    formDetaild.client_email = userData?.email;
    formDetaild.client_phone = userData?.phone;
    formDetaild.id = userData?.id;


    console.log("formDetaild on send", formDetaild);
       
    const frmvalidate = await trigger();

    if (frmvalidate && formDetaild.id !== "") {

      setWizardstep(4);
      setWizardstep((wizardstep) => {
        //console.log(" + wizardstep value 4 :", wizardstep);
        return wizardstep;
      });
     
      //console.log("formDetaild validated : ", formDetaild);
      submitMutation.mutate(formDetaild);

    }
    else{
      console.log("formDetaild not validated : ", formDetaild);

      }
    
  };

  const saveDraft =  () => {
    
    console.log("saved draft details data", details);

    let formDetaild = {...details};

    formDetaild.fullname = userData?.first_name + " "+ userData?.middle_name + " " + userData?.last_name;
    formDetaild.client_email = userData?.email;
    formDetaild.client_phone = userData?.phone;
    formDetaild.id = userData?.id;
    formDetaild.country_of_residence = userData?.country;

    //console.log("formDetaild on send", formDetaild);
    elapsedTime.current = new Date();
      saveDraftMutation.mutate(formDetaild);
    
  };

  const AppStatus = (appstatus) => {
      switch(appstatus){
        case 1:
          return "Draft"
          break;
        case 2:
          return "Pending Review"
          break;
        case 3:
          return "Approved"
          break;
        case 4:
            return "Rejected"
            break;
        default:
          return "Initiated"
      }
  }

  const handleChange = (event) => {
    event.persist();
    const { name, value, type } = event.target;
    //console.log("name:" + name + "  value:" + value + "type" + type);
    
    const formstate = { ...details };
    formstate[name] = value;
    setDetails(formstate);

    setDetails((details) => {
      console.log("updated details", details);
      return details;
    });
   
  };

  const _next = async () => {
    let currentStep = wizardstep;
    // If the current step is 1 or 2, then add one on "next" button click
    currentStep = currentStep + 1;
    await trigger("country_of_residence");
    const formValidated = await trigger();
     if (formValidated) {
     // console.log("valid input");
    setWizardstep(currentStep);
    setWizardstep((wizardstep) => {
     // console.log(" + wizardstep value:", wizardstep);
      return wizardstep;
    });

     }
    ///setWizardstep(currentStep);
  };

  const _prev = () => {
    let currentStep = wizardstep;
    // If the current step is 2 or 3, then subtract one on "previous" button click
    currentStep = currentStep - 1;
    setWizardstep(currentStep);
    setWizardstep((wizardstep) => {
     // console.log(" -wizardstep value:", wizardstep);
      return wizardstep;
    });
  };

  const previousButton = () => {
    let currentStep = wizardstep;
    if (currentStep !== 1) {
      return (
        <button
          type="button"
          className="btn btn-default prev wizard-nav-btn"
          onClick={_prev}
          disabled={false}>
          &lt;&lt; Previous
        </button>
      );
    }
    return null;
  };

  const nextButton = () => {
    let currentStep = wizardstep;

    if (currentStep < 4) {
      return (
        <button
          type="button"
          className="btn btn-default next wizard-nav-btn"
          onClick={_next}
          disabled={false}>
          Next &gt;&gt;
        </button>
      );
    }
    return null;
  };

  const gotoMessages = () => {
    navigate("/dashboard/messages", { replace: false });
  };

  const getTodaysDate = () => {
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth()+1; //January is 0 so need to add 1 to make it 1!
    let yyyy = today.getFullYear();
    if(dd<10){
      dd='0'+dd
    } 
    if(mm<10){
      mm='0'+mm
    } 

    today = yyyy+'-'+mm+'-'+dd;

    console.log("todays date",today);

    return today;
  }

  return (
    <Fragment>
      <Toaster />
      <div className="normalheader" id="tpanel">
        <div className="hpanel">
          <div className="panel-body">
            <a className="small-header-action" id="toglepanel" href={void(0)}>
              <div className="clip-header">
                <i className="fa fa-arrow-up"></i>
              </div>
            </a>

            <div id="hbreadcrumb" className="pull-right m-t-lg">
              <ol className="hbreadcrumb breadcrumb">
                <li>
                  <a href={void(0)}>i-visas</a>
                </li>
                <li>
                  <span>Client</span>
                </li>
                <li className="active">
                  <span>Overview </span>
                </li>
              </ol>
            </div>
            <h2 className="font-light m-b-xs">{`Welcome ${userData.first_name} ${userData.last_name}`}</h2>
            <small>Overview page shows your recent events</small>
          </div>
        </div>
      </div>

      <div className="content">
        <div className="row">
          <div className="col-lg-4">
            <div className="hpanel hgreen">
              <div className="panel-body">
             
                <div className="pull-right text-right">
                  <div className="btn-group">
                    <button
                      className="btn btn-success btn-xs btn-outline"
                      style={{fontSize: "12px"}}
                      onClick={gotoMessages}>
                      Send Message
                    </button>

                    {/* <button className="btn btn-default btn-xs">
                      Chat an Agent
                    </button>  */}
                  </div>
                </div>
                <i className="pe-7s-user fa-7x text-muted"></i>
                <h3>
                  <a href={void(0)}>{`${userData?.first_name} ${userData?.last_name}`}</a>
                </h3>

                { pscreninfo ? 
                  pscreninfo[0]?.prescreened_status === 0 ? (
                 <> 
                 <p>Onboarding process is 40% Complete.</p>
                 <hr className="slash-3"/>
                <p>
                 
                  Current Stage : <strong>Pre-screening</strong>
                </p>
                <hr className="slash-3"/>
                <p>
                  Kindly Complete the Pre-screening Questionnaire.
                </p>

               {/*  <div className="progress m-t-xs full progress-small">
                  <div
                    style={{ width: "40%", transition: "width 2s", direction: "ltr"}}
                    aria-valuemax="100"
                    aria-valuemin="0"
                    aria-valuenow={"40"}
                    role="progressbar"
                    className=" progress-bar progress-bar-success">
                    <span className="sr-only">{"40%"} Pending</span>
                  </div>
                </div> */}
                
                </>
                ) : pscreninfo[0]?.prescreened_status === 1 ? (
                  <>
                  <p>A draft of your Pre-screening Response has been saved.</p>
                  <hr className="slash-3"/>
                <p>
                 {/*  You are at <strong>Pre-Screening</strong> stage. */}
                  Current Stage : <strong>Pre-screening</strong>
                </p>
                <hr className="slash-3"/>
                  <p>
                  Kindly complete Pre-screening to proceed.
                </p>
               {/*  <div className="progress m-t-xs full progress-small">
                  <div
                    style={{ width: "45%", transition: "width 2s", direction: "ltr"}}
                    aria-valuemax="100"
                    aria-valuemin="0"
                    aria-valuenow={"45"}
                    role="progressbar"
                    className=" progress-bar progress-bar-success">
                    <span className="sr-only">{"45%"} Pending Submission </span>
                  </div>
                </div> */}
                </>
                ) : pscreninfo[0]?.prescreened_status === 2 ? (
                  <>
                  <p>Your Pre-screening Response is pending <strong>review</strong>.</p>
                  <hr className="slash-3"/>
                <p>
                 {/*  You are at <strong>Pre-Screening</strong> stage. */}
                  Current Stage : <strong>Pre-screening Review</strong>
                </p>
                <hr className="slash-3"/>
                {/* <div className="progress m-t-xs full progress-small">
                  <div
                    style={{ width: "75%", transition: "width 2s", direction: "ltr"}}
                    aria-valuemax="100"
                    aria-valuemin="0"
                    aria-valuenow={"75"}
                    role="progressbar"
                    className=" progress-bar progress-bar-success">
                    <span className="sr-only">{"75%"} Pending Review</span>
                  </div>
                </div> */}
                </>
                ): pscreninfo[0]?.prescreened_status === 3 && dataapp?.filter(app => app?.APPSTATUS === 5).length === 0 && dataapp?.length === 0 ? (
                  <><p>Your client profile is complete.</p>
                  <hr className="slash-3"/>
                  <p>
                   <strong>Pre-screening </strong> completed.
                </p>
                <hr className="slash-3"/>
                <p>Create <strong><Link to="/dashboard/application_list">Application</Link></strong></p>
                {/* <div className="progress m-t-xs full progress-small">
                  <div
                    style={{ width: "100%", transition: "width 2s", direction: "ltr"}}
                    aria-valuemax="100"
                    aria-valuemin="0"
                    aria-valuenow={"100"}
                    role="progressbar"
                    className=" progress-bar progress-bar-success">
                    <span className="sr-only">{"100%"} Pending Review</span>
                  </div>
                </div> */}
                </>
                ): pscreninfo[0]?.prescreened_status === 3 && dataapp?.filter(app => app?.APPSTATUS === 5).length > 0 && dataapp?.filter(app => app?.APPSTATUS === 5).length === dataapp?.length ? (
                  <><p>Your client profile is complete.</p>
                  <hr className="slash-3"/>
                  <p>
                   <strong>Pre-screening </strong> completed.
                </p>
                <hr className="slash-3"/>
                <p>Create another <strong><Link to="applications">Application</Link></strong></p>
                {/* <div className="progress m-t-xs full progress-small">
                  <div
                    style={{ width: "100%", transition: "width 2s", direction: "ltr"}}
                    aria-valuemax="100"
                    aria-valuemin="0"
                    aria-valuenow={"100"}
                    role="progressbar"
                    className=" progress-bar progress-bar-success">
                    <span className="sr-only">{"100%"} Pending Review</span>
                  </div>
                </div> */}</>
                ): pscreninfo[0]?.prescreened_status === 3 && dataapp?.filter(app => app?.APPSTATUS === 0).length === 1 ? (
                  <><p>Your client profile is complete.</p>
                  <hr className="slash-3"/>
                  <p>
                   <strong>Pre-screening </strong> completed.
                </p>
                <hr className="slash-3"/>
                <p>An Application has been <strong>initiated</strong></p>
                {/* <div className="progress m-t-xs full progress-small">
                  <div
                    style={{ width: "100%", transition: "width 2s", direction: "ltr"}}
                    aria-valuemax="100"
                    aria-valuemin="0"
                    aria-valuenow={"100"}
                    role="progressbar"
                    className=" progress-bar progress-bar-success">
                    <span className="sr-only">{"100%"} Pending Review</span>
                  </div>
                </div> */}</>
                ): pscreninfo[0]?.prescreened_status === 3 && dataapp?.filter(app => app?.APPSTATUS === 1).length === 1 ? (
                  <><p>Your client profile is complete.</p>
                  <hr className="slash-3"/>
                  <p>
                   <strong>Pre-screening </strong> completed.
                </p>
                <hr className="slash-3"/>
                <p>An Application is in <strong>Draft</strong> stage</p>
                {/* <div className="progress m-t-xs full progress-small">
                  <div
                    style={{ width: "100%", transition: "width 2s", direction: "ltr"}}
                    aria-valuemax="100"
                    aria-valuemin="0"
                    aria-valuenow={"100"}
                    role="progressbar"
                    className=" progress-bar progress-bar-success">
                    <span className="sr-only">{"100%"} Pending Review</span>
                  </div>
                </div> */}</>
                ): pscreninfo[0]?.prescreened_status === 3 && dataapp?.filter(app => app?.APPSTATUS === 2).length === 1 ? (
                  <><p>Your client profile is complete.</p>
                  <hr className="slash-3"/>
                  <p>
                   <strong>Pre-screening </strong> completed.
                </p>
                <hr className="slash-3"/>
                <p>An Application is in <strong>Review</strong> stage</p>
                {/* <div className="progress m-t-xs full progress-small">
                  <div
                    style={{ width: "100%", transition: "width 2s", direction: "ltr"}}
                    aria-valuemax="100"
                    aria-valuemin="0"
                    aria-valuenow={"100"}
                    role="progressbar"
                    className=" progress-bar progress-bar-success">
                    <span className="sr-only">{"100%"} Pending Review</span>
                  </div>
                </div> */}</>
                ): pscreninfo[0]?.prescreened_status === 3 && dataapp?.filter(app => app?.APPSTATUS === 3).length === 1 ? (
                  <><p>Your client profile is complete.</p>
                  <hr className="slash-3"/>
                  <p>
                   <strong>Pre-screening </strong> completed.
                </p>
                <hr className="slash-3"/>
                <p>An Application has been <strong>Approved</strong></p>
                {/* <div className="progress m-t-xs full progress-small">
                  <div
                    style={{ width: "100%", transition: "width 2s", direction: "ltr"}}
                    aria-valuemax="100"
                    aria-valuemin="0"
                    aria-valuenow={"100"}
                    role="progressbar"
                    className=" progress-bar progress-bar-success">
                    <span className="sr-only">{"100%"} Pending Review</span>
                  </div>
                </div> */}</>
                ): pscreninfo[0]?.prescreened_status === 3 && dataapp?.filter(app => app?.APPSTATUS === 4).length === 1 ? (
                  <><p>Your client profile is complete.</p>
                  <hr className="slash-3"/>
                  <p>
                   <strong>Pre-screening </strong> completed.
                </p>
                <hr className="slash-3"/>
                <p>An Application has been <strong>Billed</strong></p>
                {/* <div className="progress m-t-xs full progress-small">
                  <div
                    style={{ width: "100%", transition: "width 2s", direction: "ltr"}}
                    aria-valuemax="100"
                    aria-valuemin="0"
                    aria-valuenow={"100"}
                    role="progressbar"
                    className=" progress-bar progress-bar-success">
                    <span className="sr-only">{"100%"} Pending Review</span>
                  </div>
                </div> */}</>
                ): (""):
                
                  <><p>Onboarding process Pending.</p>
                 
                {/* <div className="progress m-t-xs full progress-small">
                  <div
                    style={{ width: "40%", transition: "width 2s", direction: "ltr"}}
                    aria-valuemax="100"
                    aria-valuemin="0"
                    aria-valuenow={"40"}
                    role="progressbar"
                    className=" progress-bar progress-bar-info">
                    <span className="sr-only">{"40%"} Onboarding Incomplete</span>
                  </div>
                </div> */}
                </>
                } 

            {dataapp?.length === 0 && ( 
               <div className="progress m-t-xs full progress-small">
                  <div
                    style={{ width: `${pscreninfo && pscreninfo[0]?.prescreened_status === 0 ? "40%" :
                    pscreninfo && pscreninfo[0]?.prescreened_status === 1 ? "45%" :
                    pscreninfo && pscreninfo[0]?.prescreened_status === 2 ? "75%" :
                    pscreninfo && pscreninfo[0]?.prescreened_status === 3 ? "100%" :
                     "40%"}`,
                     transition: "width 2s", direction: "ltr"}}
                    aria-valuemax="100"
                    aria-valuemin="0"
                    aria-valuenow={`${pscreninfo && pscreninfo[0]?.prescreened_status === 0 ? "40%" :
                    pscreninfo && pscreninfo[0]?.prescreened_status === 1 ? "45%" :
                    pscreninfo && pscreninfo[0]?.prescreened_status === 2 ? "75%" :
                    pscreninfo && pscreninfo[0]?.prescreened_status === 3 ? "100%" :
                     "40%"}`}
                    role="progressbar"
                    className={`progress-bar ${pscreninfo ? "progress-bar-success" : "progress-bar-info"}`}>
                    <span className="sr-only">{"40%"} Onboarding Incomplete</span>
                  </div>
                </div>
            )}

            {dataapp?.length > 0 && ( 
               <div className="progress m-t-xs full progress-small">
                  <div
                    style={{ width: `${dataapp && dataapp?.filter(app => app?.APPSTATUS === 0).length === 1 ? "40%" :
                    dataapp && dataapp?.filter(app => app?.APPSTATUS === 1).length === 1 ? "45%" :
                    dataapp && dataapp?.filter(app => app?.APPSTATUS === 2).length === 1 ? "75%" :
                    dataapp && dataapp?.filter(app => app?.APPSTATUS === 3).length === 1 ? "80%" :
                    dataapp && dataapp?.filter(app => app?.APPSTATUS === 4).length === 1 ? "95%" :
                    dataapp && dataapp?.filter(app => app?.APPSTATUS === 5).length === dataapp?.length ? "100%" :
                     "40%"}`,
                     transition: "width 2s", direction: "ltr"}}
                    aria-valuemax="100"
                    aria-valuemin="0"
                    aria-valuenow={`${dataapp && dataapp?.filter(app => app?.APPSTATUS === 0).length === 1 ? "40%" :
                    dataapp && dataapp?.filter(app => app?.APPSTATUS === 1).length === 1 ? "45%" :
                    dataapp && dataapp?.filter(app => app?.APPSTATUS === 2).length === 1 ? "75%" :
                    dataapp && dataapp?.filter(app => app?.APPSTATUS === 3).length === 1 ? "80%" :
                    dataapp && dataapp?.filter(app => app?.APPSTATUS === 4).length === 1 ? "95%" :
                    dataapp && dataapp?.filter(app => app?.APPSTATUS === 5).length === dataapp?.length ? "100%" :
                     "40%"}`}
                    role="progressbar"
                    className={`progress-bar ${dataapp ? "progress-bar-info" : "progress-bar-info"}`}>
                    <span className="sr-only">{"40%"} Pending Application</span>
                  </div>
                </div>
            )}
               
              </div>


              <div className="panel-footer contact-footer">
                <div className="row">
                  <div className="col-md-6 border-right">
                    <div className="contact-stat">
                       <span>Applications: </span> <strong>{dataapp?.length || 0}</strong> 
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="contact-stat">
                       <span>Messages: </span> <strong>{datamsg?.length || 0}</strong> 
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-8">

         {/*  { userData?.prescreened_status === 0 &&  */}

        {  !!dataaprescreen  &&
          (dataaprescreen[0]?.prescreened_status === 0 || dataaprescreen[0]?.prescreened_status === 1) && (

            <div className="hpanel animate-panel" data-child="panel-body" data-effect="fadeInUp"> 
              <div className="panel-body no-padding">
                <div className="list-group">
                  <a
                    className="list-group-item active pscreen-header"
                    href={void(0)}>
                    <h5 className="list-group-item-heading">Pre-screening</h5>
                    {wizardstep === 4 ? (
                      <p
                        className="list-group-item-text"
                        style={{ color: "#c8efc7" }}>
                        Pre-screening completed. Thank you {userData?.first_name} !{" "}
                      </p>
                    ) : (
                      <p
                        className="list-group-item-text"
                        style={{ color: "#c8efc7" }}>
                        Kindly complete the below questionaires to proceed{" "}
                      </p>
                    )}
                  </a>
                  <form
                    name="simpleForm"
                    noValidate
                    id="simpleForm">
                    <div
                      className="text-center m-b-md m-t-md"
                      id="wizardControl">
                      <span
                        className={
                          wizardstep === 1
                            ? "btn btn-primary wizardbtn"
                            : "btn btn-default wizardbtn"
                        }
                        style={{ margin: "0px 10px" }}>
                        1
                      </span>
                      <span
                        className={
                          wizardstep === 2
                            ? "btn btn-primary wizardbtn"
                            : "btn btn-default wizardbtn"
                        }
                        style={{ margin: "0px 10px" }}>
                        2
                      </span>
                      <span
                        className={
                          wizardstep === 3
                            ? "btn btn-primary wizardbtn"
                            : "btn btn-default wizardbtn"
                        }
                        style={{ margin: "0px 10px" }}>
                        3
                      </span>
                      <span
                        className={
                          wizardstep === 4
                            ? "btn btn-primary wizardbtn"
                            : "btn btn-default wizardbtn"
                        }
                        style={{ margin: "0px 10px" }}>
                        4
                      </span>
                    </div>

                    <div
                      className="tab-content"
                      style={{ borderBottom: "solid 0.0081em #74747a85" }}>
                      {wizardstep === 1 && (
                        <div
                          id="step1"
                          className={
                            wizardstep === 1
                              ? "p-m tab-pane wizardpane active"
                              : "p-m tab-pane wizardpane"
                          }>
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="row">
                                <div className="form-group col-lg-12">
                                  <h5
                                    className="list-group-item-heading"
                                    style={{ color: "#5d9732" }}>
                                    Please confirm how we can help you today:
                                  </h5>

                                  <div className="form-check">
                                    <input
                                      className="radio radio-success radio-inline"
                                      style={{ margin: "12px 10px 0" }}
                                      type="radio"
                                      name="how_we_can_help_you_question"
                                      id="Radios1"
                                      checked={
                                        details.how_we_can_help_you_question === "University Placement"
                                      }
                                      onChange={handleChange}
                                      value="University Placement"
                                      {...register('how_we_can_help_you_question', { required: { value: true, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                    />
                                    <label
                                      className="form-check-label"
                                      style={{ fontWeight: "600" }}
                                      htmlFor="Radios1">
                                      University Placement
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input
                                      className="radio radio-success radio-inline"
                                      style={{ margin: "12px 10px 0" }}
                                      type="radio"
                                      name="how_we_can_help_you_question"
                                      id="Radios2"
                                      checked={
                                        details.how_we_can_help_you_question === "Assistance with an Immigration matter"
                                      }
                                      onChange={handleChange}
                                      value="Assistance with an Immigration matter"
                                      {...register('how_we_can_help_you_question', { required: { value: true, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                    />
                                    <label
                                      className="form-check-label"
                                      style={{ fontWeight: "600" }}
                                      htmlFor="Radios2">
                                      Assistance with an Immigration matter
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input
                                      className="radio radio-success radio-inline"
                                      style={{ margin: "12px 10px 0" }}
                                      type="radio"
                                      name="how_we_can_help_you_question"
                                      id="Other"
                                      checked={
                                        details.how_we_can_help_you_question === "Other"
                                      }
                                      onChange={handleChange}
                                      value="Other"
                                      {...register('how_we_can_help_you_question', { required: { value: true, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                    />
                                    <label
                                      className="form-check-label"
                                      style={{ fontWeight: "600" }}
                                      htmlFor="Radios3">
                                      Other
                                    </label>
                                  </div>

                                
                {errors.how_we_can_help_you_question && (
                  <span className="errorValidate">This field is required</span>
                )}
                                </div>
                              </div>

                              <div className="row">
                                <div
                                  className={
                                    details.how_we_can_help_you_question === "Other"
                                      ? "form-group col-lg-12 txtareahide collapsin"
                                      : "form-group col-lg-12 txtareahide"
                                  }
                                  aria-expanded={
                                    details.how_we_can_help_you_question === "Other"
                                  }>
                                  <h5 className="list-group-item-heading label-clr" style={{fontSize: "1.36em"}}>
                                    Please specify
                                  </h5>
                                  <div className="form-outline">
                                    <textarea
                                      className="form-control txtareabb"
                                      onChange={handleChange}
                                      id="textArea"
                                      name="how_we_can_help_you"
                                      value={details.how_we_can_help_you || ""}
                                      maxLength="100"
                                      {...register('how_we_can_help_you', { required: { value: details.how_we_can_help_you_question === "Other" && details.how_we_can_help_you === "" ? true : false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                      rows="2"></textarea>
                                  </div>
                                  {errors.how_we_can_help_you && (
                                    <span className="errorValidate">This field is required</span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="text-right m-t-xs">
                            {previousButton()}
                            {nextButton()}
                          </div>
                        </div>
                      )}

                      {wizardstep === 2 && (
                        <div
                          id="step2"
                          className={
                            wizardstep === 2
                              ? "p-m tab-pane wizardpane active"
                              : "p-m tab-pane wizardpane"
                          }>
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="row col-bb">
                                <div className="form-group col-lg-12">
                                  <label className="label-clr">Full Name</label>
                                  <input
                                    type="text"
                                    value={details.fullname || "" }
                                    className="form-control"
                                    readOnly
                                    name="fullname"
                                  />
                                </div>
                              </div>
                              <div
                                className="row col-bb"
                                style={{ paddingTop: "3%" }}>
                                <div className="form-group col-lg-6">
                                  <label className="label-clr">Email</label>
                                  <input
                                    type="email"
                                    readOnly
                                    value={
                                      details?.client_email || userData?.email
                                    }
                                    className="form-control"
                                    name="client_email"
                                  />
                                </div>
                                <div className="form-group col-lg-6">
                                  <label className="label-clr">
                                    Telephone Number
                                  </label>
                                  <input
                                    type="tel"
                                    readOnly
                                    value={
                                      formatPhoneNumberIntl(details?.client_phone) || formatPhoneNumberIntl(userData?.phone)
                                    }
                                    className="form-control"
                                    name="client_phone"
                                  />
                                </div>
                              </div>
                              <div
                                className="row col-bb"
                                style={{ paddingTop: "3%" }}>
                                <div
                                  className="col-xs-6 form-group" /* style={{"marginTop": "8pxx"}} */
                                >
                                  <label className="label-clr">
                                    Which country of residence are you currently in ?
                                  </label>
                                  <input
                                    type="text"
                                    readOnly
                                    value={
                                      details?.country_of_residence || userData?.country
                                    }
                                    className="form-control"
                                    name="client_email"
                                  />
                                  {/* <select
                                     name="country_of_residence"
                                     value={details.country_of_residence || userData?.country || ""} // Prop: The email input data   
                                     {...register("country_of_residence", { required: {value : details.country_of_residence === "" ? true : false, message: 'country of residence is required'}, onChange: (e) => {handleChange(e)} })}                        
                                     className={errors.country_of_residence ? "form-select m-b-3 seleval" :  "form-select m-b-3" }
                                     style={{ width: "100%" }}>
                                      <option value=''>select country of residence</option>
                                      {
                                       countries.map((item) =>{
                                           return(
                                               <option key={item.country}>
                                                   {item.country}
                                               </option>
                                           )
                                       })
                                      }
                                  </select>
                                 
                                  {errors.country_of_residence && 
                                  <p className="errorValidate">{errors.country_of_residence.message}</p>
                                  } */}
                                </div>
                                <div className="col-xs-6 form-group">
                                  <h5
                                    className="list-group-item-heading label-h5" /* style={{"marginTop": "3%%"}} */
                                  >Do you have residence in this country of residence ?
                                  </h5>

                                  <div className="radio radio-success radio-inline">
                                    <input
                                      type="radio"
                                      id="residencey"
                                      value="Yes"
                                      name="residency_question"
                                      checked={details.residency_question === "Yes"}
                                      {...register('residency_question', { required: { value: true, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                    />
                                    <label htmlFor="residencey">Yes </label>
                                  </div>
                                  <div className="radio radio-success radio-inline">
                                    <input
                                      type="radio"
                                      id="residencen"
                                      value="No"
                                      name="residency_question"
                                      checked={details.residency_question === "No"}
                                      {...register('residency_question', { required: { value: true, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                    />
                                    <label htmlFor="residencen">No</label>
                                  </div>
                                  {errors.residency_question && (
                                  <p className="errorValidate">This field is required</p>
                                  )}
                                </div>
                              </div>

                              <div
                                className={
                                  details.residency_question === "No"
                                    ? "row txtareahide collapsin"
                                    : "row txtareahide"
                                }
                                aria-expanded={details.residency_question === "No"}
                                style={{ paddingTop: "3%" }}>
                                <div className="form-group col-lg-8">
                                  <label className="label-clr">
                                    What type of permission do you have to be in
                                    the country ?
                                  </label>
                                  <input
                                    type="text"
                                   {...register('type_of_permission', { required: { value: details.residency_question === "No" && details.type_of_permission === "" ? true : false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                    value={details.type_of_permission}
                                    className="form-control"
                                    name="type_of_permission"
                                  />
                                  {errors.type_of_permission && (
                                  <span className="errorValidate">This field is required</span>
                                  )}
                                </div>
                                <div className="form-group col-lg-4">
                                  <label className="label-clr">
                                    When does it expire
                                  </label>
                                  <input
                                    {...register('expiry', { required: { value: details.residency_question === "No" && details.expiry === "" ? true : false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                    value={details.expiry || ""}
                                    name="expiry"
                                    className="form-control"
                                    type="date"
                                  />
                                  {errors.expiry && (
                                  <span className="errorValidate">This field is required</span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="text-right m-t-xs">
                            {previousButton()}
                            {nextButton()}
                            
                          </div>
                        </div>
                      )}

                      {wizardstep === 3 && (
                        <div
                          id="step3"
                          className={
                            wizardstep === 3
                              ? "p-m tab-pane wizardpane active"
                              : "p-m tab-pane wizardpane "
                          }>
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="row col-bb">
                              
                                <div
                                  className={
                                    details.english_proficiency === "Yes"
                                      ? "form-group col-lg-3 "
                                      : "form-group col-lg-6"
                                  }>
                                  <label className="label-clr">
                                    Date of Birth ?
                                  </label>
                                  <input
                                    onChange={handleChange}
                                    value={details.dob || ""}
                                    name="dob"
                                    className="form-control"
                                    max={getTodaysDate()}
                                    type="date"
                                    {...register('dob', { required: { value: true, message: "Date of birth is required" }, onChange: (e) => {handleChange(e)} })}
                                  />
                                  {errors.dob && (
                                  <p className="errorValidate">{errors.dob.message}</p>
                                  )}
                                </div>
                                <div
                                  className={
                                    details.english_proficiency === "Yes"
                                      ? "form-group col-lg-5 "
                                      : "form-group col-lg-6"
                                  }>
                                  <h6
                                    className="list-group-item-heading label-clr"
                                    style={{
                                      fontSize: "13px",
                                      marginBottom: "13px",
                                    }}>
                                    Do you speak english proficiently ?
                                  </h6>
                                  <div className="radio radio-success radio-inline">
                                    <input
                                      type="radio"
                                      id="english_proficiency"
                                      value="Yes"
                                      name="english_proficiency"
                                      checked={details.english_proficiency === "Yes"}
                                      {...register('english_proficiency', { required: { value: true, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                    />
                                    <label htmlFor="english_proficiencyy">Yes </label>
                                  </div>
                                  <div className="radio radio-success radio-inline">
                                    <input
                                      type="radio"
                                      id="english_proficiencyn"
                                      value="No"
                                      name="english_proficiency"
                                      checked={details.english_proficiency === "No"}
                                      {...register('english_proficiency', { required: { value: true, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                    />
                                    <label htmlFor="english_proficiencyn">No</label>
                                  </div>
                                  {errors.english_proficiency && (
                                  <p className="errorValidate">This field is required</p>
                                  )}
                                </div>
                                <div
                                  className={
                                    details.english_proficiency === "Yes"
                                      ? "form-group col-lg-4 txtareahide collapsin"
                                      : "form-group col-lg-4 txtareahide"
                                  }>
                                  <label className="label-clr">
                                    What level of Proficiency ?
                                  </label>
                                  <select
                                    name="english_proficiency_level"
                                    {...register('english_proficiency_level', { required: { value: details.english_proficiency === "Yes" ? true : false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                    value={details.english_proficiency_level || ""} // Prop: The email input data
                                    onChange={handleChange} //
                                    className="form-select m-b-3"
                                    style={{ width: "100%" }}>
                                    <option value=''>select proficiency level</option>
                                    <option value="Beginner">Beginner</option>
                                    <option value="Moderate">Moderate</option>
                                    <option value="Fluent">Fluent</option>
                                    <option value="Native">Native</option>
                                  </select>
                                  {errors.english_proficiency_level && (
                                  <p className="errorValidate">This field is required</p>
                                  )}
                                </div>
                              </div>

                              <div
                                className="row col-bb"
                                style={{ paddingTop: "3%" }}>
                               
                                <div className="col-xs-5 form-group">
                                  <label className="label-clr">
                                    What other languages do you speak ?
                                  </label>
                                  <input
                                    type="text"
                                    value={details.other_languages || ""}
                                    {...register('other_languages', { required: { value: true, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                    className="form-control"
                                    name="other_languages"
                                  />
                                  {errors.other_languages && (
                                  <p className="errorValidate">This field is required</p>
                                  )}
                                </div>

                                <div className="col-xs-7 form-group">
                                  <h5 className="list-group-item-heading label-h5">
                                    Have you ever been refused a visa/entry to
                                    any country of residence in the world ?
                                  </h5>
                                  <div
                                    className="radio radio-success radio-inline"
                                    style={{ marginTop: "0" }}>
                                    <input
                                      type="radio"
                                      id="visa_refusaly"
                                      value="Yes"
                                      name="visa_refusal"
                                      checked={details.visa_refusal === "Yes"}
                                      {...register('visa_refusal', { required: { value: true, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                    />
                                    <label htmlFor="visa_refusaly">Yes </label>
                                  </div>
                                  <div
                                    className="radio radio-success radio-inline"
                                    style={{ marginTop: "0" }}>
                                    <input
                                      type="radio"
                                      id="visa_refusaln"
                                      value="No"
                                      name="visa_refusal"
                                      checked={details.visa_refusal === "No"}
                                      {...register('visa_refusal', { required: { value: true, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                    />
                                    <label htmlFor="visa_refusaln">No</label>
                                  </div>
                                  {errors.visa_refusal && (
                                  <p className="errorValidate">This field is required</p>
                                  )}
                                </div>
                              </div>
                              <div
                                className={
                                  details.visa_refusal === "Yes"
                                    ? "row col-bb txtareahide collapsin"
                                    : "row txtareahide"
                                }
                                style={{ marginTop: "3%" }}>
                                <div className="col-xs-12 form-group">
                                  <label className="label-clr">
                                    Please provide type of visa
                                    refused/date/reason given
                                  </label>
                                  <div className="form-outline">
                                    <textarea
                                      className="form-control txtareabb"
                                      {...register('visa_refusal_reason', { required: { value: details.visa_refusal === "Yes" ? true:false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                      id="textArea"
                                      name="visa_refusal_reason"
                                      maxLength="100"
                                      rows="2"
                                      value={
                                        details.visa_refusal_reason || ""
                                      }></textarea>
                                      {errors.visa_refusal_reason && (
                                  <p className="errorValidate">This field is required</p>
                                  )}
                                  </div>
                                </div>{" "}
                              </div>

                              <div
                                className="row col-bb"
                                style={{ paddingTop: "3%" }}>
                                <div className="col-xs-12 form-group">
                                  <label className="label-clr">
                                    Please provide in your own words how we can
                                    help you
                                  </label>
                                  <div className="form-outline">
                                    <textarea
                                      className="form-control txtareabb"
                                      {...register('in_your_own_words', { required: { value: true, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                      id="textArea"
                                      name="in_your_own_words"
                                      maxLength="200"
                                      value={details.in_your_own_words || ""}
                                      rows="2"></textarea>
                                      {errors.in_your_own_words && (
                                  <p className="errorValidate">This field is required</p>
                                  )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="text-right m-t-xs">
                            {previousButton()}                       
                          </div>
                        </div>
                      )}

                      {wizardstep === 4 && (
                        <div
                          id="step3"
                          className={
                            wizardstep === 4
                              ? "tab-pane wizardpane active"
                              : "tab-pane wizardpane "
                          }>
                          <div className="row text-center m-t-lg m-b-lg">
                            <div className="col-lg-12" style={{paddingTop: "20px"}}>
                             
                              {submitMutation.isLoading && 
                             
                                <span className="btn">
                                <i className="fa fa-eercast" style={{color: "#5ac557"}}></i>
                                &nbsp; Submitting Response......
                                <span className="lds-facebook"><span></span><span></span><span></span></span>
                              </span>

                              }
                             
                             { submitMutation.isSuccess && 
                                <>
                                 <i
                                className="pe-7s-check fa-5x text-muted"
                                style={{ color: "#5d982e" }}></i>
                                <p className="small m-t-md"
                                    style={{ fontSize: "18px", color: "#5d982e" }}>
                                    <strong>
                                      Details submitted Successfully !!
                                    </strong>
                                  </p>
                                  <p
                                    className="small m-t-md"
                                    style={{ fontSize: "13px" }}>
                                    Your application is now with a caseworker.
                                    Please expect feedback within 48 hours
                                  </p>
                                </> }

                                {submitMutation.isError && (
                                  <div className="mt-5 text-center">
                                     <i
                                className="pe-7s-attention fa-5x text-muted"
                                style={{ color: "#5d982e" }}></i>
                                    <ul className="errorlist">

                                    {
                                      errorls.map((errorItem, i) =>{
                                          return(
                                              <li key={i} className="text-muted-error">
                                                  {errorItem}
                                              </li>
                                          )
                                      })
                                    }
                                  </ul>
                                </div>
                                 )}
                            </div>
                          </div>
                          {wizardstep !== 4 && (
                            <div className="text-right m-t-xs">
                              {previousButton()}
                              {nextButton()}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </form>
                  {wizardstep !== 4 && (
                    <a
                      className="list-group-item"
                      href={void(0)}
                      style={{ backgroundColor: "#f5f5f5" }}>
                        { saveDraftMutation.isLoading ? 
                        (
                        <button className="ladda-button btn btn-sm btn-custom m-t-n-xs" data-style="expand-right">
                        <span className="ladda-spinner"> <i className="fa fa-ravelry" style={{color: "#5ac557"}}></i>  &nbsp; </span><span className="ladda-label">saving...</span>
                        <div className="ladda-progress" style={{width: "73px"}}></div>
                        </button>
                        ):
                      (<button
                      onClick={saveDraft}
                        className="btn btn-sm btn-custom m-t-n-xs"
                        style={{ marginRight: "10px" }} >
                        <i className="fa fa-edit"></i> Save as Draft
                      </button>
                      )}
                      {wizardstep === 3 && (
                        <span>
                          <button
                            className="btn btn-sm btn-primary m-t-n-xs"
                            onClick={onSubmit} >
                            <strong>Submit</strong>
                          </button>
                        </span>
                      )}
                       <span className="pull-right"><i className="fa fa-clock-o"> </i> {elapsedtime}</span>
                    </a>
                  )}
                  
                </div>
          
              </div>
              
            </div>
          )}
           
           {/* { userData?.prescreened_status !== 0 &&  */}
           {  !!dataaprescreen  &&
             dataaprescreen[0]?.prescreened_status > 1 && (

            <div className="hpanel animate-panel" data-child="appContent" data-effect="fadeInUp">
             
              <div className="appContent">
              <ul className="nav nav-tabs">
                <li className="active">
                  <a data-toggle="tab" href="#tab-1">
                    Applications
                  </a>
                </li>
                <li className="">
                  <a data-toggle="tab" href="#tab-2">
                    Messages
                  </a>
                </li>
              </ul>
              <div className="tab-content">
                <div id="tab-1" className="tab-pane active">
                  <div className="panel-body" style={{padding: "15px 10px"}}>
                    <div className="alert alert-success">
                      <i className="fa fa-pagelines"></i> Recent Applications :
                    </div>

                    <div className="table-responsive">
                      <table className="table table-striped apptable">
                        <thead>
                          <tr>
                           
                            <th>APP NO.</th>
                            <th>DATE </th>
                            <th>GIVEN MAME</th>
                            <th>FAMILY NAME </th>
                            <th>STATUS</th>
                            <th>ACTION</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!!dataapp && (Object.keys(dataapp).length > 0) ? 
                            
                              dataapp.map((item,i) =>{
                                           return (
                                            <tr key={i}>
                                            
                                            <td>{item.APPTYPE_ID}</td>
                                            <td>{maildate(item.created_at, 1)}</td>
                                            <td>{item.FIRSTNAME}</td>
                                            <td>{item.LASTNAME}</td>
                                            <td>{AppStatus(item.APPSTATUS)}</td>
                                            <td>
                                              <Link to={`/dashboard/view_application/${item.APPTYPE_ID}`} className="btn-group">
                                                <i className="btn btn-default btn-xs">View</i>
                                              </Link>
                                            </td>
                                          </tr>
                                           )
                                       })
                                      :
                                      (
                                        <tr><td colSpan="6" style={{textAlign: "center"}}> No Applications found</td></tr>
                                      )} 
                        
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div id="tab-2" className="tab-pane">
                  <div className="panel-body no-padding">
                    <div className="chat-discussion" style={{ height: "auto" }}>
                      <div className="alert alert-success">
                        <i className="fa fa-stack-exchange"></i> Recent Mesages
                        :
                      </div>

                      <div className="table-responsive">
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              
                              <th>Sender </th>
                              <th>Subject </th>
                              <th>Tag</th>
                              <th>Status</th>
                              <th>Date</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                           {!!datamsg && (Object.keys(datamsg).length > 0) ? 
                            
                            datamsg.map((item,i) =>{
                                         return (
                                          <tr key={i}>     
                                          <td>i-visas</td>
                                          <td>{item.MESSAGE_SUBJECT}</td>
                                          <td>{item.MESSAGE_TAG}</td>
                                          <td>{item.MESSAGE_STATUS}</td>
                                          <td>{maildate(item.created_at, 1)}</td>
                                          <td><a 
                                          className="btn btn-default btn-xs btn-outline"
                                          href={`/dashboard/messages/?conversation_id=${item.CONVERSATION_ID}`}>View Message</a></td>
                                        </tr>
                                         )
                                     })
                                    :
                                    (
                                      <tr><td colSpan="6" style={{textAlign: "center"}}> No messages found</td></tr>
                                    )} 
                           
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
             )} 
          </div>
                          
        </div>
      </div>
    </Fragment>
  );
};
export default Overview; ///withRouter(Master);
