import React, { Component } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
const animatedComponents = makeAnimated();

/* const Countries = [
  { label: "Albania", value: 355 },
  { label: "Argentina", value: 54 },
  { label: "Austria", value: 43 },
  { label: "Cocos Islands", value: 61 },
  { label: "Kuwait", value: 965 },
  { label: "Sweden", value: 46 },
  { label: "Venezuela", value: 58 }
]; */

class MultiSelectClient extends Component {
  render() {
    return (
      
            <Select 
            options={this.props.options}
            value={this.props.value ?? null}
            defaultValue={this.props.defaultValue ?? null} 
            components={animatedComponents} 
            onChange={this.props.handleChange}
            isMulti isSearchable 
            />
        
    );
  }
}
export default MultiSelectClient;