import React, { Component, Fragment } from "react";

class IndexDashboard extends Component {
  render() {
    return (
      <Fragment>
        <h2>DashBoard Page</h2>
      </Fragment>
    );
  }
}
export default IndexDashboard;
