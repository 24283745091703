import React, { useState, useEffect } from "react";
import {useMutation, useQuery} from 'react-query';
import * as api from './Utils/apiCall';
import { formatPhoneNumberIntl } from "react-phone-number-input";
import "./Verifysms.css";
import { useNavigate, useLocation } from "react-router-dom";

const VerifySms = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [btnvisible, setBtnvisible] = useState(true);

  console.log("verifysms : location state values", location?.state);
  
  const [frmstate, setFrmstate] = useState({
    digit01: "",
    digit02: "",
    digit03: "",
    digit04: "",
    digit05: "",
    digit06: "",
  });

  const [errors, setErrors] = useState([]);

  const {isError, isSuccess, isLoading, mutate} = useMutation(api.verifySMS, {
    onSuccess: (data) => {
      console.log("Verification Successfull", data);
      console.log("location state values before", location?.state);
      location.state.verified = true;
      console.log("location state values after", location?.state);
      navigate("/verifysms", {state: location.state, replace: true});
      //setTimeout(
      //  () => {
        //  console.log("location state values inside interval 1", location?.state);
        //  navigate("/verifysms", {state: {}, replace: true});
        //  console.log("location state values inside interval 2", location?.state);
       // },
       // 50000
     // )
    },
    onError: (error) => {
      //console.log("errors",error); 
      //console.log("error response data errors: ",error.response.data.errors?.mobilephone[0]);
      console.log("raw:::",error.response.data.message)
      const errlist = []
      const errObject = error.response.data?.message
      //console.log("typeof: ",typeof error.response.data.errors);
      if(error.response.data.errors !== undefined && typeof error.response.data.errors === 'object')
       {
            for(var propName in errObject) {
              if(errObject.hasOwnProperty(propName)) {
                  //console.log("within iteration",errObject[propName]); 
                  errlist.push(errObject[propName]);
              }
            }
          setErrors(errlist);
       }
       else if(error.response.data.message !== undefined )
       {
           
              errlist.push(error.response.data.message);
              setErrors(errlist);
       }
       else
       {
         errlist.push(error.message)
         setErrors(errlist);
       }

       console.log("errlist",errlist); 

      //console.log("error response status: ", error.response.status);
      
    }
  });

  useEffect(() => {
    if (location.state?.mnumber === null || location.state?.mnumber === undefined)
      return navigate("/register", { replace: true });
  }, [location.state, navigate]);

  useEffect(() => {
    if (
      Object.entries(frmstate).filter(function ([key, value]) {
        return value !== "";
      }).length === 6
    ) {
      return setBtnvisible(false);
    } else {
      return setBtnvisible(true);
    }
  }, [frmstate]);

  const handleSubmit = () => {
    console.log("submitted", frmstate);
    console.log("join frm values", Object.values(frmstate).join(""));
    const joinedotp = Object.values(frmstate).join("");
    if(!location.state.verified){
      mutate({
        "id": Number(location.state.token),
        "OTP":Number(joinedotp)
      });
  }
  else{
    console.log("Already Verified. PLease Goto Login Page!!");
  }

    /* setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setVerified(true);
    }, 3000); */
  };

  const resendOtp = async(e) => {
      e.preventDefault()
      await api.resendOTP({"phone":location.state?.mnumber}).then(response => {
      console.log(response);
    })
    .catch(e => {
      console.log(e);
    });
  }

  const resendEmail = async(e) => {
    e.preventDefault()
    await api.resendEmail({"id": location.state.token , "email":location.state?.email}).then(response => {
    console.log(response);
  })
  .catch(e => {
    console.log(e);
  });
}


////////Company Information :////////////
const [companyDetails, setCompanyDetails] = useState({});

const getCompanyInfoNav = useQuery("get_companyinfo", api.companyInfo, {
  enabled: true,
  refetchOnMount:true,
  refetchOnWindowFocus:true,
  onSuccess: (data) => {
    console.log("users data:::", data);

    const apd = {...data?.Info[0]};
    apd.URL = data?.URL;
    console.log("@@@@@@@@@@@@@@@@@@", apd)
    setCompanyDetails(apd);
    setCompanyDetails(companyDetails => {
      console.log("companyDetails ===>>>",companyDetails);
      return companyDetails;
    })
  },
  onError: (error) => {
      console.log("Error Message ::: ",error); 
  }
});

//////////////////////////////////////////


  const handleOninput = (event) => {
    event.persist();

    const { name, value } = event.target;

    const formstate = { ...frmstate };

    if (value.length > 1) {
      formstate[name] = value.slice(0, 1);

      setFrmstate(formstate);

      setFrmstate((frmstate) => {
        console.log("updated sliced frmstate", frmstate);
        return frmstate;
      });
    } else {
      formstate[name] = value;

      setFrmstate(formstate);

      setFrmstate((frmstate) => {
        console.log("updated frmstate", frmstate);
        return frmstate;
      });
    }
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
    const [fieldName, fieldIndex] = name.split("0");

    // Check if they hit the max character length
    if (value.length === 1) {
      // Check if it's not the last input field
      console.log("fieldindex b4", parseInt(fieldIndex, 10));
      if (parseInt(fieldIndex, 10) <= 6) {
        // Get the next input field
        console.log("fieldindex after", parseInt(fieldIndex, 10));
        const nextSibling = document.querySelector(
          `input[name=digit0${parseInt(fieldIndex, 10) + 1}]`
        );

        // If found, focus the next field
        if (nextSibling !== null) {
          nextSibling.focus();
        }
      }
    }
  };

  const handleOnKeyPress = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault()
      handleSubmit();
      console.log('submit')
    }
  }

  return (
    <div className="auth-page">
      <div className="container-fluid p-0">
        {!!location.state?.mnumber ?  (
          <div className="row g-0">
            <div className="col-xxl-4 col-lg-5 col-md-6 fmpane">
              <div className="auth-full-page-content d-flex p-sm-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5 text-center">
                      <a href="# " className="d-block auth-logo">
                        {/* <img src="assets/images/logo.png" alt="" height="40%" />{" "} */}
                        <img src={companyDetails?.URL || "assets/images/logo.png"} alt="logo" width="217px" height="92px" /* height="20%" */ />
                        <span className="logo-txt"></span>
                      </a>
                    </div>
                    <div className="auth-content my-auto">
                      <div className="text-center">
                        <div className="avatar-lg mx-auto">
                          <div className="avatar-title rounded-circle bg-light">
                            {isSuccess || location.state?.verified ? (
                              <i className="bx bx-mail-send h2 mb-0 text-primary"></i>
                            ) : (
                              <i className="bx bx-mobile-vibration h2 mb-0 text-primary"></i>
                            )}
                          </div>
                        </div>

                        {isSuccess || location.state?.verified ? (

                          <div className="p-2 mt-4">
                           
                            <h4>Verified Successfully !</h4>
                           
                            <p className="text-muted presend" style={{margin: "30px 1px"}}>
                              Mobile Number has been Verified.
                            </p>
                            


                            <p className="text-muted presend emailnotify">
                              A verification email has been sent to
                              <span className="fw-bold">
                                {" "}
                                {location.state?.email || "prince@sulaman.com"}
                              </span>
                              , Kindly check it
                            </p>
                            <div className="mt-4">
                              <a href="/" className="btnR btn-primary">
                                Go to Sign In
                              </a>
                            </div>
                          </div>


                         
                        ) : (
                          <div className="p-2 mt-4">
                          <h4>Verify your Mobile number</h4>
                          <p className="mb-1">
                            Please enter the 6 digit code sent to you
                          </p>
                          <p className="mb-4">
                            <span className="fw-bold">
                              {formatPhoneNumberIntl(location.state?.mnumber)}
                            </span>
                          </p>

                          <form>
                            <div
                              className="row"
                              style={{
                                justifyContent: "center",
                                marginRight: "calc(-0.5 * 0px)",
                                marginLeft: "calc(-0.5 * 0px)",
                              }}>
                              <div className="col-3">
                                <div className="mb-3">
                                  <label
                                    htmlFor="digit1-input"
                                    className="visually-hidden">
                                    Dight 1
                                  </label>
                                  <input
                                    type="number"
                                    name="digit01"
                                    step="1"
                                    id="digit1"
                                    className="form-controlR form-controlR-lg text-center two-step"
                                    value={frmstate.digit01}
                                    onInput={handleOninput}
                                    onKeyDown={handleOnKeyPress}
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>

                              <div className="col-3">
                                <div className="mb-3">
                                  <label
                                    htmlFor="digit2-input"
                                    className="visually-hidden">
                                    Dight 2
                                  </label>
                                  <input
                                    type="number"
                                    name="digit02"
                                    step="1"
                                    id="digit2"
                                    className="form-controlR form-controlR-lg text-center two-step"
                                    value={frmstate.digit02}
                                    onInput={handleOninput}
                                    onKeyDown={handleOnKeyPress}
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>

                              <div className="col-3">
                                <div className="mb-3">
                                  <label
                                    htmlFor="digit3-input"
                                    className="visually-hidden">
                                    Dight 3
                                  </label>
                                  <input
                                    type="number"
                                    name="digit03"
                                    step="1"
                                    id="digit3"
                                    className="form-controlR form-controlR-lg text-center two-step"
                                    value={frmstate.digit03}
                                    onInput={handleOninput}
                                    onKeyDown={handleOnKeyPress}
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>

                              <div className="col-3">
                                <div className="mb-3">
                                  <label
                                    htmlFor="digit4-input"
                                    className="visually-hidden">
                                    Dight 4
                                  </label>
                                  <input
                                    type="number"
                                    name="digit04"
                                    step="1"
                                    id="digit4"
                                    className="form-controlR form-controlR-lg text-center two-step"
                                    value={frmstate.digit04}
                                    onInput={handleOninput}
                                    onKeyDown={handleOnKeyPress}
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>

                              <div className="col-3">
                                <div className="mb-3">
                                  <label
                                    htmlFor="digit4-input"
                                    className="visually-hidden">
                                    Dight 5
                                  </label>
                                  <input
                                    type="number"
                                    name="digit05"
                                    step="1"
                                    id="digit5"
                                    className="form-controlR form-controlR-lg text-center two-step"
                                    value={frmstate.digit05}
                                    onInput={handleOninput}
                                    onKeyDown={handleOnKeyPress}
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>

                              <div className="col-3">
                                <div className="mb-3">
                                  <label
                                    htmlFor="digit6-input"
                                    className="visually-hidden">
                                    Digit 6
                                  </label>
                                  <input
                                    type="number"
                                    name="digit06"
                                    step="1"
                                    id="digit6"
                                    className="form-controlR form-controlR-lg text-center two-step"
                                    value={frmstate.digit06}
                                    onInput={handleOninput}
                                    onKeyDown={handleOnKeyPress}
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                            </div>
                          </form>

                          <div className="mt-4">
                            {!isLoading ? (
                              <button
                                className="btnR btn-primary waves-effect waves-light"
                                style={{ width: "75%" }}
                                disabled={btnvisible}
                                onClick={handleSubmit}
                                type="button">
                                Confirm
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="btnR waves-effect waves-light">
                                <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                Loading
                              </button>
                            )}
                          </div>
                        </div>
                        )}
                      </div>

                      {isError && (
                      <div className="mt-5 text-center">
                        <ul className="errorlist">
                        {
                          errors.map((errorItem, i) =>{
                              return(
                                  <li key={i} className="text-muted-error">
                                      {errorItem}
                                  </li>
                              )
                          })
                        }
                          </ul>
                        </div>
                      )}


                      <div className="mt-5 text-center">
                        {isSuccess || location.state.verified ? (
                          <p className="text-muted mb-0 presend">
                          Didn't receive an email ?
                          <a href="true" onClick={resendEmail} className="text-primary fw-semibold">Resend</a>
                          </p>
                        ) : (
                          <p className="text-muted mb-0 presend">
                            Didn't receive an SMS ?
                            <a href="true" onClick={resendOtp} className="text-primary fw-semibold"> Resend </a>
                          </p>
                        )}
                      </div>
                    </div>
                    <div
                      className="text-center"
                      style={{ marginTop: "2.4rem" }}>
                      <p className="mb-0 presend">
                        {/* © {new Date().getFullYear()} i-visas.com */}
                        © {new Date().getFullYear()} {companyDetails?.COMPANY_NAME ?? "i-visas.com"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* end auth full page content */}
            </div>
            {/* end col */}
            <div className="col-xxl-8 col-lg-7 col-md-6">
              <div className="auth-bg pt-md-5 p-4 d-flex">
                <div className="bg-overlay bg-primary"></div>
                <ul className="bg-bubbles">
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                </ul>
                {/* end bubble effect */}
                <div className="row justify-content-center align-items-center">
                  <div className="col-xl-9">
                    <div className="p-0 p-sm-4 px-xl-0">
                      <div
                        id="reviewcarouselIndicators"
                        className="carousel slide"
                        data-bs-ride="carousel">
                        <div className="carousel-indicators carousel-indicators-rounded justify-content-start ms-0 mb-0">
                          <button
                            type="button"
                            data-bs-target="#reviewcarouselIndicators"
                            data-bs-slide-to="0"
                            className="active"
                            aria-current="true"
                            aria-label="Slide 1"></button>
                          <button
                            type="button"
                            data-bs-target="#reviewcarouselIndicators"
                            data-bs-slide-to="1"
                            aria-label="Slide 2"></button>
                          <button
                            type="button"
                            data-bs-target="#reviewcarouselIndicators"
                            data-bs-slide-to="2"
                            aria-label="Slide 3"></button>
                        </div>
                        {/* end carouselIndicators  */}
                        <div className="carousel-inner">
                          <div className="carousel-item active">
                            <div className="testi-contain text-white">
                              <i className="bx bxs-quote-alt-left text-success display-6"></i>

                              <h4 className="mt-4 fw-medium lh-base text-white">
                                “Thank you for your attentive and expertise
                                getting my application for British Citizenship
                                approved. The service you provided was very
                                good.”
                              </h4>
                              <div className="mt-4 pt-3 pb-5">
                                <div className="d-flex align-items-start">
                                  <div className="flex-grow-1 ms-3 mb-4">
                                    <h5 className="font-size-18 text-white">
                                      Maureen Papas
                                    </h5>
                                    <p className="mb-0 text-white-50">
                                      Allpress Espresso, UK
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="carousel-item">
                            <div className="testi-contain text-white">
                              <i className="bx bxs-quote-alt-left text-success display-6"></i>

                              <h4 className="mt-4 fw-medium lh-base text-white">
                                “Professional immigration advise and responsible
                                stuff.”
                              </h4>
                              <div className="mt-4 pt-3 pb-5">
                                <div className="d-flex align-items-start">
                                  <div className="flex-grow-1 ms-3 mb-4">
                                    <h5 className="font-size-18 text-white">
                                      Wen Qi - China
                                    </h5>
                                    <p className="mb-0 text-white-50">
                                      Tier 1 (Entrepreneur)
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="carousel-item">
                            <div className="testi-contain text-white">
                              <i className="bx bxs-quote-alt-left text-success display-6"></i>

                              <h4 className="mt-4 fw-medium lh-base text-white">
                                “Of course, I am fully satisfied with the
                                services provided by you and would like to
                                continue dealing with you in future.”
                              </h4>
                              <div className="mt-4 pt-3 pb-5">
                                <div className="d-flex align-items-start">
                                  <div className="flex-1 ms-3 mb-4">
                                    <h5 className="font-size-18 text-white">
                                      SHAHZAD RAZA - ULTIMATE T LTD
                                    </h5>
                                    <p className="mb-0 text-white-50">
                                      Tier 1 (Entrepreneur)
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : ("")}
      </div>
    </div>
  );
};

export default VerifySms;
