import React, { useEffect, useState } from "react";

import PrivateRoute from "./Utils/PrivateRoute";
import PublicRoute from "./Utils/PublicRoute";
import PublicRouteAdmin from "./Utils/PublicRouteAdmin";
import PrivateRouteAdmin from "./Utils/PrivateRouteAdmin";
import { getToken, removeUserSession, setUserSession } from "./Utils/Common";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Login from "./Login";

import Register from "./Register";

import VerifySms from "./Verifysms";

import VerifyEmail from "./verifyemail";

import Forgotpassword from "./Forgotpassword";

import ResetPassword from "./reset_password";

import Dashboard from "./Dashboard";
//const Dashboard = React.lazy(() => import("./Dashboard"));

import Overview from "./Overview";
//const Overview = React.lazy(() => import("./Overview"));

import DashOverview from "./dash_overview";
//const DashOverview = React.lazy(() => import("./dash_overview"));

import ApplicationList from "./applicationList";
//const ApplicationList = React.lazy(() => import("./applicationList"));

import Application from "./Application";
//const Application = React.lazy(() => import("./Application"));

import EditApplication from "./editApplication";
//const EditApplication = React.lazy(() => import("./editApplication"));

import ViewApplication from './viewApplication';
//const ViewApplication = React.lazy(() => import("./viewApplication"));

import GenerateApplicationPDF from './GeneratePdf';
//const ViewApplication = React.lazy(() => import("./viewApplication"));

import Messages from "./Messages";
//const Messages = React.lazy(() => import("./Messages"));

import ClientTracking from "./clientTracking";
//const ClientTracking = React.lazy(() => import("./clientTracking"));

import Invoices from "./Invoices";
//const Invoices = React.lazy(() => import("./Invoices"));

import AdminDashboard from "./Admin/AdminDashboard";
//const AdminDashboard = React.lazy(() => import("./Admin/AdminDashboard"));

import AdminLogin from "./Admin/Admin_login";
//const AdminLogin = React.lazy(() => import("./Admin/Admin_login"));




import IndexDashboard from "./IndexDashboard";
//const IndexDashboard = React.lazy(() => import("./IndexDashboard"));

import NotFound from "./404";
//const NotFound = React.lazy(() => import("./404"));

import NotFoundInner from "./Inner404";
//const NotFoundInner = React.lazy(() => import("./Inner404"));

import PreScreening from "./Admin/pre_screening";
//const PreScreening = React.lazy(() => import("./Admin/pre_screening"));

import Clients from "./Admin/Clients";
//const Clients = React.lazy(() => import("./Admin/Clients"));

import ClientApplications from "./Admin/client_applications";
//const ClientApplications = React.lazy(() => import("./Admin/client_applications"));

import AdminCreateApplication from "./Admin/admin_createApplication";
//const AdminCreateApplication = React.lazy(() => import("./Admin/admin_createApplication"));

import AdminViwApplication from "./Admin/admin_viewApplication";
//const AdminViwApplication = React.lazy(() => import("./Admin/admin_viewApplication"));

import AdminGenerateApplicationPDF from "./Admin/Admin_GeneratePDF";
//const AdminViwApplication = React.lazy(() => import("./Admin/admin_viewApplication"));

import AdminEditApplication from "./Admin/admin_editApplication";
//const AdminEditApplication = React.lazy(() => import("./Admin/admin_editApplication"));

import Location from "./Admin/location";
//const Location = React.lazy(() => import("./Admin/location"));

import DefaultLocation from "./Admin/default_location";
//const DefaultLocation = React.lazy(() => import("./Admin/Default_location"));

import Tracking from "./Admin/tracking";
//const Tracking = React.lazy(() => import("./Admin/tracking"));

import AdminMessages from "./Admin/admin_messages";
//const AdminMessages = React.lazy(() => import("./Admin/admin_messages"));

import Users from "./Admin/users";
//const Users = React.lazy(() => import("./Admin/users"));

import AdminForgottenPass from "./Admin/Admin_forgottenpassword";

import AdminResetPassword from "./Admin/Admin_resetpassword";

import AdminNav from "./Admin/Admin_nav";

//import Billing from "./Admin/Billing";
const Billing = React.lazy(() => import("./Admin/Billing"));

//import AdminSettings from "./Admin/admin_settings";
const AdminSettings = React.lazy(() => import("./Admin/admin_settings"));



//import ProtectedRoute from "./ProtectedRoute";

export default function App() {
  /* const { authed } = useAuth();

  const checkLogin = localStorage.getItem("token");

  console.log("app Authed:",authed); */

  const [authLoading, setAuthLoading] = useState(true);

  /* useEffect(() => {
    const token = getToken();
    if (!token) {
      return;
    }
    else{
      setAuthLoading(false);
    }

    // axios.get(`http://localhost:4000/verifyToken?token=${token}`).then(response => {
     try {
     // setUserSession("dfzz", { username: "prince" });
      setAuthLoading(false);
    } catch (error) {
      removeUserSession();
      setAuthLoading(false);
    } 

  }, [authLoading, setAuthLoading]);

  if (authLoading && getToken()) {
    return <div className="content">Checking Authentication...</div>;
  } */

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />
        <Route path="/forgotpassword" element={<PublicRoute><Forgotpassword /></PublicRoute>} />
        <Route path="/reset_password" element={<PublicRoute><ResetPassword /></PublicRoute>} />
        <Route path="/register" element={<PublicRoute><Register /></PublicRoute>} />
        <Route path="/verifysms" element={<PublicRoute><VerifySms /></PublicRoute>} />
        <Route path="/verify_email" element={<PublicRoute><VerifyEmail /></PublicRoute>} />

        <Route path="/admin_login" element={<PublicRouteAdmin><AdminLogin /></PublicRouteAdmin>} />
        <Route path="/admin_forgotten_password" element={<PublicRouteAdmin><AdminForgottenPass /></PublicRouteAdmin>} />
        
        <Route path="/admin_resetpassword" element={<PublicRouteAdmin><AdminResetPassword /></PublicRouteAdmin>} />

        <Route
          path="/admin_nav"
          element={
            <PrivateRouteAdmin>
             <AdminDashboard />
            </PrivateRouteAdmin>
          }/>
        <Route
          path="/admin_nav/*"
          element={
            <PrivateRouteAdmin>
             <AdminNav />
            </PrivateRouteAdmin>
          }>

        <Route path="admin_dashboard" element={<AdminDashboard />} />
        <Route path="clients" element={<Clients />} />
        <Route path="pre_screening" element={<PreScreening />} />
        <Route path="client_applications" element={<ClientApplications />} />

        
        <Route path="admin_create_application" element={<AdminCreateApplication />} />
        <Route path="admin_view_application/:appid" element={<AdminViwApplication />} />
        <Route path="admin_generatepdf/:appid" element={<AdminGenerateApplicationPDF />} />
        <Route path="admin_edit_application/:appid" element={<AdminEditApplication />} /> 

        <Route path="billing" element={<React.Suspense fallback={<>...</>}><Billing /></React.Suspense>} />
        <Route path="location" element={<Location />} />
        <Route path="default_branch" element={<DefaultLocation />} />
        <Route path="tracking" element={<Tracking />} />
        <Route path="admin_messages" element={<AdminMessages />} />
        <Route path="user_management" element={<Users />} />
        <Route path="admin_settings" element={<React.Suspense fallback={<>...</>}><AdminSettings /></React.Suspense>} />
        <Route path="*" element={<NotFoundInner />} />
        </Route>

        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
             <Dashboard />
            </PrivateRoute>
          }/>

        <Route
          path="/dashboard/*"
          element={
            <PrivateRoute>
             <Dashboard />
            </PrivateRoute>
          }>
          <Route path="overview" element={<Overview />} />
          <Route path="application_list" element={<ApplicationList />} />
          <Route path="creat_application" element={<Application />} />
          <Route path="edit_application/:appid" element={<EditApplication />} />
          <Route path="view_application/:appid" element={<ViewApplication />} />
          <Route path="pdf_application/:appid" element={<GenerateApplicationPDF />} />
          <Route path="messages" element={<Messages />} />
          <Route path="application_tracking" element={<ClientTracking />} />
          <Route path="invoice" element={<Invoices />} />
          <Route path="IndexDashboard" element={<IndexDashboard />} />
          <Route path="*" element={<NotFoundInner />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
      {/* <div>
        <center>
          GET CODE AND TUTORIAL AT :
          <br />
          <strong>
            <a href="# ">
              SOme Link
            </a>
          </strong>
        </center>
      </div> */}
    </Router>
  );
}
