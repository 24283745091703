import React, { useState, useEffect, Fragment } from "react";
import { useMutation, useQuery } from "react-query";
import { useForm } from "react-hook-form";
import * as api from '../Utils/adminApiCalls';
import {sleep} from '../Utils/Common';
import { useNavigate, useSearchParams } from "react-router-dom";

const AdminResetPassword = () => {

    const {
        register,
        handleSubmit,
        trigger,
        formState: { errors },
      } = useForm();
    
      const navigate = useNavigate();
      const [searchParams] = useSearchParams();

      const [fmstate, setFmtate] = useState(
        {
            id:"",
            token:"",
            password: "",
            password_confirmation:""
        }
      );

      const [showPassword, setShowPassword] = useState(false);
    
      const [errorlist, setErrorlist] = useState([]);

      useEffect(() => {

            const currentParams = Object.fromEntries([...searchParams]);
            console.log("currentParams :::: ",currentParams); // get new values onchange
    
            if(currentParams){
    
               //mutate({"email_token":currentParams.verifycode, "id": currentParams.id});
               let resetpayload = {...fmstate};
               resetpayload.id = currentParams.id;
               resetpayload.token = currentParams.email_token;
               setFmtate(resetpayload);
               setFmtate(fmstate => {
                   console.log("fmstate:::",fmstate);
                   return fmstate;
               })
                
            }
            else{
                return navigate("/Admin_login", { replace: true });
            }
      
      }, [searchParams]);
    
      const {isError, isSuccess, error, isLoading, mutate, data} = useMutation(api.adminresetpassword, {
        onSuccess: (data) => {
          console.log("forgotten password ", data);

          sleep(4000).then(() => {
          navigate("/admin_login", {replace: true});
          });
    
        },
        onError: (error) => {
          console.log("errors",error.response.data); 
          const errlist = []
          const errObject = error.response.data?.errors
          if(error.response?.data?.errors !== undefined && typeof error.response?.data?.errors === 'object')
           {
                for(var propName in errObject) {
                  if(errObject.hasOwnProperty(propName)) {
                      errlist.push(errObject[propName]);
                  }
                }
                setErrorlist(errlist);
           }
           else if(error.response?.data?.message) {
            errlist.push(error.response?.data?.message);
            setErrorlist(errlist);
           }
           else
           {
             errlist.push(error.message)
             setErrorlist(errlist);
           }
    
           console.log("errlist",errlist); 
          
        }
      });


       ////////Company Information :////////////
  const [companyDetails, setCompanyDetails] = useState({});

  const getCompanyInfoNav = useQuery("get_companyinfo", api.companyInfo, {
    enabled: true,
    refetchOnMount:true,
    refetchOnWindowFocus:true,
    onSuccess: (data) => {
      console.log("users data:::", data);

      const apd = {...data?.Info[0]};
      apd.URL = data?.URL;
      console.log("@@@@@@@@@@@@@@@@@@", apd)
      setCompanyDetails(apd);
      setCompanyDetails(companyDetails => {
        console.log("companyDetails ===>>>",companyDetails);
        return companyDetails;
      })
    },
    onError: (error) => {
        console.log("Error Message ::: ",error); 
    }
  });

    
      const toggleShowpassword = () => {
        setShowPassword(!showPassword);
      };
    
    
    
      const handleFormChange = async (e) => {
        let loginParamsNew = { ...fmstate };
        let val = e.target.value;
        loginParamsNew[e.target.name] = val;
        setFmtate(loginParamsNew);
        console.log(fmstate);
      };
    
      const handleforgottenpassword = async () => {
        console.log(fmstate);
          
        console.log(fmstate);
        const validate = await trigger();
        if(validate && fmstate.password === fmstate.password_confirmation){
          mutate(fmstate);
        }
        
      };

      const handleOnKeyPress = (e) => {
        if (e.keyCode === 13) {
          e.preventDefault()
          handleforgottenpassword();
          console.log('submit')
        }
      }

    return (
       <>
<div className="color-line"></div>

<div className="login-container">
    <div className="row">
        <div className="col-md-12">
            <div className="text-center m-b-md">


            <div className="mb-4 mb-md-5 text-center">
                    <a href="index.html" className="d-block auth-logo">
                      {/* <img src="assets/images/logo.png" alt="" height="20%" />{" "} */}
                      <img src={companyDetails?.URL || "assets/images/logo.png"} alt="logo" width="217px" height="92px" /* height="20%" */ />
                      <span className="logo-txt"></span>
                    </a>
                  </div>

                <h3>SYSTEM USER : Reset Password</h3>
                <small>Enter your New Password!</small>
                {isSuccess && (
                  <h4>{data.message}</h4>
                )}
            </div>
            <div className="hpanel">
                <div className="panel-body" style={{border: "solid 1px #c5c5c973"}}>
                        <form id="loginForm" onSubmit={handleSubmit(handleforgottenpassword)}>
                        <label className="control-label" htmlFor="password">Password</label>
                        <div className="input-group" style={{width: "100%"}}>
                                <input 
                                type={showPassword ? "text" : "password"}
                                className={`form-controlL ${errors.password ? "redborder" : ""}`}
                                {...register("password", { required: true })}
                                autoComplete="current-password"
                                onChange={handleFormChange}
                                onKeyDown={handleOnKeyPress}
                                aria-label="Password"
                                aria-describedby="password-addon"
                                id="password"
                                name="password"
                                placeholder="Please enter new password"
                                title="Please enter new password" 
                                />
                                <button
                                    className="btn btn-light shadow-none ms-0"
                                    type="button"
                                    id="password-addon"
                                    onClick={toggleShowpassword}>
                                    <i className="mdi mdi-eye-outline"></i>
                                </button>
                            </div>
                            {errors.password && (
                                <span className="errorValidate help-block small">
                                    Please enter a password
                                </span>
                            )}
                           
                           <br />
                           
                            <label className="control-label" htmlFor="password_confirmation">Confirm Password</label>
                            <div className="input-group" style={{width: "100%"}}>
                                <input 
                               type={showPassword ? "text" : "password"}
                                className={`form-controlL ${
                                  errors.password_confirmation ? "redborder" : ""
                                }`}
                                {...register("password_confirmation", {
                                  required: true,
                                })}
                                id="password_confirmation"
                                name="password_confirmation"
                                aria-label="password_confirmation"
                                aria-describedby="confirmpassword-addon"
                                onChange={handleFormChange}
                                onKeyDown={handleOnKeyPress}
                                placeholder="Please confirm password"
                                title="Please confirm password" 
                                />
                                <button
                                    className="btn btn-light shadow-none ms-0"
                                    type="button"
                                    id="confirmpassword-addon"
                                    onClick={toggleShowpassword}>
                                    <i className="mdi mdi-eye-outline"></i>
                                </button>
                            </div>
                            {errors.password && (
                                <span className="errorValidate help-block small">
                                    Please confirm password
                                </span>
                            )}
                           <br />
                           {isLoading ? 
                            (
                               <div className="p-2 mt-4 text-center"><p className="text-muted presend">...Sending Request</p></div>
                            ):(
                               <button className="btn btn-success btn-block">Reset</button>
                            )}
                        </form>

                        {(fmstate.password !== fmstate.password_confirmation) ? 
                          (<div className="mt-5 text-center">
                            <ul className="errorlist">
                            <li className="text-muted-error">
                                Password Confirmation does not match
                            </li>
                            </ul>
                            </div>
                         ):(
                             ""
                         )}

                        {isError && (
                            <div className="mt-5 text-center">
                                <ul className="errorlist">
                                {
                                errorlist.map((errorItem, i) =>{
                                    return(
                                        <li key={i} className="text-muted-error">
                                            {errorItem}
                                        </li>
                                    )
                                })
                                }
                            </ul>
                            </div>
                        )}
                </div>
            </div>
        </div>
    </div>
    <div className="row">
        <div className="col-md-12 text-center">
            {/* <strong>I-VISAS</strong> - APPLICATION <br/>  © {new Date().getFullYear()} i-visas.com */}
            <strong>{companyDetails?.COMPANY_NAME ?? "I-VISAS"}</strong> - APPLICATION <br/>  © {new Date().getFullYear()} {companyDetails?.COMPANY_NAME ?? "i-visas.com"}
        </div>
    </div>
</div>
</>
    )
}

export default AdminResetPassword;