import React, { useState, useEffect, useRef } from "react";
import { useQuery, useMutation } from "react-query";
import { useForm } from "react-hook-form";
import { getAdminUser, sleep, removeUserAdminSession, useDebounce } from "../Utils/Common";
import * as api from '../Utils/adminApiCalls';
import "./AdminDashboard.css";
import { useNavigate, Link } from "react-router-dom";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import moment from "moment";
import toast, { Toaster } from 'react-hot-toast';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { Modal, Button } from 'react-bootstrap';
import AdminNav from "./Admin_nav";

const pageLimit = 5;
const dataLimit = 10;

const PreScreening = () => {

  const navigate = useNavigate();

    const [userData, setUserData] = useState({});

    const [psData, setPsData] = useState([]);

    const [errorls, setErrorls] = useState([]);

    const [sendmsgLoading, setSendmsgLoading] = useState(false);

    const [convertedText, setConvertedText] = useState("");

  const [convertedTextStatus, setConvertedTextStatus] = useState(false);
  
  const [clientID, setClientID] = useState("");

    const {
      register,
      trigger,
      reset,
      formState: { errors },
    } = useForm();

    const {
      register: registerDecline,
      trigger: triggerDecline,
      reset: resetDecline,
      formState: { errors: errorsd },
    } = useForm();
    

    const [open, setOpen] = useState(false);

    const [msgopen, setMsgOpen] = useState(false);

    const handleClose = () => {
      setDeclinemessage("");
      resetDecline();
      setDecliner(false);
      setOpen(false);
    }

    const [message, setMessage] = useState(
      {
        MESSAGE_TO: "",
        MESSAGE_SUBJECT: "",
        MESSAGE_TAG: "",
        MESSAGE: "",
      }
    );

    const modules = {
      toolbar: [
        [{ header: "1" }, { header: "2" }, { font: [] }],
        [{ size: [] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        ["link"],
        ["clean"],
      ],
      clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
      },
    };
    /*
     * Quill editor formats
     * See https://quilljs.com/docs/formats/
     */
    const formats = [
      "header",
      "font",
      "size",
      "bold",
      "italic",
      "underline",
      "strike",
      "blockquote",
      "list",
      "bullet",
      "indent",
      "link",
      "image",
      "video",
    ];

    /////////////////Locations /////////////////

    const [officeLocations, setOfficeLocation] = useState([]);
    const getAllLocations = useQuery("locationlist", () => api.getOfficeLocations(), {
      refetchOnWindowFocus: false,
      //enabled: locopen, // turned off by default, manual refetch is needed
      onSuccess: (data) => {
          console.log("location details: ",data);
    
          const apd = [...data];
    
          setOfficeLocation(apd);
    
          setOfficeLocation((officeLocations) => {
            return officeLocations;
          });
      },
      onError: (error) => {
        
        console.log("Error Message",error); 
      
        if(error.response.status === 401){
          toast.error("Session Expired : Please Re-Login",{
            duration: 6000,
            });
         removeUserAdminSession();
          navigate("/admin_login", {replace: false});
        }
        else {
          toast.error("Something went wrong",{
            duration: 6000,
            });
        }
      
      } 
    })

    ///////////////////////////////////////////

    ///////////Approve Pre screen////////////

    const ApprovePrescreen = useMutation(api.approvePrescreen, {
      onSuccess: (data) => {
  
         console.log("prescreenStatus approve:: ",data);
  
         toast.success('PreScreen Approved Successfully',{
          duration: 6000,
        });

        prescreenrefetch();
        getPrescreenDetails.refetch();
        
      },
      onError: (error) => {
    
        console.log("Error Message",error); 
      
        if(error?.response?.status === 401){
          toast.error("Session Expired : Please Re-Login",{
            duration: 6000,
            });
         removeUserAdminSession();
          navigate("/admin_login", {replace: false});
        }
        else {
          toast.error("Something went wrong",{
            duration: 6000,
            });
        }
      
      } 
    });

    const approvePscreen = (e) => {

      e.preventDefault();

      const clientid = e.target.getAttribute("data-clientid");

      if(clientid){
            let aprdata = {
                      "id": userData?.id,
                      "client_id": Number(clientid)
                    }
            ApprovePrescreen.mutate(aprdata);
            console.log("aprdata",aprdata);
        }
        else{
            console.log("bad client ID:", clientid);
        }
    }

    /////////////////


    ///////Decline Comment/////////

    const [decliner, setDecliner] = useState(false);
    const [declinemessage, setDeclinemessage] = useState("");

    const handleDeclineChange = (e) => {
         setDeclinemessage(e.target.value);
    }

    const handleToggleDeclineOn = () => {
      setDecliner(true);
    }

    const handleToggleDeclineOff = () => {
      resetDecline();
      setDeclinemessage("");
      setDecliner(false);
    }

    ///////////Disapprove Pre screen////////////

    const DisapprovePrescreen = useMutation(api.declinePrescreen, {
      onSuccess: (data) => {
  
         console.log("prescreenStatus decline:: ",data);
  
         toast.success('PreScreen Declined Successfully',{
          duration: 6000,
        });

        resetDecline();
        setDeclinemessage("");
        setDecliner(false);
        prescreenrefetch();
        setOpen(false);
        
      },
      onError: (error) => {
    
        console.log("Error Message",error); 
      
        if(error?.response?.status === 401){
          toast.error("Session Expired : Please Re-Login",{
            duration: 6000,
            });
         removeUserAdminSession();
          navigate("/admin_login", {replace: false});
        }
        else {
          toast.error("Something went wrong",{
            duration: 6000,
            });
        }
      
      } 
    });

    const declinePscreen = async (e) => {

      e.preventDefault();

      const clientid = e.target.getAttribute("data-clientid");
      const validateDeclineMsg = await triggerDecline();
      if(clientid && validateDeclineMsg){
            let aprdata = {
                      "id": userData?.id,
                      "client_id": Number(clientid),
                      "MESSAGE": declinemessage
                    }
          DisapprovePrescreen.mutate(aprdata);
            console.log("aprdata",aprdata);
        }
        else{
            console.log("bad client ID:", clientid);
        }
    }


    ///////////////////////////

    ///////send message/////////

    const sendMessage = useMutation(api.sendUserMessage, {
      onSuccess: (data) => {
        console.log("onSuccess data",data, "id: ", data.id);
        toast.success('Message Successfully sent!',{
          duration: 6000,
        });
        reset();
  
        const resetMessageField = {
          MESSAGE_TO: "",
          MESSAGE_SUBJECT: "",
          MESSAGE_TAG: "",
          MESSAGE: "",
          };
  
        setMessage(resetMessageField);
  
        setMessage((message)=> {
          return message;
        });
  
        setSendmsgLoading(false);
  
        setConvertedText("");
  
      },
      onError: (error) => {
    
        console.log("Error Message",error); 
      
        if(error?.response?.status === 401){
          toast.error("Session Expired : Please Re-Login",{
            duration: 6000,
            });
         removeUserAdminSession();
          navigate("/admin_login", {replace: false});
        }
        else {
          toast.error("Something went wrong",{
            duration: 6000,
            });
        }
      
      } 
    });


    const handleMsgSend = async (e) => {
    
      const clientid = e.target.getAttribute("data-clientid");

      console.log("message data", message, "client id:",clientid);
  
      const msgpayload = {...message};
     
      msgpayload.MESSAGE = convertedText;
      msgpayload.id = userData.id;
      msgpayload.MESSAGE_TO = Number(clientid);
  
      console.log("msgpayload on send", msgpayload);
         
      const msgvalidate = await trigger();
  
      if (msgvalidate && msgpayload.MESSAGE !== "" && clientid !== undefined && userData.id !== undefined) {
         setConvertedTextStatus(false);
         
         setSendmsgLoading(true);
         sendMessage.mutate(msgpayload);
       }
       else{
         if(convertedText === ""){
          setConvertedTextStatus(true);
         }
       } 
    };

    ////////////////////////////

  
    const client_id = useRef(0)
    const [prescreenDetails, setPrescreenDetails] = useState([]);

    const getPrescreenDetails = useQuery(["prescreenDetails", client_id.current], () => api.getPrescreenByClientID(client_id.current), {
      refetchOnWindowFocus: false,
      enabled: Boolean(client_id.current), // turned off by default, manual refetch is needed
      onSuccess: (data) => {
          console.log("prescreenDetails : ",data);
          const apd = [...data];
    
          console.log("apd prescreenDetails : ", apd);
          setPrescreenDetails(apd);
          setPrescreenDetails((prescreenDetails) => {
           return prescreenDetails;
          });
    
        setOpen(true);
        //client_id.current = 0;
      },
      onError: (error) => {
        
        console.log("Error Message",error); 
        client_id.current = 0;
      
        if(error.response.status === 401){
          toast.error("Session Expired : Please Re-Login",{
            duration: 6000,
            });
         removeUserAdminSession();
          navigate("/admin_login", {replace: false});
        }
        else {
          toast.error("Something went wrong",{
            duration: 6000,
            });
        }
      
      } 
    })
  
  const getClientID = (e) => {

    console.log("cliennt ID",e.target.getAttribute("data-clientid"));

    const clientid = e.target.getAttribute("data-clientid");

    setClientID(e.target.getAttribute("data-clientid"));

    if(clientid) {

      client_id.current = clientid;

      getPrescreenDetails.refetch();////mutate({"client_id": clientid});

    }
    else return null;

  };

  const handleMsgShow = (e) => {
    setClientID(e.target.getAttribute("data-clientid"));
    setMsgOpen(true);
    console.log("cliennt ID",clientID)
  }

  const handleMsgClose = () => {

    const resetMessageField = {
      MESSAGE_TO: "",
      MESSAGE_SUBJECT: "",
      MESSAGE_TAG: "",
      MESSAGE: "",
    };

    setMessage(resetMessageField);

    setMessage((message)=> {
      return message;
    });

    setConvertedText("");
    
    setMsgOpen(false);

  }

  const handleMsgChange = (e) => {
    e.persist();
    const { name, value, type } = e.target;
    //console.log("name:" + name + "  value:" + value + "type" + type);

    setMessage((message) => ({
      ...message,
      [name]: value,
    }));

       setMessage((message) => {
        //console.log("message on send", message);
        return message;
      }); 
  }

  useEffect(() => {

    function checkUserData() {
      const item = getAdminUser();
  
      if (item) {
        console.log("user item",item);
        setUserData(item);

        setUserData((userData) => {
          console.log("updated details", userData);
          return userData;
        });

      }
    }
    
    checkUserData();

    window.addEventListener('storage', checkUserData)
  
    return () => {
      window.removeEventListener('storage', checkUserData)
    }
    
  }, []);
  
  const clientPrescreens = useQuery(['clientPrescreens', userData?.id], () => api.getClientPrescreen(), {
      onSuccess: (data) => {

        setPsData(data);

        setPsData((psData)=> {
          console.log("psData", psData[0])
          return psData;
        })   
      }
  });

  const { loading: loadingapp, data: prescreenData, refetch:prescreenrefetch  } = clientPrescreens;


    const createdDate = (datestr, l=0)=> {

      if(l === 0){
      const start = moment(datestr).format("dddd, MMMM Do YYYY, h:mm:ss a");
      
      return start;
    }
    else if(l === 1){
      const start = moment(datestr).format("llll");

      return start;
    }
     
    };

    /////////////Pagination ////////////

  //const [pages] = useState(Math.ceil(datamsg?.length / dataLimit) || 1);
  const pages = useRef(Math.ceil(psData?.length / dataLimit) || 1);

  //console.log("dataLimit::", dataLimit," datamsg length::", datamsg?.length, " pages::: ", Math.ceil(psData?.length / dataLimit));

  const [currentPage, setCurrentPage] = useState(1);

  const goToNextPage = () => {
      if(currentPage <= pages.current) {
      setCurrentPage((page) => page + 1);
      }
  }

  const goToPreviousPage = () => {
      if(currentPage > 1 ){
      setCurrentPage((page) => page - 1);
      }
  }

  const changePage = (event) => {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage(pageNumber);
  }

  const getPaginatedData = () => {
    const startIndex = currentPage * dataLimit - dataLimit;
    const endIndex = startIndex + dataLimit;
    //console.log("getPaginationData", psData?.slice(startIndex, endIndex))
    return psData?.slice(startIndex, endIndex);
  };

  const getPaginationGroup = () => { 
    let start = Math.floor((currentPage - 1) / pageLimit) * pageLimit;
    
    let page = Math.ceil(psData?.length / dataLimit) || 1;
    //console.log("local page ", Math.ceil(psData?.length / dataLimit));
    //console.log("Array Return", new Array(pages).fill().map((_, idx) => start + idx + 1), "  pages : ", pages);

    pages.current = page;
    
     return new Array(page).fill().map((_, idx) => start + idx + 1);
  }

  ////////////////////////////////////



   /////////////////Search function ///////////////
const [searchquery, setSearchquery] = useState("");

const [searchApi, setSearchAPi] = useState("");

const searchPreScreen = (q) => {
 //console.log("query: ", q.target.value);

 const query = q.target.value;

 setSearchquery(query);

 setSearchquery(searchquery => {
   return searchquery;
 });
 
 if(query.length === 0){
  
  console.log("Refetching PreScreen list 2", query, " query length next: ", query.length);
     
  prescreenrefetch();
 }
}

const debouncedFilter = useDebounce(searchquery, 500);

console.log("debouncedFilter", debouncedFilter); 

const { data:searchData, isLoading:searchLoading } = useQuery(
 ['applications', debouncedFilter], 
 () => api.searchPrescreening(debouncedFilter),
 { enabled: Boolean(debouncedFilter),
 onSuccess: (data) => {
     console.log("data", data?.attributes);
     const apd = [...data?.attributes];
     setPsData(apd);
     setPsData((psData) => {
        return psData;
     });
 },
 onError: (error) => {
    
  console.log("Error Message",error); 

  if(error?.response?.status === 401){
    toast.error("Session Expired : Please Re-Login",{
      duration: 6000,
      });
   removeUserAdminSession();
    navigate("/admin_login", {replace: false});
  }
  else {
    toast.error("Something went wrong",{
      duration: 6000,
      });
  }

} 
})

 /////////////////////////////////

 ///////////////Top Thirty/////////////////

 const topThirty = () => {
  // manually refetch
  console.log("topThirty btn clicked");
  toprefetch();
};

const { refetch:toprefetch } = useQuery("key", api.topThirtyPrescreen, {
  refetchOnWindowFocus: false,
  enabled: false, // turned off by default, manual refetch is needed
  onSuccess: (data) => {
    console.log("topThirty data", data);
    const apd = [...data?.attributes];
    setPsData(apd);
     setPsData((psData) => {
        return psData;
     });
},
onError: (error) => {
    
  console.log("Error Message",error); 

  if(error?.response?.status === 401){
    toast.error("Session Expired : Please Re-Login",{
      duration: 6000,
      });
   removeUserAdminSession();
    navigate("/admin_login", {replace: false});
  }
  else {
    toast.error("Something went wrong",{
      duration: 6000,
      });
  }

} 
});

 //////////////////////////////////////////


 /////////////// PreScreen by Location/////////////////
 const [bylocation, setBylocation] = useState({});

 const psByLocation = (e) => {
  // manually refetch
  console.log("location ::", e.target.value);
  
  const locat = e.target.value;
  setBylocation(locat);
  (locat && locat !== "") && Prescreenbylocation.mutate({"client_office": locat});
};

const Prescreenbylocation = useMutation("bylocation", api.searchPreScreenbyLocation, {
  refetchOnWindowFocus: false,
  enabled: false, // turned off by default, manual refetch is needed
  onSuccess: (data) => {
    console.log("by location data", data);
    const apd = [...data?.attributes];
    setPsData(apd);
     setPsData((psData) => {
        return psData;
     });
},
onError: (error) => {
    
  console.log("Error Message",error); 

  if(error?.response?.status === 401){
    toast.error("Session Expired : Please Re-Login",{
      duration: 6000,
      });
   removeUserAdminSession();
    navigate("/admin_login", {replace: false});
  }
  else {
    toast.error("Something went wrong",{
      duration: 6000,
      });
  }

} 
});

 //////////////////////////////////////////

    return (
        <>
        <Toaster />

<div className="normalheader" id="tpanel">
        <div className="hpanel">
          <div className="panel-body">
            <a className="small-header-action" id="toglepanel" href="# ">
              <div className="clip-header">
                <i className="fa fa-arrow-up"></i>
              </div>
            </a>

            <div id="hbreadcrumb" className="pull-right m-t-lg">
              <ol className="hbreadcrumb breadcrumb">
                <li>
                  <a href="# ">i-visas</a>
                </li>
                <li>
                  <span>Admin</span>
                </li>
                <li className="active">
                  <span>Pre-screenings </span>
                </li>
              </ol>
            </div>
            <h2 className="font-light m-b-xs">Manage Pre-screenings</h2>
            <small>
              List of <strong>Pending</strong> and <strong>Approved</strong> Client Pre-screenings
            </small>
          </div>
        </div>
      </div>

    <div className="content">

    <div className="row">
            <div className="col-lg-12 text-center welcome-message">
                

            <div className="hpanel email-compose">
            <div className="panel-heading hbuilt">
                    <div className="text-center p-xs font-normal">
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={searchPreScreen}
                          value={searchquery || ""}
                          className="form-control input-sm"
                          placeholder="Search by Firstname, Lastname, Email, and Location..."
                        />{" "}
                        <span className="input-group-btn">
                          {" "}
                          <button
                            type="button"
                            disabled
                            className="btn btn-sm btn-default">
                            Search
                          </button>{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="panel-body">
                  {searchLoading ? (
                       <>
                      <div className="row text-center">
                      <div className="col-md-7 m-b-md" style={{textAlign: "left", display: "flex", flexDirection: "row", justifyItems: "flex-start", alignItems: "center", justifyContent: "normal"}}>

                              <button className="btn btn-info btn-sm" disabled >
                                <strong>Top 30</strong>
                              </button>
                             
                            <span>&nbsp; | Search by Location: &nbsp;</span>
                            <select
                                    name="bylocation"
                                    className="form-select m-b-3"
                                    style={{ width: "30%" }}>
                                    <option value=''>select location</option>
                                  </select>
                          </div>
                          <div className="col-md-5 mailbox-pagination m-b-md">
                            <span style={{ fontSize: "1.1rem" }}>
                            Showing 0 of 0 pages   &nbsp; &nbsp;
                            </span>
                            <div className="btn-group">
                              <button className="btn btn-default btn-sm previous disabled"
                             
                              disabled
                              >
                                <i className="fa fa-arrow-left"></i>
                              </button>
                              {getPaginationGroup().map((item, index) => (
                              <button 
                              key={index}
                              className="btn btn-default btn-sm disabled"
                              >
                              {item}
                              </button>
                              ))}
                              <button className="btn btn-default btn-sm next disabled"
                              disabled
                              >
                                <i className="fa fa-arrow-right"></i>
                              </button>
                            </div>
                          </div>
                      </div>
                        <div className="table-responsive" style={{overflowX: "inherit"}}>
                        <table className="table table-hover table-mailbox apptable">
                        <thead style={{borderBottom: "solid 2px #64d246"}}>
                          <tr>
                            <th style={{width: "150px"}} className="text-center">Full Name</th>
                            <th className="text-center">Approval Status</th>
                            <th className="text-center">Pre-Screening Status</th>
                            <th className="text-center">Office Location</th>
                            <th className="text-center">Creation Date </th>
                            <th className="text-left">Action</th>
                          </tr>
                        </thead>
                            <tbody>
                            <tr className="active"><td colSpan="7" style={{textAlign:"center"}}>Searching...</td></tr>
                        </tbody>
                      </table>
                        </div>
                        </>
                    ):(
                      <>
                    <div className="row">
                      <div className="col-md-7 m-b-md" style={{textAlign: "left", display: "flex", flexDirection: "row", justifyItems: "flex-start", alignItems: "center", justifyContent: "normal"}}>
                      
                          <button className="btn btn-info btn-sm" onClick={topThirty} >
                             <strong>Top 30</strong>
                          </button>
                        
                        <span>&nbsp; | <strong>Search by Location:</strong> &nbsp;</span>
                        <select
                                name="bylocation"
                               {...register("bylocation", { 
                                          required: { value: false, message: "This field is required" }, 
                                          onChange: (e) => {psByLocation(e)} })}
                                value={bylocation || ""} // Prop: The email input data
                               
                                className="form-select m-b-3"
                                style={{ width: "30%" }}>
                                <option value=''>select location</option>
                                {officeLocations?.map(loc => 
                                (
                                  <option key={loc.id} value={loc.LOCATION_CODE}>{loc.LOCATION_CODE}</option>
                                ))}
                              </select>
                      </div>
                      <div className="col-md-5 mailbox-pagination m-b-md">
                        <span style={{ fontSize: "1.1rem" }}>
                        {!!psData && (Object.keys(psData).length > 0) ? `Showing ${currentPage} of ${pages.current} pages` : "Showing 0 pages"}   &nbsp; &nbsp;
                        </span>
                        <div className="btn-group">
                          <button className={`btn btn-default btn-sm previous ${currentPage === 1 ? 'disabled' : ''}`}
                          onClick={goToPreviousPage}
                          disabled={!!psData && (currentPage === 1) ? true : false} 
                          >
                            <i className="fa fa-arrow-left"></i>
                          </button>
                          {getPaginationGroup().map((item, index) => (
                          <button 
                          key={index}
                          onClick={changePage}
                          className={`btn btn-default btn-sm  ${currentPage === item ? 'active' : null}`}
                          >
                           {item}
                          </button>
                          ))}
                          <button className={`btn btn-default btn-sm next ${currentPage === pages.current ? 'disabled' : ''}`}
                          onClick={goToNextPage} 
                          disabled={!!psData && (currentPage === pages.current) ? true : false} 
                          >
                            <i className="fa fa-arrow-right"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="table-responsive" style={{overflowX: "inherit"}}>
                      <table className="table table-hover table-mailbox apptable">
                      <thead style={{borderBottom: "solid 2px #64d246"}}>
                          <tr>
                            <th style={{width: "150px"}} className="text-center">Full Name</th>
                            <th className="text-center">Approval Status</th>
                            <th className="text-center">Pre-screening Status</th>
                            <th className="text-center">Office Location</th>
                            <th className="text-center">Creation Date </th>
                            <th className="text-left">Action</th>
                          </tr>
                        </thead>
                        <tbody>

                        {!!psData && (Object.keys(psData).length > 0) ? 
                            
                            getPaginatedData().map((item,i) =>{
                                         return (
                          <tr className="unread active" key={i}>
                            <td className="text-center">
                             {item.client_first_name} {item.client_middle_name} {item.client_last_name}
                            </td>
                            <td className="text-center">
                            {item.prescreened ? <i className="fa fa-check-square-o"></i> :
                            <i className="fa fa-times-circle"></i> }
                            {item.prescreened ? " Approved" : " Pending"}
                            </td>
                            <td className="text-center">
                            {item.prescreened_status > 1 ? <i className="fa fa-check-square"></i> :
                            <i className="fa fa-edit"></i> }
                            {item.prescreened_status > 1 ? " Submitted" : " Ongoing"}
                            </td>
                            <td className="text-center">
                              {item.LOCATION_CODE ?? "Not Set"}
                            </td>
                            <td className="text-center mail-date">
                            {createdDate(item?.created_at,1)}
                            </td>
                            <td className="text-left mail-date">
                            <div className="btn-group">

                                <button data-toggle="dropdown" className="btn btn-default dropdown-toggle">Action <span className="caret"></span></button>
                                <ul className="dropdown-menu">
                                    <li><Link to="# " data-clientid={item.client_id} onClick={getClientID} >{item.prescreened_status === 2 ? "Review" : "View"}</Link></li>
                                    {item.prescreened_status === 2 && (
                                    <>
                                    <li>
                                      <Link to="# " data-clientid={item.client_id} onClick={handleMsgShow}> Send Message </Link>
                                    </li>
                                   
                                    {/* <li> <Link to="# " data-clientid={item.client_id} onClick={approvePscreen}> Approve </Link> </li>
                                    <li> <Link to="# " onClick={getClientID}> Decline </Link> </li> */}
                                    </>
                                    )} 
                                    
                                </ul>
                          </div>
                            </td>
                          </tr>
                                )
                            }) : (
                            <tr className="unread active"><td colSpan="7" style={{textAlign:"center"}}>No Client Pre-screening found</td></tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    </>
                    )}
                  </div>
                  <div className="panel-footer text-left">
                  {!!psData && (Object.keys(psData).length > 0) ? psData.length : 0}  Pre-screenings
                 </div>
            </div>
        </div>

       
    </div>

    </div>

   {/*  <footer className="footer" style={{ maxHeight: "40px" }}>
          <div className="mt-4 mt-md-5 text-center" style={{ marginTop: "0" }}>
            <p className="footer-p">
              © <script>document.write(new Date().getFullYear())</script>{" "}
              i-visas.com
            </p>
          </div>
        </footer> */}


        <Modal show={open} onHide={handleClose} animation={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        >
           <div className="color-line"></div>
        <Modal.Header className="modal-header text-center" style={{padding: "10px 10px"}}>
          <Modal.Title>Client Pre-screening Response</Modal.Title>
        </Modal.Header>
        <Modal.Body>
         
        <div className="panel-body" style={{maxHeight: "400px", overflow: "scroll"}}>

              <div className="dd" id="nestable2">
              <ol className="dd-list">

                  <li className="dd-item" data-id="1">
                      <div className="dd-handle" style={{fontWeight:"bold"}}>
                          <span className="label h-bg-navy-blue" style={{color: "#6ae76a"}}><i className="fa fa-flag"></i></span> Please confirm how we can help you today:
                      </div>
                      <ol className="dd-list">
                          <li className="dd-item" data-id="2">
                              <div className="dd-handle">
                                  <span className="pull-right">  {prescreenDetails[0]?.how_we_can_help_you_question ? <i className="fa fa-check-square-o"></i> :
                      <i className="fa fa-times-circle"></i> }</span>
                                  <span className="label h-bg-navy-blue"><i className="fa fa-pencil"></i></span> {prescreenDetails[0]?.how_we_can_help_you_question || "No Response Provided"}
                              </div>
                          </li>
                      </ol>
                  </li>

                  <li className="dd-item" data-id="1">
                      <div className="dd-handle" style={{fontWeight:"bold"}}>
                          <span className="label h-bg-navy-blue" style={{color: "#6ae76a"}}><i className="fa fa-flag"></i></span> If other reason, Please specify: 
                      </div>
                      <ol className="dd-list">
                          <li className="dd-item" data-id="2">
                              <div className="dd-handle">
                                  <span className="pull-right"> {prescreenDetails[0]?.how_we_can_help_you ? <i className="fa fa-check-square-o"></i> :
                      <i className="fa fa-times-circle"></i> } </span>
                                  <span className="label h-bg-navy-blue"><i className="fa fa-pencil"></i></span> {prescreenDetails[0]?.how_we_can_help_you || "No Response Provided"}
                              </div>
                          </li>
                      </ol>
                  </li>

                  <li className="dd-item" data-id="1">
                      <div className="dd-handle" style={{fontWeight:"bold"}}>
                          <span className="label h-bg-navy-blue" style={{color: "#6ae76a"}}><i className="fa fa-flag"></i></span> Which country of residence are you currently in ?
                      </div>
                      <ol className="dd-list">
                          <li className="dd-item" data-id="2">
                              <div className="dd-handle">
                                  <span className="pull-right"> {prescreenDetails[0]?.country_of_residence ? <i className="fa fa-check-square-o"></i> :
                      <i className="fa fa-times-circle"></i> } </span>
                                  <span className="label h-bg-navy-blue"><i className="fa fa-pencil"></i></span> {prescreenDetails[0]?.country_of_residence || "No Response Provided"}
                              </div>
                          </li>
                      </ol>
                  </li>


                  <li className="dd-item" data-id="1">
                      <div className="dd-handle" style={{fontWeight:"bold"}}>
                          <span className="label h-bg-navy-blue" style={{color: "#6ae76a"}}><i className="fa fa-flag"></i></span> Do you have residence in this country of residence ?
                      </div>
                      <ol className="dd-list">
                          <li className="dd-item" data-id="2">
                              <div className="dd-handle">
                                  <span className="pull-right"> {prescreenDetails[0]?.residency_question ? <i className="fa fa-check-square-o"></i> :
                      <i className="fa fa-times-circle"></i> } </span>
                                  <span className="label h-bg-navy-blue"><i className="fa fa-pencil"></i></span> {prescreenDetails[0]?.residency_question || "No Response Provided"}
                              </div>
                          </li>
                      </ol>
                  </li>

                  <li className="dd-item" data-id="1">
                      <div className="dd-handle" style={{fontWeight:"bold"}}>
                          <span className="label h-bg-navy-blue" style={{color: "#6ae76a"}}><i className="fa fa-flag"></i></span> What type of permission do you have to be in
                              the country ?
                      </div>
                      <ol className="dd-list">
                          <li className="dd-item" data-id="2">
                              <div className="dd-handle">
                                  <span className="pull-right"> {prescreenDetails[0]?.type_of_permission ? <i className="fa fa-check-square-o"></i> :
                      <i className="fa fa-times-circle"></i> } </span>
                                  <span className="label h-bg-navy-blue"><i className="fa fa-pencil"></i></span> {prescreenDetails[0]?.type_of_permission || "No Response Provided"}
                              </div>
                          </li>
                      </ol>
                  </li>

                  <li className="dd-item" data-id="1">
                      <div className="dd-handle" style={{fontWeight:"bold"}}>
                          <span className="label h-bg-navy-blue" style={{color: "#6ae76a"}}><i className="fa fa-flag"></i></span> When does it expire
                      </div>
                      <ol className="dd-list">
                          <li className="dd-item" data-id="2">
                              <div className="dd-handle">
                                  <span className="pull-right"> {prescreenDetails[0]?.expiry ? <i className="fa fa-check-square-o"></i> :
                      <i className="fa fa-times-circle"></i> } </span>
                                  <span className="label h-bg-navy-blue"><i className="fa fa-pencil"></i></span> {prescreenDetails[0]?.expiry || "No Response Provided"}
                              </div>
                          </li>
                      </ol>
                  </li>

                  <li className="dd-item" data-id="1">
                      <div className="dd-handle" style={{fontWeight:"bold"}}>
                          <span className="label h-bg-navy-blue" style={{color: "#6ae76a"}}><i className="fa fa-flag"></i></span> Date of Birth 
                      </div>
                      <ol className="dd-list">
                          <li className="dd-item" data-id="2">
                              <div className="dd-handle">
                                  <span className="pull-right"> {prescreenDetails[0]?.dob ? <i className="fa fa-check-square-o"></i> :
                      <i className="fa fa-times-circle"></i> } </span>
                                  <span className="label h-bg-navy-blue"><i className="fa fa-pencil"></i></span> {prescreenDetails[0]?.dob || "No Response Provided"}
                              </div>
                          </li>
                      </ol>
                  </li>

                  <li className="dd-item" data-id="1">
                      <div className="dd-handle" style={{fontWeight:"bold"}}>
                          <span className="label h-bg-navy-blue" style={{color: "#6ae76a"}}><i className="fa fa-flag"></i></span> Do you speak english proficiently ?
                      </div>
                      <ol className="dd-list">
                          <li className="dd-item" data-id="2">
                              <div className="dd-handle">
                                  <span className="pull-right"> {prescreenDetails[0]?.english_proficiency ? <i className="fa fa-check-square-o"></i> :
                      <i className="fa fa-times-circle"></i> } </span>
                                  <span className="label h-bg-navy-blue"><i className="fa fa-pencil"></i></span> {prescreenDetails[0]?.english_proficiency || "No Response Provided"}
                              </div>
                          </li>
                      </ol>
                  </li>

                  <li className="dd-item" data-id="1">
                      <div className="dd-handle" style={{fontWeight:"bold"}}>
                          <span className="label h-bg-navy-blue" style={{color: "#6ae76a"}}><i className="fa fa-flag"></i></span> What level of Proficiency ?
                      </div>
                      <ol className="dd-list">
                          <li className="dd-item" data-id="2">
                              <div className="dd-handle">
                                  <span className="pull-right"> {prescreenDetails[0]?.english_proficiency_level ? <i className="fa fa-check-square-o"></i> :
                      <i className="fa fa-times-circle"></i> } </span>
                                  <span className="label h-bg-navy-blue"><i className="fa fa-pencil"></i></span> {prescreenDetails[0]?.english_proficiency_level || "No Response Provided"}
                              </div>
                          </li>
                      </ol>
                  </li>


                  <li className="dd-item" data-id="1">
                      <div className="dd-handle" style={{fontWeight:"bold"}}>
                          <span className="label h-bg-navy-blue" style={{color: "#6ae76a"}}><i className="fa fa-flag"></i></span> What other languages do you speak ?
                      </div>
                      <ol className="dd-list">
                          <li className="dd-item" data-id="2">
                              <div className="dd-handle">
                                  <span className="pull-right"> {prescreenDetails[0]?.other_languages ? <i className="fa fa-check-square-o"></i> :
                      <i className="fa fa-times-circle"></i> } </span>
                                  <span className="label h-bg-navy-blue"><i className="fa fa-pencil"></i></span> {prescreenDetails[0]?.other_languages || "No Response Provided"}
                              </div>
                          </li>
                      </ol>
                  </li>

                  <li className="dd-item" data-id="1">
                      <div className="dd-handle" style={{fontWeight:"bold"}}>
                          <span className="label h-bg-navy-blue" style={{color: "#6ae76a"}}><i className="fa fa-flag"></i></span> Have you ever been refused a visa/entry to
                              any country of residence in the world ?
                      </div>
                      <ol className="dd-list">
                          <li className="dd-item" data-id="2">
                              <div className="dd-handle">
                                  <span className="pull-right"> {prescreenDetails[0]?.visa_refusal ? <i className="fa fa-check-square-o"></i> :
                      <i className="fa fa-times-circle"></i> } </span>
                                  <span className="label h-bg-navy-blue"><i className="fa fa-pencil"></i></span> {prescreenDetails[0]?.visa_refusal || "No Response Provided"}
                              </div>
                          </li>
                      </ol>
                  </li>

                  <li className="dd-item" data-id="1">
                      <div className="dd-handle" style={{fontWeight:"bold"}}>
                          <span className="label h-bg-navy-blue" style={{color: "#6ae76a"}}><i className="fa fa-flag"></i></span> Please provide type of visa
                              refused/date/reason given ?
                      </div>
                      <ol className="dd-list">
                          <li className="dd-item" data-id="2">
                              <div className="dd-handle">
                                  <span className="pull-right"> {prescreenDetails[0]?.visa_refusal_reason ? <i className="fa fa-check-square-o"></i> :
                      <i className="fa fa-times-circle"></i> } </span>
                                  <span className="label h-bg-navy-blue"><i className="fa fa-pencil"></i></span> {prescreenDetails[0]?.visa_refusal_reason || "No Response Provided"}
                              </div>
                          </li>
                      </ol>
                  </li>

                  <li className="dd-item" data-id="1">
                      <div className="dd-handle" style={{fontWeight:"bold"}}>
                          <span className="label h-bg-navy-blue" style={{color: "#6ae76a"}}><i className="fa fa-flag"></i></span> Please provide in your own words how we can
                              help you ?
                      </div>
                      <ol className="dd-list">
                          <li className="dd-item" data-id="2">
                              <div className="dd-handle">
                                  <span className="pull-right"> {prescreenDetails[0]?.in_your_own_words ? <i className="fa fa-check-square-o"></i> :
                      <i className="fa fa-times-circle"></i> } </span>
                                  <span className="label h-bg-navy-blue"><i className="fa fa-pencil"></i></span> {prescreenDetails[0]?.in_your_own_words || "No Response Provided"}
                              </div>
                          </li>
                      </ol>
                  </li>

              </ol>
              </div> 

                </div>


                <div className="row">
                    <div
                      className={
                        decliner
                          ? "form-group col-lg-12 txtareahide collapsin"
                          : "form-group col-lg-12 txtareahide"
                      }
                      aria-expanded={
                        decliner
                      }>
                           <label className="label-clr">
                            Please indicate reason for decline :
                          </label>
                          <div className="form-outline">
                            <textarea
                              className="form-control txtareabb"
                              onChange={handleDeclineChange}
                              id="textArea"
                              name="declinemessage"
                              value={declinemessage || ""}
                              maxLength="100"
                              {...registerDecline('declinemessage', { required: { value: decliner ? true : false, message: "This field is required" }, onChange: (e) => {handleDeclineChange(e)} })}
                              rows="2"></textarea>
                          </div>
                          { errorsd.declinemessage && (
                            <span className="errorValidate">This field is required</span>
                          )}
                  </div>
                </div>

        </Modal.Body>
        <Modal.Footer>
         
           <div className="text-right">
            { ApprovePrescreen.isLoading || DisapprovePrescreen.isLoading ? 
                     ( 
                      <span className="btn" style={{position: "relative", top: "-8px", marginRight: "12px"}}>
                        <i className="fa fa-paper-plane" style={{color: "#5ac557"}}></i>
                        &nbsp; Applying...
                        <span className="lds-facebook"><span></span><span></span><span></span></span>
                      </span>
                      ):(  
                      <>                 
                     <button type="button"
                    className="btn btn-warning2 btn-sm"
                    disabled={(prescreenDetails[0]?.prescreened || prescreenDetails[0]?.prescreened_status === 1) ? true : false}
                    style={{display: decliner ? "none" : "inline-block"}}
                    data-clientid={clientID} 
                    onClick={handleToggleDeclineOn}>
                      <i className="fa fa-times "></i> Decline
                    </button>
                    <button type="button"
                    className="btn btn-danger btn-sm"
                    style={{display: decliner ? "inline-block" : "none"}}
                    disabled={prescreenDetails[0]?.prescreened ? true : false}
                    data-clientid={clientID} 
                    onClick={declinePscreen}>
                      <i className="fa fa-times "></i> Submit
                    </button>
                    <button type="button"
                    className="btn btn-info btn-sm"
                    style={{display: decliner ? "inline-block" : "none"}}
                    disabled={prescreenDetails[0]?.prescreened ? true : false}
                    onClick={handleToggleDeclineOff/* declinePscreen */}>
                      <i className="fa fa-times "></i> Cancel
                    </button>
                    &nbsp;
                    <button type="button"
                      className="btn btn-success btn-sm"
                      disabled={(decliner || prescreenDetails[0]?.prescreened || prescreenDetails[0]?.prescreened_status === 1) ? true : false}
                      data-clientid={clientID}
                      onClick={approvePscreen}
                      >
                      <i className="fa fa-check"></i> Approve
                    </button>

                    <button
                      type="button"
                      className="btn btn-default"
                      onClick={handleClose}>
                      Close
                    </button>
                    </>
              )}
          </div>
        </Modal.Footer>
      </Modal>


      <Modal show={msgopen} onHide={handleMsgClose} animation={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        >
           <div className="color-line"></div>
        <Modal.Header className="modal-header text-center" style={{padding: "10px 10px"}}>
          <Modal.Title>Send Message to Client</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{padding: "5px"}}>
         
          <div className="panel-body" style={{maxHeight: "400px", overflow: "scroll"}}>
                    <div className="p-xs">
                      <form method="get" className="form-horizontal">
                        
                        <div className="form-group">
                          <label className="col-sm-1 control-label text-left">
                            Subject:
                          </label>

                          <div className="col-sm-11">
                            <input
                              type="text"
                              {...register('MESSAGE_SUBJECT', { required: { value: true, message: "This field is required" }, onChange: (e) => {handleMsgChange(e)} })}
                              className="form-control input-sm"
                              placeholder="Enter Message subject"
                              name="MESSAGE_SUBJECT"
                            />
                             {errors.MESSAGE_SUBJECT && (
                  <span className="errorValidate">Message <strong>Subject</strong> is required</span>
                )}
                          </div>
                        </div>

                        <div className="form-group">
                          <label className="col-sm-1 control-label text-left">
                            Tag
                          </label>
                          <div className="col-sm-11">
                            <div className="radio radio-success radio-inline">
                              <input
                                type="radio"
                                id="travel"
                                value="Travel"
                                name="MESSAGE_TAG"
                                checked={message.MESSAGE_TAG === "Travel"}
                                {...register('MESSAGE_TAG', { required: { value: true, message: "This field is required" }, onChange: (e) => {handleMsgChange(e)} })}
                              />
                              <label htmlFor="travel">Travel </label>
                            </div>
                            <div className="radio radio-success radio-inline">
                              <input
                                type="radio"
                                id="billing"
                                value="Billing"
                                name="MESSAGE_TAG"
                                checked={message.MESSAGE_TAG === "Billing"}
                                {...register('MESSAGE_TAG', { required: { value: true, message: "This field is required" }, onChange: (e) => {handleMsgChange(e)} })}
                              />
                              <label htmlFor="billing">Billing </label>
                            </div>
                            <div className="radio radio-success radio-inline">
                              <input
                                type="radio"
                                id="payment"
                                value="Payment"
                                name="MESSAGE_TAG"
                                checked={message.MESSAGE_TAG === "Payment"}
                                {...register('MESSAGE_TAG', { required: { value: true, message: "This field is required" }, onChange: (e) => {handleMsgChange(e)} })}
                              />
                              <label htmlFor="payment">Payment </label>
                            </div>
                            <div className="radio radio-success radio-inline">
                              <input
                                type="radio"
                                id="general"
                                value="General"
                                name="MESSAGE_TAG"
                                checked={message.MESSAGE_TAG === "General"}
                                {...register('MESSAGE_TAG', { required: { value: true, message: "This field is required" }, onChange: (e) => {handleMsgChange(e)} })}
                              />
                              <label htmlFor="general">General </label>
                            </div>
                            {errors.MESSAGE_TAG && (
                           <p className="errorValidate">Message <strong>Tag</strong> is required</p>
                           )}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>

                  <div className="panel-body no-padding">
                    <div>
                      <ReactQuill
                        theme="snow"
                        modules={modules}
                        formats={formats}
                        placeholder="compose here"
                        value={convertedText}
                        onChange={setConvertedText}
                        style={{ minHeight: "300px" }}
                      />
                      </div>
                     </div>
           {/* <div className="panel-body" style={{maxHeight: "400px", overflow: "scroll"}}>

              

          </div> */}

        </Modal.Body>
        <Modal.Footer>
         
           <div className="text-right">
           {sendmsgLoading ? (
             <>
                <span className="btn">
                  <i className="fa fa-paper-plane" style={{color: "#5ac557"}}></i>
                  &nbsp; Sending Message...
                  <span className="lds-facebook"><span></span><span></span><span></span></span>
                </span>
                &nbsp; &nbsp; &nbsp; &nbsp;
                </>
                    ) : (
                <button type="button"
                  className="btn btn-success btn-sm"
                  
                  data-clientid={clientID}
                  onClick={handleMsgSend}
                  >
                  <i className="fa fa-paper-plane"></i> Send Message
                </button>
            )}
              <button
                type="button"
                className="btn btn-default"
                onClick={handleMsgClose}>
                <i className="fa fa-trash"></i>  Discard
              </button>
          </div>
        </Modal.Footer>
      </Modal>
        
        </>
    )

}

export default PreScreening;