import React, { useState, useEffect, useRef } from "react";
import { useQuery, useMutation } from "react-query";
import { useForm } from "react-hook-form";
import { getAdminUser, removeUserAdminSession, useDebounce, sleep} from "../Utils/Common";
import * as api from '../Utils/adminApiCalls';
import "./AdminDashboard.css";
import {
    Link
  } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import AdminNav from "./Admin_nav";
import toast, { Toaster } from 'react-hot-toast';
import { Modal } from 'react-bootstrap';


const pageLimit = 5;
const dataLimit = 10;

const Location = () => {

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    trigger,
    reset,
    formState: { errors },
  } = useForm({mode: "onChange"});

  const {
    register:register2,
    trigger:trigger2,
    reset:reset2,
    formState: { errors:errorsed },
  } = useForm({mode: "onChange"});

    const [userData, setUserData] = useState({});
    const [locid, setLocid] = useState("");
    const [countries, setCountries] = useState([]);
    const[alllocation, setAlllocation]= useState([]);

    const [details, setDetails] = useState({
      COUNTRY:"",
      DEFAULT_USER:"Samarah",
      LOCATION_NAME:"",
      LOCATION_CODE: "",
      ADDRESS1:"",
      ADDRESS2:"",
      TOWN:"",
      COUNTY:"",
      POSTCODE:"",
      TELEPHONE:"",
      FAX:"",
      EMAIL:"",
      COUNTRY_PREFIX:"",
      VAT_RATE:"",
      STATUS:""
    });

    useEffect(() => {
  
      function checkUserData() {
        const item = getAdminUser();
    
        if (item) {
          console.log("user item",item);
          setUserData(item);
  
          setUserData((userData) => {
            console.log("updated details", userData);
            return userData;
          });
  
        }
      }
      checkUserData();

      const data = require('../Utils/CountryData.json')
        console.log(data)
        setCountries(data)
  
      window.addEventListener('storage', checkUserData)
    
      return () => {
        window.removeEventListener('storage', checkUserData)
      }
      
    }, []);


    const getLocations = useQuery("locations", api.getOfficeLocations, {
      onSuccess: (data) => {
        console.log("location data", data);
        setAlllocation(data);
        setAlllocation(alllocation => {
          return alllocation;
        })
      },
      onError: (error) => {
        
          console.log("Error Message",error); 
        
          if(error?.response?.status === 401){
            toast.error("Session Expired : Please Re-Login",{
              duration: 6000,
              });
           removeUserAdminSession();
            navigate("/admin_login", {replace: false});
          }
          else {
            toast.error("Something went wrong",{
              duration: 6000,
              });
          }
      }
    })


    const handleChange = (event) => {
      event.persist();
  
      setDetails((details) => ({
        ...details,
        [event.target.name]: event.target.value,
      }));
      console.log(details);
    };

///////////////////////////////
const [locationDetails, setLocationDetails] = useState({});

 const fetchLocation = useRef(false);
 const Locationref = useRef();

 const getLocationDetails = useQuery(["locationdetails", Locationref.current], () => api.getBranchbyID(Locationref.current), {
  enabled : fetchLocation.current,
  onSuccess: (data) => {
      console.log("location details: ",data[0]);

      setLocationDetails(data[0]);
      setLocationDetails((locationDetails) => {
        console.log("locationDetails ::: ",locationDetails);
       return locationDetails;
    });

  },
  onError: (error) => {
    
    console.log("Error Message",error); 
  
    if(error.response.status === 401){
      toast.error("Session Expired : Please Re-Login",{
        duration: 6000,
        });
     removeUserAdminSession();
      navigate("/admin_login", {replace: false});
    }
    else {
      toast.error("Something went wrong",{
        duration: 6000,
        });
    }
  
  } 
})



const handleEditChange = (event) => {
      event.persist();
     
      const locdetails = {...locationDetails};
      locdetails[event.target.name] = event.target.value;

      console.log("locdetails ::", locdetails); 
      
      setLocationDetails(locdetails);
      setLocationDetails((locationDetails) => {
        return locationDetails
      });

      console.log(locationDetails); 
    };
  
    
    const createdDate = (datestr, l=0)=> {

      if(l === 0){
      const start = moment(datestr).format("dddd, MMMM Do YYYY, h:mm:ss a");
      
      return start;
    }
    else if(l === 1){
      const start = moment(datestr).format("llll");

      return start;
    }
     
    };

    ///////////Modal view location //////
const [viewlocopen, setViewLocOpen] = useState(false);

const handleViewLocShow = (e) => {
  setLocid(e.target.getAttribute("data-locid"));
  setViewLocOpen(true);
  Locationref.current = e.target.getAttribute("data-locid");
  
  const locationid = e.target.getAttribute("data-locid");

   if(locationid) {
    fetchLocation.current = true;
    getLocationDetails.refetch();
   }
   else return null;
};

const handleViewLocClose= (e) => {
  setViewLocOpen(false);
  fetchLocation.current = false;
};

////////////////

 ///////////Modal Edit location //////
 const [editlocopen, setEditLocOpen] = useState(false);
 
 const handleEditLocShow = (e) => {
   setLocid(e.target.getAttribute("data-locid"));
   setEditLocOpen(true);
   
   const locationid = e.target.getAttribute("data-locid");
   Locationref.current = e.target.getAttribute("data-locid");
   if(locationid) {
    fetchLocation.current = true;
    getLocationDetails.refetch();
   }
   else return null;
 };
 
 const handleEditLocClose= (e) => {
   setEditLocOpen(false);
   fetchLocation.current = false;
 };
 
const handleEditLoc = async (e) => {
    console.log(e.target.getAttribute("data-locid"));

    const locationID = e.target.getAttribute("data-locid");
    const locupdate = {...locationDetails};
    locupdate.user_id = userData?.id;
    locupdate.id = locationID;

    const validateAdd = await trigger2();
 if(validateAdd){
    if(locationID !== "" && locationID !== undefined && userData?.id !== undefined){
          updateLocationDetails.mutate(locupdate);
    }
    else {
      toast.error("Invalid Location ID : Operation Denied",{
        duration: 6000,
        });
    }
  }
};

///updateLocation

const updateLocationDetails = useMutation("locationdetails", api.updateBranchByID, {

  onSuccess: (data) => {
      console.log("location details: ",data);

      getLocations.refetch();
      toast.success("Location updated Successfully",{
        duration:6000,
      })

      sleep(2000).then(() => { 

        setEditLocOpen(false);
        setLocationDetails({});
        setLocationDetails((locationDetails) => {
          return locationDetails;
        });

      });
  },
  onError: (error) => {
    
    console.log("Error Message",error); 
  
    if(error.response.status === 401){
      toast.error("Session Expired : Please Re-Login",{
        duration: 6000,
        });
     removeUserAdminSession();
      navigate("/admin_login", {replace: false});
    }
    else {
      toast.error("Something went wrong",{
        duration: 6000,
        });
    }
  
  } 
})
 ////////////////


  ///////////Modal Add location //////
const [addlocopen, setAddLocOpen] = useState(false);

const handleAddLocShow = (e) => {
  
  setAddLocOpen(true);
  fetchLocation.current = true;
};

const handleAddLocClose= (e) => {
  setAddLocOpen(false);
  fetchLocation.current = false;
  reset();
  setDetails({
    COUNTRY:"",
    DEFAULT_USER:"Samarah",
    LOCATION_NAME:"",
    LOCATION_CODE: "",
    ADDRESS1:"",
    ADDRESS2:"",
    TOWN:"",
    COUNTY:"",
    POSTCODE:"",
    TELEPHONE:"",
    FAX:"",
    EMAIL:"",
    COUNTRY_PREFIX:"",
    VAT_RATE:"",
    STATUS:""
  });
};

const handleAddLoc = async (e) => {
  const dt = {...details};
  dt.id = userData?.id;
const validateAdd = await trigger();
 if(validateAdd){
      if(dt.id !== "" && dt.id !== undefined){
      locationMutate.mutate(dt);
      }
      else {
        toast.error("Invalid User. Operation denied",{
          duration:6000,
        })
      }
 }
};

const locationMutate = useMutation("addlocation", api.AddBranch, {
  onSuccess: (data) => {
    console.log("location data", data);
    getLocations.refetch();
    toast.success("Location Added Successfully",{
      duration:6000,
    });
    setAddLocOpen(false);
  },
  onError: (error) => {
    
      console.log("Error Message",error); 
    
      if(error?.response?.status === 401){
        toast.error("Session Expired : Please Re-Login",{
          duration: 6000,
          });
       removeUserAdminSession();
        navigate("/admin_login", {replace: false});
      }
      else {
        toast.error("Something went wrong",{
          duration: 6000,
          });
      }
  }
});
const [users, setUsers] = useState([]);
const getUsers = useQuery("users", () => api.getAllUsers(), {
  enabled: fetchLocation.current,
  onSuccess: (data) => {
    console.log("users data:::", data);
    setUsers([...data]);
    setUsers(users => {
      return users;
    })
  },
  onError: (error) => {
    
      console.log("Error Message ::: ",error); 
    
      if(error?.response?.status === 401){
        toast.error("Session Expired : Please Re-Login",{
          duration: 6000,
          });
       removeUserAdminSession();
        navigate("/admin_login", {replace: false});
      }
      else {
        toast.error("Something went wrong",{
          duration: 6000,
          });
      }
  }
});
////////////////

/////////////Pagination ////////////

  //const [pages] = useState(Math.ceil(datamsg?.length / dataLimit) || 1);
  const pages = useRef(Math.ceil(alllocation?.length / dataLimit) || 1);

  //console.log("dataLimit::", dataLimit," datamsg length::", datamsg?.length, " pages::: ", Math.ceil(alllocation?.length / dataLimit));

  const [currentPage, setCurrentPage] = useState(1);

  const goToNextPage = () => {
      if(currentPage <= pages.current) {
      setCurrentPage((page) => page + 1);
      }
  }

  const goToPreviousPage = () => {
      if(currentPage > 1 ){
      setCurrentPage((page) => page - 1);
      }
  }

  const changePage = (event) => {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage(pageNumber);
  }

  const getPaginatedData = () => {
    const startIndex = currentPage * dataLimit - dataLimit;
    const endIndex = startIndex + dataLimit;
    //console.log("getPaginationData", alllocation?.slice(startIndex, endIndex))
    return alllocation?.slice(startIndex, endIndex);
  };

  const getPaginationGroup = () => { 
    let start = Math.floor((currentPage - 1) / pageLimit) * pageLimit;
    
    let page = Math.ceil(alllocation?.length / dataLimit) || 1;
    //console.log("local page ", Math.ceil(alllocation?.length / dataLimit));
    //console.log("Array Return", new Array(pages).fill().map((_, idx) => start + idx + 1), "  pages : ", pages);

    pages.current = page;
    
     return new Array(page).fill().map((_, idx) => start + idx + 1);
  }

  ////////////////////////////////////

  /////////////////Search function ///////////////
const [searchquery, setSearchquery] = useState("");

const searchApplication = (q) => {
 //console.log("query: ", q.target.value);

 const query = q.target.value;

 setSearchquery(query);

 setSearchquery(searchquery => {
   return searchquery;
 });
 
 if(query.length === 0){
  
  console.log("Refetching Application list 2", query, " query length next: ", query.length);
     
  getLocations.refetch();
 }
}

const debouncedFilter = useDebounce(searchquery, 500);

console.log("debouncedFilter", debouncedFilter); 

const { data:searchData, isLoading:searchLoading } = useQuery(
 ['applications', debouncedFilter], 
 () => api.searchBranch(debouncedFilter),
 { enabled: Boolean(debouncedFilter),
 onSuccess: (data) => {
     console.log("data", data);
      const apd = [...data];
      console.log("apd::::",apd); 
     setAlllocation(apd);
     setAlllocation((alllocation) => {
      console.log("alllocation::::",alllocation); 
       return alllocation;
     }) 
 },
 onError: (error) => {
    
  console.log("Error Message",error); 

  if(error.response.status === 401){
    toast.error("Session Expired : Please Re-Login",{
      duration: 6000,
      });
   removeUserAdminSession();
    navigate("/admin_login", {replace: false});
  }
  else {
    toast.error("Something went wrong",{
      duration: 6000,
      });
  }

} 
})

 /////////////////////////////////


    return (
        <>
        <Toaster />
      
<div className="normalheader" id="tpanel">
        <div className="hpanel">
          <div className="panel-body">
            <a className="small-header-action" id="toglepanel" href={void(0)}>
              <div className="clip-header">
                <i className="fa fa-arrow-up"></i>
              </div>
            </a>

            <div id="hbreadcrumb" className="pull-right m-t-lg">
              <ol className="hbreadcrumb breadcrumb">
                <li>
                  <a href={void(0)}>i-visas</a>
                </li>
                <li>
                  <span>Admin</span>
                </li>
                <li className="active">
                  <span>Branches </span>
                </li>
              </ol>
            </div>
            <h2 className="font-light m-b-xs">Branch Management</h2>
            <small>
            <strong>Create</strong> and <strong>Update</strong> Branches
            </small>
          </div>
        </div>
      </div>

    <div className="content">
        <div className="row">
            <div className="col-lg-12 text-center welcome-message">
                
            <div className="hpanel email-compose">
              
               <>
            <div className="panel-heading hbuilt">
                    <div className="text-center p-xs font-normal">
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={searchApplication}
                          value={searchquery || ""}
                          className="form-control input-sm"
                          placeholder="Search by Location Name, Country, Telephone and Email..."
                        />{" "}
                        <span className="input-group-btn">
                          {" "}
                          <button
                            type="button"
                            disabled
                            className="btn btn-sm btn-default">
                            <i className="fa fa-search"></i> 
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="panel-body">

                  {searchLoading ? (
                       <>
                      <div className="row text-center">
                      <div className="col-md-7 m-b-md" style={{textAlign: "left", display: "flex", flexDirection: "row", justifyItems: "flex-start", alignItems: "center", justifyContent: "normal"}}>

                      <button className="btn btn-info btn-sm" >
                            <i className="fa fa-bank"></i> Add Branch
                          </button>
                      </div>
                      <div className="col-md-5 mailbox-pagination m-b-md">
                            <span style={{ fontSize: "1.1rem" }}>
                            Showing 0 of 0 pages   &nbsp; &nbsp;
                            </span>
                            <div className="btn-group">
                              <button className="btn btn-default btn-sm previous disabled"
                             
                              disabled
                              >
                                <i className="fa fa-arrow-left"></i>
                              </button>
                              {getPaginationGroup().map((item, index) => (
                              <button 
                              key={index}
                              className="btn btn-default btn-sm disabled"
                              >
                              {item}
                              </button>
                              ))}
                              <button className="btn btn-default btn-sm next disabled"
                              disabled
                              >
                                <i className="fa fa-arrow-right"></i>
                              </button>
                            </div>
                          </div>
                         
                      </div>
                        <div className="table-responsive" style={{overflowX: "inherit"}}>
                        <table className="table table-hover table-mailbox apptable">
                        <thead style={{borderBottom: "solid 2px #64d246"}}>
                        <tr>
                            <th className="text-center" style={{width:"210px"}}>Name</th>
                            <th className="text-center" >Location Code </th>
                            <th className="text-center" >Telephone</th>
                            <th className="text-center" >Country</th>
                            <th className="text-center" >Status</th>
                            <th className="text-center" >Creation Date </th>
                            <th className="text-right">Action</th>
                           
                          </tr>
                        </thead>
                            <tbody>
                            <tr className="active"><td colSpan="7" style={{textAlign:"center"}}>Searching...</td></tr>
                        </tbody>
                      </table>
                        </div>
                        </>
                    ):(
                      <>
                    <div className="row">
                      <div className="col-md-7 m-b-md" style={{textAlign: "left", display: "flex", flexDirection: "row", justifyItems: "flex-start", alignItems: "center", justifyContent: "normal"}}>

                      <button className="btn btn-info btn-sm" onClick={handleAddLocShow} >
                            <i className="fa fa-bank"></i> Add Branch
                          </button>
                      </div>

                      <div className="col-md-5 mailbox-pagination m-b-md">
                        <span style={{ fontSize: "1.1rem" }}>
                        {!!alllocation && (Object.keys(alllocation).length > 0) ? `Showing ${currentPage} of ${pages.current} pages` : "Showing 0 of 0 pages"}   &nbsp; &nbsp;
                        </span>
                        <div className="btn-group">
                          <button className={`btn btn-default btn-sm previous ${currentPage === 1 ? 'disabled' : ''}`}
                          onClick={goToPreviousPage}
                          disabled={!!alllocation && (currentPage === 1) ? true : false} 
                          >
                            <i className="fa fa-arrow-left"></i>
                          </button>
                          {getPaginationGroup().map((item, index) => (
                          <button 
                          key={index}
                          onClick={changePage}
                          className={`btn btn-default btn-sm  ${currentPage === item ? 'active' : null}`}
                          >
                           {item}
                          </button>
                          ))}
                          <button className={`btn btn-default btn-sm next ${currentPage === pages.current ? 'disabled' : ''}`}
                          onClick={goToNextPage} 
                          disabled={!!alllocation && (currentPage === pages.current) ? true : false} 
                          >
                            <i className="fa fa-arrow-right"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="table-responsive" style={{overflowX: "inherit"}}>
                      <table className="table table-hover table-mailbox apptable">
                      <thead style={{borderBottom: "solid 2px #64d246"}}>
                          <tr>
                            <th className="text-center" style={{width:"210px"}}>Name</th>
                            <th className="text-center" >Telephone</th>
                            <th className="text-center" >Country</th>
                            <th className="text-center" >Email</th>
                            <th className="text-center" >Assigned User</th>
                            <th className="text-center" >Creation Date </th>
                            <th className="text-right">Action</th>
                           
                          </tr>
                        </thead>
                        <tbody>
                        { getLocations.isLoading ? (

                        <tr className="unread active"><td colSpan="7" style={{textAlign:"center"}}>
                          <span className="btn">
                          <i className="fa fa-paper-plane" style={{color: "#5ac557"}}></i>
                          &nbsp; Loading...
                          <span className="lds-facebook"><span></span><span></span><span></span></span>
                          </span>
                          &nbsp; &nbsp; &nbsp; &nbsp;
                          </td></tr>
                        ) : (
                        !!alllocation && (Object.keys(alllocation).length > 0) ? 
                          getPaginatedData().map((item,i) =>{
                            return (
                              <tr className="unread" key={i}>
                              <td className="text-center" style={{width: "100px"}}>
                             {item.LOCATION_CODE}
                            </td>
                            <td className="text-center">
                             {item.TELEPHONE}
                            </td>
                            <td className="text-center">
                             {item.COUNTRY}
                            </td>
                            <td className="text-center">
                             {item.EMAIL}
                            </td>
                            <td className="text-center">
                             {item.first_name}
                            </td>
                            <td className="text-center mail-date">
                            {createdDate(item.created_at)}
                            </td>
                            <td className="text-left mail-date">
                            <div className="btn-group">
                                    <button data-toggle="dropdown" className="btn btn-default dropdown-toggle">Action <span className="caret"></span></button>
                                    <ul className="dropdown-menu">
                                        <li><Link to="# " data-locid={item.id} onClick={handleViewLocShow} >View Location</Link></li>
                                        <li><Link to="# " data-locid={item.id} onClick={handleEditLocShow} >Edit Location</Link></li>
                                       
                                    </ul>
                          </div>
                            </td>
                          </tr>
                            )
                          }) : (
                           <tr className="unread active"><td colSpan="7" style={{textAlign:"center"}}>No Location found </td></tr>
                         ))}
                           
                        </tbody>
                      </table>
                    </div>

                    </>
                    )}
                 </div>
                 <div className="panel-footer text-left">
                  {!!alllocation && (Object.keys(alllocation).length > 0) ? alllocation.length : 0}  Locations
                 </div>
               </>
               
            </div>
        </div>

    </div>
    </div>


   {/*  <footer className="footer" style={{ maxHeight: "40px" }}>
          <div className="mt-4 mt-md-5 text-center" style={{ marginTop: "0" }}>
            <p className="footer-p">
              © <script>document.write(new Date().getFullYear())</script>{" "}
              i-visas.com
            </p>
          </div>
        </footer> */}


        <Modal show={viewlocopen} onHide={handleViewLocClose} animation={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        >
           <div className="color-line"></div>
        <Modal.Header className="modal-header text-center" style={{padding: "10px 10px"}}>
          <Modal.Title>View Location</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{padding: "5px"}}>
         
        <div className="panel-body no-padding">
               <div className="row">

               <div className="col-lg-12">

               { getLocationDetails.isError &&
                 (
                  <>
                  <div className="hpanel horange">
                    <div className="panel-body">
                        <div className="row">
                            <div className="col-sm-12">
                                <p className="text-center">Encountered an Issue Loading client details</p>
                            </div>
                        </div>
                      </div>
                  </div>
                  </>
                 )}

                 { getLocationDetails.isLoading && 
                 (
                  <>
                  <div className="hpanel horange">
                    <div className="panel-body">
                        <div className="row">
                            <div className="col-sm-12">
                                <p className="text-center">Loading...</p>
                            </div>
                        </div>
                      </div>
                  </div>
                  </>
                 )} 

                 {locationDetails ? (
                 <>  
                <div className="hpanel horange">
                    <div className="panel-body">
                       {/*  <span className="label label-info pull-right">NEW</span> */}
                        <div className="row">
                            <div className="col-sm-12">
                                <h4><a href="#"> {locationDetails?.LOCATION_CODE || "Not Set"} </a></h4>
                              
                                <div className="row">
                                    <div className="col-sm-3 text-center">
                                        <div className="project-label">EMAIL</div>
                                        <small style={{fontWeight: "bold"}}>{locationDetails?.EMAIL || "Not Set"}</small>
                                    </div>
                                    <div className="col-sm-3 text-center">
                                        <div className="project-label">TELEPHONE</div>
                                        <small style={{fontWeight: "bold"}}>{locationDetails?.TELEPHONE || "Not Set"}</small>
                                    </div>
                                    <div className="col-sm-3 text-center">
                                        <div className="project-label">LOCATION CODE</div>
                                        <small style={{fontWeight: "bold"}}>{locationDetails?.LOCATION_CODE || "Not Set"}</small>
                                    </div>
                                    <div className="col-sm-3 text-center">
                                        <div className="project-label">POST CODE</div>
                                        <small style={{fontWeight: "bold"}}>{locationDetails?.POSTCODE || "Not Set"}</small>
                                    </div>
                                </div>

                                <hr className="slash-3" style={{marginTop:"18px"}}/>

                                <div className="row">
                                    <div className="col-sm-3 text-center">
                                        <div className="project-label">COUNTRY</div>
                                        <small style={{fontWeight: "bold"}}>{locationDetails?.COUNTRY || "Not Set"}</small>
                                    </div>
                                    <div className="col-sm-3 text-center">
                                        <div className="project-label">ASSIGNED OFFICER</div>
                                        <small style={{fontWeight: "bold"}}>{locationDetails?.first_name || "Not Set"}</small>
                                    </div>
                                    <div className="col-sm-3 text-center">
                                        <div className="project-label">TOWN</div>
                                        <small style={{fontWeight: "bold"}}>{locationDetails?.TOWN || "Not Set"}</small>
                                    </div>
                                    <div className="col-sm-3 text-center">
                                        <div className="project-label">COUNTY</div>
                                        <small style={{fontWeight: "bold"}}>{locationDetails?.COUNTY || "Not Set"}</small>
                                    </div>
                                    
                                </div>


                                <hr className="slash-3" style={{marginTop:"18px"}}/>

                                <div className="row">
                                    <div className="col-sm-3 text-center">
                                        <div className="project-label">COUNTRY PREFIX</div>
                                        <small style={{fontWeight: "bold"}}>{locationDetails?.COUNTRY_PREFIX || "Not Set"}</small>
                                    </div>
                                    <div className="col-sm-3 text-center">
                                        <div className="project-label">ADDRESS 1</div>
                                        <small style={{fontWeight: "bold"}}>{locationDetails?.ADDRESS1 || "Not Set"}</small>
                                    </div>
                                    <div className="col-sm-3 text-center">
                                        <div className="project-label">ADDRESS 2</div>
                                        <small style={{fontWeight: "bold"}}>{locationDetails?.ADDRESS2 || "Not Set"}</small>
                                    </div>
                                    <div className="col-sm-3 text-center">
                                        <div className="project-label">VAT RATE</div>
                                        <small style={{fontWeight: "bold"}}>{`${locationDetails?.VAT_RATE}%`|| "Not Set"}</small>
                                    </div>
                                    
                                </div>

                                <hr className="slash-3" style={{marginTop:"18px"}}/>

                                  <div className="row">
                                    <div className="col-sm-3 text-center">
                                        <div className="project-label">FAX</div>
                                        <small style={{fontWeight: "bold"}}>{locationDetails?.FAX || "Not Set"}</small>
                                    </div>
                                  </div>
                            </div>
                          
                        </div>
                    </div>
                    <div className="panel-footer">
                        last updated on {createdDate(locationDetails?.created_at)}
                    </div>
                </div>
                </>
                ) : ("Nothing to show")}
            </div>
                
               </div>
        </div>

        </Modal.Body>
        <Modal.Footer>
         
           <div className="text-right">
           
              <button
                type="button"
                className="btn btn-default"
                onClick={handleViewLocClose}>
                <i className="fa fa-trash"></i>  Close
              </button>
          </div>
        </Modal.Footer>
      </Modal>


      <Modal show={editlocopen} onHide={handleEditLocClose} animation={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        >
           <div className="color-line"></div>
        <Modal.Header className="modal-header text-center" style={{padding: "10px 10px"}}>
          <Modal.Title>Edit Location</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{padding: "5px"}}>
         
        
        <div className="panel-body no-padding">
        <div className="row">
                  <div className="col-lg-12">

                  { getLocationDetails.isError &&
                 (
                  <>
                  <div className="hpanel horange">
                    <div className="panel-body">
                        <div className="row">
                            <div className="col-sm-12">
                                <p className="text-center">Encountered an Issue Loading Location details</p>
                            </div>
                        </div>
                      </div>
                  </div>
                  </>
                 )}

                 { getLocationDetails.isLoading && 
                 (
                  <>
                  <div className="hpanel horange">
                    <div className="panel-body">
                        <div className="row">
                            <div className="col-sm-12">
                                <p className="text-center">Loading...</p>
                            </div>
                        </div>
                      </div>
                  </div>
                  </>
                 )} 
                 {locationDetails && (
                      <>
                        <div className="row col-bb">
                          
                        <div className="form-group col-lg-3">
                              <label className="label-clr">
                                Country
                              </label>
                              <select
                                name="COUNTRY"
                               {...register2("COUNTRY", { 
                                          required: { value:locationDetails?.COUNTRY !== "" ? false : true, message: "This field is required" }, 
                                          onChange: (e) => {handleEditChange(e)} })}
                                value={locationDetails?.COUNTRY || ""} // Prop: The email input data
                               
                                className="form-select m-b-3"
                                style={{ width: "100%" }}>
                                <option value=''>select country</option>
                                      {
                                       countries.map((item) =>{
                                           return(
                                               <option key={item.country}>
                                                   {item.country}
                                               </option>
                                           )
                                       })
                                      }
                              </select>
                              {errorsed.COUNTRY && (
                                  <p className="errorValidate">{errorsed.COUNTRY.message}</p>
                                  )}
                            </div>

                            <div className="form-group col-lg-3">
                                <label className="label-clr">
                                  Location Name
                                </label>
                                <input
                                  type="text"
                                  {...register2("LOCATION_NAME", { 
                                    required: { value:  locationDetails?.LOCATION_NAME !== "" ? false : true, message: "This field is required" }, 
                                          onChange: (e) => {handleEditChange(e)} })}
                                  value={locationDetails?.LOCATION_NAME || ""}
                                  className="form-control"
                                  name="LOCATION_NAME"
                                />
                                {errorsed.LOCATION_NAME && (
                                  <p className="errorValidate">{errorsed.LOCATION_NAME.message}</p>
                                  )}
                              </div>

                            <div className="form-group col-lg-3">
                                <label className="label-clr">
                                  Location Code
                                </label>
                                <input
                                  type="text"
                                  {...register2("LOCATION_CODE", { 
                                    required: { value:  locationDetails?.LOCATION_CODE !== "" ? false : true, message: "This field is required" }, 
                                          onChange: (e) => {handleEditChange(e)} })}
                                  value={locationDetails?.LOCATION_CODE || ""}
                                  className="form-control"
                                  name="LOCATION_CODE"
                                />
                                {errorsed.LOCATION_CODE && (
                                  <p className="errorValidate">{errorsed.LOCATION_CODE.message}</p>
                                  )}
                              </div>

                            <div className="form-group col-lg-3">
                              <label className="label-clr">
                              Assign an Administrator
                              </label>
                              <select
                                name="DEFAULT_USER"
                               {...register2("DEFAULT_USER", { 
                                          required: { value: false, message: "This field is required" }, 
                                          onChange: (e) => {handleEditChange(e)} })}
                                value={locationDetails?.DEFAULT_USER || "Samarah"} // Prop: The email input data
                               
                                className="form-select m-b-4"
                                style={{ width: "100%" }}>
                                <option value=''>select user</option>
                                {users?.map( user => (
                                  <option key={user.first_name} value={user.id}>{user.first_name}</option>
                                ))}
                              </select>
                              {errorsed.DEFAULT_USER && (
                                  <p className="errorValidate">{errorsed.DEFAULT_USER.message}</p>
                                  )}
                            </div>

                        </div>

                        <div className="row col-bb">
                          
                          <div className="form-group col-lg-12">
                          <div className="form-group col-lg-6">
                                <label className="label-clr">
                                  Address 1
                                </label>
                                <input
                                  type="text"
                                  {...register2("ADDRESS1", { 
                                    required: { value:  locationDetails?.ADDRESS1 !== "" ? false : true, message: "This field is required" }, 
                                          onChange: (e) => {handleEditChange(e)} })}
                                  value={locationDetails?.ADDRESS1 || ""}
                                  className="form-control"
                                  name="ADDRESS1"
                                />
                                {errorsed.ADDRESS1 && (
                                  <p className="errorValidate">{errorsed.ADDRESS1.message}</p>
                                  )}
                              </div>


                              <div className="form-group col-lg-6">
                                <label className="label-clr">
                                  Address 2
                                </label>
                                <input
                                  type="text"
                                  {...register2("ADDRESS2", { 
                                    required: { value:  locationDetails?.ADDRESS2 !== "" ? false : true, message: "This field is required" }, 
                                          onChange: (e) => {handleEditChange(e)} })}
                                  value={locationDetails?.ADDRESS2 || ""}
                                  className="form-control"
                                  name="ADDRESS2"
                                />
                                {errorsed.ADDRESS2 && (
                                  <p className="errorValidate">{errorsed.ADDRESS2.message}</p>
                                  )}
                              </div>

                          </div>

                        </div>

                        <div className="row col-bb">
                          
                          <div className="form-group col-lg-12">

                          <div className="form-group col-lg-3">
                                <label className="label-clr">
                                  Town
                                </label>
                                <input
                                  type="text"
                                  {...register2("TOWN", { 
                                    required: { value:  locationDetails?.TOWN !== "" ? false : true, message: "This field is required" }, 
                                          onChange: (e) => {handleEditChange(e)} })}
                                  value={locationDetails?.TOWN || ""}
                                  className="form-control"
                                  name="TOWN"
                                />
                                {errorsed.TOWN && (
                                  <p className="errorValidate">{errorsed.TOWN.message}</p>
                                  )}
                              </div>

                              <div className="form-group col-lg-3">
                                <label className="label-clr">
                                  County
                                </label>
                                <input
                                  type="text"
                                  {...register2("COUNTY", { 
                                    required: { value:  locationDetails?.COUNTY !== "" ? false : true, message: "This field is required" }, 
                                          onChange: (e) => {handleEditChange(e)} })}
                                  value={locationDetails?.COUNTY || ""}
                                  className="form-control"
                                  name="COUNTY"
                                />
                                {errorsed.COUNTY && (
                                  <p className="errorValidate">{errorsed.COUNTY.message}</p>
                                  )}
                              </div>

                              <div className="form-group col-lg-3">
                                <label className="label-clr">
                                  Post Code
                                </label>
                                <input
                                  type="text"
                                  {...register2("POSTCODE", { 
                                    required: { value:  locationDetails?.POSTCODE !== "" ? false : true, message: "This field is required" }, 
                                          onChange: (e) => {handleEditChange(e)} })}
                                  value={locationDetails?.POSTCODE || ""}
                                  className="form-control"
                                  name="POSTCODE"
                                />
                                {errorsed.POSTCODE && (
                                  <p className="errorValidate">{errorsed.POSTCODE.message}</p>
                                  )}
                              </div>

                              <div className="form-group col-lg-3">
                                <label className="label-clr">
                                   Country Prefix
                                </label>
                                <input
                                  type="text"
                                  {...register2("COUNTRY_PREFIX", { 
                                    required: { value:  locationDetails?.COUNTRY_PREFIX !== "" ? false : true, message: "This field is required" }, 
                                          onChange: (e) => {handleEditChange(e)} })}
                                  value={locationDetails?.COUNTRY_PREFIX || ""}
                                  className="form-control"
                                  name="COUNTRY_PREFIX"
                                />
                                {errorsed.COUNTRY_PREFIX && (
                                  <p className="errorValidate">{errorsed.COUNTRY_PREFIX.message}</p>
                                  )}
                              </div>


                          </div>
                        </div>


                        <div className="row col-bb">
                          
                          <div className="form-group col-lg-12">

                          <div className="form-group col-lg-3">
                                <label className="label-clr">
                                  Email
                                </label>
                                <input
                                  type="email"
                                  {...register2("EMAIL", { 
                                    required: { value:  locationDetails?.EMAIL !== "" ? false : true, message: "This field is required" }, 
                                          onChange: (e) => {handleEditChange(e)} })}
                                  value={locationDetails?.EMAIL || ""}
                                  className="form-control"
                                  name="EMAIL"
                                />
                                {errorsed.EMAIL && (
                                  <p className="errorValidate">{errorsed.EMAIL.message}</p>
                                  )}
                              </div>

                              <div className="form-group col-lg-3">
                                <label className="label-clr">
                                  Telephone
                                </label>
                                <input
                                  type="text"
                                  {...register2("TELEPHONE", { 
                                    required: { value:  locationDetails?.TELEPHONE !== "" ? false : true, message: "This field is required" }, 
                                          onChange: (e) => {handleEditChange(e)} })}
                                  value={locationDetails?.TELEPHONE || ""}
                                  className="form-control"
                                  name="TELEPHONE"
                                />
                                {errorsed.TELEPHONE && (
                                  <p className="errorValidate">{errorsed.TELEPHONE.message}</p>
                                  )}
                              </div>

                              <div className="form-group col-lg-3">
                                <label className="label-clr">
                                  Fax
                                </label>
                                <input
                                  type="text"
                                  {...register2("FAX", { 
                                    required: { value: false, message: "This field is required" }, 
                                          onChange: (e) => {handleEditChange(e)} })}
                                  value={locationDetails?.FAX || ""}
                                  className="form-control"
                                  name="FAX"
                                />
                                {errorsed.FAX && (
                                  <p className="errorValidate">{errorsed.FAX.message}</p>
                                  )}
                              </div>

                              <div className="form-group col-lg-3">
                                <label className="label-clr">
                                   VAT Rate
                                </label>
                                <input
                                  type="number"
                                  {...register2("VAT_RATE", { 
                                    required: { value:  locationDetails?.VAT_RATE !== "" ? false : true, message: "This field is required" }, 
                                          onChange: (e) => {handleEditChange(e)} })}
                                  value={locationDetails?.VAT_RATE || ""}
                                  className="form-control"
                                  name="VAT_RATE"
                                />
                                {errorsed.VAT_RATE && (
                                  <p className="errorValidate">{errorsed.VAT_RATE.message}</p>
                                  )}
                              </div>


                          </div>
                        </div>

                        <div className="row col-bb">
                          
                          <div className="form-group col-lg-12">
                            <label className="label-clr">
                                  Status
                            </label>
                             <br />
                            <div className="radio radio-success radio-inline">
                                    <input
                                      type="radio"
                                      id="statusy"
                                      value="ACTIVE"
                                      name="STATUS"
                                      checked={locationDetails?.STATUS === "ACTIVE"}
                                      {...register2("STATUS", { 
                                        required: { value:  locationDetails?.STATUS !== "" ? false : true, message: "This field is required" }, 
                                              onChange: (e) => {handleEditChange(e)} })}
                                    />
                                    <label htmlFor="residencey">Active </label>
                                  </div>
                                  <div className="radio radio-success radio-inline">
                                    <input
                                      type="radio"
                                      id="statusy"
                                      value="INACTIVE"
                                      name="STATUS"
                                      checked={locationDetails?. locationDetails?.STATUS !== "" ? false : true === "INACTIVE"}
                                      {...register2("STATUS", { 
                                        required: { value: true, message: "This field is required" }, 
                                              onChange: (e) => {handleEditChange(e)} })}
                                    />
                                    <label htmlFor="residencen">In-Active</label>
                                  </div>
                                {errorsed.STATUS && (
                                  <p className="errorValidate">This field is required</p>
                                  )}
                          </div>
                        </div>

                        </>
                )} 
                  </div>
              </div>
        </div>


        </Modal.Body>
        <Modal.Footer>
         
           <div className="text-right">
           {getLocationDetails.isLoading ? (
             <>
                <span className="btn">
                  <i className="fa fa-paper-plane" style={{color: "#5ac557"}}></i>
                  &nbsp; Saving...
                  <span className="lds-facebook"><span></span><span></span><span></span></span>
                </span>
                &nbsp; &nbsp; &nbsp; &nbsp;
                </>
                    ) : (
                <button type="button"
                  className="btn btn-success btn-sm"
                  
                  data-locid={locid}
                  onClick={handleEditLoc}
                  >
                   <i className="fa fa-location-arrow"></i>  Apply Changes
                </button>
            )}
              <button
                type="button"
                className="btn btn-default"
                onClick={handleEditLocClose}>
                <i className="fa fa-trash"></i>  Close
              </button>
          </div>
        </Modal.Footer>
      </Modal>


      <Modal show={addlocopen} onHide={handleAddLocClose} animation={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        >
           <div className="color-line"></div>
        <Modal.Header className="modal-header text-center" style={{padding: "10px 10px"}}>
          <Modal.Title>Add Location</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{padding: "5px"}}>
         
       
        <div className="panel-body no-padding">
        <div className="row">
                  <div className="col-lg-12">

                        <div className="row col-bb">
                          
                        <div className="form-group col-lg-3">
                              <label className="label-clr">
                                Country
                              </label>
                              <select
                                name="COUNTRY"
                               {...register("COUNTRY", { 
                                          required: { value: true, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                value={details.COUNTRY || ""} // Prop: The email input data
                               
                                className="form-select m-b-3"
                                style={{ width: "100%" }}>
                                <option value=''>select country</option>
                                      {
                                       countries.map((item) =>{
                                           return(
                                               <option key={item.country}>
                                                   {item.country}
                                               </option>
                                           )
                                       })
                                      }
                              </select>
                              {errors.COUNTRY && (
                                  <p className="errorValidate">{errors.COUNTRY.message}</p>
                                  )}
                            </div>

                            <div className="form-group col-lg-3">
                                <label className="label-clr">
                                  Location Name
                                </label>
                                <input
                                  type="text"
                                  {...register("LOCATION_NAME", { 
                                    required: { value: true, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                  value={details.LOCATION_NAME || ""}
                                  className="form-control"
                                  name="LOCATION_NAME"
                                />
                                {errors.LOCATION_NAME && (
                                  <p className="errorValidate">{errors.LOCATION_NAME.message}</p>
                                  )}
                              </div>

                            <div className="form-group col-lg-3">
                                <label className="label-clr">
                                  Location Code
                                </label>
                                <input
                                  type="text"
                                  {...register("LOCATION_CODE", { 
                                    required: { value: true, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                  value={details.LOCATION_CODE || ""}
                                  className="form-control"
                                  name="LOCATION_CODE"
                                />
                                {errors.LOCATION_CODE && (
                                  <p className="errorValidate">{errors.LOCATION_CODE.message}</p>
                                  )}
                              </div>

                            <div className="form-group col-lg-3">
                              <label className="label-clr">
                              Assign an Administrator
                              </label>
                              <select
                                name="DEFAULT_USER"
                               {...register("DEFAULT_USER", { 
                                          required: { value: false, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                value={details.DEFAULT_USER || "Samarah"} // Prop: The email input data
                               
                                className="form-select m-b-4"
                                style={{ width: "100%" }}>
                                <option value=''>select user</option>
                                {users?.map( user => (
                                  <option key={user.first_name} value={user.id}>{user.first_name}</option>
                                ))}
                              </select>
                              {errors.DEFAULT_USER && (
                                  <p className="errorValidate">{errors.DEFAULT_USER.message}</p>
                                  )}
                            </div>

                        </div>

                        <div className="row col-bb">
                          
                          <div className="form-group col-lg-12">
                          <div className="form-group col-lg-6">
                                <label className="label-clr">
                                  Address 1
                                </label>
                                <input
                                  type="text"
                                  {...register("ADDRESS1", { 
                                    required: { value: true, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                  value={details.ADDRESS1 || ""}
                                  className="form-control"
                                  name="ADDRESS1"
                                />
                                {errors.ADDRESS1 && (
                                  <p className="errorValidate">{errors.ADDRESS1.message}</p>
                                  )}
                              </div>


                              <div className="form-group col-lg-6">
                                <label className="label-clr">
                                  Address 2
                                </label>
                                <input
                                  type="text"
                                  {...register("ADDRESS2", { 
                                    required: { value: true, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                  value={details.ADDRESS2 || ""}
                                  className="form-control"
                                  name="ADDRESS2"
                                />
                                {errors.ADDRESS2 && (
                                  <p className="errorValidate">{errors.ADDRESS2.message}</p>
                                  )}
                              </div>

                          </div>

                        </div>

                        <div className="row col-bb">
                          
                          <div className="form-group col-lg-12">

                          <div className="form-group col-lg-3">
                                <label className="label-clr">
                                  Town
                                </label>
                                <input
                                  type="text"
                                  {...register("TOWN", { 
                                    required: { value: true, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                  value={details.TOWN || ""}
                                  className="form-control"
                                  name="TOWN"
                                />
                                {errors.TOWN && (
                                  <p className="errorValidate">{errors.TOWN.message}</p>
                                  )}
                              </div>

                              <div className="form-group col-lg-3">
                                <label className="label-clr">
                                  County
                                </label>
                                <input
                                  type="text"
                                  {...register("COUNTY", { 
                                    required: { value: true, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                  value={details.COUNTY || ""}
                                  className="form-control"
                                  name="COUNTY"
                                />
                                {errors.COUNTY && (
                                  <p className="errorValidate">{errors.COUNTY.message}</p>
                                  )}
                              </div>

                              <div className="form-group col-lg-3">
                                <label className="label-clr">
                                  Post Code
                                </label>
                                <input
                                  type="text"
                                  {...register("POSTCODE", { 
                                    required: { value: true, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                  value={details.POSTCODE || ""}
                                  className="form-control"
                                  name="POSTCODE"
                                />
                                {errors.POSTCODE && (
                                  <p className="errorValidate">{errors.POSTCODE.message}</p>
                                  )}
                              </div>

                              <div className="form-group col-lg-3">
                                <label className="label-clr">
                                   Country Prefix
                                </label>
                                <input
                                  type="text"
                                  {...register("COUNTRY_PREFIX", { 
                                    required: { value: true, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                  value={details.COUNTRY_PREFIX || ""}
                                  className="form-control"
                                  name="COUNTRY_PREFIX"
                                />
                                {errors.COUNTRY_PREFIX && (
                                  <p className="errorValidate">{errors.COUNTRY_PREFIX.message}</p>
                                  )}
                              </div>


                          </div>
                        </div>


                        <div className="row col-bb">
                          
                          <div className="form-group col-lg-12">

                          <div className="form-group col-lg-3">
                                <label className="label-clr">
                                  Email
                                </label>
                                <input
                                  type="email"
                                  {...register("EMAIL", { 
                                    required: { value: true, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                  value={details.EMAIL || ""}
                                  className="form-control"
                                  name="EMAIL"
                                />
                                {errors.EMAIL && (
                                  <p className="errorValidate">{errors.EMAIL.message}</p>
                                  )}
                              </div>

                              <div className="form-group col-lg-3">
                                <label className="label-clr">
                                  Telephone
                                </label>
                                <input
                                  type="text"
                                  {...register("TELEPHONE", { 
                                    required: { value: true, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                  value={details.TELEPHONE || ""}
                                  className="form-control"
                                  name="TELEPHONE"
                                />
                                {errors.TELEPHONE && (
                                  <p className="errorValidate">{errors.TELEPHONE.message}</p>
                                  )}
                              </div>

                              <div className="form-group col-lg-3">
                                <label className="label-clr">
                                  Fax
                                </label>
                                <input
                                  type="text"
                                  {...register("FAX", { 
                                    required: { value: false, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                  value={details.FAX || ""}
                                  className="form-control"
                                  name="FAX"
                                />
                                {errors.FAX && (
                                  <p className="errorValidate">{errors.FAX.message}</p>
                                  )}
                              </div>

                              <div className="form-group col-lg-3">
                                <label className="label-clr">
                                   VAT Rate
                                </label>
                                <input
                                  type="number"
                                  {...register("VAT_RATE", { 
                                    required: { value: true, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                  value={details.VAT_RATE || ""}
                                  className="form-control"
                                  name="VAT_RATE"
                                />
                                {errors.VAT_RATE && (
                                  <p className="errorValidate">{errors.VAT_RATE.message}</p>
                                  )}
                              </div>

                          </div>
                        </div>

                        <div className="row col-bb">
                          
                          <div className="form-group col-lg-12">
                            <label className="label-clr">
                                  Status
                            </label>
                             <br />
                            <div className="radio radio-success radio-inline">
                                    <input
                                      type="radio"
                                      id="statusy"
                                      value="ACTIVE"
                                      name="STATUS"
                                      checked={details?.STATUS === "ACTIVE"}
                                      {...register("STATUS", { 
                                        required: { value: true, message: "This field is required" }, 
                                              onChange: (e) => {handleChange(e)} })}
                                    />
                                    <label htmlFor="residencey">Active </label>
                                  </div>
                                  <div className="radio radio-success radio-inline">
                                    <input
                                      type="radio"
                                      id="statusy"
                                      value="INACTIVE"
                                      name="STATUS"
                                      checked={details?.STATUS === "INACTIVE"}
                                      {...register("STATUS", { 
                                        required: { value: true, message: "This field is required" }, 
                                              onChange: (e) => {handleChange(e)} })}
                                    />
                                    <label htmlFor="residencen">In-Active</label>
                                  </div>
                                {errors.STATUS && (
                                  <p className="errorValidate">This field is required</p>
                                  )}
                          </div>
                        </div>

                        
                  </div>
              </div>
        </div>


        </Modal.Body>
        <Modal.Footer>
         
           <div className="text-right">
           {locationMutate.isLoading ? (
             <>
                <span className="btn">
                  <i className="fa fa-paper-plane" style={{color: "#5ac557"}}></i>
                  &nbsp; Saving...
                  <span className="lds-facebook"><span></span><span></span><span></span></span>
                </span>
                &nbsp; &nbsp; &nbsp; &nbsp;
                </>
                    ) : (
                <button type="button"
                  className="btn btn-success btn-sm"
                  
                  onClick={handleAddLoc}
                  >
                   <i className="fa fa-bank"></i>  Add Branch
                </button>
            )}
              <button
                type="button"
                className="btn btn-default"
                onClick={handleAddLocClose}>
                <i className="fa fa-trash"></i>  Close
              </button>
          </div>
        </Modal.Footer>
      </Modal>
        
        </>
    )

}

export default Location;