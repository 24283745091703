import React, { useState, useEffect } from "react";
import {useMutation, useQuery} from 'react-query';
import { useSearchParams } from 'react-router-dom';
import * as api from './Utils/apiCall';

import "./verifyemail.css";

import { useNavigate, useLocation } from "react-router-dom";

const VerifyEmail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  
  const [errors, setErrors] = useState([]);

  const {isError, isSuccess, isLoading, data, mutate} = useMutation(api.validatemail, {
    onSuccess: (data) => {

      console.log("Verification Successfull", data.message);
      
      /* const currentParams = Object.fromEntries([...searchParams]);
      const newURL = `/verify_email?verifycode=${currentParams.verifycode}&id=${currentParams.id}`;
      console.log("newURL", newURL);
      navigate(newURL, { state:{ verified:true }, replace: true}); */
    },
    onError: (error) => {
      //console.log("errors",error); 
      //console.log("error response data errors: ",error.response.data.errors?.mobilephone[0]);
      console.log("raw:::",error.response.data.message)
      const errlist = []
      const errObject = error.response.data?.message
      //console.log("typeof: ",typeof error.response.data.errors);
      if(error.response.data.errors !== undefined && typeof error.response.data.errors === 'object')
       {
            for(var propName in errObject) {
              if(errObject.hasOwnProperty(propName)) {
                  //console.log("within iteration",errObject[propName]); 
                  errlist.push(errObject[propName]);
              }
            }
          setErrors(errlist);
       }
       else if(error.response.data.message !== undefined )
       {
           
              errlist.push(error.response.data.message);
              setErrors(errlist);
       }
       else
       {
         errlist.push(error.message)
         setErrors(errlist);
       }

       console.log("errlist",errlist); 

      //console.log("error response status: ", error.response.status);
      
    }
  });

  useEffect(() => {

    ///if(location.state?.verified === undefined || location.state?.verified === null){

        const currentParams = Object.fromEntries([...searchParams]);
        console.log("currentParams :::: ",currentParams); // get new values onchange

        if(currentParams){

           mutate({"email_token":currentParams.verifycode, "id": currentParams.id});
            /* await api.validatemail(currentParams.verycode).then(response => {
                console.log(response);
            })
            .catch(e => {
                console.log(e);
            }); */
            
        }
        else{
            return navigate("/", { replace: true });
        }
    /* }
    {
        console.log("Email Already Verified......::::");
    } */

  }, [searchParams]);

  ////////Company Information :////////////
  const [companyDetails, setCompanyDetails] = useState({});

  const getCompanyInfoNav = useQuery("get_companyinfo", api.companyInfo, {
    enabled: true,
    refetchOnMount:true,
    refetchOnWindowFocus:true,
    onSuccess: (data) => {
      console.log("users data:::", data);

      const apd = {...data?.Info[0]};
      apd.URL = data?.URL;
      console.log("@@@@@@@@@@@@@@@@@@", apd)
      setCompanyDetails(apd);
      setCompanyDetails(companyDetails => {
        console.log("companyDetails ===>>>",companyDetails);
        return companyDetails;
      })
    },
    onError: (error) => {
        console.log("Error Message ::: ",error); 
    }
  });

  //////////////////////////////////////////


  return (
    <div className="auth-page">
      <div className="container-fluid p-0">
       
          <div className="row g-0">
            <div className="col-xxl-4 col-lg-5 col-md-6 fmpane">
              <div className="auth-full-page-content d-flex p-sm-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5 text-center">
                      <a href="# " className="d-block auth-logo">
                       {/*  <img src="assets/images/logo.png" alt="" height="40%" />{" "} */}
                        <img src={companyDetails?.URL || "assets/images/logo.png"} alt="logo" width="217px" height="92px" /* height="20%" */ />
                        <span className="logo-txt"></span>
                      </a>
                    </div>

                    <div className="auth-content auth-content-margin">
                      <div className="text-center">
                        <div className="avatar-lg mx-auto">
                          <div className="avatar-title rounded-circle bg-light">
                            {isSuccess ? (
                                <i className="bx bx-mail-send h2 mb-0 text-primary"></i>
                            ) : (
                                <i className="bx bx-mobile-vibration h2 mb-0 text-primary"></i>
                            )}
                          </div>
                        </div>
{isSuccess && (
                          <div className="p-2 mt-4">
                            {data.message === "Email already verified!" ? (
                            <h4>{data.message}</h4>
                             ) : (
                              <> <h4>Success !</h4>
                              <p className="text-muted presend" style={{margin: "30px 1px"}}>
                                <strong>Congratulations!!</strong> Email Address has been Verified.
                              </p></>
                             )}

                            <p className="text-muted presend">
                              Kindly Proceed to Sign In
                            </p>
                           
                            <div className="mt-4">
                              <a href="/" className="btnR btn-primary">
                                Go to Sign In
                              </a>
                            </div>
                          </div>)}

                          {isLoading && (
                              <div className="p-2 mt-4"><p className="text-muted presend">...Confirming Email</p></div>
                          )}
                        
                      </div>

                      {isError && (
                      <div className="mt-5 text-center">
                        <ul className="errorlist">
                        {
                          errors.map((errorItem, i) =>{
                              return(
                                  <li key={i} className="text-muted-error">
                                      {errorItem}
                                  </li>
                              )
                          })
                        }
                          </ul>
                        </div>
                      )}

                    </div>
                    <div
                      className="text-center"
                      style={{ marginTop: "2.4rem" }}>
                      <p className="mb-0 presend">
                       {/*  © {new Date().getFullYear()} i-visas.com */}
                       © {new Date().getFullYear()} {companyDetails?.COMPANY_NAME ?? "i-visas.com"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* end auth full page content */}
            </div>
            {/* end col */}
            <div className="col-xxl-8 col-lg-7 col-md-6">
              <div className="auth-bg pt-md-5 p-4 d-flex">
                <div className="bg-overlay bg-primary"></div>
                <ul className="bg-bubbles">
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                </ul>
                {/* end bubble effect */}
                <div className="row justify-content-center align-items-center">
                  <div className="col-xl-9">
                    <div className="p-0 p-sm-4 px-xl-0">
                      <div
                        id="reviewcarouselIndicators"
                        className="carousel slide"
                        data-bs-ride="carousel">
                        <div className="carousel-indicators carousel-indicators-rounded justify-content-start ms-0 mb-0">
                          <button
                            type="button"
                            data-bs-target="#reviewcarouselIndicators"
                            data-bs-slide-to="0"
                            className="active"
                            aria-current="true"
                            aria-label="Slide 1"></button>
                          <button
                            type="button"
                            data-bs-target="#reviewcarouselIndicators"
                            data-bs-slide-to="1"
                            aria-label="Slide 2"></button>
                          <button
                            type="button"
                            data-bs-target="#reviewcarouselIndicators"
                            data-bs-slide-to="2"
                            aria-label="Slide 3"></button>
                        </div>
                        {/* end carouselIndicators  */}
                        <div className="carousel-inner">
                          <div className="carousel-item active">
                            <div className="testi-contain text-white">
                              <i className="bx bxs-quote-alt-left text-success display-6"></i>

                              <h4 className="mt-4 fw-medium lh-base text-white">
                                “Thank you for your attentive and expertise
                                getting my application for British Citizenship
                                approved. The service you provided was very
                                good.”
                              </h4>
                              <div className="mt-4 pt-3 pb-5">
                                <div className="d-flex align-items-start">
                                  <div className="flex-grow-1 ms-3 mb-4">
                                    <h5 className="font-size-18 text-white">
                                      Maureen Papas
                                    </h5>
                                    <p className="mb-0 text-white-50">
                                      Allpress Espresso, UK
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="carousel-item">
                            <div className="testi-contain text-white">
                              <i className="bx bxs-quote-alt-left text-success display-6"></i>

                              <h4 className="mt-4 fw-medium lh-base text-white">
                                “Professional immigration advise and responsible
                                stuff.”
                              </h4>
                              <div className="mt-4 pt-3 pb-5">
                                <div className="d-flex align-items-start">
                                  <div className="flex-grow-1 ms-3 mb-4">
                                    <h5 className="font-size-18 text-white">
                                      Wen Qi - China
                                    </h5>
                                    <p className="mb-0 text-white-50">
                                      Tier 1 (Entrepreneur)
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="carousel-item">
                            <div className="testi-contain text-white">
                              <i className="bx bxs-quote-alt-left text-success display-6"></i>

                              <h4 className="mt-4 fw-medium lh-base text-white">
                                “Of course, I am fully satisfied with the
                                services provided by you and would like to
                                continue dealing with you in future.”
                              </h4>
                              <div className="mt-4 pt-3 pb-5">
                                <div className="d-flex align-items-start">
                                  <div className="flex-1 ms-3 mb-4">
                                    <h5 className="font-size-18 text-white">
                                      SHAHZAD RAZA - ULTIMATE T LTD
                                    </h5>
                                    <p className="mb-0 text-white-50">
                                      Tier 1 (Entrepreneur)
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  );
};

export default VerifyEmail;
