import React, { useState, useEffect, Fragment, useRef } from "react";
import { useQuery, useMutation } from "react-query";
import DOMPurify from "dompurify";
import toast, { Toaster } from 'react-hot-toast';
import { getUser, removeUserSession, useDebounce } from "./Utils/Common";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as api from './Utils/apiCall';
import { useForm } from "react-hook-form";
import moment from "moment";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  useMatch,
  useResolvedPath, Link
} from "react-router-dom";

import "./Messages.css";

const pageLimit = 5;
const dataLimit = 10;

const Messages = () => {

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  
  const [userData, setUserData] = useState({});

  const [viewmsg, setViewmsg] = useState({});

  const [msgdata, setMsgdata] = useState([]);

  const [msgdatabk, setMsgdatabk] = useState([]);

  const [msgdatasend, setMsgdatasend] = useState([]);

  const [msgdatasendbk, setMsgdatasendbk] = useState([]);

  const [sendmsgLoading, setSendmsgLoading] = useState(false);

  const {isError, isSuccess, error, isLoading, mutate} = useMutation(api.sendClientMessage, {
    onSuccess: (data) => {
      console.log("onSuccess data",data, "id: ", data.id);
      toast.success('Message Successfully sent!',{
        duration: 6000,
      });
      reset();

      const resetMessageField = {
          MESSAGE_SUBJECT: "",
          MESSAGE_TAG: "General",
          MESSAGE: "",
        };

      setMessage(resetMessageField);

      setMessage((message)=> {
        return message;
      });

      setSendmsgLoading(false);

      setConvertedText("");

      datasentrefetch();
    },
    onError: (error) => {
    
      console.log("Error Message",error); 
    
      if(error?.response?.status === 401){
        toast.error("Session Expired : Please Re-Login",{
          duration: 6000,
          });
       removeUserSession();
        navigate("/", {replace: true});
      }
      else {
        toast.error("Something went wrong",{
          duration: 6000,
          });
      }
    
    } 
  });

  useEffect(() => {
    document
      .getElementById("toglepanel")
      .addEventListener("click", togglePanel);

      function checkUserData() {
        const item = getUser();
    
        if (item) {
         // console.log("user item",item);
          setUserData(item);
  
          setUserData((userData) => {
           // console.log("updated details", userData);
            return userData;
          });
  
        }
      }
      checkUserData();
  
      window.addEventListener('storage', checkUserData)
    
      return () => {
        window.removeEventListener('storage', checkUserData)
      }

  }, []);

  const sentMessages = useQuery(['sentmessages', userData?.id], () => api.getCLientMessagesSent(userData?.id), {
    onSuccess: (data) => {
      console.log("sentMessages",data)
      const apd = [...data];
      setMsgdatasend(apd);
      setMsgdatasend((msgdatasend) => {
        return msgdatasend;
      })

      setMsgdatasendbk(apd);
      setMsgdatasendbk((msgdatasendbk) => {
        return msgdatasendbk;
      });
    },
    onError: (error) => {
    
      console.log("Error Message",error); 
    
      if(error?.response?.status === 401){
        toast.error("Session Expired : Please Re-Login",{
          duration: 6000,
          });
       removeUserSession();
        navigate("/", {replace: true});
      }
      else {
        toast.error("Something went wrong",{
          duration: 6000,
          });
      }
    
    } 
  });

  const receivedMessages = useQuery(['receivedmessages', userData?.id], () => api.getCLientMessages(userData?.id),
  {
      onSuccess: (data) => {
        console.log("receivedMessages",data)
        const apd = [...data];
        setMsgdata(apd);
        setMsgdata((msgdata) => {
          return msgdata;
        })

        setMsgdatabk(apd);
        setMsgdatabk((msgdatabk) => {
          return msgdatabk;
        })
      },
      onError: (error) => {
    
        console.log("Error Message",error); 
      
        if(error?.response?.status === 401){
          toast.error("Session Expired : Please Re-Login",{
            duration: 6000,
            });
         removeUserSession();
          navigate("/", {replace: true});
        }
        else {
          toast.error("Something went wrong",{
            duration: 6000,
            });
        }
      
      } 
  });
   
  const { loading: loadingmsg, data: datamsg, refetch:datamsgrefetch } = receivedMessages;

  const { loading: loadingmsgs, data: datamsgs, refetch:datasentrefetch } = sentMessages;


  useEffect(() => {

    let isSubscribed = true;

    const viewMessageurl = async() => {

        const currentParams = Object.fromEntries([...searchParams]);
       // console.log("currentParams :::: ",currentParams); // get new values onchange

        if(currentParams?.conversation_id){
          console.log("conversation ID", currentParams?.conversation_id);

          const payload = {
            "id" : userData?.id,
            "CONVERSATION_ID" : currentParams?.conversation_id
          };

          const view_msg = await api.showClientMessage(payload);
          console.log("view mail::", view_msg);

          if (isSubscribed) {
              setViewmsg(view_msg);
          
              setViewmsg((viewmsg) => {
              //  console.log(" + viewmsg value :", viewmsg);
                return viewmsg;
              });
          
              setMailview(3);

              datamsgrefetch();
            }
         
        }
        else     
        {
            console.log("There are no Search Params with conversation_id......::::",currentParams);
        }
      }

      viewMessageurl().catch(console.error);
  
      // cancel any future `setData`
      return () => isSubscribed = false;


  }, [searchParams]);


  const replyMutation = useMutation(api.replyUserMessage, {
    onSuccess: (data) => {
      console.log("onSuccess data",data, "id: ", data.id);
      toast.success('Message Successfully sent!',{
        duration: 6000,
      });
      reset();
      setConvertedText("");
      setSendmsgLoading(false);
      datasentrefetch();
    },
    onError: (error) => {
    
      console.log("Error Message",error); 
    
      if(error?.response?.status === 401){
        toast.error("Session Expired : Please Re-Login",{
          duration: 6000,
          });
       removeUserSession();
        navigate("/", {replace: true});
      }
      else {
        toast.error("Something went wrong",{
          duration: 6000,
          });
      }
    
    } 
  });

  const {
    register,
    trigger,
    reset,
    formState: { errors },
  } = useForm();

  /* const {
    register:registercheckbox,
    reset:resetcheckbox,
    formState: { errors:errorscheckbox },
  } = useForm(); */

  const [mailview, setMailview] = useState(0);

  const [mailbox, setMailbox] = useState("inbox");

  const [message, setMessage] = useState(
    {
      MESSAGE_SUBJECT: "",
      MESSAGE_TAG: "General",
      MESSAGE: "",
    },
  );


  const [convertedText, setConvertedText] = useState("");

  const [convertedTextStatus, setConvertedTextStatus] = useState(false);

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
      ["clean"],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };
  /*
   * Quill editor formats
   * See https://quilljs.com/docs/formats/
   */
  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
  ];

  const togglePanel = () => {
    const hpanel = document.getElementById("tpanel");

    if (hpanel.classList.contains("small-header")) {
      hpanel.classList.remove("small-header");
    } else {
      hpanel.classList.add("small-header");
    }
  };

  const handleSubmit = async () => {
    
    console.log("message data", message);

    const msgpayload = {...message};

    msgpayload.MESSAGE = convertedText;
    msgpayload.id = userData.id;

    //console.log("msgpayload on send", msgpayload);
       
    const msgvalidate = await trigger();

     if (msgvalidate && msgpayload.MESSAGE !== "") {
       setConvertedTextStatus(false);
       
       setSendmsgLoading(true);
       mutate(msgpayload);
     }
     else{
       
       if(convertedText === ""){
        setConvertedTextStatus(true);
        
       }
     }
  };

  const handleReplySubmit = () => {
    console.log("reply message data", message);

    const msgpayload = {};

    msgpayload.MESSAGE = convertedText;
    msgpayload.id = userData.id;
    msgpayload.MESSAGE_TAG = viewmsg.attributes[0].MESSAGE_TAG;
    msgpayload.MESSAGE_SUBJECT = viewmsg.attributes[0].MESSAGE_SUBJECT;
    msgpayload.CONVERSATION_ID = viewmsg.attributes[0].CONVERSATION_ID;

    //console.log("msgpayload on send reply", msgpayload);

     if (msgpayload.MESSAGE !== "" && msgpayload.CONVERSATION_ID !== "") {
       setConvertedTextStatus(false);
       setSendmsgLoading(true);
       replyMutation.mutate(msgpayload);
     }
     else{
       //console.log("convertedText : ", convertedText);
       //console.log("convertedTextStatus : ", convertedTextStatus);
       if(convertedText === ""){
        setConvertedTextStatus(true);
        
       }
     }  
  }


  /////////////////Delete Message /////////////////

  const [deleteList, setDeleteList] = useState([]);

  const handleMesgCheck = (event) => {
    event.persist();
    const { name, value, type } = event.target;
    const msgid = event.target.getAttribute("data-msgid");

    console.log("name:" + name + "  value:" + value + "type" + type + " mesgid" + msgid);
    console.log("event all", event?.target?.checked);

    if(event?.target?.checked){
    setDeleteList((deleteList) => ([
      ...deleteList,
      msgid
    ]));

    setDeleteList((deleteList) => {
        console.log("deleteList on send", deleteList);
        return deleteList;
      }); 
    }
    else {

      const deleteL = [...deleteList];
      let filteredDel = deleteL.filter((del) => String(del) !== String(msgid));
      console.log("filtered", filteredDel);
      setDeleteList(filteredDel);
      setDeleteList((deleteList) => {
        console.log("deleteList on send", deleteList);
        return deleteList;
      }); 
    }
  };

  const deleteMessage = (type) => {
    console.log("IDs to be deleted", deleteList, " type:", type);
    if(deleteList?.length > 0){
      const msgpayload = {};
      msgpayload.MESSAGE = deleteList;
      msgpayload.id = userData.id;
      msgpayload.type = type;
      deletemsgMutation.mutate(msgpayload);
    }
    else {
        toast.success('Kindly Select a Message to Delete', {
          style: {
            border: '1px solid #87878a',
            padding: '16px',
            color: '#87878a',
          },
          iconTheme: {
            primary: '#87878a',
            secondary: '#FFFAEE',
          },
        });
    }
 }

 const deletemsgMutation = useMutation(api.deleteMessage, {
  onSuccess: (data) => {
    console.log("onSuccess data",data);
    toast.success('Message Successfully deleted!',{
      duration: 6000,
    });

    setDeleteList([]);
    setDeleteList((deleteList) => {
      console.log("deleteList on send", deleteList);
      return deleteList;
    }); 

    datasentrefetch();

    datamsgrefetch();
  },
  onError: (error) => {
  
    console.log("Error Message",error); 
  
    if(error?.response?.status === 401){
      toast.error("Session Expired : Please Re-Login",{
        duration: 6000,
        });
     removeUserSession();
      navigate("/", {replace: true});
    }
    else {
      toast.error("Something went wrong",{
        duration: 6000,
        });
    }
  
  } 
});

  /////////////////////////End Delete Message //////////////

  const handleChange = (event) => {
    event.persist();
    const { name, value, type } = event.target;
    //console.log("name:" + name + "  value:" + value + "type" + type);

    setMessage((message) => ({
      ...message,
      [name]: value,
    }));

       setMessage((message) => {
        //console.log("message on send", message);
        return message;
      }); 
  };

  const handleMailbox = (e) => {
    e.preventDefault();
    //console.log("data-mb", e.target.getAttribute("data-mb"));

    let all = document.getElementsByName('chck');
    for(var a=0;a<all.length;a++) {
      all[a].checked = false;
    }

    /* resetcheckbox(); */

    setDeleteList([]);

    setDeleteList((deleteList) => {
      console.log("deleteList on send", deleteList);
      return deleteList;
    }); 

    const mb = e.target.getAttribute("data-mb");
  
    setMailbox(mb);
    if(mailview !== 0) return setMailview(0);

    if(mb === "inbox"){
    receivedMessages.refetch();
    }
    else {
      sentMessages.refetch();
    }

    setTagname("");
    setTagname((tagName)=> {
      return tagName;
    }); 

  }

 const [tagName, setTagname] = useState("");

  const mailBox = (tag) => {

    console.log("mail tag", tag);
    console.log("current mailbox:", mailbox);

    if(mailbox === "inbox"){
      
      let recmesg = [...msgdatabk];
      
      console.log("received messages:", recmesg.filter((msg) => { return msg.MESSAGE_TAG == tag;}));
      const filtered_rec = recmesg.filter((msg) => { return msg.MESSAGE_TAG == tag;});
      setMsgdata(filtered_rec);
      setMsgdata(msgdata => {
        return msgdata;
      });

      }
    else {
    
      let sentmesg = [...msgdatasendbk];
      
      console.log("received messages:", sentmesg.filter((msg) => { return msg.MESSAGE_TAG == tag;}));
      const filtered_sent = sentmesg.filter((msg) => { return msg.MESSAGE_TAG == tag;});
      setMsgdatasend(filtered_sent);
      setMsgdatasend(msgdatasend => {
        return msgdatasend;
      });
    }
    setTagname(tag);
    setTagname((tagName)=> {
      return tagName;
    }); 

    setMailview(0);
  };

  const composeMail = () => {
    setMailview(1);
  };

  const viewMail = async (mailid) => {
    console.log("mail ID", mailid);
    const payload = {
          "id" : userData?.id,
          "CONVERSATION_ID" : mailid
      };
   const view_msg = await api.showClientMessage(payload);

   datamsgrefetch();
    //console.log("view mail::", view_msg);
    setViewmsg(view_msg);

    setViewmsg((viewmsg) => {
      //console.log(" + viewmsg value :", viewmsg);
      return viewmsg;
    });

    setMailview(3);
  };

  const viewMailSent = async (mailid) => {
    console.log("mail ID", mailid);
    const payload = {
          "id" : userData?.id,
          "CONVERSATION_ID" : mailid
      };
   const view_msg = await api.showClientSentMessage(payload);

   datamsgrefetch();
    //console.log("view mail::", view_msg);
    setViewmsg(view_msg);

    setViewmsg((viewmsg) => {
      //console.log(" + viewmsg value :", viewmsg);
      return viewmsg;
    });

    setMailview(3);
  };


  const maildate = (datestr, l=0) => {
    /* const start = moment(datestr).format(
      "dddd, MMMM Do YYYY, h:mm:ss a"
    );
    return start; */
    if(l === 0){
      const start = moment(datestr).format("dddd, MMMM Do YYYY, h:mm:ss a");
      return start;
    }
    else if(l === 1){
      const start = moment(datestr).format("llll");
      return start;
    }
  };

  const TagIcon = (tag) => {
    if(tag === "Travel"){
      return <i className="fa fa-plane text-danger"></i>;
    }
    else if(tag === "Billing"){
      return <i className="fa fa-calculator text-warning"></i>;
    }
    else if(tag === "Payment"){
      return <i className="fa fa-credit-card-alt text-info"></i>;
    }
    else if(tag === "General"){
      return <i className="fa fa-tag text-success"></i>;
    }
    else{
      return ""
    }
  }

  const replyMsg = async (convoid) => {
    console.log("conversation id to be replied", convoid);

    const payload = {
          "id" : userData?.id,
          "CONVERSATION_ID" : convoid
      };
    const view_msg = await api.showClientMessage(payload);
      //console.log("view mail::", view_msg);
      datamsgrefetch();
      setViewmsg(view_msg);
  
      setViewmsg((viewmsg) => {
        console.log(" + viewmsg value :", viewmsg);
        return viewmsg;
      });
  
      setMailview(2);
  }

  const handleDiscard = () =>{
    //console.log("discard message")
    setMailview(3);
  }

  const printMsg = (convoid) => {
    console.log("conversation id to be printed", convoid);
  }

  /////////////Pagination Received////////////

  //const [pages] = useState(Math.ceil(datamsg?.length / dataLimit) || 1);
  const pages = useRef(Math.ceil(msgdata?.length / dataLimit) || 1);

  //console.log("dataLimit::", dataLimit," msgdata length::", msgdata?.length, " pages::: ", Math.ceil(msgdata?.length / dataLimit));

  const [currentPage, setCurrentPage] = useState(1);

  const goToNextPage = () => {
      if(currentPage <= pages.current) {
      setCurrentPage((page) => page + 1);
      }
  }

  const goToPreviousPage = () => {
      if(currentPage > 1 ){
      setCurrentPage((page) => page - 1);
      }
  }

  const changePage = (event) => {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage(pageNumber);
  }

  const getPaginatedData = () => {
    const startIndex = currentPage * dataLimit - dataLimit;
    const endIndex = startIndex + dataLimit;
    //console.log("getPaginationData", msgdata?.slice(startIndex, endIndex))
    return msgdata?.slice(startIndex, endIndex);
  };

  const getPaginationGroup = () => { 
    let start = Math.floor((currentPage - 1) / pageLimit) * pageLimit;
    
    let page = Math.ceil(msgdata?.length / dataLimit) || 1;
    //console.log("local page ", Math.ceil(msgdata?.length / dataLimit));
    //console.log("Array Return", new Array(pages).fill().map((_, idx) => start + idx + 1), "  pages : ", pages);

    pages.current = page;
    
     return new Array(page).fill().map((_, idx) => start + idx + 1);
  }

  ////////////////////////////////////


  /////////////Pagination Send////////////

  //const [pages] = useState(Math.ceil(msgdatasend?.length / dataLimit) || 1);
  const pagesSend = useRef(Math.ceil(msgdatasend?.length / dataLimit) || 1);

  //console.log("dataLimit::", dataLimit," datamsg length::", datamsg?.length, " pages::: ", Math.ceil(datamsg?.length / dataLimit));

  const [currentPageSend, setCurrentPageSend] = useState(1);

  const goToNextPageSend = () => {
      if(currentPageSend <= pagesSend.current) {
      setCurrentPageSend((page) => page + 1);
      }
  }

  const goToPreviousPageSend = () => {
      if(currentPageSend > 1 ){
      setCurrentPageSend((page) => page - 1);
      }
  }

  const changePageSend = (event) => {
    const pageNumber = Number(event.target.textContent);
    setCurrentPageSend(pageNumber);
  }

  const getPaginatedDataSend = () => {
    const startIndex = currentPageSend * dataLimit - dataLimit;
    const endIndex = startIndex + dataLimit;
    //console.log("getPaginationData", datamsg?.slice(startIndex, endIndex))
    return msgdatasend?.slice(startIndex, endIndex);
  };

  const getPaginationGroupSend = () => { 
    let start = Math.floor((currentPageSend - 1) / pageLimit) * pageLimit;
    
    let page = Math.ceil(msgdatasend?.length / dataLimit) || 1;
    //console.log("local page ", Math.ceil(datamsg?.length / dataLimit));
    //console.log("Array Return", new Array(pages).fill().map((_, idx) => start + idx + 1), "  pages : ", pages);

    pagesSend.current = page;
    
     return new Array(page).fill().map((_, idx) => start + idx + 1);
  }

  ////////////////////////////////////



  ///////////////// Search function Received ///////////////
const [searchqueryreceived, setSearchqueryreceived] = useState("");

const searchApplicationreceived = (q) => {
    console.log("message query: ", q.target.value);

    const query = q.target.value;

    setSearchqueryreceived(query);

    setSearchqueryreceived(searchqueryreceived => {
      return searchqueryreceived;
    });

   if (query.length === 0){
      console.log("Refetching Application list", query, " query length: ", query.length);
      datamsgrefetch();
      //console.log("searchApi",);    
    }
  
}

const debouncedFilterReceived = useDebounce(searchqueryreceived, 500);

console.log("debouncedFilterReceived", debouncedFilterReceived); 

const searchReceived = useQuery(['receivedMessages', debouncedFilterReceived], () => api.searchMessageReceived(userData?.id ,debouncedFilterReceived),
 { enabled: Boolean(debouncedFilterReceived),
  onSuccess: (data) => {
    console.log("receivedMessages",data)
    const apd = [...data];
    setMsgdata(apd);
    setMsgdata((msgdata) => {
      return msgdata;
    })
  },
  onError: (error) => {
    
    console.log("Error Message",error); 
  
    if(error?.response?.status === 401){
      toast.error("Session Expired : Please Re-Login",{
        duration: 6000,
        });
     removeUserSession();
      navigate("/", {replace: true});
    }
    else {
      toast.error("Something went wrong",{
        duration: 6000,
        });
    }
  
  } 
});

 /////////////////////////////////



 ///////////////// Search function Sent ///////////////

const [searchquerysent, setSearchquerysent] = useState("");

const searchApplicationsent = (q) => {

      const query = q.target.value;

      setSearchquerysent(query);

      setSearchquerysent(searchquerysent => {
        return searchquerysent;
      });

      if(query.length === 0){

          console.log("Refetching Application list", query, " query length: ", query.length);
          datasentrefetch();
      }
}

const debouncedFilterSent = useDebounce(searchquerysent, 500);

console.log("debouncedFilterSent", debouncedFilterSent); 

const searchSent = useQuery(
 ['sentMessages', debouncedFilterSent], 
 () => api.searchMessageSent(userData.id, debouncedFilterSent),
 { enabled: Boolean(debouncedFilterSent),
  onSuccess: (data) => {
    console.log("sentMessages",data)
    const apd = [...data];
    setMsgdatasend(apd);
    setMsgdata((msgdatasend) => {
      return msgdatasend;
    })
  },
  onError: (error) => {
    
    console.log("Error Message",error); 
  
    if(error?.response?.status === 401){
      toast.error("Session Expired : Please Re-Login",{
        duration: 6000,
        });
     removeUserSession();
      navigate("/", {replace: true});
    }
    else {
      toast.error("Something went wrong",{
        duration: 6000,
        });
    }
  
  } 
});

 /////////////////////////////////

  return (
    <Fragment>
      <Toaster />
      <div className="normalheader" id="tpanel">
        <div className="hpanel">
          <div className="panel-body">
            <a className="small-header-action" id="toglepanel" href={void(0)}>
              <div className="clip-header">
                <i className="fa fa-arrow-up"></i>
              </div>
            </a>

            <div id="hbreadcrumb" className="pull-right m-t-lg">
              <ol className="hbreadcrumb breadcrumb">
                <li>
                  <a href={void(0)}>i-visas</a>
                </li>
                <li>
                  <span>Client</span>
                </li>
                <li className="active">
                  <span>Messages </span>
                </li>
              </ol>
            </div>
            <h2 className="font-light m-b-xs">Messages</h2>
            <small>
              <strong>View</strong> received messages & <strong>Compose</strong> a message to an Agent
            </small>
          </div>
        </div>
      </div>

      <div className="content">
        <div className="row">
          <div className="col-md-3">
            <div className="hpanel">
              <div className="panel-body">
                {mailview !== 1 && (
                  <a
                    href={void(0)}
                    onClick={composeMail}
                    className="btn btn-success btn-block m-b-md">
                    Compose
                  </a>
                )}
                <ul className="mailbox-list">
                <li className={mailbox === "inbox" ? "active" : ""}>
                    <a href={void(0)} onClick={handleMailbox} data-mb="inbox">
                     
                      <span className="pull-right">{!!msgdata && (Object.keys(msgdata).length > 0) ? msgdata.length : 0}</span>
                      
                      <i className="fa fa-envelope"></i> Inbox
                    </a>
                  </li>
                  <li className={mailbox === "sent" ? "active" : ""}>
                    <a href={void(0)} onClick={handleMailbox} data-mb="sent">
                    
                      <span className="pull-right">{!!msgdatasend && (Object.keys(msgdatasend).length > 0) ? msgdatasend.length : 0}</span>
                    
                      <i className="fa fa-paper-plane"></i> Sent
                    </a>
                  </li>
                 {/*  <li>
                    <a href={void(0)} onClick={() => setMailbox("draft")}>
                      <i className="fa fa-pencil"></i> Draft
                    </a>
                  </li>
                  <li>
                    <a href={void(0)} onClick={() => mailBox("trash")}>
                      <i className="fa fa-trash"></i> Trash
                    </a>
                  </li> */}
                </ul>
                <hr />
                <ul className="mailbox-list">
                  <li>
                    <span>
                      <strong>Tags</strong>
                    </span>
                  </li>
                  <li className={tagName === "Travel" ? "activetag" : ""}>
                    <a href={void(0)} onClick={() => mailBox("Travel")}>
                      <i className="fa fa-plane text-danger"></i> <span>Travel</span>
                    </a>
                  </li>
                  <li className={tagName === "Billing" ? "activetag" : ""}>
                    <a href={void(0)} onClick={() => mailBox("Billing")}>
                      <i className="fa fa-calculator text-warning"></i>  <span>Billing</span>
                    </a>
                  </li>
                  <li className={tagName === "Payment" ? "activetag" : ""}>
                    <a href={void(0)} onClick={() => mailBox("Payment")}>
                      <i className="fa fa-credit-card-alt text-info"></i>{" "}
                      <span>Payments</span>
                    </a>
                  </li>
                  <li className={tagName === "General" ? "activetag" : ""}>
                    <a href={void(0)} onClick={() => mailBox("General")}>
                      <i className="fa fa-tag text-success"></i> <span>General</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-md-9">
            <div className={mailview !== 1 ? "hpanel email-compose" : "hpanel"}>
              {mailview === 0 ? ( mailbox === "inbox" ? (
                <>
                  <div className="panel-heading hbuilt">
                    <div className="text-center p-xs font-normal">
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={searchApplicationreceived}
                          value={searchqueryreceived || ""}
                          className="form-control input-sm"
                          placeholder="Search for received messages in your inbox..."
                        />{" "}
                        <span className="input-group-btn">
                          {" "}
                          <button
                            type="button"
                            disabled
                            className="btn btn-sm btn-default">
                            <i className="fa fa-search"></i> 
                          </button>{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="panel-body">
                  {searchReceived.isLoading ? (
                        <>
                        <div className="row text-center">
                        <div className="col-md-6 m-b-md">
                        
                        <div className="btn-group">
                         {/*  <button className="btn btn-default btn-sm" disabled >
                            <i className="fa fa-eye"></i>
                          </button> */}
                         {/*  <button className="btn btn-default btn-sm" disabled >
                          <i className="fa fa-tag"></i> &nbsp; Re-Tag
                          </button> */}
                          <button className="btn btn-default btn-sm" disabled ><i className="fa fa-trash-o"></i> Delete</button>
                        </div>

                        </div>
                            <div className="col-md-6 mailbox-pagination m-b-md">
                              <span style={{ fontSize: "1.1rem" }}>
                              Showing 0 of 0 pages   &nbsp; &nbsp;
                              </span>
                              <div className="btn-group">
                                <button className="btn btn-default btn-sm previous disabled"
                              
                                disabled
                                >
                                  <i className="fa fa-arrow-left"></i>
                                </button>
                                {getPaginationGroup().map((item, index) => (
                                <button 
                                key={index}
                                className="btn btn-default btn-sm disabled"
                                >
                                {item}
                                </button>
                                ))}
                                <button className="btn btn-default btn-sm next disabled"
                                disabled
                                >
                                  <i className="fa fa-arrow-right"></i>
                                </button>
                              </div>
                            </div>
                        </div>
                          <div className="table-responsive" style={{overflowX: "inherit"}}>
                          <table className="table table-hover table-mailbox apptable">
                            <thead style={{borderBottom: "solid 2px #64d246"}}>
                            <tr>
                            <th>#</th>
                            <th>FROM</th>
                            <th>Subject </th>
                            <th>Tag</th>
                            <th>Status </th>
                            <th className="text-left">Date</th>
                           
                          </tr>
                              </thead>
                              <tbody>
                              <tr className="active"><td colSpan="6" style={{textAlign:"center"}}>Searching...</td></tr>
                          </tbody>
                        </table>
                          </div>
                          </>
                        ):(
                        <>
                    <div className="row">
                      <div className="col-md-6 m-b-md">
                        <div className="btn-group">
                         {/*  <button className="btn btn-default btn-sm" disabled={!!msgdata && (Object.keys(msgdata).length > 0) ? false : true} >
                            <i className="fa fa-refresh"></i> Refresh
                          </button> */}
                          {/* <button className="btn btn-default btn-sm" disabled={!!msgdata && (Object.keys(msgdata).length > 0) ? false : true} >
                            <i className="fa fa-eye"></i>
                          </button> */}

                          {/* <button className="btn btn-default btn-sm" disabled={!!msgdata && (Object.keys(msgdata).length > 0) ? false : true} >
                            <i className="fa fa-tag"></i> &nbsp; Re-Tag
                          </button> */}
                          <button className="btn btn-default btn-sm" disabled={!!msgdata && (Object.keys(msgdata).length > 0) ? false : true} onClick={() => deleteMessage('received')}><i className="fa fa-trash-o"></i> Delete</button>
                        </div>
                      </div>
                      
                      <div className="col-md-6 mailbox-pagination m-b-md">
                        <span style={{ fontSize: "1.1rem" }}>
                        {!!msgdata && (Object.keys(msgdata).length > 0) ? `Showing ${currentPage} of ${pages.current} pages` : "Showing 0 pages"}   &nbsp; &nbsp;
                        </span>
                        <div className="btn-group">
                          <button className={`btn btn-default btn-sm previous ${currentPage === 1 ? 'disabled' : ''}`}
                          onClick={goToPreviousPage}
                          disabled={!!msgdata && (currentPage === 1) ? true : false} 
                          >
                            <i className="fa fa-arrow-left"></i>
                          </button>
                          {getPaginationGroup().map((item, index) => (
                          <button 
                          key={index}
                          onClick={changePage}
                          className={`btn btn-default btn-sm  ${currentPage === item ? 'active' : null}`}
                          >
                           {item}
                          </button>
                          ))}
                          <button className={`btn btn-default btn-sm next ${currentPage === pages.current ? 'disabled' : ''}`}
                          onClick={goToNextPage} 
                          disabled={!!msgdata && (currentPage === pages.current) ? true : false} 
                          >
                            <i className="fa fa-arrow-right"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table className="table table-hover table-mailbox apptable">
                      <thead style={{borderBottom: "solid 2px #64d246"}}>
                          <tr>
                            <th>#</th>
                            <th>FROM</th>
                            <th>Subject </th>
                            <th>Tag</th>
                            <th>Status </th>
                            <th className="text-left">Date</th>
                           
                          </tr>
                        </thead>
                        <tbody>

                        {!!msgdata && (Object.keys(msgdata).length > 0) ? 
                            
                            getPaginatedData().map((item,i) =>{
                                         return (
                                          <tr className={item.MESSAGE_STATUS === "unread" ? "unread active" : ""} key={i} style={{borderBottom:"solid 1px #e8ebef"}}>
                                          <td>
                                            <div className="checkbox checkbox-single checkbox-success">
                                              <input
                                                type="checkbox"
                                                onChange={handleMesgCheck}
                                                /* name={`received_${item.MESSAGE_ID}`} */
                                                name="chck"
                                                value={`received_${item.MESSAGE_ID}`}
                                                /* {...registercheckbox(`received_${item.MESSAGE_ID}`, { required: { value: false, message: "This field is required" }, onChange: (e) => {handleMesgCheck(e)} })} */
                                                /* data-msgid={item.CONVERSATION_ID} */
                                                data-msgid={item.CONVERSATION_ID} 
                                              />
                                              <label></label>
                                            </div>
                                          </td>
                                          <td>
                                          <a
                                              href={void(0)}
                                              style={{fontWeight: item.MESSAGE_STATUS === "unread" ? "600" : "normal"}}
                                              onClick={() => viewMail(item.CONVERSATION_ID)}>
                                            <span>I-VISAS</span>
                                            </a>
                                            </td>
                                          <td>
                                            <a
                                              href={void(0)}
                                              style={{fontWeight: item.MESSAGE_STATUS === "unread" ? "600" : "normal"}}
                                              onClick={() => viewMail(item.CONVERSATION_ID)}>
                                              {item.MESSAGE_SUBJECT}
                                            </a>
                                          </td>
                                          <td>
                                            <a
                                              href={void(0)}
                                              style={{fontWeight: item.MESSAGE_STATUS === "unread" ? "600" : "normal"}}
                                              onClick={() => viewMail(item.MESSAGE_ID)}>
                                                {TagIcon(item.MESSAGE_TAG)} {item.MESSAGE_TAG}
                                            </a>
                                          </td>
                                          <td>
                                            <a
                                              href={void(0)}
                                              style={{fontWeight: item.MESSAGE_STATUS === "unread" ? "600" : "normal"}}
                                              onClick={() => viewMail(item.CONVERSATION_ID)}>
                                              <i className={item?.MESSAGE_STATUS === "unread" ? "fa fa-envelope text-success" : "fa fa-envelope-o text-navy"}></i> {item.MESSAGE_STATUS}
                                            </a>
                                          </td>
                                          <td className="text-left mail-date">
                                          <a
                                              href={void(0)}
                                              style={{fontWeight: item.MESSAGE_STATUS === "unread" ? "600" : "normal"}}
                                              onClick={() => viewMail(item.CONVERSATION_ID)}>
                                          {maildate(item?.updated_at, 1)}
                                          </a>
                                          </td>
                                        </tr>
                                         )
                                     }) : (
                                      <tr className="unread active"><td colSpan="6" style={{textAlign:"center"}}>No Messages found</td></tr>
                                    )}
                        </tbody>
                      </table>
                    </div>
                    </>
                      )}
                  </div>
                  <div className="panel-footer">
                    <i className="fa fa-eye"> </i> {!!msgdata && (Object.keys(msgdata).length > 0) ? msgdata.filter((msg) => {
                      return msg.MESSAGE_STATUS === 'unread';
                    }).length : 0}  unread
                  </div>
                </>
                ):
                (

                <>
                  <div className="panel-heading hbuilt">
                    <div className="text-center p-xs font-normal">
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={searchApplicationsent}
                          value={searchquerysent || ""}
                          className="form-control input-sm"
                          placeholder="Search for messages in your inbox..."
                        />{" "}
                        <span className="input-group-btn">
                          {" "}
                          <button
                            type="button"
                            disabled
                            className="btn btn-sm btn-default">
                            <i className="fa fa-search"></i> 
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="panel-body">

                  {searchSent.isLoading ? (
                        <>
                        <div className="row text-center">
                        <div className="col-md-6 m-b-md">
                        
                        <div className="btn-group">
                         {/*  <button className="btn btn-default btn-sm" disabled >
                            <i className="fa fa-eye"></i>
                          </button> */}

                         {/*  <button className="btn btn-default btn-sm" disabled >
                          <i className="fa fa-tag"></i> &nbsp; Re-Tag
                          </button> */}
                          <button className="btn btn-default btn-sm" disabled ><i className="fa fa-trash-o"></i> Delete</button>
                        </div>

                        </div>
                            <div className="col-md-6 mailbox-pagination m-b-md">
                              <span style={{ fontSize: "1.1rem" }}>
                              Showing 0 of 0 pages   &nbsp; &nbsp;
                              </span>
                              <div className="btn-group">
                                <button className="btn btn-default btn-sm previous disabled"
                              
                                disabled
                                >
                                  <i className="fa fa-arrow-left"></i>
                                </button>
                                {getPaginationGroup().map((item, index) => (
                                <button 
                                key={index}
                                className="btn btn-default btn-sm disabled"
                                >
                                {item}
                                </button>
                                ))}
                                <button className="btn btn-default btn-sm next disabled"
                                disabled
                                >
                                  <i className="fa fa-arrow-right"></i>
                                </button>
                              </div>
                            </div>
                        </div>
                          <div className="table-responsive" style={{overflowX: "inherit"}}>
                          <table className="table table-hover table-mailbox apptable">
                            <thead style={{borderBottom: "solid 2px #64d246"}}>
                            <tr>
                            <th>#</th>
                            <th>To</th>
                            <th>Subject </th>
                            <th>Tag</th>
                            <th>Status </th>
                            <th className="text-left">Date</th>
                           
                          </tr>
                              </thead>
                              <tbody>
                              <tr className="active"><td colSpan="6" style={{textAlign:"center"}}>Searching...</td></tr>
                          </tbody>
                        </table>
                          </div>
                          </>
                        ):(
                        <>
                    <div className="row">
                      <div className="col-md-6 m-b-md">
                        <div className="btn-group">
                         {/*  <button className="btn btn-default btn-sm" disabled={!!msgdatasend && (Object.keys(msgdatasend).length > 0) ? false : true} >
                          <i className="fa fa-tag"></i> &nbsp; Re-Tag
                          </button> */}
                          <button className="btn btn-default btn-sm" disabled={!!msgdatasend && (Object.keys(msgdatasend).length > 0) ? false : true} onClick={() => deleteMessage('sent')}><i className="fa fa-trash-o"></i> Delete</button>
                        </div>
                      </div>
                      <div className="col-md-6 mailbox-pagination m-b-md">
                        <span style={{ fontSize: "1.1rem" }}>
                        {!!msgdatasend && (Object.keys(msgdatasend).length > 0) ? `Showing ${currentPageSend} of ${pagesSend.current} pages` : "Showing 0 pages"}   &nbsp; &nbsp;
                        </span>
                        <div className="btn-group">
                          <button className={`btn btn-default btn-sm previous ${currentPageSend === 1 ? 'disabled' : ''}`}
                          onClick={goToPreviousPageSend}
                          disabled={!!msgdatasend && (currentPageSend === 1) ? true : false} 
                          >
                            <i className="fa fa-arrow-left"></i>
                          </button>
                          {getPaginationGroupSend().map((item, index) => (
                          <button 
                          key={index}
                          onClick={changePageSend}
                          className={`btn btn-default btn-sm  ${currentPageSend === item ? 'active' : null}`}
                          >
                           {item}
                          </button>
                          ))}
                          <button className={`btn btn-default btn-sm next ${currentPageSend === pagesSend.current ? 'disabled' : ''}`}
                          onClick={goToNextPageSend} 
                          disabled={!!msgdatasend && (currentPageSend === pagesSend.current) ? true : false} 
                          >
                            <i className="fa fa-arrow-right"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table className="table table-hover table-mailbox apptable">
                      <thead style={{borderBottom: "solid 2px #64d246"}}>
                          <tr>
                            <th>#</th>
                            <th>TO</th>
                            <th>Subject </th>
                            <th>Tag</th>
                            <th>Status </th>
                            <th className="text-left">Date</th>
                           
                          </tr>
                        </thead>
                        <tbody>

                        {!!msgdatasend && (Object.keys(msgdatasend).length > 0) ? 
                            
                            getPaginatedDataSend().map((item,i) =>{
                                         return (
                                          <tr className={item.MESSAGE_STATUS === "unread" ? "unread active" : ""} key={i} style={{borderBottom:"solid 1px #e8ebef"}}>
                                          <td>
                                            <div className="checkbox checkbox-single checkbox-success">
                                              <input
                                                type="checkbox"
                                                name="chck"
                                                value={`sent_${item.MESSAGE_ID}`}
                                                onChange={handleMesgCheck} 
                                                /* {...registercheckbox(`sent_${item.MESSAGE_ID}`, { required: { value: false, message: "This field is required" }, onChange: (e) => {handleMesgCheck(e)} })} */
                                                /* data-msgid={item.MESSAGE_ID} */
                                                data-msgid={item.CONVERSATION_ID} 
                                              />
                                              <label></label>
                                            </div>
                                          </td>
                                          <td>
                                          <a href={void(0)}
                                          style={{fontWeight: "normal"}}
                                              onClick={() => viewMailSent(item.CONVERSATION_ID)}>
                                            <span>I-VISAS</span>
                                            </a>
                                            </td>
                                          <td>
                                            <a
                                              href={void(0)}
                                              style={{fontWeight: "normal"}}
                                              onClick={() => viewMailSent(item.CONVERSATION_ID)}>
                                              {item.MESSAGE_SUBJECT}
                                            </a>
                                          </td>
                                          <td>
                                            <a
                                              href={void(0)}
                                              style={{fontWeight: "normal"}}
                                              onClick={() => viewMailSent(item.CONVERSATION_ID)}>
                                                {TagIcon(item.MESSAGE_TAG)} {item.MESSAGE_TAG}
                                            </a>
                                          </td>
                                          <td>
                                            <a
                                              href={void(0)}
                                              style={{fontWeight: "normal"}}
                                              onClick={() => viewMailSent(item.CONVERSATION_ID)}>
                                              <i className="fa fa-envelope-o text-navy"></i> {item.MESSAGE_STATUS}
                                            </a>
                                          </td>
                                          <td className="text-left mail-date">
                                          <a
                                              href={void(0)}
                                              style={{fontWeight: "normal"}}
                                              onClick={() => viewMailSent(item.CONVERSATION_ID)}>
                                          {maildate(item?.updated_at, 1)}
                                          </a>
                                          </td>
                                        </tr>
                                         )
                                     }) : (
                                      <tr className="unread active"><td colSpan="6" style={{textAlign:"center"}}>No Messages found</td></tr>
                                    )}
                        </tbody>
                      </table>
                    </div>
                    </>
                    )}
                  </div>
                  <div className="panel-footer">
                    <i className="fa fa-eye"> </i> {!!msgdatasend && (Object.keys(msgdatasend).length > 0) ? msgdatasend.filter((msg) => {
                      return msg.MESSAGE_STATUS === 'unread';
                    }).length : 0}  unread
                  </div>
                </>

                )

              ) : mailview === 1 ? (
                <>
                  <div className="panel-heading hbuilt">
                    <div className="p-xs h4">New message</div>
                  </div>
                  <div className="panel-heading hbuilt">
                    <div className="p-xs">
                      <form method="get" className="form-horizontal">
                        
                        <div className="form-group">
                          <label className="col-sm-1 control-label text-left">
                            Subject:
                          </label>

                          <div className="col-sm-11">
                            <input
                              type="text"
                              {...register('MESSAGE_SUBJECT', { required: { value: true, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                              className="form-control input-sm"
                              placeholder="Enter Message subject"
                              name="MESSAGE_SUBJECT"
                            />
                             {errors.MESSAGE_SUBJECT && (
                  <span className="errorValidate">Message <strong>Subject</strong> is required</span>
                )}
                          </div>
                        </div>

                        <div className="form-group">
                          <label className="col-sm-1 control-label text-left">
                            Tag
                          </label>
                          <div className="col-sm-11">
                            <div className="radio radio-success radio-inline">
                              <input
                                type="radio"
                                id="travel"
                                value="Travel"
                                name="MESSAGE_TAG"
                                checked={message.MESSAGE_TAG === "Travel"}
                                {...register('MESSAGE_TAG', { required: { value: true, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                              />
                              <label htmlFor="travel">Travel </label>
                            </div>
                            <div className="radio radio-success radio-inline">
                              <input
                                type="radio"
                                id="billing"
                                value="Billing"
                                name="MESSAGE_TAG"
                                checked={message.MESSAGE_TAG === "Billing"}
                                {...register('MESSAGE_TAG', { required: { value: true, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                              />
                              <label htmlFor="billing">Billing </label>
                            </div>
                            <div className="radio radio-success radio-inline">
                              <input
                                type="radio"
                                id="payment"
                                value="Payment"
                                name="MESSAGE_TAG"
                                checked={message.MESSAGE_TAG === "Payment"}
                                {...register('MESSAGE_TAG', { required: { value: true, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                              />
                              <label htmlFor="payment">Payment </label>
                            </div>
                            <div className="radio radio-success radio-inline">
                              <input
                                type="radio"
                                id="general"
                                value="General"
                                name="MESSAGE_TAG"
                                /* checked={message.MESSAGE_TAG === "General"} */
                                defaultChecked
                                {...register('MESSAGE_TAG', { required: { value: true, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                              />
                              <label htmlFor="general">General </label>
                            </div>
                            {errors.MESSAGE_TAG && (
                           <p className="errorValidate">Message <strong>Tag</strong> is required</p>
                           )}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="panel-body no-padding">
                    <div>
                      <ReactQuill
                        theme="snow"
                        modules={modules}
                        formats={formats}
                        placeholder="compose here"
                        value={convertedText}
                        onChange={setConvertedText}
                        style={{ minHeight: "300px" }}
                      />
                      </div>
                     </div>
                  {convertedTextStatus && (
                           <p className="errorValidate">Message <strong>Body</strong> is required</p>
                           )}

                  <div className="panel-footer">
                    <div className="pull-right">
                      <div className="btn-group">
                        {/* <button className="btn btn-default"><i className="fa fa-edit"></i> Save</button> */}
                        <button className="btn btn-default" onClick={handleDiscard}>
                          <i className="fa fa-trash"></i> Discard
                        </button>
                      </div>
                    </div>

                    {sendmsgLoading ? (
                          <span className="btn">
                            <i className="fa fa-paper-plane" style={{color: "#5ac557"}}></i>
                            &nbsp; Sending Message...
                            <span className="lds-facebook"><span></span><span></span><span></span></span>
                          </span>
                        ) : (
                         <button className="btn btn-primary" onClick={handleSubmit}>
                      <i className="fa fa-paper-plane"></i> Send Message
                    </button>

                          
                        )}

                  {/*   <button className="btn btn-primary" onClick={handleSubmit}>
                      <i className="fa fa-paper-plane"></i> Send Message
                    </button> */}

                    {/* <div className="btn-group">
                            <button className="btn btn-default"><i className="fa fa-paperclip"></i> </button>
                            <button className="btn btn-default"><i className="fa fa-image"></i> </button>
                        </div> */}
                  </div>
                </>
              ): mailview === 2 ? (
                <>
                  <div className="panel-heading hbuilt">
                    <div className="p-xs h4">Reply Message</div>
                  </div>
                  {!!viewmsg.attributes && (Object.keys(viewmsg.attributes).length > 0) ? 
                            
                            viewmsg.attributes.slice(0, 1).map((msgitem,i) =>{
                              return (
                            <div key={i+10}>
                              <div key={i}
                                className="border-top border-left border-right bg-light"
                                style={{
                                  borderTop: "1px solid #62cb31",
                                  borderBottom: "1px solid #62cb31",
                                }}>
                              <div className="p-m" key={i+1}>
                                <div style={{margin: "5px 1px"}} key={i+2}>
                                  <span className="font-extra-bold">Subject: </span>
                                  {msgitem?.MESSAGE_SUBJECT || ""}
                                </div>
                                <div style={{margin: "5px 1px"}} key={i+3}>
                                  <span className="font-extra-bold">{mailbox === "inbox" ? "From: " : "To: "}</span>
                                  {mailbox === "inbox" ? (
                                  <a href={void(0)}>I-VISAS</a>
                                  ): (
                                    <a href={void(0)}>I-VISAS
                                    </a>
                                  )}
                                </div>
                                <div style={{margin: "5px 1px"}} key={i+4}>
                                  <span className="font-extra-bold">Date: </span>
                                  {maildate(msgitem?.created_at, 1) || ""}
                                </div>
                                <div style={{margin: "5px 1px"}} key={i+5}>
                                  <span className="font-extra-bold">Tag:  </span>
                                  {TagIcon(msgitem?.MESSAGE_TAG)} {viewmsg?.MESSAGE_TAG || ""}
                                </div>
                              </div>
                              </div>
                            <div className="panel-body" key={i+6}>
                              <div className="messagebody" key={i+7} style={{margin: "15px 10px"}} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(msgitem?.MESSAGE || "") }} />
                              </div>
                              { i < (viewmsg.attributes.length -1) &&
                              <hr className="slash-2"  key={i+8}/>
                            }
                            </div>
                           )
                          }) : (
                           <div className="unread active" style={{textAlign:"center"}}>No Messages found</div>
                         )}
                  <div className="panel-body no-padding">
                    <div>
                      <ReactQuill
                        theme="snow"
                        modules={modules}
                        formats={formats}
                        placeholder="compose here"
                        value={convertedText}
                        onChange={setConvertedText}
                        style={{ minHeight: "300px" }}
                      />
                      </div>
                     </div>
                  {convertedTextStatus && (
                           <p className="errorValidate">Message <strong>Body</strong> is required</p>
                           )}

                  <div className="panel-footer">
                    <div className="pull-right">
                      <div className="btn-group">
                        {/* <button className="btn btn-default"><i className="fa fa-edit"></i> Save</button> */}
                        <button className="btn btn-default" onClick={handleDiscard}>
                          <i className="fa fa-trash"></i> Discard
                        </button>
                      </div>
                    </div>

                    {sendmsgLoading ? (
                          <span className="btn">
                            <i className="fa fa-paper-plane" style={{color: "#5ac557"}}></i>
                            &nbsp; Sending Message...
                            <span className="lds-facebook"><span></span><span></span><span></span></span>
                          </span>
                        ) : (
                    <button className="btn btn-primary" onClick={handleReplySubmit}>
                      <i className="fa fa-paper-plane"></i> Send Reply
                    </button>
                        )}

                  </div>
                </>
              
              ) : (
                <>
                  <div className="panel-heading hbuilt">
                    <div className="p-xs h4">
                      <small className="pull-right">
                        {/* 08:26 PM (16 hours ago) */}
                        {maildate(viewmsg?.created_at) || ""}
                      </small>
                      View Message
                    </div>
                  </div>
                  {!!viewmsg.attributes && (Object.keys(viewmsg.attributes).length > 0) ? 
                            
                            viewmsg.attributes.map((msgitem,i) =>{
                              return (
                            <div key={i+10}>
                              <div key={i}
                                className="border-top border-left border-right bg-light"
                                style={{
                                  borderTop: "1px solid #62cb31",
                                  borderBottom: "1px solid #62cb31",
                                }}>
                              <div className="p-m" key={i+1}>
                                <div style={{margin: "5px 1px"}} key={i+2}>
                                  <span className="font-extra-bold">Subject: </span>
                                  {msgitem?.MESSAGE_SUBJECT || ""}
                                </div>
                                <div style={{margin: "5px 1px"}} key={i+3}>
                                  <span className="font-extra-bold">{mailbox === "inbox" ? "From: " : "To: "}</span>
                                  {mailbox === "inbox" ? (
                                      <a href={void(0)}>I-VISAS</a>
                                      ): (
                                        <a href={void(0)}>I-VISAS</a>
                                      )}
                                </div>
                                <div style={{margin: "5px 1px"}} key={i+4}>
                                  <span className="font-extra-bold">Date: </span>
                                  {maildate(msgitem?.created_at, 1) || ""}
                                </div>
                                <div style={{margin: "5px 1px"}} key={i+5}>
                                  <span className="font-extra-bold">Tag:  </span>
                                  {TagIcon(msgitem?.MESSAGE_TAG)} {msgitem?.MESSAGE_TAG || ""}
                                </div>
                              </div>
                              </div>
                            <div className="panel-body" key={i+6}>
                              <div className="messagebody" key={i+7} style={{margin: "15px 10px"}} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(msgitem?.MESSAGE || "") }} />
                              </div>
                              { i < (viewmsg.attributes.length -1) &&
                              <hr className="slash-2"  key={i+8}/>
                            }
                            </div>
                           )
                          }) : (
                           <div className="unread active" style={{textAlign:"center"}}>No Messages found</div>
                         )}
      {!!viewmsg.attributes && (Object.keys(viewmsg.attributes).length > 0) &&
                      <div className="panel-footer text-right">
                        <div className="btn-group">
                          <button onClick={() => {replyMsg(viewmsg?.attributes[0]?.CONVERSATION_ID)}}
                          className="btn btn-default">
                            <i className="fa fa-reply"></i> Reply
                          </button>
                          {/*   <button className="btn btn-default"><i className="fa fa-arrow-right"></i> Forward</button> */}
                         {/*  <button onClick={() => {printMsg(viewmsg?.attributes[0]?.CONVERSATION_ID)}}
                          className="btn btn-default">
                            <i className="fa fa-print"></i> Print
                          </button> */}
                      {/* <button onClick={() => {removeMsg(viewmsg?.MESSAGE_ID)}}
                      className="btn btn-default">
                        <i className="fa fa-trash-o"></i> Remove
                      </button> */}
                    </div>
                  </div>
              }
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default Messages; ///withRouter(Master);
