import axios from 'axios';

const initialApi = axios.create({
    //baseURL: "http://localhost:8000/api",
    baseURL:"https://testbackend.i-visas.com/api",
    //baseURL: process.env.REACT_APP_BASEURL_API,
    withCredentials: true,
    headers: {
        "Content-Type":"application/json",
              "Accept":"application/json"
    },

});

console.log("BaseURL:", process.env.REACT_APP_BASEURL_API);

const initialCSRF = axios.create({
    //baseURL: "http://localhost:8000",
    baseURL:"https://testbackend.i-visas.com",
    //baseURL: process.env.REACT_APP_BASEURL,
    withCredentials: true,
     headers: {
             "Content-Type":"application/json", 
                   "Accept":"application/json"  
       } 
});


const api = axios.create({
    //baseURL: "http://localhost:8000/api",
    baseURL:"https://testbackend.i-visas.com/api",
    //baseURL: process.env.REACT_APP_BASEURL_API,
    withCredentials: true,
    headers: {
         "Content-Type":"application/json", 
                   "Accept":"application/json"
    },

});

  api.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    config.headers.Authorization = token ? `Bearer ${token}` : '';

    return config;
})  


export const register = (clientdata) => initialApi.post('/client/ivisas/register', clientdata).then(res => res.data);//.catch(err => err);

export const verifySMS = (otp) => initialApi.post('/client/otp/validate', otp).then(res => res.data);//.catch(err => err);

export const resendOTP = (number) => initialApi.post('/client/resend/otp', number).then(res => res.data);//.catch(err => err);

export const validatemail = (email_token) => initialApi.post('/client/email/validate', email_token).then(res => res.data);//.catch(err => err);

export const resendEmail = (email) => initialApi.post('/client/ivisas/email/resend', email).then(res => res.data);//.catch(err => err);

export const forgottenpassword = (email) => initialApi.post('/client/password/forgotpassword', email).then(res => res.data);//.catch(err => err);

export const resetpassword = (pass) => initialApi.post('/client/password/resetpassword', pass).then(res => res.data);//.catch(err => err);

//////////////////TOken Calls ///////////////
export const login = (logincredential) => initialCSRF.get('/sanctum/csrf-cookie').then(response => api.post('/client/login', logincredential)).then(res => res.data);

export const getClientbyID = (id) => api.get(`/client/${id}`).then(res => res.data);//.catch(err => err); 



export const logout = (sessionid) => api.post('/client/clientsession/logout', sessionid).then(res => res.data);//.catch(err => err); 

export const getClientPrescreen = () => api.get(`/prescreening`).then(res => res.data);//.catch(err => err); 

export const getAllApplications = () => api.get('/application').then(res => res.data);//.catch(err => err); 

export const getClientApplications = (id) => api.get(`/application/search_application_client/${id}`).then(res => res.data);//.catch(err => err);

export const getCLientMessages = (id) => api.get(`/message/showmessagesbyclientidreceived/${id}`).then(res => res.data);//.catch(err => err);

export const getCLientMessagesSent = (id) => api.get(`/message/showmessagesbyclientid/${id}`).then(res => res.data);//.catch(err => err);

export const sendClientMessage = (msg) => api.post('message/sendclientmessage', msg).then(res => res.data);//.catch(err => err);

export const showClientMessage = (payload) => api.post('/message/viewclientreceivedmessage', payload).then(res => res.data);//.catch(err => err);

export const showClientSentMessage = (payload) => api.post('/message/viewclientsentmessage', payload).then(res => res.data);//.catch(err => err);

export const deleteMessage = (payload) => api.post('/message/client/deletemessage', payload).then(res => res.data);//.catch(err => err);

export const replyUserMessage = (msg) => api.post('/message/replyusermessage', msg).then(res => res.data);//.catch(err => err);

export const createApp = (clientid) => api.post("/application/createapplication", clientid).then(res => res.data);//.catch(err => err);

export const editApp = (appid) => api.get(`/application/show_by_application_id/${appid}`).then(res => res.data);//.catch(err => err);

export const saveDraftApp = (formdata) => api.post("/application/saveapplicationdraft", formdata).then(res => res.data);//.catch(err => err);

export const submitApp = (formdata) => api.post("/application/submitapplication", formdata).then(res => res.data);//.catch(err => err);

export const getPrescreenByClientID = (id) => api.get(`/prescreening/${id}`).then(res => res.data);//.catch(err => err);

export const searchApplication = (search) => api.get(`/application/search/${search}`).then(res => res.data);//.catch(err => err);

export const searchMessageReceived = (id, keyword) => api.get(`/message/searchreceived/${id}/${keyword}`).then(res => res.data);//.catch(err => err);

export const searchMessageSent = (id, keyword) => api.get(`/message/searchsent/${id}/${keyword}`).then(res => res.data);//.catch(err => err);

export const addTracking = (trackinginfo) => api.post(`/tracking/create`, trackinginfo).then(res => res.data);//.catch(err => err);

export const uploadFile = (fileinfo) => api.post(`application/applicationupload`, fileinfo).then(res => res.data);//.catch(err => err);

export const retrieveFile = (path) => api.post(`application/application_uploads`, path).then(res => res.data);//.catch(err => err);

//////////////Admin///////////

export const adminlogin = (logincredential) => initialCSRF.get('/sanctum/csrf-cookie').then(response => api.post('/user/login', logincredential)).then(res => res.data);//.catch(err => err);

export const getAllClients = () =>  api.get("/client").then(res => res.data);//.catch(err => err); 

export const getUserMessages = (id) => api.get(`/message/showmessagesbyuserid/${id}`).then(res => res.data);//.catch(err => err); 

export const getAllClientMessage = () => api.get('/message/showmessagesfromallclients/i-visas').then(res => res.data);//.catch(err => err); 

export const showUserMessage = (payload) => api.post('/message/viewusermessage', payload).then(res => res.data);//.catch(err => err); 

export const sendUserMessage = (msg) => api.post('/message/sendusermessage', msg).then(res => res.data);//.catch(err => err); 

export const replyClientMessage = (msg) => api.post('/message/replyclientmessage', msg).then(res => res.data);//.catch(err => err); 

export const savePrescreen = (frmdata) => api.post('/prescreening/saveprescreendraft', frmdata).then(res => res.data);//.catch(err => err); 

export const submitPrescreen = (frmdata) => api.post('/prescreening/submitprescreen', frmdata).then(res => res.data);//.catch(err => err); 

export const getUser = (id) => api.get(`/users/${id}`).then(res => res.data);//.catch(err => err); 

export const getUsers = () => api.get('/users').then(res => res.data);//.catch(err => err); 

export const updateUser = (id, ...updatedUser) => api.post(`/users/${id}`).then(res => res.data);//.catch(err => err); 

export const approvePrescreen = (aprovedata) => api.post('/prescreening/approvescreening', aprovedata).then(res => res.data);//.catch(err => err); 

export const declinePrescreen = (declinedata) => api.post('/prescreening/declinescreening', declinedata).then(res => res.data);//.catch(err => err); 

export const getCourier = () => api.get('/courrier').then(res => res.data);//.catch(err => err);

export const getTrackingbyCientID = (id) => api.get(`/tracking/showbyclientid/${id}`).then(res => res.data);//.catch(err => err);

export const getTrackingByID = (id) => api.get(`/tracking/byTrackingid/${id}`).then(res => res.data);//.catch(err => err);

export const searchTracking = (keyword) => api.get(`/tracking/search/${keyword}`).then(res => res.data);//.catch(err => err);

////company:
export const createCompany = (companyinfo) => api.post('/company/setup', companyinfo).then(res => res.data);
export const updateCompany = (companyinfo) => api.post('/company/update', companyinfo).then(res => res.data);
export const showCompany = () => api.get('/company').then(res => res.data);


export const companyInfo = () => initialApi.get('/company/info').then(res => res.data);

/////bills
export const activeBills = () => api.get('/billing/activebills').then(res => res.data);

/////Invoice
export const getInvoicebyAppID = (appid) => api.get(`/invoice/byappid/${appid}`).then(res => res.data);
export const genInvoicePDF = (invoiceitems) => api.post('invoice/generatePDF', invoiceitems).then(res => res.data);