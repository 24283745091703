import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { getAdminToken } from './Common';

// handle the private routes
function PrivateRouteAdmin({ children }) {
	
  let location = useLocation()
  
  return getAdminToken() ? children : <Navigate to="/admin_login" state={{ from: location }} />
    
}

export default PrivateRouteAdmin;