import axios from 'axios';

const initialApi = axios.create({
    //baseURL: "http://localhost:8000/api",
    baseURL:"https://testbackend.i-visas.com/api",
    //baseURL: process.env.REACT_APP_BASEURL_API,
    withCredentials: true,
    headers: {
        "Content-Type":"application/json",
              "Accept":"application/json"
    },

});

const api = axios.create({
    //baseURL: "http://localhost:8000/api",
    baseURL:"https://testbackend.i-visas.com/api",
    //baseURL: process.env.REACT_APP_BASEURL_API,
    withCredentials: true,
    headers: {
       /*  "X-Requested-With": "XMLHttpRequest", */
        "Content-Type":"application/json",
              "Accept":"application/json"
    },

});

api.interceptors.request.use((config) => {
    const token = localStorage.getItem('admintoken');
    config.headers.Authorization = token ? `Bearer ${token}` : '';

    return config;
})

const initialCSRF = axios.create({
    //baseURL: "http://localhost:8000",
    baseURL:"https://testbackend.i-visas.com",
    //baseURL: process.env.REACT_APP_BASEURL,
    withCredentials: true,
    headers: {
       /*  "X-Requested-With": "XMLHttpRequest", */
         "Content-Type":"application/json",
               "Accept":"application/json" 
    }
});

//////////////Regist Client /////////////////////////////////

export const AdminRegisterClient = (clientdata) => api.post('/user/create/client', clientdata).then(res => res.data);//.catch(err => err);

export const verifySMS = (otp) => initialApi.post('/client/otp/validate', otp).then(res => res.data);//.catch(err => err);

export const resendOTP = (number) => initialApi.post('/client/resend/otp', number).then(res => res.data);//.catch(err => err);

export const validatemail = (email_token) => initialApi.post('/client/email/validate', email_token).then(res => res.data);//.catch(err => err);

export const resendEmail = (email) => initialApi.post('/client/ivisas/email/resend', email).then(res => res.data);//.catch(err => err);

//////////////////TOken Calls ///////////////
export const login = (logincredential) => initialCSRF.get('/sanctum/csrf-cookie').then(response => api.post('/client/login', logincredential)).then(res => res.data);

export const getClientbyID = (id) => api.get(`/client/${id}`).then(res => res.data);//.catch(err => err); 



export const logout = (sessionid) => api.post('/client/clientsession/logout', sessionid).then(res => res.data);//.catch(err => err); 

export const getClientPrescreen = () => api.get(`/prescreening`).then(res => res.data);//.catch(err => err); 

export const getAllApplications = () => api.get('/application').then(res => res.data);//.catch(err => err); 

export const getClientApplications = (id) => api.get(`/application/search_application_client/${id}`).then(res => res.data);//.catch(err => err); 

export const getCLientMessages = (id) => api.get(`/message/showmessagesbyclientidreceived/${id}`).then(res => res.data);//.catch(err => err); 

export const getCLientMessagesSent = (id) => api.get(`/message/showmessagesbyclientid/${id}`).then(res => res.data);//.catch(err => err); 

export const sendClientMessage = (msg) => api.post('message/sendclientmessage', msg).then(res => res.data);//.catch(err => err); 

export const showClientMessage = (payload) => api.post('/message/viewclientmessage', payload).then(res => res.data);//.catch(err => err); 

export const replyUserMessage = (msg) => api.post('/message/replyusermessage', msg).then(res => res.data);//.catch(err => err); 

export const createApp = (clientid) => api.post("/application/createapplication", clientid).then(res => res.data);//.catch(err => err); 

export const editApp = (appid) => api.get(`/application/show_by_application_id/${appid}`).then(res => res.data);//.catch(err => err); 

export const saveDraftApp = (formdata) => api.post("/application/saveapplicationdraft", formdata).then(res => res.data);//.catch(err => err); 

export const submitApp = (formdata) => api.post("/application/submitapplication", formdata).then(res => res.data);//.catch(err => err);

export const getPrescreenByClientID = (id) => api.get(`/prescreening/${id}`).then(res => res.data);//.catch(err => err); 

export const searchApplication = (search) => api.get(`/application/search/${search}`).then(res => res.data);//.catch(err => err); 

//////////////Admin///////////

export const adminlogin = (logincredential) => initialCSRF.get('/sanctum/csrf-cookie').then(response => api.post('/user/login', logincredential)).then(res => res.data); 

export const adminforgottenpassword = (email) => initialApi.post('/user/password/forget_password', email).then(res => res.data);//.catch(err => err);

export const adminresetpassword = (pass) => initialApi.post('/user/password/reset_password', pass).then(res => res.data);//.catch(err => err);

export const adminlogout = (sessionid) => api.post('/user/logout', sessionid).then(res => res.data);//.catch(err => err); 

export const getAllClients = () =>  api.get("/client").then(res => res.data);//.catch(err => err); 

export const getUserMessages = (id) => api.get(`/message/showmessagesbyuserid/${id}`).then(res => res.data);//.catch(err => err); 

export const getAllClientMessage = () => api.get('/message/showmessagesfromallclients/i-visas').then(res => res.data);//.catch(err => err); 

export const showUserMessage = (payload) => api.post('/message/viewusermessage', payload).then(res => res.data);//.catch(err => err); 

export const deleteMessage = (payload) => api.post('/message/user/deletemessage', payload).then(res => res.data);//.catch(err => err);

export const retagMessage = (payload) => api.post('/message/user/retagmessage', payload).then(res => res.data);//.catch(err => err);


export const sendUserMessage = (msg) => api.post('/message/sendusermessage', msg).then(res => res.data);//.catch(err => err); 

export const replyClientMessage = (msg) => api.post('/message/replyclientmessage', msg).then(res => res.data);//.catch(err => err); 

export const savePrescreen = (frmdata) => api.post('/prescreening/saveprescreendraft', frmdata).then(res => res.data);//.catch(err => err); 

export const submitPrescreen = (frmdata) => api.post('/prescreening/submitprescreen', frmdata).then(res => res.data);//.catch(err => err); 

export const approvePrescreen = (aprovedata) => api.post('/prescreening/approvescreening', aprovedata).then(res => res.data);//.catch(err => err); 

export const declinePrescreen = (declinedata) => api.post('/prescreening/declinescreening', declinedata).then(res => res.data);//.catch(err => err); 

export const approveApp = (approvedata) => api.post('/application/approveapplication', approvedata).then(res => res.data);

export const declineApplication = (declinedata) => api.post('/application/declineapplication', declinedata).then(res => res.data);

export const searchClients = (search) => api.get(`/client/searchbytype/${search}`).then(res => res.data);//.catch(err => err);

export const topThirty = () => api.post(`/client/get/top30clients`).then(res => res.data);//.catch(err => err);

export const verifiedClients = () => api.post(`/client/verified/clients`).then(res => res.data);//.catch(err => err);

export const searchClientbyLocation = (location) => api.post(`/client/searchby/location`, location).then(res => res.data);//.catch(err => err);

export const searchMessageReceivedUser = (id, keyword) => api.get(`/message/searchreceiveduser/${id}/${keyword}`).then(res => res.data);//.catch(err => err); 

export const searchMessageSentUser = (id, keyword) => api.get(`/message/searchsentuser/${id}/${keyword}`).then(res => res.data);//.catch(err => err); 

export const clientPrescreenAppDetails = (clientid) => api.post(`/client/clientdetails/perapplicationand/prescreening`, clientid).then(res => res.data);//.catch(err => err);

export const searchPrescreening = (search) => api.get(`/prescreening/searchbytype/${search}`).then(res => res.data);//.catch(err => err);

export const topThirtyPrescreen = () => api.post(`/prescreening/top30prescreenings`).then(res => res.data);//.catch(err => err);

export const searchPreScreenbyLocation = (location) => api.post(`/prescreening/prescreeningbyclientlocation`, location).then(res => res.data);//.catch(err => err);

export const topThirtyApplication = () => api.post(`/application/top30Applications`).then(res => res.data);//.catch(err => err);

export const reviewApplication = () => api.post(`/application/applicationsinreview`).then(res => res.data);//.catch(err => err);

export const searchApplicationbyLocation = (location) => api.post(`/application/applicationbyclientLocation`, location).then(res => res.data);//.catch(err => err);

export const generatePrescreenPDF = (clientid) => api.get('/generatepdf/prescreening', clientid).then(res => res.data);//.catch(err => err); 

export const generateApplicationPDF = (appid) => api.get('/generatepdf/application', appid).then(res => res.data);//.catch(err => err); 

export const assignOfficeLocation = (officeloc) => api.post(`/client/office/update`, officeloc).then(res => res.data);//.catch(err => err);

export const addTracking = (trackinginfo) => api.post(`/tracking/create`, trackinginfo).then(res => res.data);//.catch(err => err);

export const uploadFile = (fileinfo) => api.post(`application/applicationupload`, fileinfo).then(res => res.data);//.catch(err => err);


export const retrieveFile = (path) => api.post(`application/application_uploads`, path).then(res => res.data);//.catch(err => err);

export const assignGroup = (grp) => api.post(`/client/group/update`, grp).then(res => res.data);//.catch(err => err);

export const assignAgent = (user) => api.post(`/client/agent/update`, user).then(res => res.data);//.catch(err => err);

export const addNote = (note) => api.post(`/notes/create`, note).then(res => res.data);//.catch(err => err);

//export const getUser = (id) => api.get(`/users/${id}`).then(res => res.data); 

///export const getUsers = () => api.get('/users').then(res => res.data); 

export const updateUser = (updatedUser) => api.post(`/user/update`, updatedUser).then(res => res.data);//.catch(err => err); 

export const getAllUsers = () => api.get('/user/allusers').then(res => res.data);//.catch(err => err);

export const getUserByID = (id) => api.get(`/user/show/${id}`).then(res => res.data);//.catch(err => err);

export const AddUser = (data) => api.post(`/user/create_user`, data).then(res => res.data);//.catch(err => err);

export const submitClientPrescreen = (data) => api.post('user/client_setup/prescreening/submitprescreen', data).then(res => res.data);//.catch(err => err);

export const searchUser = (keyword) => api.get(`/user/search/${keyword}`).then(res => res.data);//.catch(err => err);

export const getUserRoles = () => api.get('/role').then(res => res.data);//.catch(err => err);

///Courier ~ tracking_type
export const AddCourier = (data) => api.post('/courrier/add_courrier', data).then(res => res.data);//.catch(err => err);
export const getCourier = () => api.get('/courrier').then(res => res.data);//.catch(err => err);
export const getCourierbyID = (id) => api.get(`/courrier/${id}`).then(res => res.data);//.catch(err => err);
export const updateCourierByID = (courierdata) => api.post('/courrier/update', courierdata).then(res => res.data);//.catch(err => err);
export const searchCourier = (keyword) => api.get(`/courrier/search/${keyword}`).then(res => res.data);//.catch(err => err);

///Branch
export const AddBranch = (data) => api.post('/branch/create', data).then(res => res.data);//.catch(err => err);
export const getOfficeLocations = () => api.get('/branch').then(res => res.data);//.catch(err => err);
export const getBranchbyID = (id) => api.get(`/branch/show/${id}`).then(res => res.data);//.catch(err => err);
export const updateBranchByID = (branchdata) => api.post('/branch/update', branchdata).then(res => res.data);//.catch(err => err);
export const searchBranch = (keyword) => api.get(`/branch/search/${keyword}`).then(res => res.data);//.catch(err => err);

////locations
export const getDefaultLocations = () => api.get('/location/get/branch').then(res => res.data);//.catch(err => err);
export const searchLocation = (keyword) => api.get(`/location/search/${keyword}`).then(res => res.data);//.catch(err => err);
export const getLocationID = (id) => api.get(`/location/${id}`).then(res => res.data);//.catch(err => err);
export const updateLocationByID = (branchdata) => api.post('/location/update', branchdata).then(res => res.data);//.catch(err => err);

////application/checkappcreationstatus
export const checkAppCreationStatus = (data) => api.post(`/application/checkappcreationstatus`, data).then(res => res.data);//.catch(err => err);


////Group:
export const createGroup = (groupData) => api.post('/groups/create', groupData).then(res => res.data);
export const getAllGroups = () => api.get('/groups').then(res => res.data);

////company:
export const createCompany = (companyinfo) => api.post('/company/setup', companyinfo).then(res => res.data);
export const updateCompany = (companyinfo) => api.post('/company/update', companyinfo).then(res => res.data);
export const showCompany = () => api.get('/company').then(res => res.data);

export const companyInfo = () => initialApi.get('/company/info').then(res => res.data);

////Billing
export const createBillitem = (billitem) => api.post('/billing/setup', billitem).then(res => res.data);
export const updateBilling = (billitem) => api.post('/billing/update', billitem).then(res => res.data);
export const listBilling = () => api.get('/billing').then(res => res.data);
export const billItembyID = (id) => api.get(`/billing/show/${id}`).then(res => res.data);
export const searchBill = (keyword) => api.get(`/billing/search/${keyword}`).then(res => res.data);
export const activeBills = () => api.get('/billing/activebills').then(res => res.data);

/////Invoice

export const genInvoice = (Invoiceitem) => api.post('/invoice/generate', Invoiceitem).then(res => res.data);
export const editInvoice = (Invoiceitem) => api.post('/invoice/editInvoice', Invoiceitem).then(res => res.data);
export const updateInvoice = (Invoiceitem) => api.post('/invoice/addonInvoice', Invoiceitem).then(res => res.data);
export const payInvoice = (Invoiceitem) => api.post('/invoice/payInvoice', Invoiceitem).then(res => res.data);
export const getInvoicebyAppID = (appid) => api.get(`/invoice/byappid/${appid}`).then(res => res.data);
export const genInvoicePDF = (invoiceitems) => api.post('invoice/generatePDF', invoiceitems).then(res => res.data);