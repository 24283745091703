import React, { useState, useEffect, useRef } from "react";
import { useQuery, useMutation } from "react-query";
import { useForm } from "react-hook-form";
import { getAdminUser, removeUserAdminSession, useDebounce, sleep} from "../Utils/Common";
import * as api from '../Utils/adminApiCalls';
import "./AdminDashboard.css";
import {
    Link
  } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import AdminNav from "./Admin_nav";
import toast, { Toaster } from 'react-hot-toast';
import { Modal } from 'react-bootstrap';
import MultiSelectClient from '../Utils/MultiSelectClient'

const pageLimit = 5;
const dataLimit = 10;

const Users = () => {

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    trigger,
    reset,
    formState: { errors },
  } = useForm({mode: "onBlur"});

  const {
    register: register2,
    reset:resetEdit,
    trigger: triggerEdit,
    formState: { errors: errors2 },
    handleSubmit: handleSubmit2,
  } = useForm({
    mode: "onChange",
  });

    const [userData, setUserData] = useState({});
    const [userid, setUserid] = useState("");
    
    const[allusers, setallusers]= useState([]);

    const [details, setDetails] = useState({
      first_name:"",
      last_name:"",
      email:"",
      role:"",
      status:"",
      location:"",
      password:""
    });

    useEffect(() => {
  
      function checkUserData() {
        const item = getAdminUser();
    
        if (item) {
          console.log("user item",item);
          setUserData(item);
  
          setUserData((userData) => {
            console.log("updated details", userData);
            return userData;
          });
  
        }
      }
      checkUserData();
  
      window.addEventListener('storage', checkUserData)
    
      return () => {
        window.removeEventListener('storage', checkUserData)
      }
      
    }, []);

    const [userroles, setUserRoles] = useState([]);

    const getUsers = useQuery("users", api.getAllUsers, {
      onSuccess: (data) => {
        console.log("users data:::", data);
       
          setallusers(data);
          setallusers(allusers => {
            return allusers;
          })
      },
      onError: (error) => {
        
          console.log("Error Message ::: ",error); 
        
          if(error?.response?.status === 401){
            toast.error("Session Expired : Please Re-Login",{
              duration: 6000,
              });
           removeUserAdminSession();
            navigate("/admin_login", {replace: false});
          }
          else {
            toast.error("Something went wrong",{
              duration: 6000,
              });
          }
      }
    });
    
    const[alllocation, setAlllocation]= useState([]);
    const[defaultLoc, setDefaultLoc]= useState([]);
    const getLocations = useQuery("locations", api.getOfficeLocations, {
      onSuccess: (data) => {
        console.log("location data", data);
        setAlllocation(data);
        setAlllocation(alllocation => {
          return alllocation;
        })

        /********** **/

        const apd = [...data];
        let selectList = new Array();
    
        (apd.length > 0) && (
               apd.map( (ap) => {
                 selectList = [...selectList, { value: ap.id, label: ap.LOCATION_CODE }];
               })
        )
        console.log("selectList", selectList);
    
        setDefaultLoc(selectList);
        setDefaultLoc(defaultLoc => {
          return defaultLoc;
        })
        /*********** */
      },
      onError: (error) => {
        
          console.log("Error Message",error); 
        
          if(error?.response?.status === 401){
            toast.error("Session Expired : Please Re-Login",{
              duration: 6000,
              });
           removeUserAdminSession();
            navigate("/admin_login", {replace: false});
          }
          else {
            toast.error("Something went wrong",{
              duration: 6000,
              });
          }
      }
    })


    const handleChange = (event) => {
      event.persist();

      if(event.target.name === "location"){
        const selectedIndex = event.nativeEvent.target.selectedIndex;
        const selectedText = event.nativeEvent.target[selectedIndex].text
       // const removeOption = e => {
          const newLoc = [...frmLocation];
          const newSelect = newLoc.filter(
            item => item.label !== selectedText
          );
          console.log("newSelect ::::",newSelect, "   selectedText:::",selectedText)
          setFrmLocation(newSelect);
          setFrmLocation(frmLocation => {
            return frmLocation
          }); 
       // };
      }
  
      setDetails((details) => ({
        ...details,
        [event.target.name]: event.target.value,
      }));
      console.log(details);
    };


    const [userDetails, setUserDetails] = useState([]);

    const handleEditChange = (event) => {
      event.persist();
     
      const usrdetails = [...userDetails];
      usrdetails[0][event.target.name] = event.target.value;

      console.log("locdetails ::", usrdetails); 

      if(event.target.name === "branch_id"){
        const selectedIndex = event.nativeEvent.target.selectedIndex;
        const selectedText = event.nativeEvent.target[selectedIndex].text
       // const removeOption = e => {
          const newLoc = [...frmLocation];
          const newSelect = newLoc.filter(
            item => item.label !== selectedText
          );
          console.log("newSelect ::::",newSelect, "   selectedText:::",selectedText)
          setLocationList(newSelect);
          setLocationList(locationList => {
            return locationList
          }); 
       // };
      }
      
      setUserDetails(usrdetails);
      setUserDetails((userDetails) => {
        return userDetails
      });

      console.log(userDetails); 
    };
  
    
    const createdDate = (datestr, l=0)=> {

      if(l === 0){
      const start = moment(datestr).format("dddd, MMMM Do YYYY, h:mm:ss a");
      
      return start;
    }
    else if(l === 1){
      const start = moment(datestr).format("llll");

      return start;
    }
     
    };

    ///////////Modal view location //////
const [viewuseropen, setviewuseropen] = useState(false);

const handleViewUserShow = (e) => {
  setUserid(e.target.getAttribute("data-userid"));
  setviewuseropen(true);

  const userid = e.target.getAttribute("data-userid");
  useridRef.current = userid;

   if(userid) {
    userRef.current = true;
    getuserDetails.refetch();
   }
   else return null;
};

const handleViewUserClose= (e) => {
  setviewuseropen(false);
  userRef.current = false;
};

////////////////

 ///////////Modal Edit location //////
 const [edituseropen, setedituseropen] = useState(false);

 const [locdefault, setLocDefault] = useState([]);
 const [locselect, setLocselect] = useState([]);
 const [locationList, setLocationList] = useState([]);
 
 const handleEditUserShow = (e) => {

   setUserid(e.target.getAttribute("data-userid"));
   const userid = e.target.getAttribute("data-userid");
   useridRef.current = userid;
   setedituseropen(true);

   roleRef.current = true;
   getRoles.refetch();

   if(userid) {
    userRef.current = true;
   getuserDetails.refetch();
   }
   else return null;
 };
 
 const handleEditUserClose = (e) => {
   setedituseropen(false);
   userRef.current = false;
   roleRef.current = false;
 };
 
const userRef = useRef(false);
const useridRef = useRef();

const getuserDetails = useQuery(["userDetails", useridRef.current], () => api.getUserByID(useridRef.current), {
enabled:userRef.current,
refetchOnWindowFocus:false,
refetchOnMount:false,
  onSuccess: (data) => {
      console.log("user details #:::=> ",data);

      setUserDetails(data?.user_details);
      setUserDetails((userDetails) => {
        //console.log("user details : ",userDetails);
       return userDetails;
    });

    if(!!data?.locations[0]?.location_id){

        setLocDefault(JSON.parse(data?.locations[0]?.location_id));
        setLocDefault(locdefault => {
          console.log("locdefault ^^^", locdefault)
          return locdefault;
        }); 

        setLocselect(JSON.parse(data?.locations[0]?.location_id));
        setLocselect(locselect => {
          return locselect;
        });
  }
  else{
        setLocDefault([]);
        setLocDefault(locdefault => {
          console.log("locdefault ^^^", locdefault)
          return locdefault;
        }); 

        setLocselect([]);
        setLocselect(locselect => {
          return locselect;
        });
  }

   /*  let selectList = new Array();
    //let locationList = new Array();

    if(data?.locations?.length > 0) {

      if(!!data?.locations[0]?.location_id){
             selectList = [...JSON.parse(data?.locations[0]?.location_id)];
      }
             //locationList.push(ap.location_id);   
    }
    console.log("selectList ^^^", selectList);
    //console.log("locationList", locationList);

    setLocselect(selectList);
    setLocselect(locselect => {
      return locselect;
    }); */

    /* setLocationList(locationList);
    setLocationList(locationList => {return locationList;}) */

  },
  onError: (error) => {
    
    console.log("Error Message",error); 
  
    if(error.response.status === 401){
      toast.error("Session Expired : Please Re-Login",{
        duration: 6000,
        });
     removeUserAdminSession();
      navigate("/admin_login", {replace: false});
    }
    else {
      toast.error("Something went wrong",{
        duration: 6000,
        });
    }
  
  } 
});

const handleMultiLocEditChange = (selectedOption) => {
  
  /* console.log(`Option selected edit:`, selectedOption);
  
   let frmGrp = [...selectedOption];
   
  console.log(`frmGrp:`, frmGrp);
  setLocationList(frmGrp);
  setLocationList(locationList => {
    return locationList
  });  */

     console.log(`Option selected:`, selectedOption);
     
      let frmGrp = [...selectedOption];
      /* selectedOption.map(sele => {
       frmGrp.push({value:sele.value, label:sele.label});
      }) */
     
     //frmGrp = [...new Set(frmGrp)];

     console.log(`frmGrp:`, frmGrp);

     if(!!userDetails[0]?.branch_id){
        const newLoc = userDetails[0]?.branch_id;
        const newSelect = frmGrp.filter(
          item => item.value !== parseInt(newLoc)
        );
        console.log("newSelect ::::",newSelect, "   location/Branch value:::",newLoc, "  typeof newLoc", typeof newLoc)
        setLocationList(newSelect);
        setLocationList(locationList => {
          return locationList
        }); 
     }
     else{
        setLocationList(frmGrp);
        setLocationList(locationList => {
        return locationList
      }); 
     }

};


const handleEditUser = async (e) => {
    console.log(e.target.getAttribute("data-userid"));

    const userid = e.target.getAttribute("data-userid");
    const userupdate = [...userDetails];
    userupdate[0].role = userupdate[0].roleID;
    userupdate[0].userid = userData?.id;
    userupdate[0].countries = JSON.stringify(locationList);
    console.log("userupdate before user edit mutation", userupdate);
    const validatuserInput = await triggerEdit();
  
    if(validatuserInput && userid !== "" && userid !== undefined && userData?.id !== undefined){
          updateuserDetails.mutate(userupdate[0]);
    }
    else {
      toast.error("Invalid User ID : Operation Denied",{
        duration: 6000,
        });
    }
};

///updateLocation

const updateuserDetails = useMutation("userDetails", api.updateUser, {

  onSuccess: (data) => {
      console.log("user details: ",data);

    getUsers.refetch();
    toast.success("User updated Successfully",{
      duration:6000,
    });

    sleep(2000).then(() => {
      userRef.current = false;
      setedituseropen(false);
      setUserDetails([]);
    });

  },
  onError: (error) => {
    
    console.log("Error Message",error); 
  
    if(error.response.status === 401){
      toast.error("Session Expired : Please Re-Login",{
        duration: 6000,
        });
     removeUserAdminSession();
      navigate("/admin_login", {replace: false});
    }
    else {
      toast.error("Something went wrong",{
        duration: 6000,
        });
    }
  
  } 
})

const roleRef = useRef(false);
const getRoles = useQuery("roles", () => api.getUserRoles(), {
  enabled: roleRef.current,
  onSuccess: (data) => {
    console.log("users roles:::", data);
    setUserRoles(data);
    setUserRoles(userroles => {
      return userroles;
    })
  },
  onError: (error) => {
    
      console.log("Error Message ::: ",error); 
    
      if(error?.response?.status === 401){
        toast.error("Session Expired : Please Re-Login",{
          duration: 6000,
          });
       removeUserAdminSession();
        navigate("/admin_login", {replace: false});
      }
      else {
        toast.error("Something went wrong",{
          duration: 6000,
          });
      }
  }
})
 ////////////////


  ///////////Modal Add User //////
const [adduseropen, setAddUserOpen] = useState(false);

const handleAddUserShow = (e) => {
  
  setAddUserOpen(true);
  roleRef.current = true;
  getRoles.refetch();
};

const handleAddUserClose= (e) => {
  setAddUserOpen(false);
  roleRef.current = false;
};

const handleAddUser = async (e) => {
  const dt = {...details};
  dt.id = userData?.id;
  dt.password_confirmation = dt.password;
  dt.branch_id = dt.location;
  dt.countries = JSON.stringify(frmLocation); //[...frmLocation]
  const validatuserInput = await trigger();
  if(validatuserInput && dt.id !== "" && dt.id !== undefined){
    userMutate.mutate(dt);
  }
  else {
    toast.error("Invalid User. Operation denied",{
      duration:6000,
    })
  }
};

const userMutate = useMutation("adduser", api.AddUser, {
  onSuccess: (data) => {
    console.log("user data", data);
    getUsers.refetch();
    toast.success("User Added Successfully",{
      duration:6000,
    });

    reset();

    setDetails({
      first_name:"",
      last_name:"",
      email:"",
      role:"",
      status:"",
      location:"",
      password:""
    });

    setFrmLocation([]);
    setAddUserOpen(false);
  
  },
  onError: (error) => {
    
      console.log("Error Message",error); 
    
      if(error?.response?.status === 401){
        toast.error("Session Expired : Please Re-Login",{
          duration: 6000,
          });
       removeUserAdminSession();
        navigate("/admin_login", {replace: false});
      }
      else {
        toast.error("Something went wrong",{
          duration: 6000,
          });
      }
  }
})
////////////////

/////////////Pagination ////////////

  //const [pages] = useState(Math.ceil(datamsg?.length / dataLimit) || 1);
  const pages = useRef(Math.ceil(allusers?.length / dataLimit) || 1);

  //console.log("dataLimit::", dataLimit," datamsg length::", datamsg?.length, " pages::: ", Math.ceil(allusers?.length / dataLimit));

  const [currentPage, setCurrentPage] = useState(1);

  const goToNextPage = () => {
      if(currentPage <= pages.current) {
      setCurrentPage((page) => page + 1);
      }
  }

  const goToPreviousPage = () => {
      if(currentPage > 1 ){
      setCurrentPage((page) => page - 1);
      }
  }

  const changePage = (event) => {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage(pageNumber);
  }

  const getPaginatedData = () => {
    const startIndex = currentPage * dataLimit - dataLimit;
    const endIndex = startIndex + dataLimit;
    //console.log("getPaginationData", allusers?.slice(startIndex, endIndex))
    return allusers?.slice(startIndex, endIndex);
  };

  const getPaginationGroup = () => { 
    let start = Math.floor((currentPage - 1) / pageLimit) * pageLimit;
    
    let page = Math.ceil(allusers?.length / dataLimit) || 1;
    //console.log("local page ", Math.ceil(allusers?.length / dataLimit));
    //console.log("Array Return", new Array(pages).fill().map((_, idx) => start + idx + 1), "  pages : ", pages);

    pages.current = page;
    
     return new Array(page).fill().map((_, idx) => start + idx + 1);
  }

  ////////////////////////////////////

  /////////////////Search function ///////////////
const [searchquery, setSearchquery] = useState("");

const searchApplication = (q) => {
 //console.log("query: ", q.target.value);

 const query = q.target.value;

 setSearchquery(query);

 setSearchquery(searchquery => {
   return searchquery;
 });
 
 if(query.length === 0){
  
  console.log("Refetching Application list 2", query, " query length next: ", query.length);
     
  getUsers.refetch();
 }
}

const debouncedFilter = useDebounce(searchquery, 500);

console.log("debouncedFilter", debouncedFilter); 

const { data:searchData, isLoading:searchLoading } = useQuery(
 ['applications', debouncedFilter], 
 () => api.searchUser(debouncedFilter),
 { enabled: Boolean(debouncedFilter),
 onSuccess: (data) => {
     console.log("data", data);
     const apd = [...data];
     setallusers(apd);
     setallusers((allusers) => {
       return allusers;
     })
 },
 onError: (error) => {
    
  console.log("Error Message",error); 

  if(error.response.status === 401){
    toast.error("Session Expired : Please Re-Login",{
      duration: 6000,
      });
   removeUserAdminSession();
    navigate("/admin_login", {replace: false});
  }
  else {
    toast.error("Something went wrong",{
      duration: 6000,
      });
  }

} 
})

 /////////////////////////////////


 ///////////// Add Location ////////////////////

 const [frmLocation, setFrmLocation] = useState([]);
 //const[defaultLoc, setDefaultLoc]= useState([]);

 /*
 const getDefaultLoc = useQuery("locations_default", api.getDefaultLocations, {
  onSuccess: (data) => {
    console.log("location data:::=>", data);
    const apd = [...data];
    let selectList = new Array();

    (apd.length > 0) && (
           apd.map( (ap) => {
             selectList = [...selectList, { value: ap.id, label: ap.COUNTRY }];
           })
    )
    console.log("selectList", selectList);

    setDefaultLoc(selectList);
    setDefaultLoc(defaultLoc => {
      return defaultLoc;
    })
  },
  onError: (error) => {
    
      console.log("Error Message",error); 
    
      if(error?.response?.status === 401){
        toast.error("Session Expired : Please Re-Login",{
          duration: 6000,
          });
       removeUserAdminSession();
        navigate("/admin_login", {replace: false});
      }
      else {
        toast.error("Something went wrong",{
          duration: 6000,
          });
      }
  }
})
*/

 const handleMultiClientChange = (selectedOption) => {
  
     console.log(`Option selected:`, selectedOption);
     
      let frmGrp = [...selectedOption];
      /* selectedOption.map(sele => {
       frmGrp.push({value:sele.value, label:sele.label});
      }) */
     
     //frmGrp = [...new Set(frmGrp)];

     console.log(`frmGrp:`, frmGrp);

     if(!!details?.location){
        const newLoc = details?.location
        const newSelect = frmGrp.filter(
          item => item.value !== parseInt(newLoc)
        );
        console.log("newSelect ::::",newSelect, "   location/Branch value:::",newLoc, "  typeof newLoc", typeof newLoc)
        setFrmLocation(newSelect);
        setFrmLocation(frmLocation => {
          return frmLocation
        }); 
     }
     else{
        setFrmLocation(frmGrp);
        setFrmLocation(frmLocation => {
        return frmLocation
      }); 
     }
    
   
 };
 
 /////////////// End Location ////////////////////


    return (
        <>
        <Toaster />
      
<div className="normalheader" id="tpanel">
        <div className="hpanel">
          <div className="panel-body">
            <a className="small-header-action" id="toglepanel" href={void(0)}>
              <div className="clip-header">
                <i className="fa fa-arrow-up"></i>
              </div>
            </a>

            <div id="hbreadcrumb" className="pull-right m-t-lg">
              <ol className="hbreadcrumb breadcrumb">
                <li>
                  <a href={void(0)}>i-visas</a>
                </li>
                <li>
                  <span>Admin</span>
                </li>
                <li className="active">
                  <span>Users </span>
                </li>
              </ol>
            </div>
            <h2 className="font-light m-b-xs">User Management</h2>
            <small>
            <strong>Create</strong> System Users
            </small>
          </div>
        </div>
      </div>

    <div className="content">
        <div className="row">
            <div className="col-lg-12 text-center welcome-message">
                
            <div className="hpanel email-compose">
              
               <>
            <div className="panel-heading hbuilt">
                    <div className="text-center p-xs font-normal">
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={searchApplication}
                          value={searchquery || ""}
                          className="form-control input-sm"
                          placeholder="Search by First Name, Last Name, and Email..."
                        />{" "}
                        <span className="input-group-btn">
                          {" "}
                          <button
                            type="button"
                            disabled
                            className="btn btn-sm btn-default">
                            <i className="fa fa-search"></i> 
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="panel-body">

                  {searchLoading ? (
                       <>
                      <div className="row text-center">
                      <div className="col-md-7 m-b-md" style={{textAlign: "left", display: "flex", flexDirection: "row", justifyItems: "flex-start", alignItems: "center", justifyContent: "normal"}}>

                      <button className="btn btn-info btn-sm" >
                            <i className="fa fa-user-plus"></i> Add User
                          </button>
                      </div>
                      <div className="col-md-5 mailbox-pagination m-b-md">
                            <span style={{ fontSize: "1.1rem" }}>
                            Showing 0 of 0 pages   &nbsp; &nbsp;
                            </span>
                            <div className="btn-group">
                              <button className="btn btn-default btn-sm previous disabled"
                             
                              disabled
                              >
                                <i className="fa fa-arrow-left"></i>
                              </button>
                              {getPaginationGroup().map((item, index) => (
                              <button 
                              key={index}
                              className="btn btn-default btn-sm disabled"
                              >
                              {item}
                              </button>
                              ))}
                              <button className="btn btn-default btn-sm next disabled"
                              disabled
                              >
                                <i className="fa fa-arrow-right"></i>
                              </button>
                            </div>
                          </div>
                         
                      </div>
                        <div className="table-responsive" style={{overflowX: "inherit"}}>
                        <table className="table table-hover table-mailbox apptable">
                        <thead style={{borderBottom: "solid 2px #64d246"}}>
                        <tr>
                        <th className="text-center" style={{width:"210px"}}>First Name</th>
                            <th className="text-center" >Last Name</th>
                            <th className="text-center" >Email</th>
                            <th className="text-center" >Role</th>
                            <th className="text-center" >Creation Date</th>
                            <th className="text-center">Action</th>
                           
                          </tr>
                        </thead>
                            <tbody>
                            <tr className="active"><td colSpan="7" style={{textAlign:"center"}}>Searching...</td></tr>
                        </tbody>
                      </table>
                        </div>
                        </>
                    ):(
                      <>
                    <div className="row">
                      <div className="col-md-7 m-b-md" style={{textAlign: "left", display: "flex", flexDirection: "row", justifyItems: "flex-start", alignItems: "center", justifyContent: "normal"}}>

                      <button className="btn btn-info btn-sm" onClick={handleAddUserShow} >
                            <i className="fa fa-user-plus"></i> Add User
                          </button>
                      </div>

                      <div className="col-md-5 mailbox-pagination m-b-md">
                        <span style={{ fontSize: "1.1rem" }}>
                        {!!allusers && (Object.keys(allusers).length > 0) ? `Showing ${currentPage} of ${pages.current} pages` : "Showing 0 of 0 pages"}   &nbsp; &nbsp;
                        </span>
                        <div className="btn-group">
                          <button className={`btn btn-default btn-sm previous ${currentPage === 1 ? 'disabled' : ''}`}
                          onClick={goToPreviousPage}
                          disabled={!!allusers && (currentPage === 1) ? true : false} 
                          >
                            <i className="fa fa-arrow-left"></i>
                          </button>
                          {getPaginationGroup().map((item, index) => (
                          <button 
                          key={index}
                          onClick={changePage}
                          className={`btn btn-default btn-sm  ${currentPage === item ? 'active' : null}`}
                          >
                           {item}
                          </button>
                          ))}
                          <button className={`btn btn-default btn-sm next ${currentPage === pages.current ? 'disabled' : ''}`}
                          onClick={goToNextPage} 
                          disabled={!!allusers && (currentPage === pages.current) ? true : false} 
                          >
                            <i className="fa fa-arrow-right"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="table-responsive" style={{overflowX: "inherit"}}>
                      <table className="table table-hover table-mailbox apptable">
                      <thead style={{borderBottom: "solid 2px #64d246"}}>
                          <tr>
                            <th className="text-center" style={{width:"210px"}}>First Name</th>
                            <th className="text-center" >Last Name</th>
                            <th className="text-center" >Email</th>
                            <th className="text-center" >Role</th>
                            <th className="text-center" >Creation Date</th>
                            <th className="text-center">Action</th>
                           
                          </tr>
                        </thead>
                        <tbody>
                        { getUsers.isLoading ? (

                        <tr className="unread active"><td colSpan="7" style={{textAlign:"center"}}>
                          <span className="btn">
                          <i className="fa fa-paper-plane" style={{color: "#5ac557"}}></i>
                          &nbsp; Loading...
                          <span className="lds-facebook"><span></span><span></span><span></span></span>
                          </span>
                          &nbsp; &nbsp; &nbsp; &nbsp;
                          </td></tr>
                        ) : (

                        !!allusers && (Object.keys(allusers).length > 0) ? 
                          getPaginatedData().map((item,i) =>{
                            return (
                              <tr className="unread active" key={i}>
                              <td className="text-center" style={{width: "100px"}}>
                             {item.first_name}
                            </td>
                            <td className="text-center">
                             {item.last_name}
                            </td>
                            <td className="text-center">
                             {item.email}
                            </td>
                            <td className="text-center">
                             {item.name}
                            </td>
                            <td className="text-center mail-date">
                            {createdDate(item.created_at)}
                            </td>
                            <td className="text-center mail-date">
                            <div className="btn-group">
                                    <button data-toggle="dropdown" className="btn btn-default dropdown-toggle">Action <span className="caret"></span></button>
                                    <ul className="dropdown-menu">
                                        <li><Link to="# " data-userid={item.id} onClick={handleViewUserShow} >View User</Link></li>
                                        <li><Link to="# " data-userid={item.id} onClick={handleEditUserShow} >Edit User</Link></li>
                                       
                                    </ul>
                          </div>
                            </td>
                          </tr>
                            )
                          }) : (
                           <tr className="unread active"><td colSpan="7" style={{textAlign:"center"}}>No User found </td></tr>
                         ))}
                           
                        </tbody>
                      </table>
                    </div>

                    </>
                    )}
                 </div>
                 <div className="panel-footer text-left">
                  {!!allusers && (Object.keys(allusers).length > 0) ? allusers.length : 0}  Users
                 </div>
               </>
               
            </div>
        </div>

    </div>
    </div>


   {/*  <footer className="footer" style={{ maxHeight: "40px" }}>
          <div className="mt-4 mt-md-5 text-center" style={{ marginTop: "0" }}>
            <p className="footer-p">
              © <script>document.write(new Date().getFullYear())</script>{" "}
              i-visas.com
            </p>
          </div>
        </footer> */}


        <Modal show={viewuseropen} onHide={handleViewUserClose} animation={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        >
           <div className="color-line"></div>
        <Modal.Header className="modal-header text-center" style={{padding: "10px 10px"}}>
          <Modal.Title>View User</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{padding: "5px"}}>
         
        <div className="panel-body no-padding">
               <div className="row">

               <div className="col-lg-12">

               { getuserDetails.isError &&
                 (
                  <>
                  <div className="hpanel horange">
                    <div className="panel-body">
                        <div className="row">
                            <div className="col-sm-12">
                                <p className="text-center">Encountered an Issue Loading User details</p>
                            </div>
                        </div>
                      </div>
                  </div>
                  </>
                 )}

                 { getuserDetails.isLoading ? 
                 (
                  <>
                  <div className="hpanel horange">
                    <div className="panel-body">
                        <div className="row">
                            <div className="col-sm-12">
                                <p className="text-center">Loading...</p>
                            </div>
                        </div>
                      </div>
                  </div>
                  </>
                 ): userDetails && userDetails.length > 0 && (
                 <>  
                <div className="hpanel horange">
                    <div className="panel-body">
                       {/*  <span className="label label-info pull-right">NEW</span> */}
                        <div className="row">
                            <div className="col-sm-12">
                              
                                <div className="row">
                                    <div className="col-sm-4 text-center">
                                        <div className="project-label">FIRST NAME</div>
                                        <small style={{fontWeight: "bold"}}>{userDetails[0]?.first_name || "Not Set"}</small>
                                    </div>
                                    <div className="col-sm-4 text-center">
                                        <div className="project-label">LAST NAME</div>
                                        <small style={{fontWeight: "bold"}}>{userDetails[0]?.last_name || "Not Set"}</small>
                                    </div>
                                    <div className="col-sm-4 text-center">
                                        <div className="project-label">EMAIL</div>
                                        <small style={{fontWeight: "bold"}}>{userDetails[0]?.email || "Not Set"}</small>
                                    </div>
                                    
                                </div>

                                <hr className="slash-3" style={{marginTop:"18px"}}/>

                                <div className="row">
                                    <div className="col-sm-4 text-center">
                                        <div className="project-label">ROLE</div>
                                        <small style={{fontWeight: "bold"}}>{userDetails[0]?.name || "Not Set"}</small>
                                    </div>
                                    <div className="col-sm-4 text-center">
                                        <div className="project-label">Branch</div>
                                        <small style={{fontWeight: "bold"}}>{userDetails[0]?.LOCATION_CODE || "Not Set"}</small>
                                    </div>
                                    <div className="col-sm-4 text-center">
                                        <div className="project-label">Status</div>
                                        <small style={{fontWeight: "bold"}}>{userDetails[0]?.status || "Not Set"}</small>
                                    </div>
                                   
                                </div>

                                <hr className="slash-3" style={{marginTop:"18px"}}/>

                                <div className="row">
                                    <div className="col-sm-12 text-center">
                                    <div className="project-label">Assigned Branches</div>
                                    {locdefault?.length > 0 ? (
                                      locdefault.map(loc => (
                                        <span key={loc.value} className="label label-default"style={{margin:"5px"}}>{loc.label}</span>
                                      ))
                                      ) : ( <small style={{fontWeight: "bold"}}>Not Set</small>)
                                    }
                                    </div>
                                </div>

                                <hr className="slash-3" style={{marginTop:"18px"}}/>

                            </div>
                          
                        </div>
                    </div>
                    <div className="panel-footer">
                        last updated on {createdDate(userDetails[0]?.created_at)}
                    </div>
                </div>
                </>
                )}
            </div>
                
               </div>
        </div>

        </Modal.Body>
        <Modal.Footer>
         
           <div className="text-right">
           
              <button
                type="button"
                className="btn btn-default"
                onClick={handleViewUserClose}>
                <i className="fa fa-trash"></i>  Close
              </button>
          </div>
        </Modal.Footer>
      </Modal>


      <Modal show={edituseropen} onHide={handleEditUserClose} animation={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        >
           <div className="color-line"></div>
        <Modal.Header className="modal-header text-center" style={{padding: "10px 10px"}}>
          <Modal.Title>Edit User</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{padding: "5px"}}>
         
        
        <div className="panel-body no-padding">
        <div className="row">
                  <div className="col-lg-12">

                  { getuserDetails.isError &&
                 (
                  <>
                  <div className="hpanel horange">
                    <div className="panel-body">
                        <div className="row">
                            <div className="col-sm-12">
                                <p className="text-center">Encountered an Issue Loading User details</p>
                            </div>
                        </div>
                      </div>
                  </div>
                  </>
                 )}

                 { getuserDetails.isLoading ? 
                 (
                  <>
                  <div className="hpanel horange">
                    <div className="panel-body">
                        <div className="row">
                            <div className="col-sm-12">
                                <p className="text-center">Loading...</p>
                            </div>
                        </div>
                      </div>
                  </div>
                  </>
                 ): userDetails && userDetails.length > 0 && (
                      <>
                        <div className="row col-bb">
                          
                        <div className="form-group col-lg-4">
                              <label className="label-clr">
                                First Name
                              </label>
                              <input
                                  type="text"
                                  {...register2("first_name", { 
                                    required: { value: true, message: "This field is required" }, 
                                          onChange: (e) => {handleEditChange(e)} })}
                                  value={userDetails[0]?.first_name || ""}
                                  className="form-control"
                                  name="first_name"
                                />
                                {errors2.first_name && (
                                  <p className="errorValidate">{errors2.first_name.message}</p>
                                  )}
                            </div>

                            <div className="form-group col-lg-4">
                                <label className="label-clr">
                                  Last Name
                                </label>
                                <input
                                  type="text"
                                  {...register2("last_name", { 
                                    required: { value: true, message: "This field is required" }, 
                                          onChange: (e) => {handleEditChange(e)} })}
                                  value={userDetails[0]?.last_name || ""}
                                  className="form-control"
                                  name="last_name"
                                />
                                {errors2.last_name && (
                                  <p className="errorValidate">{errors2.last_name.message}</p>
                                  )}
                              </div>

                            <div className="form-group col-lg-4">
                                <label className="label-clr">
                                  Email
                                </label>
                                <input
                                  type="text"
                                  {...register2("email", { 
                                    required: { value: true, message: "This field is required" }, 
                                          onChange: (e) => {handleEditChange(e)} })}
                                  value={userDetails[0]?.email || ""}
                                  className="form-control"
                                  name="email"
                                />
                                {errors2.email && (
                                  <p className="errorValidate">{errors2.email.message}</p>
                                  )}
                              </div>

                        </div>

                        <div className="row col-bb">

                        <div className="form-group col-lg-4">
                              <label className="label-clr">
                              Assign Role
                              </label>
                              <select
                                name="roleID"
                               {...register2("roleID", { 
                                          required: { value: userDetails[0]?.roleID === "" || userDetails[0]?.roleID === null ? true : false, message: "This field is required" }, 
                                          onChange: (e) => {handleEditChange(e)} })}
                                value={userDetails[0]?.roleID || ""} // Prop: The email input data
                               
                                className="form-select m-b-4"
                                style={{ width: "100%" }}>
                                <option value=''>select user</option>
                                {userroles.map(role => (
                                  <option key={role.id} value={role.id}>{role.name}</option>
                                ))}
                               {/*  <option value="admin">Admin</option>
                                <option value="super_admin">Super Admin</option> */}
                               
                              </select>
                              {errors2.roleID && (
                                  <p className="errorValidate">{errors2.roleID.message}</p>
                                  )}
                            </div>

                            <div className="form-group col-lg-4">
                            <label className="label-clr">
                              Branch
                              </label>
                              <select
                                name="branch_id"
                               {...register2("branch_id", { 
                                          required: { value: true, message: "This field is required" }, 
                                          onChange: (e) => {handleEditChange(e)} })}
                                value={userDetails[0]?.branch_id || ""} // Prop: The email input data
                               
                                className="form-select m-b-4"
                                style={{ width: "100%" }}>
                                <option value=''>select Branch</option>
                                {alllocation.map(loc => (
                                  <option key={loc.id} value={loc.id}>{loc.LOCATION_CODE}</option>
                                ))}
                              </select>
                              {errors2.branch_id && (
                                  <p className="errorValidate">{errors2.branch_id.message}</p>
                                  )}
                            </div>

                              <div className="col-lg-4 form-group">
                                  <h5
                                    className="list-group-item-heading label-h5" /* style={{"marginTop": "3%%"}} */
                                  > User Status
                                  </h5>

                                  <div className="radio radio-success radio-inline">
                                    <input
                                      type="radio"
                                      id="statusactive"
                                      value="ACTIVE"
                                      name="status"
                                      checked={userDetails[0]?.status === "ACTIVE"}
                                      {...register2('status', { required: { value: true, message: "This field is required" }, onChange: (e) => {handleEditChange(e)} })}
                                    />
                                    <label htmlFor="statusactive">Active</label>
                                  </div>
                                  <div className="radio radio-success radio-inline">
                                    <input
                                      type="radio"
                                      id="statusinactive"
                                      value="INACTIVE"
                                      name="status"
                                      checked={userDetails[0]?.status === "INACTIVE"}
                                      {...register2('status', { required: { value: true, message: "This field is required" }, onChange: (e) => {handleEditChange(e)} })}
                                    />
                                    <label htmlFor="statusinactive">In-active</label>
                                  </div>
                                  {errors2.status && (
                                  <p className="errorValidate">This field is required</p>
                                  )}
                                </div>
                              </div>

                              <div className="row col-bb">

                        <div className="form-group col-lg-8">

                        <label className="label-clr">Assigned Branches</label>
                                  {!!defaultLoc && (Object.keys(defaultLoc).length > 0) ?
                                  (
                                    <MultiSelectClient options={defaultLoc}   value={locationList}  defaultValue={locselect} handleChange={handleMultiLocEditChange} /> 
                                  ):(
                                    <input
                                    type="text"
                                    name="Locations_id"
                                    disabled
                                    value=""
                                    className="form-control"
                                  />
                                  )}

                        </div>
                      </div>

                        </>
                )} 
                  </div>
              </div>
        </div>


        </Modal.Body>
        <Modal.Footer>
         
           <div className="text-right">
           {getuserDetails.isLoading ? (
             <>
                <span className="btn">
                  <i className="fa fa-paper-plane" style={{color: "#5ac557"}}></i>
                  &nbsp; Saving...
                  <span className="lds-facebook"><span></span><span></span><span></span></span>
                </span>
                &nbsp; &nbsp; &nbsp; &nbsp;
                </>
                    ) : (
                <button type="button"
                  className="btn btn-success btn-sm"
                  
                  data-userid={userid}
                  onClick={handleEditUser}
                  >
                   <i className="fa fa-location-arrow"></i>  Apply Changes
                </button>
            )}
              <button
                type="button"
                className="btn btn-default"
                onClick={handleEditUserClose}>
                <i className="fa fa-trash"></i>  Close
              </button>
          </div>
        </Modal.Footer>
      </Modal>


      <Modal show={adduseropen} onHide={handleAddUserClose} animation={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        >
           <div className="color-line"></div>
        <Modal.Header className="modal-header text-center" style={{padding: "10px 10px"}}>
          <Modal.Title>Add User</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{padding: "5px"}}>
         
       
        <div className="panel-body no-padding">
        <div className="row">
                  <div className="col-lg-12">

                        <div className="row col-bb">
                          
                        <div className="form-group col-lg-4">
                              <label className="label-clr">
                                First Name
                              </label>
                              <input
                                  type="text"
                                  {...register("first_name", { 
                                    required: { value: true, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                  value={details.first_name || ""}
                                  className="form-control"
                                  name="first_name"
                                />
                                {errors.first_name && (
                                  <p className="errorValidate">{errors.first_name.message}</p>
                                  )}
                            </div>

                            <div className="form-group col-lg-4">
                                <label className="label-clr">
                                  Last Name
                                </label>
                                <input
                                  type="text"
                                  {...register("last_name", { 
                                    required: { value: true, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                  value={details.last_name || ""}
                                  className="form-control"
                                  name="last_name"
                                />
                                {errors.last_name && (
                                  <p className="errorValidate">{errors.last_name.message}</p>
                                  )}
                              </div>

                            <div className="form-group col-lg-4">
                                <label className="label-clr">
                                  Email
                                </label>
                                <input
                                  type="text"
                                  {...register("email", { 
                                    required: { value: true, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                  value={details.email || ""}
                                  className="form-control"
                                  name="email"
                                />
                                {errors.email && (
                                  <p className="errorValidate">{errors.email.message}</p>
                                  )}
                              </div>

                        </div>

                        <div className="row col-bb">

                        <div className="form-group col-lg-4">
                              <label className="label-clr">
                              Role
                              </label>
                              <select
                                name="role"
                               {...register("role", { 
                                          required: { value: true, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                value={details.role || ""} // Prop: The email input data
                               
                                className="form-select m-b-4"
                                style={{ width: "100%" }}>
                                <option value=''>select role</option>
                                {userroles.map(role => (
                                  <option key={role.id} value={role.name}>{role.name}</option>
                                ))}
                                {/* <option value="admin">Admin</option>
                                <option value="super_admin">Super Admin</option> */}
                              </select>
                              {errors.role && (
                                  <p className="errorValidate">{errors.role.message}</p>
                                  )}
                            </div>

                        <div className="form-group col-lg-4">
                            <label className="label-clr">
                              Branch
                              </label>
                              <select
                                name="location"
                               {...register("location", { 
                                          required: { value: true, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                value={details.location || ""} // Prop: The email input data
                               
                                className="form-select m-b-4"
                                style={{ width: "100%" }}>
                                <option value=''>select Branch</option>
                                {alllocation.map(loc => (
                                  <option key={loc.id} value={loc.id}>{loc.LOCATION_CODE}</option>
                                ))}
                              </select>
                              {errors.location && (
                                  <p className="errorValidate">{errors.location.message}</p>
                                  )}
                            </div>
                          
                            <div className="form-group col-lg-4">
                            <label className="label-clr">
                                  Password
                                </label>
                                <input
                                  type="text"
                                  {...register("password", { 
                                    required: { value: true, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                  value={details.password || ""}
                                  className="form-control"
                                  name="password"
                                />
                                {errors.password && (
                                  <p className="errorValidate">{errors.password.message}</p>
                                  )}
                            </div>

                        </div>

                        <div className="row col-bb">

                        <div className="form-group col-lg-8">

                        <label className="label-clr">Assign Additional Branches</label>
                                  {!!defaultLoc && (Object.keys(defaultLoc).length > 0) ?
                                  (
                                    <MultiSelectClient options={defaultLoc}  value={frmLocation} handleChange={handleMultiClientChange} /> 
                                  ):(
                                    <input
                                    type="text"
                                    name="CLIENT_ID"
                                    disabled
                                    value=""
                                    className="form-control"
                                  />
                                  )}

                        </div>

                        <div className="col-lg-4 form-group text-center">
                                  <h5
                                    className="list-group-item-heading label-h5" /* style={{"marginTop": "3%%"}} */
                                  > User Status
                                  </h5>

                                  <div className="radio radio-success radio-inline">
                                    <input
                                      type="radio"
                                      id="statusactive"
                                      value="ACTIVE"
                                      name="status"
                                      checked={details.status === "ACTIVE"}
                                      {...register('status', { required: { value: true, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                    />
                                    <label htmlFor="statusactive">Active </label>
                                  </div>
                                  <div className="radio radio-success radio-inline">
                                    <input
                                      type="radio"
                                      id="statusinactive"
                                      value="INACTIVE"
                                      name="status"
                                      checked={details.status === "INACTIVE"}
                                      {...register('status', { required: { value: true, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                    />
                                    <label htmlFor="statusinactive">In-active</label>
                                  </div>
                                  {errors.status && (
                                  <p className="errorValidate">This field is required</p>
                                  )}
                                </div>

                               
                        </div>

                  </div>
              </div>
        </div>


        </Modal.Body>
        <Modal.Footer>
         
           <div className="text-right">
           {userMutate.isLoading ? (
             <>
                <span className="btn">
                  <i className="fa fa-paper-plane" style={{color: "#5ac557"}}></i>
                  &nbsp; Saving...
                  <span className="lds-facebook"><span></span><span></span><span></span></span>
                </span>
                &nbsp; &nbsp; &nbsp; &nbsp;
                </>
                    ) : (
                <button type="button"
                  className="btn btn-success btn-sm"
                  
                  onClick={handleAddUser}
                  >
                   <i className="fa fa-user-plus"></i>  Add User
                </button>
            )}
              <button
                type="button"
                className="btn btn-default"
                onClick={handleAddUserClose}>
                <i className="fa fa-trash"></i>  Close
              </button>
          </div>
        </Modal.Footer>
      </Modal>
        
        </>
    )

}

export default Users;