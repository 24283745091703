import React, { useState, useEffect, Fragment, useRef } from "react";
import { useQuery, useMutation } from "react-query";
import toast, { Toaster } from 'react-hot-toast';
import { getUser, removeUserSession, useDebounce } from "./Utils/Common";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as api from './Utils/apiCall';
import { useForm } from "react-hook-form";
import moment from "moment";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Modal } from 'react-bootstrap';

import "./Messages.css";

const ClientTracking = () => {

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  
  const [userData, setUserData] = useState({});
  
  useEffect(() => {
    document
      .getElementById("toglepanel")
      .addEventListener("click", togglePanel);

      function checkUserData() {
        const item = getUser();
    
        if (item) {
          console.log("user item",item);
          setUserData(item);
  
          setUserData((userData) => {
            console.log("updated details", userData);
            return userData;
          });
  
        }
      }
      checkUserData();
  
      window.addEventListener('storage', checkUserData)
    
      return () => {
        window.removeEventListener('storage', checkUserData)
      }

  }, []);

  const {
    register,
    trigger,
    reset,
    formState: { errors },
  } = useForm();

  const togglePanel = () => {
    const hpanel = document.getElementById("tpanel");

    if (hpanel.classList.contains("small-header")) {
      hpanel.classList.remove("small-header");
    } else {
      hpanel.classList.add("small-header");
    }
  };

  const maildate = (datestr) => {
    const start = moment(datestr).format(
      "dddd, MMMM Do YYYY, h:mm:ss a"
    );
    return start;
  };

  const truncateString = (str, n) => {
    return (str.length > n) ? str.substr(0, n-1) + '...' : str;
  };

  const [trackingList, setTrackingList] = useState([]);
  
  const getTracking = useQuery(["tracking", userData?.id], () => api.getTrackingbyCientID(userData?.id), {
    enabled: userData?.id !== undefined,
    onSuccess: (data) => {
      console.log("tracking data:::", data);
      setTrackingList([...data]);
      setTrackingList(trackingList => {
        return trackingList;
      })
    },
    onError: (error) => {
      
        console.log("Error Message ::: ",error); 
      
        if(error?.response?.status === 401){
          toast.error("Session Expired : Please Re-Login",{
            duration: 6000,
            });
         removeUserSession();
          navigate("/", {replace: true});
        }
        else {
          toast.error("Something went wrong",{
            duration: 6000,
            });
        }
    }
  })

   ///////////Modal view tracker //////
   const trackidRef = useRef();
   const trackRef = useRef(false);
   const [trackerid, setTrackerid] = useState("");
   const [viewtrackeropen, setViewTrackerOpen] = useState(false);
   const [tracker, setTracker] = useState([]);
   
   const handleViewTrackerShow = (t) => {
     console.log("tracking ID:::", t);
     setTrackerid(t);
     trackidRef.current = t;
     setViewTrackerOpen(true);
     trackRef.current = true;
     console.log("trackidRef:::", trackidRef.current);
     getTrackingbyID.refetch();
   };
   
   const handleViewTrackerClose= (e) => {
     setViewTrackerOpen(false);
     trackRef.current = false;
   };

   const getTrackingbyID = useQuery(["tracker", trackerid], () => api.getTrackingByID(trackerid), {
     enabled:trackRef.current,
    onSuccess: (data) => {
      console.log("tracker data:::", data);
      setTracker([...data]);
      setTracker(tracker => {
        return tracker;
      })
    },
    onError: (error) => {
      
        console.log("Error Message ::: ",error); 
      
        if(error?.response?.status === 401){
          toast.error("Session Expired : Please Re-Login",{
            duration: 6000,
            });
         removeUserSession();
          navigate("/", {replace: true});
        }
        else {
          toast.error("Something went wrong",{
            duration: 6000,
            });
        }
    }
  })
   
   ////////////////

    /////////////////Search function ///////////////
const [searchquery, setSearchquery] = useState("");

const [searchApi, setSearchAPi] = useState("");

const searchApplication = (q) => {
 //console.log("query: ", q.target.value);

 const query = q.target.value;

 setSearchquery(query);

 setSearchquery(searchquery => {
   return searchquery;
 });

 if(query.length === 0){
  
     console.log("Refetching Application list", query, " query length: ", query.length);
     getTracking.refetch();
     //console.log("searchApi",);      
 }
}

const debouncedFilter = useDebounce(searchquery, 500);

console.log("debouncedFilter", debouncedFilter); 

const { data:searchData, isLoading:searchLoading } = useQuery(
 ['trackingsearch', debouncedFilter], 
 () => api.searchTracking(debouncedFilter),
 { enabled: Boolean(debouncedFilter),
 onSuccess: (data) => {
     console.log("data", data);
     const apd = [...data];
     setTrackingList(apd);
     setTrackingList((trackingList) => {
       return trackingList;
     })
 },
 onError: (error) => {
     
   console.log("Error Message",error); 

   toast.error("Something went wrong",{
     duration: 6000,
     });

   if(error.response.status === 401){
     removeUserSession();
     navigate("/", {replace: true});
   }

 } 
})

 /////////////////////////////////

  return (
    <Fragment>
      <Toaster />
      <div className="normalheader" id="tpanel">
        <div className="hpanel">
          <div className="panel-body">
            <a className="small-header-action" id="toglepanel" href={void(0)}>
              <div className="clip-header">
                <i className="fa fa-arrow-up"></i>
              </div>
            </a>

            <div id="hbreadcrumb" className="pull-right m-t-lg">
              <ol className="hbreadcrumb breadcrumb">
                <li>
                  <a href={void(0)}>i-visas</a>
                </li>
                <li>
                  <span>Client</span>
                </li>
                <li className="active">
                  <span>Tracking </span>
                </li>
              </ol>
            </div>
            <h2 className="font-light m-b-xs">Tracking</h2>
            <small>
            <strong>View</strong> received and sent <strong>items</strong> between I-VISAS for all Applications
            </small>
          </div>
        </div>
      </div>

      <div className="content">
        <div className="row">
          
          <div className="col-md-12">
            

          <div className="row">
                <div className="col-lg-12">
                    <div className="hpanel">
                        <div className="panel-body">
                            <div className="input-group">
                                <input className="form-control" 
                                 type="text"
                                 onChange={searchApplication}
                                 value={searchquery || ""}
                                placeholder="Search by Tracking ID or APP ID.."/>
                                <div className="input-group-btn">
                                    <button disabled className="btn btn-default"><i className="fa fa-search"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div className="row">

<div className="col-lg-12" style={{marginBottom: "20px"}}>

<div className="hpanel forum-box">

<div className="panel-heading">
            <span className="pull-right">
                <i className="fa fa-clock-o"> </i> Last check: {maildate(new Date())}
            </span>
    Showing: {trackingList?.length} Items
</div>
{trackingList && 
(
  trackingList?.map(tracking => (
<div key={tracking?.id} className="hpanel filter-item" style={{border: "solid 1px #f3f1f1"}}>
    <a href={void(0)} data-trackerid={tracking?.id} onClick={ () => handleViewTrackerShow(tracking?.id)}>
        <div className="panel-body">
            <div className="pull-right text-right">
                    <small className="stat-label">{maildate(tracking?.created_at)}</small>
                <h5>Tracking ID : {tracking?.TRACKING_ID} <i className={tracking.DIRECTION === "send" ? "fa fa-level-up text-info" : "fa fa-level-down text-success"} style={{marginLeft: "20px"}}></i></h5>
            </div>
            <h5 className="m-b-xs">APP ID : <span style={{color : "#5c9731"}}>{tracking?.APPLICATION_ID}</span></h5>
            <p className="small">{truncateString(tracking.TRACKING_NOTE, 50)}</p>
        </div>
        </a>
    </div>
  ))

)}
    

   {/*  <div className="hpanel filter-item" style={{border: "solid 1px #f3f1f1"}}>
    <a href={void(0)} data-trackerid={1} onClick={handleViewTrackerShow}>
            <div className="panel-body">
                <div className="pull-right text-right">
                    <small className="stat-label">{maildate(new Date())}</small>
                    <h5>Tracking ID : 23781  <i className="fa fa-level-down text-success" style={{marginLeft: "20px"}}></i></h5>
                </div>
                <h5 className="m-b-xs">APP ID : <span style={{color : "#5c9731"}}>157885</span></h5>
                <p className="small">Note on what was sent to you...</p>
            </div>
        </a>
    </div>

    <div className="hpanel filter-item" style={{border: "solid 1px #f3f1f1"}}>
    <a href={void(0)} data-trackerid={1} onClick={handleViewTrackerShow}>
            <div className="panel-body">
                <div className="pull-right text-right">
                    <small className="stat-label">{maildate(new Date())}</small>
                    <h5>Tracking ID : 44218  <i className="fa fa-level-down text-warning" style={{marginLeft: "20px"}}></i></h5>
                </div>
                <h5 className="m-b-xs">APP ID : <span style={{color : "#5c9731"}}>656436</span></h5>
                <p className="small">English certificates with all supporting documents</p>
            </div>
        </a>
    </div>

    <div className="hpanel filter-item" style={{border: "solid 1px #f3f1f1"}}>
    <a href="#"  data-trackerid={1} onClick={handleViewTrackerShow}>
            <div className="panel-body">
                <div className="pull-right text-right">
                    <small className="stat-label">{maildate(new Date())}</small>
                    <h5>Tracking ID : 56756  <i className="fa fa-level-up text-warning" style={{marginLeft: "20px"}}></i></h5>
                </div>
                <h5 className="m-b-xs">APP ID : <span style={{color : "#5c9731"}}>780256</span></h5>
                <p className="small">Details of what was sent from the Client to I-Visas.</p>
            </div>
        </a>
    </div>

    <div className="hpanel filter-item" style={{border: "solid 1px #f3f1f1"}}>
        <a href={void(0)} data-trackerid={1} onClick={handleViewTrackerShow}>
            <div className="panel-body">
                <div className="pull-right text-right">
                    <small className="stat-label">{maildate(new Date())}</small>
                    <h5>Tracking ID : 56756  <i className="fa fa-level-up text-success" style={{marginLeft: "20px"}}></i></h5>
                </div>
                <h5 className="m-b-xs">APP ID : <span style={{color : "#5c9731"}}>780256</span></h5>
                <p className="small">Samle note from Sender on what was sent and other relevant info of the Sending vendor</p>
            </div>
        </a>
    </div> */}

    </div>

</div>


</div>


          </div>
        </div>
     
        <Modal show={viewtrackeropen} onHide={handleViewTrackerClose} animation={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        >
           <div className="color-line"></div>
        <Modal.Header className="modal-header text-center" style={{padding: "10px 10px"}}>
          <Modal.Title>Tracking Details</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{padding: "5px"}}>
         
                <div className="panel-body">
                       
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="row">
                                    <div className="col-sm-3 text-center">
                                        <div className="project-label">COURIER</div>
                                        <p className="font-bold" style={{fontSize:"20px"}}>{tracker[0]?.TRACK_CODE}</p>
                                    </div>
                                    <div className="col-sm-3 text-center">
                                        <div className="project-label">TRACKER ID</div>
                                        <p className="font-bold" style={{fontSize:"20px"}}>{tracker[0]?.TRACKING_ID}</p>
                                    </div>
                                    <div className="col-sm-3 text-center">
                                        <div className="project-label">APP ID</div>
                                        <p className="font-bold" style={{fontSize:"20px"}}> {tracker[0]?.APPLICATION_ID}</p>
                                    </div>
                                    <div className="col-sm-3 text-center">
                                        <div className="project-label">DIRECTION</div>
                                        <span className={tracker[0]?.DIRECTION === "send" ? "label label-info pull-center" : "label label-success pull-center"}>{tracker[0]?.DIRECTION}</span>
                                    </div>
                                </div>
                                <hr/>
                                <div className="project-label" style={{marginBottom: "5px"}}>NOTES</div>
                                
                                <p className="trackingNotes">
                                {tracker[0]?.TRACKING_NOTE}
                                </p>
                                <hr/>
                                <p className="text-right">
                                   <a href={`${tracker[0]?.TRACK_URL.replace("[TRACKING_ID]", tracker[0]?.TRACKING_ID)}`} target="_blank" className="btn w-xs btn-info"><i className="fa fa-location-arrow"></i> Track Item</a> 
                                 {/*  <button type="button" className="btn w-xs btn-info"><i className="fa fa-location-arrow"></i> Track Item</button> */}
                                  </p>
                            </div>
                        </div>
                    </div>
        </Modal.Body>
        <Modal.Footer>
           <div className="text-right">
              <button
                type="button"
                className="btn btn-default"
                onClick={handleViewTrackerClose}>
                <i className="fa fa-trash"></i>  Close
              </button>
          </div>
        </Modal.Footer>
      </Modal>

        </div>

    </Fragment>
  );
};
export default ClientTracking; 
