import React, { useState, useEffect, useRef, Fragment } from "react";
import { useQuery, useMutation } from "react-query";
import { useForm } from "react-hook-form";
import toast, { Toaster } from 'react-hot-toast';
import { getAdminUser, removeUserAdminSession, useDebounce } from "../Utils/Common";
import * as api from '../Utils/adminApiCalls';
import "./AdminDashboard.css";
import {
    useMatch,
    useResolvedPath, Link, useNavigate
  } from "react-router-dom";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import moment from "moment";
import AdminNav from "./Admin_nav";
import { Modal } from 'react-bootstrap';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";


const pageLimit = 5;
const dataLimit = 10;

const ClientApplications = () => {

  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm({mode: "onChange"});

  const {
    register:registerIn,
    handleSubmit:handleSubmitIn,
    trigger:triggerIn,
    reset:resetIn,
    formState: { errors:errorsIn },
  } = useForm({mode: "onChange"});

  const {
    register:registerPay,
    handleSubmit:handleSubmitPay,
    trigger:triggerPay,
    reset:resetPay,
    formState: { errors:errorsPay },
  } = useForm({mode: "onChange"});

const navigate = useNavigate();
  
const [userData, setUserData] = useState({});

const [appID, setAppID] = useState("");

const [applist, setApplist] = useState([]);

const [bylocation, setBylocation] = useState({});


const callPDFgenerator = useMutation(["pdfgenerator", setAppID], api.generateApplicationPDF, {
  onSuccess: (data) => {
      console.log("pdf: ",data);
      
      toast.success("Application PDF generated Successfully",{
      duration: 6000,
      });
  },
  onError: (error) => {

    console.log("Error Message",error); 

    toast.error("Something went wrong",{
    duration: 6000,
    });

    if(error?.response?.status === 401){
      removeUserAdminSession();
      navigate("/admin_login", {replace: false});
    }

  } 
})

const generatePDF = (e) => {

  console.log("appID e :", e.target.getAttribute("data-appid"))
  setAppID(e.target.getAttribute("data-appid"));

  if(appID){
      callPDFgenerator.mutate({"APPLICATION_ID" : appID});
  }
  else return null;

};

const [details, setDetails] = useState({
  direction:"",
  courier:"",
  trackingid: "",
  tracking_date:"",
  note:""
});
  
useEffect(() => {
  
  function checkUserData() {
    const item = getAdminUser();

    if (item) {
      console.log("user item",item);
      setUserData(item);

      setUserData((userData) => {
        console.log("updated details", userData);
        return userData;
      });

    }
  }
  checkUserData();

  window.addEventListener('storage', checkUserData)

  return () => {
    window.removeEventListener('storage', checkUserData)
  }
  
}, []);
  
const clientApplications = useQuery(['allclientapplications', userData?.id], () => api.getAllApplications(), {
  onSuccess: (data) => {
    console.log("application data", data);
    const apd = [...data];
    setApplist(apd);
    setApplist((applist) => {
      return applist;
    })
},
onError: (error) => {
    
  console.log("Error Message",error); 

  if(error?.response?.status === 401){
    toast.error("Session Expired : Please Re-Login",{
      duration: 6000,
      });
   removeUserAdminSession();
    navigate("/admin_login", {replace: false});
  }
  else {
    toast.error("Something went wrong",{
      duration: 6000,
      });
  }

} 
});
     
    const { loading: loadingapp, data: dataapp, refetch:apprefetch } = clientApplications;

    const handleChange = (event) => {
      event.persist();
  
      setDetails((details) => ({
        ...details,
        [event.target.name]: event.target.value,
      }));
      console.log(details);
    };
  
    const viewApp = async (appid) => {
      console.log("appid ID", appid);
      
    };
  
    const appdate = (datestr) => {
      const start = moment(datestr).format(
        "dddd, MMMM Do YYYY, h:mm:ss a"
      );
      
      return start;
    };

    /* const TagIcon = (tag) => {
      if(tag === 1){
        return <i className="fa fa-credit-card-alt text-info"></i>
      }
      else if(tag === 2 ){
        return <i className="fa fa-tag text-success"></i>
      }
      else{
        return ""
      }
    } */

    const TagIcon = (tag) => {
      if(tag === 0){
        return <><i className="fa fa-star-half text-info"></i><span> INITIATED</span></>
      }
      else if(tag === 1 ){
        return <><i className="fa fa-star-half-empty text-info"></i><span> DRAFT</span></>
      }
      else if(tag === 2 ){
        return <><i className="fa fa-search-plus text-default"></i><span> UNDER REVIEW</span></>
      }
      else if(tag === 3 ){
        return <><i className="fa fa-handshake-o text-success"></i><span> APPROVED</span></>
      }
      else if(tag === 4 ){
        return <><i className="fa fa-cart-plus text-info"></i><span> BILLED</span></>
      }
      else if(tag === 5 ){
        return <><i className="fa fa-leaf text-success"></i><span> COMPLETED</span></>
      }
      else{
        return ""
      }
    }
    
    const getTodaysDate = () => {
      let today = new Date();
      let dd = today.getDate();
      let mm = today.getMonth()+1; //January is 0 so need to add 1 to make it 1!
      let yyyy = today.getFullYear();
      if(dd<10){
        dd='0'+dd
      } 
      if(mm<10){
        mm='0'+mm
      } 
  
      today = yyyy+'-'+mm+'-'+dd;
  
      console.log("todays date",today);
  
      return today;
    }

    /////////////////Locations /////////////////

    const [officeLocations, setOfficeLocation] = useState([]);
    const getAllLocations = useQuery("locationlist", () => api.getOfficeLocations(), {
      refetchOnWindowFocus: false,
      //enabled: locopen, // turned off by default, manual refetch is needed
      onSuccess: (data) => {
          console.log("location details: ",data);
    
          const apd = [...data];
    
          setOfficeLocation(apd);
    
          setOfficeLocation((officeLocations) => {
            return officeLocations;
          });
      },
      onError: (error) => {
        
        console.log("Error Message",error); 
      
        if(error?.response?.status === 401){
          toast.error("Session Expired : Please Re-Login",{
            duration: 6000,
            });
         removeUserAdminSession();
          navigate("/admin_login", {replace: false});
        }
        else {
          toast.error("Something went wrong",{
            duration: 6000,
            });
        }
      
      } 
    })

    ///////////////////////////////////////////


     ///////send note/////////

     const [noteopen, setNoteOpen] = useState(false);
     const [convertedTextNote, setConvertedTextNote] = useState("");
     const [note, setNote] = useState(
       {
         MESSAGE: "",
       }
     );

     const modules = {
      toolbar: [
        [{ header: "1" }, { header: "2" }, { font: [] }],
        [{ size: [] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        ["link"],
        ["clean"],
      ],
      clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
      },
    };
    /*
     * Quill editor formats
     * See https://quilljs.com/docs/formats/
     */
    const formats = [
      "header",
      "font",
      "size",
      "bold",
      "italic",
      "underline",
      "strike",
      "blockquote",
      "list",
      "bullet",
      "indent",
      "link",
      "image",
      "video",
    ];
 
      const sendNote = useMutation(api.addNote, {
       onSuccess: (data) => {
         console.log("onSuccess data",data, "id: ", data.id);
         toast.success('Note Successfully Added!',{
           duration: 6000,
         });
         
         const resetNoteField = {
           MESSAGE: "",
           };
   
         setNote(resetNoteField);
   
         setNote((note)=> {
           return note;
         });
   
         setConvertedTextNote("");
   
       },
       onError: (error) => {
     
         console.log("Error Message",error); 
       
         if(error?.response?.status === 401){
           toast.error("Session Expired : Please Re-Login",{
             duration: 6000,
             });
          removeUserAdminSession();
           navigate("/admin_login", {replace: false});
         }
         else {
           toast.error("Something went wrong",{
             duration: 6000,
             });
         }
       
       } 
     });
 
 
     const handleNote = async (e) => {
 
       const appid = e.target.getAttribute("data-appid");
 
       console.log("note data", note, "app id:",appid);
   
       const notepayload = {...note};
      
       notepayload.MESSAGE = convertedTextNote;
       notepayload.DESCRIPTION = convertedTextNote;
       notepayload.APPLICATION_ID = appID;
       notepayload.id = userData.id;
   
       console.log("notepayload on send", notepayload);
         
       if (notepayload.MESSAGE !== "" && userData.id !== undefined) {
          
          sendNote.mutate(notepayload);
        }
        else{
          if(convertedTextNote === ""){
          toast.error("Note cannot be empty",{
            duration: 6000,
            });
          }
        } 
     }
 
     const handleNoteShow = (e) => {
       setAppID(e.target.getAttribute("data-appid"));
       setNoteOpen(true);
       console.log("app ID",appID)
     }
   
     const handleNoteClose = () => {
 
       setConvertedTextNote("");
       
       setNoteOpen(false);
   
     }
 ////////////////////////////


     //////Modal Add Tracker
     const [trackeropen, setTrackerOpen] = useState(false);
     const [courier, setCourier] = useState([]);
     
     const getCouriers = useQuery("courier_list", () => api.getCourier(), {
       enabled: trackeropen,
       onSuccess: (data) => {
         console.log("users data:::", data);
         setCourier([...data]);
         setCourier(courier => {
           return courier;
         })
       },
       onError: (error) => {
         
        console.log("Error Message",error); 
       
        if(error?.response?.status === 401){
          toast.error("Session Expired : Please Re-Login",{
            duration: 6000,
            });
         removeUserAdminSession();
          navigate("/admin_login", {replace: false});
        }
        else {
          toast.error("Something went wrong",{
            duration: 6000,
            });
        }
      
      } 
     })

const handleTrackerShow = (e) => {
  setAppID(e.target.getAttribute("data-appid"));
  setTrackerOpen(true);
};


const handleTrackerClose= (e) => {
  setTrackerOpen(false);
};


const handleTracker = async (e) => {
  console.log(e.target.getAttribute("data-appid"));

  const appid = e.target.getAttribute("data-appid");

  const frmvalidate = await trigger();
  
    if(frmvalidate && appid !== ""){
        addTracking.mutate({
          'APPTYPE_ID': appid,
          'TRACKING_TYPE_ID': details.courier,
          'DIRECTION': details.direction,
          'TRACKING_DATE': details.tracking_date,
          'TRACKING_NOTE': details.note,
          'TRACKING_ID': details.trackingid,
        });
  }
  else {
    toast.error("Invalid Tracking Data",{
      duration: 6000,
      });

      console.log("tracking details", details, " appid", appid);

      console.log("frmvalidate :::", frmvalidate);
  }
};


////////////////////////// Invoice Gen & Payment /////////////////

const [invoiceopen, setInvoiceOpen] = useState(false);
const [paymentopen, setPaymentOpen] = useState(false);

const [vrate, setVrate] = useState("");
//let invoiceItemsBK = new Map();
const [invoiceItems, setInvoiceItems] = useState([
  {
    billingitem_0: "",
    quantity_0: "",
    amount_0: "",
    subtotal_:"",
    vat_0: "",
    paid_0: "0",
    cap_0: false,
  },
]);

/* const [invoiceMetric, setInvoiceMetric] = useState(
  {
    totalCost: "",
    totalTax: "",
    balance: "",
  },
); */

const roundToTwo = (num) => {
  return +(Math.round(num + "e+2")  + "e-2");
}

   let totalCost = useRef(0);
   let totalTax = useRef(0);
   let paidAmount = useRef(0);
   let balance = useRef(0);
   let VATRATE = useRef(0);


const[allbills, setallbills]= useState([]);

const[invoicedetail, setInvoiceDetail]= useState([]);

const InvoiceRef = useRef(null);
const InvoiceType = useRef(null);
const InvoicegetRef = useRef(false);


const getInvoice = useQuery(["invoice",InvoiceRef.current], () => api.getInvoicebyAppID(InvoiceRef.current), {
  enabled : Boolean(InvoicegetRef.current),
  onSuccess: (data) => {
    console.log("invoice data", data);

    setInvoiceDetail(data[0]);
    setInvoiceDetail(invoicedetail => {
      return invoicedetail;
    });

    VATRATE.current = parseFloat(parseInt(data[0]?.VAT_RATE)/100);

    console.log("VAT RATE :::: ", VATRATE.current);

    if(data[0]?.INVOICE_DETAILS){
      console.log("INVOICE_DETAILS from DB parsed", JSON.parse(data[0]?.INVOICE_DETAILS));
     setInvoiceItems(JSON.parse(data[0]?.INVOICE_DETAILS));
     setInvoiceItems((invoiceItems) => { console.log("invoiceItems:", invoiceItems); return invoiceItems;});

     JSON.parse(data[0]?.INVOICE_DETAILS).forEach((invo,i) => {

      console.log(` ###### : ${invo[`amount_${i}`]}`);

     if(invo[`vat_${i}`] === "1") {

        totalCost.current += roundToTwo(parseInt(invo[`quantity_${i}`]) * parseFloat(invo[`amount_${i}`]) + parseInt(invo[`quantity_${i}`]) * parseFloat(invo[`amount_${i}`])*(parseFloat(parseInt(data[0]?.VAT_RATE)/100)));
        totalTax.current +=  roundToTwo(parseInt(invo[`quantity_${i}`])  * parseFloat(invo[`amount_${i}`]) * (parseFloat(parseInt(data[0]?.VAT_RATE)/100)));

        console.log("vat 1, totalcost :", totalCost.current);

        if(invo[`paid_${i}`] === "1") {
          paidAmount.current += roundToTwo(parseInt(invo[`quantity_${i}`]) * parseFloat(invo[`amount_${i}`]) + parseInt(invo[`quantity_${i}`]) * parseFloat(invo[`amount_${i}`])*(parseFloat(parseInt(data[0]?.VAT_RATE)/100)));
        }
     }
     else {

        totalCost.current += roundToTwo(parseInt(invo[`quantity_${i}`]) * parseFloat(invo[`amount_${i}`]), 2);

        console.log("vat 0, totalcost :", totalCost.current);

        if(invo[`paid_${i}`] === "1") {
          paidAmount.current += roundToTwo(parseInt(invo[`quantity_${i}`]) * parseFloat(invo[`amount_${i}`]), 2); // + parseInt(invo[`quantity_${i}`]) * parseFloat(invo[`amount_${i}`])*(parseFloat(parseInt(data[0]?.VAT_RATE)/100)));
        }
     }
      
     console.log(" tamount", totalCost, "ttax", totalTax);
      /* for (let key in invo) {
        console.log(` ###### ${key}: ${invo[key]}`);
        
      } */
     
     });
   
    }

    balance.current = roundToTwo(parseFloat(totalCost.current - paidAmount.current));

    InvoicegetRef.current = false;
  },
  onError: (error) => {
    
      console.log("Error Message",error); 
      InvoicegetRef.current = false;

      if(error?.response?.status === 401){
        toast.error("Session Expired : Please Re-Login",{
          duration: 6000,
          });
       removeUserAdminSession();
        navigate("/admin_login", {replace: false});
      }
      else {
        toast.error("Something went wrong",{
          duration: 6000,
          });
      }
  }
});


const handleInvoiceShow = (e) => {

  setAppID(e.target.getAttribute("data-appid"));

  if(e.target.getAttribute("data-vrate")) setVrate(e.target.getAttribute("data-vrate"));
  
  InvoiceType.current = e.target.getAttribute("data-type");

  if(InvoiceType.current !== "New"){

    InvoicegetRef.current = true;
    InvoiceRef.current = e.target.getAttribute("data-appid");
    getInvoice.refetch();
  }

  setInvoiceOpen(true);
  console.log("Invoice event target", e.target);
};


const handleInvoiceClose= (e) => {

  setInvoiceOpen(false);

  totalCost.current = 0;
  totalTax.current = 0;
  paidAmount.current = 0;
  balance.current = 0;
  VATRATE.current = 0;

  resetIn();

  const list = [
    {
      billingitem_0: "",
      quantity_0: "",
      amount_0: "",
      subtotal_:"",
      vat_0: "",
      paid_0: "0",
      cap_0: false,
    },
  ]

  setInvoiceItems(list);

  setInvoiceItems((invoiceItems) => {
    console.log("updated invoiceItems close", invoiceItems);
    return invoiceItems;
  });

};


const handlePaymentShow = (e) => {
  setAppID(e.target.getAttribute("data-appid"));

  InvoiceType.current = e.target.getAttribute("data-type");

  if(InvoiceType.current !== "New"){

    InvoicegetRef.current = true;
    InvoiceRef.current = e.target.getAttribute("data-appid");
    getInvoice.refetch();
  }

  setPaymentOpen(true);

  resetPay();

  const list = [
    {
      billingitem_0: "",
      quantity_0: "",
      amount_0: "",
      subtotal_:"",
      vat_0: "",
      paid_0: "0",
      cap_0: false,
    },
  ]

  setInvoiceItems(list);

  setInvoiceItems((invoiceItems) => {
    console.log("updated invoiceItems payment open", invoiceItems);
    return invoiceItems;
  });

};

const handlePaymentClose= (e) => {
  setPaymentOpen(false);

  totalCost.current = 0;
  totalTax.current = 0;
  paidAmount.current = 0;
  balance.current = 0;
  VATRATE.current = 0;

  resetIn();

  const list = [
    {
      billingitem_0: "",
      quantity_0: "",
      amount_0: "",
      subtotal_:"",
      vat_0: "",
      paid_0: "0",
      cap_0: false,
    },
  ]

  setInvoiceItems(list);

  setInvoiceItems((invoiceItems) => {
    console.log("updated invoiceItems payment close", invoiceItems);
    return invoiceItems;
  });
};


////////////////////////////////////////////////////////////////////
const [invoiceviewopen, setInvoiceViewOpen] = useState(false);

const handleInvoiceViewShow = (e) => {
  setAppID(e.target.getAttribute("data-appid"));

  InvoiceType.current = e.target.getAttribute("data-type");

  if(InvoiceType.current !== "New"){

    InvoicegetRef.current = true;
    InvoiceRef.current = e.target.getAttribute("data-appid");
    getInvoice.refetch();
  }

  setInvoiceViewOpen(true);

  const list = [
    {
      billingitem_0: "",
      quantity_0: "",
      amount_0: "",
      subtotal_:"",
      vat_0: "",
      paid_0: "0",
      cap_0: false,
    },
  ]

  setInvoiceItems(list);

  setInvoiceItems((invoiceItems) => {
    console.log("updated invoiceItems view open", invoiceItems);
    return invoiceItems;
  });
}

const handleInvoiceViewClose = (e) => {
  setInvoiceViewOpen(false);

  totalCost.current = 0;
  totalTax.current = 0;
  paidAmount.current = 0;
  balance.current = 0;
  VATRATE.current = 0;

  const list = [
    {
      billingitem_0: "",
      quantity_0: "",
      amount_0: "",
      subtotal_:"",
      vat_0: "",
      paid_0: "0",
      cap_0: false,
    },
  ]

  setInvoiceItems(list);

  setInvoiceItems((invoiceItems) => {
    console.log("updated invoiceItems view close", invoiceItems);
    return invoiceItems;
  });
}

/////////////////////////////////////////////////////////////////////

const getBills = useQuery("billings", api.activeBills, {
  onSuccess: (data) => {
    console.log("bills data", data);
    setallbills(data);
    setallbills(allbills => {
      return allbills;
    })
  },
  onError: (error) => {
    
      console.log("Error Message",error); 
    
      if(error?.response?.status === 401){
        toast.error("Session Expired : Please Re-Login",{
          duration: 6000,
          });
       removeUserAdminSession();
        navigate("/admin_login", {replace: false});
      }
      else {
        toast.error("Something went wrong",{
          duration: 6000,
          });
      }
  }
});


const handleInvoiceItemChange = (e, index) => {
   
  const { name, value } = e.target;
  console.log(
    "event::" + e.target.name + "--" + e.target.value + "--" + index
  );

  console.log("event target type :", e.target.type);

  const list = [...invoiceItems];
  console.log("list before :",list);

  if(e.target.type === 'select-one'){

    console.log("vat value : ",e.target[e.target.selectedIndex].getAttribute('data-vat'));
    list[index][name] = value;
    list[index][`vat_${index}`] = e.target[e.target.selectedIndex].getAttribute('data-vat');
  }
  else {

    console.log("Billing Item value : ", list[index][`billingitem_${index}`]);
   if(list[index][`billingitem_${index}`] !== "")
   {

        list[index][name] = value;
        console.log("Amount data : ",e.target.getAttribute('data-amtt'), "Amount value", list[index][`amount_${index}`]);

        if((e.target.getAttribute('data-amtt') !== null && e.target.getAttribute('data-amtt') === "amount") || list[index][`amount_${index}`] != '')
        {
          console.log("subtotal components:", " Quantity: ", list[index][`quantity_${index}`], " Amount : ", value, "VAT Value : ", list[index][`vat_${index}`]);
          //console.log("subtotal:", parseFloat((parseInt(list[index][`quantity_${index}`] || 1) * parseFloat(value))));
          if(list[index][`vat_${index}`] === "1")
          {
            console.log("if ...VAT Enabled : ", list[index][`vat_${index}`], "VAT Value : ", vrate);
            list[index][`subtotal_${index}`] = roundToTwo(parseInt(list[index][`quantity_${index}`] || 1) * parseFloat(list[index][`amount_${index}`]) + parseInt(list[index][`quantity_${index}`] || 1) * parseFloat(list[index][`amount_${index}`])*(parseFloat(parseInt(vrate) || 0.00)/100));
          }
          else {
            console.log("else ...VAT Enabled : ", list[index][`vat_${index}`]);
            list[index][`subtotal_${index}`] = roundToTwo(parseInt(list[index][`quantity_${index}`] || 1) * parseFloat(list[index][`amount_${index}`]))
            //parseFloat((parseInt(list[index][`quantity_${index}`] || 1) * parseFloat(value)));
          }
        }
      }
   }
  
  console.log("updated invoice Items", list);
  setInvoiceItems(list);

  setInvoiceItems((invoiceItems) => {
    console.log("updated invoiceItems", invoiceItems);
    return invoiceItems;
  });

};

const handleInvoiceItemPayChange = (e, index) => {

  const { name, value } = e.target;
  console.log(
    "event::" + e.target.name + "--" + e.target.value + "--" + index + "----" + e.target.type
  );

console.log("subtotal", e.target.getAttribute('data-subtotal'));

const subtotal = parseFloat(e.target.getAttribute('data-subtotal'));

balance.current = e.target.value  === "1" ? roundToTwo(balance.current + subtotal) : roundToTwo(balance.current - subtotal);

paidAmount.current = e.target.value  === "1" ? roundToTwo(paidAmount.current - subtotal) : roundToTwo(paidAmount.current + subtotal);

  const list = [...invoiceItems];
  list[index][`paid_${index}`] = e.target.value === "1" ? "0" : "1";
  list[index][`cap_${index}`] = e.target.value  === "1" ? "0" : "1";

  console.log("updated invoice paid Items", list);
  setInvoiceItems(list);

  setInvoiceItems((invoiceItems) => {
    console.log("updated invoiceItems paid", invoiceItems);
    return invoiceItems;
  });

}

// handle click event of the Remove button
const handleRemoveInvoiceItem = (index) => {
  const list = [...invoiceItems];
  list.splice(index, 1);
  setInvoiceItems(list);
};

// handle click event of the Add button
const handleAddInvoiceItem  = (i) => {
  i++;
  const billingitem = "billingitem_" + Number(i);
  const quantity_ = "quantity_" + Number(i);
  const amount = "amount_" + Number(i);
  const subtotal = "subtotal_" + Number(i);
  const vat = "vat_" + Number(i);
  const paid = "paid_" + Number(i);
  const cap = "cap_" + Number(i);
  
  setInvoiceItems([
    ...invoiceItems,
    {
    [billingitem]: "",
    [quantity_]: "",
    [amount]: "",
    [subtotal]:"",
    [vat]: "",
    [paid]:"0",
    [cap]:false
    },
  ]);

  setInvoiceItems((invoiceItems) => {
    console.log(" +++ invoiceItems value:", invoiceItems);
    return invoiceItems;
  });
};

const handleInvoice = async (e) => {

  console.log(e.target.getAttribute("data-appid"));

  const appid = e.target.getAttribute("data-appid");

  const Invvalidate = await triggerIn();
  
  if(Invvalidate && appid !== ""){
        genInvoice.mutate({
          'APPTYPE_ID': appid,
          'InvoiceItems': JSON.stringify(invoiceItems),
          'UserID': userData?.id,
        });
  }
  else {
    toast.error("Invalid Invoice Data",{
      duration: 6000,
      });

      console.log("Invoice details", invoiceItems, " appid", appid);

      console.log("Invvalidate :::", Invvalidate);
  }

}


const handleEditInvoice = async (e) => {

  console.log(e.target.getAttribute("data-appid"));

  const appid = e.target.getAttribute("data-appid");

  const Invvalidate = await triggerIn();
  
  if(Invvalidate && appid !== ""){
    editInvoice.mutate({
          'APPTYPE_ID': appid,
          'INVOICE_NUMBER': invoicedetail.INVOICE_NUMBER,
          'CLIENT_ID': invoicedetail.CLIENT_ID,
          /* 'PAYMENT_STATUS': invoicedetail.PAYMENT_STATUS,
          'PAYMENT_AMOUNT': invoicedetail.PAYMENT_AMOUNT, */
          'InvoiceItems': JSON.stringify(invoiceItems),
          'UserID': userData?.id,
        });
  }
  else {
    toast.error("Invalid Invoice Data",{
      duration: 6000,
      });

      console.log("Invoice details", invoiceItems, " appid", appid);

      console.log("Invvalidate :::", Invvalidate);
  }

 }


const handleUpdateInvoice = async (e) => {

  console.log(e.target.getAttribute("data-appid"));

  const appid = e.target.getAttribute("data-appid");

  const Invvalidate = await triggerIn();
  
  if(Invvalidate && appid !== ""){
    updateInvoice.mutate({
          'APPTYPE_ID': appid,
          'INVOICE_NUMBER': invoicedetail.INVOICE_NUMBER,
          'CLIENT_ID': invoicedetail.CLIENT_ID,
          /* 'PAYMENT_STATUS': invoicedetail.PAYMENT_STATUS,
          'PAYMENT_AMOUNT': invoicedetail.PAYMENT_AMOUNT, */
          'InvoiceItems': JSON.stringify(invoiceItems),
          'UserID': userData?.id,
        });
  }
  else {
    toast.error("Invalid Invoice Data",{
      duration: 6000,
      });

      console.log("Invoice details", invoiceItems, " appid", appid);

      console.log("Invvalidate :::", Invvalidate);
  }

 }

const handlePayment = async (e) => { 

  console.log("APP ID",e.target.getAttribute("data-appid"));

  const appid = e.target.getAttribute("data-appid");

  const Invvalidate = await triggerPay();
  
  if(Invvalidate && appid !== ""){
    payInvoice.mutate({
          'APPTYPE_ID': appid,
          'INVOICE_NUMBER': invoicedetail.INVOICE_NUMBER,
          'CLIENT_ID': invoicedetail.CLIENT_ID,
          /* 'PAYMENT_STATUS': invoicedetail.PAYMENT_STATUS,
          'PAYMENT_AMOUNT': invoicedetail.PAYMENT_AMOUNT, */
          'InvoiceItems': JSON.stringify(invoiceItems),
          'UserID': userData?.id,
        });
  }
  else {
    toast.error("Invalid Invoice Data",{
      duration: 6000,
      });

      console.log("Invoice details", invoiceItems, " appid", appid);

      console.log("Invvalidate :::", Invvalidate);
  }

}


const genInvoice = useMutation('invoice_gen', api.genInvoice, {
  onSuccess: (data) => {
      console.log("data", data);

      toast.success("Invoice Added Successfully",{
        duration: 6000,
        });
      
      resetIn();

      const dt = [{
          billingitem_0: "",
          quantity_0: "",
          amount_0: "",
          subtotal_:"",
          vat_0: "",
          paid_0: "0",
          cap_0: false
        }];

      setInvoiceItems(dt);
      setInvoiceItems(invoiceItems => {
        return invoiceItems;
      }); 

      let fileUrl = data?.url; 
       let fileName = data?.filename;
       console.log(" fileName", fileName);
       let a = document.getElementById('invoicegen'); //document.createElement("a");
       a.setAttribute("id", "invoicegen");
 
      // a.href = fileUrl;
       a.setAttribute('href', fileUrl);
       a.setAttribute('target', '_blank');
       a.setAttribute("download", fileName);
       a.click(); 
       //document.body.removeChild(a);

       setInvoiceOpen(false);
  },
  onError: (error) => {
      
    console.log("Error Message",error); 

    if(error?.response?.status === 401){
      toast.error("Session expired : Kindly Re-login",{
        duration: 6000,
        })
      removeUserAdminSession();
      navigate("/admin_login", {replace: false});
    }
    else {
      toast.error("Something went wrong : Invoicing",{
        duration: 6000,
        });
    }

  } 
});


const editInvoice = useMutation('invoice_edit', api.editInvoice, {
  onSuccess: (data) => {
      console.log("data", data);

      toast.success("Invoice Added Successfully",{
        duration: 6000,
        });
      
      resetIn();

      const dt = [{
          billingitem_0: "",
          quantity_0: "",
          amount_0: "",
          subtotal_:"",
          vat_0: "",
          paid_0: "0",
          cap_0: false
        }];

      setInvoiceItems(dt);
      setInvoiceItems(invoiceItems => {
        return invoiceItems;
      }); 

      let fileUrl = data?.url; 
       let fileName = data?.filename;
       console.log(" fileName", fileName);
       let a = document.getElementById('invoicegen'); //document.createElement("a");
       a.setAttribute("id", "invoicegen");
 
      // a.href = fileUrl;
       a.setAttribute('href', fileUrl);
       a.setAttribute('target', '_blank');
       a.setAttribute("download", fileName);
       a.click(); 
       //document.body.removeChild(a);

       setInvoiceOpen(false);
  },
  onError: (error) => {
      
    console.log("Error Message",error); 

    if(error?.response?.status === 401){
      toast.error("Session expired : Kindly Re-login",{
        duration: 6000,
        })
      removeUserAdminSession();
      navigate("/admin_login", {replace: false});
    }
    else {
      toast.error("Something went wrong : Invoicing",{
        duration: 6000,
        });
    }

  } 
});



const updateInvoice = useMutation('invoice_update', api.updateInvoice, {
  onSuccess: (data) => {
      console.log("data", data);

      toast.success("Invoice updated Successfully",{
        duration: 6000,
        });
      
      resetIn();

      const dt = [{
          billingitem_0: "",
          quantity_0: "",
          amount_0: "",
          subtotal_:"",
          vat_0: "",
          paid_0: "0",
          cap_0: false
        }];

      setInvoiceItems(dt);
      setInvoiceItems(invoiceItems => {
        return invoiceItems;
      }); 

      let fileUrl = data?.url; 
       let fileName = data?.filename;
       console.log(" fileName", fileName);
       let a = document.getElementById('invoicegen'); //document.createElement("a");
       a.setAttribute("id", "invoicegen");
 
      // a.href = fileUrl;
       a.setAttribute('href', fileUrl);
       a.setAttribute('target', '_blank');
       a.setAttribute("download", fileName);
       a.click(); 
       //document.body.removeChild(a);

       setInvoiceOpen(false);
  },
  onError: (error) => {
      
    console.log("Error Message",error); 

    if(error?.response?.status === 401){
      toast.error("Session expired : Kindly Re-login",{
        duration: 6000,
        })
      removeUserAdminSession();
      navigate("/admin_login", {replace: false});
    }
    else {
      toast.error("Something went wrong : Invoicing",{
        duration: 6000,
        });
    }

  } 
});


const payInvoice = useMutation('invoice_pay', api.payInvoice, {
  onSuccess: (data) => {
      console.log("data", data);

      toast.success("Invoice paid Successfully",{
        duration: 6000,
        });
      
      resetPay();

      const dt = [{
          billingitem_0: "",
          quantity_0: "",
          amount_0: "",
          subtotal_:"",
          vat_0: "",
          paid_0: "0",
          cap_0: false
        }];

      setInvoiceItems(dt);
      setInvoiceItems(invoiceItems => {
        return invoiceItems;
      }); 

      let fileUrl = data?.url; 
       let fileName = data?.filename;
       console.log(" fileName", fileName);
       let a = document.getElementById('invoicegen'); //document.createElement("a");
       a.setAttribute("id", "invoicegen");
 
      // a.href = fileUrl;
       a.setAttribute('href', fileUrl);
       a.setAttribute('target', '_blank');
       a.setAttribute("download", fileName);
       a.click(); 
       //document.body.removeChild(a);

       //setInvoiceOpen(false);
       setPaymentOpen(false);
  },
  onError: (error) => {
      
    console.log("Error Message",error); 

    if(error?.response?.status === 401){
      toast.error("Session expired : Kindly Re-login",{
        duration: 6000,
        })
      removeUserAdminSession();
      navigate("/admin_login", {replace: false});
    }
    else {
      toast.error("Something went wrong : Invoicing",{
        duration: 6000,
        });
    }

  } 
});


///////////////////////////////////Invoice End//////////////////////////////////////////////


const addTracking = useMutation('tracking', api.addTracking, {
  onSuccess: (data) => {
      console.log("data", data);
      
      const dt = {
        direction:"",
        courier:"",
        trackingid: "",
        tracking_date:"",
        note:"",
      }

      setDetails(dt);
      setDetails(details => {
        return details;
      });

      toast.success("Tracker Added Successfully",{
        duration: 6000,
        });
  },
  onError: (error) => {
      
    console.log("Error Message",error); 

    if(error?.response?.status === 401){
      toast.error("Session expired : Kindly Re-login",{
        duration: 6000,
        })
      removeUserAdminSession();
      navigate("/admin_login", {replace: false});
    }
    else {
      toast.error("Something went wrong : tracking",{
        duration: 6000,
        });
    }

  } 
})

///////////////////////

/////////////Pagination ////////////

  //const [pages] = useState(Math.ceil(datamsg?.length / dataLimit) || 1);
  const pages = useRef(Math.ceil(applist?.length / dataLimit) || 1);

  //console.log("dataLimit::", dataLimit," datamsg length::", datamsg?.length, " pages::: ", Math.ceil(applist?.length / dataLimit));

  const [currentPage, setCurrentPage] = useState(1);

  const goToNextPage = () => {
      if(currentPage <= pages.current) {
      setCurrentPage((page) => page + 1);
      }
  }

  const goToPreviousPage = () => {
      if(currentPage > 1 ){
      setCurrentPage((page) => page - 1);
      }
  }

  const changePage = (event) => {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage(pageNumber);
  }

  const getPaginatedData = () => {
    const startIndex = currentPage * dataLimit - dataLimit;
    const endIndex = startIndex + dataLimit;
    //console.log("getPaginationData", applist?.slice(startIndex, endIndex))
    return applist?.slice(startIndex, endIndex);
  };

  const getPaginationGroup = () => { 
    let start = Math.floor((currentPage - 1) / pageLimit) * pageLimit;
    
    let page = Math.ceil(applist?.length / dataLimit) || 1;
    //console.log("local page ", Math.ceil(applist?.length / dataLimit));
    //console.log("Array Return", new Array(pages).fill().map((_, idx) => start + idx + 1), "  pages : ", pages);

    pages.current = page;
    
     return new Array(page).fill().map((_, idx) => start + idx + 1);
  }

  ////////////////////////////////////

  /////////////////Search function ///////////////
const [searchquery, setSearchquery] = useState("");

const searchApplication = (q) => {
 //console.log("query: ", q.target.value);

 const query = q.target.value;

 setSearchquery(query);

 setSearchquery(searchquery => {
   return searchquery;
 });
 
 if(query.length === 0){
  
  console.log("Refetching Application list 2", query, " query length next: ", query.length);
     
  apprefetch();
 }
}

const debouncedFilter = useDebounce(searchquery, 500);

console.log("debouncedFilter", debouncedFilter); 

const { data:searchData, isLoading:searchLoading } = useQuery(
 ['applications', debouncedFilter], 
 () => api.searchApplication(debouncedFilter),
 { enabled: Boolean(debouncedFilter),
 onSuccess: (data) => {
     console.log("data", data);
     const apd = [...data];
     setApplist(apd);
     setApplist((applist) => {
       return applist;
     })
 },
 onError: (error) => {
    
  console.log("Error Message",error); 

  if(error.response.status === 401){
    toast.error("Session Expired : Please Re-Login",{
      duration: 6000,
      });
   removeUserAdminSession();
    navigate("/admin_login", {replace: false});
  }
  else {
    toast.error("Something went wrong",{
      duration: 6000,
      });
  }

} 
})

 /////////////////////////////////

 ///////////////Top Thirty/////////////////

 const topThirty = () => {
  // manually refetch
  console.log("topThirty btn clicked");
  toprefetch();
};

const { refetch:toprefetch } = useQuery("top30", api.topThirtyApplication, {
  refetchOnWindowFocus: false,
  enabled: false, // turned off by default, manual refetch is needed
  onSuccess: (data) => {
    console.log("topThirty data", data);
    const apd = [...data?.attributes];
    setApplist(apd);
    setApplist((applist) => {
      return applist;
    })
},
onError: (error) => {
    
  console.log("Error Message",error); 

  if(error.response.status === 401){
    toast.error("Session Expired : Please Re-Login",{
      duration: 6000,
      });
   removeUserAdminSession();
    navigate("/admin_login", {replace: false});
  }
  else {
    toast.error("Something went wrong",{
      duration: 6000,
      });
  }

} 
});

 //////////////////////////////////////////


 ///////////////Verified Clients/////////////////

 const applicationUnderReview = () => {
  // manually refetch
  console.log("verified btn clicked");
  reviewrefetch();
};

const { data:verifiedData, refetch:reviewrefetch } = useQuery("reviewapp", api.reviewApplication, {
  refetchOnWindowFocus: false,
  enabled: false, // turned off by default, manual refetch is needed
  onSuccess: (data) => {
    console.log("application searched data", data);
    const apd = [...data?.applications_in_review];
    setApplist(apd);
    setApplist((applist) => {
      return applist;
    })
},
onError: (error) => {
    
  console.log("Error Message",error); 

  if(error.response.status === 401){
    toast.error("Session Expired : Please Re-Login",{
      duration: 6000,
      });
   removeUserAdminSession();
    navigate("/admin_login", {replace: false});
  }
  else {
    toast.error("Something went wrong",{
      duration: 6000,
      });
  }

} 
});

 //////////////////////////////////////////


 /////////////// Clients by Location/////////////////

 const appByLocation = (e) => {
  // manually refetch
  console.log("location ::", e.target.value);
  
  const locat = e.target.value;
  setBylocation(locat);
  (locat && locat !== "") && Applicationbylocation.mutate({"client_office": locat});
};

const Applicationbylocation = useMutation("bylocation", api.searchApplicationbyLocation, {
  refetchOnWindowFocus: false,
  enabled: false, // turned off by default, manual refetch is needed
  onSuccess: (data) => {
    console.log("by location data", data);
    const apd = [...data?.attributes];
    setApplist(apd);
    setApplist((applist) => {
      return applist;
    })
},
onError: (error) => {
    
  console.log("Error Message",error); 

  if(error.response.status === 401){
    toast.error("Session Expired : Please Re-Login",{
      duration: 6000,
      });
   removeUserAdminSession();
    navigate("/admin_login", {replace: false});
  }
  else {
    toast.error("Something went wrong",{
      duration: 6000,
      });
  }

} 
});

 //////////////////////////////////////////


    return (
        <>
        <Toaster />

<div className="normalheader" id="tpanel">
        <div className="hpanel">
          <div className="panel-body">
            <a className="small-header-action" id="toglepanel" href={void(0)}>
              <div className="clip-header">
                <i className="fa fa-arrow-up"></i>
              </div>
            </a>

            <div id="hbreadcrumb" className="pull-right m-t-lg">
              <ol className="hbreadcrumb breadcrumb">
                <li>
                  <a href={void(0)}>i-visas</a>
                </li>
                <li>
                  <span>Admin</span>
                </li>
                <li className="active">
                  <span>Applications </span>
                </li>
              </ol>
            </div>
            <h2 className="font-light m-b-xs">Applications Listing</h2>
            <small>
              Manage all Clients <strong>Applications</strong>
            </small>
          </div>
        </div>
      </div>

    <div className="content">
        
    <div className="row">
         
         <div className="col-md-12">
           <div className="hpanel email-compose">
            
               <>
                 <div className="panel-heading hbuilt">
                   <div className="text-center p-xs font-normal">
                     <div className="input-group">
                       <input
                         type="text"
                         onChange={searchApplication}
                          value={searchquery || ""}
                         className="form-control input-sm"
                         placeholder="Search by APP ID, First Name, Last Name, Location..."
                       />{" "}
                       <span className="input-group-btn">
                         {" "}
                         <button
                         disabled
                           type="button"
                           className="btn btn-sm btn-default">
                           <i className="fa fa-search"></i> 
                         </button>{" "}
                       </span>
                     </div>
                   </div>
                 </div>
                 <div className="panel-body">
                 {searchLoading ? (
                       <>
                      <div className="row text-center">
                      <div className="col-md-7 m-b-md" style={{textAlign: "left", display: "flex", flexDirection: "row", justifyItems: "flex-start", alignItems: "center", justifyContent: "normal"}}>

                      <button className="btn btn-info btn-sm">
                             <strong>Top 30</strong>
                          </button>
                          <span>&nbsp; | &nbsp;</span>
                          <button className="btn btn-info btn-sm" >
                            <strong>Review</strong>
                          </button>
                        <span>&nbsp; | <strong>Search by Location:</strong> &nbsp;</span>
                            <select
                                    name="bylocation"
                                    className="form-select m-b-3"
                                    style={{ width: "30%" }}>
                                    <option value=''>select location</option>
                                  </select>
                          </div>
                          <div className="col-md-5 mailbox-pagination m-b-md">
                            <span style={{ fontSize: "1.1rem" }}>
                            Showing 0 of 0 pages   &nbsp; &nbsp;
                            </span>
                            <div className="btn-group">
                              <button className="btn btn-default btn-sm previous disabled"
                             
                              disabled
                              >
                                <i className="fa fa-arrow-left"></i>
                              </button>
                              {getPaginationGroup().map((item, index) => (
                              <button 
                              key={index}
                              className="btn btn-default btn-sm disabled"
                              >
                              {item}
                              </button>
                              ))}
                              <button className="btn btn-default btn-sm next disabled"
                              disabled
                              >
                                <i className="fa fa-arrow-right"></i>
                              </button>
                            </div>
                          </div>
                      </div>
                        <div className="table-responsive" style={{overflowX: "inherit"}}>
                        <table className="table table-hover table-mailbox apptable">
                        <thead style={{borderBottom: "solid 2px #64d246"}}>
                          <tr>
                            <th style={{width: "100px"}} className="text-center">APP ID</th>
                            <th className="text-center">CLIENT NAME</th>
                            <th className="text-center">LOCATION</th>
                            <th className="text-center">APP STATUS</th>
                            <th className="text-center">CREATION DATE</th>
                            <th className="text-center">ACTION</th>
                          </tr>
                        </thead>
                            <tbody>
                            <tr className="active"><td colSpan="6" style={{textAlign:"center"}}>Searching...</td></tr>
                        </tbody>
                      </table>
                        </div>
                        </>
                    ):(
                      <>
                   <div className="row">
                   <div className="col-md-7 m-b-md" style={{textAlign: "left", display: "flex", flexDirection: "row", justifyItems: "flex-start", alignItems: "center", justifyContent: "normal"}}>
                     
                          <button className="btn btn-info btn-sm"  onClick={topThirty} >
                             <strong>Top 30</strong>
                          </button>
                          <span>&nbsp; | &nbsp;</span>
                          <button className="btn btn-info btn-sm" onClick={applicationUnderReview} >
                            <strong>Review</strong>
                          </button>
                        <span>&nbsp; | <strong>Search by Location:</strong> &nbsp;</span>
                        <select
                                name="bylocation"
                               {...register("bylocation", { 
                                          required: { value: false, message: "This field is required" }, 
                                          onChange: (e) => {appByLocation(e)} })}
                                value={bylocation || ""} // Prop: The email input data
                               
                                className="form-select m-b-3"
                                style={{ width: "30%" }}>
                                <option value=''>select location</option>
                                {officeLocations?.map(loc => 
                                (
                                  <option key={loc.id} value={loc.LOCATION_CODE}>{loc.LOCATION_CODE}</option>
                                ))}
                              </select>
                      </div>
                    
                      <div className="col-md-5 mailbox-pagination m-b-md">
                        <span style={{ fontSize: "1.1rem" }}>
                        {!!applist && (Object.keys(applist).length > 0) ? `Showing ${currentPage} of ${pages.current} pages` : "Showing 0 of 0 pages"}   &nbsp; &nbsp;
                        </span>
                        <div className="btn-group">
                          <button className={`btn btn-default btn-sm previous ${currentPage === 1 ? 'disabled' : ''}`}
                          onClick={goToPreviousPage}
                          disabled={!!applist && (currentPage === 1) ? true : false} 
                          >
                            <i className="fa fa-arrow-left"></i>
                          </button>
                          {getPaginationGroup().map((item, index) => (
                          <button 
                          key={index}
                          onClick={changePage}
                          className={`btn btn-default btn-sm  ${currentPage === item ? 'active' : null}`}
                          >
                           {item}
                          </button>
                          ))}
                          <button className={`btn btn-default btn-sm next ${currentPage === pages.current ? 'disabled' : ''}`}
                          onClick={goToNextPage} 
                          disabled={!!applist && (currentPage === pages.current) ? true : false} 
                          >
                            <i className="fa fa-arrow-right"></i>
                          </button>
                        </div>
                      </div>
                   </div>
                   <div className="table-responsive" style={{overflowX: "inherit"}}>
                     <table className="table table-hover table-mailbox apptable">
                     <thead style={{borderBottom: "solid 2px #64d246"}}>
                           <tr>
                            <th style={{width: "100px"}} className="text-center">APP ID</th>
                            <th className="text-center">CLIENT NAME</th>
                            <th className="text-center">LOCATION</th>
                            <th className="text-center">APP STATUS</th>
                            <th className="text-center">CREATION DATE</th>
                            <th className="text-center">ACTION</th>
                          </tr>
                             </thead>
                       <tbody>

                       {!!applist && (Object.keys(applist).length > 0) ? 
                           
                           getPaginatedData().map((item,i) =>{
                                        return (
                         <tr className={item.MESSAGE_STATUS === "unread" ? "unread active" : ""} key={i}>
                           <td className="text-center" style={{width: "100px"}}>
                           <a
                               href={void(0)}
                               onClick={() => viewApp(item.APPTYPE_ID)}>
                               <span>{item.APPTYPE_ID}</span>
                             </a>
                               </td>
                           <td className="text-center">
                             <a
                               href={void(0)}
                               onClick={() => viewApp(item.APPTYPE_ID)}>
                               {item.FIRSTNAME} {item.MIDDLENAME} {item.LASTNAME}
                             </a>
                           </td>
                           <td className="text-center">
                             <a
                               href={void(0)}
                               onClick={() => viewApp(item.APPTYPE_ID)}>
                                 {item.LOCATION_CODE ?? "Not Set"}
                             </a>
                           </td>
                           <td className="text-center">
                             <a
                               href={void(0)}
                               onClick={() => viewApp(item.APPTYPE_ID)}>
                                    {TagIcon(item.APPSTATUS)} 
                              {/* {item.APPSTATUS === 2 ? " UNDER REVIEW" : " DRAFT"} */}
                             </a>
                           </td>
                         
                           <td className="text-center mail-date">
                           {appdate(item?.created_at)}
                           </td>

                           <td className="text-center">
                               <div className="btn-group">
                                   <button data-toggle="dropdown" className="btn btn-default dropdown-toggle">Action <span className="caret"></span></button>
                                   <ul className="dropdown-menu">
                                      {/*  <li><a href={void(0)} onClick={() => uploadDocs(item.APPTYPE_ID)}>Upload Docs</a></li> */}
                                       {/* <li> <a href={void(0)} data-toggle="modal" data-target="#myuploadModal" data-appid={item.APPTYPE_ID} onClick={getAppID}> Upload Docs </a> </li> */}
                                       <li><Link to={`/admin_nav/admin_view_application/${item.APPTYPE_ID}`} >{item.APPSTATUS === 2 ? "Review" : "View"}</Link></li>
                                       {/* <li> <a href={void(0)}style={{pointerEvents: item.APPSTATUS === 1 ? "auto" : "none"}} data-appid={item.APPTYPE_ID} onClick={generatePDF}> Generate PDF </a> </li> */}
                                       <li><Link to={`/admin_nav/admin_generatepdf/${item.APPTYPE_ID}`} target="_blank" >Generate PDF</Link></li>
                                      {/*  <li><Link to={`/admin_edit_application/${item.APPTYPE_ID}`} >Edit</Link></li> */}
                                       <li className="divider"></li>
                                       <li> <a href={void(0)} data-appid={item.APPTYPE_ID} onClick={handleTrackerShow}> Add Tracking </a> </li>
                                       <li><a href={void(0)} data-appid={item.APPTYPE_ID} onClick={handleNoteShow}> Add Note </a></li>
                                       
                                       {item.APPSTATUS > 2 &&
                                       <> 
                                       <li className="divider"></li>
                                       </>
                                       }

                                       {(item.APPSTATUS === 3 && item.INVOICE_NUMBER === null) &&
                                       <li> <a href={void(0)} data-appid={item.APPTYPE_ID} data-vrate={item.VAT_RATE} data-type="New" onClick={handleInvoiceShow}> Generate Invoice </a> </li>
                                       }

                                       {(item.APPSTATUS === 4 && item.PAYMENT_STATUS === null && item.INVOICE_NUMBER) &&
                                       <>
                                       <li> <a href={void(0)} data-appid={item.APPTYPE_ID} data-vrate={item.VAT_RATE} data-type="Edit" onClick={handleInvoiceShow}> Edit Invoice </a> </li>
                                       <li><a href={void(0)} data-appid={item.APPTYPE_ID} data-type="pay" onClick={handlePaymentShow}> Make Payment </a></li>
                                       </>
                                       }

                                      {(item.APPSTATUS === 5 && item.INVOICE_NUMBER && item.PAYMENT_STATUS !== null && item.PAYMENT_AMOUNT !== null) &&
                                       <>
                                       <li> <a href={void(0)} data-appid={item.APPTYPE_ID} data-vrate={item.VAT_RATE} data-type="Update" onClick={handleInvoiceShow}> Update Invoice </a> </li>
                                       </>
                                       }

                                       {(item.INVOICE_NUMBER) &&
                                       <>
                                       <li> <a href={void(0)} data-appid={item.APPTYPE_ID} data-type="view" onClick={handleInvoiceViewShow}> View Invoice </a> </li>
                                       </>
                                       }
                                       
                                      {/*  </>
                                       } */}
                                   </ul>
                               </div>
                           </td>
                       </tr>
                                        )
                                    }) : (
                                     <tr className="unread active"><td colSpan="6" style={{textAlign:"center"}}>No application found under {userData?.first_name?.toUpperCase()} {userData?.last_name?.toUpperCase()}</td></tr>
                                   )}

                       </tbody>
                     </table>
                   </div>
                   </>
                    )}
                 </div>
                 <div className="panel-footer">
                  {!!applist && (Object.keys(applist).length > 0) ? applist.length : 0}  Application(s)
                 </div>
               </>
               
           </div>
         </div>
       </div>

    </div>

   {/*  <footer className="footer" style={{ maxHeight: "40px" }}>
          <div className="mt-4 mt-md-5 text-center" style={{ marginTop: "0" }}>
            <p className="footer-p">
              © <script>document.write(new Date().getFullYear())</script>{" "}
              i-visas.com
            </p>
            <a href={void(0)} id="invoicegen" style={{display:"none"}}></a>
          </div>
        </footer> */}



        <Modal show={trackeropen} onHide={handleTrackerClose} animation={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        >
           <div className="color-line"></div>
        <Modal.Header className="modal-header text-center" style={{padding: "10px 10px"}}>
          <Modal.Title>Add Tracking</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{padding: "5px"}}>
         
        <div className="panel-body no-padding">
        <div className="row">
                  <div className="col-lg-12">

                        <div className="row col-bb">
                          
                        <div className="form-group col-lg-3">
                              <label className="label-clr">
                                Direction
                              </label>
                              <select
                                name="direction"
                               {...register("direction", { 
                                          required: { value: true, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                value={details.direction || ""} // Prop: The email input data
                               
                                className="form-select m-b-3"
                                style={{ width: "100%" }}>
                                <option value=''>select direction</option>
                                <option value="send">Send</option>
                                <option value="receive">Receive</option>
                              </select>
                              {errors.direction && (
                                  <p className="errorValidate">{errors.direction.message}</p>
                                  )}
                            </div>

                            <div className="form-group col-lg-3">
                              <label className="label-clr">
                                Courier
                              </label>
                              <select
                                name="courier"
                               {...register("courier", { 
                                          required: { value: true, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                value={details.courier || ""} // Prop: The email input data
                               
                                className="form-select m-b-3"
                                style={{ width: "100%" }}>
                                <option value=''>select courier</option>
                                {courier?.map(cour => 
                                (
                                  <option key={cour.RECID} value={cour.RECID}>{cour.TRACK_CODE}</option>
                                ))

                                }
                               {/*  <option value="1">DHL</option>
                                <option value="2">UPS</option>
                                <option value="3">EMS</option>
                                <option value="4">FedEx</option>
                                <option value="5">TNT Express</option> */}
                              </select>
                              {errors.courier && (
                                  <p className="errorValidate">{errors.courier.message}</p>
                                  )}
                            </div>

                            <div className="form-group col-lg-3">
                                <label className="label-clr">
                                  Tracking ID
                                </label>
                                <input
                                  type="text"
                                  {...register("trackingid", { 
                                    required: { value: true, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                  value={details.trackingid || ""}
                                  className="form-control"
                                  name="trackingid"
                                />
                                {errors.trackingid && (
                                  <p className="errorValidate">{errors.trackingid.message}</p>
                                  )}
                              </div>

                              <div className="form-group col-lg-3">
                                  <label className="label-clr">
                                    Tracking Date
                                  </label>
                                  <input
                                    value={details.tracking_date || ""}
                                    name="tracking_date"
                                    className="form-control"
                                    min={getTodaysDate()}
                                    type="date"
                                    {...register('tracking_date', { required: { value: true, message: "Tracking Date is required" }, onChange: (e) => {handleChange(e)} })}
                                  />
                                  {errors.tracking_date && (
                                  <p className="errorValidate">{errors.tracking_date.message}</p>
                                  )}
                                </div>

                        </div>

                        <div className="row">
                          
                        <div className="form-group col-lg-12">
                        <label className="list-group-item-heading label-clr" style={{fontSize: "13px"}}>
                                   Note
                                  </label>
                                  <div className="form-outline">
                                    <textarea
                                      className="form-control txtareabb"
                                      id="textArea"
                                      name="note"
                                      value={details.note || ""}
                                      maxLength="100"
                                      {...register('note', { required: { value: false}, onChange: (e) => {handleChange(e)} })}
                                      rows="2"></textarea>
                                  </div>
                                  {errors.note && (
                                    <span className="errorValidate">This field is required</span>
                                  )}
                        </div>

                        </div>
                  </div>
              </div>
        </div>
        </Modal.Body>
        <Modal.Footer>
         
           <div className="text-right">
           { addTracking.isLoading ? (
             <>
                <span className="btn">
                  <i className="fa fa-paper-plane" style={{color: "#5ac557"}}></i>
                  &nbsp; Saving...
                  <span className="lds-facebook"><span></span><span></span><span></span></span>
                </span>
                &nbsp; &nbsp; &nbsp; &nbsp;
                </>
                    ) : (
                <button type="button"
                  className="btn btn-success btn-sm"
                  
                  data-appid={appID}
                  onClick={handleTracker}
                  >
                  <i className="fa fa-check-square-o"></i> Add Tracker
                </button>
            )}
              <button
                type="button"
                className="btn btn-default"
                onClick={handleTrackerClose}>
                <i className="fa fa-trash"></i>  Close
              </button>
          </div>
        </Modal.Footer>
      </Modal>


      <Modal show={noteopen} onHide={handleNoteClose} animation={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        >
           <div className="color-line"></div>
        <Modal.Header className="modal-header text-center" style={{padding: "10px 10px"}}>
          <Modal.Title>Add Note</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{padding: "5px"}}>
         <div className="panel-body no-padding">
                    <div>
                      <ReactQuill
                        theme="snow"
                        modules={modules}
                        formats={formats}
                        placeholder="compose here"
                        value={convertedTextNote}
                        onChange={setConvertedTextNote}
                        style={{ minHeight: "300px" }}
                      />
                      </div>
                     </div>
        </Modal.Body>
        <Modal.Footer>
         
           <div className="text-right">
           {sendNote.isLoading ? (
             <>
                <span className="btn">
                  <i className="fa fa-paper-plane" style={{color: "#5ac557"}}></i>
                  &nbsp; Saving Note...
                  <span className="lds-facebook"><span></span><span></span><span></span></span>
                </span>
                &nbsp; &nbsp; &nbsp; &nbsp;
                </>
                    ) : (
                <button type="button"
                  className="btn btn-success btn-sm"
                  
                  data-clientid={appID}
                  onClick={handleNote}
                  >
                  <i className="fa fa-pencil-square-o"></i> Save Note
                </button>
            )}
              <button
                type="button"
                className="btn btn-default"
                onClick={handleNoteClose}>
                <i className="fa fa-trash"></i>  Discard
              </button>
          </div>
        </Modal.Footer>
      </Modal>



      {/* ==================================================================================================================== */}

      <Modal show={invoiceopen} onHide={handleInvoiceClose} animation={false}
        /* size="lg" */
        dialogClassName="modal-85w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        >
           <div className="color-line"></div>
        <Modal.Header className="modal-header text-center" style={{padding: "10px 10px"}}>
          <Modal.Title>{`${InvoiceType.current} Invoice`}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{padding: "5px"}}>
         
        <div className="panel-body no-padding">
        <div className="row">
                  <div className="col-lg-12" style={{height:"300px", overflowY:"scroll"}}>
                  
                  <div className="row" style={{marginTop:"10px"}}>
                     <div className="form-group col-lg-6 text-center">
                      <label className="label-clr">
                         ITEM(s)
                       </label>
                     </div>

                     <div className="form-group col-lg-2 text-center">
                      <label className="label-clr">
                         QUANTITY
                       </label>
                     </div>

                     <div className="form-group col-lg-2 text-center">
                      <label className="label-clr">
                         UNIT PRICE
                       </label>
                     </div>

                     <div className="form-group col-lg-2 text-center">
                      <label className="label-clr">
                         SUB TOTAL
                       </label>
                     </div>
                  </div>

                 { (InvoiceType.current === "New" || InvoiceType.current === "Edit") &&

                  invoiceItems.map((x, i) => {
                   
                   return (
                    <Fragment key={i}>
                  <div className="row justify-content-center" key={i}>
                     <div className="form-group col-lg-12">
                        <div className="row">
                        <div className="form-group col-lg-1" style={{alignItems: 'center', display: 'flex', justifyContent: 'center'}}>
                              <label className="label-clr">
                              {i + 1}.
                              </label>
                        </div>
                        <div className="form-group col-lg-5">
                              {/* <label className="label-clr">
                              {i + 1}. Item
                              </label> */}
                              <select
                                name={`billingitem_${i}`}
                                disabled={x[`cap_${i}`]}
                               {...registerIn(`billingitem_${i}`, { 
                                    required: { value: x[`billingitem_${i}`] !== "" ? false : true, message: "This field is required" }, 
                                    onChange: (e) => {handleInvoiceItemChange(e,i)} })}
                                    value={x[`billingitem_${i}`] || ""}
                                className="form-select m-b-3"
                                style={{ width: "100%" }}>
                                <option value=''>select Billing Item</option>
                               
                                {allbills?.map(bill => 
                                (
                                  <option key={bill.id} data-vat={bill.VAT_APPLICABLE} value={bill.id}>{bill.DESCRIPTION}</option>
                                ))}

                                {/* <option value="1" data-vat="1">Visa fee</option>
                                <option value="2" data-vat="0">Logistics</option>
                                <option value="3" data-vat="1">Plane Yicket</option>
                                <option value="3" data-vat="0">Hotel Booking</option> */}
                              </select>
                              {errorsIn[`billingitem_${i}`] && 
                                                <p className="errorValidate">{errorsIn[`billingitem_${i}`].message}</p>
                                          }
                            </div>

                            <div className="form-group col-lg-2" style={{textAlign: 'center'}}>
                             {/*  <label className="label-clr">
                                Quantity
                              </label> */}
                              <input
                                  type="number"
                                  readOnly={x[`cap_${i}`]}
                                  {...registerIn(`quantity_${i}`, { 
                                    required: { value: x[`quantity_${i}`] !== "" ? false : true, message: "This field is required" }, 
                                   /*  pattern: {value: /^[\d]+$/i, message: "Invalid Quantity"}, */
                                    onChange: (e) => {handleInvoiceItemChange(e,i)} })}
                                    value={x[`quantity_${i}`] || ""}
                                  className="form-control invoiceinputs"
                                  name={`quantity_${i}`}
                                />
                              {errorsIn[`quantity_${i}`] && 
                                                <p className="errorValidate">{errorsIn[`quantity_${i}`].message}</p>
                                          }
                            </div>

                            <div className="form-group col-lg-2" style={{textAlign: 'center'}}>
                                {/* <label className="label-clr">
                                 Amount
                                </label> */}
                                <input
                                  type="number"
                                  data-amtt="amount"
                                  readOnly={x[`cap_${i}`]}
                                  {...registerIn(`amount_${i}`, { 
                                    required: { value: x[`amount_${i}`] !== "" ? false : true, message: "This field is required" }, 
                                    /* pattern: {value: /^(\d*\.)?\d+$/i, message: "Invalid Amount"}, */
                                    onChange: (e) => {handleInvoiceItemChange(e,i)} })}
                                    value={x[`amount_${i}`] || ""}
                                  className="form-control invoiceinputs"
                                  name={`amount_${i}`}
                                />
                              {errorsIn[`amount_${i}`] && 
                                                <p className="errorValidate">{errorsIn[`amount_${i}`].message}</p>
                                          }
                              </div>

                              <div className="form-group col-lg-2" style={{textAlign: 'center'}}>
                              <input
                                  type="number"
                                  readOnly={true}
                                  {...registerIn(`subtotal_${i}`, { 
                                    required: { value: false}, 
                                    })}
                                  value={x[`subtotal_${i}`] || ""}
                                  className="form-control invoiceinputs"
                                  name={`subtotal_${i}`}
                                />
                                </div>
                              </div>

                              <div className="row">
                              <div className="col-md-1 form-group"></div>
                                      <div
                                        className="col-md-5 form-group">
                                          <span className="addRemovebtn">
                                          {i !== 0 && (
                                            <button
                                            disabled={x[`cap_${i}`]}
                                              className="mr10 delbtn"
                                              onClick={() => handleRemoveInvoiceItem(i)}>
                                              <i className="fa fa-trash-o"></i> Remove Billing Item
                                            </button>
                                          )}
                                          <button
                                            className="addbtn"
                                            onClick={() => handleAddInvoiceItem(i)}>
                                            <i className="fa fa-plus"></i> Add Billing Item
                                          </button>
                                          </span>
                                      </div>
                                      </div>

                              </div>
                              
                           </div>
                           <hr className="slash-3"/>
                           </Fragment>
                       );
                     })
                    
                    }

{ InvoiceType.current === "Update"  &&

invoiceItems.map((x, i) => {
 
 return (
  <Fragment key={i}>
<div className="row justify-content-center" key={i}>
   <div className="form-group col-lg-12">
      <div className="row">
      <div className="form-group col-lg-1" style={{alignItems: 'center', display: 'flex', justifyContent: 'center'}}>
            <label className="label-clr">
            {i + 1}.
            </label>
      </div>
      <div className="form-group col-lg-5">
            {/* <label className="label-clr">
            {i + 1}. Item
            </label> */}
            <select
              name={`billingitem_${i}`}
              disabled={x[`cap_${i}`]}
             {...registerIn(`billingitem_${i}`, { 
                  required: { value: x[`billingitem_${i}`] !== "" ? false : true, message: "This field is required" }, 
                  onChange: (e) => {handleInvoiceItemChange(e,i)} })}
                  value={x[`billingitem_${i}`] || ""}
              className="form-select m-b-3"
              style={{ width: "100%" }}>
              <option value=''>select Billing Item</option>
             
              {allbills?.map(bill => 
              (
                <option key={bill.id} data-vat={bill.VAT_APPLICABLE} value={bill.id}>{bill.DESCRIPTION}</option>
              ))}

              {/* <option value="1" data-vat="1">Visa fee</option>
              <option value="2" data-vat="0">Logistics</option>
              <option value="3" data-vat="1">Plane Yicket</option>
              <option value="3" data-vat="0">Hotel Booking</option> */}
            </select>
            {errorsIn[`billingitem_${i}`] && 
                              <p className="errorValidate">{errorsIn[`billingitem_${i}`].message}</p>
                        }
          </div>

          <div className="form-group col-lg-2" style={{textAlign: 'center'}}>
           {/*  <label className="label-clr">
              Quantity
            </label> */}
            <input
                type="number"
                readOnly={x[`cap_${i}`]}
                {...registerIn(`quantity_${i}`, { 
                  required: { value: x[`quantity_${i}`] !== "" ? false : true, message: "This field is required" }, 
                 /*  pattern: {value: /^[\d]+$/i, message: "Invalid Quantity"}, */
                  onChange: (e) => {handleInvoiceItemChange(e,i)} })}
                  value={x[`quantity_${i}`] || ""}
                className="form-control invoiceinputs"
                name={`quantity_${i}`}
              />
            {errorsIn[`quantity_${i}`] && 
                              <p className="errorValidate">{errorsIn[`quantity_${i}`].message}</p>
                        }
          </div>

          <div className="form-group col-lg-2" style={{textAlign: 'center'}}>
              {/* <label className="label-clr">
               Amount
              </label> */}
              <input
                type="number"
                data-amtt="amount"
                readOnly={x[`cap_${i}`]}
                {...registerIn(`amount_${i}`, { 
                  required: { value: x[`amount_${i}`] !== "" ? false : true, message: "This field is required" }, 
                  /* pattern: {value: /^(\d*\.)?\d+$/i, message: "Invalid Amount"}, */
                  onChange: (e) => {handleInvoiceItemChange(e,i)} })}
                  value={x[`amount_${i}`] || ""}
                className="form-control invoiceinputs"
                name={`amount_${i}`}
              />
            {errorsIn[`amount_${i}`] && 
                              <p className="errorValidate">{errorsIn[`amount_${i}`].message}</p>
                        }
            </div>
            <div className="form-group col-lg-2" style={{textAlign: 'center'}}>
                              <input
                                  type="number"
                                  readOnly={true}
                                  {...registerIn(`subtotal_${i}`, { 
                                    required: { value: false}, 
                                    })}
                                  value={x[`subtotal_${i}`] || ""}
                                  className="form-control invoiceinputs"
                                  name={`subtotal_${i}`}
                                />
                                </div>
            </div>

            <div className="row">
            <div className="col-md-1 form-group"></div>
                    <div
                      className="col-md-5 form-group">
                        <span className="addRemovebtn">
                        {i !== 0 && (
                          <button
                            className="mr10 delbtn"
                            disabled={x[`cap_${i}`]}
                            onClick={() => handleRemoveInvoiceItem(i)}>
                            <i className="fa fa-trash-o"></i> Remove Billing Item
                          </button>
                        )}
                        <button
                          className="addbtn"
                          onClick={() => handleAddInvoiceItem(i)}>
                          <i className="fa fa-plus"></i> Add Billing Item
                        </button>
                        </span>
                    </div>
                    </div>

            </div>
            
         </div>
         <hr className="slash-3"/>
         </Fragment>
     );
   })
  
  }
                       
                  </div>
              </div>
        </div>
        </Modal.Body>
        <Modal.Footer>
         { InvoiceType.current === "New" &&
           <div className="text-right">
           { genInvoice.isLoading ? (
             <>
                <span className="btn">
                  <i className="fa fa-paper-plane" style={{color: "#5ac557"}}></i>
                  &nbsp; Saving...
                  <span className="lds-facebook"><span></span><span></span><span></span></span>
                </span>
                &nbsp; &nbsp; &nbsp; &nbsp;
                </>
                    ) : (
                <button type="button"
                  className="btn btn-success btn-sm"
                  
                  data-appid={appID}
                  onClick={handleInvoice}
                  >
                  <i className="fa fa-check-square-o"></i> Generate Invoice
                </button>
            )}
              <button
                type="button"
                className="btn btn-default"
                onClick={handleInvoiceClose}>
                <i className="fa fa-trash"></i>  Close
              </button>
          </div>
        }

      { InvoiceType.current === "Edit" &&
           <div className="text-right">
           { editInvoice.isLoading ? (
             <>
                <span className="btn">
                  <i className="fa fa-paper-plane" style={{color: "#5ac557"}}></i>
                  &nbsp; Saving...
                  <span className="lds-facebook"><span></span><span></span><span></span></span>
                </span>
                &nbsp; &nbsp; &nbsp; &nbsp;
                </>
                    ) : (
                <button type="button"
                  className="btn btn-success btn-sm"
                  
                  data-appid={appID}
                  onClick={handleEditInvoice}
                  >
                  <i className="fa fa-check-square-o"></i> Apply Changes
                </button>
            )}
              <button
                type="button"
                className="btn btn-default"
                onClick={handleInvoiceClose}>
                <i className="fa fa-trash"></i>  Close
              </button>
          </div>
        }

       { InvoiceType.current === "Update" &&
           <div className="text-right">
           { updateInvoice.isLoading ? (
             <>
                <span className="btn">
                  <i className="fa fa-paper-plane" style={{color: "#5ac557"}}></i>
                  &nbsp; Saving...
                  <span className="lds-facebook"><span></span><span></span><span></span></span>
                </span>
                &nbsp; &nbsp; &nbsp; &nbsp;
                </>
                    ) : (
                <button type="button"
                  className="btn btn-success btn-sm"
                  
                  data-appid={appID}
                  onClick={handleUpdateInvoice}
                  >
                  <i className="fa fa-check-square-o"></i> Update Invoice
                </button>
            )}
              <button
                type="button"
                className="btn btn-default"
                onClick={handleInvoiceClose}>
                <i className="fa fa-trash"></i>  Close
              </button>
          </div>
        }
        </Modal.Footer>
      </Modal>

{/* =================================================Make Payment=================================================================== */}


       <Modal show={paymentopen} onHide={handlePaymentClose} animation={false}
        dialogClassName="modal-85w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        >
           <div className="color-line"></div>
        <Modal.Header className="modal-header text-center" style={{padding: "10px 10px"}}>
          <Modal.Title>Update Payment Status</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{padding: "5px"}}>
        <div className="panel-body no-padding">
        <div className="row">
            <div className="col-lg-12">

            <div className="panel-body">
            <div className="row">
                        <div className="col-md-2">
                            <h4>Invoice # <small>{invoicedetail?.INVOICE_NUMBER}</small></h4>
                        </div>

                        <div className="col-md-8">
                            <div className="text-center">
                            <h4>{invoicedetail?.first_name ?? ""} {invoicedetail?.middle_name ?? ""} {invoicedetail?.last_name ?? ""}</h4>
                            </div>

                        </div>

                        <div className="col-md-2">
                            <div className="text-right">
                                <button className={`btn ${invoicedetail?.PAYMENT_STATUS === null ? `btn-default` : `btn-primary`} btn-sm`}><i className="fa fa-tags"></i> {invoicedetail?.PAYMENT_STATUS === null ? " UNPAID" : " PAID"}</button>
                            </div>

                        </div>
                    </div>
                </div>
            
            <div className="row" style={{marginTop:"10px"}}>
                     <div className="form-group col-lg-2 text-center">
                      <label className="label-clr">
                         ITEM(s)
                       </label>
                     </div>

                     <div className="form-group col-lg-2 text-center">
                      <label className="label-clr">
                         QUANTITY
                       </label>
                     </div>

                     <div className="form-group col-lg-2 text-center">
                      <label className="label-clr">
                         UNIT PRICE
                       </label>
                     </div>

                     <div className="form-group col-lg-2 text-center">
                      <label className="label-clr">
                         VAT
                       </label>
                     </div>

                     <div className="form-group col-lg-2 text-center">
                      <label className="label-clr">
                         SUBTOTAL
                       </label>
                     </div>

                     <div className="form-group col-lg-2 text-center">
                      <label className="label-clr">
                         PAYMENT STATUS :
                       </label>
                     </div>
                  </div>

            
            {  invoiceItems.map((x, i) => {
 
                return (
                  <Fragment key={i}>
                  
                  <div className={`row ${ x[`paid_${i}`] === "1" ? `invoiceRowpaid` : `invoiceRow`}`}>
                     <div className="form-group col-lg-2 text-center" style={{marginBottom: '7px', marginTop: '7px'}}>
                       <span>
                       {allbills.find(bills => bills.id == x[`billingitem_${i}`])?.DESCRIPTION}
                       </span>
                     </div>

                     <div className="form-group col-lg-2 text-center">
                       <span>
                       {x[`quantity_${i}`] || ""}
                       </span>
                     </div>

                     <div className="form-group col-lg-2 text-center">
                       <span>
                       {x[`amount_${i}`] || ""}
                       </span>
                     </div>

                     <div className="form-group col-lg-2 text-center">
                       <span>
                       {x[`vat_${i}`] === "1" ? roundToTwo(parseFloat(x[`amount_${i}`] || 0)* parseInt(x[`quantity_${i}`] || 0)*(parseFloat(invoicedetail?.VAT_RATE)/100)) : 0.00 }
                       </span>
                     </div>

                     <div className="form-group col-lg-2 text-center">
                       <span>
                       {x[`vat_${i}`] === "1" ?  roundToTwo(parseInt(x[`quantity_${i}`]) * parseFloat(x[`amount_${i}`]) + parseInt(x[`quantity_${i}`]) * parseFloat(x[`amount_${i}`])*(parseFloat(invoicedetail?.VAT_RATE)/100)) : roundToTwo(parseInt(x[`quantity_${i}`]) * parseFloat(x[`amount_${i}`]))} 
                       </span>
                     </div>
                    
                     <div className="form-group col-lg-2 text-center">
                       <div className="checkbox checkbox-success checkbox-inline">
                          <input id="checkbox1" type="checkbox" 
                          checked={x[`paid_${i}`] === "1" ? true : false} 
                          {...registerPay(`paid_${i}`, { 
                           required: { value: false}, 
                           onChange: (e) => {handleInvoiceItemPayChange(e,i)} })}
                           value={x[`paid_${i}`] || ""}
                         className="form-control checkbox-primary,"
                         name={`paid_${i}`}
                         data-subtotal={x[`vat_${i}`] === "1" ?  roundToTwo(parseInt(x[`quantity_${i}`]) * parseFloat(x[`amount_${i}`]) + parseInt(x[`quantity_${i}`]) * parseFloat(x[`amount_${i}`])*(parseFloat(invoicedetail?.VAT_RATE)/100)) : roundToTwo(parseInt(x[`quantity_${i}`]) * parseFloat(x[`amount_${i}`]))} 
                          />
                          <label htmlFor="checkbox1">
                              Paid
                          </label>
                        </div>
                      
                     </div>
                  </div>

                  <hr className="slash-3"/>
         </Fragment>
     );
   })
  
  }

   <div className="row" style={{marginTop:"10px"}}>
          <div className="form-group col-lg-2 text-center"></div>
          <div className="form-group col-lg-2 text-center"></div>
          <div className="form-group col-lg-2 text-center"></div>
          <div className="form-group col-lg-2 text-center totallsmetric"><strong>Total Taxes</strong></div>
          <div className="form-group col-lg-2 text-center totallsmetricval"><span className="totalls">	&#163; {roundToTwo(totalTax.current)}</span></div>  
          <div className="form-group col-lg-2 text-center"></div>            
   </div>

   <div className="row" style={{marginTop:"10px"}}>
          <div className="form-group col-lg-2 text-center"></div>
          <div className="form-group col-lg-2 text-center"></div>
          <div className="form-group col-lg-2 text-center"></div>
          <div className="form-group col-lg-2 text-center totallsmetric"><strong>Total Cost</strong></div>
          <div className="form-group col-lg-2 text-center totallsmetricval"><span className="totalls">	&#163; {roundToTwo(totalCost.current)}</span></div>   
          <div className="form-group col-lg-2 text-center"></div>           
   </div>

   <div className="row" style={{marginTop:"10px"}}>
          <div className="form-group col-lg-2 text-center"></div>
          <div className="form-group col-lg-2 text-center"></div>
          <div className="form-group col-lg-2 text-center"></div>
          <div className="form-group col-lg-2 text-center totallsmetric"><strong>Paid Amount</strong></div>
          <div className="form-group col-lg-2 text-center totallsmetricval"><span className="totallpaid">	&#163; {roundToTwo(paidAmount.current)}</span></div>    
          <div className="form-group col-lg-2 text-center"></div>          
   </div>

   <div className="row" style={{marginTop:"10px"}}>
          <div className="form-group col-lg-2 text-center"></div>
          <div className="form-group col-lg-2 text-center"></div>
          <div className="form-group col-lg-2 text-center"></div>
          <div className="form-group col-lg-2 text-center totallsmetric"><strong>Out Standing balance</strong></div>
          <div className="form-group col-lg-2 text-center totallsmetricval"><span className="outstanding">	&#163; {roundToTwo(balance.current)}</span></div>    
          <div className="form-group col-lg-2 text-center"></div>          
   </div>

              </div>
            </div> 
        </div>            


        </Modal.Body>
        <Modal.Footer>
         
           <div className="text-right">
           {payInvoice.isLoading ? (
             <>
                <span className="btn">
                  <i className="fa fa-paper-plane" style={{color: "#5ac557"}}></i>
                  &nbsp; Applying Payments...
                  <span className="lds-facebook"><span></span><span></span><span></span></span>
                </span>
                &nbsp; &nbsp; &nbsp; &nbsp;
                </>
                    ) : (
                <button type="button"
                  className="btn btn-success btn-sm"
                  
                  data-appid={appID}
                  onClick={handlePayment}
                  >
                  <i className="fa fa-pencil-square-o"></i> Save Payments
                </button>
            )}
              <button
                type="button"
                className="btn btn-default"
                onClick={handlePaymentClose}>
                <i className="fa fa-trash"></i>  Discard
              </button>
          </div>
        </Modal.Footer>
      </Modal>

{/* =================================================View Invoice=================================================================== */}


<Modal show={invoiceviewopen} onHide={handleInvoiceViewClose} animation={false}
        dialogClassName="modal-85w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        >
           <div className="color-line"></div>
        <Modal.Header className="modal-header text-center" style={{padding: "10px 10px"}}>
          <Modal.Title>Invoice Status</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{padding: "5px"}}>
        <div className="panel-body no-padding">
        <div className="row">
            <div className="col-lg-12">

            <div className="panel-body">
                    <div className="row">
                        <div className="col-md-2">
                            <h4>Invoice # <small>{invoicedetail?.INVOICE_NUMBER}</small></h4>
                        </div>

                        <div className="col-md-8">
                            <div className="text-center">
                            <h4>{invoicedetail?.first_name ?? ""} {invoicedetail?.middle_name ?? ""} {invoicedetail?.last_name ?? ""}</h4>
                            </div>

                        </div>

                        <div className="col-md-2">
                            <div className="text-right">
                                <button className={`btn ${invoicedetail?.PAYMENT_STATUS === null ? `btn-default` : `btn-primary`} btn-sm`}><i className="fa fa-tags"></i> {invoicedetail?.PAYMENT_STATUS === null ? " UNPAID" : " PAID"}</button>
                            </div>

                        </div>
                    </div>
                </div>
            
            <div className="row" style={{marginTop:"10px"}}>
                     <div className="form-group col-lg-2 text-center">
                      <label className="label-clr">
                         ITEM(s)
                       </label>
                     </div>

                     <div className="form-group col-lg-2 text-center">
                      <label className="label-clr">
                         QUANTITY
                       </label>
                     </div>

                     <div className="form-group col-lg-2 text-center">
                      <label className="label-clr">
                         UNIT PRICE
                       </label>
                     </div>

                     <div className="form-group col-lg-2 text-center">
                      <label className="label-clr">
                         VAT
                       </label>
                     </div>

                     <div className="form-group col-lg-2 text-center">
                      <label className="label-clr">
                         SUBTOTAL
                       </label>
                     </div>

                     <div className="form-group col-lg-2 text-center">
                      <label className="label-clr">
                         PAYMENT STATUS :
                       </label>
                     </div>
                  </div>

            
            {  invoiceItems.map((x, i) => {
 
                return (
                  <Fragment key={i}>
                  
                  <div className={`row ${ x[`paid_${i}`] === "1" ? `invoiceRowpaid` : `invoiceRow`}`}>
                     <div className="form-group col-lg-2 text-center" style={{marginBottom: '7px', marginTop: '7px'}}>
                       <span>
                       {allbills.find(bills => bills.id == x[`billingitem_${i}`])?.DESCRIPTION}
                       </span>
                     </div>

                     <div className="form-group col-lg-2 text-center">
                       <span>
                       {x[`quantity_${i}`] || ""}
                       </span>
                     </div>

                     <div className="form-group col-lg-2 text-center">
                       <span>
                       {x[`amount_${i}`] || ""}
                       </span>
                     </div>

                     <div className="form-group col-lg-2 text-center">
                       <span>
                       {x[`vat_${i}`] === "1" ? roundToTwo(parseFloat(x[`amount_${i}`] || 0)* parseInt(x[`quantity_${i}`] || 0)*(parseFloat(invoicedetail?.VAT_RATE || 0.00)/100)) : 0.00 }
                       </span>
                     </div>

                     <div className="form-group col-lg-2 text-center">
                       <span>
                       {x[`vat_${i}`] === "1" ?  roundToTwo(parseInt(x[`quantity_${i}`]) * parseFloat(x[`amount_${i}`]) + parseInt(x[`quantity_${i}`]) * parseFloat(x[`amount_${i}`])*(parseFloat(invoicedetail?.VAT_RATE || 0.00)/100)) : roundToTwo(parseInt(x[`quantity_${i}`]) * parseFloat(x[`amount_${i}`]))} 
                       </span>
                     </div>
                    
                     <div className="form-group col-lg-2 text-center">
                       {x[`paid_${i}`] === "1" ? <span style={{color:"#30a530"}}><strong>PAID</strong></span> : "UNPAID"} 
                     </div>
                  </div>

                  <hr className="slash-3"/>
         </Fragment>
     );
   })
  
  }

   <div className="row" style={{marginTop:"10px"}}>
          <div className="form-group col-lg-2 text-center"></div>
          <div className="form-group col-lg-2 text-center"></div>
          <div className="form-group col-lg-2 text-center"></div>
          <div className="form-group col-lg-2 text-center totallsmetric"><strong>Total Taxes</strong></div>
          <div className="form-group col-lg-2 text-center totallsmetricval"><span className="totalls">	&#163; {roundToTwo(totalTax.current)}</span></div>  
          <div className="form-group col-lg-2 text-center"></div>            
   </div>

   <div className="row" style={{marginTop:"10px"}}>
          <div className="form-group col-lg-2 text-center"></div>
          <div className="form-group col-lg-2 text-center"></div>
          <div className="form-group col-lg-2 text-center"></div>
          <div className="form-group col-lg-2 text-center totallsmetric"><strong>Total Cost</strong></div>
          <div className="form-group col-lg-2 text-center totallsmetricval"><span className="totalls">	&#163; {roundToTwo(totalCost.current)}</span></div>   
          <div className="form-group col-lg-2 text-center"></div>           
   </div>

   <div className="row" style={{marginTop:"10px"}}>
          <div className="form-group col-lg-2 text-center"></div>
          <div className="form-group col-lg-2 text-center"></div>
          <div className="form-group col-lg-2 text-center"></div>
          <div className="form-group col-lg-2 text-center totallsmetric"><strong>Paid Amount</strong></div>
          <div className="form-group col-lg-2 text-center totallsmetricval"><span className="totallpaid">	&#163; {roundToTwo(paidAmount.current)}</span></div>    
          <div className="form-group col-lg-2 text-center"></div>          
   </div>

   <div className="row" style={{marginTop:"10px"}}>
          <div className="form-group col-lg-2 text-center"></div>
          <div className="form-group col-lg-2 text-center"></div>
          <div className="form-group col-lg-2 text-center"></div>
          <div className="form-group col-lg-2 text-center totallsmetric"><strong>Outstanding Balance</strong></div>
          <div className="form-group col-lg-2 text-center totallsmetricval"><span className="outstanding">	&#163; {roundToTwo(balance.current)}</span></div>    
          <div className="form-group col-lg-2 text-center"></div>          
   </div>

              </div>
            </div> 
        </div>            


        </Modal.Body>
        <Modal.Footer>
         
           <div className="text-right">
              <button
                type="button"
                className="btn btn-default"
                onClick={handleInvoiceViewClose}>
                <i className="fa fa-trash"></i>  Discard
              </button>
          </div>
        </Modal.Footer>
      </Modal>



        
        </>
    )

}

export default ClientApplications;