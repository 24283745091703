import React, { useEffect , useState} from "react";
import * as api from './Utils/apiCall';
import { useQuery, useMutation } from "react-query";
import toast, { Toaster } from 'react-hot-toast';
import { getUser, removeUserSession, sleep } from "./Utils/Common";
import {
  Outlet,
  useMatch,
  useResolvedPath,
  useLocation,
  useNavigate,
  Link
} from "react-router-dom";
import "./styles/style.css";
import Logo from "./images/logos_fit.png";
import "./Dashboard.css";

const Dashboard = () => {

const navigate = useNavigate();

const location = useLocation();

  const [userData, setUserData] = useState({});
  const [msgCount, setMsgCount] = useState(0);
  useEffect(() => {

    document
      .getElementById("menuToggle")
      .addEventListener("click", toggleMenux);
    //document.getElementById("toglepanel").addEventListener("click", togglePanel);

    console.log("Dash: location state:",location.pathname)

    function checkUserData() {
      const item = getUser();
  
      if (item) {
      //  console.log("user item",item);
        setUserData(item);

        setUserData((userData) => {
        //  console.log("updated details", userData);
          return userData;
        });

      }
    }
    checkUserData();

    window.addEventListener('storage', checkUserData)
  
    return () => {
      window.removeEventListener('storage', checkUserData)
    }
    
  }, []);

  /* const clientApplications = useQuery(['clientapplications', userData?.id], async () => {return await api.getClientApplications(userData?.id)}, 
  {onSuccess: (res) => {
      const result = {
        status: res.status + "-" + res.statusText,
        headers: res.headers,
        data: res.data,
      }
      console.log("applications:: ",result);
    }
  }); */
  
  const clientMessages = useQuery(['clientmessages', userData?.id], async () => {return await api.getCLientMessages(userData?.id)},
  
  {
    refetchInterval:30000,
    refetchIntervalInBackground: true,
    onSuccess: (data) => {

    console.log("Dashboard:: ",data);

        if(data?.filter(dmsg => dmsg.MESSAGE_STATUS == "unread").length > 0 && data?.filter(dmsg => dmsg.MESSAGE_STATUS == "unread").length !== msgCount) {
          toast.success('You have received a new Message!',{
            duration: 6000,
          });

          setMsgCount(data?.filter(dmsg => dmsg.MESSAGE_STATUS == "unread").length);
        }
      
  },
  onError: (error) => {

    console.log("Error Message",error); 

  if(error?.response?.status === 401){
    toast.error("Session Expired : Please Re-Login",{
      duration: 6000,
      });
   removeUserSession();
    navigate("/", {replace: false});
  }
  else {
    toast.error("Something went wrong",{
      duration: 6000,
      });
  }

  }
});
   
  ///const { loading: loadingapp, data: dataapp } = clientApplications;
  const { loading: loadingmsg, data: datamsg } = clientMessages;

  const CheckActiveLink = (to) => {
    let resolved = useResolvedPath(to);
    let match = useMatch({ path: resolved.pathname, end: true });
   // console.log("link match ? ", match);
    return match;
  };

  const logOut = (e)=> {
    e.preventDefault();
    logOutMutation.mutate({"id":userData.id});
  }

  const toggleMenux = () => {
   
    if (window.innerWidth < 769) {
      //$("body").toggleClass("show-sidebar");
      document.body.classList.toggle("show-sidebar");
  //    console.log("window.innerWidth is less");
    } else {
      //$("body").toggleClass("hide-sidebar");
      document.body.classList.toggle("hide-sidebar");
   //   console.log("window.innerWidth is greater");
    }
  };

  const logOutMutation = useMutation(api.logout, {
    onSuccess: (data) => {
      console.log("onSuccess data",data);
      toast.success('Logged Out Successfully',{
        duration: 6000,
      });
      
      sleep(1000).then(() => {
      removeUserSession();
      navigate("/", {replace: true});
      });
      
    },
    onError: (error) => {

      console.log("Error Message",error); 
  
    if(error?.response?.status === 401){
      toast.error("Session Expired : Please Re-Login",{
        duration: 6000,
        });
     removeUserSession();
      navigate("/", {replace: true});
    }
    else {
      toast.error("Something went wrong",{
        duration: 6000,
        });
    }

    }
  });

  ////////Company Information :////////////
  const [companyDetails, setCompanyDetails] = useState({});

  const getCompanyInfoNav = useQuery("get_companyinfo", api.showCompany, {
    enabled: true,
    refetchOnMount:true,
    refetchOnWindowFocus:true,
    onSuccess: (data) => {
      console.log("users data:::", data);

      const apd = {...data?.Info[0]};
      apd.URL = data?.URL;
      console.log("@@@@@@@@@@@@@@@@@@", apd)
      setCompanyDetails(apd);
      setCompanyDetails(companyDetails => {
        console.log("companyDetails ===>>>",companyDetails);
        return companyDetails;
      })
    },
    onError: (error) => {
      
        console.log("Error Message ::: ",error); 
      
        if(error?.response?.status === 401){
          toast.error("Session Expired : Please Re-Login",{
            duration: 6000,
            });
         removeUserSession();
          navigate("/", {replace: false});
        }
        else {
          toast.error("Something went wrong",{
            duration: 6000,
            });
        }
    }
  });
  const {isLoading, data : companyData} = getCompanyInfoNav;


  //////////////////////////////////////////

  return (
    <>
    <Toaster />
      <div id="header">
        <div className="color-line"></div>
        <div id="logo" className="light-version">
        <span>
        {companyDetails && companyDetails?.COMPANY_NAME ? 
          (
            <span>{companyDetails?.COMPANY_NAME}</span>
          ):(
            <>
            <i>i</i>-visas
            </>
          )}
        </span>
        </div>
        <nav role="navigation">
          <div className="header-link hide-menu" id="menuToggle">
            <i className="fa fa-bars"></i>
          </div>
          <div className="small-logo">
            <span className="text-primary">
            {companyDetails && companyDetails?.COMPANY_NAME ? 
              (
                <span>{companyDetails?.COMPANY_NAME}</span>
              ):(
                <>
                <i>i</i>-visas
                </>
              )}
            </span>
          </div>

          <div className="mobile-menu">
            <button
              type="button"
              className="navbar-toggle mobile-menu-toggle"
              data-toggle="collapse"
              data-target="#mobile-collapse">
              <i className="fa fa-chevron-down"></i>
            </button>
            <div className="collapse mobile-navbar" id="mobile-collapse">
              <ul className="nav navbar-nav">
                <li>
                  <a className="" href={void(0)}>
                    Logout
                  </a>
                </li>
                <li>
                  <a className="" href={void(0)}>
                    Profile
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="navbar-right">
            <ul className="nav navbar-nav no-borders">
              <li className="dropdown naver-link">
                <a className="dropdown-toggle" href={void(0)} data-toggle="dropdown">
                  <i className="pe-7s-comment"></i>
                </a>
                <ul className="dropdown-menu hdropdown notification animated flipInX">

                {!!datamsg && (Object.keys(datamsg.filter(dmsg => dmsg.MESSAGE_STATUS == "unread")).length > 0) ? 
                                    
                                    datamsg.slice(0, 3).map((item,i) =>{
                                                 return (
                                                  <li key={i}>
                                                  <a href={void(0)}>
                                                    <span className="label label-success">NEW</span> {item.MESSAGE_SUBJECT}
                                                  </a>
                                                </li>
                                                 )
                                             }) : datamsg?.filter(dmsg => dmsg.MESSAGE_STATUS == "unread").length > 0 ? (
                                              <li className="summary">
                                              <a href={void(0)}>See all notifications</a>
                                            </li>
                                             ): (
                                             <li><span className="summary">0 notifications</span></li>
                                            )}

                  {/* <li>
                    <a href={void(0)}>
                      <span className="label label-success">NEW</span> You have
                      a reply to your chat.
                    </a>
                  </li>
                  <li>
                    <a href={void(0)}>
                      <span className="label label-success">NEW</span>{" "}
                      Pre-Screening is Pending.
                    </a>
                  </li>
                  <li className="summary">
                    <a href={void(0)}>See all notifications</a>
                  </li> */}
                </ul>
              </li>
              <li className="dropdown naver-link">
                <a className="dropdown-toggle" href={void(0)} data-toggle="dropdown">
                  <i className="pe-7s-keypad"></i>
                </a>

                <div className="dropdown-menu hdropdown bigmenu animated flipInX">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <Link to="application_list">
                            <i className="pe pe-7s-portfolio text-info"></i>
                            <h5>Applications</h5>
                          </Link>
                        </td>
                        <td>
                          <Link to="messages">
                            <i className="pe pe-7s-mail text-warning"></i>
                            <h5>Messages</h5>
                          </Link>
                        </td>
                        {/* <td>
                          <Link to="# ">
                            <i className="pe pe-7s-chat text-info"></i>
                            <h5>Chat</h5>
                          </Link>
                        </td> */}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </li>
              <li className="dropdown naver-link">
                        <a
                          className="dropdown-toggle label-menu-corner"
                          href={void(0)}
                          data-toggle="dropdown">
                          <i className="pe-7s-mail"></i>
                          <span className="label label-success">{!!datamsg  && (Object.keys(datamsg.filter(dmsg => dmsg.MESSAGE_STATUS == "unread")).length > 0) ? Object.keys(datamsg.filter(dmsg => dmsg.MESSAGE_STATUS == "unread")).length : "0"}</span>
                        </a>
                        <ul className="dropdown-menu hdropdown animated flipInX">
                          <div className="title" style={{width: "max-content"}}>{!!datamsg  && (Object.keys(datamsg.filter(dmsg => dmsg.MESSAGE_STATUS == "unread")).length > 0) ? `You have ${datamsg.filter(dmsg => dmsg.MESSAGE_STATUS == "unread").length} new messages` : `You have no new messages` }</div>
                          {!!datamsg && (Object.keys(datamsg).length > 0) ? 
                            
                            datamsg.filter(dmsg => dmsg.MESSAGE_STATUS == "unread").slice(0, 5).map((item,i) =>{

                            let list = <li className="notifyLink" key={i}><Link to={`messages/?conversation_id=${item.CONVERSATION_ID}`}>{item.MESSAGE_SUBJECT}</Link></li>;

                          return list;
                            
                        }) : (
                        <li className="notifyLink"><span className="summary">0 notifications</span></li>
                      )}
                      <li className="summary"><Link to="messages">See all notifications</Link></li>
                        </ul>
                      </li>

              <li className="dropdown naver-link">
                <a
                  href={void(0)}
                  className="dropdown-toggle label-menu-corner"
                  data-toggle="dropdown">
                  <i className="pe-7s-user"></i>
                </a>
                <ul className="dropdown-menu hdropdown animated flipInX">
                  {/* <li>
                    <a href={void(0)}>Profile</a>
                  </li>
                  <li>
                    <a href={void(0)}>Preference</a>
                  </li> */}
                  <li>
                    <a href={void(0)} onClick={logOut}>Logout</a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </nav>
      </div>

      <aside id="menu">
        <div id="navigation">
          <div className="profile-picture">
            <a href={void(0)}>{/* companyDetails?.URL ??  */}
              <img src={companyDetails?.URL || Logo} className="img-circle" alt="logo" width="135px" height="57px" />
            </a>
          </div>

          <ul className="nav" id="side-menu">
            <li
              className={
                CheckActiveLink("overview") ? "active" : ""
              } /* className="active" */
            >
              <Link to="overview">
                <span className="nav-label">Overview</span>
              </Link>
            </li>
            <li className={CheckActiveLink("application_list") ? "active" : ""}>
              <Link to="application_list" /* style={{'pointerEvents': 'none'}} */>
                {" "}
                <span className="nav-label">Applications</span>
              </Link>
            </li>
            <li className={CheckActiveLink("application_tracking") ? "active" : ""}>
              <Link to="application_tracking" /* style={{'pointerEvents': 'none'}} */>
                {" "}
                <span className="nav-label">Tracking</span>
              </Link>
            </li>
            {/* <li className={CheckActiveLink("invoice") ? "active" : ""}>
              <Link to="invoice" >
                <span className="nav-label">Invoices</span>
              </Link>
            </li> */}
          </ul>
        </div>
      </aside>

      <div id="wrapper">
        <Outlet />

        <footer className="footer" style={{ maxHeight: "40px" }}>
          <div className="mt-4 mt-md-5 text-center" style={{ marginTop: "0" }}>
            <p className="footer-p">
              © <script>document.write(new Date().getFullYear())</script>{" "}
              i-visas.com
            </p>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Dashboard; ///withRouter(Dashboard);
