import React from 'react';
import { Navigate } from 'react-router-dom';
import { getAdminToken } from './Common';

// handle the public routes
function PublicRouteAdmin({ children }) {
  return  !getAdminToken() ? children : <Navigate to={{ pathname: '/admin_dashboard' }} replace />
 
}

export default PublicRouteAdmin;