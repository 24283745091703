import * as React from "react";

const authContext = React.createContext();

export function useAuth() {

  const [authed, setAuthed] = React.useState(false);

  return {
    authed,

    login (username,password) {
        return new Promise((res) => {
        let user_id = username;
        let user_password = password;
            if (user_id === "admin@admin.com" && user_password === "123") {
                localStorage.setItem("token", "T");
                setAuthed(true);

                setAuthed((authed) => {
                  console.log("authed ",authed);
                  return authed;
                });
                res({'authed':'success'});
            }
            else{
              setAuthed(false);
              setAuthed((authed) => {
                console.log("authed ",authed);
                return authed;
              });
              res({'authed':'failed'});
            }
            //return authed;
        
        });
    },

    logout() {
        return new Promise((res) => {
        localStorage.removeItem("token");
        setAuthed(false);
        res();
        });
    }
    /* login() {
      return new Promise((res) => {
        setAuthed(true);
        res();
      });
    },
    logout() {
      return new Promise((res) => {
        setAuthed(false);
        res();
      });
    } */
  };
}

export function AuthProvider({ children }) {
  const auth = useAuth();

  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  );
}

export default function AuthConsumer() {
  return React.useContext(authContext);
}