import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { getToken } from './Common';

// handle the private routes
function PrivateRoute({ children }) {
	
  let location = useLocation()
  
  return getToken() ? children : <Navigate to="/" state={{ from: location }} />
    
}

export default PrivateRoute;