import React, { useState, useEffect, useRef } from "react";
import { useQuery, useMutation } from "react-query";
import { useForm } from "react-hook-form";
import { getAdminUser, removeUserAdminSession, useDebounce, sleep} from "../Utils/Common";
import * as api from '../Utils/adminApiCalls';
import "./AdminDashboard.css";
import {
    Link
  } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import AdminNav from "./Admin_nav";
import toast, { Toaster } from 'react-hot-toast';
import { Modal } from 'react-bootstrap';

const pageLimit = 5;
const dataLimit = 10;


const Tracking = () => {

  const navigate = useNavigate();

  const {
    register,
    trigger,
    reset,
    formState: { errors },
  } = useForm({mode: "onBlur"});

  const {
    register : register2,
    trigger : trigger2,
    reset : reset2,
    formState: { errors : errors2 },
  } = useForm({mode: "onBlur"});

    const [userData, setUserData] = useState({});
    const [courierid, setcourierid] = useState("");
    
    const[allcourier, setallcourier]= useState([]);

    const [details, setDetails] = useState({
      TRACK_CODE:"",
      TRACK_URL:"",
      STATUS:""
    });

    useEffect(() => {
  
      function checkUserData() {
        const item = getAdminUser();
    
        if (item) {
          console.log("user item",item);
          setUserData(item);
  
          setUserData((userData) => {
            console.log("updated details", userData);
            return userData;
          });
  
        }
      }
      checkUserData();
  
      window.addEventListener('storage', checkUserData)
    
      return () => {
        window.removeEventListener('storage', checkUserData)
      }
      
    }, []);


    const getCouriers = useQuery("couriers", api.getCourier, {
      onSuccess: (data) => {
        console.log("courier all data:::", data);
        setallcourier(data);
        setallcourier(allcourier => {
          return allcourier;
        })
      },
      onError: (error) => {
        
          console.log("Error Message ::: ",error); 
        
          if(error?.response?.status === 401){
            toast.error("Session Expired : Please Re-Login",{
              duration: 6000,
              });
           removeUserAdminSession();
            navigate("/admin_login", {replace: false});
          }
          else {
            toast.error("Something went wrong",{
              duration: 6000,
              });
          }
      }
    })


    const handleChange = (event) => {
      event.persist();
  
      setDetails((details) => ({
        ...details,
        [event.target.name]: event.target.value,
      }));
      console.log(details);
    };


    const [courierDetails, setcourierDetails] = useState([]);

    const handleEditChange = (event) => {
      event.persist();
     
      const usrdetails = [...courierDetails];
      usrdetails[0][event.target.name] = event.target.value;

      console.log("locdetails ::", usrdetails); 
      
      setcourierDetails(usrdetails);
      setcourierDetails((courierDetails) => {
        return courierDetails
      });

      console.log(courierDetails); 
    };
  
    
    const createdDate = (datestr, l=0)=> {

      if(l === 0){
      const start = moment(datestr).format("dddd, MMMM Do YYYY, h:mm:ss a");
      
      return start;
    }
    else if(l === 1){
      const start = moment(datestr).format("llll");

      return start;
    }
     
    };

    ///////////Modal view location //////
    const courierRef = useRef(false);
    const courieridRef = useRef();
const [viewcourieropen, setviewcourieropen] = useState(false);

const handleViewCourierShow = (e) => {
  setcourierid(e.target.getAttribute("data-courierid"));
  setviewcourieropen(true);
  
  const courierid = e.target.getAttribute("data-courierid");
  courieridRef.current = courierid;
   if(courierid) {
    courierRef.current = true;
    getcourierDetails.refetch();
   }
   else return null;
};

const handleViewCourierClose= (e) => {
  setviewcourieropen(false);
  courierRef.current = false;
};

////////////////

 ///////////Modal Edit location //////
 const [editcourieropen, seteditcourieropen] = useState(false);
 
 const handleEditCourierShow = (e) => {
   
   seteditcourieropen(true);

   const courieridd = e.target.getAttribute("data-courierid");
   courieridRef.current = courieridd;
   setcourierid(courieridd);

   setcourierid(courierid => { return courierid;})

   console.log("courier state", courierid, "courieridd ::",courieridd);

   if(courieridd) {
      courierRef.current = true;
      getcourierDetails.refetch();
   }
   else return null;
 };
 
 const handleEditCourierClose = (e) => {
   seteditcourieropen(false);
   courierRef.current = false;
 };
 

 const getcourierDetails = useQuery(['courierdetails', courieridRef.current], 
 () => api.getCourierbyID(courieridRef.current),
 { enabled: courierRef.current,//"courierDetails", api.getCourierbyID, {

  onSuccess: (data) => {
      console.log("user details : ",data);
     
      setcourierDetails(data);
      setcourierDetails((courierDetails) => {
       return courierDetails;
    });

  },
  onError: (error) => {
    
    console.log("Error Message",error); 
  
    if(error.response.status === 401){
      toast.error("Session Expired : Please Re-Login",{
        duration: 6000,
        });
     removeUserAdminSession();
      navigate("/admin_login", {replace: false});
    }
    else {
      toast.error("Something went wrong",{
        duration: 6000,
        });
    }
  
  } 
})


const handleEditCourier = async (e) => {
    console.log("courier id data-attribute::::",e.target.getAttribute("data-courierid")," courierid:",courierid);

    const courieridd = e.target.getAttribute("data-courierid");
    const userupdate = [...courierDetails];
    userupdate[0].id = courieridd;
    userupdate[0].USER = userData?.id;
   
    const validate = await trigger2();

    if(validate){

      if(courieridd !== "" && courieridd !== undefined && userData?.id !== undefined){
            updatecourierDetails.mutate(userupdate[0]);
      }
      else {
        toast.error("Invalid Courier ID : Operation Denied",{
          duration: 6000,
          });
      }
  }
};

///updateLocation

const updatecourierDetails = useMutation("courierDetails", api.updateCourierByID, {

  onSuccess: (data) => {
      console.log("user details: ",data);

    getCouriers.refetch();
    toast.success("Location updated Successfully",{
      duration:6000,
    })

  },
  onError: (error) => {
    
    console.log("Error Message",error); 
  
    if(error.response.status === 401){
      toast.error("Session Expired : Please Re-Login",{
        duration: 6000,
        });
     removeUserAdminSession();
      navigate("/admin_login", {replace: false});
    }
    else {
      toast.error("Something went wrong",{
        duration: 6000,
        });
    }
  
  } 
})
 ////////////////


  ///////////Modal Add Courier //////
const [addcourieropen, setaddcourieropen] = useState(false);

const handleAddCourierShow = (e) => {
  setaddcourieropen(true);
};

const handleAddCourierClose= (e) => {
  setaddcourieropen(false);
  reset({
    TRACK_CODE:"",
    TRACK_URL:"",
    STATUS:""
  });
};

const handleAddCourier = async (e) => {
  
  const validatedd = await trigger();

  if(validatedd) {

    const dt = {...details};
    dt.user_id = userData?.id;

      if(dt.user_id !== "" && dt.user_id !== undefined){
        courierMutate.mutate(dt);
      }
      else {
        toast.error("Invalid Courier. Operation denied",{
          duration:6000,
        })
      }
  }
};

const courierMutate = useMutation("addcourier", api.AddCourier, {
  onSuccess: (data) => {
    console.log("user data", data);
    getCouriers.refetch();
    toast.success("Courier Added Successfully",{
      duration:6000,
    });

    sleep(2000).then(() => { 
      
      setaddcourieropen(false);
      setDetails({});
        reset({
          TRACK_CODE:"",
          TRACK_URL:"",
          STATUS:""
        });
       
     });
  },
  onError: (error) => {
    
      console.log("Error Message",error); 
    
      if(error?.response?.status === 401){
        toast.error("Session Expired : Please Re-Login",{
          duration: 6000,
          });
       removeUserAdminSession();
        navigate("/admin_login", {replace: false});
      }
      else {
        toast.error("Something went wrong",{
          duration: 6000,
          });
      }
  }
})
////////////////

/////////////Pagination ////////////

  //const [pages] = useState(Math.ceil(datamsg?.length / dataLimit) || 1);
  const pages = useRef(Math.ceil(allcourier?.length / dataLimit) || 1);

  //console.log("dataLimit::", dataLimit," datamsg length::", datamsg?.length, " pages::: ", Math.ceil(allcourier?.length / dataLimit));

  const [currentPage, setCurrentPage] = useState(1);

  const goToNextPage = () => {
      if(currentPage <= pages.current) {
      setCurrentPage((page) => page + 1);
      }
  }

  const goToPreviousPage = () => {
      if(currentPage > 1 ){
      setCurrentPage((page) => page - 1);
      }
  }

  const changePage = (event) => {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage(pageNumber);
  }

  const getPaginatedData = () => {
    const startIndex = currentPage * dataLimit - dataLimit;
    const endIndex = startIndex + dataLimit;
    //console.log("getPaginationData", allcourier?.slice(startIndex, endIndex))
    return allcourier?.slice(startIndex, endIndex);
  };

  const getPaginationGroup = () => { 
    let start = Math.floor((currentPage - 1) / pageLimit) * pageLimit;
    
    let page = Math.ceil(allcourier?.length / dataLimit) || 1;
    //console.log("local page ", Math.ceil(allcourier?.length / dataLimit));
    //console.log("Array Return", new Array(pages).fill().map((_, idx) => start + idx + 1), "  pages : ", pages);

    pages.current = page;
    
     return new Array(page).fill().map((_, idx) => start + idx + 1);
  }

  ////////////////////////////////////

  /////////////////Search function ///////////////
const [searchquery, setSearchquery] = useState("");

const searchApplication = (q) => {
 //console.log("query: ", q.target.value);

 const query = q.target.value;

 setSearchquery(query);

 setSearchquery(searchquery => {
   return searchquery;
 });
 
 if(query.length === 0){
  
  console.log("Refetching Application list 2", query, " query length next: ", query.length);
     
  getCouriers.refetch();
 }
}

const debouncedFilter = useDebounce(searchquery, 500);

console.log("debouncedFilter", debouncedFilter); 

const { data:searchData, isLoading:searchLoading } = useQuery(
 ['applications', debouncedFilter], 
 () => api.searchCourier(debouncedFilter),
 { enabled: Boolean(debouncedFilter),
 onSuccess: (data) => {
     console.log("data", data);
     const apd = [...data.attributes];
     setallcourier(apd);
     setallcourier((allcourier) => {
       return allcourier;
     })
 },
 onError: (error) => {
    
  console.log("Error Message",error); 

  if(error.response.status === 401){
    toast.error("Session Expired : Please Re-Login",{
      duration: 6000,
      });
   removeUserAdminSession();
    navigate("/admin_login", {replace: false});
  }
  else {
    toast.error("Something went wrong",{
      duration: 6000,
      });
  }

} 
})

 /////////////////////////////////

    return (
        <>
        <Toaster />

<div className="normalheader" id="tpanel">
        <div className="hpanel">
          <div className="panel-body">
            <a className="small-header-action" id="toglepanel" href={void(0)}>
              <div className="clip-header">
                <i className="fa fa-arrow-up"></i>
              </div>
            </a>

            <div id="hbreadcrumb" className="pull-right m-t-lg">
              <ol className="hbreadcrumb breadcrumb">
                <li>
                  <a href={void(0)}>i-visas</a>
                </li>
                <li>
                  <span>Admin</span>
                </li>
                <li className="active">
                  <span>Tracking </span>
                </li>
              </ol>
            </div>
            <h2 className="font-light m-b-xs">Tracking</h2>
            <small>
              View a list of Courier service providers
            </small>
          </div>
        </div>
      </div>

      <div className="content">
        <div className="row">
          
          <div className="col-md-12">
            

         {/*  <div className="col-lg-12 text-center welcome-message"> */}
                
                <div className="hpanel email-compose">
                  
                   <>
                <div className="panel-heading hbuilt">
                        <div className="text-center p-xs font-normal">
                          <div className="input-group">
                            <input
                              type="text"
                              onChange={searchApplication}
                              value={searchquery || ""}
                              className="form-control input-sm"
                              placeholder="Search by Location Name, Country, Telephone and Email..."
                            />{" "}
                            <span className="input-group-btn">
                              {" "}
                              <button
                                type="button"
                                disabled
                                className="btn btn-sm btn-default">
                                <i className="fa fa-search"></i> 
                              </button>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="panel-body">
    
                      {searchLoading ? (
                           <>
                          <div className="row text-center">
                          <div className="col-md-7 m-b-md" style={{textAlign: "left", display: "flex", flexDirection: "row", justifyItems: "flex-start", alignItems: "center", justifyContent: "normal"}}>
    
                          <button className="btn btn-info btn-sm" >
                                <i className="fa fa-user-plus"></i> Add Courier
                              </button>
                          </div>
                          <div className="col-md-5 mailbox-pagination m-b-md">
                                <span style={{ fontSize: "1.1rem" }}>
                                Showing 0 of 0 pages   &nbsp; &nbsp;
                                </span>
                                <div className="btn-group">
                                  <button className="btn btn-default btn-sm previous disabled"
                                 
                                  disabled
                                  >
                                    <i className="fa fa-arrow-left"></i>
                                  </button>
                                  {getPaginationGroup().map((item, index) => (
                                  <button 
                                  key={index}
                                  className="btn btn-default btn-sm disabled"
                                  >
                                  {item}
                                  </button>
                                  ))}
                                  <button className="btn btn-default btn-sm next disabled"
                                  disabled
                                  >
                                    <i className="fa fa-arrow-right"></i>
                                  </button>
                                </div>
                              </div>
                             
                          </div>
                            <div className="table-responsive" style={{overflowX: "inherit"}}>
                            <table className="table table-hover table-mailbox apptable">
                            <thead style={{borderBottom: "solid 2px #64d246"}}>
                            <tr>
                            <th className="text-center" style={{width:"210px"}}>Tracking Code</th>
                                <th className="text-center" >Tracking URL</th>
                                <th className="text-center" >Status</th>
                                <th className="text-center" >Creation Date</th>
                                <th className="text-center">Action</th>
                               
                              </tr>
                            </thead>
                                <tbody>
                                <tr className="active"><td colSpan="5" style={{textAlign:"center"}}>Searching...</td></tr>
                            </tbody>
                          </table>
                            </div>
                            </>
                        ):(
                          <>
                        <div className="row">
                          <div className="col-md-7 m-b-md" style={{textAlign: "left", display: "flex", flexDirection: "row", justifyItems: "flex-start", alignItems: "center", justifyContent: "normal"}}>
    
                          <button className="btn btn-info btn-sm" onClick={handleAddCourierShow} >
                                <i className="fa fa-user-plus"></i> Add Courier
                              </button>
                          </div>
    
                          <div className="col-md-5 mailbox-pagination m-b-md">
                            <span style={{ fontSize: "1.1rem" }}>
                            {!!allcourier && (Object.keys(allcourier).length > 0) ? `Showing ${currentPage} of ${pages.current} pages` : "Showing 0 of 0 pages"}   &nbsp; &nbsp;
                            </span>
                            <div className="btn-group">
                              <button className={`btn btn-default btn-sm previous ${currentPage === 1 ? 'disabled' : ''}`}
                              onClick={goToPreviousPage}
                              disabled={!!allcourier && (currentPage === 1) ? true : false} 
                              >
                                <i className="fa fa-arrow-left"></i>
                              </button>
                              {getPaginationGroup().map((item, index) => (
                              <button 
                              key={index}
                              onClick={changePage}
                              className={`btn btn-default btn-sm  ${currentPage === item ? 'active' : null}`}
                              >
                               {item}
                              </button>
                              ))}
                              <button className={`btn btn-default btn-sm next ${currentPage === pages.current ? 'disabled' : ''}`}
                              onClick={goToNextPage} 
                              disabled={!!allcourier && (currentPage === pages.current) ? true : false} 
                              >
                                <i className="fa fa-arrow-right"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="table-responsive" style={{overflowX: "inherit"}}>
                          <table className="table table-hover table-mailbox apptable">
                          <thead style={{borderBottom: "solid 2px #64d246"}}>
                              <tr>
                              <th className="text-center" style={{width:"210px"}}>Tracking Code</th>
                                <th className="text-center" >Tracking URL</th>
                                <th className="text-center" >Status</th>
                                <th className="text-center" >Creation Date</th>
                                <th className="text-center">Action</th>
                               
                              </tr>
                            </thead>
                            <tbody>
                            { getCouriers.isLoading ? (

                            <tr className="unread active"><td colSpan="5" style={{textAlign:"center"}}>
                              <span className="btn">
                              <i className="fa fa-paper-plane" style={{color: "#5ac557"}}></i>
                              &nbsp; Loading...
                              <span className="lds-facebook"><span></span><span></span><span></span></span>
                              </span>
                              &nbsp; &nbsp; &nbsp; &nbsp;
                              </td></tr>
                            ) : (

                            !!allcourier && (Object.keys(allcourier).length > 0) ? 
                              getPaginatedData().map((item,i) =>{
                                return (
                                  <tr className="unread" key={i}>
                                  <td className="text-center" style={{width: "100px"}}>
                                 {item.TRACK_CODE}
                                </td>
                                <td className="text-center">
                                 {`${item.TRACK_URL.substring(0,50)}...`}
                                </td>
                                <td className="text-center">
                                 {item.STATUS}
                                </td>
                                <td className="text-center mail-date">
                                {createdDate(item.created_at)}
                                </td>
                                <td className="text-center mail-date">
                                <div className="btn-group">
                                        <button data-toggle="dropdown" className="btn btn-default dropdown-toggle">Action <span className="caret"></span></button>
                                        <ul className="dropdown-menu">
                                            <li><Link to="# " data-courierid={item.RECID} onClick={handleViewCourierShow} >View Courier</Link></li>
                                            <li><Link to="# " data-courierid={item.RECID} onClick={handleEditCourierShow} >Edit Courier</Link></li>
                                           
                                        </ul>
                              </div>
                                </td>
                              </tr>
                                )
                              }) : (
                               <tr className="unread active"><td colSpan="5" style={{textAlign:"center"}}>No Courier found </td></tr>
                             ))}
                               
                            </tbody>
                          </table>
                        </div>
    
                        </>
                        )}
                     </div>
                     <div className="panel-footer text-left">
                      {!!allcourier && (Object.keys(allcourier).length > 0) ? allcourier.length : 0}  Courier
                     </div>
                   </>
                   
                </div>
            </div>
    
        </div>
        </div>
    
    
       {/*  <footer className="footer" style={{ maxHeight: "40px" }}>
              <div className="mt-4 mt-md-5 text-center" style={{ marginTop: "0" }}>
                <p className="footer-p">
                  © <script>document.write(new Date().getFullYear())</script>{" "}
                  i-visas.com
                </p>
              </div>
            </footer> */}
    
    
            <Modal show={viewcourieropen} onHide={handleViewCourierClose} animation={false}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            keyboard={false}
            >
               <div className="color-line"></div>
            <Modal.Header className="modal-header text-center" style={{padding: "10px 10px"}}>
              <Modal.Title>View User</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{padding: "5px"}}>
             
            <div className="panel-body no-padding">
                   <div className="row">
    
                   <div className="col-lg-12">
    
                   { getcourierDetails.isError &&
                     (
                      <>
                      <div className="hpanel horange">
                        <div className="panel-body">
                            <div className="row">
                                <div className="col-sm-12">
                                    <p className="text-center">Encountered an Issue Loading Courier details</p>
                                </div>
                            </div>
                          </div>
                      </div>
                      </>
                     )}
    
                     { getcourierDetails.isLoading ? 
                     (
                      <>
                      <div className="hpanel horange">
                        <div className="panel-body">
                            <div className="row">
                                <div className="col-sm-12">
                                    <p className="text-center">Loading...</p>
                                </div>
                            </div>
                          </div>
                      </div>
                      </>
                     ): courierDetails && courierDetails.length > 0 && (
                     <>  
                    <div className="hpanel horange">
                        <div className="panel-body">
                           {/*  <span className="label label-info pull-right">NEW</span> */}
                            <div className="row">
                                <div className="col-sm-12">
                                  
                                    <div className="row">
                                        <div className="col-sm-4 text-center">
                                            <div className="project-label">Track Code</div>
                                            <small style={{fontWeight: "bold"}}>{courierDetails[0]?.TRACK_CODE || "Not Set"}</small>
                                        </div>
                                        
                                        <div className="col-sm-4 text-center">
                                            <div className="project-label">Status</div>
                                            <small style={{fontWeight: "bold"}}>{courierDetails[0]?.STATUS || "Not Set"}</small>
                                        </div>
                                        <div className="col-sm-4 text-center">
                                            <div className="project-label">Date Created</div>
                                            <small style={{fontWeight: "bold"}}>{courierDetails[0]?.created_at || "Not Set"}</small>
                                        </div>
                                    </div>

                                    <hr className="slash-3"/>

                                    <div className="row">
                                    <div className="col-sm-12 text-center">
                                            <div className="project-label">Track URL</div>
                                            <small style={{fontWeight: "bold"}}>{courierDetails[0]?.TRACK_URL || "Not Set"}</small>
                                        </div>
                                    </div>
    
                                   {/*  <hr className="slash-3" style={{marginTop:"18px"}}/> */}
    
                                </div>
                              
                            </div>
                        </div>
                        <div className="panel-footer">
                            last updated on {createdDate(courierDetails[0]?.created_at)}
                        </div>
                    </div>
                    </>
                    )}
                </div>
                    
                   </div>
            </div>
    
            </Modal.Body>
            <Modal.Footer>
             
               <div className="text-right">
               
                  <button
                    type="button"
                    className="btn btn-default"
                    onClick={handleViewCourierClose}>
                    <i className="fa fa-trash"></i>  Close
                  </button>
              </div>
            </Modal.Footer>
          </Modal>
    
    
          <Modal show={editcourieropen} onHide={handleEditCourierClose} animation={false}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            keyboard={false}
            >
               <div className="color-line"></div>
            <Modal.Header className="modal-header text-center" style={{padding: "10px 10px"}}>
              <Modal.Title>Edit Courier</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{padding: "5px"}}>
             
            
            <div className="panel-body no-padding">
            <div className="row">
                      <div className="col-lg-12">
    
                      { getcourierDetails.isError &&
                     (
                      <>
                      <div className="hpanel horange">
                        <div className="panel-body">
                            <div className="row">
                                <div className="col-sm-12">
                                    <p className="text-center">Encountered an Issue Loading Courier details</p>
                                </div>
                            </div>
                          </div>
                      </div>
                      </>
                     )}
    
                     { getcourierDetails.isLoading ? 
                     (
                      <>
                      <div className="hpanel horange">
                        <div className="panel-body">
                            <div className="row">
                                <div className="col-sm-12">
                                    <p className="text-center">Loading...</p>
                                </div>
                            </div>
                          </div>
                      </div>
                      </>
                     ): courierDetails && courierDetails.length > 0 && (
                          <>
                            <div className="row col-bb">
                              
                            <div className="form-group col-lg-4">
                                  <label className="label-clr">
                                    Track Code
                                  </label>
                                  <input
                                      type="text"
                                      {...register2("TRACK_CODE", { 
                                        required: { value: true, message: "This field is required" }, 
                                              onChange: (e) => {handleEditChange(e)} })}
                                      value={courierDetails[0]?.TRACK_CODE || ""}
                                      className="form-control"
                                      name="TRACK_CODE"
                                    />
                                    {errors.TRACK_CODE && (
                                      <p className="errorValidate">{errors.TRACK_CODE.message}</p>
                                      )}
                                </div>
    
                                <div className="form-group col-lg-4">
                                    <label className="label-clr">
                                      Track URL
                                    </label>
                                    <input
                                      type="url"
                                      {...register2("TRACK_URL", { 
                                        required: { value: true, message: "This field is required" },
                                        pattern: { value: /^((ftp|http|https):\/\/)?(www\.)?([A-z]+)\.([A-z]{2,})/,
                                        message: "Please provide a Valid URL"}, 
                                        onChange: (e) => {handleEditChange(e)} 
                                        }
                                              
                                      )}
                                      value={courierDetails[0]?.TRACK_URL || ""}
                                      className="form-control"
                                      name="TRACK_URL"
                                    />
                                    {errors.TRACK_URL && (
                                      <p className="errorValidate">{errors.TRACK_URL.message}</p>
                                      )}
                                  </div>
    
                                <div className="form-group col-lg-4">
                                    <label className="label-clr">
                                      Status
                                    </label>
                                    <select
                                    name="STATUS"
                                   {...register2("STATUS", { 
                                              required: { value: true, message: "This field is required" }, 
                                              onChange: (e) => {handleEditChange(e)} })}
                                    value={courierDetails[0]?.STATUS || ""} // Prop: The email input data
                                   
                                    className="form-select m-b-4"
                                    style={{ width: "100%" }}>
                                    <option value=''>select status</option>
                                    <option value="active">Active</option>
                                    <option value="inactive">In-active</option>
                                   
                                  </select>
                                    {errors.STATUS && (
                                      <p className="errorValidate">{errors.STATUS.message}</p>
                                      )}
                                  </div>
    
                            </div>
    
                            </>
                    )} 
                      </div>
                  </div>
            </div>
    
    
            </Modal.Body>
            <Modal.Footer>
             
               <div className="text-right">
               {getcourierDetails.isLoading ? (
                 <>
                    <span className="btn">
                      <i className="fa fa-paper-plane" style={{color: "#5ac557"}}></i>
                      &nbsp; Saving...
                      <span className="lds-facebook"><span></span><span></span><span></span></span>
                    </span>
                    &nbsp; &nbsp; &nbsp; &nbsp;
                    </>
                        ) : (
                    <button type="button"
                      className="btn btn-success btn-sm"
                      data-courierid={courierid}
                      onClick={handleEditCourier}
                      >
                       <i className="fa fa-location-arrow"></i>  Apply Changes
                    </button>
                )}
                  <button
                    type="button"
                    className="btn btn-default"
                    onClick={handleEditCourierClose}>
                    <i className="fa fa-trash"></i>  Close
                  </button>
              </div>
            </Modal.Footer>
          </Modal>
    
    
          <Modal show={addcourieropen} onHide={handleAddCourierClose} animation={false}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            keyboard={false}
            >
               <div className="color-line"></div>
            <Modal.Header className="modal-header text-center" style={{padding: "10px 10px"}}>
              <Modal.Title>Add Courier</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{padding: "5px"}}>
             
           
            <div className="panel-body no-padding">
            <div className="row">
                      <div className="col-lg-12">
    
                            <div className="row col-bb">
                              
                            <div className="form-group col-lg-4">
                                  <label className="label-clr">
                                    Track Code
                                  </label>
                                  <input
                                      type="text"
                                      {...register("TRACK_CODE", { 
                                        required: { value: true, message: "This field is required" }, 
                                              onChange: (e) => {handleChange(e)} })}
                                      value={details.TRACK_CODE || ""}
                                      className="form-control"
                                      name="TRACK_CODE"
                                    />
                                    {errors.TRACK_CODE && (
                                      <p className="errorValidate">{errors.TRACK_CODE.message}</p>
                                      )}
                                </div>
    
                                <div className="form-group col-lg-4">
                                    <label className="label-clr">
                                      Track URL
                                    </label>
                                    <input
                                      type="url"
                                      {...register("TRACK_URL", 
                                        { required: { value: true, message: "This field is required" },
                                          onChange: (e) => {handleChange(e)} ,
                                          pattern: { value: /^((ftp|http|https):\/\/)?(www\.)?([A-z]+)\.([A-z]{2,})/,
                                          message: "Please provide a Valid URL"
                                          }
                                        }
                                       )}
                                      value={details.TRACK_URL || ""}
                                      className="form-control"
                                      name="TRACK_URL"
                                    />
                                    {!errors.TRACK_URL && (
                                      <p className="text-center text-info">Please include <b>[TRACKING_ID]</b> in URL</p>
                                     )} 
                                    {errors.TRACK_URL && (
                                      <p className="errorValidate">{errors.TRACK_URL.message}</p>
                                      )}
                                  </div>
    
                                <div className="form-group col-lg-4">
                                    <label className="label-clr">
                                      Status
                                    </label>
                                    <select
                                    name="STATUS"
                                   {...register("STATUS", { 
                                              required: { value: true, message: "This field is required" }, 
                                              onChange: (e) => {handleChange(e)} })}
                                    value={details?.STATUS || ""} // Prop: The email input data
                                   
                                    className="form-select m-b-4"
                                    style={{ width: "100%" }}>
                                    <option value=''>select status</option>
                                    <option value="active">Active</option>
                                    <option value="inactive">In-active</option>
                                   
                                  </select>
                                    {errors.STATUS && (
                                      <p className="errorValidate">{errors.STATUS.message}</p>
                                      )}
                                  </div>
                            </div>
                      </div>
                  </div>
            </div>
    
    
            </Modal.Body>
            <Modal.Footer>
             
               <div className="text-right">
               {courierMutate.isLoading ? (
                 <>
                    <span className="btn">
                      <i className="fa fa-paper-plane" style={{color: "#5ac557"}}></i>
                      &nbsp; Saving...
                      <span className="lds-facebook"><span></span><span></span><span></span></span>
                    </span>
                    &nbsp; &nbsp; &nbsp; &nbsp;
                    </>
                        ) : (
                    <button type="button"
                      className="btn btn-success btn-sm"
                      
                      onClick={handleAddCourier}
                      >
                       <i className="fa fa-location-arrow"></i>  Add Courier
                    </button>
                )}
                  <button
                    type="button"
                    className="btn btn-default"
                    onClick={handleAddCourierClose}>
                    <i className="fa fa-trash"></i>  Close
                  </button>
              </div>
            </Modal.Footer>
          </Modal>
            
            </>
    )

}

export default Tracking;