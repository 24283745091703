import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "react-query";
import { getAdminUser, removeUserAdminSession, sleep } from "../Utils/Common";
import * as api from '../Utils/adminApiCalls';
import "./AdminDashboard.css";
import {
    Outlet,
    useMatch,
    useResolvedPath,
    useNavigate,
    useLocation,
    Link
  } from "react-router-dom";
//import { , Link } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';


const AdminNav = () => {

  const [userData, setUserData] = useState({});
  const navigate = useNavigate();

  /////////////////////
  let location = useLocation();

  let from = (window.history.state && window.history.state.idx > 0) ? -1 : "/dashboard/overview";

  console.log("window history state: ", window.history.state);  
  console.log("window.history.state.idx", window.history.state.idx, window.history.state.idx > 0);

  console.log("react location: ",location.state?.from?.pathname)

  console.log("history URL",from);
  console.log("location pathname:",location?.pathname);

  //////////////////////

  const [msgCount, setMsgCount] = useState(0);

    const receivedMessages = useQuery('allreceivedclientmessages', () => api.getAllClientMessage(), {
        onSuccess:(data) => {
          console.log("admin_messages:: ",data);
          console.log("admin_messages response:: ",data?.response);

          //if(data?.filter(dmsg => dmsg.MESSAGE_STATUS == "unread").length > 0) {
            if(data?.filter(dmsg => dmsg.MESSAGE_STATUS == "unread").length > 0 && data?.filter(dmsg => dmsg.MESSAGE_STATUS == "unread").length !== msgCount) {
            toast.success('You have received a new Message!',{
              duration: 6000,
            });

            setMsgCount(data?.filter(dmsg => dmsg.MESSAGE_STATUS == "unread").length);
          }
          
        },
        onError: (error) => {
     
          console.log("Error Message",error); 
       
          toast.error("Something went wrong",{
            duration: 6000,
            });
       
          if(error?.response?.status === 401){
           removeUserAdminSession();
            navigate("/admin_login", {replace: false});
          }
        }
      });

    const { loading: loadingmsg, data: datamsg } = receivedMessages;

    useEffect(() => {
  
      document
        .getElementById("menuToggle")
        .addEventListener("click", toggleMenux);
      //document.getElementById("toglepanel").addEventListener("click", togglePanel);
  
      function checkUserData() {
        const item = getAdminUser();
    
        if (item) {
          console.log("user item",item);
          setUserData(item);
  
          setUserData((userData) => {
            console.log("updated details", userData);
            return userData;
          });
  
        }
      }
      checkUserData();
  
      window.addEventListener('storage', checkUserData)
    
      return () => {
        window.removeEventListener('storage', checkUserData)
      }
      
    }, []);
 
   const CheckActiveLink = (to) => {
     let resolved = useResolvedPath(to);
     let match = useMatch({ path: resolved.pathname, end: true });
     //console.log("link match ? ", match, "resolved:", resolved);
     return match;
   };

   const checkAuthorized = () => {
    console.log("User Role Status <:::::> ", userData.role)
    let roleState = false;
    if(userData?.role !== 'agent'){
      roleState = true;
    }
    return roleState; 
   }
 
   const toggleMenux = () => {
     console.log(
       "window.innerWidth",
       window.innerWidth,
       "window.outerWidth",
       window.outerWidth
     );
     if (window.innerWidth < 769) {
       //$("body").toggleClass("show-sidebar");
       document.body.classList.toggle("show-sidebar");
       //console.log("window.innerWidth is less");
     } else {
       //$("body").toggleClass("hide-sidebar");
       document.body.classList.toggle("hide-sidebar");
       //console.log("window.innerWidth is greater");
     }
   };

   const logOut = (e)=> {
    e.preventDefault();
    logOutMutation.mutate({"id":userData.id});
  }

   const logOutMutation = useMutation(api.adminlogout, {
    onSuccess: (data) => {
      console.log("onSuccess data",data);

      if(data?.response?.status === 401){
        removeUserAdminSession();
         navigate("/admin_login", {replace: true});
       }
       
      toast.success('Logged Out Successfully',{
        duration: 6000,
      });
      
      sleep(1000).then(() => {
        removeUserAdminSession();
      navigate("/admin_login", {replace: true});
      });
      
    },
    onError: (error) => {

      console.log("Error Message",error); 
  
    if(error?.response?.status === 401){
      toast.error("Session Expired : Please Re-Login",{
        duration: 6000,
        });
     removeUserAdminSession();
      navigate("/admin_login", {replace: true});
    }
    else {
      toast.error("Something went wrong",{
        duration: 6000,
        });
    }

    }
  });

  ////////Company Information :////////////
  const [companyDetails, setCompanyDetails] = useState({});

  const getCompanyInfoNav = useQuery("get_companyinfo", api.showCompany, {
    enabled: true,
    refetchOnMount:true,
    refetchOnWindowFocus:true,
    onSuccess: (data) => {
      console.log("users data:::", data);

      const apd = {...data?.Info[0]};
      apd.URL = data?.URL;
      console.log("@@@@@@@@@@@@@@@@@@", apd)
      setCompanyDetails(apd);
      setCompanyDetails(companyDetails => {
        console.log("companyDetails ===>>>",companyDetails);
        return companyDetails;
      })
    },
    onError: (error) => {
      
        console.log("Error Message ::: ",error); 
      
        if(error?.response?.status === 401){
          toast.error("Session Expired : Please Re-Login",{
            duration: 6000,
            });
         removeUserAdminSession();
          navigate("/admin_login", {replace: false});
        }
        else {
          toast.error("Something went wrong",{
            duration: 6000,
            });
        }
    }
  });
  const {isLoading, data : companyData} = getCompanyInfoNav;


  //////////////////////////////////////////

return (
    <>
    <Toaster />
    <div id="header">
    <div className="color-line"></div>
    <div id="logo" className="light-version">
      <span>
      {companyDetails && companyDetails?.COMPANY_NAME ? 
      (
        <span>{companyDetails?.COMPANY_NAME}</span>
       ):(
         <>
        <i>i</i>-visas
        </>
       )}
      </span>
    </div>
    <nav role="navigation">
      <div className="header-link hide-menu" id="menuToggle">
        <i className="fa fa-bars"></i>
      </div>
      <div className="small-logo">
        <span className="text-primary">
        {companyDetails && companyDetails?.COMPANY_NAME ? 
      (
        <span>{companyDetails?.COMPANY_NAME}</span>
       ):(
         <>
        <i>i</i>-visas
        </>
       )}
        </span>
      </div>

      <div className="mobile-menu">
        <button
          type="button"
          className="navbar-toggle mobile-menu-toggle"
          data-toggle="collapse"
          data-target="#mobile-collapse">
          <i className="fa fa-chevron-down"></i>
        </button>
        <div className="collapse mobile-navbar" id="mobile-collapse">
          <ul className="nav navbar-nav">
            <li>
              <a className="" href={void(0)}>
                Logout
              </a>
            </li>
            <li>
              <a className="" href={void(0)}>
                Profile
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="navbar-right">
        <ul className="nav navbar-nav no-borders">
          <li className="dropdown naver-link">
            <a className="dropdown-toggle" href={void(0)} data-toggle="dropdown">
              <i className="pe-7s-comment"></i>
            </a>
            <ul className="dropdown-menu hdropdown notification animated flipInX">

            {!!datamsg && (Object.keys(datamsg?.filter(dmsg => dmsg.MESSAGE_STATUS == "unread")).length > 0) ? 
                        
                        datamsg?.slice(0, 3).map((item,i) =>{
                                     return (
                                      <li key={i}>
                                      <a href={void(0)}>
                                        <span className="label label-success">NEW</span> {item.MESSAGE_SUBJECT}
                                      </a>
                                    </li>
                                     )
                                 }) : datamsg?.filter(dmsg => dmsg.MESSAGE_STATUS == "unread").length > 0 ? (
                                  <li className="summary">
                                  <a href={void(0)}>See all notifications</a>
                                </li>
                                 ): (
                                 <li><span className="summary">0 notifications</span></li>
                                )}

            </ul>
          </li>
          <li className="dropdown naver-link">
            <a className="dropdown-toggle" href={void(0)} data-toggle="dropdown">
              <i className="pe-7s-keypad"></i>
            </a>

            <div className="dropdown-menu hdropdown bigmenu animated flipInX">
              <table>
                <tbody>
                  <tr>
                    <td>
                      <a href="/admin_nav/client_applications">
                        <i className="pe pe-7s-portfolio text-info"></i>
                        <h5>Applications</h5>
                      </a>
                    </td>
                    <td>
                      <a href="/admin_nav/admin_messages">
                        <i className="pe pe-7s-mail text-warning"></i>
                        <h5>Messages</h5>
                      </a>
                    </td>
                   {/*  <td>
                      <a href={void(0)}>
                        <i className="pe pe-7s-chat text-info"></i>
                        <h5>Chat</h5>
                      </a>
                    </td> */}
                  </tr>
                </tbody>
              </table>
            </div>
          </li>
          <li className="dropdown naver-link">
            <a
              className="dropdown-toggle label-menu-corner"
              href={void(0)}
              data-toggle="dropdown">
              <i className="pe-7s-mail"></i>
              <span className="label label-success">{!!datamsg  && (Object.keys(datamsg.filter(dmsg => dmsg.MESSAGE_STATUS == "unread")).length > 0) ? Object.keys(datamsg.filter(dmsg => dmsg.MESSAGE_STATUS == "unread")).length : "0"}</span>
            </a>
            <ul className="dropdown-menu hdropdown animated flipInX">
              <div className="title" style={{width: "max-content"}}>{!!datamsg  && (Object.keys(datamsg.filter(dmsg => dmsg.MESSAGE_STATUS == "unread")).length > 0) ? `You have ${Object.keys(datamsg.filter(dmsg => dmsg.MESSAGE_STATUS == "unread")).length} new messages` : `You have no new messages` }</div>
              {!!datamsg && (Object.keys(datamsg).length > 0) ? 
                
                datamsg.filter(dmsg => dmsg.MESSAGE_STATUS == "unread").slice(0, 5).map((item,i) =>{

                let list = <li key={i}><Link to={`/admin_nav/admin_messages/?conversation_id=${item.CONVERSATION_ID}`}>{item.MESSAGE_SUBJECT}</Link></li>;

              return list;
                
            }) : (
            <li><span className="summary">0 notifications</span></li>
          )}
          <li className="summary"><Link to="/admin_nav/admin_messages">See all notifications</Link></li>
            </ul>
          </li>

          <li className="dropdown naver-link">
            <a
              href={void(0)}
              className="dropdown-toggle label-menu-corner"
              data-toggle="dropdown">
              <i className="pe-7s-user"></i>
            </a>
            <ul className="dropdown-menu hdropdown animated flipInX">
             {/*  <li>
                <a href={void(0)}>Profile</a>
              </li> */}
              <li>
              <a href={void(0)} onClick={logOut}>Logout</a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </nav>
  </div>

  <aside id="menu" style={{backgroundColor: "#f0f3f6"}}>
<div className="slimScrollDiv"  style={{position: "relative", overflow: "hidden", "width": "auto", height: "100%", backgroundColor: "#f0f3f6"}} >
<div id="navigation" style={{backgroundColor: "#f0f3f6"}}>


<ul className="nav" id="side-menun" style={{background: "#f0f3f6", padding: "23px 0 50px 25px"}}>
<li className={CheckActiveLink("admin_dashboard") ? "active" : ""}>
    <Link to="admin_dashboard"> <span className="nav-label">Dashboard</span> </Link>
</li>
<li className={CheckActiveLink("clients") ? "active" : ""}>
    <Link to="clients "> <span className="nav-label">Clients</span><span className="label label-warning pull-right">NEW</span> </Link>
</li>
<li className={CheckActiveLink("pre_screening") ? "active" : ""}>
    <Link to="pre_screening"><span className="nav-label">Pre-Screens</span><span className="label label-success pull-right">New</span></Link>
</li>
<li className={CheckActiveLink("client_applications") ? "active" : ""}>
    <Link to="client_applications"><span className="nav-label">Applications</span><span className="label label-success pull-right">New</span> </Link>
</li>
<li className={CheckActiveLink("admin_messages") ? "active" : ""}>
    <Link to="admin_messages"><span className="nav-label">Messages</span><span className="label label-success pull-right">{!!datamsg  && (Object.keys(datamsg.filter(dmsg => dmsg.MESSAGE_STATUS == "unread")).length > 0) ? Object.keys(datamsg.filter(dmsg => dmsg.MESSAGE_STATUS == "unread")).length : "0"}</span> </Link> 
</li>


<hr className="hr-text" data-content="SETTINGS" />

<li className={CheckActiveLink("billing") ? "active" : ""}>
    <Link to="billing"><span className="nav-label">Billing</span></Link>
</li>
<li className={CheckActiveLink("location") ? "active" : ""}>
    <Link to="location"><span className="nav-label">Branch </span> </Link>
</li>
<li className={CheckActiveLink("default_branch") ? "active" : ""}>
    <Link to="default_branch"><span className="nav-label">Default Location </span> </Link>
</li>
<li className={CheckActiveLink("tracking") ? "active" : ""}>
    <Link to="tracking"><span className="nav-label">Tracking</span> </Link>
</li>

<li className={CheckActiveLink("user_management") ? "active" : ""} style={{display:`${checkAuthorized() ? "block" : "none"}`}}>
    <Link to="user_management"><span className="nav-label">User Management</span> </Link>
</li>
<li className={CheckActiveLink("admin_settings") ? "active" : ""}  style={{display:`${checkAuthorized() ? "block" : "none"}`}}>
    <Link to="admin_settings"><span className="nav-label">Preferences</span> </Link>
</li>

</ul>
</div>
</div>
</aside>

<div id="wrapper">
        <Outlet />

        <footer className="footer" style={{ maxHeight: "40px" }}>
          <div className="mt-4 mt-md-5 text-center" style={{ marginTop: "0" }}>
            <p className="footer-p">
              © <script>document.write(new Date().getFullYear())</script>{" "}
              i-visas.com
            </p>
            <a href={void(0)} id="invoicegen" style={{display:"none"}}></a>
          </div>
        </footer>
      </div>
</>
)
}

export default AdminNav;