import React, { useState, useEffect, Fragment, useRef } from "react";
import { useForm } from "react-hook-form";
import { useQuery, useMutation } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import toast from 'react-hot-toast';
import { removeUserSession, sleep } from "./Utils/Common";
import moment from "moment";
import * as api from './Utils/apiCall';
import "./Application.css";

const EditApplication = () => {

const navigate = useNavigate();

const params = useParams();

const elapsedTime = useRef();

const [elapsedtime, setElapsedtime] = useState("");

const [countries, setCountries] = useState([]);

const [errorlist, setErrorlist] = useState([]);

const [appid, setAppid] = useState('');

const [permit, setPermit] = useState(false);

//const [movedate, setMovedate] = useState("");

const [wizardstep, setWizardstep] = useState(1);

const [childrenList, setChildrenList] = useState([
  {
    childname_0: "",
    gender_0: "",
    dob_0: "",
    childnationality_0: "",
    passportno_0: "",
    passportissueddate_0: "",
    passportexpiry_0: "",
    visatype_0: "",
    visaissueddate_0: "",
    visaexpiry_0: "",
  },
]);

const [appdetails, setAppdetails] = useState({
    CLIENT_ID : "",
    FIRSTNAME: "",
    MIDDLENAME: "",
    LASTNAME: "",
    EMAIL: "",
    MOBILE: "",
    COUNTRY: "",
    DOB: "",
    APPTYPE_ID: ""
  })

  const [details, setDetails] = useState({
    APPTYPE_ID:"",
    CLIENT_ID:"",
    fullname: "",
    NAME_CHANGE_QUESTION: "",
    OTHER_NAMES:"", //[],
    COUNTRY_OF_BIRTH: "",
    PLACE_OF_BIRTH: "",
    NATIONALITY: "",
    OTHER_NATIONALITY_QUESTION: "",
    OTHER_NATIONALITY: "", //[],
    PASSPORT_NO: "",
    PASSPORT_ISSUED: "",
    PASSPORT_EXPIRY: "",
    ISSUING_AUTHORITY: "",
    PLACE_OF_ISSUE: "",
    BRP_QUESTION: "",
    BRP_NUMBER: "",
    BRP_EXPIRY_DATE: "",
    dateofexpiry: "",
    NATIONAL_ID_QUESTION: "",
    NAME_MOTHER: "",
    DOB_MOTHER: "",
    NATIONALITY_MOTHER: "",
    PLACE_OF_BIRTH_MOTHER: "",
    NAME_FATHER: "",
    DOB_FATHER: "",
    NATIONALITY_FATHER: "",
    PLACE_OF_BIRTH_FATHER: "",
    UK_NI_QUESTION: "",
    UK_NI: "",
    UK_DRIVER_LICENSE_QUESTION: "",
    UK_DRIVER_LICENSE:"",
    mobilenumber: "",
    email: "",

    ADDRESS1:"",
    ADDRESS2:"",
    //LOCATION_NAME:"",
    //LOCATION_CODE:"",
    TOWN:"",
    COUNTY:"",
    POSTCODE:"",
    COUNTRY_PREFIX:"",
    COUNTRY:"",
    //FAX:"",
    //VATRATE:"",

    TIME_LIVED_AT_ADDRESS:"",
    HOME_QUESTION_ANSWER:"",

    DATE_MOVED_IN_ADDRESS: "",
    

    LANDLORD_NAME:"",
    LANDLORD_EMAIL:"",
    LANDLORD_MOBILE:"",
    
    LANDLORD_ADDRESS1:"",
    LANDLORD_ADDRESS2:"",
    //LANDLORD_LOCATION_NAME:"",
    //LANDLORD_LOCATION_CODE:"",
    LANDLORD_TOWN:"",
    LANDLORD_COUNTY:"",
    LANDLORD_POSTCODE:"",
    LANDLORD_COUNTRY_PREFIX:"",
    LANDLORD_COUNTRY:"",
    //LANDLORD_FAX:"",
    //LANDLORD_VRATE:"",
    

    NUMBER_OF_BEDROOMS: "",
    NUMBER_OF_OTHERROOMS: "",
    WHO_LIVES_THERE: "",
    
    PREVIOUS_ADDRESS1: "",
    PREVIOUS_ADDRESS2: "",
    TOWN_PREVIOUS: "",
    COUNTY_PREVIOUS: "",
    POSTCODE_PREVIOUS: "",

    //PREVIOUS_LOCATION_NAME: "",
    //PREVIOUS_LOCATION_CODE: "",
    PREVIOUS_COUNTRY_PREFIX: "",
    COUNTRY_PREVIOUS: "",
    //PREVIOUS_FAX: "",
    //PREVIOUS_VRATE: "",

    MARITAL_STATUS: "",
    DATE_OF_MARRIAGE: "",
    WHERE_YOU_GOT_MARRIED: "",                  
    NAME_OF_SPOUSE: "",
    DOB_SPOUSE: "",
    NATIONALITY_SPOUSE: "",                
    PASSPORT_SPOUSE: "",
    WHERE_YOU_MET: "",
    WHERE_RELATIONSHIP_BEGAN: "",
    WHEN_LAST_YOU_SAW_EACHOTHER: "",              
    LIVE_TOGETHER_QUESTION: "",
    DATE_LIVING_TOGETHER: "",
    DO_YOU_HAVE_CHILDREN: "",
    NUMBER_OF_DEPENDENT_CHILDREN: 0,
    CHILDREN: "",//[],
    MARRIED_BEFORE_QUESTION: "",
    PREVIOUS_MARRIAGE: "",//[],

    PARTNER_MARRIED_BEFORE: "",
    PARTNERS_EX: "",//[],
    DO_YOU_HAVE_FAMILY_IN_HOME_COUNTRY: "",
    FAMILY_IN_HOME_COUNTRY: "",//[],

    PASSED_RECOGNIZED_TEST: "",
    WHAT_TEST_DID_YOU_PASS: "",
    OTHER_CERTIFICATE:"",
    HAVE_DEGREE_IN_ENGLISH: "",

    QUALIFICATION_QUESTION:"",
    QUALIFICATION: "",//[],

    EMPLOYMENT_STATUS: "",
    
    EMPLOYMENT_DATE: "",
    EMPLOYER_NAME: "",
    EMPLOYER_PHONE: "",
    EMPLOYER_EMAIL: "",
    SALARY: "",
    JOB_TITLE: "",
    EMPLOYER_ADDRESS1: "",
    EMPLOYER_ADDRESS2: "",
    //EMPLOYER_LOCATION: "",
    //EMPLOYER_LOCATION_CODE: "",
    EMPLOYER_TOWN: "",
    EMPLOYER_COUNTRY: "",
    EMPLOYER_POSTCODE: "",
    EMPLOYER_COUNTRYPREFIX: "",
    EMPLOYER_COUNTY: "",
    //EMPLOYER_FAX: "",
    //EMPLOYER_VATRATE: "",

    //MEMBERSHIP: "",//[],

    BANK_NAME: "",
    REGISTERED: "",
    HELD_COUNTRY: "",
    HELD_CURRENCY: "",
    HELD_AMOUNT: "",
    HELD_DATE: "",

    ARE_YOU_IN_UK: "",
    WHEN_DID_YOU_ENTER_UK: "",
    DID_YOU_ENTER_LEGALLY: "",
    REASON_FOR_ILEGAL_ENTRY:"",
    VISA_START_DATE: "",
    VISA_END_DATE: "",
    VISA_STATUS: "",
    OUT_OF_THE_UK_BEFORE: "",
    COUNTRIES_TRAVELLED: "",//[],

    LAST_FIVE_VISITS: "",//[],
    ENTERED_UK_MEANS: "",
    REASON_FOR_ILEGAL_ENTRY: "",

    EVER_STAYED_BEYOND_EXPIRY: "",
    REASON_FOR_STAYING_BEYOND_EXPIRY: "",

    ANY_OTHER_COUNTRY_VISITED: "",//[],

    BREACHED_CONDITION_FOR_LEAVE: "",
    REASON_FOR_BREACH: "",

    WORK_WITHOUT_PERMIT: "",
    REASON_FOR_WORK_WITHOUT_PERMIT: "",

    RECEIVED_PUBLIC_FUNDS: "",
    REASON_RECEIVING_FUNDS: "",

    GIVE_FALSE_INFO: "",
    REASON_FOR_FALSE_INFO: "",

    USED_DECEPTION: "",
    REASON_FOR_DECEPTION: "",

    BREACHED_OTHER_LAWS: "",
    REASON_FOR_BREACHING__LAWS: "",

    VISA_REFUSAL_QUESTION: "",
    REASON_FOR_REFUSAL: "",

    PERMISSION_REFUSAL: "",
    REASON_FOR_PERMISSION_REFUSAL: "",

    ASYLUM_REFUSAL: "",
    REASON_FOR_ASYLUM_REFUSAL: "",

    EVER_DEPORTED: "",
    REASON_FOR_DEPORTATION: "",

    EVER_BANNED: "",
    REASON_FOR_BAN: "",

    CRIMINAL_OFFENSE: "",
    CRIMINAL_OFFENCE_ANSWER: "",

   
    PENDING_PERSECUTION: "",
    DETAILS_OF_PROSECUTIONS: "",
    
    TERRORIST_VIEW: "",
    DETAILS_OF_TERRORIST_CHARGES: "",
    
    WORKED_FOR_SECURITY: "",
    DETAILS_OF_WORK: "",
  });

  /* -------------------------use form instances start----------------------------- */

  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm({mode: "onChange", shouldUnregister: true, defaultValues: {...details}});

  /* -------------------------use form instances end----------------------------- */

  const appidRef = useRef();

  useEffect(() => {
    if (params?.appid === null || params?.appid=== undefined) {
        console.log("inside sideEffect edit 1", params?.appid,);
      return navigate("/dashboard/application_list", { replace: true });
    }
    else {
      appidRef.current = params?.appid;
            setAppid(params?.appid);

            setAppid((appid) => {
                console.log("returning AppID", appid);
                return appid;
            });      
    }
  }, [params?.appid]);

   useEffect(() => {

    if(permit) {
    document
      .getElementById("toglepanel")
      .addEventListener("click", togglePanel);

      const data = require('./Utils/CountryData.json')
        console.log(data)
        setCountries(data)
    }

  }, [permit]); 


  //const editingApp = useQuery(['getApplicationbyID', appid], () => api.editApp(appid), {
  const editingApp = useQuery(['getApplicationbyID', appidRef.current], () => api.editApp(appidRef.current), {
    refetchInterval: false,
    onSuccess: (data) => {

      console.log("edit app Successfull", data);
      console.log("edit app Successfull http status", data?.response?.status);

      if(data?.response?.status === 401){
        toast.error("Session Expired : Please Re-Login",{
          duration: 6000,
          });
       removeUserSession();
        navigate("/", {replace: false});
      }

      const AppDetails = {...details}
      AppDetails.APPTYPE_ID = data?.application_details[0]?.APPTYPE_ID ?? "";
      AppDetails.CLIENT_ID = data?.application_details[0]?.CLIENT_ID ?? "";
      AppDetails.COUNTRY_OF_BIRTH = data?.application_details[0]?.COUNTRY_OF_BIRTH ?? "";
      AppDetails.PLACE_OF_BIRTH = data?.application_details[0]?.PLACE_OF_BIRTH ?? "";

      AppDetails.NAME_CHANGE_QUESTION = data?.application_details[0]?.NAME_CHANGE_QUESTION ?? "";
      if(data?.name_change_details[0]?.ATTRIBUTES){
        console.log("namelist from DB", data?.name_change_details[0]?.ATTRIBUTES);
        console.log("namelist from DB parsed", JSON.parse(data?.name_change_details[0]?.ATTRIBUTES));
       setNameList(JSON.parse(data?.name_change_details[0]?.ATTRIBUTES));
       setNameList(nameList => { console.log("nameList:", nameList); return nameList;});
      }
      AppDetails.NATIONALITY = data?.application_details[0]?.NATIONALITY ?? "";
      AppDetails.email = data?.application_details[0]?.EMAIL ?? "";
      AppDetails.fullname = data?.application_details[0]?.FIRSTNAME + " "+ (data?.application_details[0]?.MIDDLENAME ?? "") + " " + data?.application_details[0]?.LASTNAME;
      AppDetails.mobilenumber = data?.application_details[0]?.MOBILE ?? "";
      
      AppDetails.OTHER_NATIONALITY_QUESTION = data?.application_details[0]?.OTHER_NATIONALITY_QUESTION ?? "";
      if(data?.nationality_details[0]?.ATTRIBUTES){
        console.log("nationality from DB parsed", JSON.parse(data?.nationality_details[0]?.ATTRIBUTES));
       setNationalityList(JSON.parse(data?.nationality_details[0]?.ATTRIBUTES)) ;
       setNationalityList(nationalityList => {console.log("nationalityList:", nationalityList); return nationalityList;});
      }

      AppDetails.PASSPORT_NO = data?.application_details[0]?.PASSPORT_NO ?? "";
      AppDetails.PASSPORT_ISSUED = data?.application_details[0]?.PASSPORT_ISSUED ?? "";
      AppDetails.PASSPORT_EXPIRY = data?.application_details[0]?.PASSPORT_EXPIRY  ?? "";
      AppDetails.ISSUING_AUTHORITY = data?.application_details[0]?.ISSUING_AUTHORITY ?? "";
      AppDetails.PLACE_OF_ISSUE = data?.application_details[0]?.PLACE_OF_ISSUE ?? "";
      AppDetails.NATIONAL_ID_QUESTION = data?.application_details[0]?.NATIONAL_ID_QUESTION ?? "";
      AppDetails.BRP_QUESTION = data?.application_details[0]?.BRP_QUESTION ?? "";
      AppDetails.BRP_NUMBER = data?.application_details[0]?.BRP_NUMBER ?? "";
      AppDetails.BRP_EXPIRY_DATE = data?.application_details[0]?.BRP_EXPIRY_DATE ?? "";

      AppDetails.NAME_MOTHER = data?.application_details[0]?.NAME_MOTHER ?? "";
      AppDetails.DOB_MOTHER = data?.application_details[0]?.DOB_MOTHER ?? "";
      AppDetails.NATIONALITY_MOTHER = data?.application_details[0]?.NATIONALITY_MOTHER ?? "";
      AppDetails.PLACE_OF_BIRTH_MOTHER = data?.application_details[0]?.PLACE_OF_BIRTH_MOTHER
      AppDetails.NAME_FATHER = data?.application_details[0]?.NAME_FATHER ?? "";
      AppDetails.DOB_FATHER = data?.application_details[0]?.DOB_FATHER ?? "";
      AppDetails.PLACE_OF_BIRTH_FATHER = data?.application_details[0]?.PLACE_OF_BIRTH_FATHER ?? "";
      AppDetails.NATIONALITY_FATHER = data?.application_details[0]?.NATIONALITY_FATHER ?? "";

      AppDetails.UK_NI_QUESTION = data?.application_details[0]?.UK_NI_QUESTION ?? "";
      AppDetails.UK_NI = data?.application_details[0]?.UK_NI ?? "";
      AppDetails.UK_DRIVER_LICENSE_QUESTION = data?.application_details[0]?.UK_DRIVER_LICENSE_QUESTION ?? "";
      AppDetails.UK_DRIVER_LICENSE = data?.application_details[0]?.UK_DRIVER_LICENSE ?? "";

      AppDetails.ADDRESS1 = data?.application_details[0]?.ADDRESS1 ?? "";
      AppDetails.ADDRESS2 = data?.application_details[0]?.ADDRESS2 ?? "";

     // AppDetails.LOCATION_NAME = data?.application_details[0]?.LOCATION_NAME ?? "";
     // AppDetails.LOCATION_CODE = data?.application_details[0]?.LOCATION_CODE ?? "";
      AppDetails.TOWN = data?.application_details[0]?.TOWN ?? "";
      AppDetails.COUNTY = data?.application_details[0]?.COUNTY ?? "";
      AppDetails.POSTCODE = data?.application_details[0]?.POSTCODE ?? "";
      AppDetails.COUNTRY_PREFIX = data?.application_details[0]?.COUNTRY_PREFIX ?? "";
      AppDetails.COUNTRY = data?.application_details[0]?.COUNTRY ?? "";
    //  AppDetails.FAX = data?.application_details[0]?.FAX ?? "";
    //  AppDetails.VATRATE = data?.application_details[0]?.VATRATE ?? "";

      AppDetails.DATE_MOVED_IN_ADDRESS = data?.application_details[0]?.DATE_MOVED_IN_ADDRESS ?? "";
      AppDetails.TIME_LIVED_AT_ADDRESS = data?.application_details[0]?.DATE_MOVED_IN_ADDRESS ? getdatedif(data?.application_details[0]?.DATE_MOVED_IN_ADDRESS) : "";
      AppDetails.HOME_QUESTION_ANSWER = data?.application_details[0]?.HOME_QUESTION_ANSWER ?? "";

      AppDetails.NUMBER_OF_BEDROOMS = data?.application_details[0]?.NUMBER_OF_BEDROOMS ?? "";
      AppDetails.NUMBER_OF_OTHERROOMS = data?.application_details[0]?.NUMBER_OF_OTHERROOMS ?? "";
      AppDetails.WHO_LIVES_THERE = data?.application_details[0]?.WHO_LIVES_THERE ?? "";

      AppDetails.LANDLORD_NAME = data?.application_details[0]?.LANDLORD_NAME ?? "";
      AppDetails.LANDLORD_EMAIL = data?.application_details[0]?.LANDLORD_EMAIL ?? "";
      AppDetails.LANDLORD_MOBILE = data?.application_details[0]?.LANDLORD_MOBILE ?? "";
    
      AppDetails.LANDLORD_ADDRESS1 = data?.application_details[0]?.LANDLORD_ADDRESS1 ?? "";
      AppDetails.LANDLORD_ADDRESS2 = data?.application_details[0]?.LANDLORD_ADDRESS2 ?? "";
    //  AppDetails.LANDLORD_LOCATION_NAME = data?.application_details[0]?.LANDLORD_LOCATION_NAME ?? "";
    //  AppDetails.LANDLORD_LOCATION_CODE = data?.application_details[0]?.LANDLORD_LOCATION_CODE ?? "";
      AppDetails.LANDLORD_TOWN = data?.application_details[0]?.LANDLORD_TOWN ?? "";
      AppDetails.LANDLORD_COUNTY = data?.application_details[0]?.LANDLORD_COUNTY ?? "";
      AppDetails.LANDLORD_POSTCODE = data?.application_details[0]?.LANDLORD_POSTCODE ?? "";
      AppDetails.LANDLORD_COUNTRY_PREFIX = data?.application_details[0]?.LANDLORD_COUNTRY_PREFIX ?? "";
      AppDetails.LANDLORD_COUNTRY = data?.application_details[0]?.LANDLORD_COUNTRY ?? "";
    //  AppDetails.LANDLORD_FAX = data?.application_details[0]?.LANDLORD_FAX ?? "";
    //  AppDetails.LANDLORD_VRATE = data?.application_details[0]?.LANDLORD_VRATE ?? "";

      AppDetails.PREVIOUS_ADDRESS1 = data?.application_details[0]?.PREVIOUS_ADDRESS1 ?? "";
      AppDetails.PREVIOUS_ADDRESS2 = data?.application_details[0]?.PREVIOUS_ADDRESS2 ?? "";
      AppDetails.TOWN_PREVIOUS = data?.application_details[0]?.TOWN_PREVIOUS ?? "";
      AppDetails.COUNTY_PREVIOUS = data?.application_details[0]?.COUNTY_PREVIOUS ?? "";
      AppDetails.POSTCODE_PREVIOUS = data?.application_details[0]?.POSTCODE_PREVIOUS ?? "";

    //  AppDetails.PREVIOUS_LOCATION_NAME = data?.application_details[0]?.PREVIOUS_LOCATION_NAME ?? "";
    //  AppDetails.PREVIOUS_LOCATION_CODE = data?.application_details[0]?.PREVIOUS_LOCATION_CODE ?? "";
      AppDetails.PREVIOUS_COUNTRY_PREFIX = data?.application_details[0]?.PREVIOUS_COUNTRY_PREFIX ?? "";
      AppDetails.COUNTRY_PREVIOUS = data?.application_details[0]?.COUNTRY_PREVIOUS ?? "";
    //  AppDetails.PREVIOUS_FAX = data?.application_details[0]?.PREVIOUS_FAX ?? "";
    //  AppDetails.PREVIOUS_VRATE = data?.application_details[0]?.PREVIOUS_VRATE ?? "";

      AppDetails.MARITAL_STATUS = data?.application_details[0]?.MARITAL_STATUS !== null ? data?.application_details[0]?.MARITAL_STATUS : "";
      AppDetails.DATE_OF_MARRIAGE = data?.application_details[0]?.DATE_OF_MARRIAGE ?? "";
      AppDetails.WHERE_YOU_GOT_MARRIED = data?.application_details[0]?.WHERE_YOU_GOT_MARRIED ?? "";              
      AppDetails.NAME_OF_SPOUSE = data?.application_details[0]?.NAME_OF_SPOUSE ?? "";
      AppDetails.DOB_SPOUSE = data?.application_details[0]?.DOB_SPOUSE ?? "";
      AppDetails.NATIONALITY_SPOUSE = data?.application_details[0]?.NATIONALITY_SPOUSE ?? "";                
      AppDetails.PASSPORT_SPOUSE = data?.application_details[0]?.PASSPORT_SPOUSE ?? "";
      AppDetails.WHERE_YOU_MET = data?.application_details[0]?.WHERE_YOU_MET ?? "";
      AppDetails.WHERE_RELATIONSHIP_BEGAN = data?.application_details[0]?.WHERE_RELATIONSHIP_BEGAN ?? "";
      AppDetails.WHEN_LAST_YOU_SAW_EACHOTHER = data?.application_details[0]?.WHEN_LAST_YOU_SAW_EACHOTHER ?? "";              
      AppDetails.LIVE_TOGETHER_QUESTION = data?.application_details[0]?.LIVE_TOGETHER_QUESTION ?? "";
      AppDetails.DATE_LIVING_TOGETHER = data?.application_details[0]?.DATE_LIVING_TOGETHER ?? "";

      AppDetails.DO_YOU_HAVE_CHILDREN = data?.application_details[0]?.DO_YOU_HAVE_CHILDREN ?? "";

      AppDetails.NUMBER_OF_DEPENDENT_CHILDREN = data?.application_details[0]?.NUMBER_OF_DEPENDENT_CHILDREN ?? 0;

      if(data?.children_details[0]?.ATTRIBUTES){
        console.log("childrenList from DB parsed", JSON.parse(data?.children_details[0]?.ATTRIBUTES));
       setChildrenList(JSON.parse(data?.children_details[0]?.ATTRIBUTES));
       setChildrenList((childrenList) => { console.log("childrenList:", childrenList); return childrenList;});
      }

      AppDetails.MARRIED_BEFORE_QUESTION = data?.application_details[0]?.MARRIED_BEFORE_QUESTION ?? "";
      if(data?.previous_marriage_details[0]?.PREVIOUS_MARRIAGE){
       setExList(JSON.parse(data?.previous_marriage_details[0]?.PREVIOUS_MARRIAGE));
       setExList((exList) => { return exList;});
      }

      AppDetails.PARTNER_MARRIED_BEFORE = data?.application_details[0]?.PARTNER_MARRIED_BEFORE ?? "";
      if(data?.partner_married_before_details[0]?.PARTNERS_EX){
       setCPexList(JSON.parse(data?.partner_married_before_details[0]?.PARTNERS_EX));
       setCPexList((cpexList) => {return cpexList;});
      }

      AppDetails.DO_YOU_HAVE_FAMILY_IN_HOME_COUNTRY = data?.application_details[0]?.DO_YOU_HAVE_FAMILY_IN_HOME_COUNTRY ?? "";
      if(data?.names_of_people_at_address_details[0]?.ATTRIBUTES){
       setHomectry(JSON.parse(data?.names_of_people_at_address_details[0]?.ATTRIBUTES))
       setHomectry((homectry) => {return homectry;});
      }

      AppDetails.HAVE_DEGREE_IN_ENGLISH = data?.application_details[0]?.HAVE_DEGREE_IN_ENGLISH ?? "";
      AppDetails.PASSED_RECOGNIZED_TEST = data?.application_details[0]?.PASSED_RECOGNIZED_TEST ?? "";
      AppDetails.WHAT_TEST_DID_YOU_PASS = data?.application_details[0]?.WHAT_TEST_DID_YOU_PASS ?? "";
      AppDetails.OTHER_CERTIFICATE = data?.application_details[0]?.OTHER_CERTIFICATE ?? "";

      AppDetails.QUALIFICATION_QUESTION = data?.application_details[0]?.QUALIFICATION_QUESTION ?? "";
      if(data?.qualification_details[0]?.QUALIFICATION){
        console.log("qualifi from DB parsed", JSON.parse(data?.qualification_details[0]?.QUALIFICATION));
       setQualifi(JSON.parse(data?.qualification_details[0]?.QUALIFICATION));
       setQualifi((qualifi) => { console.log("qualifi:", qualifi); return qualifi;});
      }

      AppDetails.EMPLOYMENT_STATUS = data?.employment_details[0]?.EMPLOYMENT_STATUS ?? "";
      AppDetails.EMPLOYMENT_DATE = data?.employment_details[0]?.EMPLOYMENT_DATE ?? "";
      AppDetails.EMPLOYER_NAME = data?.employment_details[0]?.EMPLOYER_NAME ?? "";
      AppDetails.EMPLOYER_PHONE = data?.employment_details[0]?.EMPLOYER_PHONE ?? "";
      AppDetails.EMPLOYER_EMAIL = data?.employment_details[0]?.EMPLOYER_EMAIL ?? "";
      AppDetails.SALARY = data?.employment_details[0]?.SALARY ?? "";
      AppDetails.JOB_TITLE = data?.employment_details[0]?.JOB_TITLE ?? "";
      AppDetails.EMPLOYER_ADDRESS1 = data?.employment_details[0]?.EMPLOYER_ADDRESS1 ?? "";
      AppDetails.EMPLOYER_ADDRESS2 = data?.employment_details[0]?.EMPLOYER_ADDRESS2 ?? "";
    //  AppDetails.EMPLOYER_LOCATION = data?.employment_details[0]?.EMPLOYER_LOCATION ?? "";
    //  AppDetails.EMPLOYER_LOCATION_CODE = data?.employment_details[0]?.EMPLOYER_LOCATION_CODE ?? "";
      AppDetails.EMPLOYER_TOWN = data?.employment_details[0]?.EMPLOYER_TOWN ?? "";
      AppDetails.EMPLOYER_COUNTRY = data?.employment_details[0]?.EMPLOYER_COUNTRY ?? "";
      AppDetails.EMPLOYER_POSTCODE = data?.employment_details[0]?.EMPLOYER_POSTCODE ?? "";
      AppDetails.EMPLOYER_COUNTRYPREFIX = data?.employment_details[0]?.EMPLOYER_COUNTRYPREFIX ?? "";
      AppDetails.EMPLOYER_COUNTY = data?.employment_details[0]?.EMPLOYER_COUNTY ?? "";
    //  AppDetails.EMPLOYER_FAX = data?.employment_details[0]?.EMPLOYER_FAX ?? "";
    //  AppDetails.EMPLOYER_VATRATE = data?.employment_details[0]?.EMPLOYER_VATRATE ?? "";

     
      /* if(data?.membership_details[0]?.ATTRIBUTES){
       setMembership(JSON.parse(data?.membership_details[0]?.ATTRIBUTES))
       setMembership((membership) => {return membership; });
      } */
      AppDetails.BANK_NAME = data?.maintenance_details[0]?.BANK_NAME ?? "";
      AppDetails.REGISTERED = data?.maintenance_details[0]?.REGISTERED ?? "";
      AppDetails.HELD_COUNTRY = data?.maintenance_details[0]?.HELD_COUNTRY ?? "";
      AppDetails.HELD_CURRENCY = data?.maintenance_details[0]?.HELD_CURRENCY ?? "";
      AppDetails.HELD_AMOUNT = data?.maintenance_details[0]?.HELD_AMOUNT ?? "";
      AppDetails.HELD_DATE = data?.maintenance_details[0]?.HELD_DATE ?? "";

    AppDetails.ARE_YOU_IN_UK = data?.application_details[0]?.ARE_YOU_IN_UK ?? "";
    AppDetails.WHEN_DID_YOU_ENTER_UK = data?.application_details[0]?.WHEN_DID_YOU_ENTER_UK ?? "";
    AppDetails.DID_YOU_ENTER_LEGALLY = data?.application_details[0]?.DID_YOU_ENTER_LEGALLY ?? "";
    AppDetails.VISA_START_DATE = data?.application_details[0]?.VISA_START_DATE ?? "";
    AppDetails.VISA_END_DATE = data?.application_details[0]?.VISA_END_DATE ?? "";

    if(AppDetails.VISA_END_DATE !== "" && moment(AppDetails.VISA_END_DATE).isAfter(moment(new Date())) === false){

      ///AppDetails.EVER_STAYED_BEYOND_EXPIRY = "Yes";
      expirychecRef.current = true;
     }

    AppDetails.VISA_STATUS = data?.application_details[0]?.VISA_STATUS ?? "";
    AppDetails.OUT_OF_THE_UK_BEFORE = data?.application_details[0]?.OUT_OF_THE_UK_BEFORE ?? "";
   
    if(data?.location_details[0]?.COUNTRIES_TRAVELLED){
      setCountriestravelled(JSON.parse(data?.location_details[0]?.COUNTRIES_TRAVELLED))
      setCountriestravelled((countriestravelled) => {return countriestravelled });
    }

    
    if(data?.location_details[0]?.LAST_FIVE_VISITS){
      setLastfivevisits(JSON.parse(data?.location_details[0]?.LAST_FIVE_VISITS))
      setLastfivevisits((lastfivevisits) => {return lastfivevisits;});
    }

    AppDetails.ENTERED_UK_MEANS = data?.application_details[0]?.ENTERED_UK_MEANS ?? "";
    AppDetails.REASON_FOR_ILEGAL_ENTRY = data?.application_details[0]?.REASON_FOR_ILEGAL_ENTRY ?? "";
    AppDetails.EVER_STAYED_BEYOND_EXPIRY = data?.application_details[0]?.EVER_STAYED_BEYOND_EXPIRY ?? "";
    AppDetails.REASON_FOR_STAYING_BEYOND_EXPIRY = data?.application_details[0]?.REASON_FOR_STAYING_BEYOND_EXPIRY ?? "";
   
    if(data?.application_details[0]?.ANY_OTHER_COUNTRY_VISITED){
      setAnyothercountry(JSON.parse(data?.application_details[0]?.ANY_OTHER_COUNTRY_VISITED))
      setAnyothercountry((anyothercountry) => {return anyothercountry; });
    }

    AppDetails.BREACHED_CONDITION_FOR_LEAVE = data?.application_details[0]?.BREACHED_CONDITION_FOR_LEAVE ?? "";
    AppDetails.REASON_FOR_BREACH = data?.application_details[0]?.REASON_FOR_BREACH ?? "";
    AppDetails.WORK_WITHOUT_PERMIT = data?.application_details[0]?.WORK_WITHOUT_PERMIT ?? "";
    AppDetails.REASON_FOR_WORK_WITHOUT_PERMIT = data?.application_details[0]?.REASON_FOR_WORK_WITHOUT_PERMIT ?? "";
    AppDetails.RECEIVED_PUBLIC_FUNDS = data?.application_details[0]?.RECEIVED_PUBLIC_FUNDS ?? "";
    AppDetails.REASON_RECEIVING_FUNDS = data?.application_details[0]?.REASON_RECEIVING_FUNDS ?? "";
    AppDetails.GIVE_FALSE_INFO = data?.application_details[0]?.GIVE_FALSE_INFO ?? "";
    AppDetails.REASON_FOR_FALSE_INFO = data?.application_details[0]?.REASON_FOR_FALSE_INFO ?? "";
    AppDetails.USED_DECEPTION = data?.application_details[0]?.USED_DECEPTION ?? "";
    AppDetails.REASON_FOR_DECEPTION = data?.application_details[0]?.REASON_FOR_DECEPTION ?? "";
    AppDetails.BREACHED_OTHER_LAWS = data?.application_details[0]?.BREACHED_OTHER_LAWS ?? "";
    AppDetails.REASON_FOR_BREACHING__LAWS = data?.application_details[0]?.REASON_FOR_BREACHING__LAWS ?? "";
    AppDetails.VISA_REFUSAL_QUESTION = data?.application_details[0]?.VISA_REFUSAL_QUESTION ?? "";
    AppDetails.REASON_FOR_REFUSAL = data?.application_details[0]?.REASON_FOR_REFUSAL ?? "";
    AppDetails.PERMISSION_REFUSAL = data?.application_details[0]?.PERMISSION_REFUSAL ?? "";
    AppDetails.REASON_FOR_PERMISSION_REFUSAL = data?.application_details[0]?.REASON_FOR_PERMISSION_REFUSAL ?? "";
    AppDetails.ASYLUM_REFUSAL = data?.application_details[0]?.ASYLUM_REFUSAL ?? "";
    AppDetails.REASON_FOR_ASYLUM_REFUSAL = data?.application_details[0]?.REASON_FOR_ASYLUM_REFUSAL ?? "";
    AppDetails.EVER_DEPORTED = data?.application_details[0]?.EVER_DEPORTED ?? "";
    AppDetails.REASON_FOR_DEPORTATION = data?.application_details[0]?.REASON_FOR_DEPORTATION ?? "";
    AppDetails.EVER_BANNED = data?.application_details[0]?.EVER_BANNED ?? "";
    AppDetails.REASON_FOR_BAN = data?.application_details[0]?.REASON_FOR_BAN ?? "";

    AppDetails.CRIMINAL_OFFENSE = data?.application_details[0]?.CRIMINAL_OFFENSE ?? "";
    AppDetails.CRIMINAL_OFFENCE_ANSWER = data?.character_details[0]?.CRIMINAL_OFFENCE_ANSWER ?? "";

    AppDetails.PENDING_PERSECUTION = data?.character_details[0]?.PENDING_PERSECUTION ?? "";
    AppDetails.DETAILS_OF_PROSECUTIONS = data?.character_details[0]?.DETAILS_OF_PROSECUTIONS ?? "";
    AppDetails.TERRORIST_VIEW = data?.character_details[0]?.TERRORIST_VIEW ?? "";
    AppDetails.DETAILS_OF_TERRORIST_CHARGES = data?.character_details[0]?.DETAILS_OF_TERRORIST_CHARGES ?? "";
    AppDetails.WORKED_FOR_SECURITY = data?.character_details[0]?.WORKED_FOR_SECURITY ?? "";
    AppDetails.DETAILS_OF_WORK = data?.character_details[0]?.DETAILS_OF_WORK ?? "";

      if(details?.APPTYPE_ID === ""){

      sleep(200).then(() => {

        toast.success('Application loaded Successfully!',{
          duration: 6000,
        });

        setDetails(AppDetails);

              setDetails((details) => {
                console.log(" edit details value:", details);
                return details;
              }); 
          });
        }
          setPermit(true);

  },
  onError: (error) => {
      console.log("Error Message",error); 
      setPermit(false);
  
    if(error?.response?.status === 401){
      toast.error("Session Expired : Please Re-Login",{
        duration: 6000,
        });
     removeUserSession();
      navigate("/", {replace: false});
    }
    else {
      toast.error("Something went wrong",{
        duration: 6000,
        });
    }

    }
  });

  const { loading: loadingedit } = editingApp;


   ////////Submit Mutation////////////

   const submitApp = useMutation(api.submitApp , {
    onSuccess: (data) => {
      console.log("Application Successfully!", data);
      
      let currentStep = 10;

      setWizardstep(currentStep);
      setWizardstep((wizardstep) => {
        console.log(" -wizardstep value:", wizardstep);
        return wizardstep;
      });

      sleep(2000).then(() => {

        toast.success('Application Successfully!',{
          duration: 8000,
        });

        navigate("/dashboard/application_list", {replace: false});

      });

    },
    onError: (error) => {

      console.log("Error Message",error); 
  
    if(error?.response?.status === 401){
      toast.error("Session Expired : Please Re-Login",{
        duration: 6000,
        });
     removeUserSession();
      navigate("/", {replace: false});
    }
    else {
      toast.error("Something went wrong",{
        duration: 6000,
        });
    }

    }
  });

  const { loading: loadingsubmit} = submitApp;


  ////////Save as Draft Mutation////////////

  const savedraftApp = useMutation(api.saveDraftApp , {
    onSuccess: (data) => {
      console.log("Draft saved Successfully!", data);
      
      getdatedif(details.DATE_MOVED_IN_ADDRESS);
      const timeLapsed = "Saved " + moment(elapsedTime.current).fromNow();

      sleep(2000).then(() => {

        toast.success('Draft saved Successfully!',{
          duration: 6000,
        });

        setElapsedtime(timeLapsed);

      });

    },
    onError: (error) => {

      console.log("Error Message",error); 
  
    if(error?.response?.status === 401){
      toast.error("Session Expired : Please Re-Login",{
        duration: 6000,
        });
     removeUserSession();
      navigate("/", {replace: false});
    }
    else {
      toast.error("Something went wrong",{
        duration: 6000,
        });
    }

    }
  });

  const { loading: loadingsdraft} = savedraftApp;

  /* useEffect(() => {
  
    editingApp.refetch();
    setDetails((details) => {
      console.log(" details at wizardstep :", details);
      return details;
    });
  
  }, [wizardstep]);  */

/*   useEffect(() => {
    
      if (callapi === "draft" && details.APPLICATION_ID === "") {
        //createmutate(location.state?.clientid);
        //creatingApp.mutate({"id":location.state.clientid})
        console.log("callapi :",  callapi, "clientid: ", location.state?.clientid)

        editingApp.mutate(location.state?.appid);
      }
      else {
        console.log("callapi :",  callapi, "action: ", location.state?.action)
      }

  }, [callapi]); */


  const [nameList, setNameList] = useState([
    { oldname_0: "", startdate_0: "", enddate_0: "" },
  ]);

  const [nationalityList, setNationalityList] = useState([
    { othernation_0: "", otherstartdate_0: "", otherenddate_0: "" },
  ]);

  const [exList, setExList] = useState([
    {
      exname_0: "",
      exdob_0: "",
      exnationality_0: "",
      exdateofmarriage_0: "",
      exdateofdivorce_0: "",
      explaceofmarr_0: ""
    },
  ]);

  const [cpexList, setCPexList] = useState([
    {
      partnersExName_0 : "",
      partnersExDob_0 : "",
      partnersExNationality_0 : "",
      partnersExDateofmarriage_0 : "",
      partnersExDateofdivorce_0 : "",
    },
  ]);

  const [homectry, setHomectry] = useState([{ relativeName_0: "", relationship_0: "" }]);

  const [qualifi, setQualifi] = useState([
    {
      countryofaward_0: "",
      countrystate_0: "",
      qualification_0: "",
      awardinginstitute_0: "",
      courselen_0: "",
      coursesubj_0: "",
      yearofaward_0: "",
      grade_0: "",
    },
  ]);

 /*  const [membership, setMembership] = useState([
    { memshiptype_0: "", memshipname_0: "", memshipissued_0: "", memshipexpiry_0: "" },
  ]); */

  const [countriestravelled, setCountriestravelled] = useState([
    {
      countryname_0: "",
      dateoftravel_0: "",
      dateofreturn_0: "",
      reasonfortravel_0: "",
    },
  ]);

  const [lastfivevisits, setLastfivevisits] = useState([
    {
      dateofentry_0: "",
      dateofdeparture_0: "",
      reasonforvisit_0: "",
    },
  ]);

  const [anyothercountry, setAnyothercountry] = useState([{ othercountryname_0: "" }]);

  const [myaddress, setMyaddress] = useState([
    {
      addressprime: "",
      addresssec: "",
      locname: "",
      loccode: "",
      town: "",
      county: "",
      postcode: "",
      countryprefix: "",
      addresscountry: "",
      fax: "",
      vatrate: "",
    },
  ]);

  const [prevaddr, setPrevaddr] = useState([
    {
      paddressprime: "",
      paddresssec: "",
      plocname: "",
      ploccode: "",
      ptown: "",
      pcounty: "",
      ppostcode: "",
      pcountryprefix: "",
      paddresscountry: "",
      pfax: "",
      pvatrate: "",
    },
  ]);

  const [empaddr, setEmpaddr] = useState([
    {
      eaddressprime: "",
      eaddresssec: "",
      elocname: "",
      eloccode: "",
      etown: "",
      ecounty: "",
      epostcode: "",
      ecountryprefix: "",
      eaddresscountry: "",
      efax: "",
      evatrate: "",
    },
  ]);

  const [llordaddr, setLlordaddr] = useState([
    {
      laddressprime: "",
      laddresssec: "",
      llocname: "",
      lloccode: "",
      ltown: "",
      lcounty: "",
      lpostcode: "",
      lcountryprefix: "",
      laddresscountry: "",
      lfax: "",
      lvatrate: "",
    },
  ]);

  const [yearsLived, setYearsLived] = useState(null);

  const getdatedif = (s) => {

    console.log("%%%%%%%%%%%%%% date moved value", s);

    let d = new Date(s);

    let oldDateMoment, newDateMoment, numYears, numMonths, numDays;

    oldDateMoment = moment(d);
    newDateMoment = moment(new Date());
  
    console.log("isnan d", isNaN(d));
    if(!isNaN(d)){

    numYears = newDateMoment.diff(oldDateMoment, "years");
    oldDateMoment = oldDateMoment.add(numYears, "years");
    numMonths = newDateMoment.diff(oldDateMoment, "months");
    oldDateMoment = oldDateMoment.add(numMonths, "months");
    numDays = newDateMoment.diff(oldDateMoment, "days"); 

    let difcount =
      numYears + " years |" + numMonths + " months |" + numDays + " days";
      const detailObject = {...details};
      detailObject.DATE_MOVED_IN_ADDRESS = s;
      detailObject.TIME_LIVED_AT_ADDRESS = difcount;
      setDetails(detailObject);
      setDetails((details) => {
          console.log("assigned date period: ",details.TIME_LIVED_AT_ADDRESS)
          return details;
      });
      console.log("type of numYears:", typeof numYears);
      setYearsLived(numYears);
      setYearsLived(yearsLived => {
        console.log("yearsLived", yearsLived);
        return yearsLived;
      })

   /*  setMovedate(difcount); */
    return difcount; 
    }
     return null;
  };

  const togglePanel = () => {
    const hpanel = document.getElementById("tpanel");

    if (hpanel.classList.contains("small-header")) {
      hpanel.classList.remove("small-header");
    } else {
      hpanel.classList.add("small-header");
    }
  };

  let expirychecRef =useRef(false);

  const handleChange = (event) => {
    event.persist();
   
    console.log("event name:",event.target.name, " event value:", event.target.value);
    
    if (event.target.name === "DATE_MOVED_IN_ADDRESS") {
      console.log(
        "date moved selected value",
        event.target.name + "+++++++++++++++" + event.target.value
      );
      let selectedDate = event.target.value
      
      getdatedif(selectedDate);

    }
    else if (event.target.name === "VISA_END_DATE") {

       console.log("check if date:", event.target.value);

       console.log("moment check if date is greater than today:", moment(event.target.value).isAfter(moment(new Date())));

       const frmdetail = {...details};

       if(moment(event.target.value).isAfter(moment(new Date()))  === false){

        frmdetail.EVER_STAYED_BEYOND_EXPIRY = "Yes";
        expirychecRef.current = true;
       }
       else{
        expirychecRef.current = false;
       }

      frmdetail[event.target.name] = event.target.value;
      setDetails(frmdetail);

        setDetails((details) => {

            console.log("details onchange",details);
            return details;
          });
    }
    else {
  
      console.log("countriestravelled", countriestravelled);

      const frmdetail = {...details};

      frmdetail[event.target.name] = event.target.value;
      setDetails(frmdetail);

        setDetails((details) => {

            console.log("details onchange",details);
            return details;
          });
        }

  };

  /*********Wizrd Navi************** */
  const _next = async () => {
    let currentStep = wizardstep;
    // If the current step is 1 or 2, then add one on "next" button click
    currentStep = currentStep + 1;
    const formValidated = await trigger();
    console.log("next :: saved details", details);
     if (formValidated) {
    //  console.log("valid input");

    setWizardstep(currentStep);

    setWizardstep((wizardstep) => {
      console.log(" + wizardstep value:", wizardstep);
      return wizardstep;
    });

      }
     else{
       console.log("validated : ", formValidated);
       toast.success('Kindly complete all fields marked in red', {
        style: {
          border: '1px solid #bf1650',
          padding: '16px',
          color: '#bf1650',
        },
        iconTheme: {
          primary: '#bf1650',
          secondary: '#FFFAEE',
        },
      });
     } 
    ///setWizardstep(currentStep);
  };

  const _prev = () => {
    let currentStep = wizardstep;
    console.log("prev :: saved details", details);
    // If the current step is 2 or 3, then subtract one on "previous" button click
    currentStep = currentStep - 1;
    setWizardstep(currentStep);
    setWizardstep((wizardstep) => {
      console.log(" -wizardstep value:", wizardstep);
      return wizardstep;
    });
  };

  const previousButton = () => {
    let currentStep = wizardstep;
    if (currentStep !== 1) {
      return (
        <button
          type="button"
          className="btn btn-default prev wizard-nav-btn"
          onClick={_prev}
          disabled={false}>
          &lt;&lt; Previous
        </button>
      );
    }
    return null;
  };

  const nextButton = () => {
    let currentStep = wizardstep;

    if (currentStep < 10) {
      return (
        <button
          type="button"
          className="btn btn-default next wizard-nav-btn"
          onClick={_next}
          disabled={false}>
          Next &gt;&gt;
        </button>
      );
    }
    return null;
  };


  const getTodaysDate = () => {
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth()+1; //January is 0 so need to add 1 to make it 1!
    let yyyy = today.getFullYear();
    if(dd<10){
      dd='0'+dd
    } 
    if(mm<10){
      mm='0'+mm
    } 

    today = yyyy+'-'+mm+'-'+dd;

    console.log("todays date",today);

    return today;
  }


  const saveDraft = () => {

    const updatedValue = {...details};

    console.log("nameList::-> ",nameList[0]?.oldname_0);
    
    if(nameList[0]?.oldname_0 !== ""){
      console.log("nameList::-> in ",nameList[0]?.oldname_0);
        
      updatedValue.OTHER_NAMES = JSON.stringify(nameList);
       /*  setDetails(updatedValue);

        setDetails(details => {
          console.log("details on nameList save", details);
          return details;
        })
        console.log("after NameList save update",details); */
    }
    
    console.log("nationalityList: ",nationalityList[0]?.othernation_0);
    if(nationalityList[0]?.othernation_0 !== ""){
      console.log("nationalityList in :->> ",nationalityList[0]?.othernation_0);
       
      updatedValue.OTHER_NATIONALITY = JSON.stringify(nationalityList);
      /* setDetails(updatedValue);
      
      setDetails(details => {
        console.log("details on nationalityList save", details);
        return details;
      })
      console.log("after NameList save update",details); */
    }
    
    console.log("childrenList: ",childrenList[0]?.childname_0);
    if(childrenList[0]?.childname_0 !== ""){
      
    updatedValue.CHILDREN = JSON.stringify(childrenList);
       
    }


    updatedValue.NUMBER_OF_DEPENDENT_CHILDREN = childrenList?.length ?? 0;
    
    console.log("exList: ",exList[0]?.exname_0);
    if(exList[0]?.exname_0 !== ""){
      
        updatedValue.PREVIOUS_MARRIAGE = JSON.stringify(exList);
       
    }
    
    
    console.log("cpexList: ",cpexList[0]?.partnersExName_0);
    if(cpexList[0]?.partnersExName_0 !== ""){
      
        updatedValue.PARTNERS_EX = JSON.stringify(cpexList);
       
    }
    
    
    console.log("homectry: ",homectry[0]?.relativeName_0);
    if(homectry[0]?.relativeName_0 !== ""){
       
        updatedValue.FAMILY_IN_HOME_COUNTRY = JSON.stringify(homectry);
        
    }
    
    
    console.log("qualifi: ",qualifi[0]);
    if(qualifi.length !== 0){
       
        updatedValue.QUALIFICATION = JSON.stringify(qualifi);
        
    }
    
    
   /*  console.log("membership: ",membership[0]?.memshiptype_0);
    if(membership[0]?.memshiptype_0 !== ""){
       
        updatedValue.MEMBERSHIP = JSON.stringify(membership);
       
    } */
    
    
    console.log("countriestravelled: ",countriestravelled[0]?.countryname_0);
    if(countriestravelled[0]?.countryname_0 !== ""){
       
         updatedValue.COUNTRIES_TRAVELLED = JSON.stringify(countriestravelled);
       
    }
    
    
    console.log("lastfivevisits: ",lastfivevisits[0]?.dateofentry_0);
    if(lastfivevisits[0]?.dateofentry_0 !== ""){
       
        updatedValue.LAST_FIVE_VISITS = JSON.stringify(lastfivevisits);
       
    }
    
    
    console.log("anyothercountry: ",anyothercountry[0]?.othercountryname_0);
    if(anyothercountry[0]?.othercountryname_0 !== ""){
       
        updatedValue.ANY_OTHER_COUNTRY_VISITED = JSON.stringify(anyothercountry);
       
    }

    setDetails(updatedValue);

    setDetails(details => {
      console.log("updated details", details);
      return details;
    })

    //e.preventDefault();
  
    console.log("final updatedValue", updatedValue);
    elapsedTime.current = new Date();
    savedraftApp.mutate(updatedValue);
  }


  const submitApplication = async () => {

    const updatedValue = {...details};

    console.log("nameList::-> ",nameList[0]?.oldname_0);
    
    if(nameList[0]?.oldname_0 !== ""){
      console.log("nameList::-> in ",nameList[0]?.oldname_0);
        
      updatedValue.OTHER_NAMES = JSON.stringify(nameList);
    
    }
    
    console.log("nationalityList: ",nationalityList[0]?.othernation_0);

    if(nationalityList[0]?.othernation_0 !== ""){
      console.log("nationalityList in :->> ",nationalityList[0]?.othernation_0);
       
      updatedValue.OTHER_NATIONALITY = JSON.stringify(nationalityList);
      
    }
    
    console.log("childrenList: ",childrenList[0]?.childname_0);

    if(childrenList[0]?.childname_0 !== ""){
      
    updatedValue.CHILDREN = JSON.stringify(childrenList);
       
    }

    updatedValue.NUMBER_OF_DEPENDENT_CHILDREN = childrenList?.length ?? 0;
    
    
    console.log("exList: ",exList[0]?.exname_0);
    if(exList[0]?.exname_0 !== ""){
      
        updatedValue.PREVIOUS_MARRIAGE = JSON.stringify(exList);
       
    }
    
    
    console.log("cpexList: ",cpexList[0]?.partnersExName_0);
    if(cpexList[0]?.partnersExName_0 !== ""){
      
        updatedValue.PARTNERS_EX = JSON.stringify(cpexList);
       
    }
    
    
    console.log("homectry: ",homectry[0]?.relativeName_0);
    if(homectry[0]?.relativeName_0 !== ""){
       
        updatedValue.FAMILY_IN_HOME_COUNTRY = JSON.stringify(homectry);
        
    }
    
    
    console.log("qualifi: ",qualifi[0]);
    if(qualifi.length !== 0){
       
        updatedValue.QUALIFICATION = JSON.stringify(qualifi);
        
    }
    
    
    console.log("countriestravelled: ",countriestravelled[0]?.countryname_0);

    if(countriestravelled[0]?.countryname_0 !== ""){
       
         updatedValue.COUNTRIES_TRAVELLED = JSON.stringify(countriestravelled);
       
    }
    
    
    console.log("lastfivevisits: ",lastfivevisits[0]?.dateofentry_0);

    if(lastfivevisits[0]?.dateofentry_0 !== ""){
       
        updatedValue.LAST_FIVE_VISITS = JSON.stringify(lastfivevisits);
       
    }
    
    
    console.log("anyothercountry: ",anyothercountry[0]?.othercountryname_0);

    if(anyothercountry[0]?.othercountryname_0 !== ""){
       
        updatedValue.ANY_OTHER_COUNTRY_VISITED = JSON.stringify(anyothercountry);
       
    }

    setDetails(updatedValue);

    setDetails(details => {
      console.log("updated details", details);
      return details;
    })

    //e.preventDefault();
  
    console.log("final updatedValue", updatedValue);

    elapsedTime.current = new Date();

    const formValidated = await trigger();
    if(formValidated){
    submitApp.mutate(updatedValue);
    }
    else {
      toast.error("Kindly complete all required fields",{
        duration: 6000,
        });
    }

  };

  /*********End Wizard Navigation********** */

  /*******************Handle Additional Names with Dates*************************** */
  // handle input change
  const handleAddNameChange = (e) => {
    const { name, value } = e.target;
    console.log(
      "event::" + e.target.name + "--" + e.target.value + "data-x", e.target.getAttribute("data-x")
    );

    const eventIndex = e.target.getAttribute("data-x");
    const list = [...nameList];
    list[eventIndex][name] = value;
    console.log("updated list", list);
    setNameList(list);

    setNameList((nameList) => {
      console.log("updated namelist", nameList);
      return nameList;
    });

   /*  const updatedValue = {};
    updatedValue.OTHER_NAMES = JSON.stringify(nameList);
    setDetails({
      ...details,
      ...updatedValue
    })

    console.log("Details after handleAddNameChange", details); */

  };

  // handle click event of the Remove button
  const handleRemoveName = (index) => {
    const list = [...nameList];
    list.splice(index, 1);
    setNameList(list);
  };

  // handle click event of the Add button
  const handleAddName = (i) => {
    i++;
    const name = "oldname_" + i;
    const from = "startdate_" + i;
    const to = "enddate_" + i;
    setNameList([...nameList, { [name]: "", [from]: "", [to]: "" }]);
    setNameList((nameList) => {
      console.log(" + nameList value:", nameList);
      return nameList;
    });
    /* const el = document.getElementById("NAME_CHANGE_QUESTIONid");
  //const list = [...nameList];
  let mxheight = ""+100*nameList.length+"px !important";
  console.log("mxheight::",mxheight)
  el.style.maxHeight = mxheight; */
  };

  /***************************End Additional Names and Dates************************** */

  /*******************Handle Additional Nationality with Dates*************************** */
  // handle input change
  const handleNationChange = (e) => {
    const { name, value } = e.target;
    console.log(
      "event::" + e.target.name + "--" + e.target.value + "--"
    );

    const eventIndex = e.target.getAttribute("data-x");
    const list = [...nationalityList];
    list[eventIndex][name] = value;
    console.log("updated list", list);
    setNationalityList(list);

    setNationalityList((nationalityList) => {
      console.log("updated namelist", nationalityList);
      return nationalityList;
    });

    /* const updatedValue = {};
    updatedValue.OTHER_NATIONALITY = JSON.stringify(nationalityList);
    setDetails({
      ...details,
      ...updatedValue
    })

    console.log("Details after handleNationChange", details); */

  };

  // handle click event of the Remove button
  const handleRemoveNation = (index) => {
    const list = [...nationalityList];
    list.splice(index, 1);
    setNationalityList(list);
  };

  // handle click event of the Add button
  const handleAddNation = (i) => {
    i++;
    const othernation = "othernation_" + i;
    const from = "otherstartdate_" + i;
    const to = "otherenddate_" + i;
    setNationalityList([
      ...nationalityList,
      { [othernation]: "", [from]: "", [to]: "" },
    ]);
    setNationalityList((nationalityList) => {
      console.log(" + nationalityList value:", nationalityList);
      return nationalityList;
    });
  };

  /***************************End Additional Nationality and Dates************************** */

  /*******************Handle Additional Child with Dates*************************** */
  // handle input change
  const handleChildChange = (e, index) => {
    const { name, value } = e.target;
    console.log(
      "event::" + e.target.name + "--" + e.target.value + "--" + index
    );
    const list = [...childrenList];
    list[index][name] = value;
    console.log("updated list", list);
    setChildrenList(list);

    setChildrenList((childrenList) => {
      console.log("updated childrenList", childrenList);
      return childrenList;
    });

    /* const updatedValue = {};
    updatedValue.CHILDREN = JSON.stringify(childrenList);
    setDetails({
      ...details,
      ...updatedValue
    })

    console.log("Details after handleChildChange", details);
 */
  };

  // handle click event of the Remove button
  const handleRemoveChild = (index) => {
    const list = [...childrenList];
    list.splice(index, 1);
    setChildrenList(list);
  };

  // handle click event of the Add button
  const handleAddChild = (i) => {
    i++;
    const gender = "gender_" + i;
    const childname = "childname_" + i;
    const dob = "dob_" + i;
    const childnationality = "childnationality_" + i;
    const passportno = "passportno_" + i;
    const passportissueddate = "passportissueddate_" + i;
    const passportexpiry = "passportexpiry_" + i; 
    const visatype =  "visatype_" + i;
    const visaissueddate = "visaissueddate_" + i;
    const visaexpiry = "visaexpiry_" + i;

    setChildrenList([
      ...childrenList,
      {
        [childname]: "",
        [gender]: "",
        [dob]: "",
        [childnationality]: "",
        [passportno]: "",
        [passportissueddate]: "",
        [passportexpiry]: "",
        [visatype]: "",
        [visaissueddate]: "",
        [visaexpiry]: "",
      },
    ]);

    setChildrenList((childrenList) => {
      console.log(" + childrenList value:", childrenList);
      return childrenList;
    });
  };

  /***************************End Additional Child and Dates************************** */

  /*******************Handle Additional Ex with Dates*************************** */
  // handle input change
  const handleExChange = (e, index) => {
   

    const { name, value } = e.target;
    console.log(
      "event::" + e.target.name + "--" + e.target.value + "--" + index
    );
    const list = [...exList];
    list[index][name] = value;
    console.log("updated list", list);
    setExList(list);

    setExList((exList) => {
      console.log("updated childrenList", exList);
      return exList;
    });

    /* const updatedValue = {};
    updatedValue.PREVIOUS_MARRIAGE = JSON.stringify(exList);
    setDetails({
      ...details,
      ...updatedValue
    })

    console.log("Details after handleChildChange", details); */

  };

  // handle click event of the Remove button
  const handleRemoveEx = (index) => {
    const list = [...exList];
    list.splice(index, 1);
    setExList(list);
  };

  // handle click event of the Add button
  const handleAddEx = (i) => {
    i++;
    const exname = "exname_" + i;
    const dob = "exdob_" + i;
    const exnationality = "exnationality_" + i;
    const exdateofmarriage = "exdateofmarriage_" + i;
    const exdateofdivorce = "exdateofdivorce_" + i;
    const explaceofmarr = "explaceofmarr_" + i;
    setExList([
      ...exList,
      {
      [exname]: "",
      [dob]: "",
      [exnationality]: "",
      [exdateofmarriage]: "",
      [exdateofdivorce]: "",
      [explaceofmarr]: ""
      },
    ]);

    setExList((exList) => {
      console.log(" + exList value:", exList);
      return exList;
    });
  };

  /***************************End Additional Ex and Dates************************** */

  /*******************Handle Additional Current Partner's Ex with Dates*************************** */
  // handle input change
  const handleCPexChange = (e, index) => {
    /* const { name, value } = e.target;
    console.log(
      "event::" + e.target.name + "--" + e.target.value + "--" + index
    );
    const list = [...cpexList];
    list[index][name] = value;
    console.log("updated list", list);
    setCPexList(list);

    setCPexList((cpexList) => {
      console.log("updated exList", cpexList);
      return cpexList;
    }); */

    const { name, value } = e.target;
    console.log(
      "event::" + e.target.name + "--" + e.target.value + "--" + index
    );
    const list = [...cpexList];
    list[index][name] = value;
    console.log("updated list", list);
    setCPexList(list);

    setCPexList((cpexList) => {
      console.log("updated childrenList", cpexList);
      return cpexList;
    });

    /* const updatedValue = {};
    updatedValue.PARTNERS_EX = JSON.stringify(cpexList);
    setDetails({
      ...details,
      ...updatedValue
    })

    console.log("Details after handleChildChange", details); */

  };

  // handle click event of the Remove button
  const handleRemoveCPex = (index) => {
    const list = [...cpexList];
    list.splice(index, 1);
    setCPexList(list);
  };

  // handle click event of the Add button
  const handleAddCPex = (i) => {
    i++;
    const partnersExName = "partnersExName_" + i;
    const partnersExDo = "partnersExDob_" + i;
    const partnersExNationality = "partnersExNationality_" + i;
    const partnersExDateofmarriage = "partnersExDateofmarriage_" + i;
    const partnersExDateofdivorce = "partnersExDateofdivorce_" + i;

    setCPexList([
      ...cpexList,
      {
        [partnersExName]: "",
        [partnersExDo]: "",
        [partnersExNationality]: "",
        [partnersExDateofmarriage]: "",
        [partnersExDateofdivorce]: "",
      },
    ]);

    setCPexList((cpexList) => {
      console.log(" + exList value:", cpexList);
      return cpexList;
    });

  };

  /***************************End Additional Current Partner's Ex and Dates************************** */

  /****************************Relation Handle************************* */

  // handle input change
  const handleRelChange = (e, index) => {
    /* const { name, value } = e.target;
    console.log(
      "event::" + e.target.name + "--" + e.target.value + "--" + index
    );
    const list = [...homectry];
    list[index][name] = value;
    console.log("updated list", list);
    setHomectry(list);

    setHomectry((homectry) => {
      console.log("updated homectry", homectry);
      return homectry;
    }); */

    const { name, value } = e.target;
    console.log(
      "event::" + e.target.name + "--" + e.target.value + "--" + index
    );
    const list = [...homectry];
    list[index][name] = value;
    console.log("updated list", list);
    setHomectry(list);

    setHomectry((homectry) => {
      console.log("updated homectry", homectry);
      return homectry;
    });

   /*  const updatedValue = {};
    updatedValue.FAMILY_IN_HOME_COUNTRY = JSON.stringify(homectry);
    setDetails({
      ...details,
      ...updatedValue
    })

    console.log("Details after handleChildChange", details); */
  };

  // handle click event of the Remove button
  const handleRemoveRel = (index) => {
    const list = [...homectry];
    list.splice(index, 1);
    setHomectry(list);
  };

  // handle click event of the Add button
  const handleAddRel = (i) => {
    i++;
    const relativeName = "relativeName_" + i;
    const relationship = "relationship_" + i;

    setHomectry([...homectry, { [relativeName]: "", [relationship]: "" }]);
    setHomectry((homectry) => {
      console.log(" + homectry value:", homectry);
      return homectry;
    });
  };

  /************************Relation handle end***************************** */

  /*********************************Qualification**************************************** */

  // handle input change
  const handleQualiChange = (e, index) => {
    /* const { name, value } = e.target;
    console.log(
      "event::" + e.target.name + "--" + e.target.value + "--" + index
    );
    const list = [...qualifi];
    list[index][name] = value;
    console.log("updated list", list);
    setQualifi(list);

    setQualifi((qualifi) => {
      console.log("updated homectry", qualifi);
      return qualifi;
    }); */

    const { name, value } = e.target;
    console.log(
      "event::" + e.target.name + "--" + e.target.value + "--" + index
    );
    const list = [...qualifi];
    list[index][name] = value;
    console.log("updated list", list);
    setQualifi(list);

    setQualifi((qualifi) => {
      console.log("updated homectry", qualifi);
      return qualifi;
    });

    /* const updatedValue = {};
    updatedValue.QUALIFICATION = JSON.stringify(qualifi);
    setDetails({
      ...details,
      ...updatedValue
    })

    console.log("Details after handleChildChange", details); */

  };

  // handle click event of the Remove button
  const handleRemoveQuali = (index) => {
    const list = [...qualifi];
    list.splice(index, 1);
    setQualifi(list);
    setQualifi(qualifi => {
      console.log("qualifi after remove", qualifi);
      return qualifi;
    })
  };

  // handle click event of the Add button
  const handleAddQuali = () => {
    //if (!isNaN(i) && i !== 0) i++;
    let i = 0;
    let qualiLength = qualifi.length;
    if (!isNaN(qualiLength) && qualiLength !== 0) 
    {
      i = qualiLength++;
    }
    else if(!isNaN(qualiLength) && qualiLength === 0){
         i = 0;
    }
    else {
      throw new Error("No valid value for iteration");
    }
    const countryofaward = "countryofaward_" + i;
    const countrystate = "countrystate_" + i;
    const qualification = "qualification_" + i;
    const awardinginstitute = "awardinginstitute_" + i;
    const courselen = "courselen_" + i;
    const coursesubj = "coursesubj_" + i;
    const yearofaward = "yearofaward_" + i;
    const grade = "grade_" + i;

    setQualifi([
      ...qualifi,
      {
        [countryofaward]: "",
        [countrystate]: "",
        [qualification]: "",
        [awardinginstitute]: "",
        [courselen]: "",
        [coursesubj]: "",
        [yearofaward]: "",
        [grade]: "",
      },
    ]);
    setQualifi((qualifi) => {
      console.log(" + qualifi value after addition:", qualifi);
      return qualifi;
    });
  };

  /******************************************End Qualification*************************** */

  /************************Handle Membership*****************8 */

  // handle input change
  /* const handleMemshipChange = (e, index) => {
  
    const { name, value } = e.target;
    console.log(
      "event::" + e.target.name + "--" + e.target.value + "--" + index
    );
    const list = [...membership];
    list[index][name] = value;
    console.log("updated list", list);
    setMembership(list);

    setMembership((membership) => {
      console.log("updated homectry", membership);
      return membership;
    });

  }; */

  // handle click event of the Remove button
  /* const handleRemoveMemship = (index) => {
    const list = [...membership];
    list.splice(index, 1);
    setMembership(list);
  }; */

  // handle click event of the Add button
  /* const handleAddMemship = (i) => {
    i++;
    const memshiptype = "memshiptype_"+i;
    const memshipname = "memshipname_"+i;
    const memshipissued = "memshipissued_"+i;
    const memshipexpiry = "memshipexpiry_"+i;

    setMembership([
      ...membership,
      {
        [memshiptype]: "",
        [memshipname]: "",
        [memshipissued]: "",
        [memshipexpiry]: "",
      },
    ]);

    setMembership((membership) => {
      console.log(" + membership value:", membership);
      return membership;
    });
  }; */

  /******************************************End Membership*************************** */

  /************************Handle countriestravelled*****************8 */

  // handle input change
  const handleCTChange = (e, index) => {
    /* const { name, value } = e.target;
    console.log(
      "event::" + e.target.name + "--" + e.target.value + "--" + index
    );
    const list = [...countriestravelled];
    list[index][name] = value;
    console.log("updated list", list);
    setCountriestravelled(list);

    setCountriestravelled((countriestravelled) => {
      console.log("updated countriestravelled", countriestravelled);
      return countriestravelled;
    }); */

    const { name, value } = e.target;
    console.log(
      "event::" + e.target.name + "--" + e.target.value + "--" + index
    );
    const list = [...countriestravelled];
    list[index][name] = value;
    console.log("updated list", list);
    setCountriestravelled(list);

    setCountriestravelled((countriestravelled) => {
      console.log("updated homectry", countriestravelled);
      return countriestravelled;
    });

   /*  const updatedValue = {};
    updatedValue.COUNTRIES_TRAVELLED = JSON.stringify(countriestravelled);
    setDetails({
      ...details,
      ...updatedValue
    })

    console.log("Details after handleChildChange", details); */

  };

  // handle click event of the Remove button
  const handleRemoveCT = (index) => {
    const list = [...countriestravelled];
    list.splice(index, 1);
    setCountriestravelled(list);
  };

  // handle click event of the Add button
  const handleAddCT = (i) => {

    console.log("countriestravelled i before:", i);

    i++;
    const countryname = "countryname_" + i
    const dateoftravel = "dateoftravel_" + i;
    const dateofreturn = "dateofreturn_" + i;
    const reasonfortravel = "reasonfortravel_" + i;

    console.log("countriestravelled i after:", i);

     setCountriestravelled([
      ...countriestravelled,
      {
        [countryname]: "",
        [dateoftravel]: "",
        [dateofreturn]: "",
        [reasonfortravel]: "",
      },
    ]);

    setCountriestravelled((countriestravelled) => {
      console.log(" + countriestravelled value:", countriestravelled);
      return countriestravelled;
    });
  };

  /******************************************End countriestravelled*************************** */

  /************************Handle lastfivevisits*****************8 */

  // handle input change
  const handleLFChange = (e, index) => {
    /* const { name, value } = e.target;
    console.log(
      "event::" + e.target.name + "--" + e.target.value + "--" + index
    );
    const list = [...lastfivevisits];
    list[index][name] = value;
    console.log("updated list", list);
    setLastfivevisits(list);

    setLastfivevisits((lastfivevisits) => {
      console.log("updated lastfivevisits", lastfivevisits);
      return lastfivevisits;
    }); */

    const { name, value } = e.target;
    console.log(
      "event::" + e.target.name + "--" + e.target.value + "--" + index
    );
    const list = [...lastfivevisits];
    list[index][name] = value;
    console.log("updated list", list);
    setLastfivevisits(list);

    setLastfivevisits((lastfivevisits) => {
      console.log("updated lastfivevisits", lastfivevisits);
      return lastfivevisits;
    });

   /*  const updatedValue = {};
    updatedValue.LAST_FIVE_VISITS = JSON.stringify(lastfivevisits);
    setDetails({
      ...details,
      ...updatedValue
    })

    console.log("Details after handleChildChange", details); */
  };

  // handle click event of the Remove button
  const handleRemoveLF = (index) => {
    const list = [...lastfivevisits];
    list.splice(index, 1);
    setLastfivevisits(list);
  };

  // handle click event of the Add button
  const handleAddLF = (i) => {
    i++;
    const dateofentry = "dateofentry_"+i;
    const dateofdeparture = "dateofdeparture_"+i;
    const reasonforvisit = "reasonforvisit_"+i;

    setLastfivevisits([
      ...lastfivevisits,
      { [dateofentry]: "", [dateofdeparture]: "", [reasonforvisit]: "" },
    ]);

    setLastfivevisits((lastfivevisits) => {
      console.log(" + lastfivevisits value:", lastfivevisits);
      return lastfivevisits;
    });
  };

  /******************************************End lastfivevisits*************************** */

  /******************************************start anyothercountry*************************** */

  // handle input change
  const handleOtherCountryChange = (e, index) => {
    /* const { name, value } = e.target;
    console.log(
      "event::" + e.target.name + "--" + e.target.value + "--" + index
    );
    const list = [...anyothercountry];
    list[index][name] = value;
    console.log("updated list", list);
    setAnyothercountry(list);

    setAnyothercountry((anyothercountry) => {
      console.log("updated anyothercountry", anyothercountry);
      return anyothercountry;
    }); */

    const { name, value } = e.target;
    console.log(
      "event::" + e.target.name + "--" + e.target.value + "--" + index
    );
    const list = [...anyothercountry];
    list[index][name] = value;
    console.log("updated list", list);
    setAnyothercountry(list);

    setAnyothercountry((anyothercountry) => {
      console.log("updated anyothercountry", anyothercountry);
      return anyothercountry;
    });

    /* const updatedValue = {};
    updatedValue.ANY_OTHER_COUNTRY_VISITED = JSON.stringify(anyothercountry);
    setDetails({
      ...details,
      ...updatedValue
    })

    console.log("Details after handleChildChange", details); */

  };

  // handle click event of the Remove button
  const handleRemoveOtherCountry = (index) => {
    const list = [...anyothercountry];
    list.splice(index, 1);
    setAnyothercountry(list);
  };

  // handle click event of the Add button
  const handleAddOtherCountry = (i) => {
    i++;
    const othercountryname = "othercountryname_" + i;
    setAnyothercountry([...anyothercountry, { [othercountryname]: "" }]);

    setAnyothercountry((anyothercountry) => {
      console.log(" + anyothercountry value:", anyothercountry);
      return anyothercountry;
    });
  };

  /******************************************End anyothercountry*************************** */

  /******************************************handle my address*************************** */

  // handle input change
  const handleMAChange = (e) => {
    const { name, value } = e.target;
    console.log("event::" + e.target.name + "--" + e.target.value);
    const list = [...myaddress];
    list[0][name] = value;
    console.log("updated list", list);
    setMyaddress(list);

    setMyaddress((myaddress) => {
      console.log("updated myaddress", myaddress);
      return myaddress;
    });
  };

  /******************************************handle my address end*************************** */

  /******************************************handle prev address*************************** */

  // handle input change
  const handlePAChange = (e) => {
    const { name, value } = e.target;
    console.log("event::" + e.target.name + "--" + e.target.value);
    const list = [...prevaddr];
    list[0][name] = value;
    console.log("updated list", list);
    setPrevaddr(list);

    setPrevaddr((prevaddr) => {
      console.log("updated prevaddr", prevaddr);
      return prevaddr;
    });
  };

  /******************************************handle prev address end*************************** */

  /******************************************handle Emp address*************************** */

  // handle input change
  const handleEAChange = (e) => {
    const { name, value } = e.target;
    console.log("event::" + e.target.name + "--" + e.target.value);
    const list = [...empaddr];
    list[0][name] = value;
    console.log("updated list", list);
    setEmpaddr(list);

    setEmpaddr((empaddr) => {
      console.log("updated empaddr", empaddr);
      return empaddr;
    });
  };

  /******************************************handle Emp address end*************************** */

  /******************************************handle Land lord address*************************** */

  // handle input change
  const handleLAChange = (e) => {
    const { name, value } = e.target;
    console.log("event::" + e.target.name + "--" + e.target.value);
    const list = [...llordaddr];
    list[0][name] = value;
    console.log("updated list", list);
    setLlordaddr(list);

    setLlordaddr((llordaddr) => {
      console.log("updated llordaddr", llordaddr);
      return llordaddr;
    });
  };

  /******************************************handle Land lord address end*************************** */
  if( editingApp.isLoading){
    return (
      <div style={{display:"grid", justifyContent: "center", alignContent: "center"}}>Loading ...</div>
    )
  }

  if(!permit){
      return (
                <div style={{display:"grid", justifyContent: "center", alignContent: "center"}}>
                  {editingApp.isError && (
                      <div className="mt-5 text-center">
                        <ul className="errorlist">
                        {
                          errorlist.map((errorItem, i) =>{
                              return(
                                  <li key={i} className="text-muted-error">
                                      {errorItem[i]}
                                  </li>
                              )
                          })
                        }
                          </ul>
                        </div>
                      )}
                      </div>
              )
              
            }

  return (
    
      <Fragment>
      <div className="normalheader" id="tpanel">
        <div className="hpanel">
          <div className="panel-body">
            <a className="small-header-action" id="toglepanel" href={void(0)}>
              <div className="clip-header">
                <i className="fa fa-arrow-up"></i>
              </div>
            </a>

            <div id="hbreadcrumb" className="pull-right m-t-lg">
              <ol className="hbreadcrumb breadcrumb">
                <li>
                  <a href={void(0)}>i-visas</a>
                </li>
                <li>
                  <span>Client</span>
                </li>
                <li className="active">
                  <span>Overview </span>
                </li>
              </ol>
            </div>
            <h2 className="font-light m-b-xs">Application form</h2>
            <small>Kindly complete the below forms to proceed</small>
          </div>
        </div>
      </div>

      <div className="content">
        <div className="row">
          <div className="col-lg-12">
            <div className="hpanel hgreen">
              <div className="panel-body">
                <p>Sections:</p>
                <div className="text-center m-b-md m-t-md" id="wizardControl">
                  <div className="stepper-wrapper">
                    <div
                      className={
                        wizardstep === 1
                          ? "stepper-item completed active"
                          : "stepper-item completed"
                      }>
                      <div className="step-counter">1</div>
                      <div className="step-name">General</div>
                    </div>
                    <div
                      className={
                        wizardstep === 2
                          ? "stepper-item active"
                          : wizardstep > 2
                          ? "stepper-item completed"
                          : "stepper-item"
                      }>
                      <div className="step-counter">2</div>
                      <div className="step-name">Accommodation</div>
                    </div>
                    <div
                      className={
                        wizardstep === 3
                          ? "stepper-item active"
                          : wizardstep > 3
                          ? "stepper-item completed"
                          : "stepper-item"
                      }>
                      <div className="step-counter">3</div>
                      <div className="step-name">Family</div>
                    </div>
                    <div
                      className={
                        wizardstep === 4
                          ? "stepper-item active"
                          : wizardstep > 4
                          ? "stepper-item completed"
                          : "stepper-item"
                      }>
                      <div className="step-counter">4</div>
                      <div className="step-name">English Proficiency</div>
                    </div>
                    <div
                      className={
                        wizardstep === 5
                          ? "stepper-item active"
                          : wizardstep > 5
                          ? "stepper-item completed"
                          : "stepper-item"
                      }>
                      <div className="step-counter">5</div>
                      <div className="step-name">Qualifications</div>
                    </div>
                    <div
                      className={
                        wizardstep === 6
                          ? "stepper-item active"
                          : wizardstep > 6
                          ? "stepper-item completed"
                          : "stepper-item"
                      }>
                      <div className="step-counter">6</div>
                      <div className="step-name">Employment</div>
                    </div>
                    <div
                      className={
                        wizardstep === 7
                          ? "stepper-item active"
                          : wizardstep > 7
                          ? "stepper-item completed"
                          : "stepper-item"
                      }>
                      <div className="step-counter">7</div>
                      <div className="step-name">Maintenance</div>
                    </div>
                    <div
                      className={
                        wizardstep === 8
                          ? "stepper-item active"
                          : wizardstep > 8
                          ? "stepper-item completed"
                          : "stepper-item"
                      }>
                      <div className="step-counter">8</div>
                      <div className="step-name">Travel</div>
                    </div>
                    <div
                      className={
                        wizardstep === 9
                          ? "stepper-item active"
                          : wizardstep > 9
                          ? "stepper-item completed"
                          : "stepper-item"
                      }>
                      <div className="step-counter">9</div>
                      <div className="step-name">Character</div>
                    </div>
                    <div
                      className={
                        wizardstep === 10
                          ? "stepper-item active"
                          : wizardstep > 10
                          ? "stepper-item completed"
                          : "stepper-item"
                      }>
                      <div className="step-counter">10</div>
                      <div className="step-name">Completed</div>
                    </div>
                    {/* <div
                      className={
                        wizardstep === 11
                          ? "stepper-item completed active"
                          : "stepper-item"
                      }>
                      <div className="step-counter">11</div>
                      <div className="step-name">Completed</div>
                    </div> */}
                  </div>
                </div>

                {/* tab content */}

                <div className="tab-content">
                  {wizardstep === 1 && (
                    <div
                      id="step1"
                      className={
                        wizardstep === 1
                          ? "p-m tab-pane wizardpane active"
                          : "p-m tab-pane wizardpane"
                      }>
                      <div className="row">
                        <div className="col-lg-12">

                        <div className="row col-bb">
                            <div className="form-group col-lg-2">
                            <label className="label-clr">APPLICATION ID:</label>
                              <input
                                type="text"
                                value={details.APPTYPE_ID || ""}
                                className="form-control"
                                readOnly
                                name="APPTYPE_ID"
                                style={{ontWeight: "bold", fontSize: "25px", textAlign: "center"}}
                              />
                            </div>

                            <div className="form-group col-lg-5"></div>
                            <div className="form-group col-lg-5"></div>
                        </div>

                          <div className="row col-bb">
                            <div className="form-group col-lg-7">
                              <label className="label-clr">Full Name</label>
                              <input
                                type="text"
                                value={details.fullname || ""}
                                className="form-control"
                                readOnly
                                name="fullname"
                              />
                            </div>

                            <div className="form-group col-lg-5">
                              <h5 className="list-group-item-heading label-h5">
                                Have you been known by any other names ?
                              </h5>

                              <div className="radio radio-success radio-inline">
                                <input
                                  type="radio"
                                  id="othernamey"
                                  value="Yes"
                                  name="NAME_CHANGE_QUESTION"
                                  checked={details.NAME_CHANGE_QUESTION === "Yes"}
                                  {...register('NAME_CHANGE_QUESTION', { required: { value: true, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                />
                                <label htmlFor="othernamey">Yes </label>
                              </div>
                              <div className="radio radio-success radio-inline">
                                <input
                                  type="radio"
                                  id="othernamen"
                                  value="No"
                                  name="NAME_CHANGE_QUESTION"
                                  checked={details.NAME_CHANGE_QUESTION === "No"}
                                  {...register('NAME_CHANGE_QUESTION', { required: { value: true, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                />
                                <label htmlFor="othernamen">No</label>
                              </div>
                              {errors.NAME_CHANGE_QUESTION && 
                                  <p className="errorValidate">{errors.NAME_CHANGE_QUESTION.message}</p>
                                  }
                            </div>
                          </div>

                          {/* Next Row field 2 */}

                          <div
                            className={
                              details.NAME_CHANGE_QUESTION === "Yes"
                                ? "txtareahide collapsin"
                                : "txtareahide"
                            }
                            id="NAME_CHANGE_QUESTIONid"
                            aria-expanded={details.NAME_CHANGE_QUESTION === "Yes"}>
                            {nameList.map((x, i) => {
                              return (
                                <div
                                  className="row justify-content-center col-bb"
                                  key={i}>
                                  <div className="col-lg-4">
                                    <label className="label-clr">
                                      Previous Name
                                    </label>
                                    <input
                                      type="text"
                                      data-x={i}
                                      value={x[`oldname_${i}`] || ""}
                                      {...register(`oldname_${i}`, { 
                                      required: { value: details.NAME_CHANGE_QUESTION === "Yes" && x[`oldname_${i}`] === "" ? true : false, message: "This field is required"},
                                      pattern: {value: /^[A-Za-z\ ]+$/i, message: "Invalid Name"},
                                      onChange: (e) => {handleAddNameChange(e)} })}
                                      className="form-control"
                                      name={`oldname_${i}`}
                                    />
                                    {errors[`oldname_${i}`] && (
                                    <p className="errorValidate">{errors[`oldname_${i}`].message}</p>
                                    )}
                                  </div>

                                  <div className="col-lg-6">
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label className="label-clr">
                                          From
                                        </label>
                                        <input
                                         {...register(`startdate_${i}`, { 
                                           required: { value: details.NAME_CHANGE_QUESTION === "Yes"  && x[`startdate_${i}`] === "" ? true : false, message: "This field is required"},
                                           onChange: (e) => {handleAddNameChange(e)} })}
                                          value={x[`startdate_${i}`] || ""}
                                          name={`startdate_${i}`}
                                          max={x[`emddate_${i}`]}
                                          className="form-control"
                                          type="date"
                                          data-x={i}
                                        />
                                      </div>
                                      {errors[`startdate_${i}`] && (
                                  <p className="errorValidate">This field is required</p>
                                  )}
                                    </div>

                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label className="label-clr">To</label>
                                        <input
                                          data-x={i}
                                          {...register(`enddate_${i}`, { 
                                            required: { value: details.NAME_CHANGE_QUESTION === "Yes" && x[`enddate_${i}`] === ""  ? true : false, message: "This field is required"}, 
                                            onChange: (e) => {handleAddNameChange(e)} })}
                                          value={x[`enddate_${i}`] || ""}
                                          min={x[`startdate_${i}`]}
                                          name={`enddate_${i}`}
                                          className="form-control"
                                          type="date"
                                        />
                                      </div>
                                      {errors[`enddate_${i}`] && (
                                  <p className="errorValidate">This field is required</p>
                                  )}
                                    </div>
                                  </div>

                                  <div className="col-lg-2">
                                    <div
                                      className="text-left m-t-xs"
                                      style={{
                                        paddingTop: "15%",
                                        lineHeight: "1.8em",
                                      }}>
                                      {i !== 0 && (
                                        <button
                                          className="mr10 delbtn"
                                          onClick={() => handleRemoveName(i)}>
                                          <i className="fa fa-trash-o"></i> Remove Name
                                        </button>
                                      )}
                                      {i === 0 && (
                                        <button
                                          className="addbtn"
                                          style={{padding:"0px 4px"}}
                                          onClick={() => handleAddName(i)}>
                                          <i className="fa fa-plus"></i> Add Name
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>

                          <div className="row col-bb">
                            <div className="form-group col-lg-6">
                              <label className="label-clr">
                                Country of Birth
                              </label>
                              <select
                                name="COUNTRY_OF_BIRTH"
                                value={details.COUNTRY_OF_BIRTH || ""}
                               {...register('COUNTRY_OF_BIRTH', { required: { value: details.COUNTRY_OF_BIRTH === "" ? true : false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                className={`form-select m-b-3 ${errors.COUNTRY_OF_BIRTH && "seleval"}`}
                                style={{ width: "100%" }}>
                                <option value="">select country</option>
                                {
                                  countries.map((item) =>{
                                      return(
                                          <option key={item.country}>
                                              {item.country}
                                          </option>
                                      )
                                  })
                                }
                              </select>
                               {errors.COUNTRY_OF_BIRTH && (
                                  <p className="errorValidate">This field is required</p>
                                  )}
                            </div>

                            <div className="form-group col-lg-6">
                              <label className="label-clr">
                                Place of Birth
                              </label>

                              <input
                                type="text"
                                 {...register('PLACE_OF_BIRTH', { required: { value: details.PLACE_OF_BIRTH === "" ? true : false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                value={details.PLACE_OF_BIRTH || ""}
                                className="form-control"
                                name="PLACE_OF_BIRTH"
                              />
                              {errors.PLACE_OF_BIRTH && (
                                  <p className="errorValidate">{errors.PLACE_OF_BIRTH.message}</p>
                                  )}
                            </div>
                            
                          </div>

                          <div className="row col-bb">
                            <div className="form-group col-lg-7">
                              <label className="label-clr">
                                Current nationality
                              </label>
                              <input
                                type="text"
                               
                                {...register('NATIONALITY', { required: { value: details.NATIONALITY === "" ? true : false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                value={details.NATIONALITY || ""}
                                className="form-control"
                                name="NATIONALITY"
                              />
                              {errors.NATIONALITY && (
                                  <p className="errorValidate">This field is required</p>
                                  )}
                            </div>

                            <div className="form-group col-lg-5">
                              <h5 className="list-group-item-heading label-h5">
                                Any other nationalities ?
                              </h5>

                              <div className="radio radio-success radio-inline">
                                <input
                                  type="radio"
                                  id="OTHER_NATIONALITY_QUESTIONy"
                                  value="Yes"
                                  name="OTHER_NATIONALITY_QUESTION"
                                  checked={details.OTHER_NATIONALITY_QUESTION === "Yes"}
                                  {...register('OTHER_NATIONALITY_QUESTION', { required: { value: true, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                />
                                <label htmlFor="OTHER_NATIONALITY_QUESTIONy">Yes </label>
                              </div>
                              <div className="radio radio-success radio-inline">
                                <input
                                  type="radio"
                                  id="OTHER_NATIONALITY_QUESTIONn"
                                  value="No"
                                  name="OTHER_NATIONALITY_QUESTION"
                                  checked={details.OTHER_NATIONALITY_QUESTION === "No"}
                                 {...register('OTHER_NATIONALITY_QUESTION', { required: { value: true, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                />
                                <label htmlFor="OTHER_NATIONALITY_QUESTIONn">No</label>
                              </div>
                              {errors.OTHER_NATIONALITY_QUESTION && (
                                  <p className="errorValidate">This field is required</p>
                                  )}
                            </div>
                          </div>

                          <div
                            className={
                              details.OTHER_NATIONALITY_QUESTION === "Yes"
                                ? "txtareahide collapsin"
                                : "txtareahide"
                            }
                            id="OTHER_NATIONALITY_QUESTIONid"
                            aria-expanded={details.OTHER_NATIONALITY_QUESTION === "Yes"}>
                            {nationalityList.map((x, i) => {
                              return (
                                <div
                                  className="row justify-content-center col-bb"
                                  key={i}>
                                  <div className="col-lg-4">
                                    <label className="label-clr">
                                      Other Nationality
                                    </label>
                                    <input
                                      type="text"
                                      data-x={i}
                                      value={x[`othernation_${i}`] || ""}
                                      {...register(`othernation_${i}`, { 
                                        required: { value: details.OTHER_NATIONALITY_QUESTION === "Yes" && x[`othernation_${i}`] === "" ? true:false, message: "This field is required" }, 
                                        pattern: {value: /^[A-Za-z\ ]+$/i, message: "Invalid Name"},
                                        onChange: (e) => {handleNationChange(e)} })}
                                      /* onChange={(e) => handleNationChange(e, i)} */
                                      className="form-control"
                                      name={`othernation_${i}`}
                                    />
                                    {errors[`othernation_${i}`] && (
                                  <p className="errorValidate">{errors[`othernation_${i}`].message}</p>
                                  )}
                                  </div>

                                  <div className="col-lg-6">
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label className="label-clr">
                                          From
                                        </label>
                                        <input
                                        data-x={i}
                                          {...register(`otherstartdate_${i}`, { 
                                        required: { value: details.OTHER_NATIONALITY_QUESTION === "Yes" && x[`otherstartdate_${i}`] === "" ? true:false, message: "This field is required" }, 
                                        onChange: (e) => {handleNationChange(e)} })}
                                          value={x[`otherstartdate_${i}`] || ""}
                                          name={`otherstartdate_${i}`}
                                          max={x[`otherenddate_${i}`]}
                                          className="form-control"
                                          type="date"
                                        />
                                         {errors[`otherstartdate_${i}`] && (
                                  <p className="errorValidate">{errors[`otherstartdate_${i}`].message}</p>
                                  )}
                                      </div>
                                    </div>

                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label className="label-clr">To</label>
                                        <input
                                          data-x={i}
                                          {...register(`otherenddate_${i}`, { 
                                          required: { value: details.OTHER_NATIONALITY_QUESTION === "Yes" && x[`otherenddate_${i}`] === "" ? true:false, message: "This field is required" },  
                                          onChange: (e) => {handleNationChange(e)} })}
                                          value={x[`otherenddate_${i}`] || ""}
                                          name={`otherenddate_${i}`}
                                          min={x[`otherstartdate_${i}`]}
                                          className="form-control"
                                          type="date"
                                        />
                                         {errors[`otherenddate_${i}`] && (
                                  <p className="errorValidate">{errors[`otherenddate_${i}`].message}</p>
                                  )}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-lg-2">
                                    <div
                                      className="text-left m-t-xs"
                                      style={{
                                        paddingTop: "18%",
                                        lineHeight: "1.8em",
                                      }}>
                                      {i !== 0 && (
                                        <button
                                          className="mr10 delbtn"
                                          style={{padding:"0px 4px"}}
                                          onClick={() => handleRemoveNation(i)}>
                                          <i className="fa fa-trash-o"></i> Remove Nationality
                                        </button>
                                      )}
                                      {i === 0 && (
                                        <button
                                          className="addbtn"
                                          style={{padding:"0px 4px"}}
                                          onClick={() => handleAddNation(i)}>
                                          <i className="fa fa-plus"></i> Add Nationality
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>

                          <div className="row justify-content-center col-bb">
                            <div className="form-group  col-lg-6">
                              <label className="label-clr">
                                Current passport number
                              </label>
                              <input
                                type="text"
                                value={details.PASSPORT_NO || ""}
                                
                                {...register("PASSPORT_NO", { 
                                          required: { value: details.PASSPORT_NO === "" ? true : false, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                className="form-control"
                                name="PASSPORT_NO"
                              />
                               {errors.PASSPORT_NO && (
                                  <p className="errorValidate">{errors.PASSPORT_NO.message}</p>
                                  )}
                            </div>

                            <div className="form-group col-lg-6">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="label-clr">
                                    Issue date{" "}
                                  </label>
                                  <input
                                    {...register("PASSPORT_ISSUED", { 
                                          required: { value: details.PASSPORT_ISSUED === "" ? true : false, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                    value={details.PASSPORT_ISSUED || ""}
                                    name="PASSPORT_ISSUED"
                                    className="form-control"
                                    max={details.PASSPORT_ISSUED}
                                    type="date"
                                  />
                                  {errors.PASSPORT_ISSUED && (
                                  <p className="errorValidate">{errors.PASSPORT_ISSUED.message}</p>
                                  )}
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="label-clr">
                                    Expiry date{" "}
                                  </label>
                                  <input
                                    {...register("PASSPORT_EXPIRY", { 
                                          required: { value: details.PASSPORT_EXPIRY === "" ? true : false, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                    value={details.PASSPORT_EXPIRY || ""}
                                    min={details.PASSPORT_ISSUED}
                                    name="PASSPORT_EXPIRY"
                                    className="form-control"
                                    type="date"
                                  />{errors.PASSPORT_EXPIRY && (
                                  <p className="errorValidate">{errors.PASSPORT_EXPIRY.message}</p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row col-bb">
                            <div className="form-group col-lg-6">
                              <label className="label-clr">
                                Issuing authority
                              </label>
                              <input
                                type="text"
                                value={details.ISSUING_AUTHORITY || ""}
                               {...register("ISSUING_AUTHORITY", { 
                                          required: { value: details.ISSUING_AUTHORITY === "" ? true : false, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                className="form-control"
                                name="ISSUING_AUTHORITY"
                              />
                              {errors.ISSUING_AUTHORITY && (
                                  <p className="errorValidate">{errors.ISSUING_AUTHORITY.message}</p>
                                  )}
                            </div>

                            <div className="form-group col-lg-6">
                              <label className="label-clr">
                                Place of issue
                              </label>
                              <input
                                type="text"
                                value={details.PLACE_OF_ISSUE || ""}
                                {...register("PLACE_OF_ISSUE", { 
                                          required: { value: details.PLACE_OF_ISSUE === "" ? true : false, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                className="form-control"
                                name="PLACE_OF_ISSUE"
                              />
                              {errors.PLACE_OF_ISSUE && (
                                  <p className="errorValidate">{errors.PLACE_OF_ISSUE.message}</p>
                                  )}
                            </div>
                          </div>

                          <div className="row col-bb">
                            <div className="form-group col-lg-5">
                              <h5 className="list-group-item-heading label-h5">
                                Do you have a national ID card for your country
                                of nationality ?
                              </h5>

                              <div className="radio radio-success radio-inline">
                                <input
                                  type="radio"
                                  id="NATIONAL_ID_QUESTIONy"
                                  value="Yes"
                                  name="NATIONAL_ID_QUESTION"
                                  checked={details.NATIONAL_ID_QUESTION === "Yes"}
                                  {...register("NATIONAL_ID_QUESTION", { 
                                          required: { value: true, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                />
                                <label htmlFor="NATIONAL_ID_QUESTIONy">Yes </label>
                              </div>
                              <div className="radio radio-success radio-inline">
                                <input
                                  type="radio"
                                  id="NATIONAL_ID_QUESTIONn"
                                  value="No"
                                  name="NATIONAL_ID_QUESTION"
                                  checked={details.NATIONAL_ID_QUESTION === "No"}
                                  {...register("NATIONAL_ID_QUESTION", { 
                                          required: { value: true, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                />
                                <label htmlFor="NATIONAL_ID_QUESTIONn">No</label>
                              </div>
                              {errors.NATIONAL_ID_QUESTION && (
                                  <p className="errorValidate">{errors.NATIONAL_ID_QUESTION.message}</p>
                                  )}
                            </div>

                            <div
                              className={
                                details.BRP_QUESTION === "Yes"
                                  ? "form-group col-lg-2"
                                  : "form-group col-lg-7"
                              }>
                              <h5 className="list-group-item-heading label-h5">
                                Do you have BRP?
                              </h5>

                              <div className="radio radio-success radio-inline">
                                <input
                                  type="radio"
                                  id="BRP_QUESTIONy"
                                  value="Yes"
                                  name="BRP_QUESTION"
                                  checked={details.BRP_QUESTION === "Yes"}
                                  {...register("BRP_QUESTION", { 
                                          required: { value: true, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                />
                                <label htmlFor="BRP_QUESTIONy">Yes </label>
                              </div>
                              <div className="radio radio-success radio-inline">
                                <input
                                  type="radio"
                                  id="BRP_QUESTIONn"
                                  value="No"
                                  name="BRP_QUESTION"
                                  checked={details.BRP_QUESTION === "No"}
                                  {...register("BRP_QUESTION", { 
                                          required: { value: true, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                />
                                <label htmlFor="BRP_QUESTIONn">No</label>
                              </div>
                              {errors.BRP_QUESTION && (
                                  <p className="errorValidate">{errors.BRP_QUESTION.message}</p>
                                  )}
                            </div>

                            <div
                              className={
                                details.BRP_QUESTION === "Yes"
                                  ? "form-group col-lg-5 whide collapsinw"
                                  : "whide"
                              }
                              style={{paddingRight: "15px"}}
                              aria-expanded={details.BRP_QUESTION === "Yes"}>
                              <div
                                className="col-md-6"
                                style={{ paddingRight: "0px" }}>
                                <label className="label-clr">BRP Number</label>
                                <input
                                  type="text"
                                  {...register("BRP_NUMBER", { 
                                          required: { value: (details.BRP_QUESTION === "Yes" && details.BRP_NUMBER === "") ? true:false, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                  value={details.BRP_NUMBER || ""}
                                  className="form-control"
                                  name="BRP_NUMBER"
                                />
                                {errors.BRP_NUMBER && (
                                  <p className="errorValidate">{errors.BRP_NUMBER.message}</p>
                                  )}
                              </div>

                              <div
                                className="col-md-6"
                                style={{ paddingRight: "0px" }}>
                                <label className="label-clr">
                                  Date of Expiry
                                </label>
                                <input
                                  type="date"
                                 {...register("BRP_EXPIRY_DATE", { 
                                          required: { value: (details.BRP_QUESTION === "Yes" && details.BRP_EXPIRY_DATE === "") ? true:false, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                  value={details.BRP_EXPIRY_DATE || ""}
                                  className="form-control"
                                  name="BRP_EXPIRY_DATE"
                                  style={{ padding: "6px 3px" }}
                                />
                                {errors.BRP_EXPIRY_DATE && (
                                  <p className="errorValidate">{errors.BRP_EXPIRY_DATE.message}</p>
                                  )}
                              </div>

                            </div>
                          </div>

                          <div className="row justify-content-center col-bb">
                            <div className="form-group  col-lg-4">
                              <label className="label-clr">
                              Mother's Name
                              </label>
                              <input
                                type="text"
                                value={details.NAME_MOTHER || ""}
                                {...register("NAME_MOTHER", { 
                                          required: { value: details.NAME_MOTHER === "" ? true : false, message: "This field is required" },
                                          pattern: {value: /^[A-Za-z\ ]+$/i, message: "Invalid Name"}, 
                                          onChange: (e) => {handleChange(e)} })}
                                className="form-control"
                                name="NAME_MOTHER"
                              />
                              {errors.NAME_MOTHER && (
                                  <p className="errorValidate">{errors.NAME_MOTHER.message}</p>
                                  )}
                            </div>

                            <div className="form-group col-lg-4">
                              <div className="form-group">
                                <label className="label-clr">
                                  Date of Birth
                                </label>
                                <input
                                  {...register("DOB_MOTHER", { 
                                          required: { value: details.DOB_MOTHER === "" ? true : false, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                  value={details.DOB_MOTHER || ""}
                                  name="DOB_MOTHER"
                                  className="form-control"
                                  type="date"
                                />
                                {errors.DOB_MOTHER && (
                                  <p className="errorValidate">{errors.DOB_MOTHER.message}</p>
                                  )}
                              </div>
                            </div>

                            <div className="form-group col-lg-4">
                              <div className="form-group">
                                <label className="label-clr">
                                  Nationality{" "}
                                </label>
                                <select
                                  name="NATIONALITY_MOTHER"
                                  value={details.NATIONALITY_MOTHER || ""} // Prop: The email input data
                                  {...register("NATIONALITY_MOTHER", { 
                                          required: {value: details.NATIONALITY_MOTHER === "" ? true : false, message:"Mother's Nationality is required"}, 
                                          onChange: (e) => {handleChange(e)} })}
                                  className={`form-select m-b-3 ${errors.NATIONALITY_MOTHER && "seleval"}`}
                                  style={{ width: "100%" }}>
                                  <option value=''>select country</option>
                                      {
                                       countries.map((item) =>{
                                           return(
                                               <option key={item.country}>
                                                   {item.country}
                                               </option>
                                           )
                                       })
                                      }
                                </select>
                              </div>
                              {errors.NATIONALITY_MOTHER && (
                                  <p className="errorValidate">{errors.NATIONALITY_MOTHER.message}</p>
                                  )}
                            </div>
                          </div>

                          <div className="row col-bb">
                            <div className="form-group  col-lg-12">
                              <label className="label-clr">
                                Mother's Place of birth
                              </label>
                              <input
                                type="text"
                                value={details.PLACE_OF_BIRTH_MOTHER || ""}
                                 {...register("PLACE_OF_BIRTH_MOTHER", { 
                                          required: { value: details.PLACE_OF_BIRTH_MOTHER === "" ? true : false, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                className="form-control"
                                name="PLACE_OF_BIRTH_MOTHER"
                              />
                              {errors.PLACE_OF_BIRTH_MOTHER && (
                                  <p className="errorValidate">{errors.PLACE_OF_BIRTH_MOTHER.message}</p>
                                  )}
                            </div>
                          </div>


<div className="row justify-content-center col-bb">
                            <div className="form-group  col-lg-4">
                              <label className="label-clr">
                              Father's Name
                              </label>
                              <input
                                type="text"
                                value={details.NAME_FATHER || ""}
                                {...register("NAME_FATHER", { 
                                          required: { value: details.NAME_FATHER === "" ? true : false, message: "This field is required" },
                                          pattern: {value: /^[A-Za-z\ ]+$/i, message: "Invalid Name"}, 
                                          onChange: (e) => {handleChange(e)} })}
                                className="form-control"
                                name="NAME_FATHER"
                              />
                              {errors.NAME_FATHER && (
                                  <p className="errorValidate">{errors.NAME_FATHER.message}</p>
                                  )}
                            </div>

                            <div className="form-group col-lg-4">
                              <div className="form-group">
                                <label className="label-clr">
                                  Date of Birth
                                </label>
                                <input
                                  {...register("DOB_FATHER", { 
                                          required: { value: details.DOB_FATHER === "" ? true : false, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                  value={details.DOB_FATHER || ""}
                                  name="DOB_FATHER"
                                  className="form-control"
                                  type="date"
                                />
                                {errors.DOB_FATHER && (
                                  <p className="errorValidate">{errors.DOB_FATHER.message}</p>
                                  )}
                              </div>
                            </div>

                            <div className="form-group col-lg-4">
                              <div className="form-group">
                                <label className="label-clr">
                                  Nationality{" "}
                                </label>
                                <select
                                  name="NATIONALITY_FATHER"
                                  {...register("NATIONALITY_FATHER", { 
                                    required: {value: details.NATIONALITY_FATHER === "" ? true : false, message:"Father's Nationality is required"}, 
                                          onChange: (e) => {handleChange(e)} })}
                                  value={details.NATIONALITY_FATHER || ""} // Prop: The email input data
                                  
                                  className={`form-select m-b-3 ${errors.NATIONALITY_MOTHER && "seleval"}`}
                                  style={{ width: "100%" }}>
                                  <option value="">select country</option>
                                      {
                                       countries.map((item) =>{
                                           return(
                                               <option key={item.country}>
                                                   {item.country}
                                               </option>
                                           )
                                       })
                                      }
                                </select>
                              </div>
                              {errors.NATIONALITY_FATHER && (
                                  <p className="errorValidate">{errors.NATIONALITY_FATHER.message}</p>
                                  )}
                            </div>
                          </div>


                          <div className="row col-bb">
                            <div className="form-group  col-lg-12">
                              <label className="label-clr">
                                Father's Place of birth
                              </label>
                              <input
                                type="text"
                                value={details.PLACE_OF_BIRTH_FATHER || ""}
                                 {...register("PLACE_OF_BIRTH_FATHER", { 
                                          required: { value: details.PLACE_OF_BIRTH_FATHER === "" ? true : false, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                className="form-control"
                                name="PLACE_OF_BIRTH_FATHER"
                              />
                              {errors.PLACE_OF_BIRTH_FATHER && (
                                  <p className="errorValidate">{errors.PLACE_OF_BIRTH_FATHER.message}</p>
                                  )}
                            </div>
                          </div>

                          <div className="row col-bb">
                            <div className={ details.UK_NI_QUESTION === "Yes" ? "form-group col-lg-3" : "form-group col-lg-6"}>
                              <h5 className="list-group-item-heading label-h5">
                                Do you have a UK NI Number ?
                              </h5>

                              <div className="radio radio-success radio-inline">
                                <input
                                  type="radio"
                                  id="UK_NI_QUESTIONy"
                                  value="Yes"
                                  name="UK_NI_QUESTION"
                                  checked={details.UK_NI_QUESTION === "Yes"}
                                  {...register("UK_NI_QUESTION", { 
                                          required: { value: true, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                />
                                <label htmlFor="UK_NI_QUESTIONy">Yes </label>
                              </div>
                              <div className="radio radio-success radio-inline">
                                <input
                                  type="radio"
                                  id="UK_NI_QUESTIONn"
                                  value="No"
                                  name="UK_NI_QUESTION"
                                  checked={details.UK_NI_QUESTION === "No"}
                                  {...register("UK_NI_QUESTION", { 
                                          required: { value: true, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                />
                                <label htmlFor="UK_NI_QUESTIONn">No</label>
                              </div>
                              {errors.UK_NI_QUESTION && (
                                  <p className="errorValidate">{errors.UK_NI_QUESTION.message}</p>
                                  )}
                            </div>

                            <div
                              className={
                                details.UK_NI_QUESTION === "Yes"
                                  ? "form-group col-lg-3 whide collapsinw"
                                  : "whide"
                              }
                              aria-expanded={details.UK_NI_QUESTION === "Yes"}>
                              <div
                                className="col-md-6"
                                style={{ paddingRight: "0px" }}>
                                <label className="label-clr">UK NI</label>
                                <input
                                  type="text"
                                  {...register("UK_NI", { 
                                          required: { value: (details.UK_NI_QUESTION === "Yes" && details.UK_NI === "") ? true:false, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                  value={details.UK_NI || ""}
                                  className="form-control"
                                  name="UK_NI"
                                />
                                {errors.UK_NI && (
                                  <p className="errorValidate">{errors.UK_NI.message}</p>
                                  )}
                              </div>
                              </div>

                            <div className={details.UK_DRIVER_LICENSE_QUESTION === "Yes" ? "form-group col-lg-3" : "form-group col-lg-6"}>
                              <h5 className="list-group-item-heading label-h5">
                                Do you have a UK driving licence ?
                              </h5>

                              <div className="radio radio-success radio-inline">
                                <input
                                  type="radio"
                                  id="UK_DRIVER_LICENSE_QUESTIONy"
                                  value="Yes"
                                  name="UK_DRIVER_LICENSE_QUESTION"
                                  checked={details.UK_DRIVER_LICENSE_QUESTION === "Yes"}
                                  {...register("UK_DRIVER_LICENSE_QUESTION", { 
                                          required: { value: true, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                />
                                <label htmlFor="UK_DRIVER_LICENSE_QUESTIONy">Yes </label>
                              </div>
                              <div className="radio radio-success radio-inline">
                                <input
                                  type="radio"
                                  id="UK_DRIVER_LICENSE_QUESTIONn"
                                  value="No"
                                  name="UK_DRIVER_LICENSE_QUESTION"
                                  checked={details.UK_DRIVER_LICENSE_QUESTION === "No"}
                                  {...register("UK_DRIVER_LICENSE_QUESTION", { 
                                          required: { value: true, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                />
                                <label htmlFor="UK_DRIVER_LICENSE_QUESTIONn">No</label>
                              </div>
                              {errors.UK_DRIVER_LICENSE_QUESTION && (
                                  <p className="errorValidate">{errors.UK_DRIVER_LICENSE_QUESTION.message}</p>
                                  )}
                            </div>

                            <div
                              className={
                                details.UK_DRIVER_LICENSE_QUESTION === "Yes"
                                  ? "form-group col-lg-3 whide collapsinw"
                                  : "whide"
                              }
                              aria-expanded={details.UK_DRIVER_LICENSE_QUESTION === "Yes"}>
                              <div
                                className="col-md-6"
                                style={{ paddingRight: "0px" }}>
                                <label className="label-clr">Licence No.</label>
                                <input
                                  type="text"
                                  {...register("UK_DRIVER_LICENSE", { 
                                          required: { value: (details.UK_DRIVER_LICENSE_QUESTION === "Yes"  && details.UK_DRIVER_LICENSE === "") ? true:false, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                  value={details.UK_DRIVER_LICENSE || ""}
                                  className="form-control"
                                  name="UK_DRIVER_LICENSE"
                                />
                                {errors.UK_DRIVER_LICENSE && (
                                  <p className="errorValidate">{errors.UK_DRIVER_LICENSE.message}</p>
                                  )}
                              </div>
                              </div>
                          </div>

                          <div className="row col-bb">
                            <div className="form-group col-lg-6">
                              <label className="label-clr">Email Address</label>
                              <input
                                type="email"
                                value={details.email || ""}
                                className="form-control"
                                readOnly
                                name="email"
                              />
                            </div>

                            <div className="form-group col-lg-6">
                              <label className="label-clr">Mobile Number</label>
                              <input
                                type="tel"
                                pattern="^(\+)\d{3}-\d{3}-\d{3}-\d{4}$"
                                value={details.mobilenumber || ""}
                                className="form-control"
                                readOnly
                                name="mobilenumber"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="text-right m-t-xs">
                        {previousButton()}
                        {nextButton()}
                        {/* <a className="btn btn-default prev" href={void(0)}>Previous</a>
        <a className="btn btn-default next" href={void(0)}>Next</a> */}
                      </div>
                    </div>
                  )}

                  {wizardstep === 2 && (
                    <div
                      id="step2"
                      className={
                        wizardstep === 2
                          ? "p-m tab-pane wizardpane active"
                          : "p-m tab-pane wizardpane"
                      }>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="row col-bb">
                            <div className="form-group col-lg-12">
                              <label className="label-tpr">
                                Please provide your current Address
                              </label>

                              <div className="col-lg-12">
                                <div className="row rowbuttom">
                                  <div className="form-group col-lg-6">
                                    <label className="label-clr">
                                      Address 1
                                    </label>
                                    <input
                                      type="text"
                                      {...register("ADDRESS1", { 
                                          required: { value: details.ADDRESS1 === "" ? true : false, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                      value={details.ADDRESS1 || ""}
                                      className="form-control"
                                      name="ADDRESS1"
                                    />
                                    {errors.ADDRESS1 && (
                                  <p className="errorValidate">{errors.ADDRESS1.message}</p>
                                  )}
                                  </div>
                                  <div className="form-group col-lg-6">
                                    <label className="label-clr">
                                      Address 2
                                    </label>
                                    <input
                                      type="text"
                                      {...register("ADDRESS2", { 
                                          required: { value: details.ADDRESS2 === "" ? true : false, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                      value={details.ADDRESS2 || ""}
                                      className="form-control"
                                      name="ADDRESS2"
                                    />
                                    {errors.ADDRESS2 && (
                                  <p className="errorValidate">{errors.ADDRESS2.message}</p>
                                  )}
                                  </div>
                                </div>

                                <div className="row rowbuttom">
                                  {/* <div className="form-group col-lg-4">
                                    <label className="label-clr">
                                      {" "}
                                      Location Name
                                    </label>
                                    <input
                                      type="text"
                                      {...register("LOCATION_NAME", { 
                                          required: { value: true, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                      value={details.LOCATION_NAME || ""}
                                      className="form-control"
                                      name="LOCATION_NAME"
                                    />
                                    {errors.LOCATION_NAME && (
                                  <p className="errorValidate">{errors.LOCATION_NAME.message}</p>
                                  )}
                                  </div>
                                  <div className="form-group col-lg-4">
                                    <label className="label-clr">
                                      Location Code
                                    </label>
                                    <input
                                      type="text"
                                      {...register("LOCATION_CODE", { 
                                          required: { value: true, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                      value={details.LOCATION_CODE || ""}
                                      className="form-control"
                                      name="LOCATION_CODE"
                                    />
                                    {errors.LOCATION_CODE && (
                                  <p className="errorValidate">{errors.LOCATION_CODE.message}</p>
                                  )}
                                  </div> */}

                                  <div className="form-group col-lg-4">
                                    <label className="label-clr">Town</label>
                                    <input
                                      type="text"
                                      {...register("TOWN", { 
                                          required: { value: details.TOWN === "" ? true : false, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                      value={details.TOWN || ""}
                                      className="form-control"
                                      name="TOWN"
                                    />
                                    {errors.TOWN && (
                                  <p className="errorValidate">{errors.TOWN.message}</p>
                                  )}
                                  </div>

                                  <div className="form-group col-lg-4">
                                    <label className="label-clr"> County</label>
                                    <input
                                      type="text"
                                      
                                      {...register("COUNTY", { 
                                          required: { value: details.COUNTY === "" ? true : false, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                      value={details.COUNTY || ""}
                                      className="form-control"
                                      name="COUNTY"
                                    />
                                    {errors.COUNTY && (
                                  <p className="errorValidate">{errors.COUNTY.message}</p>
                                  )}
                                  </div>
                                  <div className="form-group col-lg-4">
                                    <label className="label-clr">
                                      Post Code
                                    </label>
                                    <input
                                      type="text"
                                      {...register("POSTCODE", { 
                                          required: { value: details.POSTCODE === "" ? true : false, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                      value={details.POSTCODE || ""}
                                      className="form-control"
                                      name="POSTCODE"
                                    />
                                    {errors.POSTCODE && (
                                  <p className="errorValidate">{errors.POSTCODE.message}</p>
                                  )}
                                  </div>
                                </div>

                                <div className="row rowbuttom">
                                  {/* <div className="form-group col-lg-4">
                                    <label className="label-clr"> County</label>
                                    <input
                                      type="text"
                                      
                                      {...register("COUNTY", { 
                                          required: { value: true, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                      value={details.COUNTY || ""}
                                      className="form-control"
                                      name="COUNTY"
                                    />
                                    {errors.COUNTY && (
                                  <p className="errorValidate">{errors.COUNTY.message}</p>
                                  )}
                                  </div>
                                  <div className="form-group col-lg-4">
                                    <label className="label-clr">
                                      Post Code
                                    </label>
                                    <input
                                      type="text"
                                      {...register("POSTCODE", { 
                                          required: { value: true, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                      value={details.POSTCODE || ""}
                                      className="form-control"
                                      name="POSTCODE"
                                    />
                                    {errors.POSTCODE && (
                                  <p className="errorValidate">{errors.POSTCODE.message}</p>
                                  )}
                                  </div> */}

                                  <div className="form-group col-lg-4">
                                    <div className="form-group">
                                      <label className="label-clr">                                     
                                        Country
                                      </label>
                                      <select
                                        name="COUNTRY"                        
                                        value={
                                          details.COUNTRY || ""
                                        } // Prop: The email input data
                                       {...register("COUNTRY", { 
                                          required: { value: details.COUNTRY === "" ? true:false, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                        className={errors.COUNTRY ? "form-select m-b-3 seleval" :  "form-select m-b-3" }
                                     style={{ width: "100%" }}>
                                      <option value=''>select country</option>
                                      {
                                       countries.map((item) =>{
                                           return(
                                               <option key={item.country}>
                                                   {item.country}
                                               </option>
                                           )
                                       })
                                      }
                                      </select>
                                    </div>
                                    {errors.COUNTRY && (
                                  <p className="errorValidate">{errors.COUNTRY.message}</p>
                                  )}
                                  </div>

                                  <div className="form-group col-lg-4">
                                    <label className="label-clr">
                                      Country Prefix
                                    </label>
                                    <input
                                      type="text"
                                      {...register("COUNTRY_PREFIX", { 
                                          required: { value: details.COUNTRY_PREFIX === "" ? true : false, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                      value={details.COUNTRY_PREFIX || ""}
                                      className="form-control"
                                      name="COUNTRY_PREFIX"
                                    />
                                    {errors.COUNTRY_PREFIX && (
                                  <p className="errorValidate">{errors.COUNTRY_PREFIX.message}</p>
                                  )}
                                  </div>
                                </div>

                                {/* <div className="row rowbuttom">
                                  <div className="form-group col-lg-4">
                                    <div className="form-group">
                                      <label className="label-clr">                                     
                                        Country
                                      </label>
                                      <select
                                        name="COUNTRY"  
                                                                        
                                        value={
                                          details.COUNTRY || ""
                                        } // Prop: The email input data
                                       {...register("COUNTRY", { 
                                          required: { value: details.COUNTRY === "" ? true:false, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                        className={errors.COUNTRY ? "form-select m-b-3 seleval" :  "form-select m-b-3" }
                                     style={{ width: "100%" }}>
                                      <option value=''>select country</option>
                                      {
                                       countries.map((item) =>{
                                           return(
                                               <option key={item.country}>
                                                   {item.country}
                                               </option>
                                           )
                                       })
                                      }
                                      </select>
                                    </div>
                                    {errors.COUNTRY && (
                                  <p className="errorValidate">{errors.COUNTRY.message}</p>
                                  )}
                                  </div>
                                  <div className="form-group col-lg-4">
                                    <label className="label-clr">FAX</label>
                                    <input
                                      type="text"
                                      {...register("FAX", { 
                                          required: { value: true, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                      value={details.FAX || ""}
                                      className="form-control"
                                      name="FAX"
                                    />
                                     {errors.FAX && (
                                  <p className="errorValidate">{errors.FAX.message}</p>
                                  )}
                                  </div>

                                  <div className="form-group col-lg-4">
                                    <label className="label-clr">
                                      VAT Rate
                                    </label>
                                    <input
                                      type="text"
                                      {...register("VATRATE", { 
                                          required: { value: true, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                      value={details.VATRATE || ""}
                                      className="form-control"
                                      name="VATRATE"
                                    />
                                     {errors.VATRATE && (
                                  <p className="errorValidate">{errors.VATRATE.message}</p>
                                  )}
                                  </div>
                                </div> */}
                              </div>
                            </div>
                          </div>

                          <div className="row col-bb">
                            <div className="form-group col-lg-4">
                              <label className="label-clr">
                                What date did you move in ?
                              </label>
                              <input
                                type="date"
                                {...register("DATE_MOVED_IN_ADDRESS", { 
                                          required: { value: details.DATE_MOVED_IN_ADDRESS === "" ? true : false, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                value={details.DATE_MOVED_IN_ADDRESS || ""}
                                className="form-control"
                                max={getTodaysDate()}
                                name="DATE_MOVED_IN_ADDRESS"
                              />
                               {errors.DATE_MOVED_IN_ADDRESS && (
                                  <p className="errorValidate">{errors.DATE_MOVED_IN_ADDRESS.message}</p>
                                  )}
                            </div>
                            <div className="form-group col-lg-4">
                              <label className="label-clr">
                                Time lived at current address
                              </label>
                              <input
                                type="text"
                                {...register("TIME_LIVED_AT_ADDRESS")}
                                readOnly 
                                /* value={movedate} */
                                value={details.TIME_LIVED_AT_ADDRESS}
                                className="form-control"
                                name="TIME_LIVED_AT_ADDRESS"
                              />
                            </div>

                            <div className="form-group col-lg-4">
                              <label className="label-clr">
                                Is your home owned/rented/other ?
                              </label>
                              <select
                                name="HOME_QUESTION_ANSWER"
                               {...register("HOME_QUESTION_ANSWER", { 
                                          required: { value: details.HOME_QUESTION_ANSWER === "" ? true:false, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                value={details.HOME_QUESTION_ANSWER || ""} // Prop: The email input data
                               
                                className="form-select m-b-3"
                                style={{ width: "100%" }}>
                                <option value=''>select type</option>
                                <option value="owned">Owned</option>
                                <option value="rented">rented</option>
                                <option value="other">Other</option>
                              </select>
                              {errors.HOME_QUESTION_ANSWER && (
                                  <p className="errorValidate">{errors.HOME_QUESTION_ANSWER.message}</p>
                                  )}
                            </div>
                          </div>

                          <div
                            className={
                              details.HOME_QUESTION_ANSWER === "rented"
                                ? "txtareahide collapsin"
                                : "txtareahide"
                            }>
                            <label className="label-tpr">
                              Please provide details of your landlord's, name,
                              address, email address and telephone number{" "}
                            </label>

                            <div className="row col-bb">
                              <div className="form-group col-lg-4">
                                <label className="label-clr">
                                  Landlord's Name{" "}
                                </label>
                                <input
                                  type="text"
                                  {...register("LANDLORD_NAME", { 
                                          required: { value: (details.HOME_QUESTION_ANSWER === "rented" && details.LANDLORD_NAME === "")  ? true:false, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                  value={details.LANDLORD_NAME || ""}
                                  className="form-control"
                                  name="LANDLORD_NAME"
                                />
                                {errors.LANDLORD_NAME && (
                                  <p className="errorValidate">{errors.LANDLORD_NAME.message}</p>
                                  )}
                              </div>

                              <div className="form-group col-lg-4">
                                <label className="label-clr">
                                  Landlord's Email Address
                                </label>
                                <input
                                  type="email"
                                  {...register("LANDLORD_EMAIL", { 
                                    required: { value: (details.HOME_QUESTION_ANSWER === "rented" && details.LANDLORD_EMAIL === "")  ? true:false, message: "This field is required" },
                                    pattern: {
                                      value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                      message: "Invalid email address"
                                    }, 
                                    onChange: (e) => {handleChange(e)} })}
                                  value={details.LANDLORD_EMAIL || ""}
                                  className="form-control"
                                  name="LANDLORD_EMAIL"
                                />
                                {errors.LANDLORD_EMAIL && (
                                  <p className="errorValidate">{errors.LANDLORD_EMAIL.message}</p>
                                  )}
                              </div>

                              <div className="form-group col-lg-4">
                                <label className="label-clr">
                                  Landlord's Telephone Number{" "}
                                </label>
                                <input
                                  type="tel"
                                  placeholder="+xxx-xxx-xxx-xxxx"
                                  {...register("LANDLORD_MOBILE", { 
                                    required: { value: (details.HOME_QUESTION_ANSWER === "rented" && details.LANDLORD_MOBILE === "")  ? true:false, message: "This field is required" }, 
                                    /* pattern: {value: /^(\+)\d{3}-\d{3}-\d{3}-\d(3|4)$/, message:'Invalid Phone Number'}, */
                                    pattern: {value: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{3,4}$/im, message:'Invalid Phone Number'},
                                          onChange: (e) => {handleChange(e)} })}
                                  value={details.LANDLORD_MOBILE || ""}
                                  className="form-control"
                                  name="LANDLORD_MOBILE"
                                />
                                {errors.LANDLORD_MOBILE && (
                                  <p className="errorValidate">{errors.LANDLORD_MOBILE.message}</p>
                                  )}
                              </div>
                            </div>

                            {/* <div className="row col-bb">
                

                <div className="form-group col-lg-6">
                    <label className="label-clr">Landlord's Address</label>
                    <div className="form-outline">
                      <textarea className="form-control txtareabb" onChange={handleChange}  name="landlordaddress" maxLength="100" rows="2" value={details.landlordaddress || ""}></textarea>
                  </div>
                </div>
                </div> */}

                            <div className="row col-bb">
                              <div className="form-group col-lg-12">
                                <label className="label-tpr">
                                  Please provide Address of your LandLord
                                </label>

                               
                                  <div className="row rowbuttom">
                                    <div className="form-group col-lg-6">
                                      <label className="label-clr">
                                       Landlord's  Address 1
                                      </label>
                                      <input
                                        type="text"
                                        
                                        {...register("LANDLORD_ADDRESS1", { 
                                    required: { value: (details.HOME_QUESTION_ANSWER === "rented" && details.LANDLORD_ADDRESS1 === "")  ? true:false, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                        value={details.LANDLORD_ADDRESS1 || ""}
                                        className="form-control"
                                        name="LANDLORD_ADDRESS1"
                                      />
                                       {errors.LANDLORD_ADDRESS1 && (
                                  <p className="errorValidate">{errors.LANDLORD_ADDRESS1.message}</p>
                                  )}
                                    </div>
                                    <div className="form-group col-lg-6">
                                      <label className="label-clr">
                                        Landlord's  Address 2
                                      </label>
                                      <input
                                        type="text"
                                        {...register("LANDLORD_ADDRESS2", { 
                                    required: { value: (details.HOME_QUESTION_ANSWER === "rented" && details.LANDLORD_ADDRESS2 === "")  ? true:false, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                        value={details.LANDLORD_ADDRESS2 || ""}
                                        className="form-control"
                                        name="LANDLORD_ADDRESS2"
                                      />
                                       {errors.LANDLORD_ADDRESS2 && (
                                  <p className="errorValidate">{errors.LANDLORD_ADDRESS2.message}</p>
                                  )}
                                    </div>
                                  </div>

                                  <div className="row rowbuttom">
                                    {/* <div className="form-group col-lg-4">
                                      <label className="label-clr">
                                        {" "}
                                        Location Name
                                      </label>
                                      <input
                                        type="text"
                                        {...register("LANDLORD_LOCATION_NAME", { 
                                    required: { value: details.HOME_QUESTION_ANSWER === "rented" ? true:false, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                        value={details.LANDLORD_LOCATION_NAME || ""}
                                        className="form-control"
                                        name="LANDLORD_LOCATION_NAME"
                                      />
                                       {errors.LANDLORD_LOCATION_NAME && (
                                  <p className="errorValidate">{errors.LANDLORD_LOCATION_NAME.message}</p>
                                  )}
                                    </div>
                                    <div className="form-group col-lg-4">
                                      <label className="label-clr">
                                        Location Code
                                      </label>
                                      <input
                                        type="text"
                                       {...register("LANDLORD_LOCATION_CODE", { 
                                    required: { value: details.HOME_QUESTION_ANSWER === "rented" ? true:false, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                        value={details.LANDLORD_LOCATION_CODE || ""}
                                        className="form-control"
                                        name="LANDLORD_LOCATION_CODE"
                                      />
                                       {errors.LANDLORD_LOCATION_CODE && (
                                  <p className="errorValidate">{errors.LANDLORD_LOCATION_CODE.message}</p>
                                  )}
                                    </div> */}

                                    <div className="form-group col-lg-4">
                                      <label className="label-clr">Town</label>
                                      <input
                                        type="text"
                                        {...register("LANDLORD_TOWN", { 
                                    required: { value: (details.HOME_QUESTION_ANSWER === "rented" && details.LANDLORD_TOWN === "")  ? true:false, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                        value={details.LANDLORD_TOWN || ""}
                                        className="form-control"
                                        name="LANDLORD_TOWN"
                                      />
                                       {errors.LANDLORD_TOWN && (
                                  <p className="errorValidate">{errors.LANDLORD_TOWN.message}</p>
                                  )}
                                    </div>

                                    <div className="form-group col-lg-4">
                                      <label className="label-clr">
                                        {" "}
                                        County
                                      </label>
                                      <input
                                        type="text"
                                        {...register("LANDLORD_COUNTY", { 
                                    required: { value: (details.HOME_QUESTION_ANSWER === "rented" && details.LANDLORD_COUNTY === "")  ? true:false, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                        value={details.LANDLORD_COUNTY || ""}
                                        className="form-control"
                                        name="LANDLORD_COUNTY"
                                      />
                                       {errors.LANDLORD_COUNTY && (
                                  <p className="errorValidate">{errors.LANDLORD_COUNTY.message}</p>
                                  )}
                                    </div>
                                    <div className="form-group col-lg-4">
                                      <label className="label-clr">
                                        Post Code
                                      </label>
                                      <input
                                        type="text"
                                        {...register("LANDLORD_POSTCODE", { 
                                    required: { value: (details.HOME_QUESTION_ANSWER === "rented" && details.LANDLORD_POSTCODE === "")  ? true:false, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                        value={details.LANDLORD_POSTCODE || ""}
                                        className="form-control"
                                        name="LANDLORD_POSTCODE"
                                      />
                                       {errors.LANDLORD_POSTCODE && (
                                  <p className="errorValidate">{errors.LANDLORD_POSTCODE.message}</p>
                                  )}
                                    </div>
                                  </div>

                                  <div className="row rowbuttom">
                                    {/* <div className="form-group col-lg-4">
                                      <label className="label-clr">
                                        {" "}
                                        County
                                      </label>
                                      <input
                                        type="text"
                                        {...register("LANDLORD_COUNTY", { 
                                    required: { value: details.HOME_QUESTION_ANSWER === "rented" ? true:false, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                        value={details.LANDLORD_COUNTY || ""}
                                        className="form-control"
                                        name="LANDLORD_COUNTY"
                                      />
                                       {errors.LANDLORD_COUNTY && (
                                  <p className="errorValidate">{errors.LANDLORD_COUNTY.message}</p>
                                  )}
                                    </div>
                                    <div className="form-group col-lg-4">
                                      <label className="label-clr">
                                        Post Code
                                      </label>
                                      <input
                                        type="text"
                                        {...register("LANDLORD_POSTCODE", { 
                                    required: { value: details.HOME_QUESTION_ANSWER === "rented" ? true:false, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                        value={details.LANDLORD_POSTCODE || ""}
                                        className="form-control"
                                        name="LANDLORD_POSTCODE"
                                      />
                                       {errors.LANDLORD_POSTCODE && (
                                  <p className="errorValidate">{errors.LANDLORD_POSTCODE.message}</p>
                                  )}
                                    </div> */}

                                    <div className="form-group col-lg-4">
                                      <div className="form-group">
                                        <label className="label-clr">
                                          {" "}
                                          Country
                                        </label>
                                        <select
                                          name="LANDLORD_COUNTRY"
                                          {...register("LANDLORD_COUNTRY", { 
                                    required: { value: (details.HOME_QUESTION_ANSWER === "rented" && details.LANDLORD_COUNTRY === "")  ? true:false, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                          value={
                                            details.LANDLORD_COUNTRY || ""
                                          } // Prop: The email input data
                                         
                                          className={errors.LANDLORD_COUNTRY ? "form-select m-b-3 seleval" :  "form-select m-b-3" }
                                     style={{ width: "100%" }}>
                                      <option value=''>select country</option>
                                      {
                                       countries.map((item) =>{
                                           return(
                                               <option key={item.country}>
                                                   {item.country}
                                               </option>
                                           )
                                       })
                                      }
                                        </select>
                                      </div>
                                       {errors.LANDLORD_COUNTRY && (
                                  <p className="errorValidate">{errors.LANDLORD_COUNTRY.message}</p>
                                  )}
                                    </div>

                                    <div className="form-group col-lg-4">
                                      <label className="label-clr">Country Prefix </label>
                                      <input
                                        type="text"
                                        {...register("LANDLORD_COUNTRY_PREFIX", { 
                                    required: { value: (details.HOME_QUESTION_ANSWER === "rented" && details.LANDLORD_COUNTRY_PREFIX === "")  ? true:false, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                        value={details.LANDLORD_COUNTRY_PREFIX || ""}
                                        className="form-control"
                                        name="LANDLORD_COUNTRY_PREFIX"
                                      />
                                       {errors.LANDLORD_COUNTRY_PREFIX && (
                                  <p className="errorValidate">{errors.LANDLORD_COUNTRY_PREFIX.message}</p>
                                  )}
                                    </div>
                                  </div>

                                  {/* <div className="row rowbuttom">
                                    <div className="form-group col-lg-4">
                                      <div className="form-group">
                                        <label className="label-clr">
                                          {" "}
                                          Country
                                        </label>
                                        <select
                                          name="LANDLORD_COUNTRY"
                                          {...register("LANDLORD_COUNTRY", { 
                                    required: { value: details.HOME_QUESTION_ANSWER === "rented" ? true:false, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                          value={
                                            details.LANDLORD_COUNTRY || ""
                                          } // Prop: The email input data
                                         
                                          className={errors.LANDLORD_COUNTRY ? "form-select m-b-3 seleval" :  "form-select m-b-3" }
                                     style={{ width: "100%" }}>
                                      <option value=''>select country</option>
                                      {
                                       countries.map((item) =>{
                                           return(
                                               <option key={item.country}>
                                                   {item.country}
                                               </option>
                                           )
                                       })
                                      }
                                        </select>
                                      </div>
                                       {errors.LANDLORD_COUNTRY && (
                                  <p className="errorValidate">{errors.LANDLORD_COUNTRY.message}</p>
                                  )}
                                    </div>
                                    <div className="form-group col-lg-4">
                                      <label className="label-clr">FAX</label>
                                      <input
                                        type="text"
                                         {...register("LANDLORD_FAX", { 
                                    required: { value: details.HOME_QUESTION_ANSWER === "rented" ? true:false, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                        value={details.LANDLORD_FAX || ""}
                                        className="form-control"
                                        name="LANDLORD_FAX"
                                      />
                                       {errors.LANDLORD_FAX && (
                                  <p className="errorValidate">{errors.LANDLORD_FAX.message}</p>
                                  )}
                                    </div>

                                    <div className="form-group col-lg-4">
                                      <label className="label-clr">
                                        VAT Rate
                                      </label>
                                      <input
                                        type="text"
                                         {...register("LANDLORD_VRATE", { 
                                    required: { value: details.HOME_QUESTION_ANSWER === "rented" ? true:false, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                        value={details.LANDLORD_VRATE || ""}
                                        className="form-control"
                                        name="LANDLORD_VRATE"
                                      />
                                       {errors.LANDLORD_VRATE && (
                                  <p className="errorValidate">{errors.LANDLORD_VRATE.message}</p>
                                  )}
                                    </div>
                                  </div> */}
                                
                              </div>
                            </div>
                          </div>

                          <div className="row col-bb">
                            <div className="form-group col-lg-3">
                              <label className="label-clr">
                                How many bedrooms are in your house ?
                              </label>
                              <input
                                type="number"
                                min="0"
                                {...register("NUMBER_OF_BEDROOMS", { 
                                    required: { value: details.NUMBER_OF_BEDROOMS === "" ? true : false, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                value={details.NUMBER_OF_BEDROOMS || ""}
                                className="form-control"
                                name="NUMBER_OF_BEDROOMS"
                              />
                               {errors.NUMBER_OF_BEDROOMS && (
                                  <p className="errorValidate">{errors.NUMBER_OF_BEDROOMS.message}</p>
                                  )}
                            </div>
                            <div className="form-group col-lg-3">
                              <label className="label-clr">
                                How many other rooms are in your house ?
                              </label>
                              <input
                                type="number"
                                min="0"
                                {...register("NUMBER_OF_OTHERROOMS", { 
                                    required: { value: details.NUMBER_OF_OTHERROOMS === "" ? true : false, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                value={details.NUMBER_OF_OTHERROOMS || ""}
                                className="form-control"
                                name="NUMBER_OF_OTHERROOMS"
                              />
                               {errors.NUMBER_OF_OTHERROOMS && (
                                  <p className="errorValidate">{errors.NUMBER_OF_OTHERROOMS.message}</p>
                                  )}
                            </div>
                            <div className="form-group col-lg-6">
                              <label className="label-clr">
                              Who else lives there and what are their names ?
                              </label>
                              <div className="form-outline">
                                <textarea
                                  className="form-control txtareabb"
                                  {...register("WHO_LIVES_THERE", { 
                                    required: { value: details.WHO_LIVES_THERE === "" ? true : false, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                  name="WHO_LIVES_THERE"
                                  maxLength="100"
                                  rows="2"
                                  value={
                                    details.WHO_LIVES_THERE || ""
                                  }></textarea>
                              </div>
                               {errors.WHO_LIVES_THERE && (
                                  <p className="errorValidate">{errors.WHO_LIVES_THERE.message}</p>
                                  )}
                            </div>
                          </div>

                          {/* <div className="row col-bb">
            <div className="form-group col-lg-12">
                <label className="label-clr">Please provide your previous address if you have lived at your address for less than 2 years</label>
                  <div className="form-outline">
                      <textarea className="form-control txtareabb" onChange={handleChange}  name="prevaddr" maxLength="100" rows="2" value={details.prevaddr || ""}></textarea>
                  </div>
                </div>
            </div> */}

                          <div className={(yearsLived !== null && yearsLived < 2)  ? "row col-bb collapsin" : "txtareahide"}>
                            <div className="form-group col-lg-12">
                              <label className="label-tpr">
                                Please provide your previous address if you have
                                lived at your address for less than 2 years
                              </label>

                             
                                <div className="row rowbuttom">
                                  <div className="form-group col-lg-6">
                                    <label className="label-clr">
                                      Address 1
                                    </label>
                                    <input
                                      type="text"
                                     
                                      {...register("PREVIOUS_ADDRESS1", { 
                                    required: { value: (yearsLived !== null && yearsLived < 2) && details.PREVIOUS_ADDRESS1 === "" ? true : false, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                      value={details.PREVIOUS_ADDRESS1 || ""}
                                      className="form-control"
                                      name="PREVIOUS_ADDRESS1"
                                    />
                                     {errors.PREVIOUS_ADDRESS1 && (
                                  <p className="errorValidate">{errors.PREVIOUS_ADDRESS1.message}</p>
                                  )}
                                  </div>
                                  <div className="form-group col-lg-6">
                                    <label className="label-clr">
                                      Address 2
                                    </label>
                                    <input
                                      type="text"
                                      {...register("PREVIOUS_ADDRESS2", { 
                                    required: { value: (yearsLived !== null && yearsLived < 2) && details.PREVIOUS_ADDRESS2 === ""  ? true : false, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                     
                                      value={details.PREVIOUS_ADDRESS2 || ""}
                                      className="form-control"
                                      name="PREVIOUS_ADDRESS2"
                                    />
                                     {errors.PREVIOUS_ADDRESS2 && (
                                  <p className="errorValidate">{errors.PREVIOUS_ADDRESS2.message}</p>
                                  )}
                                  </div>
                                </div>

                                <div className="row rowbuttom">
                                 {/*  <div className="form-group col-lg-4">
                                    <label className="label-clr">
                                      {" "}
                                      Location Name
                                    </label>
                                    <input
                                      type="text"
                                      {...register("PREVIOUS_LOCATION_NAME", { 
                                    required: { value: (yearsLived !== null && yearsLived <= 2) ? true : false, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                      value={details.PREVIOUS_LOCATION_NAME || ""}
                                      className="form-control"
                                      name="PREVIOUS_LOCATION_NAME"
                                    />
                                      {errors.PREVIOUS_LOCATION_NAME && (
                                  <p className="errorValidate">{errors.PREVIOUS_LOCATION_NAME.message}</p>
                                  )}
                                  </div>
                                  <div className="form-group col-lg-4">
                                    <label className="label-clr">
                                      Location Code
                                    </label>
                                    <input
                                      type="text"
                                      {...register("PREVIOUS_LOCATION_CODE", { 
                                    required: { value: (yearsLived !== null && yearsLived <= 2) ? true : false, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                      value={details.PREVIOUS_LOCATION_CODE || ""}
                                      className="form-control"
                                      name="PREVIOUS_LOCATION_CODE"
                                    />
                                     {errors.PREVIOUS_LOCATION_CODE && (
                                  <p className="errorValidate">{errors.PREVIOUS_LOCATION_CODE.message}</p>
                                  )}
                                  </div> */}

                                  <div className="form-group col-lg-4">
                                    <label className="label-clr">Town</label>
                                    <input
                                      type="text"
                                      {...register("TOWN_PREVIOUS", { 
                                    required: { value: (yearsLived !== null && yearsLived < 2) && details.TOWN_PREVIOUS === ""  ? true : false, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                      value={details.TOWN_PREVIOUS || ""}
                                      className="form-control"
                                      name="TOWN_PREVIOUS"
                                    />
                                     {errors.TOWN_PREVIOUS && (
                                  <p className="errorValidate">{errors.TOWN_PREVIOUS.message}</p>
                                  )}
                                  </div>

                                  <div className="form-group col-lg-4">
                                    <label className="label-clr"> County</label>
                                    <input
                                      type="text"
                                       {...register("COUNTY_PREVIOUS", { 
                                    required: { value: (yearsLived !== null && yearsLived < 2) && details.COUNTY_PREVIOUS === ""  ? true : false, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                      value={details.COUNTY_PREVIOUS || ""}
                                      className="form-control"
                                      name="COUNTY_PREVIOUS"
                                    />
                                    {errors.COUNTY_PREVIOUS && (
                                  <p className="errorValidate">{errors.COUNTY_PREVIOUS.message}</p>
                                  )}
                                  </div>
                                  <div className="form-group col-lg-4">
                                    <label className="label-clr">
                                      Post Code
                                    </label>
                                    <input
                                      type="text"
                                       {...register("POSTCODE_PREVIOUS", { 
                                    required: { value: (yearsLived !== null && yearsLived < 2) && details.POSTCODE_PREVIOUS === ""  ? true : false, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                      value={details.POSTCODE_PREVIOUS || ""}
                                      className="form-control"
                                      name="POSTCODE_PREVIOUS"
                                    />
                                    {errors.POSTCODE_PREVIOUS && (
                                  <p className="errorValidate">{errors.POSTCODE_PREVIOUS.message}</p>
                                  )}
                                  </div>
                                </div>

                                <div className="row rowbuttom">
                                  {/* <div className="form-group col-lg-4">
                                    <label className="label-clr"> County</label>
                                    <input
                                      type="text"
                                       {...register("COUNTY_PREVIOUS", { 
                                    required: { value: (yearsLived !== null && yearsLived <= 2) ? true : false, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                      value={details.COUNTY_PREVIOUS || ""}
                                      className="form-control"
                                      name="COUNTY_PREVIOUS"
                                    />
                                    {errors.COUNTY_PREVIOUS && (
                                  <p className="errorValidate">{errors.COUNTY_PREVIOUS.message}</p>
                                  )}
                                  </div>
                                  <div className="form-group col-lg-4">
                                    <label className="label-clr">
                                      Post Code
                                    </label>
                                    <input
                                      type="text"
                                       {...register("POSTCODE_PREVIOUS", { 
                                    required: { value: (yearsLived !== null && yearsLived <= 2) ? true : false, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                      value={details.POSTCODE_PREVIOUS || ""}
                                      className="form-control"
                                      name="POSTCODE_PREVIOUS"
                                    />
                                    {errors.POSTCODE_PREVIOUS && (
                                  <p className="errorValidate">{errors.POSTCODE_PREVIOUS.message}</p>
                                  )}
                                  </div> */}

                                  <div className="form-group col-lg-4">
                                    <div className="form-group">
                                      <label className="label-clr">
                                        {" "}
                                        Country
                                      </label>
                                      <select
                                        name="COUNTRY_PREVIOUS"
                                        {...register("COUNTRY_PREVIOUS", { 
                                    required: { value: (yearsLived !== null && yearsLived < 2) && details.COUNTRY_PREFIX === ""  ? true : false, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                        value={details.COUNTRY_PREVIOUS || ""} // Prop: The email input data
                                        
                                        className={errors.COUNTRY_PREVIOUS ? "form-select m-b-3 seleval" :  "form-select m-b-3" }
                                     style={{ width: "100%" }}>
                                      <option value=''>select country</option>
                                      {
                                       countries.map((item) =>{
                                           return(
                                               <option key={item.country}>
                                                   {item.country}
                                               </option>
                                           )
                                       })
                                      }
                                      </select>
                                    </div>
                                    {errors.COUNTRY_PREVIOUS && (
                                  <p className="errorValidate">{errors.COUNTRY_PREVIOUS.message}</p>
                                  )}
                                  </div>

                                  <div className="form-group col-lg-4">
                                    <label className="label-clr">
                                      Country Prefix
                                    </label>
                                    <input
                                      type="text"
                                       {...register("PREVIOUS_COUNTRY_PREFIX", { 
                                    required: { value: (yearsLived !== null && yearsLived < 2) && details.PREVIOUS_COUNTRY_PREFIX === ""  ? true : false, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                      value={details.PREVIOUS_COUNTRY_PREFIX || ""}
                                      className="form-control"
                                      name="PREVIOUS_COUNTRY_PREFIX"
                                    />
                                    {errors.PREVIOUS_COUNTRY_PREFIX && (
                                  <p className="errorValidate">{errors.PREVIOUS_COUNTRY_PREFIX.message}</p>
                                  )}
                                  </div>
                                </div>

                                {/* <div className="row rowbuttom">
                                  <div className="form-group col-lg-4">
                                    <div className="form-group">
                                      <label className="label-clr">
                                        {" "}
                                        Country
                                      </label>
                                      <select
                                        name="COUNTRY_PREVIOUS"
                                        {...register("COUNTRY_PREVIOUS", { 
                                    required: { value: (yearsLived !== null && yearsLived <= 2) ? true : false, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                        value={details.COUNTRY_PREVIOUS || ""} // Prop: The email input data
                                        
                                        className={errors.COUNTRY_PREVIOUS ? "form-select m-b-3 seleval" :  "form-select m-b-3" }
                                     style={{ width: "100%" }}>
                                      <option value=''>select country</option>
                                      {
                                       countries.map((item) =>{
                                           return(
                                               <option key={item.country}>
                                                   {item.country}
                                               </option>
                                           )
                                       })
                                      }
                                      </select>
                                    </div>
                                    {errors.COUNTRY_PREVIOUS && (
                                  <p className="errorValidate">{errors.COUNTRY_PREVIOUS.message}</p>
                                  )}
                                  </div>
                                  <div className="form-group col-lg-4">
                                    <label className="label-clr">FAX</label>
                                    <input
                                      type="text"
                                       {...register("PREVIOUS_FAX", { 
                                    required: { value: (yearsLived !== null && yearsLived <= 2) ? true : false, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                      value={details.PREVIOUS_FAX || ""}
                                      className="form-control"
                                      name="PREVIOUS_FAX"
                                    />
                                     {errors.PREVIOUS_FAX && (
                                  <p className="errorValidate">{errors.PREVIOUS_FAX.message}</p>
                                  )}
                                  </div>

                                  <div className="form-group col-lg-4">
                                    <label className="label-clr">
                                      VAT Rate
                                    </label>
                                    <input
                                      type="text"
                                      {...register("PREVIOUS_VRATE", { 
                                    required: { value: (yearsLived !== null && yearsLived <= 2) ? true : false, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                      value={details.PREVIOUS_VRATE || ""}
                                      className="form-control"
                                      name="PREVIOUS_VRATE"
                                    />
                                    {errors.pvatrate && (
                                  <p className="errorValidate">{errors.PREVIOUS_VRATE.message}</p>
                                  )}
                                  </div>
                                </div> */}
                              
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="text-right m-t-xs">
                        {previousButton()}
                        {nextButton()}
                        {/* <a className="btn btn-default prev" href={void(0)}>Previous</a>
        <a className="btn btn-default next" href={void(0)}>Next</a> */}
                      </div>
                    </div>
                  )}

                  {wizardstep === 3 && (
                    <div
                      id="step3"
                      className={
                        wizardstep === 3
                          ? "p-m tab-pane wizardpane active"
                          : "p-m tab-pane wizardpane "
                      }>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="row col-bb">
                            <div className="form-group col-md-4">
                              <div className="form-group">
                                <label className="label-clr">
                                  Marital Status{" "}
                                </label>
                                <select
                                  name="MARITAL_STATUS"
                                  value={details.MARITAL_STATUS || ""}
                                  {...register("MARITAL_STATUS", { 
                                    required: { value: details.MARITAL_STATUS === "" ? true : false, message: "This field is required" }, 
                                          onChange: (e) => {handleChange(e)} })}
                                  className={errors.MARITAL_STATUS ? "form-select m-b-3 seleval" :  "form-select m-b-3" }
                                  style={{ width: "100%" }}>
                                  <option value="">select Marital Status</option>
                                  <option value="Married or a civil partner">
                                    Married or a civil partner
                                  </option>
                                  <option value="Unmarried partner">
                                    Unmarried partner
                                  </option>
                                  <option value="Divorced or civil partnership dissolved">
                                    Divorced or civil partnership dissolved
                                  </option>
                                  <option value="Separated">Separated</option>
                                  <option value="Widowed or a surviving civil partner">
                                    Widowed or a surviving civil partner
                                  </option>
                                  <option value="Single">
                                  Single
                                  </option>
                                </select>
                              </div>
                               {errors.MARITAL_STATUS && 
                                  <p className="errorValidate">{errors.MARITAL_STATUS.message}</p>
                                  }
                            </div>
                          </div>

                          <div
                            className={
                              details.MARITAL_STATUS !== "" && details.MARITAL_STATUS !== "Single"
                                ? "txtareahide collapsin"
                                : "txtareahide"
                            }>
                            <div className="row col-bb">
                              <div className={details.MARITAL_STATUS === "Married or a civil partner" ? "form-group col-lg-4" : "form-group col-lg-6"}>
                                <label className="label-clr">
                                  Full name of your partner
                                </label>
                                <input
                                  type="text"
                                   {...register("NAME_OF_SPOUSE", { required: { value: (details.MARITAL_STATUS && details.MARITAL_STATUS !== "Single" && details.NAME_OF_SPOUSE === "") ? true: false, message:"Full Name is required" }, 
                                    pattern: {value: /^[A-Za-z\ ]+$/i, message: "Invalid Name"},
                                   onChange: (e) => {handleChange(e)} })}
                                  value={details.NAME_OF_SPOUSE || ""}
                                  className="form-control"
                                  name="NAME_OF_SPOUSE"
                                />
                                 {errors.NAME_OF_SPOUSE && 
                                  <p className="errorValidate">{errors.NAME_OF_SPOUSE.message}</p>
                                  }
                              </div>

                              <div className={details.MARITAL_STATUS === "Married or a civil partner" ? "form-group col-lg-4" : "form-group col-lg-6"}>
                                <label className="label-clr">
                                  Where did you get married/enter civil partnership?
                                </label>
                                <input
                                  type="text"
                                 {...register("WHERE_YOU_GOT_MARRIED", { required: { value: (details.MARITAL_STATUS && details.MARITAL_STATUS !== "Single" && details.WHERE_YOU_GOT_MARRIED === "") ? true: false, message:"Country is required" }, 
                                   onChange: (e) => {handleChange(e)} })}
                                  value={details.WHERE_YOU_GOT_MARRIED || ""}
                                  className="form-control"
                                  name="WHERE_YOU_GOT_MARRIED"
                                />
                                {errors.WHERE_YOU_GOT_MARRIED && 
                                  <p className="errorValidate">{errors.WHERE_YOU_GOT_MARRIED.message}</p>
                                  }
                              </div>

                              <div 
                              className={
                                details.MARITAL_STATUS === "Married or a civil partner"
                                  ? "txtareahide collapsin form-group col-lg-4"
                                  : "txtareahide"
                              }>
                               
                                <label className="label-clr">
                                If married/civil partnerships, what is the date of your marriage/civil partnership?
                                </label>
                                <input
                                  type="date"
                                   {...register("DATE_OF_MARRIAGE", { required: { value: (details.MARITAL_STATUS && details.MARITAL_STATUS !== "Single" && details.DATE_OF_MARRIAGE === "") ? true: false, message:"Country is required" }, 
                                   onChange: (e) => {handleChange(e)} })}
                                  value={details.DATE_OF_MARRIAGE || ""}
                                  className="form-control"
                                  name="DATE_OF_MARRIAGE"
                                  max={getTodaysDate()}
                                />
                                 {errors.DATE_OF_MARRIAGE && 
                                  <p className="errorValidate">{errors.DATE_OF_MARRIAGE.message}</p>
                                  }
                              
                              </div>
                            </div>

                            <div className="row col-bb">
                              <div className="form-group col-lg-4">
                                <label className="label-clr">
                                 Please provide your partner’s date of birth
                                </label>
                                <input
                                  type="date"
                                  {...register("DOB_SPOUSE", { required: { value: (details.MARITAL_STATUS && details.MARITAL_STATUS !== "Single" && details.DOB_SPOUSE === "") ? true: false, message:"Country is required" }, 
                                   onChange: (e) => {handleChange(e)} })}
                                  value={details.DOB_SPOUSE || ""}
                                  className="form-control"
                                  name="DOB_SPOUSE"
                                  max={getTodaysDate()}
                                />
                                {errors.DOB_SPOUSE && 
                                  <p className="errorValidate">{errors.DOB_SPOUSE.message}</p>
                                  }
                              </div>

                              <div className="form-group col-lg-4">
                                <div className="form-group">
                                  <label className="label-clr">
                                  Please provide your partner’s nationality
                                  </label>
                                  <select
                                    name="NATIONALITY_SPOUSE"
                                    {...register("NATIONALITY_SPOUSE", { required: { value: (details.MARITAL_STATUS && details.MARITAL_STATUS !== "Single" && details.NATIONALITY_SPOUSE === "") ? true: false, message:"Country is required" }, 
                                   onChange: (e) => {handleChange(e)} })}
                                    value={details.NATIONALITY_SPOUSE || ""} 
                                    className={errors.NATIONALITY_SPOUSE ? "form-select m-b-3 seleval" :  "form-select m-b-3" }
                                     style={{ width: "100%" }}>
                                      <option value=''>select country</option>
                                      {
                                       countries.map((item) =>{
                                           return(
                                               <option key={item.country}>
                                                   {item.country}
                                               </option>
                                           )
                                       })
                                      }
                                  </select>
                                </div>
                                {errors.NATIONALITY_SPOUSE && 
                                  <p className="errorValidate">{errors.NATIONALITY_SPOUSE.message}</p>
                                  }
                              </div>

                              <div className="form-group col-lg-4">
                                <label className="label-clr">
                                Please provide your partner’s passport number
                                </label>
                                <input
                                  type="text"
                                  {...register("PASSPORT_SPOUSE", { required: { value: (details.MARITAL_STATUS && details.MARITAL_STATUS !== "Single" && details.PASSPORT_SPOUSE === "") ? true: false, message:"Country is required" }, 
                                   onChange: (e) => {handleChange(e)} })}
                                  value={details.PASSPORT_SPOUSE || ""}
                                  className="form-control"
                                  name="PASSPORT_SPOUSE"
                                />
                                {errors.PASSPORT_SPOUSE && 
                                  <p className="errorValidate">{errors.PASSPORT_SPOUSE.message}</p>
                                  }
                              </div>
                            </div>

                            <div className="row col-bb">
                              <div className="form-group col-lg-4">
                                <label className="label-clr">
                                Where did you meet your partner?
                                </label>
                                <input
                                  type="text"
                                  {...register("WHERE_YOU_MET", { required: { value: (details.MARITAL_STATUS && details.MARITAL_STATUS !== "Single" && details.WHERE_YOU_MET === "") ? true: false, message:"Country is required" }, 
                                   onChange: (e) => {handleChange(e)} })}
                                  value={details.WHERE_YOU_MET || ""}
                                  className="form-control"
                                  name="WHERE_YOU_MET"
                                />
                                {errors.WHERE_YOU_MET && 
                                  <p className="errorValidate">{errors.WHERE_YOU_MET.message}</p>
                                  }
                              </div>

                              <div className="form-group col-lg-4">
                                <label className="label-clr">
                                When did your relationship begin with your partner?
                                </label>
                                <input
                                  type="text"
                                  {...register("WHERE_RELATIONSHIP_BEGAN", { required: { value: (details.MARITAL_STATUS && details.MARITAL_STATUS !== "Single" && details.WHERE_RELATIONSHIP_BEGAN === "") ? true: false, message:"Country is required" }, 
                                   onChange: (e) => {handleChange(e)} })}
                                  value={details.WHERE_RELATIONSHIP_BEGAN || ""}
                                  className="form-control"
                                  name="WHERE_RELATIONSHIP_BEGAN"
                                />
                                 {errors.WHERE_RELATIONSHIP_BEGAN && 
                                  <p className="errorValidate">{errors.WHERE_RELATIONSHIP_BEGAN.message}</p>
                                  }
                              </div>

                              <div className="form-group col-lg-4">
                                <label className="label-clr">
                                When did you last see your partner ?
                                </label>
                                <input
                                  type="date"
                                  {...register("WHEN_LAST_YOU_SAW_EACHOTHER", { required: { value: (details.MARITAL_STATUS && details.MARITAL_STATUS !== "Single" && details.WHEN_LAST_YOU_SAW_EACHOTHER === "") ? true: false, message:"Country is required" }, 
                                   onChange: (e) => {handleChange(e)} })}
                                  value={details.WHEN_LAST_YOU_SAW_EACHOTHER || ""}
                                  className="form-control"
                                  name="WHEN_LAST_YOU_SAW_EACHOTHER"
                                />
                                 {errors.WHEN_LAST_YOU_SAW_EACHOTHER && 
                                  <p className="errorValidate">{errors.WHEN_LAST_YOU_SAW_EACHOTHER.message}</p>
                                  }
                              </div>
                            </div>

                            <div className="row col-bb">
                              <div
                                className={
                                  details.LIVE_TOGETHER_QUESTION === "Yes"
                                    ? "form-group col-lg-6"
                                    : "form-group col-lg-12"
                                }>
                                <h6
                                  className="list-group-item-heading label-clr"
                                  style={{ fontSize: "13px" }}>
                                  Do you live together ?
                                </h6>
                                <div className="radio radio-success radio-inline">
                                  <input
                                    type="radio"
                                    id="LIVE_TOGETHER_QUESTIONy"
                                    value="Yes"
                                    name="LIVE_TOGETHER_QUESTION"
                                    checked={details.LIVE_TOGETHER_QUESTION === "Yes"}
                                    {...register("LIVE_TOGETHER_QUESTION", { required: { value: (details.MARITAL_STATUS && details.MARITAL_STATUS !== "Single" && details.LIVE_TOGETHER_QUESTION === "") ? true: false, message:"This field is required" }, 
                                   onChange: (e) => {handleChange(e)} })}
                                    /* onChange={handleChange} */
                                  />
                                  <label htmlFor="LIVE_TOGETHER_QUESTIONy">Yes </label>
                                </div>
                                <div className="radio radio-success radio-inline">
                                  <input
                                    type="radio"
                                    id="LIVE_TOGETHER_QUESTIONn"
                                    value="No"
                                    name="LIVE_TOGETHER_QUESTION"
                                    checked={details.LIVE_TOGETHER_QUESTION === "No"}
                                    {...register("LIVE_TOGETHER_QUESTION", { required: { value: (details.MARITAL_STATUS && details.MARITAL_STATUS !== "Single" && details.LIVE_TOGETHER_QUESTION === "") ? true: false, message:"This field is required" }, 
                                   onChange: (e) => {handleChange(e)} })}
                                    /* onChange={handleChange} */
                                  />
                                  <label htmlFor="LIVE_TOGETHER_QUESTIONn">No</label>
                                </div>
                                {errors.LIVE_TOGETHER_QUESTION && 
                                  <p className="errorValidate">{errors.LIVE_TOGETHER_QUESTION.message}</p>
                                  }
                              </div>

                              <div
                                className={
                                  details.LIVE_TOGETHER_QUESTION === "Yes"
                                    ? "form-group col-lg-6 whide collapsinw"
                                    : "whide"
                                }>
                                <label className="label-clr">
                                  What date you started living together ?{" "}
                                </label>
                                <input
                                  type="date"
                                  /* onChange={handleChange} */
                                  {...register("DATE_LIVING_TOGETHER", { required: { value: (details.MARITAL_STATUS && details.MARITAL_STATUS !== "Single" && details.DATE_LIVING_TOGETHER === "") ? true: false, message:"This field is required" }, 
                                   onChange: (e) => {handleChange(e)} })}
                                  value={details.DATE_LIVING_TOGETHER || ""}
                                  className="form-control"
                                  name="DATE_LIVING_TOGETHER"
                                  max={getTodaysDate()}
                                />
                                {errors.DATE_LIVING_TOGETHER && 
                                  <p className="errorValidate">{errors.DATE_LIVING_TOGETHER.message}</p>
                                  }
                              </div>
                            </div>

                            <div className="row col-bb">
                              <div className="form-group col-lg-6">
                                <h6
                                  className="list-group-item-heading label-clr"
                                  style={{ fontSize: "13px" }}>
                                  Do you have any children ?
                                </h6>
                                <div className="radio radio-success radio-inline">
                                  <input
                                    type="radio"
                                    id="children"
                                    value="Yes"
                                    name="DO_YOU_HAVE_CHILDREN"
                                    checked={details.DO_YOU_HAVE_CHILDREN === "Yes"}
                                    {...register("DO_YOU_HAVE_CHILDREN", { required: { value: (details.MARITAL_STATUS && details.MARITAL_STATUS !== "Single" && details.DO_YOU_HAVE_CHILDREN === "") ? true: false, message:"This field is required" }, 
                                   onChange: (e) => {handleChange(e)} })}
                                    /* onChange={handleChange} */
                                  />
                                  <label htmlFor="children">Yes </label>
                                </div>
                                <div className="radio radio-success radio-inline">
                                  <input
                                    type="radio"
                                    id="childrenn"
                                    value="No"
                                    name="DO_YOU_HAVE_CHILDREN"
                                    checked={details.DO_YOU_HAVE_CHILDREN === "No"}
                                    {...register("DO_YOU_HAVE_CHILDREN", { required: { value: (details.MARITAL_STATUS && details.MARITAL_STATUS !== "Single" && details.DO_YOU_HAVE_CHILDREN === "") ? true: false, message:"This field is required" }, 
                                   onChange: (e) => {handleChange(e)} })}
                                    /* onChange={handleChange} */
                                  />
                                  <label htmlFor="childrenn">No</label>
                                </div>
                                {errors.DO_YOU_HAVE_CHILDREN && 
                                  <p className="errorValidate">{errors.DO_YOU_HAVE_CHILDREN.message}</p>
                                  }
                              </div>

                              <div className={
                                details.DO_YOU_HAVE_CHILDREN === "Yes"
                                  ? "disappeared form-group col-lg-6"
                                  : "appeared form-group col-lg-6"} >
                                <label className="label-clr">
                                How many dependant children do you have ? {(details.NUMBER_OF_DEPENDENT_CHILDREN)}
                                </label>
                                <input
                                  type="number"
                                  readOnly
                                  {...register("NUMBER_OF_DEPENDENT_CHILDREN", { required: { value: (details.MARITAL_STATUS && details.MARITAL_STATUS !== "Single" && details.NUMBER_OF_DEPENDENT_CHILDREN === "") ? true: false, message:"Country is required" }, 
                                   onChange: (e) => {handleChange(e)} })}
                                  value={childrenList?.length ?? 0} /* details.NUMBER_OF_DEPENDENT_CHILDREN */ 
                                  className="form-control"
                                  name="NUMBER_OF_DEPENDENT_CHILDREN"
                                />
                                 {errors.NUMBER_OF_DEPENDENT_CHILDREN && 
                                  <p className="errorValidate">{errors.NUMBER_OF_DEPENDENT_CHILDREN.message}</p>
                                 }
                              </div>
                            </div>

                            <div
                              className={
                                details.DO_YOU_HAVE_CHILDREN === "Yes"
                                  ? "txtareahide collapsin"
                                  : "txtareahide"
                              }>
                              <label className="label-tpr">
                                Please provide details of child/children
                              </label>
                              
                              {childrenList.map((x, i) => {
                                return (
                                  <div
                                    className="row justify-content-center col-bb"
                                    key={i}>
                                    <div className="col-lg-12">
                                      <div className="row">
                                        <div className="form-group col-md-6">
                                          <label className="label-clr">
                                            ({i + 1}) Child's Name
                                          </label>
                                          <input
                                            type="text"
                                            {...register(`childname_${i}`, { 
                                              required: { value: (details.MARITAL_STATUS && details.MARITAL_STATUS !== "Single" && details.DO_YOU_HAVE_CHILDREN === "Yes" && x[`childname_${i}`] === "") ? true : false, message: "This field is required"},
                                              pattern: {value: /^[A-Za-z\ ]+$/i, message: "Invalid Name"},
                                               onChange: (e) => {handleChildChange(e,i)} })}
                                            value={x[`childname_${i}`] || ""}
                                            className="form-control"
                                            name={`childname_${i}`}
                                          />
                                          {errors[`childname_${i}`] && 
                                                <p className="errorValidate">{errors[`childname_${i}`].message}</p>
                                                }
                                        </div>

                                        <div className="form-group col-md-3">
                                          <h6
                                            className="list-group-item-heading label-clr"
                                            style={{ fontSize: "13px" }}>
                                            Gender
                                          </h6>
                                         
                                              <div className="radio radio-success radio-inline">
                                                <input
                                                  type="radio"
                                                  id={`genderm${i}`}
                                                  value="Male"
                                                  name={`gender_${i}`}
                                                  checked={x[`gender_${i}`] === "Male"}
                                                  {...register(`gender_${i}`, { 
                                                    required: { value: (details.MARITAL_STATUS && details.MARITAL_STATUS !== "Single" && details.DO_YOU_HAVE_CHILDREN === "Yes" && x[`gender_${i}`] === "") ? true : false, message: "This field is required"},
                                                     onChange: (e) => {handleChildChange(e,i)} })}
                                                />
                                                <label htmlFor={`genderm${i}`}>
                                                  Male{" "}
                                                </label>
                                              </div>
                                              <div className="radio radio-success radio-inline">
                                                <input
                                                  type="radio"
                                                  id={`genders${i}`}
                                                  value="Female"
                                                  name={`gender_${i}`}
                                                  checked={
                                                    x[`gender_${i}`] === "Female"
                                                  }
                                                  {...register(`gender_${i}`, { 
                                                    required: { value:(details.MARITAL_STATUS && details.MARITAL_STATUS !== "Single" && details.DO_YOU_HAVE_CHILDREN === "Yes" && x[`gender_${i}`] === "") ? true : false, message: "This field is required"},
                                                     onChange: (e) => {handleChildChange(e,i)} })}
                                                />
                                                <label htmlFor={`genders${i}`}>
                                                  Female
                                                </label>
                                              </div>
                                               {errors[`gender_${i}`] && 
                                                <p className="errorValidate">{errors[`gender_${i}`].message}</p>
                                                }
                                        </div>

                                        <div className="form-group col-md-3">
                                          <label className="label-clr">
                                            Date of Birth{" "}
                                          </label>
                                          <input
                                            type="date"
                                            {...register(`dob_${i}`, { 
                                              required: { value: (details.MARITAL_STATUS && details.MARITAL_STATUS !== "Single" && details.DO_YOU_HAVE_CHILDREN === "Yes" && x[`dob_${i}`] === "") ? true : false, message: "This field is required"},
                                               onChange: (e) => {handleChildChange(e,i)} })}
                                            value={x[`dob_${i}`] || ""}
                                            className="form-control"
                                            name={`dob_${i}`}
                                            max={getTodaysDate()}
                                          />
                                          {errors[`dob_${i}`] && 
                                                <p className="errorValidate">{errors[`dob_${i}`].message}</p>
                                                }
                                        </div>
                                      </div>

                                      <div className="row">
                                        <div className="form-group col-md-3">
                                          <div className="form-group">
                                            <label className="label-clr">
                                              Nationality of Child{" "}
                                            </label>
                                            <select
                                              name={`childnationality_${i}`}
                                              /* {...register('childnationality', { required: true})} */
                                              
                                              {...register(`childnationality_${i}`, { 
                                                required: { value: (details.MARITAL_STATUS && details.MARITAL_STATUS !== "Single" && details.DO_YOU_HAVE_CHILDREN === "Yes" && x[`childnationality_${i}`] === "") ? true : false, message: "This field is required"},
                                               
                                                 onChange: (e) => {handleChildChange(e,i)} })}
                                              value={x[`childnationality_${i}`] || ""}
                                              data-icheck={
                                                x[`childnationality_${i}`] || ""
                                              }
                                              className="form-select m-b-3"
                                              style={{ width: "100%" }}>
                                              <option value=''>select country</option>
                                                {
                                                countries.map((item) =>{
                                                    return(
                                                        <option key={item.country}>
                                                            {item.country}
                                                        </option>
                                                    )
                                                })
                                                }
                                            </select>
                                          </div>
                                          {errors[`childnationality_${i}`] && 
                                                <p className="errorValidate">{errors[`childnationality_${i}`].message}</p>
                                                }
                                        </div>

                                        <div className="form-group col-md-3">
                                          <label className="label-clr">
                                            Passport Number{" "}
                                          </label>
                                          <input
                                              type="text"
                                              name={`passportno_${i}`}
                                              {...register(`passportno_${i}`, { 
                                                required: { value: (details.MARITAL_STATUS && details.MARITAL_STATUS !== "Single" && details.DO_YOU_HAVE_CHILDREN === "Yes" && x[`passportno_${i}`] === "") ? true : false, message: "This field is required"},
                                                
                                                 onChange: (e) => {handleChildChange(e,i)} })}
                                              value={x[`passportno_${i}`] || ""}
                                              className="form-control"
                                          />
                                          {errors[`passportno_${i}`] && 
                                                <p className="errorValidate">{errors[`passportno_${i}`].message}</p>
                                                }
                                        </div>

                                        <div className="form-group col-md-3">
                                          <label className="label-clr">
                                            Passport Issued Date{" "}
                                          </label>
                                          <input
                                           
                                            type="date"
                                            {...register(`passportissueddate_${i}`, { 
                                              required: { value: (details.MARITAL_STATUS && details.MARITAL_STATUS !== "Single" && details.DO_YOU_HAVE_CHILDREN === "Yes" && x[`passportissueddate_${i}`] === "") ? true : false, message: "This field is required"},
                                               onChange: (e) => {handleChildChange(e,i)} })}
                                            value={x[`passportissueddate_${i}`] || ""}
                                            className="form-control"
                                            name={`passportissueddate_${i}`}
                                            max={x[`passportexpiry_${i}`] || ""}
                                          />
                                           {errors[`passportissueddate_${i}`] && 
                                                <p className="errorValidate">{errors[`passportissueddate_${i}`].message}</p>
                                                }
                                        </div>

                                        <div className="form-group col-md-3">
                                          <label className="label-clr">
                                            Passport Expiry Date{" "}
                                          </label>
                                          <input
                                           
                                            type="date"
                                            {...register(`passportexpiry_${i}`, { 
                                              required: { value: (details.MARITAL_STATUS && details.MARITAL_STATUS !== "Single" && details.DO_YOU_HAVE_CHILDREN === "Yes" && x[`passportexpiry_${i}`] === "") ? true : false, message: "This field is required"},
                                               onChange: (e) => {handleChildChange(e,i)} })}
                                            value={x[`passportexpiry_${i}`] || ""}
                                            className="form-control"
                                            name={`passportexpiry_${i}`}
                                            min={x[`passportissueddate_${i}`] || ""}
                                          />
                                          {errors[`passportexpiry_${i}`] && 
                                                <p className="errorValidate">{errors[`passportexpiry_${i}`].message}</p>
                                                }
                                        </div>
                                      </div>

                                      <div className="row">
                                        <div className="form-group col-md-4">
                                          <div className="form-group">
                                            <label className="label-clr">
                                              Visa Type{" "}
                                            </label>
                                            <select
                                               name={`visatype_${i}`}
                                               {...register(`visatype_${i}`, { 
                                                 required: { value: (details.MARITAL_STATUS && details.MARITAL_STATUS !== "Single" && details.DO_YOU_HAVE_CHILDREN === "Yes" && x[`visatype_${i}`] === "") ? true : false, message: "This field is required"},
                                                  onChange: (e) => {handleChildChange(e,i)} })}
                                               value={x[`visatype_${i}`] || ""}
                                               data-icheck={
                                                 x[`visatype_${i}`] || ""
                                               }
                                              className="form-select m-b-3"
                                              style={{ width: "100%" }}>
                                              <option value='' >select visa type</option>
                                              <option value="VISA Type 1">
                                                VISA Type 1
                                              </option>
                                              <option value="VISA Type 2">
                                                VISA Type 2
                                              </option>
                                              <option value="VISA Type 3">
                                                VISA Type 3
                                              </option>
                                            </select>
                                          </div>
                                          {errors[`visatype_${i}`] && 
                                                <p className="errorValidate">{errors[`visatype_${i}`].message}</p>
                                                }
                                        </div>

                                        <div className="form-group col-md-4">
                                          <label className="label-clr">
                                            Visa Issued Date{" "}
                                          </label>
                                          <input
                                            type="date"
                                            /* onChange={(e) =>
                                              handleChildChange(e, i)
                                            }
                                            value={x.visaissueddate || ""}
                                            className="form-control"
                                            name="visaissueddate" */
                                            {...register(`visaissueddate_${i}`, { 
                                              required: { value: (details.MARITAL_STATUS && details.MARITAL_STATUS !== "Single" && details.DO_YOU_HAVE_CHILDREN === "Yes" && x[`visaissueddate_${i}`] === "") ? true : false, message: "This field is required"},
                                               onChange: (e) => {handleChildChange(e,i)} })}
                                            value={x[`visaissueddate_${i}`] || ""}
                                            className="form-control"
                                            name={`visaissueddate_${i}`}
                                            max={x[`visaexpiry_${i}`] || ""}
                                          />
                                          {errors[`visaissueddate_${i}`] && 
                                                <p className="errorValidate">{errors[`visaissueddate_${i}`].message}</p>
                                                }
                                        </div>

                                        <div className="form-group col-md-4">
                                          <label className="label-clr">
                                            Visa Expiry Date{" "}
                                          </label>
                                          <input
                                            type="date"
                                            /* onChange={(e) =>
                                              handleChildChange(e, i)
                                            }
                                            min={x.visaissueddate}
                                            value={x.visaexpiry || ""}
                                            className="form-control"
                                            name="visaexpiry" */
                                            {...register(`visaexpiry_${i}`, { 
                                              required: { value: (details.MARITAL_STATUS && details.MARITAL_STATUS !== "Single" && details.DO_YOU_HAVE_CHILDREN === "Yes" && x[`visaexpiry_${i}`] === "") ? true : false, message: "This field is required"},
                                               onChange: (e) => {handleChildChange(e,i)} })}
                                            value={x[`visaexpiry_${i}`] || ""}
                                            className="form-control"
                                            name={`visaexpiry_${i}`}
                                            min={x[`visaissueddate_${i}`] || ""}
                                          />
                                          {errors[`visaexpiry_${i}`] && 
                                                <p className="errorValidate">{errors[`visaexpiry_${i}`].message}</p>
                                          }
                                        </div>
                                      </div>

                                      <div className="row">
                                        <div className="form-group col-md-5">
                                      <span className="addRemovebtn">
                                        {i !== 0 && (
                                          <button
                                            className="mr10 delbtn"
                                            onClick={() =>
                                              handleRemoveChild(i)
                                            }>
                                            <i className="fa fa-trash-o"></i> Remove Dependant Child
                                          </button>
                                        )}
                                        
                                        
                                          <button
                                            className="addbtn"
                                            onClick={() => handleAddChild(i)}>
                                             <i className="fa fa-plus"></i>  Add Dependant Child
                                          </button>
                                      
                                          </span>

                                        </div>
                                      </div>
                                    </div>
                                    {/* <div
                                      className="col-lg-1"
                                      style={{ paddingTop: "10px" }}>
                                      <div
                                        className="text-left m-t-xs"
                                        style={{
                                          paddingTop: "18%",
                                          lineHeight: "1.8em",
                                        }}>
                                        {i !== 0 && (
                                          <button
                                            className="mr10 delbtn"
                                            onClick={() =>
                                              handleRemoveChild(i)
                                            }>
                                            <i className="fa fa-trash-o"></i>
                                          </button>
                                        )}
                                         {i === 0 && (
                                          <button
                                            className="addbtn"
                                            onClick={() => handleAddChild(i)}>
                                            +
                                          </button>
                                        )} 
                                      </div>
                                    </div> */}
                                  </div>
                                );
                              })}
                            </div>

                            <div className="row col-bb">
                              <div className="form-group col-lg-12">
                                <h6
                                  className="list-group-item-heading label-clr"
                                  style={{ fontSize: "13px" }}>
                                  Have you been married before ?
                                </h6>
                                <div className="radio radio-success radio-inline">
                                  <input
                                    type="radio"
                                    id="marriedbeforey"
                                    value="Yes"
                                    name="MARRIED_BEFORE_QUESTION"
                                    checked={details.MARRIED_BEFORE_QUESTION === "Yes"}
                                    {...register("MARRIED_BEFORE_QUESTION", { required: { value: (details.MARITAL_STATUS && details.MARITAL_STATUS !== "Single" && details.MARRIED_BEFORE_QUESTION === "Yes") ? true: false, message:"Country is required" }, 
                                   onChange: (e) => {handleChange(e)} })}
                                  />
                                  <label htmlFor="marriedbefore">Yes </label>
                                </div>
                                <div className="radio radio-success radio-inline">
                                  <input
                                    type="radio"
                                    id="marriedbeforen"
                                    value="No"
                                    name="MARRIED_BEFORE_QUESTION"
                                    checked={details.MARRIED_BEFORE_QUESTION === "No"}
                                    {...register("MARRIED_BEFORE_QUESTION", { required: { value: (details.MARITAL_STATUS && details.MARITAL_STATUS !== "Single" && details.MARRIED_BEFORE_QUESTION === "Yes") ? true: false, message:"This field is required" }, 
                                   onChange: (e) => {handleChange(e)} })}
                                  />
                                  <label htmlFor="marriedbeforen">No</label>
                                </div>
                                {errors.MARRIED_BEFORE_QUESTION && 
                                  <p className="errorValidate">{errors.MARRIED_BEFORE_QUESTION.message}</p>
                                  }
                              </div>
                            </div>

                            <div
                              className={
                                details.MARRIED_BEFORE_QUESTION === "Yes"
                                  ? "txtareahide collapsin"
                                  : "txtareahide"
                              }>
                              <label className="label-tpr">
                                Please provide details of your previous partner(s)
                              </label>
                              {exList.map((x, i) => {
                                return (
                                  <div
                                    className="row justify-content-center col-bb"
                                    key={i}>
                                    <div className="form-group col-lg-12">
                                      <div className="row">
                                        <div className="form-group col-md-4">
                                          <label className="label-clr">
                                            ({i + 1})Previous partner’s name
                                          </label>
                                          <input
                                            type="text"
                                           
                                            {...register(`exname_${i}`, { 
                                              required: { value: (details.MARITAL_STATUS && details.MARITAL_STATUS !== "Single" && details.MARRIED_BEFORE_QUESTION === "Yes" && x[`exname_${i}`] === "") ? true : false, message: "This field is required"},
                                              
                                               onChange: (e) => {handleExChange(e,i)} })}
                                            value={x[`exname_${i}`] || ""}
                                            className="form-control"
                                            name={`exname_${i}`}
                                          />
                                          {errors[`exname_${i}`] && 
                                                <p className="errorValidate">{errors[`exname_${i}`].message}</p>
                                          }
                                        </div>

                                        <div className="form-group col-md-4">
                                          <label className="label-clr">
                                          Previous partner’s date of birth
                                          </label>
                                          <input
                                            type="date"

                                            {...register(`exdob_${i}`, { 
                                              required: { value: (details.MARITAL_STATUS && details.MARITAL_STATUS !== "Single" && details.MARRIED_BEFORE_QUESTION === "Yes" && x[`exdob_${i}`] === "") ? true : false, message: "This field is required"},
                                               onChange: (e) => {handleExChange(e,i)} })}
                                            value={x[`exdob_${i}`] || ""}
                                            className="form-control"
                                            name={`exdob_${i}`}
                                            max={getTodaysDate()}
                                          />
                                          {errors[`exdob_${i}`] && 
                                                <p className="errorValidate">{errors[`exdob_${i}`].message}</p>
                                          }
                                        </div>
                                        <div className="form-group col-md-4">
                                          <label className="label-clr">
                                          Previous place of marriage /civil partnership
                                          </label>
                                          <input
                                            type="text"

                                            {...register(`explaceofmarr_${i}`, { 
                                              required: { value: (details.MARITAL_STATUS && details.MARITAL_STATUS !== "Single" && details.MARRIED_BEFORE_QUESTION === "Yes" && x[`explaceofmarr_${i}`] === "") ? true : false, message: "This field is required"},
                                               onChange: (e) => {handleExChange(e,i)} })}
                                            value={x[`explaceofmarr_${i}`] || ""}
                                            className="form-control"
                                            name={`explaceofmarr_${i}`}
                                          />
                                          {errors[`explaceofmarr_${i}`] && 
                                                <p className="errorValidate">{errors[`explaceofmarr_${i}`].message}</p>
                                          }
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div
                                          className="col-md-4 form-group" /* style={{"marginTop": "8pxx"}} */
                                        >
                                          <label className="label-clr">
                                          Previous partner’s nationality
                                          </label>
                                          <select
                                            {...register(`exnationality_${i}`, { 
                                              required: { value: (details.MARITAL_STATUS && details.MARITAL_STATUS !== "Single" && details.MARRIED_BEFORE_QUESTION === "Yes" && x[`exnationality_${i}`] === "") ? true : false, message: "This field is required"},
                                              onChange: (e) => {handleExChange(e,i)} })}
                                            value={x[`exnationality_${i}`] || ""}
                                            name={`exnationality_${i}`}
                                            className="form-select m-b-3"
                                            style={{ width: "100%" }}>
                                            <option value=''>select country</option>
                                              {
                                              countries.map((item) =>{
                                                  return(
                                                      <option key={item.country}>
                                                          {item.country}
                                                      </option>
                                                  )
                                              })
                                              }
                                          </select>
                                          {errors[`exnationality_${i}`] && 
                                                <p className="errorValidate">{errors[`exnationality_${i}`].message}</p>
                                          }
                                        </div>

                                        <div className="form-group col-md-4">
                                          <label className="label-clr">
                                          Previous date of marriage/civil partnership
                                          </label>
                                          <input
                                            type="date"

                                            {...register(`exdateofmarriage_${i}`, { 
                                              required: { value: (details.MARITAL_STATUS && details.MARITAL_STATUS !== "Single" && details.MARRIED_BEFORE_QUESTION === "Yes" && x[`exdateofmarriage_${i}`] === "") ? true : false, message: "This field is required"},
                                              onChange: (e) => {handleExChange(e,i)} })}
                                            value={x[`exdateofmarriage_${i}`] || ""}
                                            className="form-control"
                                            name={`exdateofmarriage_${i}`}
                                            max={x[`exdateofdivorce_${i}`]}
                                          />
                                          {errors[`exdateofmarriage_${i}`] && 
                                                <p className="errorValidate">{errors[`exdateofmarriage_${i}`].message}</p>
                                          }
                                        </div>

                                        <div className="form-group col-md-4">
                                          <label className="label-clr">
                                          Previous date of divorce /civil partnership
                                          </label>
                                          <input
                                            type="date"

                                            {...register(`exdateofdivorce_${i}`, { 
                                              required: { value: (details.MARITAL_STATUS && details.MARITAL_STATUS !== "Single" && details.MARRIED_BEFORE_QUESTION === "Yes" && x[`exdateofdivorce_${i}`] === "") ? true : false, message: "This field is required"},
                                              onChange: (e) => {handleExChange(e,i)} })}
                                            value={x[`exdateofdivorce_${i}`] || ""}
                                            className="form-control"
                                            name={`exdateofdivorce_${i}`}
                                            min={x[`exdateofmarriage_${i}`]}

                                          />
                                          {errors[`exdateofdivorce_${i}`] && 
                                                <p className="errorValidate">{errors[`exdateofdivorce_${i}`].message}</p>
                                          }
                                        </div>
                                      </div>

                                      <div className="row">
                                      <div
                                        className="col-md-5 form-group">
                                          <span className="addRemovebtn">
                                          {i !== 0 && (
                                            <button
                                              className="mr10 delbtn"
                                              onClick={() => handleRemoveEx(i)}>
                                              <i className="fa fa-trash-o"></i> Remove Ex-Partner
                                            </button>
                                          )}
                                          <button
                                            className="addbtn"
                                            onClick={() => handleAddEx(i)}>
                                            <i className="fa fa-plus"></i> Add Ex-Partner
                                          </button>
                                          </span>
                                      </div>
                                      </div>
                                    </div>
                                   {/*  <div className="col-lg-2 align-self-center">
                                      <div
                                        className="text-left m-t-xs"
                                        style={{
                                          paddingTop: "15%",
                                          lineHeight: "1.8em",
                                        }}>
                                        {i !== 0 && (
                                          <button
                                            className="mr10 delbtn"
                                            onClick={() => handleRemoveEx(i)}>
                                            <i className="fa fa-trash-o"></i>
                                          </button>
                                        )}
                                        {i === 0 && (
                                          <button
                                            className="addbtn"
                                            onClick={handleAddEx}>
                                            +
                                          </button>
                                        )}
                                      </div>
                                    </div> */}
                                  </div>
                                );
                              })}
                            </div>

                            <div className="row col-bb">
                              <div className="form-group col-lg-12">
                                <h6
                                  className="list-group-item-heading label-clr"
                                  style={{ fontSize: "13px" }}>
                                  Has your current partner been married before ?
                                </h6>
                                <div className="radio radio-success radio-inline">
                                  <input
                                    type="radio"
                                    id="PARTNER_MARRIED_BEFOREy"
                                    value="Yes"
                                    name="PARTNER_MARRIED_BEFORE"
                                    checked={details.PARTNER_MARRIED_BEFORE === "Yes"}
                                    {...register("PARTNER_MARRIED_BEFORE", { required: { value: (details.MARITAL_STATUS && details.MARITAL_STATUS !== "Single" && details.PARTNER_MARRIED_BEFORE === "Yes") ? true: false, message:"This field is required" }, 
                                    onChange: (e) => {handleChange(e)} })}
                                  />
                                  <label htmlFor="PARTNER_MARRIED_BEFORE">Yes </label>
                                </div>
                                <div className="radio radio-success radio-inline">
                                  <input
                                    type="radio"
                                    id="PARTNER_MARRIED_BEFOREn"
                                    value="No"
                                    name="PARTNER_MARRIED_BEFORE"
                                    checked={details.PARTNER_MARRIED_BEFORE === "No"}
                                    {...register("PARTNER_MARRIED_BEFORE", { required: { value: (details.MARITAL_STATUS && details.MARITAL_STATUS !== "Single" && details.PARTNER_MARRIED_BEFORE === "Yes") ? true: false, message:"This field is required" }, 
                                    onChange: (e) => {handleChange(e)} })}
                                  />
                                  <label htmlFor="PARTNER_MARRIED_BEFOREn">No</label>
                                </div>
                                {errors.PARTNER_MARRIED_BEFORE && 
                                      <p className="errorValidate">{errors.PARTNER_MARRIED_BEFORE.message}</p>
                                }
                              </div>
                            </div>

                            <div
                              className={
                                details.PARTNER_MARRIED_BEFORE === "Yes"
                                  ? "txtareahide collapsin"
                                  : "txtareahide"
                              }>
                              <label className="label-tpr">
                                Please provide details of your partner's previous marriage(s)
                              </label>
                              {cpexList.map((x, i) => {
                                return (
                                  <div
                                    className="row justify-content-center col-bb"
                                    key={i}>
                                    <div className="form-group col-lg-12">
                                      <div className="row">
                                       
                                        <div className="form-group col-md-6">
                                          <label className="label-clr">
                                            ({i + 1}) Name of Ex-Partner
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            name={`partnersExName_${i}`}
                                            {...register(`partnersExName_${i}`, { 
                                              required: { value: (details.MARITAL_STATUS && details.MARITAL_STATUS !== "Single" && details.PARTNER_MARRIED_BEFORE === "Yes" && x[`partnersExName_${i}`] === "") ? true : false, message: "This field is required"},
                                              
                                              onChange: (e) => {handleCPexChange(e,i)} })}
                                            value={x[`partnersExName_${i}`] || ""}
                                          />
                                          {errors[`partnersExName_${i}`] && 
                                                <p className="errorValidate">{errors[`partnersExName_${i}`].message}</p>
                                          }
                                        </div>

                                        <div className="form-group col-md-6">
                                          <label className="label-clr">
                                            Date of Birth{" "}
                                          </label>
                                          <input
                                            type="date"
                                            className="form-control"
                                            name={`partnersExDob_${i}`}
                                            {...register(`partnersExDob_${i}`, { 
                                              required: { value: (details.MARITAL_STATUS && details.MARITAL_STATUS !== "Single" && details.PARTNER_MARRIED_BEFORE === "Yes" && x[`partnersExDob_${i}`] === "") ? true : false, message: "This field is required"},
                                              
                                              onChange: (e) => {handleCPexChange(e,i)} })}
                                            value={x[`partnersExDob_${i}`] || ""}

                                            max={getTodaysDate()}
                                          />
                                          {errors[`partnersExDob_${i}`] && 
                                                <p className="errorValidate">{errors[`partnersExDob_${i}`].message}</p>
                                          }
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div
                                          className="col-md-4 form-group" /* style={{"marginTop": "8pxx"}} */
                                        >
                                          <label className="label-clr">
                                            Nationality
                                          </label>
                                          <select

                                            name={`partnersExNationality_${i}`}
                                            {...register(`partnersExNationality_${i}`, { 
                                              required: { value: (details.MARITAL_STATUS && details.MARITAL_STATUS !== "Single" && details.PARTNER_MARRIED_BEFORE === "Yes" && x[`partnersExNationality_${i}`] === "") ? true : false, message: "This field is required"},
                                              
                                              onChange: (e) => {handleCPexChange(e,i)} })}
                                            value={x[`partnersExNationality_${i}`] || ""}

                                            className="form-select m-b-3"
                                            style={{ width: "100%" }}>
                                            <option value=''>select country</option>
                                                {
                                                countries.map((item) =>{
                                                    return(
                                                        <option key={item.country}>
                                                            {item.country}
                                                        </option>
                                                    )
                                                })
                                                }
                                          </select>
                                          {errors[`partnersExNationality_${i}`] && 
                                                <p className="errorValidate">{errors[`partnersExNationality_${i}`].message}</p>
                                          }
                                        </div>

                                        <div className="form-group col-md-4">
                                          <label className="label-clr">
                                            Date of Marriage{" "}
                                          </label>
                                          <input
                                            type="date"

                                            name={`partnersExDateofmarriage_${i}`}
                                            {...register(`partnersExDateofmarriage_${i}`, { 
                                              required: { value: (details.MARITAL_STATUS && details.MARITAL_STATUS !== "Single" && details.PARTNER_MARRIED_BEFORE === "Yes" && x[`partnersExDateofmarriage_${i}`] === "") ? true : false, message: "This field is required"},
                                              
                                              onChange: (e) => {handleCPexChange(e,i)} })}
                                            value={x[`partnersExDateofmarriage_${i}`] || ""}

                                            max={x[`partnersExDateofdivorce_${i}`]}

                                           
                                            className="form-control"
                                           
                                          />
                                          {errors[`partnersExDateofmarriage_${i}`] && 
                                                <p className="errorValidate">{errors[`partnersExDateofmarriage_${i}`].message}</p>
                                          }
                                        </div>

                                        <div className="form-group col-md-4">
                                          <label className="label-clr">
                                            Date of Divorce{" "}
                                          </label>
                                          <input
                                            type="date"

                                            name={`partnersExDateofdivorce_${i}`}
                                            {...register(`partnersExDateofdivorce_${i}`, { 
                                              required: { value: (details.MARITAL_STATUS && details.MARITAL_STATUS !== "Single" && details.PARTNER_MARRIED_BEFORE === "Yes" && x[`partnersExDateofdivorce_${i}`] === "") ? true : false, message: "This field is required"},
                                              
                                              onChange: (e) => {handleCPexChange(e,i)} })}
                                            value={x[`partnersExDateofdivorce_${i}`] || ""}

                                            min={x[`partnersExDateofmarriage_${i}`]}

                                            
                                            className="form-control"
                                            
                                          />
                                          {errors[`partnersExDateofdivorce_${i}`] && 
                                                <p className="errorValidate">{errors[`partnersExDateofdivorce_${i}`].message}</p>
                                          }
                                        </div>
                                      </div>

                                      <div className="row">
                                      <div className="form-group col-md-7">
                                        <span className="addRemovebtn">
                                        {i !== 0 && (
                                          <button
                                            className="mr10 delbtn"
                                            onClick={() => handleRemoveCPex(i)}>
                                            <i className="fa fa-trash-o"></i> Remove Partner's Previous Partner
                                          </button>
                                        )}
                                        <button
                                            className="addbtn"
                                            onClick={() => handleAddCPex(i)}>
                                             <i className="fa fa-plus"></i> Add Partner's Previous Partner
                                          </button>
                                        </span>
                                      </div>
                                      </div>
                                    </div>
                                   {/*  <div className="col-lg-2 align-self-center">
                                      <div
                                        className="text-left m-t-xs"
                                        style={{
                                          paddingTop: "15%",
                                          lineHeight: "1.8em",
                                        }}>
                                        {i !== 0 && (
                                          <button
                                            className="mr10 delbtn"
                                            onClick={() => handleRemoveCPex(i)}>
                                            <i className="fa fa-trash-o"></i>
                                          </button>
                                        )}
                                        {i === 0 && (
                                          <button
                                            className="addbtn"
                                            onClick={handleAddCPex}>
                                            +
                                          </button>
                                        )}
                                      </div>
                                    </div> */}
                                  </div>
                                );
                              })}
                            </div>

                            <div className="row col-bb">
                              <div className="form-group col-lg-12">
                                <h6
                                  className="list-group-item-heading label-clr"
                                  style={{ fontSize: "13px" }}>
                                  Do you have any family/friends in your home
                                  country ?
                                </h6>
                                <div className="radio radio-success radio-inline">
                                  <input
                                    type="radio"
                                    id="homectryy"
                                    value="Yes"
                                    name="DO_YOU_HAVE_FAMILY_IN_HOME_COUNTRY"
                                    checked={details.DO_YOU_HAVE_FAMILY_IN_HOME_COUNTRY === "Yes"}
                                    {...register("DO_YOU_HAVE_FAMILY_IN_HOME_COUNTRY", { required: { value: (details.MARITAL_STATUS && details.MARITAL_STATUS !== "Single" && details.DO_YOU_HAVE_FAMILY_IN_HOME_COUNTRY === "Yes") ? true: false, message:"This field is required" }, 
                                    onChange: (e) => {handleChange(e)} })}
                                  />
                                  <label htmlFor="homectryy">Yes </label>
                                </div>
                                <div className="radio radio-success radio-inline">
                                  <input
                                    type="radio"
                                    id="homectryn"
                                    value="No"
                                    name="DO_YOU_HAVE_FAMILY_IN_HOME_COUNTRY"
                                    checked={details.DO_YOU_HAVE_FAMILY_IN_HOME_COUNTRY === "No"}
                                    {...register("DO_YOU_HAVE_FAMILY_IN_HOME_COUNTRY", { required: { value: (details.MARITAL_STATUS && details.MARITAL_STATUS !== "Single" && details.DO_YOU_HAVE_FAMILY_IN_HOME_COUNTRY === "Yes") ? true: false, message:"This field is required" }, 
                                    onChange: (e) => {handleChange(e)} })}
                                  />
                                  <label htmlFor="homectryn">No</label>
                                </div>
                                {errors.DO_YOU_HAVE_FAMILY_IN_HOME_COUNTRY && 
                                      <p className="errorValidate">{errors.DO_YOU_HAVE_FAMILY_IN_HOME_COUNTRY.message}</p>
                                }
                              </div>
                            </div>

                            <div
                              className={
                                details.DO_YOU_HAVE_FAMILY_IN_HOME_COUNTRY === "Yes"
                                  ? "txtareahide collapsin"
                                  : "txtareahide"
                              }>
                              <div className="row justify-content-center col-bb">
                                <div className="form-group col-lg-12">
                                  <label className="label-tpr">
                                    Please provide details 
                                  </label>
                                  {homectry.map((x, i) => {
                                    return (
                                      <div
                                    className="row justify-content-center"
                                    key={i}>
                                    <div className="form-group col-lg-12">
                                      <div className="row">
                                        <div className="col-md-6">
                                          <label className="label-clr">
                                           ({i+1}) Name of Relative
                                          </label>
                                          <input
                                            type="text"
                                            name={`relativeName_${i}`}
                                            {...register(`relativeName_${i}`, { 
                                              required: { value: (details.MARITAL_STATUS && details.MARITAL_STATUS !== "Single" && details.DO_YOU_HAVE_FAMILY_IN_HOME_COUNTRY === "Yes" && x[`relativeName_${i}`] === "") ? true : false, message: "This field is required"},
                                              
                                               onChange: (e) => {handleRelChange(e,i)} })}
                                            value={x[`relativeName_${i}`] || ""}
                                            className="form-control"

                                          />
                                        {errors[`relativeName_${i}`] && 
                                                <p className="errorValidate">{errors[`relativeName_${i}`].message}</p>
                                          }
                                        </div>

                                        <div className="col-md-6">
                                          <div className="form-group">
                                            <label className="label-clr">
                                              Relationship{" "}
                                            </label>
                                            <select
                                            name={`relationship_${i}`}
                                            {...register(`relationship_${i}`, { 
                                              required: { value: (details.MARITAL_STATUS && details.MARITAL_STATUS !== "Single" && details.DO_YOU_HAVE_FAMILY_IN_HOME_COUNTRY === "Yes" && x[`relationship_${i}`] === "") ? true : false, message: "This field is required"},
                                               onChange: (e) => {handleRelChange(e,i)} })}
                                            value={x[`relationship_${i}`] || ""}
                                              data-icheck={x[`relationship_${i}`] || ""}
                                              className="form-select m-b-3"
                                              style={{ width: "100%" }}>
                                              <option value="">
                                                select relationship
                                              </option>
                                              <option value="Mother">Mother</option>
                                              <option value="Father">Father</option>
                                              <option value="Sister">Sister</option>
                                              <option value="Brother">
                                                Brother
                                              </option>
                                              <option value="Nephew">Nephew</option>
                                              <option value="Neice">Neice</option>
                                              <option value="Cousin">Cousin</option>
                                              <option value="Spouse">Spouse</option>
                                              <option value="Son">Son</option>
                                              <option value="Daughter">
                                                Daughter
                                              </option>
                                              <option value="Uncle">Uncle</option>
                                              <option value="Aunty">Aunty</option>
                                              <option value="Grand Mother">
                                                Grand Mother
                                              </option>
                                              <option value="Grand Father">
                                                Grand Father
                                              </option>
                                            </select>
                                            {errors[`relationship_${i}`] && 
                                                <p className="errorValidate">{errors[`relationship_${i}`].message}</p>
                                          }
                                          </div>
                                        </div>

                                        <div className="row">
                                        <div className="form-group col-md-12">
                                          <span className="addRemovebtn">
                                            {i !== 0 && (
                                              <button
                                                className="mr10 delbtn"
                                                onClick={() =>
                                                  handleRemoveRel(i)
                                                }>
                                                <i className="fa fa-trash-o"></i> Remove Family/Friend
                                              </button>
                                            )}
                                            
                                              <button
                                                className="addbtn"
                                                onClick={() => handleAddRel(i)}>
                                                 <i className="fa fa-plus"></i> Add Family/Friend
                                              </button>
                                            
                                            </span>
                                          </div>
                                        </div>

                                       {/*  <div className="col-md-2 align-self-center">
                                          <div
                                            className="text-left m-t-xs"
                                            style={{
                                              paddingTop: "15%",
                                              lineHeight: "1.8em",
                                            }}>
                                            {i !== 0 && (
                                              <button
                                                className="mr10 delbtn"
                                                onClick={() =>
                                                  handleRemoveRel(i)
                                                }>
                                                <i className="fa fa-trash-o"></i>
                                              </button>
                                            )}
                                            {i === 0 && (
                                              <button
                                                className="addbtn"
                                                onClick={handleAddRel}>
                                                +
                                              </button>
                                            )}
                                          </div>
                                        </div> */}
                                      </div>
                                      </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="text-right m-t-xs">
                     
                        {previousButton()}
                        {nextButton()}
                      </div>
                    </div>
                  )}

                  {wizardstep === 4 && (
                    <div
                      id="step4"
                      className={
                        wizardstep === 4
                          ? "p-m tab-pane wizardpane active"
                          : "p-m tab-pane wizardpane"
                      }>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="row col-bb">
                           {/*  <div
                              className={
                                details.PASSED_RECOGNIZED_TEST === "Yes"
                                  ? "form-group col-lg-4"
                                  : "form-group col-lg-6"
                              }>
                              <h6
                                className="list-group-item-heading label-clr"
                                style={{ fontSize: "13px" }}>
                                {" "}
                                Do you have a degree taught in English ?
                              </h6>
                              <div className="radio radio-success radio-inline">
                                <input
                                  type="radio"
                                  id="engdegreey"
                                  value="Yes"
                                  name="HAVE_DEGREE_IN_ENGLISH"
                                  checked={details.HAVE_DEGREE_IN_ENGLISH === "Yes"}
                                  onChange={handleChange}
                                />
                                <label htmlFor="engdegreey">Yes </label>
                              </div>
                              <div className="radio radio-success radio-inline">
                                <input
                                  type="radio"
                                  id="engdegreen"
                                  value="No"
                                  name="HAVE_DEGREE_IN_ENGLISH"
                                  checked={details.HAVE_DEGREE_IN_ENGLISH === "No"}
                                  onChange={handleChange}
                                />
                                <label htmlFor="engdegreen">No</label>
                              </div>
                            </div> */}
                           
                             <div
                              className="form-group col-lg-4">
                              <h6
                                className="list-group-item-heading label-clr"
                                style={{ fontSize: "13px" }}>
                                {" "}
                                Do you have a degree taught in English ?
                              </h6>
                              <div className="radio radio-success radio-inline">
                                <input
                                  type="radio"
                                  id="engdegreey"
                                  value="Yes"
                                  name="HAVE_DEGREE_IN_ENGLISH"
                                  checked={details.HAVE_DEGREE_IN_ENGLISH === "Yes"}
                                  {...register("HAVE_DEGREE_IN_ENGLISH", { required: { value: (details.HAVE_DEGREE_IN_ENGLISH === "") ? true: false, message:"This field is required" }, 
                                    onChange: (e) => {handleChange(e)} })}
                                />
                                <label htmlFor="engdegreey">Yes </label>
                              </div>
                              <div className="radio radio-success radio-inline">
                                <input
                                  type="radio"
                                  id="engdegreen"
                                  value="No"
                                  name="HAVE_DEGREE_IN_ENGLISH"
                                  checked={details.HAVE_DEGREE_IN_ENGLISH === "No"}
                                  {...register("HAVE_DEGREE_IN_ENGLISH", { required: { value: (details.HAVE_DEGREE_IN_ENGLISH === "") ? true: false, message:"This field is required" }, 
                                    onChange: (e) => {handleChange(e)} })}
                                />
                                <label htmlFor="engdegreen">No</label>
                              </div>
                              {errors.HAVE_DEGREE_IN_ENGLISH && 
                                    <p className="errorValidate">{errors.HAVE_DEGREE_IN_ENGLISH.message}</p>
                              }
                            </div>                   

                            <div
                              className="form-group col-lg-4">
                              <h6
                                className="list-group-item-heading label-clr"
                                style={{ fontSize: "13px" }}>
                                Have you passed any English Test ?
                              </h6>
                              <div className="radio radio-success radio-inline">
                                <input
                                  type="radio"
                                  id="engpassedy"
                                  value="Yes"
                                  name="PASSED_RECOGNIZED_TEST"
                                  checked={details.PASSED_RECOGNIZED_TEST === "Yes"}
                                  {...register("PASSED_RECOGNIZED_TEST", { required: { value: (details.PASSED_RECOGNIZED_TEST === "") ? true: false, message:"This field is required" }, 
                                    onChange: (e) => {handleChange(e)} })}
                                />
                                <label htmlFor="engpassedy">Yes </label>
                              </div>
                              <div className="radio radio-success radio-inline">
                                <input
                                  type="radio"
                                  id="engpassedn"
                                  value="No"
                                  name="PASSED_RECOGNIZED_TEST"
                                  checked={details.PASSED_RECOGNIZED_TEST === "No"}
                                  {...register("PASSED_RECOGNIZED_TEST", { required: { value: (details.PASSED_RECOGNIZED_TEST === "") ? true: false, message:"This field is required" }, 
                                    onChange: (e) => {handleChange(e)} })}
                                />
                                <label htmlFor="engpassedn">No</label>
                              </div>
                              {errors.PASSED_RECOGNIZED_TEST && 
                                    <p className="errorValidate">{errors.PASSED_RECOGNIZED_TEST.message}</p>
                              }
                            </div>

                            <div
                              className={
                                details.PASSED_RECOGNIZED_TEST === "Yes"
                                  ? "form-group col-lg-4 whide collapsinw"
                                  : "whide"
                              }
                              aria-expanded={details.PASSED_RECOGNIZED_TEST === "Yes"}>
                              <label className="label-clr">
                                Please select the relevant test
                              </label>
                              <select
                                name="WHAT_TEST_DID_YOU_PASS"
                                {...register("WHAT_TEST_DID_YOU_PASS", { required: { value: (details.PASSED_RECOGNIZED_TEST === "Yes" && details.WHAT_TEST_DID_YOU_PASS === "") ? true: false, message:"This field is required" }, 
                                    onChange: (e) => {handleChange(e)} })}
                                value={details.WHAT_TEST_DID_YOU_PASS || ""} // Prop: The email input data
                                 //
                                className="form-select m-b-3"
                                style={{ width: "100%" }}>
                                <option value="">select test</option>
                                <option value="LanguageCert">
                                  LanguageCert
                                </option>
                                <option value="Pearson">Pearson</option>
                                <option value="Trinity College London">
                                  Trinity College London
                                </option>
                                <option value="IELTS SELT Consortium">
                                  IELTS SELT Consortium
                                </option>
                                <option value="Other">
                                  Other
                                </option>
                              </select>
                              {errors.WHAT_TEST_DID_YOU_PASS && 
                                    <p className="errorValidate">{errors.WHAT_TEST_DID_YOU_PASS.message}</p>
                              }
                            </div>
                          </div>

                          <div className={
                                details.WHAT_TEST_DID_YOU_PASS === "Other"
                                  ? "row col-bb txtareahide collapsin"
                                  : "txtareahide"
                              } >
                          <div className="col-lg-12" style={{marginBottom:"10px"}}>

                          <label className="label-clr">
                              Please specifiy other English Certificate :
                            </label>
                            <div className="form-outline">
                              <input
                              type="text"
                                className="form-control txtareabb"
                                {...register("OTHER_CERTIFICATE", { 
                                  required: { value: (details.WHAT_TEST_DID_YOU_PASS === "Other" && details.OTHER_CERTIFICATE === "") ? true : false, message: "This field is required" }, 
                                        onChange: (e) => {handleChange(e)} })}
                                name="OTHER_CERTIFICATE"
                                value={details.OTHER_CERTIFICATE || ""}
                                />
                            </div>
                              {errors.OTHER_CERTIFICATE && (
                                <p className="errorValidate">{errors.OTHER_CERTIFICATE.message}</p>
                              )}
                          </div>
                        </div> 

                      </div>
                      </div>
                      <div className="text-right m-t-xs">
                        {previousButton()}
                        {nextButton()}
                      </div>
                   
                    </div>
                  )}

                  {wizardstep === 5 && (
                    <div
                      id="step5"
                      className={
                        wizardstep === 5
                          ? "p-m tab-pane wizardpane active"
                          : "p-m tab-pane wizardpane"
                      }>
                   <div className="row col-bb">
                         <div
                              className="form-group col-lg-6">
                              <h6
                                className="list-group-item-heading label-clr"
                                style={{ fontSize: "13px" }}>
                                {" "}
                                Do you have any other qualifications ?
                              </h6>
                              <div className="radio radio-success radio-inline">
                                <input
                                  type="radio"
                                  id="engdegreey"
                                  value="Yes"
                                  name="QUALIFICATION_QUESTION"
                                  checked={details.QUALIFICATION_QUESTION === "Yes"}
                                  {...register("QUALIFICATION_QUESTION", { required: { value: (details.QUALIFICATION_QUESTION === "") ? true: false, message:"This field is required" }, 
                                    onChange: (e) => {handleChange(e)} })}
                                />
                                <label htmlFor="engdegreey">Yes </label>
                              </div>
                              <div className="radio radio-success radio-inline">
                                <input
                                  type="radio"
                                  id="engdegreen"
                                  value="No"
                                  name="QUALIFICATION_QUESTION"
                                  checked={details.QUALIFICATION_QUESTION === "No"}
                                  {...register("QUALIFICATION_QUESTION", { required: { value: (details.QUALIFICATION_QUESTION === "") ? true: false, message:"This field is required" }, 
                                  onChange: (e) => {handleChange(e)} })}
                                />
                                <label htmlFor="engdegreen">No</label>
                              </div>
                              {errors.QUALIFICATION_QUESTION && 
                                    <p className="errorValidate">{errors.QUALIFICATION_QUESTION.message}</p>
                              }
                            </div>
                      </div>

                    <div className={details.QUALIFICATION_QUESTION === "Yes" ? "txtareahide collapsin" : "txtareahide"}>
                      <div className="row text-left">
                      
                      <div className="col-md-5">

                      {qualifi.length > 0 && (
                      <label className="label-tpr">
                                Please provide details of qualification
                        </label>
                          )}
                      </div>
                      </div>
                      {qualifi.map((x, i) => {
                        return (
                          <div className="row" key={i}>
                            <div className="col-lg-12">
                              <div className="row col-bb">
                                <div className="form-group col-lg-4">
                                  <label className="label-clr">
                                  ({i+1}) Qualification
                                  </label>
                                  <input
                                    type="text"
                                    name={`qualification_${i}`}
                                    {...register(`qualification_${i}`, { 
                                      required: { value: (details.QUALIFICATION_QUESTION === "Yes" && x[`qualification_${i}`]=== "") ? true : false, message: "This field is required"},
                                      onChange: (e) => {handleQualiChange(e,i)} })}
                                    value={x[`qualification_${i}`] || ""}
                                    className="form-control"
                                  />
                                  {errors[`qualification_${i}`] && 
                                        <p className="errorValidate">{errors[`qualification_${i}`].message}</p>
                                  }
                                </div>

                                <div className="form-group col-lg-4">
                                  <label className="label-clr">
                                    Awarding Institute{" "}
                                  </label>
                                  <input
                                    type="text"

                                    name={`awardinginstitute_${i}`}
                                    {...register(`awardinginstitute_${i}`, { 
                                      required: { value: (details.QUALIFICATION_QUESTION === "Yes" && x[`awardinginstitute_${i}`]=== "") ? true : false, message: "This field is required"},
                                      onChange: (e) => {handleQualiChange(e,i)} })}
                                    value={x[`awardinginstitute_${i}`] || ""}
                                    className="form-control"
                                  />
                                  {errors[`awardinginstitute_${i}`] && 
                                        <p className="errorValidate">{errors[`awardinginstitute_${i}`].message}</p>
                                  }
                                </div>

                                <div className="form-group col-lg-4">
                                  <label className="label-clr">Grade </label>
                                  <input
                                    type="text"

                                    name={`grade_${i}`}
                                    {...register(`grade_${i}`, { 
                                      required: { value: (details.QUALIFICATION_QUESTION === "Yes" && x[`grade_${i}`]=== "") ? true : false, message: "This field is required"},
                                      onChange: (e) => {handleQualiChange(e,i)} })}
                                    value={x[`grade_${i}`] || ""}
                                    className="form-control"
                                  />
                                  {errors[`grade_${i}`] && 
                                        <p className="errorValidate">{errors[`grade_${i}`].message}</p>
                                  }
                                </div>
                              </div>

                              <div className="row col-bb">
                                <div className="form-group col-lg-4">
                                  <label className="label-clr">
                                    Course Subject{" "}
                                  </label>
                                  <input
                                    type="text"

                                    name={`coursesubj_${i}`}
                                    {...register(`coursesubj_${i}`, { 
                                      required: { value: (details.QUALIFICATION_QUESTION === "Yes" && x[`coursesubj_${i}`]=== "") ? true : false, message: "This field is required"},
                                      onChange: (e) => {handleQualiChange(e,i)} })}
                                    value={x[`coursesubj_${i}`] || ""}
                                    className="form-control"
                                  />
                                  {errors[`coursesubj_${i}`] && 
                                        <p className="errorValidate">{errors[`coursesubj_${i}`].message}</p>
                                  }
                                </div>

                                <div className="form-group col-lg-4">
                                  <label className="label-clr">
                                    Course Length{" "}
                                  </label>
                                  <input
                                    type="text"

                                    name={`courselen_${i}`}
                                    {...register(`courselen_${i}`, { 
                                      required: { value: (details.QUALIFICATION_QUESTION === "Yes" && x[`courselen_${i}`]=== "") ? true : false, message: "This field is required"},
                                      onChange: (e) => {handleQualiChange(e,i)} })}
                                    value={x[`courselen_${i}`] || ""}
                                    className="form-control"
                                  />
                                  {errors[`courselen_${i}`] && 
                                        <p className="errorValidate">{errors[`courselen_${i}`].message}</p>
                                  }
                                </div>

                                <div className="form-group col-lg-4">
                                  <label className="label-clr">
                                    Year of Award{" "}
                                  </label>
                                  <input
                                    type="month"

                                    name={`yearofaward_${i}`}
                                    {...register(`yearofaward_${i}`, { 
                                      required: { value: (details.QUALIFICATION_QUESTION === "Yes" && x[`yearofaward_${i}`]=== "") ? true : false, message: "This field is required"},
                                      onChange: (e) => {handleQualiChange(e,i)} })}
                                    value={x[`yearofaward_${i}`] || ""}
                                    className="form-control"
                                    min="1979-01"
                                  />
                                  {errors[`yearofaward_${i}`] && 
                                        <p className="errorValidate">{errors[`yearofaward_${i}`].message}</p>
                                  }
                                </div>
                              </div>

                              <div className="row rowbuttom">
                                <div className="form-group col-lg-4">
                                  <label className="label-clr">
                                    Country of Award{" "}
                                  </label>
                                  <select

                                    name={`countryofaward_${i}`}
                                    {...register(`countryofaward_${i}`, { 
                                      required: { value: (details.QUALIFICATION_QUESTION === "Yes" && x[`countryofaward_${i}`]=== "") ? true : false, message: "This field is required"},
                                      onChange: (e) => {handleQualiChange(e,i)} })}
                                    value={x[`countryofaward_${i}`] || ""}
                                    className="form-select m-b-3"
                                    style={{ width: "100%" }}>
                                    <option value=''>select country</option>
                                    {
                                       countries.map((item) =>{
                                           return(
                                               <option key={item.country}>
                                                   {item.country}
                                               </option>
                                           )
                                       })
                                      }
                                  </select>
                                  {errors[`countryofaward_${i}`] && 
                                        <p className="errorValidate">{errors[`countryofaward_${i}`].message}</p>
                                  }
                                </div>

                                <div className="form-group col-lg-4">
                                  <label className="label-clr">State </label>
                                  <input
                                    type="text"
                                    name={`countrystate_${i}`}
                                    {...register(`countrystate_${i}`, { 
                                      required: { value: (details.QUALIFICATION_QUESTION === "Yes" && x[`countrystate_${i}`]=== "") ? true : false, message: "This field is required"},
                                      onChange: (e) => {handleQualiChange(e,i)} })}
                                    value={x[`countrystate_${i}`] || ""}
                                    className="form-control"
                                  />
                                  {errors[`countrystate_${i}`] && 
                                        <p className="errorValidate">{errors[`countrystate_${i}`].message}</p>
                                  }
                                </div>

                               {/*  <div className="form-group col-lg-4">
                                  <div
                                    className="text-left m-t-xs"
                                    style={{
                                      paddingTop: "7%",
                                      lineHeight: "1.8em",
                                      display: "grid",
                                      placeContent: "center",
                                    }}>
                                    {i !== 0 && (
                                      <button
                                        className="mr10 delbtn"
                                        onClick={() => handleRemoveQuali(i)}>
                                        <i className="fa fa-trash-o"></i> Delete
                                        Qualification
                                      </button>
                                    )}
                                    {i === 0 && (
                                      <button
                                        className="addbtn"
                                        style={{padding:"0px 5px"}}
                                        onClick={() => handleAddQuali(i)}>
                                         <i className="fa fa-plus"></i> Add Qualification
                                      </button>
                                    )}
                                  </div>
                                </div> */}
                                </div>

                                <div className="row">
                                        <div className="form-group col-md-5">
                                      <span className="addRemovebtn">
                                          <button
                                            className="mr10 delbtn"
                                            onClick={() =>
                                              handleRemoveQuali(i)
                                            }>
                                            <i className="fa fa-trash-o"></i>  Remove Qualification
                                          </button>
                                        
                                       {/*    <button
                                            className="addbtn"
                                            onClick={() => handleAddQuali(i)}>
                                             <i className="fa fa-plus"></i>  Add Additional Qualification
                                          </button> */}
                                      
                                          </span>

                                        </div>
                                      </div>

                              </div>
                            </div>
                        );
                      })}

                      <div className={`row text-left ${qualifi.length > 0 ? "qualiaddbtn" : ""}`}>
                                            
                        <div className="col-md-5">
                            <button
                              className="addbtn"
                              onClick={() => handleAddQuali()}>
                                <i className="fa fa-plus"></i>  Add Additional Qualification
                            </button>
                        </div>
                      </div>
                      
                       </div>
                      <div className="text-right m-t-xs">
                        {previousButton()}
                        {nextButton()}
                        {/* <a className="btn btn-default prev" href={void(0)}>Previous</a>
        <a className="btn btn-default next" href={void(0)}>Next</a> */}
                      </div>
                    </div>
                  )}

                  {wizardstep === 6 && (
                    <div
                      id="step6"
                      className={
                        wizardstep === 6
                          ? "p-m tab-pane wizardpane active"
                          : "p-m tab-pane wizardpane"
                      }>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="row col-bb">
                            <div className="form-group col-lg-12">
                              <h6
                                className="list-group-item-heading label-clr"
                                style={{ fontSize: "13px" }}>
                                {" "}
                                Are you
                              </h6>
                              <div className="radio radio-success radio-inline">
                                <input
                                  type="radio"
                                  id="empstatusy"
                                  value="Employed"
                                  name="EMPLOYMENT_STATUS"
                                  {...register('EMPLOYMENT_STATUS', { required: { value: true, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                  checked={details.EMPLOYMENT_STATUS === "Employed"}
                                  
                                />
                                <label htmlFor="empstatusy">Employed </label>
                              </div>
                              <div className="radio radio-success radio-inline">
                                <input
                                  type="radio"
                                  id="empstatusn"
                                  value="Self-Employed"
                                  name="EMPLOYMENT_STATUS"
                                  {...register('EMPLOYMENT_STATUS', { required: { value: true, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                  checked={details.EMPLOYMENT_STATUS === "Self-Employed"}
                                  
                                />
                                <label htmlFor="empstatusn">
                                  Self Employed
                                </label>
                              </div>
                              <div className="radio radio-success radio-inline">
                                <input
                                  type="radio"
                                  id="unemployed"
                                  value="Unemployed"
                                  name="EMPLOYMENT_STATUS"
                                  {...register('EMPLOYMENT_STATUS', { required: { value: true, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                  checked={details.EMPLOYMENT_STATUS === "Unemployed"}
                                  
                                />
                                <label htmlFor="empstatusn">Unemployed</label>
                              </div>
                              <div className="radio radio-success radio-inline">
                                <input
                                  type="radio"
                                  id="selfsufficient"
                                  value="Selfsufficient"
                                  name="EMPLOYMENT_STATUS"
                                  {...register('EMPLOYMENT_STATUS', { required: { value: true, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                  checked={details.EMPLOYMENT_STATUS === "Selfsufficient"}
                                  
                                />
                                <label htmlFor="empstatusn">Self-sufficient</label>
                              </div>
                              <div className="radio radio-success radio-inline">
                                <input
                                  type="radio"
                                  id="retired"
                                  value="Retired"
                                  name="EMPLOYMENT_STATUS"
                                  {...register('EMPLOYMENT_STATUS', { required: { value: true, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                  checked={details.EMPLOYMENT_STATUS === "Retired"}
                                  
                                />
                                <label htmlFor="empstatusn">Retired</label>
                              </div>
                              {errors.EMPLOYMENT_STATUS && 
                                 <p className="errorValidate">{errors.EMPLOYMENT_STATUS.message}</p>
                              }
                            </div>
                          </div>

                          <div
                            className={
                              details.EMPLOYMENT_STATUS === "Employed" ||
                              details.EMPLOYMENT_STATUS === "Self-Employed"
                                ? "txtareahide collapsin"
                                : "txtareahide"
                            }>
                            {details.EMPLOYMENT_STATUS === "Self-Employed" ? (
                              <label className="label-tpr">
                                If you are <strong>Self Employed</strong>,
                                please provide details
                              </label>
                            ) : details.EMPLOYMENT_STATUS === "Employed" ? (
                              <label className="label-tpr">
                                If you are <strong>Employed</strong>, please
                                provide Employer's details below{" "}
                              </label>
                            ) : (
                              ""
                            )}
                            <div className="row col-bb">
                              <div className="form-group col-lg-4">
                                <label className="label-clr">
                                  When did you start your job ?
                                </label>
                                <input
                                  type="date"
                                  
                                  {...register('EMPLOYMENT_DATE', { required: { value: ((details.EMPLOYMENT_STATUS === "Employed" ||
                                  details.EMPLOYMENT_STATUS === "Self-Employed") && details.EMPLOYMENT_DATE === "") ? true :false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                  value={details.EMPLOYMENT_DATE || ""}
                                  className="form-control"
                                  name="EMPLOYMENT_DATE"
                                />
                                {errors.EMPLOYMENT_DATE && 
                                  <p className="errorValidate">{errors.EMPLOYMENT_DATE.message}</p>
                                }
                              </div>

                              <div className="form-group col-lg-4">
                                <label className="label-clr">
                                  What is the name of your employer ?
                                </label>
                                <input
                                  type="text"
                                  
                                  {...register('EMPLOYER_NAME', { required: { value: ((details.EMPLOYMENT_STATUS === "Employed" ||
                                  details.EMPLOYMENT_STATUS === "Self-Employed") && details.EMPLOYER_NAME === "") ? true :false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                  value={details.EMPLOYER_NAME || ""}
                                  className="form-control"
                                  name="EMPLOYER_NAME"
                                />
                                {errors.EMPLOYER_NAME && 
                                 <p className="errorValidate">{errors.EMPLOYER_NAME.message}</p>
                                }
                              </div>

                              <div className="form-group col-lg-4">
                                <label className="label-clr">
                                  Telephone number of your employer
                                </label>
                                <input
                                  type="tel"
                                  
                                  {...register('EMPLOYER_PHONE', { required: { value: ((details.EMPLOYMENT_STATUS === "Employed" ||
                                  details.EMPLOYMENT_STATUS === "Self-Employed") && details.EMPLOYER_PHONE === "") ? true :false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                  value={details.EMPLOYER_PHONE || ""}
                                  className="form-control"
                                  name="EMPLOYER_PHONE"
                                />
                                {errors.EMPLOYER_PHONE && 
                                 <p className="errorValidate">{errors.EMPLOYER_PHONE.message}</p>
                                }
                              </div>
                            </div>

                            <div className="row col-bb">
                              <div className="form-group col-lg-5">
                                <label className="label-clr">
                                  Email address of your employer{" "}
                                </label>
                                <input
                                  type="email"
                                 
                                  {...register('EMPLOYER_EMAIL', { required: { value: ((details.EMPLOYMENT_STATUS === "Employed" ||
                                  details.EMPLOYMENT_STATUS === "Self-Employed") && details.EMPLOYER_EMAIL === "") ? true :false, message: "This field is required" }, 
                                  pattern: {
                                    value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                    message: "Invalid email address"
                                  },
                                  onChange: (e) => {handleChange(e)} })}
                                  value={details.EMPLOYER_EMAIL || ""}
                                  className="form-control"
                                  name="EMPLOYER_EMAIL"
                                />
                                {errors.EMPLOYER_EMAIL && 
                                 <p className="errorValidate">{errors.EMPLOYER_EMAIL.message}</p>
                                }
                              </div>

                              <div className="form-group col-lg-3">
                                <label className="label-clr">
                                  What is your annual salary ?
                                </label>
                                <input
                                  type="number"
                                 
                                  {...register('SALARY', { required: { value: ((details.EMPLOYMENT_STATUS === "Employed" ||
                                  details.EMPLOYMENT_STATUS === "Self-Employed") && details.SALARY === "") ? true :false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                  value={details.SALARY || ""}
                                  className="form-control"
                                  name="SALARY"
                                />
                                {errors.SALARY && 
                                 <p className="errorValidate">{errors.SALARY.message}</p>
                                }
                              </div>

                              <div className="form-group col-lg-4">
                                <label className="label-clr">
                                  What is your job title ?
                                </label>
                                <input
                                  type="text"
                                  onChange={handleChange}
                                  {...register('JOB_TITLE', { required: { value: ((details.EMPLOYMENT_STATUS === "Employed" ||
                                  details.EMPLOYMENT_STATUS === "Self-Employed") && details.JOB_TITLE === "") ? true :false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                  value={details.JOB_TITLE || ""}
                                  className="form-control"
                                  name="JOB_TITLE"
                                />
                                {errors.JOB_TITLE && 
                                 <p className="errorValidate">{errors.JOB_TITLE.message}</p>
                                }
                              </div>
                            </div>

                            <div className="row col-bb">
                              <div className="form-group col-lg-12">
                                <label className="label-tpr">
                                  Please provide Address of your employer
                                </label>

                               
                                  <div className="row rowbuttom">
                                    <div className="form-group col-lg-6">
                                      <label className="label-clr">
                                        Address 1
                                      </label>
                                      <input
                                        type="text"
                                        onChange={handleChange}
                                        {...register('EMPLOYER_ADDRESS1', { required: { value: ((details.EMPLOYMENT_STATUS === "Employed" ||
                                        details.EMPLOYMENT_STATUS === "Self-Employed") && details.EMPLOYER_ADDRESS1 === "") ? true :false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                        value={details.EMPLOYER_ADDRESS1 || ""}
                                        className="form-control"
                                        name="EMPLOYER_ADDRESS1"
                                      />
                                      {errors.EMPLOYER_ADDRESS1 && 
                                       <p className="errorValidate">{errors.EMPLOYER_ADDRESS1.message}</p>
                                      }
                                    </div>
                                    <div className="form-group col-lg-6">
                                      <label className="label-clr">
                                        Address 2
                                      </label>
                                      <input
                                        type="text"
                                        onChange={handleChange}
                                        {...register('EMPLOYER_ADDRESS2', { required: { value: ((details.EMPLOYMENT_STATUS === "Employed" ||
                                        details.EMPLOYMENT_STATUS === "Self-Employed") && details.EMPLOYER_ADDRESS2 === "") ? true :false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                        value={details.EMPLOYER_ADDRESS2 || ""}
                                        className="form-control"
                                        name="EMPLOYER_ADDRESS2"
                                      />
                                      {errors.EMPLOYER_ADDRESS2 && 
                                       <p className="errorValidate">{errors.EMPLOYER_ADDRESS2.message}</p>
                                      }
                                    </div>
                                  </div>

                                  <div className="row rowbuttom">
                                    {/* <div className="form-group col-lg-4">
                                      <label className="label-clr">
                                        {" "}
                                        Location Name
                                      </label>
                                      <input
                                        type="text"
                                        onChange={handleChange}
                                        {...register('EMPLOYER_LOCATION', { required: { value: true, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                        value={details.EMPLOYER_LOCATION || ""}
                                        className="form-control"
                                        name="EMPLOYER_LOCATION"
                                      />
                                    </div>
                                    <div className="form-group col-lg-4">
                                      <label className="label-clr">
                                        Location Code
                                      </label>
                                      <input
                                        type="text"
                                        onChange={handleChange}
                                        {...register('EMPLOYER_LOCATION_CODE', { required: { value: true, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                        value={details.EMPLOYER_LOCATION_CODE || ""}
                                        className="form-control"
                                        name="EMPLOYER_LOCATION_CODE"
                                      />
                                    </div> */}

                                    <div className="form-group col-lg-4">
                                      <label className="label-clr">Town</label>
                                      <input
                                        type="text"
                                        onChange={handleChange}
                                        {...register('EMPLOYER_TOWN', { required: { value: ((details.EMPLOYMENT_STATUS === "Employed" ||
                                        details.EMPLOYMENT_STATUS === "Self-Employed") && details.EMPLOYER_TOWN === "") ? true :false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                        value={details.EMPLOYER_TOWN || ""}
                                        className="form-control"
                                        name="EMPLOYER_TOWN"
                                      />
                                      {errors.EMPLOYER_TOWN && 
                                       <p className="errorValidate">{errors.EMPLOYER_TOWN.message}</p>
                                      }
                                    </div>

                                    <div className="form-group col-lg-4">
                                      <label className="label-clr">
                                        {" "}
                                        County
                                      </label>
                                      <input
                                        type="text"
                                        onChange={handleChange}
                                        {...register('EMPLOYER_COUNTY', { required: { value: ((details.EMPLOYMENT_STATUS === "Employed" ||
                                        details.EMPLOYMENT_STATUS === "Self-Employed") && details.EMPLOYER_COUNTY === "") ? true :false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                        value={details.EMPLOYER_COUNTY || ""}
                                        className="form-control"
                                        name="EMPLOYER_COUNTY"
                                      />
                                      {errors.EMPLOYER_COUNTY && 
                                       <p className="errorValidate">{errors.EMPLOYER_COUNTY.message}</p>
                                      }
                                    </div>
                                    <div className="form-group col-lg-4">
                                      <label className="label-clr">
                                        Post Code
                                      </label>
                                      <input
                                        type="text"
                                        onChange={handleChange}
                                        {...register('EMPLOYER_POSTCODE', { required: { value: ((details.EMPLOYMENT_STATUS === "Employed" ||
                                        details.EMPLOYMENT_STATUS === "Self-Employed") && details.EMPLOYER_POSTCODE === "") ? true :false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                        value={details.EMPLOYER_POSTCODE || ""}
                                        className="form-control"
                                        name="EMPLOYER_POSTCODE"
                                      />
                                      {errors.EMPLOYER_POSTCODE && 
                                       <p className="errorValidate">{errors.EMPLOYER_POSTCODE.message}</p>
                                      }
                                    </div>
                                  </div>

                                  <div className="row rowbuttom">
                                   {/*  <div className="form-group col-lg-4">
                                      <label className="label-clr">
                                        {" "}
                                        County
                                      </label>
                                      <input
                                        type="text"
                                        onChange={handleChange}
                                        {...register('EMPLOYER_COUNTY', { required: { value: true, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                        value={details.EMPLOYER_COUNTY || ""}
                                        className="form-control"
                                        name="EMPLOYER_COUNTY"
                                      />
                                      {errors.EMPLOYER_COUNTY && 
                                       <p className="errorValidate">{errors.EMPLOYER_COUNTY.message}</p>
                                      }
                                    </div>
                                    <div className="form-group col-lg-4">
                                      <label className="label-clr">
                                        Post Code
                                      </label>
                                      <input
                                        type="text"
                                        onChange={handleChange}
                                        {...register('EMPLOYER_POSTCODE', { required: { value: true, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                        value={details.EMPLOYER_POSTCODE || ""}
                                        className="form-control"
                                        name="EMPLOYER_POSTCODE"
                                      />
                                      {errors.EMPLOYER_POSTCODE && 
                                       <p className="errorValidate">{errors.EMPLOYER_POSTCODE.message}</p>
                                      }
                                    </div> */}
                                     <div className="form-group col-lg-4">
                                      <div className="form-group">
                                        <label className="label-clr">
                                          {" "}
                                          Country
                                        </label>
                                        <select
                                          onChange={handleChange}
                                          {...register('EMPLOYER_COUNTRY', { required: { value: ((details.EMPLOYMENT_STATUS === "Employed" ||
                                          details.EMPLOYMENT_STATUS === "Self-Employed") && details.EMPLOYER_COUNTRY === "") ? true :false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                          value={details.EMPLOYER_COUNTRY || ""}
                                          name="EMPLOYER_COUNTRY"
                                          className="form-select m-b-3"
                                          style={{ width: "100%" }}>
                                          <option value=''>select country</option>
                                          {
                                            countries.map((item) =>{
                                                return(
                                                    <option key={item.country}>
                                                        {item.country}
                                                    </option>
                                                )
                                            })
                                          }
                                        </select>
                                        {errors.EMPLOYER_COUNTRY && 
                                       <p className="errorValidate">{errors.EMPLOYER_COUNTRY.message}</p>
                                      }
                                      </div>
                                    </div>

                                    <div className="form-group col-lg-4">
                                      <label className="label-clr">
                                        Country Prefix
                                      </label>
                                      <input
                                        type="text"
                                        onChange={handleChange}
                                        {...register('EMPLOYER_COUNTRYPREFIX', { required: { value: ((details.EMPLOYMENT_STATUS === "Employed" ||
                                        details.EMPLOYMENT_STATUS === "Self-Employed") && details.EMPLOYER_COUNTRYPREFIX === "") ? true :false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                        value={details.EMPLOYER_COUNTRYPREFIX || ""}
                                        className="form-control"
                                        name="EMPLOYER_COUNTRYPREFIX"
                                      />
                                      {errors.EMPLOYER_COUNTRYPREFIX && 
                                       <p className="errorValidate">{errors.EMPLOYER_COUNTRYPREFIX.message}</p>
                                      }
                                    </div>
                                  </div>

                                 {/*  <div className="row rowbuttom">
                                    <div className="form-group col-lg-4">
                                      <div className="form-group">
                                        <label className="label-clr">
                                          {" "}
                                          Country
                                        </label>
                                        <select
                                          onChange={handleChange}
                                          {...register('EMPLOYER_COUNTRY', { required: { value: true, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                          value={details.EMPLOYER_COUNTRY || ""}
                                          name="EMPLOYER_COUNTRY"
                                          className="form-select m-b-3"
                                          style={{ width: "100%" }}>
                                          <option>select country</option>
                                          {
                                            countries.map((item) =>{
                                                return(
                                                    <option key={item.country}>
                                                        {item.country}
                                                    </option>
                                                )
                                            })
                                          }
                                        </select>
                                        {errors.EMPLOYER_COUNTRY && 
                                       <p className="errorValidate">{errors.EMPLOYER_COUNTRY.message}</p>
                                      }
                                      </div>
                                    </div>
                                    <div className="form-group col-lg-4">
                                      <label className="label-clr">FAX</label>
                                      <input
                                        type="text"
                                        onChange={handleChange}
                                        {...register('EMPLOYER_FAX', { required: { value: true, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                        value={details.EMPLOYER_FAX || ""}
                                        className="form-control"
                                        name="EMPLOYER_FAX"
                                      />
                                    </div>

                                    <div className="form-group col-lg-4">
                                      <label className="label-clr">
                                        VAT Rate
                                      </label>
                                      <input
                                        type="text"
                                        onChange={handleChange}
                                        {...register('EMPLOYER_VATRATE', { required: { value: true, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                        value={details.EMPLOYER_VATRATE || ""}
                                        className="form-control"
                                        name="EMPLOYER_VATRATE"
                                      />
                                    </div>
                                  </div> */}
                               
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="text-right m-t-xs">
                        {previousButton()}
                        {nextButton()}
                      </div>
                    </div>
                  )}

                 {/*  {wizardstep === 7 && (
                    <div
                      id="step7"
                      className={
                        wizardstep === 7
                          ? "p-m tab-pane wizardpane active"
                          : "p-m tab-pane wizardpane"
                      }>
                      {membership.map((x, i) => {
                        return (
                          <div className="row" key={i}>
                            <div className="col-lg-12">
                              <div className="row col-bb">
                                <div className="form-group col-lg-6">
                                  <label className="label-clr">
                                   ({i+1}) Membership Type
                                  </label>
                                  <input
                                    type="text"

                                    name={`memshiptype_${i}`}
                                    {...register(`memshiptype_${i}`, { 
                                      required: { value: true , message: "This field is required"},
                                      
                                      onChange: (e) => {handleMemshipChange(e,i)} })}
                                    value={x[`memshiptype_${i}`] || ""}
                                    className="form-control"
                                  />
                                  {errors[`.memshiptype_${i}`] && 
                                  <p className="errorValidate">{errors[`.memshiptype_${i}`].message}</p>
                                  }
                                </div>

                                <div className="form-group col-lg-6">
                                  <label className="label-clr">
                                    Membership Name{" "}
                                  </label>
                                  <input
                                    type="text"
                                    name={`memshipname_${i}`}
                                    {...register(`memshipname_${i}`, { 
                                      required: { value: true , message: "This field is required"},
                                      
                                      onChange: (e) => {handleMemshipChange(e,i)} })}
                                    value={x[`memshipname_${i}`] || ""}
                                    className="form-control"
                                  />
                                  {errors[`.memshipname_${i}`] && 
                                  <p className="errorValidate">{errors[`.memshipname_${i}`].message}</p>
                                  }
                                </div>
                              </div>

                              <div className="row rowbuttom">
                                <div className="form-group col-lg-4">
                                  <label className="label-clr">
                                    Issued Date{" "}
                                  </label>
                                  <input
                                    type="date"
                                    
                                    name={`memshipissued_${i}`}
                                    {...register(`memshipissued_${i}`, { 
                                      required: { value: true , message: "This field is required"},
                                      
                                      onChange: (e) => {handleMemshipChange(e,i)} })}
                                    value={x[`memshipissued_${i}`] || ""}
                                    className="form-control"
                                    max={x[`memshipexpiry_${i}`]}
                                  />
                                  {errors[`.memshipissued_${i}`] && 
                                  <p className="errorValidate">{errors[`.memshipissued_${i}`].message}</p>
                                  }
                                </div>

                                <div className="form-group col-lg-4">
                                  <label className="label-clr">
                                    Expiry Date{" "}
                                  </label>
                                  <input
                                    type="date"
                                   
                                    name={`memshipexpiry${i}`}
                                    {...register(`memshipexpiry${i}`, { 
                                      required: { value: true , message: "This field is required"},
                                      
                                      onChange: (e) => {handleMemshipChange(e,i)} })}
                                    value={x[`memshipexpiry${i}`] || ""}
                                    className="form-control"
                                    min={x[`memshipissued_${i}`]}
                                  />
                                  {errors[`.memshipexpiry${i}`] && 
                                  <p className="errorValidate">{errors[`.memshipexpiry${i}`].message}</p>
                                  }
                                </div>

                                <div className="form-group col-lg-4">
                                  <div
                                    className="text-left m-t-xs"
                                    style={{
                                      paddingTop: "7%",
                                      lineHeight: "1.8em",
                                      display: "grid",
                                      placeContent: "center",
                                    }}>
                                    {i !== 0 && (
                                      <button
                                        className="mr10 delbtn"
                                        onClick={() => handleRemoveMemship(i)}>
                                        <i className="fa fa-trash-o"></i> Delete
                                        Membership
                                      </button>
                                    )}
                                    {i === 0 && (
                                      <button
                                        className="addbtn"
                                        onClick={() => handleAddMemship(i)}>
                                        Add Membership
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      <div className="text-right m-t-xs">
                        {previousButton()}
                        {nextButton()}
                       
                      </div>
                    </div>
                  )} */}

                  {wizardstep === 7 && (
                    <div
                      id="step7"
                      className={
                        wizardstep === 7
                          ? "p-m tab-pane wizardpane active"
                          : "p-m tab-pane wizardpane"
                      }>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="row col-bb">
                            <div className="form-group col-lg-4">
                              <label className="label-clr">Name of Bank</label>
                              <input
                                type="text"
                                
                                {...register('BANK_NAME', { required: { value: details.BANK_NAME === "" ? true : false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                value={details.BANK_NAME || ""}
                                className="form-control"
                                name="BANK_NAME"
                              />
                               {errors.BANK_NAME && (
                                  <p className="errorValidate">This field is required</p>
                                  )}
                            </div>

                            <div className="form-group col-lg-4">
                              <label className="label-clr">
                                Is it a registered recognized financial
                                institute ?
                              </label>

                              <div className="radio radio-success radio-inline">
                                <input
                                  type="radio"
                                  id="regyes"
                                  value="Yes"
                                  name="REGISTERED"
                                  checked={details.REGISTERED === "Yes"}
                                  {...register("REGISTERED", { required: { value: (details.REGISTERED === "") ? true: false, message:"This field is required" }, 
                                    onChange: (e) => {handleChange(e)} })}
                                />
                                <label htmlFor="regyes">Yes </label>
                              </div>
                              <div className="radio radio-success radio-inline">
                                <input
                                  type="radio"
                                  id="regno"
                                  value="No"
                                  name="REGISTERED"
                                  checked={details.REGISTERED === "No"}
                                  {...register("REGISTERED", { required: { value: (details.REGISTERED === "") ? true: false, message:"This field is required" }, 
                                  onChange: (e) => {handleChange(e)} })}
                                />
                                <label htmlFor="regno">No</label>
                              </div>
                              {errors.REGISTERED && 
                                    <p className="errorValidate">{errors.REGISTERED.message}</p>
                              }
                             {/*  <input
                                type="text"
                               
                                {...register('REGISTERED', { required: { value: details.REGISTERED === "" ? true : false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                value={details.REGISTERED || ""}
                                className="form-control"
                                name="REGISTERED"
                              />
                               {errors.REGISTERED && (
                                  <p className="errorValidate">This field is required</p>
                                  )} */}
                            </div>

                            <div className="form-group col-lg-4">
                              <label className="label-clr">
                                Country funds held in
                              </label>
                              <select
                                onChange={handleChange}
                                {...register('HELD_COUNTRY', { required: { value: details.HELD_COUNTRY === "" ? true : false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                value={details.HELD_COUNTRY || ""}
                                name="HELD_COUNTRY"
                                className="form-select m-b-3"
                                style={{ width: "100%" }}>
                                <option value=''>select country</option>
                                      {
                                       countries.map((item) =>{
                                           return(
                                               <option key={item.country}>
                                                   {item.country}
                                               </option>
                                           )
                                       })
                                      }
                              </select>
                              {errors.HELD_COUNTRY && (
                                  <p className="errorValidate">This field is required</p>
                                  )}
                            </div>
                          </div>

                          <div className="row col-bb">
                            <div className="form-group col-lg-4">
                              <label className="label-clr">
                                Currency funds held in
                              </label>
                              <input
                                type="text"

                                onChange={handleChange}
                                  {...register('HELD_CURRENCY', { required: { value: details.HELD_CURRENCY === "" ? true : false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                  value={details.HELD_CURRENCY || ""}
                                  className="form-control"
                                  name="HELD_CURRENCY"
                              />
                               {errors.HELD_CURRENCY && (
                                  <p className="errorValidate">This field is required</p>
                               )}
                            </div>

                            <div className="form-group col-lg-4">
                              <label className="label-clr">Amount held</label>
                              <input
                                  type="number"
                                   onChange={handleChange}
                                    {...register('HELD_AMOUNT', { required: { value: details.HELD_AMOUNT === "" ? true : false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                    value={details.HELD_AMOUNT || ""}
                                    className="form-control"
                                    name="HELD_AMOUNT"
                              />
                               {errors.HELD_AMOUNT && (
                                  <p className="errorValidate">This field is required</p>
                               )}
                            </div>

                            <div className="form-group col-lg-4">
                              <label className="label-clr">
                                Date the funds have been held from
                              </label>
                              <input
                                type="date"
                              
                               onChange={handleChange}
                                    {...register('HELD_DATE', { required: { value: details.HELD_DATE === "" ? true : false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                    value={details.HELD_DATE || ""}
                                    className="form-control"
                                    name="HELD_DATE"
                              />
                               {errors.HELD_DATE && (
                                  <p className="errorValidate">This field is required</p>
                               )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="text-right m-t-xs">
                        {previousButton()}
                        {nextButton()}
                        {/* <a className="btn btn-default prev" href={void(0)}>Previous</a>
        <a className="btn btn-default next" href={void(0)}>Next</a> */}
                      </div>
                    </div>
                  )}

                  {wizardstep === 8 && (
                    <div
                      id="step8"
                      className={
                        wizardstep === 8
                          ? "p-m tab-pane wizardpane active"
                          : "p-m tab-pane wizardpane"
                      }>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="row col-bb">
                            <div className="form-group col-lg-12">
                              <h5 className="list-group-item-heading label-h5">
                                Are you currently in the UK ?
                              </h5>

                              <div className="radio radio-success radio-inline">
                                <input
                                  type="radio"
                                  id="inuky"
                                  value="Yes"
                                  name="ARE_YOU_IN_UK"
                                  checked={details.ARE_YOU_IN_UK === "Yes"}
                                  {...register('ARE_YOU_IN_UK', { required: { value: details.ARE_YOU_IN_UK === "" ? true : false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                />
                                <label htmlFor="inuky">Yes </label>
                              </div>
                              <div className="radio radio-success radio-inline">
                                <input
                                  type="radio"
                                  id="inukn"
                                  value="No"
                                  name="ARE_YOU_IN_UK"
                                  {...register('ARE_YOU_IN_UK', { required: { value: details.ARE_YOU_IN_UK === "" ? true : false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                  checked={details.ARE_YOU_IN_UK === "No"}
                                  onChange={handleChange}
                                />
                                <label htmlFor="inukn">No</label>
                              </div>
                              {errors.ARE_YOU_IN_UK && (
                                  <p className="errorValidate">This field is required</p>
                                  )}
                            </div>
                          </div>

                          <div
                            className={
                              details.ARE_YOU_IN_UK === "Yes"
                                ? "txtareahide collapsin"
                                : "txtareahide"
                            }>
                            <div className="row col-bb">
                              <div className="form-group col-lg-6">
                                <label className="label-clr">
                                  When did you enter the UK if you are applying
                                  in-country ?
                                </label>
                                <input
                                  type="date"
                                  max={getTodaysDate()}
                                  onChange={handleChange}
                                  {...register('WHEN_DID_YOU_ENTER_UK', { required: { value: (details.ARE_YOU_IN_UK === "Yes" && details.WHEN_DID_YOU_ENTER_UK === "") ? true : false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                  value={details.WHEN_DID_YOU_ENTER_UK || ""}
                                  className="form-control"
                                  name="WHEN_DID_YOU_ENTER_UK"
                                />
                                 {errors.WHEN_DID_YOU_ENTER_UK && 
                                <p className="errorValidate">{errors.WHEN_DID_YOU_ENTER_UK.message}</p>
                                }
                              </div>

                              <div className="form-group col-lg-6">
                                <h5 className="list-group-item-heading label-h5">
                                  Did you enter legally ?
                                </h5>

                                <div className="radio radio-success radio-inline">
                                  <input
                                    type="radio"
                                    id="legalentryy"
                                    value="Yes"
                                    name="DID_YOU_ENTER_LEGALLY"
                                    {...register('DID_YOU_ENTER_LEGALLY', { required: { value: (details.ARE_YOU_IN_UK === "Yes" && details.DID_YOU_ENTER_LEGALLY === "") ? true : false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                    checked={details.DID_YOU_ENTER_LEGALLY === "Yes"}
                                    onChange={handleChange}
                                  />
                                  <label htmlFor="legalentryy">Yes </label>
                                </div>
                                <div className="radio radio-success radio-inline">
                                  <input
                                    type="radio"
                                    id="legalentryn"
                                    value="No"
                                    name="DID_YOU_ENTER_LEGALLY"
                                    {...register('DID_YOU_ENTER_LEGALLY', { required: { value: (details.ARE_YOU_IN_UK === "Yes" && details.DID_YOU_ENTER_LEGALLY === "") ? true : false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                    checked={details.DID_YOU_ENTER_LEGALLY === "No"}
                                    onChange={handleChange}
                                  />
                                  <label htmlFor="legalentryn">No</label>
                                </div>
                                {errors.DID_YOU_ENTER_LEGALLY && 
                                <p className="errorValidate">{errors.DID_YOU_ENTER_LEGALLY.message}</p>
                                }
                              </div>
                            </div>

                            <div
                            className={
                              details.DID_YOU_ENTER_LEGALLY === "No"
                                ? "txtareahide collapsin"
                                : "txtareahide"
                            }>
                            <label className="label-tpr">
                            Please provide details of when, how and where you entered the UK
                            </label>
                            <div className="row col-bb">
                              <div className="form-group col-lg-12">
                                <div className="form-outline">
                                  <textarea
                                    className="form-control txtareabb"
                                    {...register('REASON_FOR_ILLEGAL_ENTRY', { required: { value: (details.ARE_YOU_IN_UK === "Yes" && details.DID_YOU_ENTER_LEGALLY === "No" && details.REASON_FOR_ILEGAL_ENTRY === "") ? true : false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                    id="illegallydetails"
                                    name="REASON_FOR_ILLEGAL_ENTRY"
                                    value={details.REASON_FOR_ILEGAL_ENTRY || ""}
                                    maxLength="100"
                                    rows="2"></textarea>
                                </div>
                                {errors.REASON_FOR_ILEGAL_ENTRY && (
                                  <p className="errorValidate">This field is required</p>
                                  )}
                              </div>
                            </div>
                          </div>

                            <div className="row col-bb">
                              <div className="form-group col-lg-6">
                                <label className="label-clr">
                                  When did your visa start ?
                                </label>
                                <input
                                  type="date"
                                  max={getTodaysDate()}
                                  onChange={handleChange}
                                  {...register('VISA_START_DATE', { required: { value: (details.ARE_YOU_IN_UK === "Yes" && details.VISA_START_DATE === "") ? true : false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                  value={details.VISA_START_DATE || ""}
                                  className="form-control"
                                  name="VISA_START_DATE"
                                />
                                 {errors.VISA_START_DATE && 
                                <p className="errorValidate">{errors.VISA_START_DATE.message}</p>
                                }
                              </div>

                              <div className="form-group col-lg-6">
                                <label className="label-clr">
                                  When does your visa expire ?
                                </label>
                                <input
                                  type="date"
                                  min={details.VISA_START_DATE}
                                  onChange={handleChange}
                                  {...register('VISA_END_DATE', { required: { value: (details.ARE_YOU_IN_UK === "Yes" && details.VISA_END_DATE === "") ? true : false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                  value={details.VISA_END_DATE || ""}
                                  className="form-control"
                                  name="VISA_END_DATE"
                                />
                                 {errors.VISA_END_DATE && 
                                <p className="errorValidate">{errors.VISA_END_DATE.message}</p>
                                }
                              </div>
                            </div>

                            <div className="row col-bb">
                              <div className="form-group col-lg-6">
                              <div className="form-group">
                                <label className="label-clr"> What is your current visa status?</label>
                                <select name="VISA_STATUS" {...register('VISA_STATUS', { 
                                      required: { value: (details.ARE_YOU_IN_UK === "Yes" && details.VISA_STATUS === "") ? true : false, message: "This field is required"},
                                      onChange: (e) => {handleChange(e)} })}
                                      value={details.VISA_STATUS || ""}
                                  className="form-select m-b-3" style={{ width: "100%" }}>
                                  <option value='' >select visa status</option>
                                  <option value="None">None</option>
                                  <option value="Active">Active</option>
                                  <option value="Expired">Expired</option>
                                  </select>
                              </div>
                              {errors.VISA_STATUS && 
                                <p className="errorValidate">{errors.VISA_STATUS.message}</p>
                                }
                                {/* <input
                                  type="text"
                                  onChange={handleChange}
                                  {...register('VISA_STATUS', { required: { value: (details.ARE_YOU_IN_UK === "Yes" && details.VISA_STATUS === "") ? true : false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                  value={details.VISA_STATUS || ""}
                                  className="form-control"
                                  name="VISA_STATUS"
                                />
                                {errors.VISA_STATUS && 
                                <p className="errorValidate">{errors.VISA_STATUS.message}</p>
                                } */}
                              </div>

                              <div className="form-group col-lg-6">
                                <h5 className="list-group-item-heading label-h5">
                                  Have you left the UK since you have been
                                  living in the UK ?
                                </h5>

                                <div className="radio radio-success radio-inline">
                                  <input
                                    type="radio"
                                    id="outofuksincey"
                                    value="Yes"
                                    name="OUT_OF_THE_UK_BEFORE"
                                    {...register('OUT_OF_THE_UK_BEFORE', { required: { value: (details.ARE_YOU_IN_UK === "Yes" && details.OUT_OF_THE_UK_BEFORE === "") ? true : false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                    checked={details.OUT_OF_THE_UK_BEFORE === "Yes"}
                                    onChange={handleChange}
                                  />
                                  <label htmlFor="outofuksincey">Yes </label>
                                </div>
                                <div className="radio radio-success radio-inline">
                                  <input
                                    type="radio"
                                    id="outofuksincen"
                                    value="No"
                                    name="OUT_OF_THE_UK_BEFORE"
                                    {...register('OUT_OF_THE_UK_BEFORE', { required: { value: (details.ARE_YOU_IN_UK === "Yes" && details.OUT_OF_THE_UK_BEFORE === "") ? true : false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                    checked={details.OUT_OF_THE_UK_BEFORE === "No"}
                                    onChange={handleChange}
                                  />
                                  <label htmlFor="outofuksincen">No</label>
                                </div>
                                {errors.OUT_OF_THE_UK_BEFORE && 
                                <p className="errorValidate">{errors.OUT_OF_THE_UK_BEFORE.message}</p>
                                }
                              </div>
                            </div>
                          </div>

                          <div
                            className={
                              details.OUT_OF_THE_UK_BEFORE === "Yes"
                                ? "txtareahide collapsin"
                                : "txtareahide"
                            }>
                          <div className="row col-bb">
                            <div className="form-group col-lg-12">
                              <label className="label-tpr">
                              What other countries have you visited ? Please provide the date you entered the country and the date you left as well as the reason for your visit.
                              </label>
                              {countriestravelled.map((x, i) => {
                                return (
                                  <div className="col-lg-12" key={i}>
                                    <div className="row rowbuttom">
                                      <div className="form-group col-lg-4">
                                        <label className="label-clr">
                                         ({i+1}) Country Visited
                                        </label>
                                        <select
                                          name={`countryname_${i}`}
                                          {...register(`countryname_${i}`, { 
                                            required: { value: (details.ARE_YOU_IN_UK === "Yes" && details.OUT_OF_THE_UK_BEFORE === "Yes" && x[`countryname_${i}`] === "") ? true : false, message: "This field is required"},
                                            onChange: (e) => {handleCTChange(e,i)} })}
                                          value={x[`countryname_${i}`] || ""}
                                          className="form-select m-b-3"
                                          style={{ width: "100%" }}>
                                          <option value=''>select country</option>
                                            {
                                            countries.map((item) =>{
                                                return(
                                                    <option key={item.country}>
                                                        {item.country}
                                                    </option>
                                                )
                                            })
                                            }
                                             
                                        </select>
                                        {errors[`countryname_${i}`] && 
                                              <p className="errorValidate">{errors[`countryname_${i}`].message}</p>
                                              }
                                      </div>

                                      <div className="form-group col-lg-4">
                                        <label className="label-clr">
                                          What date did you leave the UK ?
                                        </label>
                                        <input

                                          name={`dateoftravel_${i}`}
                                          {...register(`dateoftravel_${i}`, { 
                                            required: { value: (details.ARE_YOU_IN_UK === "Yes" && details.OUT_OF_THE_UK_BEFORE === "Yes" && x[`dateoftravel_${i}`] === "") ? true : false, message: "This field is required"},
                                            onChange: (e) => {handleCTChange(e,i)} })}
                                          value={x[`dateoftravel_${i}`] || ""}

                                          type="date"
                                         max={x[`dateofreturn_${i}`]}
                                          className="form-control"
                                        
                                        />
                                        {errors[`dateoftravel_${i}`] && 
                                        <p className="errorValidate">{errors[`dateoftravel_${i}`].message}</p>
                                        }
                                      </div>

                                      <div className="form-group col-lg-4">
                                        <label className="label-clr">
                                          What date did you return ?
                                        </label>
                                        <input
                                         name={`dateofreturn_${i}`}
                                         {...register(`dateofreturn_${i}`, { 
                                           required: { value: (details.ARE_YOU_IN_UK === "Yes" && details.OUT_OF_THE_UK_BEFORE === "Yes" && x[`dateofreturn_${i}`] === "") ? true : false, message: "This field is required"},
                                           onChange: (e) => {handleCTChange(e,i)} })}
                                         value={x[`dateofreturn_${i}`] || ""}

                                         type="date"
                                        min={x[`dateoftravel_${i}`]}
                                         className="form-control"
                                        />
                                         {errors[`dateofreturn_${i}`] && 
                                        <p className="errorValidate">{errors[`dateofreturn_${i}`].message}</p>
                                        }
                                      </div>
                                    </div>

                                    <div className="row rowbuttom">
                                      <div className="form-group col-lg-10">
                                        <h5 className="list-group-item-heading label-h5">
                                          Reason for your visit
                                        </h5>
                                        <div className="form-outline">
                                          <textarea
                                            className="form-control txtareabb"

                                            name={`reasonfortravel_${i}`}
                                            {...register(`reasonfortravel_${i}`, { 
                                              required: { value: (details.ARE_YOU_IN_UK === "Yes" && details.OUT_OF_THE_UK_BEFORE === "Yes" && x[`reasonfortravel_${i}`] === "") ? true : false, message: "This field is required"},
                                              onChange: (e) => {handleCTChange(e,i)} })}
                                            value={x[`reasonfortravel_${i}`] || ""}
                                            id="textArea"
                                           maxLength="100"
                                            rows="2"></textarea>
                                        </div>
                                        {errors[`reasonfortravel_${i}`] && 
                                        <p className="errorValidate">{errors[`reasonfortravel_${i}`].message}</p>
                                        }
                                      </div>

                                      <div className="form-group col-lg-2">
                                        <div
                                          className="text-left m-t-xs"
                                          style={{
                                            paddingTop: "37%",
                                            lineHeight: "1.8em",
                                            display: "grid",
                                            placeContent: "center",
                                          }}>
                                          {i !== 0 && (
                                            <button
                                              className="mr10 delbtn"
                                              style={{ width: "140px" }}
                                              onClick={() => handleRemoveCT(i)}>
                                              <i className="fa fa-trash-o"></i>{" "}
                                              Delete Country
                                            </button>
                                          )}
                                          {i === 0 && (
                                            <button
                                              style={{ fontSize: "1.0em" }}
                                              onClick={() => handleAddCT(i)}>
                                              Add Country
                                            </button>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                          </div>

                          <div className="row col-bb">
                            <div className="form-group col-lg-12">
                              <label className="label-tpr">
                                {" "}
                                Please list the last 5 visits to the UK – date
                                entered/date left and the reasons for your visit{" "}
                              </label>

                              {lastfivevisits.map((x, i) => {
                                return (
                                  <div className="col-lg-12" key={i}>
                                    <div className="row rowbuttom">
                                      <div className="form-group col-lg-6">
                                        <label className="label-clr">
                                         ({i+1}) Date of Entry
                                        </label>
                                        <input
                                          type="date"
                                          max={x[`dateofdeparture_${i}`] }
                                          name={`dateofentry_${i}`}
                                          {...register(`dateofentry_${i}`, { 
                                            required: { value: (x[`dateofentry_${i}`] === "") ? true : false, message: "This field is required"},
                                            onChange: (e) => {handleLFChange(e,i)} })}
                                          value={x[`dateofentry_${i}`] || ""}
                                          className="form-control"
                                        />
                                        {errors[`dateofentry_${i}`] && 
                                        <p className="errorValidate">{errors[`dateofentry_${i}`].message}</p>
                                        }
                                      </div>

                                      <div className="form-group col-lg-6">
                                        <label className="label-clr">
                                          Date of Departure{" "}
                                        </label>
                                        <input
                                          type="date"
                                          min={x[`dateofentry_${i}`] }
                                          name={`dateofdeparture_${i}`}
                                          {...register(`dateofdeparture_${i}`, { 
                                            required: { value: (x[`dateofdeparture_${i}`] === "") ? true : false, message: "This field is required"},
                                            onChange: (e) => {handleLFChange(e,i)} })}
                                          value={x[`dateofdeparture_${i}`] || ""}
                                          className="form-control"
                                        />
                                        {errors[`dateofdeparture_${i}`] && 
                                        <p className="errorValidate">{errors[`dateofdeparture_${i}`].message}</p>
                                        }
                                      </div>
                                    </div>

                                    <div className="row rowbuttom">
                                      <div className="form-group col-lg-10">
                                        <h5 className="list-group-item-heading label-h5">
                                          Reason for Visit
                                        </h5>
                                        <div className="form-outline">
                                          <textarea
                                            className="form-control txtareabb"
                                            name={`reasonforvisit_${i}`}
                                            {...register(`reasonforvisit_${i}`, { 
                                              required: { value: (x[`reasonforvisit_${i}`] === "") ? true : false, message: "This field is required"},
                                              onChange: (e) => {handleLFChange(e,i)} })}
                                            value={x[`reasonforvisit_${i}`] || ""}
                                            id="textArea"
                                            maxLength="100"
                                            rows="2"></textarea>
                                        </div>
                                        {errors[`reasonforvisit_${i}`] && 
                                        <p className="errorValidate">{errors[`reasonforvisit_${i}`].message}</p>
                                        }
                                      </div>

                                      <div className="form-group col-lg-2">
                                        <div
                                          className="text-left m-t-xs"
                                          style={{
                                            paddingTop: "20%",
                                            lineHeight: "1.8em",
                                            display: "grid",
                                            placeContent: "center",
                                          }}>
                                          {i !== 0 && (
                                            <button
                                              className="mr10 delbtn"
                                              onClick={() => handleRemoveLF(i)}>
                                              <i className="fa fa-trash-o"></i>{" "}
                                              Delete Visit
                                            </button>
                                          )}
                                          {i === 0 && (
                                            <button
                                              className="addbtnn"
                                              onClick={() => handleAddLF(i)}>
                                              Add Visit
                                            </button>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>

                          <div className="row col-bb">
                            <div className="form-group col-lg-12">
                              <h5 className="list-group-item-heading label-h5">
                                Have you ever entered the UK illegally ?
                              </h5>

                              <div className="radio radio-success radio-inline">
                                <input
                                  type="radio"
                                  id="ilegalentryy"
                                  value="Yes"
                                  name="ENTERED_UK_MEANS"
                                  checked={details.ENTERED_UK_MEANS === "Yes"}
                                  {...register('ENTERED_UK_MEANS', { required: { value: (details.ENTERED_UK_MEANS === "") ? true : false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                />
                                <label htmlFor="ilegalentryy">Yes </label>
                              </div>
                              <div className="radio radio-success radio-inline">
                                <input
                                  type="radio"
                                  id="ilegalentryn"
                                  value="No"
                                  name="ENTERED_UK_MEANS"
                                  checked={details.ENTERED_UK_MEANS === "No"}
                                  {...register('ENTERED_UK_MEANS', { required: { value: (details.ENTERED_UK_MEANS === "") ? true : false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                />
                                <label htmlFor="ilegalentryn">No</label>
                              </div>
                              {errors.ENTERED_UK_MEANS && (
                                  <p className="errorValidate">This field is required</p>
                                  )}
                            </div>
                          </div>

                          <div
                            className={
                              details.ENTERED_UK_MEANS === "Yes"
                                ? "txtareahide collapsin"
                                : "txtareahide"
                            }>
                            <label className="label-tpr">
                              Please provide details
                            </label>
                            <div className="row col-bb">
                              <div className="form-group col-lg-12">
                                <div className="form-outline">
                                  <textarea
                                    className="form-control txtareabb"
                                    {...register('REASON_FOR_ILEGAL_ENTRY', { required: { value: (details.ENTERED_UK_MEANS === "Yes" && details.REASON_FOR_ILEGAL_ENTRY === "") ? true : false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                    id="illegallydetails"
                                    name="REASON_FOR_ILEGAL_ENTRY"
                                    value={details.REASON_FOR_ILEGAL_ENTRY || ""}
                                    maxLength="100"
                                    rows="2"></textarea>
                                </div>
                                {errors.REASON_FOR_ILEGAL_ENTRY && (
                                  <p className="errorValidate">This field is required</p>
                                  )}
                              </div>
                            </div>
                          </div>

                          <div className="row col-bb">
                            <div className="form-group col-lg-12">
                              <h5 className="list-group-item-heading label-h5">
                                Have you ever stayed in any country, including the UK, without a VISA or after your VISA has expired ?
                              </h5>

                              <div className="radio radio-success radio-inline">
                                <input
                                  type="radio"
                                  id="breachedthelawy"
                                  value="Yes"
                                  
                                  name="EVER_STAYED_BEYOND_EXPIRY"
                                  checked={details.EVER_STAYED_BEYOND_EXPIRY === "Yes"}
                                  {...register('EVER_STAYED_BEYOND_EXPIRY', { required: { value: details.EVER_STAYED_BEYOND_EXPIRY === "" ? true : false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                />
                                <label htmlFor="breachedthelawy">Yes </label>
                              </div>
                              <div className="radio radio-success radio-inline">
                                <input
                                  type="radio"
                                  id="breachedthelawn"
                                  value="No"
                                  disabled={expirychecRef.current}
                                  name="EVER_STAYED_BEYOND_EXPIRY"
                                  checked={details.EVER_STAYED_BEYOND_EXPIRY === "No"}
                                  {...register('EVER_STAYED_BEYOND_EXPIRY', { required: { value: details.EVER_STAYED_BEYOND_EXPIRY === "" ? true : false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                />
                                <label htmlFor="breachedthelawn">No</label>
                              </div>
                              {errors.EVER_STAYED_BEYOND_EXPIRY && (
                                  <p className="errorValidate">This field is required</p>
                                  )}
                            </div>
                          </div>

                          <div
                            className={
                              details.EVER_STAYED_BEYOND_EXPIRY === "Yes"
                                ? "txtareahide collapsin"
                                : "txtareahide"
                            }>
                            <label className="label-tpr">
                              Please provide details
                            </label>
                            <div className="row col-bb">
                              <div className="form-group col-lg-12">
                                <div className="form-outline">
                                  <textarea
                                    className="form-control txtareabb"
                                    {...register('REASON_FOR_STAYING_BEYOND_EXPIRY', { required: { value:  (details.EVER_STAYED_BEYOND_EXPIRY === "Yes" && details.REASON_FOR_STAYING_BEYOND_EXPIRY === "") ? true : false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                    id="illegallydetails"
                                    name="REASON_FOR_STAYING_BEYOND_EXPIRY"
                                    value={details.REASON_FOR_STAYING_BEYOND_EXPIRY || ""}
                                    maxLength="100"
                                    rows="2"></textarea>
                                </div>
                                {errors.REASON_FOR_STAYING_BEYOND_EXPIRY && (
                                  <p className="errorValidate">This field is required</p>
                                  )}
                              </div>
                            </div>
                          </div>

                          <div className="row col-bb">
                            <div className="form-group col-lg-12">
                              <label className="label-tpr">
                                For either the UK or any other country, have you
                                ever been
                              </label>

                              {anyothercountry.map((x, i) => {
                                return (
                                  <div className="col-lg-12" key={i}>
                                    <div className="row rowbuttom">
                                      <div className="form-group col-lg-10">
                                       
                                        <select
                                         
                                          name={`othercountryname_${i}`}
                                          {...register(`othercountryname_${i}`, { 
                                            required: { value: x[`othercountryname_${i}`] === "" ? true : false, message: "This field is required"},
                                            
                                             onChange: (e) => {handleOtherCountryChange(e,i)} })}
                                          value={x[`othercountryname_${i}`] || ""}
                                          

                                          className="form-select m-b-3"
                                          style={{ width: "100%" }}>
                                           <option value=''>select country</option>
                                              {
                                              countries.map((item) =>{
                                                  return(
                                                      <option key={item.country}>
                                                          {item.country}
                                                      </option>
                                                  )
                                              })
                                              }
                                        </select>
                                        {errors[`othercountryname_${i}`] && 
                                        <p className="errorValidate">{errors[`othercountryname_${i}`].message}</p>
                                        }
                                      </div>

                                      <div className="form-group col-lg-2">
                                        <div
                                          className="text-left m-t-xs"
                                          style={{
                                            display: "grid",
                                            placeContent: "center",
                                          }}>
                                          {i !== 0 && (
                                            <button
                                              className="mr10 delbtn"
                                              onClick={() =>
                                                handleRemoveOtherCountry(i)
                                              }>
                                              <i className="fa fa-trash-o"></i>{" "}
                                              Delete Country
                                            </button>
                                          )}
                                          {i === 0 && (
                                            <button
                                              className="addbtnn"
                                              onClick={() => handleAddOtherCountry(i)}>
                                              Add Country
                                            </button>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>

                          <div className="row col-bb">
                            <div className="form-group col-lg-12">
                              <h5 className="list-group-item-heading label-h5">
                                Have you ever breached the conditions of your
                                leave ?
                              </h5>

                              <div className="radio radio-success radio-inline">
                                <input
                                  type="radio"
                                  id="leaveconditionsy"
                                  value="Yes"
                                  name="BREACHED_CONDITION_FOR_LEAVE"
                                  checked={details.BREACHED_CONDITION_FOR_LEAVE === "Yes"}
                                  {...register('BREACHED_CONDITION_FOR_LEAVE', { required: { value: details.BREACHED_CONDITION_FOR_LEAVE === "" ? true : false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                />
                                <label htmlFor="leaveconditionsy">Yes </label>
                              </div>
                              <div className="radio radio-success radio-inline">
                                <input
                                  type="radio"
                                  id="leaveconditionsn"
                                  value="No"
                                  name="BREACHED_CONDITION_FOR_LEAVE"
                                  checked={details.BREACHED_CONDITION_FOR_LEAVE === "No"}
                                  {...register('BREACHED_CONDITION_FOR_LEAVE', { required: { value: details.BREACHED_CONDITION_FOR_LEAVE === "" ? true : false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                />
                                <label htmlFor="leaveconditionsn">No</label>
                              </div>
                              {errors.BREACHED_CONDITION_FOR_LEAVE && (
                                  <p className="errorValidate">This field is required</p>
                              )}
                            </div>
                          </div>

                          <div
                            className={
                              details.BREACHED_CONDITION_FOR_LEAVE === "Yes"
                                ? "txtareahide collapsin"
                                : "txtareahide"
                            }>
                            <label className="label-tpr">
                              Please provide details
                            </label>
                            <div className="row col-bb">
                              <div className="form-group col-lg-12">
                                <div className="form-outline">
                                  <textarea
                                    className="form-control txtareabb"
                                   {...register('REASON_FOR_BREACH', { required: { value: (details.BREACHED_CONDITION_FOR_LEAVE === "Yes" && details.REASON_FOR_BREACH === "") ? true : false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                    id="leaveconditionsdetails"
                                    name="REASON_FOR_BREACH"
                                    value={details.REASON_FOR_BREACH || ""}
                                    maxLength="100"
                                    rows="2"></textarea>
                                </div>
                                {errors.REASON_FOR_BREACH && (
                                  <p className="errorValidate">This field is required</p>
                              )}
                              </div>
                            </div>
                          </div>

                          <div className="row col-bb">
                            <div className="form-group col-lg-12">
                              <h5 className="list-group-item-heading label-h5">
                              Have you ever worked without permission ?
                              </h5>

                              <div className="radio radio-success radio-inline">
                                <input
                                  type="radio"
                                  id="withoutpermity"
                                  value="Yes"
                                  name="WORK_WITHOUT_PERMIT"
                                  checked={details.WORK_WITHOUT_PERMIT === "Yes"}
                                  {...register('WORK_WITHOUT_PERMIT', { required: { value: details.WORK_WITHOUT_PERMIT === "" ? true : false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                />
                                <label htmlFor="withoutpermity">Yes </label>
                              </div>
                              <div className="radio radio-success radio-inline">
                                <input
                                  type="radio"
                                  id="withoutpermitn"
                                  value="No"
                                  name="WORK_WITHOUT_PERMIT"
                                  checked={details.WORK_WITHOUT_PERMIT === "No"}
                                  {...register('WORK_WITHOUT_PERMIT', { required: { value: details.WORK_WITHOUT_PERMIT === "" ? true : false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                />
                                <label htmlFor="withoutpermitn">No</label>
                              </div>
                              {errors.WORK_WITHOUT_PERMIT && (
                                  <p className="errorValidate">This field is required</p>
                              )}
                            </div>
                          </div>

                          <div
                            className={
                              details.WORK_WITHOUT_PERMIT === "Yes"
                                ? "txtareahide collapsin"
                                : "txtareahide"
                            }>
                            <label className="label-tpr">
                              Please provide details
                            </label>
                            <div className="row col-bb">
                              <div className="form-group col-lg-12">
                                <div className="form-outline">
                                  <textarea
                                    className="form-control txtareabb"
                                    {...register('REASON_FOR_WORK_WITHOUT_PERMIT', { required: { value: (details.WORK_WITHOUT_PERMIT === "Yes" && details.REASON_FOR_WORK_WITHOUT_PERMIT === "") ? true : false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                    id="withoutpermitdetails"
                                    name="REASON_FOR_WORK_WITHOUT_PERMIT"
                                    value={details.REASON_FOR_WORK_WITHOUT_PERMIT || ""}
                                    maxLength="100"
                                    rows="2"></textarea>
                                </div>
                                {errors.REASON_FOR_WORK_WITHOUT_PERMIT && (
                                  <p className="errorValidate">This field is required</p>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="row col-bb">
                            <div className="form-group col-lg-12">
                              <h5 className="list-group-item-heading label-h5">
                              Have you ever received public funds ?
                              </h5>

                              <div className="radio radio-success radio-inline">
                                <input
                                  type="radio"
                                  id="publicfundsy"
                                  value="Yes"
                                  name="RECEIVED_PUBLIC_FUNDS"
                                  checked={details.RECEIVED_PUBLIC_FUNDS === "Yes"}
                                  {...register('RECEIVED_PUBLIC_FUNDS', { required: { value: details.RECEIVED_PUBLIC_FUNDS === "" ? true:false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                />
                                <label htmlFor="publicfundsy">Yes </label>
                              </div>
                              <div className="radio radio-success radio-inline">
                                <input
                                  type="radio"
                                  id="publicfundsn"
                                  value="No"
                                  name="RECEIVED_PUBLIC_FUNDS"
                                  checked={details.RECEIVED_PUBLIC_FUNDS === "No"}
                                  {...register('RECEIVED_PUBLIC_FUNDS', { required: { value: details.RECEIVED_PUBLIC_FUNDS === "" ? true:false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                />
                                <label htmlFor="publicfundsn">No</label>
                              </div>
                              {errors.RECEIVED_PUBLIC_FUNDS && (
                                  <p className="errorValidate">This field is required</p>
                                )}
                            </div>
                          </div>

                          <div
                            className={
                              details.RECEIVED_PUBLIC_FUNDS === "Yes"
                                ? "txtareahide collapsin"
                                : "txtareahide"
                            }>
                            <label className="label-tpr">
                              Please provide details
                            </label>
                            <div className="row col-bb">
                              <div className="form-group col-lg-12">
                                <div className="form-outline">
                                  <textarea
                                    className="form-control txtareabb"
                                    {...register('REASON_RECEIVING_FUNDS', { required: { value: (details.RECEIVED_PUBLIC_FUNDS === "Yes" && details.REASON_RECEIVING_FUNDS === "") ? true : false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                    id="publicfundsdetails"
                                    name="REASON_RECEIVING_FUNDS"
                                    value={details.REASON_RECEIVING_FUNDS || ""}
                                    maxLength="100"
                                    rows="2"></textarea>
                                </div>
                                {errors.REASON_RECEIVING_FUNDS && (
                                  <p className="errorValidate">This field is required</p>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="row col-bb">
                            <div className="form-group col-lg-12">
                              <h5 className="list-group-item-heading label-h5">
                              Have you ever given false information when applying for a visa ?
                              </h5>

                              <div className="radio radio-success radio-inline">
                                <input
                                  type="radio"
                                  id="falseinfoy"
                                  value="Yes"
                                  name="GIVE_FALSE_INFO"
                                  checked={details.GIVE_FALSE_INFO === "Yes"}
                                  {...register('GIVE_FALSE_INFO', { required: { value: details.GIVE_FALSE_INFO === "" ? true : false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                />
                                <label htmlFor="falseinfoy">Yes </label>
                              </div>
                              <div className="radio radio-success radio-inline">
                                <input
                                  type="radio"
                                  id="falseinfon"
                                  value="No"
                                  name="GIVE_FALSE_INFO"
                                  checked={details.GIVE_FALSE_INFO === "No"}
                                  {...register('GIVE_FALSE_INFO', { required: { value: details.GIVE_FALSE_INFO === "" ? true : false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                />
                                <label htmlFor="falseinfon">No</label>
                              </div>
                              {errors.GIVE_FALSE_INFO && (
                                  <p className="errorValidate">This field is required</p>
                                )}
                            </div>
                          </div>

                          <div
                            className={
                              details.GIVE_FALSE_INFO === "Yes"
                                ? "txtareahide collapsin"
                                : "txtareahide"
                            }>
                            <label className="label-tpr">
                              Please provide details
                            </label>
                            <div className="row col-bb">
                              <div className="form-group col-lg-12">
                                <div className="form-outline">
                                  <textarea
                                    className="form-control txtareabb"
                                    {...register('REASON_FOR_FALSE_INFO', { required: { value: (details.GIVE_FALSE_INFO === "Yes" && details.REASON_FOR_FALSE_INFO === "") ? true : false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                    id="falseinfodetails"
                                    name="REASON_FOR_FALSE_INFO"
                                    value={details.REASON_FOR_FALSE_INFO || ""}
                                    maxLength="100"
                                    rows="2"></textarea>
                                </div>
                                {errors.REASON_FOR_FALSE_INFO && (
                                  <p className="errorValidate">This field is required</p>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="row col-bb">
                            <div className="form-group col-lg-12">
                              <h5 className="list-group-item-heading label-h5">
                              Have you ever used deception in a previous visa application ?
                              </h5>

                              <div className="radio radio-success radio-inline">
                                <input
                                  type="radio"
                                  id="deceptiony"
                                  value="Yes"
                                  name="USED_DECEPTION"
                                  checked={details.USED_DECEPTION === "Yes"}
                                  {...register('USED_DECEPTION', { required: { value: details.USED_DECEPTION === "" ? true : false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                />
                                <label htmlFor="deceptiony">Yes </label>
                              </div>
                              <div className="radio radio-success radio-inline">
                                <input
                                  type="radio"
                                  id="deceptionn"
                                  value="No"
                                  name="USED_DECEPTION"
                                  checked={details.USED_DECEPTION === "No"}
                                  {...register('USED_DECEPTION', { required: { value: details.USED_DECEPTION === "" ? true : false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                />
                                <label htmlFor="deceptionn">No</label>
                              </div>
                              {errors.USED_DECEPTION && (
                                  <p className="errorValidate">This field is required</p>
                                )}
                            </div>
                          </div>

                          <div
                            className={
                              details.USED_DECEPTION === "Yes"
                                ? "txtareahide collapsin"
                                : "txtareahide"
                            }>
                            <label className="label-tpr">
                              Please provide details
                            </label>
                            <div className="row col-bb">
                              <div className="form-group col-lg-12">
                                <div className="form-outline">
                                  <textarea
                                    className="form-control txtareabb"
                                    {...register('REASON_FOR_DECEPTION', { required: { value: (details.USED_DECEPTION === "Yes" && details.REASON_FOR_DECEPTION === "") ? true : false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                    id="deceptiondetails"
                                    name="REASON_FOR_DECEPTION"
                                    value={details.REASON_FOR_DECEPTION || ""}
                                    maxLength="100"
                                    rows="2"></textarea>
                                </div>
                                {errors.REASON_FOR_DECEPTION && (
                                  <p className="errorValidate">This field is required</p>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="row col-bb">
                            <div className="form-group col-lg-12">
                              <h5 className="list-group-item-heading label-h5">
                              Have you ever breached any other immigration laws ?
                              </h5>

                              <div className="radio radio-success radio-inline">
                                <input
                                  type="radio"
                                  id="Breachedy"
                                  value="Yes"
                                  name="BREACHED_OTHER_LAWS"
                                  checked={details.BREACHED_OTHER_LAWS === "Yes"}
                                  {...register('BREACHED_OTHER_LAWS', { required: { value: details.BREACHED_OTHER_LAWS === "" ? true : false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                />
                                <label htmlFor="Breachedy">Yes </label>
                              </div>
                              <div className="radio radio-success radio-inline">
                                <input
                                  type="radio"
                                  id="Breachedn"
                                  value="No"
                                  name="BREACHED_OTHER_LAWS"
                                  checked={details.BREACHED_OTHER_LAWS === "No"}
                                  {...register('BREACHED_OTHER_LAWS', { required: { value: details.BREACHED_OTHER_LAWS === "" ? true : false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                />
                                <label htmlFor="Breachedn">No</label>
                              </div>
                              {errors.BREACHED_OTHER_LAWS && (
                                  <p className="errorValidate">This field is required</p>
                                )}
                            </div>
                          </div>

                          <div
                            className={
                              details.BREACHED_OTHER_LAWS === "Yes"
                                ? "txtareahide collapsin"
                                : "txtareahide"
                            }>
                            <label className="label-tpr">
                              Please provide details
                            </label>
                            <div className="row col-bb">
                              <div className="form-group col-lg-12">
                                <div className="form-outline">
                                  <textarea
                                    className="form-control txtareabb"
                                    {...register('REASON_FOR_BREACHING__LAWS', { required: { value: (details.BREACHED_OTHER_LAWS === "Yes" && details.REASON_FOR_BREACHING__LAWS === "") ? true : false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                    id="Breacheddetails"
                                    name="REASON_FOR_BREACHING__LAWS"
                                    value={details.REASON_FOR_BREACHING__LAWS || ""}
                                    maxLength="100"
                                    rows="2"></textarea>
                                </div>
                                {errors.REASON_FOR_BREACHING__LAWS && (
                                  <p className="errorValidate">This field is required</p>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="row col-bb">
                            <div className="form-group col-lg-12">
                              <h5 className="list-group-item-heading label-h5">
                                Have you ever been refused a visa or refused entry at
                                the border ?
                              </h5>

                              <div className="radio radio-success radio-inline">
                                <input
                                  type="radio"
                                  id="refusedvisay"
                                  value="Yes"
                                  name="VISA_REFUSAL_QUESTION"
                                  checked={details.VISA_REFUSAL_QUESTION === "Yes"}
                                  {...register('VISA_REFUSAL_QUESTION', { required: { value: details.VISA_REFUSAL_QUESTION === "" ? true : false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                />
                                <label htmlFor="refusedvisay">Yes </label>
                              </div>
                              <div className="radio radio-success radio-inline">
                                <input
                                  type="radio"
                                  id="refusedvisan"
                                  value="No"
                                  name="VISA_REFUSAL_QUESTION"
                                  checked={details.VISA_REFUSAL_QUESTION === "No"}
                                  {...register('VISA_REFUSAL_QUESTION', { required: { value: details.VISA_REFUSAL_QUESTION === "" ? true : false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                />
                                <label htmlFor="refusedvisan">No</label>
                              </div>
                              {errors.VISA_REFUSAL_QUESTION && (
                                  <p className="errorValidate">This field is required</p>
                                )}
                            </div>
                          </div>

                          <div
                            className={
                              details.VISA_REFUSAL_QUESTION === "Yes"
                                ? "txtareahide collapsin"
                                : "txtareahide"
                            }>
                            <label className="label-tpr">
                              Please provide details
                            </label>
                            <div className="row col-bb">
                              <div className="form-group col-lg-12">
                                <div className="form-outline">
                                  <textarea
                                    className="form-control txtareabb"
                                    {...register('REASON_FOR_REFUSAL', { required: { value: (details.VISA_REFUSAL_QUESTION === "Yes" && details.REASON_FOR_REFUSAL === "") ? true : false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                    id="refusedvisadetails"
                                    name="REASON_FOR_REFUSAL"
                                    value={details.REASON_FOR_REFUSAL || ""}
                                    maxLength="100"
                                    rows="2"></textarea>
                                </div>
                                {errors.REASON_FOR_REFUSAL && (
                                  <p className="errorValidate">This field is required</p>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="row col-bb">
                            <div className="form-group col-lg-12">
                              <h5 className="list-group-item-heading label-h5">
                                Have you ever been refused permission to stay or
                                remain ?
                              </h5>

                              <div className="radio radio-success radio-inline">
                                <input
                                  type="radio"
                                  id="refusedpermity"
                                  value="Yes"
                                  name="PERMISSION_REFUSAL"
                                  checked={details.PERMISSION_REFUSAL === "Yes"}
                                 {...register('PERMISSION_REFUSAL', { required: { value: details.PERMISSION_REFUSAL === "" ? true : false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                />
                                <label htmlFor="refusedpermity">Yes </label>
                              </div>
                              <div className="radio radio-success radio-inline">
                                <input
                                  type="radio"
                                  id="refusedpermitn"
                                  value="No"
                                  name="PERMISSION_REFUSAL"
                                  checked={details.PERMISSION_REFUSAL === "No"}
                                 {...register('PERMISSION_REFUSAL', { required: { value: details.PERMISSION_REFUSAL === "" ? true : false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                />
                                <label htmlFor="refusedpermitn">No</label>
                              </div>
                              {errors.PERMISSION_REFUSAL && (
                                  <p className="errorValidate">This field is required</p>
                                )}
                            </div>
                          </div>

                          <div
                            className={
                              details.PERMISSION_REFUSAL === "Yes"
                                ? "txtareahide collapsin"
                                : "txtareahide"
                            }>
                            <label className="label-tpr">
                              Please provide details
                            </label>
                            <div className="row col-bb">
                              <div className="form-group col-lg-12">
                                <div className="form-outline">
                                  <textarea
                                    className="form-control txtareabb"
                                    {...register('REASON_FOR_PERMISSION_REFUSAL', { required: { value: (details.PERMISSION_REFUSAL === "Yes" && details.REASON_FOR_PERMISSION_REFUSAL ==="") ? true : false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                    id="refusedpermitdetails"
                                    name="REASON_FOR_PERMISSION_REFUSAL"
                                    value={details.REASON_FOR_PERMISSION_REFUSAL || ""}
                                    maxLength="100"
                                    rows="2"></textarea>
                                </div>
                                {errors.REASON_FOR_PERMISSION_REFUSAL && (
                                  <p className="errorValidate">This field is required</p>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="row col-bb">
                            <div className="form-group col-lg-12">
                              <h5 className="list-group-item-heading label-h5">
                                Have you ever been refused asylum ?
                              </h5>

                              <div className="radio radio-success radio-inline">
                                <input
                                  type="radio"
                                  id="refusedasylumy"
                                  value="Yes"
                                  name="ASYLUM_REFUSAL"
                                  checked={details.ASYLUM_REFUSAL === "Yes"}
                                  {...register('ASYLUM_REFUSAL', { required: { value: details.ASYLUM_REFUSAL === "" ? true : false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                />
                                <label htmlFor="refusedasylumy">Yes </label>
                              </div>
                              <div className="radio radio-success radio-inline">
                                <input
                                  type="radio"
                                  id="refusedasylumn"
                                  value="No"
                                  name="ASYLUM_REFUSAL"
                                  checked={details.ASYLUM_REFUSAL === "No"}
                                  {...register('ASYLUM_REFUSAL', { required: { value: details.ASYLUM_REFUSAL === "" ? true : false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                />
                                <label htmlFor="refusedasylumn">No</label>
                              </div>
                              {errors.ASYLUM_REFUSAL && (
                                  <p className="errorValidate">This field is required</p>
                                )}
                            </div>
                          </div>

                          <div
                            className={
                              details.ASYLUM_REFUSAL === "Yes"
                                ? "txtareahide collapsin"
                                : "txtareahide"
                            }>
                            <label className="label-tpr">
                              Please provide details
                            </label>
                            <div className="row col-bb">
                              <div className="form-group col-lg-12">
                                <div className="form-outline">
                                  <textarea
                                    className="form-control txtareabb"
                                    {...register('REASON_FOR_ASYLUM_REFUSAL', { required: { value: (details.ASYLUM_REFUSAL === "Yes" && details.REASON_FOR_ASYLUM_REFUSAL === "") ? true : false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                    id="refusedasylumdetails"
                                    name="REASON_FOR_ASYLUM_REFUSAL"
                                    value={details.REASON_FOR_ASYLUM_REFUSAL || ""}
                                    maxLength="100"
                                    rows="2"></textarea>
                                </div>
                                {errors.REASON_FOR_ASYLUM_REFUSAL && (
                                  <p className="errorValidate">This field is required</p>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="row col-bb">
                            <div className="form-group col-lg-12">
                              <h5 className="list-group-item-heading label-h5">
                                Have you ever been deported, removed or been
                                required to leave any country ?
                              </h5>

                              <div className="radio radio-success radio-inline">
                                <input
                                  type="radio"
                                  id="deportedy"
                                  value="Yes"
                                  name="EVER_DEPORTED"
                                  checked={details.EVER_DEPORTED === "Yes"}
                                   {...register('EVER_DEPORTED', { required: { value: details.EVER_DEPORTED === "" ? true : false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                />
                                <label htmlFor="deportedy">Yes </label>
                              </div>
                              <div className="radio radio-success radio-inline">
                                <input
                                  type="radio"
                                  id="deportedn"
                                  value="No"
                                  name="EVER_DEPORTED"
                                  checked={details.EVER_DEPORTED === "No"}
                                   {...register('EVER_DEPORTED', { required: { value: details.EVER_DEPORTED === "" ? true : false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                />
                                <label htmlFor="deportedn">No</label>
                              </div>
                              {errors.EVER_DEPORTED && (
                                  <p className="errorValidate">This field is required</p>
                                )}
                            </div>
                          </div>

                          <div
                            className={
                              details.EVER_DEPORTED === "Yes"
                                ? "txtareahide collapsin"
                                : "txtareahide"
                            }>
                            <label className="label-tpr">
                              Please provide details
                            </label>
                            <div className="row col-bb">
                              <div className="form-group col-lg-12">
                                <div className="form-outline">
                                  <textarea
                                    className="form-control txtareabb"
                                    {...register('REASON_FOR_DEPORTATION', { required: { value: (details.EVER_DEPORTED === "Yes" && details.REASON_FOR_DEPORTATION === "") ? true : false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                    id="deporteddetails"
                                    name="REASON_FOR_DEPORTATION"
                                    value={details.REASON_FOR_DEPORTATION || ""}
                                    maxLength="100"
                                    rows="2"></textarea>
                                </div>
                                {errors.REASON_FOR_DEPORTATION && (
                                  <p className="errorValidate">This field is required</p>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="row col-bb">
                            <div className="form-group col-lg-12">
                              <h5 className="list-group-item-heading label-h5">
                                Have you ever been excluded or banned from any
                                country ?
                              </h5>

                              <div className="radio radio-success radio-inline">
                                <input
                                  type="radio"
                                  id="bannedy"
                                  value="Yes"
                                  name="EVER_BANNED"
                                  checked={details.EVER_BANNED === "Yes"}
                                  {...register('EVER_BANNED', { required: { value: details.EVER_BANNED === "" ? true : false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                />
                                <label htmlFor="bannedy">Yes </label>
                              </div>
                              <div className="radio radio-success radio-inline">
                                <input
                                  type="radio"
                                  id="bannedn"
                                  value="No"
                                  name="EVER_BANNED"
                                  checked={details.EVER_BANNED === "No"}
                                  {...register('EVER_BANNED', { required: { value: details.EVER_BANNED === "" ? true : false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                />
                                <label htmlFor="bannedn">No</label>
                              </div>
                              {errors.EVER_BANNED && (
                                  <p className="errorValidate">This field is required</p>
                                )}
                            </div>
                          </div>

                          <div
                            className={
                              details.EVER_BANNED === "Yes"
                                ? "txtareahide collapsin"
                                : "txtareahide"
                            }>
                            <label className="label-tpr">
                              Please provide details:
                            </label>
                            <div className="row col-bb">
                              <div className="form-group col-lg-12">
                                <div className="form-outline">
                                  <textarea
                                    className="form-control txtareabb"
                                    {...register('REASON_FOR_BAN', { required: { value: (details.EVER_BANNED === "Yes" && details.REASON_FOR_BAN === "") ? true : false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                    id="banneddetails"
                                    name="REASON_FOR_BAN"
                                    value={details.REASON_FOR_BAN || ""}
                                    maxLength="100"
                                    rows="2"></textarea>
                                </div>
                                {errors.REASON_FOR_BAN && (
                                  <p className="errorValidate">This field is required</p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="text-right m-t-xs">
                        {previousButton()}
                        {nextButton()}
                        {/* <a className="btn btn-default prev" href={void(0)}>Previous</a>
        <a className="btn btn-default next" href={void(0)}>Next</a> */}
                      </div>
                    </div>
                  )}

                  {wizardstep === 9 && (
                    <div
                      id="step9"
                      className={
                        wizardstep === 9
                          ? "p-m tab-pane wizardpane active"
                          : "p-m tab-pane wizardpane"
                      }>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="row col-bb">
                            <div className="form-group col-lg-12">
                              <h5 className="list-group-item-heading label-h5">
                                Have you ever been charged with a criminal
                                offence ?
                              </h5>

                              <div className="radio radio-success radio-inline">
                                <input
                                  type="radio"
                                  id="crimechargey"
                                  value="Yes"
                                  name="CRIMINAL_OFFENSE"
                                  checked={details.CRIMINAL_OFFENSE === "Yes"}
                                  {...register('CRIMINAL_OFFENSE', { required: { value: details.CRIMINAL_OFFENSE === "" ? true : false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                />
                                <label htmlFor="crimechargey">Yes </label>
                              </div>
                              <div className="radio radio-success radio-inline">
                                <input
                                  type="radio"
                                  id="crimechargen"
                                  value="No"
                                  name="CRIMINAL_OFFENSE"
                                  checked={details.CRIMINAL_OFFENSE === "No"}
                                  {...register('CRIMINAL_OFFENSE', { required: { value: details.CRIMINAL_OFFENSE === "" ? true : false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                />
                                <label htmlFor="crimechargen">No</label>
                              </div>
                              {errors.CRIMINAL_OFFENSE && (
                                  <p className="errorValidate">This field is required</p>
                                )}
                            </div>
                          </div>

                          <div
                            className={
                              details.CRIMINAL_OFFENSE === "Yes"
                                ? "txtareahide collapsin"
                                : "txtareahide"
                            }>
                            <label className="label-tpr">
                              If <strong>Yes</strong>, please provide crime
                              details below{" "}
                            </label>
                            <div className="row col-bb">
                              <div className="form-group col-lg-12">
                                <div className="form-outline">
                                  <textarea
                                    className="form-control txtareabb"
                                    {...register('CRIMINAL_OFFENCE_ANSWER', { required: { value: (details.CRIMINAL_OFFENSE === "Yes" && details.CRIMINAL_OFFENCE_ANSWER === "") ? true : false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                    id="crimedetails"
                                    name="CRIMINAL_OFFENCE_ANSWER"
                                    value={details.CRIMINAL_OFFENCE_ANSWER || ""}
                                    maxLength="100"
                                    rows="2"></textarea>
                                </div>
                                {errors.CRIMINAL_OFFENCE_ANSWER && (
                                  <p className="errorValidate">This field is required</p>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="row col-bb">
                            <div className="form-group col-lg-12">
                              <h5 className="list-group-item-heading label-h5">
                                Do you have any pending prosecutions ?
                              </h5>
                              <div className="radio radio-success radio-inline">
                                <input
                                  type="radio"
                                  id="pendingprosecutey"
                                  value="Yes"
                                  name="PENDING_PERSECUTION"
                                  checked={details.PENDING_PERSECUTION === "Yes"}
                                   {...register('PENDING_PERSECUTION', { required: { value: details.PENDING_PERSECUTION === "" ? true : false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                />
                                <label htmlFor="pendingprosecutey">Yes </label>
                              </div>
                              <div className="radio radio-success radio-inline">
                                <input
                                  type="radio"
                                  id="pendingprosecuten"
                                  value="No"
                                  name="PENDING_PERSECUTION"
                                  checked={details.PENDING_PERSECUTION === "No"}
                                   {...register('PENDING_PERSECUTION', { required: { value: details.PENDING_PERSECUTION === "" ? true : false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                />
                                <label htmlFor="pendingprosecuten">No</label>
                              </div>
                              {errors.PENDING_PERSECUTION && (
                                  <p className="errorValidate">This field is required</p>
                                )}
                            </div>
                          </div>

                          <div
                            className={
                              details.PENDING_PERSECUTION === "Yes"
                                ? "txtareahide collapsin"
                                : "txtareahide"
                            }>
                            <label className="label-tpr">
                              Please provide details
                            </label>
                            <div className="row col-bb">
                              <div className="form-group col-lg-12">
                                <div className="form-outline">
                                  <textarea
                                    className="form-control txtareabb"
                                    {...register('DETAILS_OF_PROSECUTIONS', { required: { value: (details.PENDING_PERSECUTION === "Yes" && details.DETAILS_OF_PROSECUTIONS === "" ) ? true : false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                    id="prosecutiondetails"
                                    name="DETAILS_OF_PROSECUTIONS"
                                    value={details.DETAILS_OF_PROSECUTIONS || ""}
                                    maxLength="100"
                                    rows="2"></textarea>
                                </div>
                                {errors.DETAILS_OF_PROSECUTIONS && (
                                  <p className="errorValidate">This field is required</p>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="row col-bb">
                            <div className="form-group col-lg-12">
                              <h5 className="list-group-item-heading label-h5">
                                Have you ever had any terrorist views or charged
                                with or been questioned in relation to terrorist
                                charges ?
                              </h5>

                              <div className="radio radio-success radio-inline">
                                <input
                                  type="radio"
                                  id="terroristviewsy"
                                  value="Yes"
                                  name="TERRORIST_VIEW"
                                  checked={details.TERRORIST_VIEW === "Yes"}
                                 {...register('TERRORIST_VIEW', { required: { value: details.TERRORIST_VIEW === "" ? true : false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                />
                                <label htmlFor="terroristviewsy">Yes </label>
                              </div>
                              <div className="radio radio-success radio-inline">
                                <input
                                  type="radio"
                                  id="terroristviewsn"
                                  value="No"
                                  name="TERRORIST_VIEW"
                                  checked={details.TERRORIST_VIEW === "No"}
                                 {...register('TERRORIST_VIEW', { required: { value: details.TERRORIST_VIEW === "" ? true : false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                />
                                <label htmlFor="terroristviewsn">No</label>
                              </div>
                              {errors.TERRORIST_VIEW && (
                                  <p className="errorValidate">This field is required</p>
                                )}
                            </div>
                          </div>

                          <div
                            className={
                              details.TERRORIST_VIEW === "Yes"
                                ? "txtareahide collapsin"
                                : "txtareahide"
                            }>
                            <label className="label-tpr">
                              Please provide details
                            </label>
                            <div className="row col-bb">
                              <div className="form-group col-lg-12">
                                <div className="form-outline">
                                  <textarea
                                    className="form-control txtareabb"
                                    {...register('DETAILS_OF_TERRORIST_CHARGES', { required: { value: (details.TERRORIST_VIEW === "Yes" && details.DETAILS_OF_TERRORIST_CHARGES === "") ? true : false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                    id="terroristdetails"
                                    name="DETAILS_OF_TERRORIST_CHARGES"
                                    value={details.DETAILS_OF_TERRORIST_CHARGES || ""}
                                    maxLength="100"
                                    rows="2"></textarea>
                                </div>
                                {errors.DETAILS_OF_TERRORIST_CHARGES && (
                                  <p className="errorValidate">This field is required</p>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="row col-bb">
                            <div className="form-group col-lg-12">
                              <h5 className="list-group-item-heading label-h5">
                                Have you ever worked for the Judiciary/Security
                                Services/Media/Intelligence Agencies/Armed
                                Forces ?
                              </h5>

                              <div className="radio radio-success radio-inline">
                                <input
                                  type="radio"
                                  id="workedforsecurityy"
                                  value="Yes"
                                  name="WORKED_FOR_SECURITY"
                                  checked={details.WORKED_FOR_SECURITY === "Yes"}
                                   {...register('WORKED_FOR_SECURITY', { required: { value: details.WORKED_FOR_SECURITY === "" ? true : false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                />
                                <label htmlFor="workedforsecurityy">Yes </label>
                              </div>
                              <div className="radio radio-success radio-inline">
                                <input
                                  type="radio"
                                  id="workedforsecurityn"
                                  value="No"
                                  name="WORKED_FOR_SECURITY"
                                  checked={details.WORKED_FOR_SECURITY === "No"}
                                   {...register('WORKED_FOR_SECURITY', { required: { value: details.WORKED_FOR_SECURITY === "" ? true : false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                />
                                <label htmlFor="workedforsecurityn">No</label>
                              </div>
                              {errors.WORKED_FOR_SECURITY && (
                                  <p className="errorValidate">This field is required</p>
                                )}
                            </div>
                          </div>

                          <div
                            className={
                              details.WORKED_FOR_SECURITY === "Yes"
                                ? "txtareahide collapsin"
                                : "txtareahide"
                            }>
                            <label className="label-tpr">
                              Please provide details
                            </label>
                            <div className="row col-bb">
                              <div className="form-group col-lg-12">
                                <div className="form-outline">
                                  <textarea
                                    className="form-control txtareabb"
                                    {...register('DETAILS_OF_WORK', { required: { value: (details.WORKED_FOR_SECURITY === "Yes" && details.DETAILS_OF_WORK === "") ? true : false, message: "This field is required" }, onChange: (e) => {handleChange(e)} })}
                                    id="workedforsecuritydetails"
                                    name="DETAILS_OF_WORK"
                                    value={
                                      details.DETAILS_OF_WORK || ""
                                    }
                                    maxLength="100"
                                    rows="2"></textarea>
                                </div>
                                {errors.DETAILS_OF_WORK && (
                                  <p className="errorValidate">This field is required</p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="text-right m-t-xs">
                        {previousButton()}
                        {/* {nextButton()} */}
                        {/* <a className="btn btn-default prev" href={void(0)}>Previous</a>
        <a className="btn btn-default next" href={void(0)}>Next</a> */}
                      </div>
                    </div>
                  )}

                  {wizardstep === 10 && (
                    <div
                      id="step11"
                      className={
                        wizardstep === 10
                          ? "tab-pane wizardpane active"
                          : "tab-pane wizardpane "
                      }>
                      <div className="row text-center m-t-lg m-b-lg" style={{marginTop:"30px"}}>
                        <div className="col-lg-12">
                          <i
                            className="pe-7s-check fa-5x text-muted"
                            style={{ color: "#5d982e" }}></i>
                          <p
                            className="small m-t-md"
                            style={{ fontSize: "18px", color: "#5d982e" }}>
                            <strong>Details submitted Successfully !!</strong>
                          </p>
                          <p
                            className="small m-t-md"
                            style={{ fontSize: "13px" }}>
                            Your application is now with a caseworker. Please
                            expect feedback within 48 hours.
                          </p>
                        </div>
                      </div>
                      {wizardstep !== 10 && (
                        <div className="text-right m-t-xs">
                          {previousButton()}
                          {nextButton()}
                        </div>
                      )}
                    </div>
                  )}
                </div>

                {/*tab content end */}
              </div>

              <div
                className="panel-footer contact-footer"
                style={{ borderTop: "solid 0.0081em rgb(116 116 122 / 38%)" }}>
                {wizardstep !== 10 ? 
                 (
                  savedraftApp.isLoading ? 
                    (
                     /*  <button type="button" className="btn waves-effect waves-light">
                            <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>Saving your data... 
                      </button>  */
                      <button className="ladda-button btn btn-sm btn-custom m-t-n-xs" data-style="expand-right">
                      <span className="ladda-spinner"> <i className="fa fa-ravelry" style={{color: "#5ac557"}}></i>  &nbsp; </span><span className="ladda-label">Saving your data...</span>
                      <div className="ladda-progress" style={{width: "73px"}}></div>
                      </button>
                  ):(
                  <a
                    className="btn btn-sm btn-custom m-t-n-xs"
                    style={{ marginRight: "10px" }}
                    onClick={saveDraft}
                    >
                    <i className="fa fa-edit"></i> Save as Draft
                  </a>
                  ))
                  :("")
                }
                {wizardstep === 9 && (
                  <span>
                    <button
                      className="btn btn-sm btn-primary m-t-n-xs"
                      onClick={submitApplication}
                      type="submit">
                      <strong>Submit Application</strong>
                    </button>
                  </span>
                )}
                {wizardstep !== 10 ? 
                 (
                <span className="pull-right"><i className="fa fa-clock-o"> </i> {elapsedtime}</span>
                 ):("")}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default EditApplication;
