import React, { useState, useEffect,useRef } from "react";
import { useQuery, useMutation } from "react-query";
import { useForm } from "react-hook-form";
import toast, { Toaster } from 'react-hot-toast';
import { getAdminUser, removeUserAdminSession, useDebounce, sleep } from "../Utils/Common";
import * as api from '../Utils/adminApiCalls';
import "./AdminDashboard.css";
import {
    useNavigate, Link
  } from "react-router-dom";
import "react-phone-number-input/style.css";
import PhoneInput, {
  parsePhoneNumber,
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input/max";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import moment from "moment";
import AdminNav from "./Admin_nav";
import { Modal } from 'react-bootstrap';
import { verifySMS } from "../Utils/apiCall";

import MultiSelectClient from '../Utils/MultiSelectClient'

const pageLimit = 5;
const dataLimit = 10;

const Clients = () => {

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm({mode: "onChange"});

  const {
    register:registerp,
    handleSubmit:handleSubmitp,
    trigger:triggerp,
    formState: { errors:errorsp },
  } = useForm({mode: "onChange"});

  const {
    register:registerloc,
    handleSubmit:handleSubmitloc,
    trigger:triggerloc,
    formState: { errors:errorsloc },
  } = useForm({mode: "onChange"});

  const {
    register:registerAgent,
    handleSubmit:handleSubmitAgent,
    trigger:triggerAgent,
    formState: { errors:errorsAgent },
  } = useForm({mode: "onChange"});

  const {
    register:registerGrp,
    handleSubmit:handleSubmitGrp,
    trigger:triggerGrp,
    formState: { errors:errorsGrp },
  } = useForm({mode: "onChange"});

  const {
    register:registerCGrp,
    handleSubmit:handleSubmitCGrp,
    trigger:triggerCGrp,
    reset: resetCGrp,
    formState: { errors:errorsCGrp },
  } = useForm({mode: "onChange"});
  

    const [userData, setUserData] = useState({});

    const [bylocation, setBylocation] = useState({});

    const [clientID, setClientID] = useState("");

    const [clientslist, setClientslist] = useState([]);

const getClientID = (e) => {
  setClientID(e.target.getAttribute("data-clientid"));
}


///////////Modal Create Client //////
const [createclientopen, setCreateClientOpen] = useState(false);

const [errorsl, setErrorsl] = useState([]);

const [clientCreation, setClientCreation] = useState(false);

const [phone, setPhone] = useState("");

const [passwordShown, setPasswordShown] = useState(false);

const [cpasswordShown, setCpasswordShown] = useState(false);

const [frmstate, setFrmstate] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    email: "",
    //password: "",
    nationality: "GB",
    country: "United Kingdom",
    //password_confirmation: "",
    phone: phone,
    countrycallingcode: "",
});

const [errstate, setErrstate] = useState({
    errfirst_name: "",
    errmiddle_name: "",
    errlast_name: "",
    erremail: "",
    errpassword: "",
    errpassword_confirmation: "",
    errphone: "",
    errcountrycallingcode: "",
});

const [countries, setCountries] = useState([])

  useEffect(() => {

      //const data = require('./Utils/CountryData.json')
      const data = require('../Utils/countries.json')
        console.log(data)
        setCountries(data)

  }, []);

  useEffect(() => {
    if (phone !== "" && phone !== undefined) {
      const errlist = { ...errstate };
      const PhoneInputInput = document.querySelector("input[type=tel]"); //(".PhoneInputInput");
      if (
        isPossiblePhoneNumber(phone) !== true &&
        isValidPhoneNumber(phone) !== true
      ) {
        errlist.errphone = "Please enter a Valid Phone Number";
        PhoneInputInput.classList.remove("PhoneInputInput");
        PhoneInputInput.classList.add("invalidPhoneInputInput");
        setErrstate(errlist);
        setErrstate((errstate) => {
          console.log("updated errstate", errstate);
          return errstate;
        });
      } else {
        errlist.errphone = "";
        PhoneInputInput.classList.remove("invalidPhoneInputInput");
        PhoneInputInput.classList.add("PhoneInputInput");
        setErrstate(errlist);
        setErrstate((errstate) => {
          console.log("updated errstate", errstate);
          return errstate;
        });
      }
    }
    /* else {
      setPhone(000);
    } */
  }, [phone]);


  /////////////////Check Application Creation Status of Client ////////////

  const checkClientAppStatus = (e) => {
    const cid = e.target.getAttribute("data-clientid");
    setClientID(e.target.getAttribute("data-clientid"));
    console.log("client id check", cid);
    if(cid){
      checkAppStatus.mutate({"clientid":cid});
    }
  }

  const checkAppStatus = useMutation(api.checkAppCreationStatus, {
    onSuccess: (data) => {
      console.log("onSuccess data",data, "id: ");

      if(data?.status === "false"){
      toast.success(data?.Message,{
        duration: 6000,
      });
    }
    else if(data?.status === "true"){ 
      navigate("/admin_nav/admin_create_application", {
        state: {
          clientid: data?.clientid,
          action:"create"
        }, 
        replace: false,
      });
    }
    else {
      console.log("No status to action on", data?.status, "message", data?.Message);
    }
    
     /*  sleep(2000).then(() => {  
         
      }); */
      
    },
    onError: (error) => {
      
      const errObject = error.response.data?.errors

      console.log("errObject",errObject); 

        if(error.response.status === 401){
          toast.error("Session expired: Kindly re-login",{
            duration: 6000,
            });
          removeUserAdminSession();
          navigate("/Admin_login", {replace: false});
        }
        else {
          toast.error("Something went wrong :: submission",{
            duration: 6000,
            });
        }

    }
  });


  ////////////////////////////////////////////////////

  ////////////// Pre-Screen /////////////
  const [showPrescreen, setShowPrescreen] = useState(false);
  const [prescreendetails, setPrescreendetails] = useState({
    how_we_can_help_you_question: "",
    how_we_can_help_you: "",
    fullname: (frmstate?.first_name ?? "") + (frmstate?.middle_name ?? "") + (frmstate?.last_name ?? ""),
    client_email: frmstate?.email ?? "",
    client_phone: frmstate?.phone ?? "",
    country_of_residence: frmstate?.country ?? "",
    residency_question: "",
    type_of_permission: "",
    expiry: "",
    dob: "",
    english_proficiency: "",
    english_proficiency_level: "",
    other_languages: "",
    visa_refusal: "",
    visa_refusal_reason: "",
    in_your_own_words: ""
  });

  const getTodaysDate = () => {
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth()+1; //January is 0 so need to add 1 to make it 1!
    let yyyy = today.getFullYear();
    if(dd<10){
      dd='0'+dd
    } 
    if(mm<10){
      mm='0'+mm
    } 

    today = yyyy+'-'+mm+'-'+dd;

    console.log("todays date",today);

    return today;
  }

  const preScreenMutation = useMutation(api.submitClientPrescreen, {
    onSuccess: (data) => {
      console.log("onSuccess data",data, "id: ");
    
      sleep(2000).then(() => {
          toast.success('Client Created Successfully!',{
            duration: 6000,
          });
          clientListing.refetch();

          setCreateClientOpen(false);
          setClientCreation(false);
          setShowPrescreen(false);
      });
      
    },
    onError: (error) => {
      
      const errObject = error.response.data?.errors

      console.log("errObject",errObject); 

        if(error.response.status === 401){
          toast.error("Session expired: Kindly re-login",{
            duration: 6000,
            });
          removeUserAdminSession();
          navigate("/Admin_login", {replace: false});
        }
        else {
          toast.error("Something went wrong :: submission",{
            duration: 6000,
            });
        }

    }
  });

  const handlePrescreenSubmit = async () => {
    
    console.log("submitted prescreen data", prescreendetails);
 
    const frmvalidate = await triggerp();

    if (frmvalidate) {

      //console.log("formDetaild validated : ", formDetaild);
      preScreenMutation.mutate(prescreendetails);

    }
    else{
      console.log("formDetaild not validated : ", prescreendetails);

      }
  };

  const handlePreScreenChange = (event) => {
    event.persist();
    const { name, value, type } = event.target;
    //console.log("name:" + name + "  value:" + value + "type" + type);
    
    const prescreenformstate = { ...prescreendetails };
    prescreenformstate[name] = value;
    setPrescreendetails(prescreenformstate);

    setPrescreendetails((prescreendetails) => {
      console.log("updated prescreendetails", prescreendetails);
      return prescreendetails;
    });
   
  };

  ///////////// Create Group ////////////////////
  const [creategroupopen, setCreatGroupeOpen] = useState(false);

  const [frmGroupstate, setFrmGroupstate] = useState({
    GROUP_NAME	: "",
    USER:""
});

  const handleCreateGroupShow = (e) => {
    setCreatGroupeOpen(true);
  };

  const handleCreateGroupClose= (e) => {
    setCreatGroupeOpen(false);
  };

  const handleGroupChange= (e) => {
    e.persist();
  const { name, value } = e.target;

  const formstate = { ...frmGroupstate };
  formstate[name] = value;

    setFrmGroupstate(formstate);
    setFrmGroupstate(frmGroupstate => {
           console.log("frmGroupstate:", frmGroupstate);
           return frmGroupstate
    });
  

  };

  const handleMultiClientChange = (selectedOption) => {
   
      console.log(`Option selected:`, selectedOption);
      console.log(`optionsL:`, optionsL);
       const frmGrp = {...frmGroupstate};
       selectedOption.map(sele => {
        frmGrp.CLIENT_ID.push(sele.value);
       })
      
      frmGrp.CLIENT_ID = [...new Set(frmGrp.CLIENT_ID)];
      console.log(`frmGrp:`, frmGrp.CLIENT_ID);
      setFrmGroupstate(frmGrp);
      setFrmGroupstate(frmGroupstate => {
        return frmGroupstate
      }); 
    
  };

  const handleClientGroupCreation = async () => {
    const grpform = {...frmGroupstate};
    grpform.USER = userData?.id;
    const validateGroup = await triggerCGrp();
    if(validateGroup){
      createGroup.mutate(grpform);
    }

  }

  const createGroup = useMutation("createGroup", api.createGroup, {
    onSuccess: (data) => {
        console.log("Group created: ",data);
      
          toast.success("Group Successfully created",{
            duration: 6000,
            });

            resetCGrp();
            setCreatGroupeOpen(false);
    },
    onError: (error) => {
      
      console.log("Error Message",error); 
    
      if(error.response.status === 401){
        toast.error("Session Expired : Please Re-Login",{
          duration: 6000,
          });
       removeUserAdminSession();
        navigate("/admin_login", {replace: false});
      }
      else {
        toast.error("Something went wrong",{
          duration: 6000,
          });
  
          const errObject = error.response.data?.errors
         
          console.log("errObject",errObject); 
      }
    } 
  })
  

  /////////////// End Group ////////////////////

/////////////////// ClientCreation //////////////

const createClient = useMutation("createClient", api.AdminRegisterClient, {
  onSuccess: (data) => {
      console.log("client created: ",data?.message, "id", data?.id);
      
      if(data?.id !== undefined) {

       const prescreenfrm = {...prescreendetails};

       prescreenfrm.id = data?.id;
       prescreenfrm.user_id = userData?.id;
       prescreenfrm.first_name = frmstate.first_name;
       prescreenfrm.middle_name = frmstate.middle_name;
       prescreenfrm.last_name = frmstate.last_name;
       prescreenfrm.email = frmstate.email;
       prescreenfrm.country = frmstate.country;
       prescreenfrm.phone =  frmstate.phone;
   
       setPrescreendetails(prescreenfrm);
        
        toast.success("Client Successfully Registered",{
          duration: 6000,
          });

          setClientCreation(false);
          setShowPrescreen(true);

      }
      else{
        toast.error("client data ID was not receivedd",{
          duration: 6000,
        });
      }

  },
  onError: (error) => {
    
    console.log("Error Message",error); 
  
    if(error.response.status === 401){
      toast.error("Session Expired : Please Re-Login",{
        duration: 6000,
        });
     removeUserAdminSession();
      navigate("/admin_login", {replace: false});
    }
    else {
      toast.error("Something went wrong",{
        duration: 6000,
        });

        const errlist = []
        const errObject = error.response.data?.errors
        //console.log("typeof: ",typeof error.response.data.errors);
        if(error.response.data.errors !== undefined && typeof error.response.data.errors === 'object')
        {
              for(var propName in errObject) {
                if(errObject.hasOwnProperty(propName)) {
                    //console.log("within iteration",errObject[propName]); 
                    errlist.push(errObject[propName]);
                }
              }
            setErrorsl(errlist);
        }
        else
        {
          errlist.push(error.message)
          setErrorsl(errlist);
        }

        console.log("errlist",errlist); 
    }
  } 
})

const handleCreateClientShow = (e) => {
  setCreateClientOpen(true);
  setClientCreation(true);
};

const handleCreateClientClose= (e) => {
  setCreateClientOpen(false);
  setClientCreation(false);
  setShowPrescreen(false);
};

const handleClientCreation = () => {
  console.log("all client data",frmstate);

  const errors = [];
  const errfield = { ...errstate };

  if (!frmstate.first_name.match(/^[a-zA-Z]+$/)) {
    errfield.errfirst_name = "Invalid first name input";
    setErrstate(errfield);
    setErrstate((errstate) => {
      console.log("updated errstate", errstate);
      return errstate;
    });
    errors.push("Invalid first name input");
  } else if (frmstate.last_name.length < 3) {
    errfield.errlast_name = "last name must be at least 3 Characters long";
    setErrstate(errfield);
    setErrstate((errstate) => {
      console.log("updated errstate", errstate);
      return errstate;
    });
    errors.push("last name must be at least 3 Characters long");
  }

  if (frmstate.middle_name !== "" && !frmstate.middle_name.match(/^[a-zA-Z ]+$/)) {
    errfield.errmiddle_name = "Invalid middle name input";
    setErrstate(errfield);
    setErrstate((errstate) => {
      console.log("updated errstate", errstate);
      return errstate;
    });
    errors.push("Invalid middle name input");
  } else if (frmstate.middle_name !== "" && frmstate.middle_name.length < 3) {
    errfield.errmiddle_name = "middle name must be at least 3 Characters long";
    setErrstate(errfield);
    setErrstate((errstate) => {
      console.log("updated errstate", errstate);
      return errstate;
    });
    errors.push("middle name must be at least 3 Characters long");
  }

  if (!frmstate.last_name.match(/^[a-zA-Z]+$/)) {
    errfield.errlast_name = "Invalid first name input";
    setErrstate(errfield);
    setErrstate((errstate) => {
      console.log("updated errstate", errstate);
      return errstate;
    });
    errors.push("Invalid last name input");
  } else if (frmstate.last_name.length < 3) {
    errfield.errlast_name = "last name must be at least 3 Characters long";
    setErrstate(errfield);
    setErrstate((errstate) => {
      console.log("updated errstate", errstate);
      return errstate;
    });
    errors.push("last name must be at least 3 Characters long");
  }

  if (
    !/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/.test(
      frmstate.email
    )
  ) {
    errfield.erremail = "Invalid Email Address";
    setErrstate(errfield);
    setErrstate((errstate) => {
      console.log("updated errstate", errstate);
      return errstate;
    });
    errors.push("Invalid Email Address");
  }

  if (phone !== "" && phone !== undefined) {
    console.log("phone# is not empty nor undefined");
    if (
      isPossiblePhoneNumber(phone) !== true &&
      isValidPhoneNumber(phone) !== true
    ) {
      console.log("phone# is not valid");
      const PhoneInputInput = document.querySelector("input[type=tel]");
      PhoneInputInput.classList.remove("PhoneInputInput");
      PhoneInputInput.classList.add("invalidPhoneInputInput");
      errfield.errphone = "Invalid phone number";
      setErrstate(errfield);
      setErrstate((errstate) => {
        console.log("updated errstate", errstate);
        return errstate;
      });
      errors.push("Invalid Phone Number");
    }
  } else {
    console.log("phone# empty");
    const PhoneInputInput = document.querySelector("input[type=tel]");
    PhoneInputInput.classList.remove("PhoneInputInput");
    PhoneInputInput.classList.add("invalidPhoneInputInput");
    errfield.errphone = "Phone number cannot be empty";
    setErrstate(errfield);
    setErrstate((errstate) => {
      console.log("updated errstate", errstate);
      return errstate;
    });
    errors.push("Phone number cannot be empty");
  }

  /* if (frmstate.password.length < 8) {
    errfield.errpassword = "Password should be at least 8 characters long";
    setErrstate(errfield);
    setErrstate((errstate) => {
      console.log("updated errstate", errstate);
      return errstate;
    });
    errors.push("Password should be at least 8 characters long");
  }

  if (frmstate.password !== frmstate.password_confirmation) {
    errfield.errpassword_confirmation = "Passwords do not match";
    setErrstate(errfield);
    setErrstate((errstate) => {
      console.log("updated errstate", errstate);
      return errstate;
    });
    errors.push("Passwords do not match");
  }
 */
  if (errors.length === 0) {

    const regform = {...frmstate};
    regform.user_id = userData?.id;

    createClient.mutate(regform); 
   
  }
};


 // Password toggle handler
 const togglePassword = (e) => {
  e.preventDefault();
  setPasswordShown(!passwordShown);
};

// Password toggle handler
const toggleCPassword = (e) => {
  e.preventDefault();
  setCpasswordShown(!cpasswordShown);
};


const handleClientCreationChange = (event) => {
  event.persist();
  const { name, value } = event.target;
  console.log(
    event.target.type,
    "date moved selected value",
    event.target.name +
      "+++++++++++++++" +
      event.target.value +
      "Phone" +
      phone
  );

  const formstate = { ...frmstate };

  if (event.target.type === "select-one") {
    let selectedIndex = event.nativeEvent.target.selectedIndex;
    let selectedText = event.nativeEvent.target[selectedIndex].text;
    console.log("selected text:", selectedText);
    let CountryCode = event.nativeEvent.target[selectedIndex].getAttribute("data-code");
    console.log("selected item data code:", CountryCode);
    formstate.country = selectedText;
    formstate.nationality = event.nativeEvent.target[selectedIndex].getAttribute("data-code");
    console.log("updated formstate in select", formstate);
  }

  formstate[name] = value;

  formstate.phone = phone || "";

  console.log("updated formstate", formstate);
  setFrmstate(formstate);

  setFrmstate((frmstate) => {
    console.log("updated frmstate", frmstate);
    return frmstate;
  });
};

const handleOnBlur = (e) => {
  e.persist();
  const errlist = { ...errstate };

  //////first_name start
  if (e.target.name === "first_name") {
    if (!frmstate.first_name) {
      errlist.errfirst_name = "first name is required";
      setErrstate(errlist);
      setErrstate((errstate) => {
        console.log("updated errstate", errstate);
        return errstate;
      });
    } else if (!frmstate.first_name.match(/^[a-zA-Z]+$/)) {
      errlist.errfirst_name = "Please enter Only letters";
      setErrstate(errlist);
      setErrstate((errstate) => {
        console.log("updated errstate", errstate);
        return errstate;
      });
    } else if (frmstate.first_name.length < 3) {
      errlist.errfirst_name = "First name must be at least 3 Characters long";
      setErrstate(errlist);
      setErrstate((errstate) => {
        console.log("updated errstate", errstate);
        return errstate;
      });
    } else {
      errlist.errfirst_name = "";
      setErrstate(errlist);
      setErrstate((errstate) => {
        console.log("updated errstate", errstate);
        return errstate;
      });
    }
  }
  //////first_name end

  //////middle_name start
  if (e.target.name === "middle_name") {
    if( frmstate.middle_name !== ""){

        if (!frmstate.middle_name.match(/^[a-zA-Z ]+$/)) {
          errlist.errmiddle_name = "Please enter Only letters";
          setErrstate(errlist);
          setErrstate((errstate) => {
            console.log("updated errstate", errstate);
            return errstate;
          });
        } else if (frmstate.middle_name.length < 3) {
          errlist.errmiddle_name =
            "Middle name must be at least 3 Characters long";
          setErrstate(errlist);
          setErrstate((errstate) => {
            console.log("updated errstate", errstate);
            return errstate;
          });
        } else {
          errlist.errmiddle_name = "";
          setErrstate(errlist);
          setErrstate((errstate) => {
            console.log("updated errstate", errstate);
            return errstate;
          });
        }
    }
    else {
      errlist.errmiddle_name = "";
      setErrstate(errlist);
      setErrstate((errstate) => {
        console.log("updated errstate", errstate);
        return errstate;
      });
    }
  }
  //////middle_name end

  //////last_name start
  if (e.target.name === "last_name") {
    if (!frmstate.last_name) {
      errlist.errlast_name = "last name is required";
      setErrstate(errlist);
      setErrstate((errstate) => {
        console.log("updated errstate", errstate);
        return errstate;
      });
    } else if (!frmstate.last_name.match(/^[a-zA-Z]+$/)) {
      errlist.errlast_name = "Please enter Only letters";
      setErrstate(errlist);
      setErrstate((errstate) => {
        console.log("updated errstate", errstate);
        return errstate;
      });
    } else if (frmstate.last_name.length < 3) {
      errlist.errlast_name = "last_name must be at least 3 Characters long";
      setErrstate(errlist);
      setErrstate((errstate) => {
        console.log("updated errstate", errstate);
        return errstate;
      });
    } else {
      errlist.errlast_name = "";
      setErrstate(errlist);
      setErrstate((errstate) => {
        console.log("updated errstate", errstate);
        return errstate;
      });
    }
  }
  //////last_name end

  //////Email start
  if (e.target.name === "email") {
    if (!frmstate.email) {
      errlist.erremail = "Email is required";
      setErrstate(errlist);
      setErrstate((errstate) => {
        console.log("updated errstate", errstate);
        return errstate;
      });
    } else if (
      !/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/.test(
        frmstate.email
      )
    ) {
      errlist.erremail = "Email is Invalid";
      setErrstate(errlist);
      setErrstate((errstate) => {
        console.log("updated errstate", errstate);
        return errstate;
      });
    } else {
      errlist.erremail = "";
      setErrstate(errlist);
      setErrstate((errstate) => {
        console.log("updated errstate", errstate);
        return errstate;
      });
    }
  }
  //////Email end

  //////password start
 /*  if (e.target.name === "password") {
    if (!frmstate.password) {
      errlist.errpassword = "Password is required";
      setErrstate(errlist);
      setErrstate((errstate) => {
        console.log("updated errstate", errstate);
        return errstate;
      });
    } else if (frmstate.password.length < 8) {
      errlist.errpassword = "Password must be 8 or more  Characters long";
      setErrstate(errlist);
      setErrstate((errstate) => {
        console.log("updated errstate", errstate);
        return errstate;
      });
    } else if (frmstate.password !== frmstate.password_confirmation) {
      errlist.errpassword_confirmation = "Passwords do not match";
      setErrstate(errlist);
      setErrstate((errstate) => {
        console.log("updated errstate", errstate);
        return errstate;
      });
    } else {
      errlist.errpassword = "";
      setErrstate(errlist);
      setErrstate((errstate) => {
        console.log("updated errstate", errstate);
        return errstate;
      });
    }
  } */
  //////password end

  //////password_confirmation start
  /* if (e.target.name === "password_confirmation") {
    if (!frmstate.password_confirmation) {
      errlist.errpassword = "confirm password is required";
      setErrstate(errlist);
      setErrstate((errstate) => {
        console.log("updated errstate", errstate);
        return errstate;
      });
    } else if (frmstate.password !== frmstate.password_confirmation) {
      errlist.errpassword_confirmation = "Passwords do not match";
      setErrstate(errlist);
      setErrstate((errstate) => {
        console.log("updated errstate", errstate);
        return errstate;
      });
    } else {
      errlist.errpassword_confirmation = "";
      setErrstate(errlist);
      setErrstate((errstate) => {
        console.log("updated errstate", errstate);
        return errstate;
      });
    }
  } */
  //////password_confirmation end
};

const getPhone = (e) => {
  console.log("I am trying to get number here", e);
  const formstate = { ...frmstate };
  formstate.phone = e;

  setFrmstate(formstate);

  setFrmstate((frmstate) => {
    console.log("updated frmstate.phone", frmstate);
    return frmstate;
  });

  setPhone(e);
  setPhone( (phone) => {
    console.log("update phone state", phone);
    return phone;
  })

}
/////////////////////////////////////////////////////

    //////Modal Location
const [locopen, setLocOpen] = useState(false);
const [locLoading, setLocLoading] = useState(false);
const [currentoffice, setCurrentOffice] = useState("");


const handleLocShow = (e) => {
  setClientID(e.target.getAttribute("data-clientid"));
  setCurrentOffice(e.target.getAttribute("data-office"));
  setLocOpen(true);
  //getAllLocations.refetch();
};

const [officeLocations, setOfficeLocation] = useState([]);
const getAllLocations = useQuery("locationlist", () => api.getOfficeLocations(), {
  refetchOnWindowFocus: false,
  //enabled: locopen, // turned off by default, manual refetch is needed
  onSuccess: (data) => {
      console.log("location details: ",data);

      const apd = [...data];

      setOfficeLocation(apd);

      setOfficeLocation((officeLocations) => {
        return officeLocations;
      });
  },
  onError: (error) => {
    
    console.log("Error Message",error); 
  
    if(error.response.status === 401){
      toast.error("Session Expired : Please Re-Login",{
        duration: 6000,
        });
     removeUserAdminSession();
      navigate("/admin_login", {replace: false});
    }
    else {
      toast.error("Something went wrong",{
        duration: 6000,
        });
    }
  
  } 
})

const setCLientOffice = useMutation("reassignOffice", api.assignOfficeLocation, {
  onSuccess: (data) => {
      console.log("client details: ",data);
      
      clientRefetch();
      
      setLocOpen(false);
    
      toast.success("Location Re-Assigned Successfully",{
      duration: 6000,
      });
  },
  onError: (error) => {
    
    console.log("Error Message",error); 
  
    if(error.response.status === 401){
      toast.error("Session Expired : Please Re-Login",{
        duration: 6000,
        });
     removeUserAdminSession();
      navigate("/admin_login", {replace: false});
    }
    else {
      toast.error("Something went wrong",{
        duration: 6000,
        });
    }
  
  } 
})

const handleLocClose= (e) => {
  setLocOpen(false);
};

const handleLoc = async (e) => {
  console.log("e::::",e)
  console.log("CLient ID", e.target.getAttribute("data-clientid"));
  console.log("Selected Location",cdetails.office_location);
  const clientid = e.target.getAttribute("data-clientid");
  const office = cdetails.office_location;
  const validateloc = await triggerloc();
  if(validateloc){
  setCLientOffice.mutate({"client_id": Number(clientid), "office":office})
  }
};

/////////////////

///////////Modal Group //////
const [groupopen, setGroupOpen] = useState(false);

const [groupname, setGroupname] = useState("");

const handleGroupShow = (e) => {
  setClientID(e.target.getAttribute("data-clientid"));
  setGroupname(e.target.getAttribute("data-group"));
  setGroupOpen(true);
};

const handleGroupClose= (e) => {
  setGroupOpen(false);
};

const handleGroup = async (e) => {
  
  const clientid = e.target.getAttribute("data-clientid");
  const group = cdetails.client_group;
  const validateGroups = await triggerGrp();

  if(validateGroups){

    if(clientid !== "" && (group !== "" && group !== undefined)){
      setClientGroup.mutate({"clientid": Number(clientid), "group":group})
    }
    else {
      toast.error("Invalid Group data",{
        duration: 6000,
        });
        console.log(" clientid: ", e.target.getAttribute("data-clientid"), " group:", group);
    }
 }
};

const setClientGroup = useMutation("assigngroup", api.assignGroup, {
  onSuccess: (data) => {
      console.log("client details: ",data);
      
      clientRefetch();
      
      setGroupOpen(false);
    
      toast.success("Group Assigned Successfully",{
      duration: 6000,
      });
  },
  onError: (error) => {
    
    console.log("Error Message",error); 
  
    if(error.response.status === 401){
      toast.error("Session Expired : Please Re-Login",{
        duration: 6000,
        });
     removeUserAdminSession();
      navigate("/admin_login", {replace: false});
    }
    else {
      toast.error("Something went wrong",{
        duration: 6000,
        });
    }
  
  } 
})


const [allgroups, setAllGroups] = useState([]);
const getAllGroups = useQuery("groups", () => api.getAllGroups(), {
  refetchOnWindowFocus: false,
  enabled: groupopen, // turned off by default, manual refetch is needed
  onSuccess: (data) => {
      console.log("group details: ",data);

      const apd = [...data];

      console.log("group details: apd ",apd);

      setAllGroups(apd);

      setAllGroups((allgroups) => {
        console.log("allgroups",allgroups);
        return allgroups;
      });
  },
  onError: (error) => {
    
    console.log("Error Message",error); 
  
    if(error.response.status === 401){
      toast.error("Session Expired : Please Re-Login",{
        duration: 6000,
        });
     removeUserAdminSession();
      navigate("/admin_login", {replace: false});
    }
    else {
      toast.error("Something went wrong",{
        duration: 6000,
        });
    }
  
  } 
})
////////////////

///////////Modal Assign User //////

const [users, setUsers] = useState([]);
const fetchUserRef = useRef(false);

const getUsers = useQuery("users", () => api.getAllUsers(), {
  enabled: fetchUserRef.current,
  refetchOnWindowFocus:false,
  refetchOnMount:false,
  onSuccess: (data) => {
    console.log("users data:::", data);
    setUsers([...data]);
    setUsers(users => {
      return users;
    })
  },
  onError: (error) => {
    
      console.log("Error Message ::: ",error); 
    
      if(error?.response?.status === 401){
        toast.error("Session Expired : Please Re-Login",{
          duration: 6000,
          });
       removeUserAdminSession();
        navigate("/admin_login", {replace: false});
      }
      else {
        toast.error("Something went wrong",{
          duration: 6000,
          });
      }
  }
});
const [assignuseropen, setAssignUserOpen] = useState(false);

const [agentName, setAgentName] = useState("");

const handleAssignUserShow = (e) => {
  setClientID(e.target.getAttribute("data-clientid"));
  setAgentName(e.target.getAttribute("data-agent"));
  setAssignUserOpen(true);
  fetchUserRef.current = true;
  getUsers.refetch();
};

const handleAssignUserClose= (e) => {
  setAssignUserOpen(false);
  fetchUserRef.current = false;
};

const handleAssignUser = async (e) => {
  const clientid = e.target.getAttribute("data-clientid");
  const agent = cdetails.admin_user_text;
  const validateAgent = await triggerAgent();

  if(validateAgent) {

      if(clientid !== "" && (agent !== "" && agent !== undefined)){

      setClientAgent.mutate({"client_id": Number(clientid), "agent":agent})
      }
      else {
        toast.error("Invalid Agent data",{
          duration: 6000,
          });
          console.log(" clientid: ", e.target.getAttribute("data-clientid"), " agent:", agent);
      }
  }

};

const setClientAgent = useMutation("assignagent", api.assignAgent, {
  onSuccess: (data) => {
      console.log("client details: ",data);
      
      clientRefetch();
      
      setAssignUserOpen(false);
    
      toast.success("Agent Assigned Successfully",{
      duration: 6000,
      });
  },
  onError: (error) => {
    
    console.log("Error Message",error); 
  
    if(error.response.status === 401){
      toast.error("Session Expired : Please Re-Login",{
        duration: 6000,
        });
     removeUserAdminSession();
      navigate("/admin_login", {replace: false});
    }
    else {
      toast.error("Something went wrong",{
        duration: 6000,
        });
    }
  
  } 
})
////////////////

///////////Modal View Client //////
const [viewclientopen, setViewClientOpen] = useState(false);

const [clientDetails, setClientDetails] = useState([]);

const getClientDetails = useMutation("clientdetails", api.clientPrescreenAppDetails, {
  onSuccess: (data) => {
      console.log("client details: ",data);
      const apd = [...data?.client_details, ...data?.client_prescreening , ...data?.client_application ];

      console.log("apd client info: ", apd);
      setClientDetails(apd);
      setClientDetails((clientDetails) => {
       return clientDetails;
    });

    setViewClientOpen(true);
  },
  onError: (error) => {
    
    console.log("Error Message",error); 
  
    if(error.response.status === 401){
      toast.error("Session Expired : Please Re-Login",{
        duration: 6000,
        });
     removeUserAdminSession();
      navigate("/admin_login", {replace: false});
    }
    else {
      toast.error("Something went wrong",{
        duration: 6000,
        });
    }
  
  } 
})

const [cGroup, setCGroup] = useState(null);
const [cBranch, setCBranch] = useState(null);

const handleViewClientShow = (e) => {
  const clientid = e.target.getAttribute("data-clientid");
  setCGroup(e.target.getAttribute("data-cgroup"));
  setCBranch(e.target.getAttribute("data-cbranch"));
  setAgentName(e.target.getAttribute("data-agent"));



  if(clientid) {
  setClientID(e.target.getAttribute("data-clientid"));
  getClientDetails.mutate({"client_id": clientid});
  }
  else return null;
};

const handleViewClientClose= (e) => {
  setViewClientOpen(false);
};

const handleViewClient = (e) => {
  console.log(e.target.getAttribute("data-clientid"));
};
////////////////

const [cdetails, setCdetails] = useState({
  office_location : "",
  client_group : "",
  admin_user: ""
});


const handleChange = (e) => {
  console.log(e);

  if(e.target.name === "admin_user"){
    const optionText = e.target.selectedOptions[0].text;
    const cdetails_all = {...cdetails};
    cdetails_all[e.target.name] = e.target.value;
    cdetails_all.admin_user_text = e.target.selectedOptions[0].text;

    setCdetails(cdetails_all);

    setCdetails(cdetails => {
      console.log("cdetails", cdetails);
      return cdetails;
    })

  }
  else{
  const cdetails_all = {...cdetails};
  cdetails_all[e.target.name] = e.target.value;
  setCdetails(cdetails_all);
  setCdetails(cdetails => {
    return cdetails;
  })
 }
};
    useEffect(() => {

  
      function checkUserData() {
        const item = getAdminUser();
    
        if (item) {
          console.log("user item",item);
          setUserData(item);
  
          setUserData((userData) => {
            console.log("updated details", userData);
            return userData;
          });
  
        }
      }
      checkUserData();
  
      window.addEventListener('storage', checkUserData)
    
      return () => {
        window.removeEventListener('storage', checkUserData)
      }
      
    }, []);

    //const userMessages = useQuery(['usermessages', userData?.id], () => api.getUserMessages(userData?.id));

    const [optionsL, setOptionsL] = useState([])
     
    const clientListing = useQuery('getallclients', () => api.getAllClients(), {
      onSuccess: (data) => {
             console.log("client list: ",data);
             const apd = [...data];
             setClientslist(apd);
             setClientslist((clientslist) => {
               return clientslist;
             });
             
             let selectList = new Array();

             (apd.length > 0) && (
                    apd.map( (ap) => {
                      let fullname = ap.first_name + " ";
                      fullname += ap.middle_name === null ? "" : ap.middle_name+" ";
                      fullname += ap.last_name;
                      console.log("fullname of client::: ", fullname, " id", ap.id);
                      selectList = [...selectList, { value: ap.id, label: fullname }];
                    })
             )
             console.log("selectList", selectList);
             setOptionsL(selectList);
      },
      onError: (error) => {
    
        console.log("Error Message",error); 
      
        if(error.response.status === 401){
          toast.error("Session Expired : Please Re-Login",{
            duration: 6000,
            });
         removeUserAdminSession();
          navigate("/admin_login", {replace: false});
        }
        else {
          toast.error("Something went wrong",{
            duration: 6000,
            });
        }
      
      } 
    });
   
    const { loading: loadingdata, data: clientdata, refetch:clientRefetch } = clientListing;

    const createdDate = (datestr, l=0)=> {

      if(l === 0){
      const start = moment(datestr).format("dddd, MMMM Do YYYY, h:mm:ss a");
      return start;
    }
    else if(l === 1){
      const start = moment(datestr).format("llll");
      return start;
    }
     
    };

    /////////////Pagination ////////////

  //const [pages] = useState(Math.ceil(datamsg?.length / dataLimit) || 1);
  const pages = useRef(Math.ceil(clientslist?.length / dataLimit) || 1);

  //console.log("dataLimit::", dataLimit," datamsg length::", datamsg?.length, " pages::: ", Math.ceil(clientdata?.length / dataLimit));

  const [currentPage, setCurrentPage] = useState(1);

  const goToNextPage = () => {
      if(currentPage <= pages.current) {
      setCurrentPage((page) => page + 1);
      }
  }

  const goToPreviousPage = () => {
      if(currentPage > 1 ){
      setCurrentPage((page) => page - 1);
      }
  }

  const changePage = (event) => {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage(pageNumber);
  }

  const getPaginatedData = () => {
    const startIndex = currentPage * dataLimit - dataLimit;
    const endIndex = startIndex + dataLimit;
    //console.log("getPaginationData", clientdata?.slice(startIndex, endIndex))
    return clientslist?.slice(startIndex, endIndex);
  };

  const getPaginationGroup = () => { 
    let start = Math.floor((currentPage - 1) / pageLimit) * pageLimit;
    
    let page = Math.ceil(clientslist?.length / dataLimit) || 1;
    //console.log("local page ", Math.ceil(clientdata?.length / dataLimit));
    //console.log("Array Return", new Array(pages).fill().map((_, idx) => start + idx + 1), "  pages : ", pages);

    pages.current = page;
    
     return new Array(page).fill().map((_, idx) => start + idx + 1);
  }

  ////////////////////////////////////


  /////////////////Search function ///////////////
const [searchquery, setSearchquery] = useState("");

const [searchApi, setSearchAPi] = useState("");

const searchClients = (q) => {
 //console.log("query: ", q.target.value);

 const query = q.target.value;

 setSearchquery(query);

 setSearchquery(searchquery => {
   return searchquery;
 });
 
 if(query.length === 0){
  
  console.log("Refetching Application list 2", query, " query length next: ", query.length);
     
     clientRefetch();
 }
}

const debouncedFilter = useDebounce(searchquery, 500);

console.log("debouncedFilter", debouncedFilter); 

const { data:searchData, isLoading:searchLoading } = useQuery(
 ['applications', debouncedFilter], 
 () => api.searchClients(debouncedFilter),
 { enabled: Boolean(debouncedFilter),
 onSuccess: (data) => {
     console.log("data", data);
     const apd = [...data];
     setClientslist(apd);
     setClientslist((clientslist) => {
       return clientslist;
     })
 },
 onError: (error) => {
    
  console.log("Error Message",error); 

  if(error.response.status === 401){
    toast.error("Session Expired : Please Re-Login",{
      duration: 6000,
      });
   removeUserAdminSession();
    navigate("/admin_login", {replace: false});
  }
  else {
    toast.error("Something went wrong",{
      duration: 6000,
      });
  }

} 
})

 /////////////////////////////////

 ///////////////Top Thirty/////////////////

 const topThirty = () => {
  // manually refetch
  console.log("topThirty btn clicked");
  toprefetch();
};

const { refetch:toprefetch } = useQuery("key", api.topThirty, {
  refetchOnWindowFocus: false,
  enabled: false, // turned off by default, manual refetch is needed
  onSuccess: (data) => {
    console.log("topThirty data", data);
    const apd = [...data?.attributes];
    setClientslist(apd);
    setClientslist((clientslist) => {
      return clientslist;
    })
},
onError: (error) => {
    
  console.log("Error Message",error); 

  if(error.response.status === 401){
    toast.error("Session Expired : Please Re-Login",{
      duration: 6000,
      });
   removeUserAdminSession();
    navigate("/admin_login", {replace: false});
  }
  else {
    toast.error("Something went wrong",{
      duration: 6000,
      });
  }

} 
});

 //////////////////////////////////////////


 ///////////////Verified Clients/////////////////

 const verifiedClients = () => {
  // manually refetch
  console.log("verified btn clicked");
  verifiedrefetch();
};

const { data:verifiedData, refetch:verifiedrefetch } = useQuery("key", api.verifiedClients, {
  refetchOnWindowFocus: false,
  enabled: false, // turned off by default, manual refetch is needed
  onSuccess: (data) => {
    console.log("verifiedClients data", data);
    const apd = [...data?.attributes];
    setClientslist(apd);
    setClientslist((clientslist) => {
      return clientslist;
    })
},
onError: (error) => {
    
  console.log("Error Message",error); 

  if(error.response.status === 401){
    toast.error("Session Expired : Please Re-Login",{
      duration: 6000,
      });
   removeUserAdminSession();
    navigate("/admin_login", {replace: false});
  }
  else {
    toast.error("Something went wrong",{
      duration: 6000,
      });
  }

} 
});

 //////////////////////////////////////////


 /////////////// Clients by Location/////////////////

 const clientByLocation = (e) => {
  // manually refetch
  console.log("location ::", e.target.value);
  
  const locat = e.target.value;
  setBylocation(locat);
  (locat && locat !== "") && Clientbylocation.mutate({"client_office": locat});
};

const Clientbylocation = useMutation("bylocation", api.searchClientbyLocation, {
  refetchOnWindowFocus: false,
  enabled: false, // turned off by default, manual refetch is needed
  onSuccess: (data) => {
    console.log("by location data", data);
    const apd = [...data?.attributes];
    setClientslist(apd);
    setClientslist((clientslist) => {
      return clientslist;
    })
},
onError: (error) => {
    
  console.log("Error Message",error); 

  if(error.response.status === 401){
    toast.error("Session Expired : Please Re-Login",{
      duration: 6000,
      });
   removeUserAdminSession();
    navigate("/admin_login", {replace: false});
  }
  else {
    toast.error("Something went wrong",{
      duration: 6000,
      });
  }

} 
});

 //////////////////////////////////////////

    return (
        <>
        <Toaster />
<div className="normalheader" id="tpanel">
        <div className="hpanel">
          <div className="panel-body">
            <a className="small-header-action" id="toglepanel" href={void(0)}>
              <div className="clip-header">
                <i className="fa fa-arrow-up"></i>
              </div>
            </a>

            <div id="hbreadcrumb" className="pull-right m-t-lg">
              <ol className="hbreadcrumb breadcrumb">
                <li>
                  <a href={void(0)}>i-visas</a>
                </li>
                <li>
                  <span>Admin</span>
                </li>
                <li className="active">
                  <span>Clients</span>
                </li>
              </ol>
            </div>
            <h2 className="font-light m-b-xs">Client Management</h2>
            <small>
              Manage <strong>Clients</strong>
            </small>
          </div>
        </div>
      </div>

    <div className="content">
        <div className="row">
            <div className="col-lg-12 text-center welcome-message">
                

            <div className="hpanel email-compose">
            <div className="panel-heading hbuilt">
                    <div className="text-center p-xs font-normal">
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={searchClients}
                          value={searchquery || ""}
                          className="form-control input-sm"
                          placeholder="Search by Firstname, Lastname, Email, Location and Group..."
                        />{" "}
                        <span className="input-group-btn">
                          {" "}
                          <button
                            type="button"
                            disabled
                            className="btn btn-sm btn-default">
                            Search
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="panel-body">
                  {searchLoading ? (
                       <>
                      <div className="row text-center">
                      <div className="col-md-9 m-b-md" style={{textAlign: "left", display: "flex", flexDirection: "row", justifyItems: "flex-start", alignItems: "center", justifyContent: "normal"}}>

                          <button className="btn btn-info btn-sm" disabled >
                                <i className="fa fa-user"></i> Create Client
                              </button>
                              <span>&nbsp;|&nbsp;</span>

                         <button className="btn btn-info btn-sm" disabled >
                            <i className="fa fa-group"></i> Create Group
                          </button>
                          <span>&nbsp;|&nbsp;</span>
                            
                              <button className="btn btn-info btn-sm" disabled >
                                <strong>Top 30</strong>
                              </button>
                              <span>&nbsp;|&nbsp;</span>
                              <button className="btn btn-info btn-sm" disabled >
                                <strong>Verified</strong>
                              </button>
                            
                            <span>&nbsp; | Search by Location: &nbsp;</span>
                            <select
                                    name="bylocation"
                                    className="form-select m-b-3"
                                    style={{ width: "30%" }}>
                                    <option value=''>select location</option>
                                  </select>
                          </div>
                          <div className="col-md-3 mailbox-pagination m-b-md">
                            <span style={{ fontSize: "1.1rem" }}>
                            Showing 0 of 0 pages   &nbsp; &nbsp;
                            </span>
                            <div className="btn-group">
                              <button className="btn btn-default btn-sm previous disabled"
                             
                              disabled
                              >
                                <i className="fa fa-arrow-left"></i>
                              </button>
                              {getPaginationGroup().map((item, index) => (
                              <button 
                              key={index}
                              className="btn btn-default btn-sm disabled"
                              >
                              {item}
                              </button>
                              ))}
                              <button className="btn btn-default btn-sm next disabled"
                              disabled
                              >
                                <i className="fa fa-arrow-right"></i>
                              </button>
                            </div>
                          </div>
                      </div>
                        <div className="table-responsive" style={{overflowX: "inherit"}}>
                        <table className="table table-hover table-mailbox apptable">
                        <thead style={{borderBottom: "solid 2px #64d246"}}>
                              <tr>
                                <th>#</th>
                                <th>Full Name</th>
                                <th>Mobile Number </th>
                                <th>Email</th>
                               {/*  <th>SMS Verified</th>
                                <th>Email Verified</th> */}
                                <th>Office Location</th>
                                <th>Group</th>
                                <th>Creation Date </th>
                                <th className="text-left">Action</th>
                              
                              </tr>
                            </thead>
                            <tbody>
                            <tr className="active"><td colSpan="10" style={{textAlign:"center"}}>Searching...</td></tr>
                        </tbody>
                      </table>
                        </div>
                        </>
                    ):(
                      <>
                    <div className="row">
                      <div className="col-md-9 m-b-md" style={{textAlign: "left", display: "flex", flexDirection: "row", justifyItems: "flex-start", alignItems: "center", justifyContent: "normal"}}>

                      <button className="btn btn-info btn-sm" onClick={handleCreateClientShow} >
                            <i className="fa fa-user"></i> Create Client
                          </button>
                          <span>&nbsp;|&nbsp;</span>

                          <button className="btn btn-info btn-sm" onClick={handleCreateGroupShow} >
                            <i className="fa fa-group"></i> Create Group
                          </button>
                          <span>&nbsp;|&nbsp;</span>
                        
                          <button className="btn btn-info btn-sm" onClick={topThirty} >
                             <strong>Top 30</strong>
                          </button>
                          <span>&nbsp;|&nbsp;</span>
                          <button className="btn btn-info btn-sm" onClick={verifiedClients} >
                            <strong>Verified</strong>
                          </button>
                        
                        <span>&nbsp; | Search by Location: &nbsp;</span>
                        <select
                                name="bylocation"
                               {...register("bylocation", { 
                                          required: { value: false, message: "This field is required" }, 
                                          onChange: (e) => {clientByLocation(e)} })}
                                value={bylocation || ""} // Prop: The email input data
                               
                                className="form-select m-b-3"
                                style={{ width: "30%" }}>
                                <option value=''>select location</option>
                                {officeLocations?.map(loc => 
                                (
                                  <option key={loc.id} value={loc.LOCATION_CODE}>{loc.LOCATION_CODE}</option>
                                ))}
                               {/*  <option value="London">London</option>
                                <option value="Islamabad">Islamabad</option>
                                <option value="Karachi">Karachi</option>
                                <option value="Kiev">Kiev</option>
                                <option value="Sindh">Sindh</option>
                                <option value="Rawalpindi">Rawalpindi</option>
                                <option value="Sheffield">Sheffield</option>
                                <option value="Accra">Accra</option> */}
                              </select>
                      </div>
                      <div className="col-md-3 mailbox-pagination m-b-md">
                        <span style={{ fontSize: "1.1rem" }}>
                        {!!clientslist && (Object.keys(clientslist).length > 0) ? `Showing ${currentPage} of ${pages.current} pages` : "Showing 0 pages"}   &nbsp; &nbsp;
                        </span>
                        <div className="btn-group">
                          <button className={`btn btn-default btn-sm previous ${currentPage === 1 ? 'disabled' : ''}`}
                          onClick={goToPreviousPage}
                          disabled={!!clientslist && (currentPage === 1) ? true : false} 
                          >
                            <i className="fa fa-arrow-left"></i>
                          </button>
                          {getPaginationGroup().map((item, index) => (
                          <button 
                          key={index}
                          onClick={changePage}
                          className={`btn btn-default btn-sm  ${currentPage === item ? 'active' : null}`}
                          >
                           {item}
                          </button>
                          ))}
                          <button className={`btn btn-default btn-sm next ${currentPage === pages.current ? 'disabled' : ''}`}
                          onClick={goToNextPage} 
                          disabled={!!clientslist && (currentPage === pages.current) ? true : false} 
                          >
                            <i className="fa fa-arrow-right"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="table-responsive" style={{overflowX: "inherit"}}>
                      <table className="table table-hover table-mailbox apptable">
                      <thead style={{borderBottom: "solid 2px #64d246"}}>
                          <tr>
                            <th>#</th>
                            <th>Full Name</th>
                            <th>Mobile Number </th>
                            <th>Email</th>
                            {/* <th>SMS Verified</th>
                            <th>Email Verified</th> */}
                            <th>Office Location</th>
                            <th>Group</th>
                            <th>Creation Date </th>
                            <th className="text-left">Action</th>
                           
                          </tr>
                        </thead>
                        <tbody>

                        {!!clientslist && (Object.keys(clientslist).length > 0) ? 
                            
                            getPaginatedData().map((item,i) =>{
                                         return (
                          <tr className="unread " key={i} style={{borderBottom:"solid 1px #e8ebef"}}>
                            <td className="text-left">
                             {i+1}
                            </td>
                            <td className="text-left">
                             {item.first_name} {item.last_name}
                            </td>
                            <td className="text-left">
                            {formatPhoneNumberIntl(item.phone)}
                            </td>
                             <td className="text-left">{item.email}</td> 
                           {/*  <td>
                            {item.sms_verified ? <i className="fa fa-check"></i> :
                            <i className="fa fa-cancel"></i> }
                            {item.sms_verified ? " Yes" : " No"}
                            </td>
                            <td>
                            {item.email_verified_at ? <i className="fa fa-check"></i> :
                            <i className="fa fa-cancel"></i> }
                            {item.email_verified_at ? " Yes" : " No"}
                            </td> */}
                            <td className="text-left">
                              {item.LOCATION_CODE ?? " Not Set"}
                            </td>
                            <td className="text-left">
                              {item.GROUP_NAME ?? "None"}
                            </td>
                            <td className="text-left mail-date">
                            {createdDate(item?.created_at,1)}
                            </td>
                            <td className="text-left mail-date">
                            <div className="btn-group">

                           
                                    <button data-toggle="dropdown" className="btn btn-default dropdown-toggle">Action <span className="caret"></span></button>
                                    <ul className="dropdown-menu">
                                       
                                        
                                        <li><Link to="# " data-cgroup={item.GROUP_NAME} data-cbranch={item.LOCATION_CODE} data-clientid={item.id} data-agent={item.users_firstname || "Samarah"} onClick={handleViewClientShow} >View</Link></li>
                                        {/* <li><Link to="# " data-toggle="modal" data-target="#editModal" data-clientid={item.id} onClick={getClientID} >Edit</Link></li> */}
                                        <li> <Link to="# " data-clientid={item.id} data-office={item.LOCATION_CODE} onClick={handleLocShow}> Change Location </Link> </li>
                                        <li className="divider"></li>
                                        <li><Link to="# " data-clientid={item.id} onClick={checkClientAppStatus} /* style={{pointerEvents: "none"}} */>Create Application</Link></li>
                                        <li><Link to="# " data-clientid={item.id} data-group={item.GROUP_NAME ?? "None"} onClick={handleGroupShow} >Add to Group</Link></li>
                                        <li><Link to="# " data-clientid={item.id} data-agent={item.users_firstname || "Samarah"} onClick={handleAssignUserShow} >Assign to Agent</Link></li>
                                    </ul>
                          </div>
                            </td>
                          </tr>
                                )
                            }) : (
                            <tr className="unread active"><td colSpan="10" style={{textAlign:"center"}}>No Clients found</td></tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    </>
                    )}
                  </div>
                  <div className="panel-footer text-left">
                  {!!clientslist && (Object.keys(clientslist).length > 0) ? clientslist.length : 0}  Client{!!clientslist && (Object.keys(clientslist).length > 1) ? 's' : ''}
                 </div>
            </div>
        </div>

       
    </div>
    </div>

    {/* <footer className="footer" style={{ maxHeight: "40px" }}>
          <div className="mt-4 mt-md-5 text-center" style={{ marginTop: "0" }}>
            <p className="footer-p">
              © <script>document.write(new Date().getFullYear())</script>{" "}
              i-visas.com
            </p>
          </div>
        </footer> */}

        <Modal show={createclientopen} onHide={handleCreateClientClose} animation={false}
        dialogClassName="modal-85w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        >
           <div className="color-line"></div>
        <Modal.Header className="modal-header text-center" style={{padding: "10px 10px"}}>
          <Modal.Title>Client Creation</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{padding: "5px"}}>
        <div className="hpanel">
        <div className="alert alert-success">
                <i className="fa fa-bolt"></i> By registering you agree to i-visas <a href="# " className="text-primary" style={{ fontWeight: "700" }}>Terms of Use </a>
                <span style={{paddingLeft:"20%", fontSize:"16px"}}>{clientCreation ? "Register Client" : ""}  {showPrescreen ? "Complete Prescreen" : ""}</span>
            </div>
        <div className="panel-body no-padding">
          <div className="row">
              <div className="form-group col-lg-12" style={{overflow:"hidden",overflowX: "clip"}}>
              <div className={clientCreation ? "showregform" : "hideregform"} >
              <form
                      className="needs-validation mt-4 pt-2"
                      noValidate
                      id="regform"
                      action="">
                      <div className="row">
                        <div className="mb-3 col-sm-4">
                          <label htmlFor="first_name" className="form-label">
                            First Name
                          </label>
                          <input
                            type="text"
                            pattern="^[a-zA-Z]+"
                            title="Please enter alphabets only."
                            minLength="3"
                            className={
                              errstate.errfirst_name
                                ? "is-invalid"
                                : "form-control"
                            }
                            id="first_name"
                            name="first_name"
                            placeholder="Enter first name"
                            value={frmstate.first_name || ""}
                            onChange={handleClientCreationChange}
                            onBlur={handleOnBlur}
                            required
                          />
                          <div
                            className={
                              errstate.errfirst_name
                                ? "invalid-feedback"
                                : "hide-error"
                            }>
                            {errstate.errfirst_name}
                          </div>
                        </div>

                        <div className="mb-3 col-sm-4">
                          <label htmlFor="lastname" className="form-label">
                            Middle Name
                          </label>
                          <input
                            type="text"
                            /* pattern="^[a-zA-Z]+"
                            minLength="3" */
                            className={
                              errstate.errmiddle_name
                                ? "is-invalid"
                                : "form-control"
                            }
                            id="middle_name"
                            name="middle_name"
                            placeholder="Enter middlename"
                            value={frmstate.middle_name || ""}
                            onBlur={handleOnBlur}
                            onChange={handleClientCreationChange}
                            required
                          />
                          <div
                            className={
                              errstate.errmiddle_name
                                ? "invalid-feedback"
                                : "hide-error"
                            }>
                            {errstate.errmiddle_name}
                          </div>
                        </div>

                        <div className="mb-3 col-sm-4">
                          <label htmlFor="lastname" className="form-label">
                            last name
                          </label>
                          <input
                            type="text"
                            pattern="^[a-zA-Z]+"
                            minLength="3"
                            className={
                              errstate.errlast_name
                                ? "is-invalid"
                                : "form-control"
                            }
                            id="last_name"
                            name="last_name"
                            placeholder="Enter surname"
                            value={frmstate.last_name || ""}
                            onBlur={handleOnBlur}
                            onChange={handleClientCreationChange}
                            required
                          />
                          <div
                            className={
                              errstate.errlast_name
                                ? "invalid-feedback"
                                : "hide-error"
                            }>
                            {errstate.errlast_name}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="mb-3 col-sm-4">
                          <label htmlFor="useremail" className="form-label">
                            Email
                          </label>
                          <input
                            type="email"
                            className={
                              errstate.erremail ? "is-invalid" : "form-control"
                            }
                            id="email"
                            name="email"
                            placeholder="Enter email"
                            required
                            value={frmstate.email || ""}
                            onBlur={handleOnBlur}
                            onChange={handleClientCreationChange}
                          />
                          <div
                            className={
                              errstate.erremail
                                ? "invalid-feedback"
                                : "hide-error"
                            }>
                            {errstate.erremail}
                          </div>
                        </div>

                        <div className="mb-3 col-sm-4">
                          <label htmlFor="nationality" className="form-label">
                            Country of Origin
                          </label>
                          <select
                            name="country"
                            value={frmstate.country || ""} // Prop: The email input data
                            onChange={handleClientCreationChange} //
                            className="form-select m-b-3"
                            style={{ width: "100%", color: "#495057" }}>
                              <option value=''>select country</option>
                              {
                                countries.map((item) =>{
                                    return(
                                        <option key={item.Code} data-code={item.Code}>
                                            {item.Name}
                                        </option>
                                    )
                                })
                              }
                          </select>
                        </div>

                        <div className="mb-3 col-sm-4">
                          <label htmlFor="mobilenumber" className="form-label">
                            Mobile Number
                          </label>
                          <PhoneInput
                            placeholder="Enter phone number"
                            international
                            countryCallingCodeEditable={true}
                            defaultCountry={frmstate.nationality}
                            value={phone ?? "+"}
                            name="phone"
                            onChange={(e) => getPhone(e)} /* setPhone */
                          />
                          <div
                            className={
                              errstate.errphone
                                ? "invalid-feedback"
                                : "hide-error"
                            }>
                            {errstate.errphone}
                          </div>
                  
                        </div>
                      </div>

                      {/* <div className="row">
                        <div className="mb-3 col-sm-6">
                          <label htmlFor="toglepassword" className="form-label">
                            Password
                          </label>
                          <div className="input-groupR">
                            <input
                              type={passwordShown ? "text" : "password"}
                              minLength="8"
                              className={
                                errstate.errpassword
                                  ? "is-invalid"
                                  : "form-control"
                              }
                              id="password"
                              name="password"
                              placeholder="Enter password"
                              required
                              value={frmstate.password || ""}
                              onBlur={handleOnBlur}
                              onChange={handleClientCreationChange}
                            />
                            <button
                              className="btn btn-light shadow-none ms-0"
                              onClick={togglePassword}>
                              <i
                                className={
                                  !passwordShown
                                    ? "mdi mdi-eye-outline"
                                    : "mdi mdi-eye-off-outline"
                                }></i>
                            </button>
                          </div>
                          <div
                            className={
                              errstate.errpassword
                                ? "invalid-feedback"
                                : "hide-error"
                            }>
                            {errstate.errpassword}
                          </div>
                        </div>
                        <div className="mb-3 col-sm-6">
                          <label htmlFor="toglepassword" className="form-label">
                            Confirm Password
                          </label>
                          <div className="input-groupR">
                            <input
                              type={cpasswordShown ? "text" : "password"}
                              minLength="8"
                              className={
                                errstate.errpassword_confirmation
                                  ? "is-invalid"
                                  : "form-control"
                              }
                              id="password"
                              name="password_confirmation"
                              placeholder="Confirm password"
                              required
                              value={frmstate.password_confirmation || ""}
                              onBlur={handleOnBlur}
                              onChange={handleClientCreationChange}
                            />
                            <button
                              className="btn btn-light shadow-none ms-0"
                              onClick={toggleCPassword}>
                              <i
                                className={
                                  !cpasswordShown
                                    ? "mdi mdi-eye-outline"
                                    : "mdi mdi-eye-off-outline"
                                }></i>
                            </button>
                          </div>
                          <div
                            className={
                              errstate.errpassword_confirmation
                                ? "invalid-feedback"
                                : "hide-error"
                            }>
                            {errstate.errpassword_confirmation}
                          </div>
                        </div>
                      </div> */}

                      <div className="mb-3 reg-btn-wrapper">
                        
                        {createClient.isLoading ? (
                          <button
                            type="button"
                            className="btn waves-effect waves-light">
                            <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                            Saving your data...
                          </button>
                        ) : (
                          <button
                            className="btn btn-primary waves-effect waves-light reg-btn"
                            onClick={handleClientCreation}
                            type="button">
                             <i className="fa fa-angle-double-right"></i> Next
                          </button>

                          
                        )}
                      </div>
                    </form>

                    {createClient.isError && (
                      <div className="mt-5 text-center">
                        <ul className="errorlist">
                      {/* <p className="text-muted-error mb-0"></p> */}
                        
                        {
                          errorsl.map((errorItem, i) =>{
                              return(
                                  <li key={i} className="text-muted-error">
                                      {errorItem}
                                  </li>
                              )
                          })
                        }
                      </ul>
                    </div>
                    ) } 
                 </div>  

                 <div className={showPrescreen ? "showprescreenform" : "hideprescreenform"} style={{margin: "2px 1px"}}>
                      <form>
                          <div
                            className="row col-bb"
                            style={{
                              justifyContent: "center",
                              marginRight: "calc(-0.5 * 0px)",
                              marginLeft: "calc(-0.5 * 0px)",
                            }}>

                          <div className="form-group col-lg-12">
                             <h5
                                className="list-group-item-heading label-h5"
                                style={{ color: "#5d9732" }}>
                                Please confirm how we can help you today:
                              </h5>

                              <div className="radio radio-success radio-inline">
                              <input
                                    className="radio radio-success radio-inline"
                                    style={{ margin: "12px 10px 0" }}
                                    type="radio"
                                    name="how_we_can_help_you_question"
                                    id="Radios1"
                                    checked={
                                      prescreendetails.how_we_can_help_you_question === "University Placement in the UK"
                                    }
                                    onChange={handlePreScreenChange}
                                    value="University Placement in the UK"
                                    {...registerp('how_we_can_help_you_question', { required: { value: true, message: "This field is required" }, onChange: (e) => {handlePreScreenChange(e)} })}
                                  /> <label htmlFor="Radios1">University Placement</label>
                              </div> 
                              
                              <div className="radio radio-success radio-inline">
                              <input
                                    className="radio radio-success radio-inline"
                                    style={{ margin: "12px 10px 0" }}
                                    type="radio"
                                    name="how_we_can_help_you_question"
                                    id="Radios2"
                                    checked={
                                      prescreendetails.how_we_can_help_you_question === "Assistance with an Immigration matter"
                                    }
                                    onChange={handlePreScreenChange}
                                    value="Assistance with an Immigration matter"
                                    {...registerp('how_we_can_help_you_question', { required: { value: true, message: "This field is required" }, onChange: (e) => {handlePreScreenChange(e)} })}
                                  /> <label htmlFor="Radios2">Assistance with an Immigration matter</label>
                              </div> 
                              
                              <div className="radio radio-success radio-inline">
                              <input
                                    className="radio radio-success radio-inline"
                                    style={{ margin: "12px 10px 0" }}
                                    type="radio"
                                    name="how_we_can_help_you_question"
                                    id="Other"
                                    checked={
                                      prescreendetails.how_we_can_help_you_question === "Other"
                                    }
                                    onChange={handlePreScreenChange}
                                    value="Other"
                                    {...registerp('how_we_can_help_you_question', { required: { value: true, message: "This field is required" }, onChange: (e) => {handlePreScreenChange(e)} })}
                                  /> <label htmlFor="Other">Other</label>
                              </div>

                              {errorsp.how_we_can_help_you_question && (
                                  <span className="errorValidate">This field is required</span>
                                )}              
                          </div>
                          </div>

                            <div 
                                  className={
                                    prescreendetails.how_we_can_help_you_question === "Other"
                                      ? "row col-bb txtareahide collapsin"
                                      : "row txtareahide"
                                  }

                                  aria-expanded={
                                    prescreendetails.how_we_can_help_you_question === "Other"
                                  }

                              style={{
                                justifyContent: "center",
                                marginRight: "calc(-0.5 * 0px)",
                                marginLeft: "calc(-0.5 * 0px)",
                              }}>
                                <div
                                  className="form-group col-lg-12"
                                  >
                                  <h5 className="list-group-item-heading label-h5">
                                    Please specify
                                  </h5>
                                  <div className="form-outline">
                                    <textarea
                                      className="form-control txtareabb"
                                      onChange={handlePreScreenChange}
                                      id="textArea"
                                      name="how_we_can_help_you"
                                      value={prescreendetails.how_we_can_help_you || ""}
                                      maxLength="100"
                                      {...registerp('how_we_can_help_you', { required: { value: prescreendetails.how_we_can_help_you_question === "Other" && prescreendetails.how_we_can_help_you === "" ? true : false, message: "This field is required" }, onChange: (e) => {handlePreScreenChange(e)} })}
                                      rows="2"></textarea>
                                  </div>
                                  {errorsp.how_we_can_help_you && (
                                    <span className="errorValidate">This field is required</span>
                                  )}
                                </div>
                              </div>

                              <div className="row col-bb"
                              style={{
                                justifyContent: "center",
                                marginRight: "calc(-0.5 * 0px)",
                                marginLeft: "calc(-0.5 * 0px)",
                              }}>
                             <div className="form-group col-lg-12">
                             <h5
                                className="list-group-item-heading label-h5"
                                style={{ color: "#5d9732" }}>
                                Do you have residence in this country of residence ?
                              </h5>

                              <div className="radio radio-success radio-inline">
                                    <input
                                      type="radio"
                                      id="residencey"
                                      value="Yes"
                                      name="residency_question"
                                      checked={prescreendetails.residency_question === "Yes"}
                                      {...registerp('residency_question', { required: { value: true, message: "This field is required" }, onChange: (e) => {handlePreScreenChange(e)} })}
                                    />
                                    <label htmlFor="residencey">Yes </label>
                                  </div>
                                  <div className="radio radio-success radio-inline">
                                    <input
                                      type="radio"
                                      id="residencen"
                                      value="No"
                                      name="residency_question"
                                      checked={prescreendetails.residency_question === "No"}
                                      {...registerp('residency_question', { required: { value: true, message: "This field is required" }, onChange: (e) => {handlePreScreenChange(e)} })}
                                    />
                                    <label htmlFor="residencen">No</label>
                                  </div>
                                {errorsp.residency_question && (
                                  <p className="errorValidate">This field is required</p>
                                  )}
                            </div>
                          </div>

                         
                          <div
                              className={
                                prescreendetails.residency_question === "No"
                                  ? "row col-bb txtareahide collapsin"
                                  : "row txtareahide"
                              }
                                aria-expanded={prescreendetails.residency_question === "No"}
                                style={{
                                  justifyContent: "center",
                                  marginRight: "calc(-0.5 * 0px)",
                                  marginLeft: "calc(-0.5 * 0px)"
                                }}
                                >
                                <div className="form-group col-lg-8">
                                 {/*  <label className="label-clr">
                                    What type of permission do you have to be in
                                    the country ?
                                  </label> */}
                                  <h5
                                className="list-group-item-heading label-h5"
                                style={{ color: "#5d9732" }}>
                                What type of permission do you have to be in the country ?
                              </h5>
                                  <input
                                    type="text"
                                   {...registerp('type_of_permission', { required: { value: prescreendetails.residency_question === "No" && prescreendetails.type_of_permission === "" ? true : false, message: "This field is required" }, onChange: (e) => {handlePreScreenChange(e)} })}
                                    value={prescreendetails.type_of_permission}
                                    className="form-control"
                                    name="type_of_permission"
                                  />
                                  {errorsp.type_of_permission && (
                                  <span className="errorValidate">This field is required</span>
                                  )}
                                </div>
                                <div className="form-group col-lg-4">
                                {/*   <label className="label-clr">
                                    When does it expire
                                  </label> */}
                                  <h5
                                className="list-group-item-heading label-h5"
                                style={{ color: "#5d9732" }}>
                                   When does it expire
                              </h5>
                                  <input
                                    {...registerp('expiry', { required: { value: prescreendetails.residency_question === "No" && prescreendetails.expiry === "" ? true : false, message: "This field is required" }, onChange: (e) => {handlePreScreenChange(e)} })}
                                    value={prescreendetails.expiry || ""}
                                    name="expiry"
                                    className="form-control"
                                    type="date"
                                  />
                                  {errorsp.expiry && (
                                  <span className="errorValidate">This field is required</span>
                                  )}
                                </div>                             
                          </div>

                          <div className="row col-bb"
                              style={{
                                justifyContent: "center",
                                marginRight: "calc(-0.5 * 0px)",
                                marginLeft: "calc(-0.5 * 0px)",
                              }}>
                                <div
                                  className={
                                    prescreendetails.english_proficiency === "Yes"
                                      ? "form-group col-lg-4 "
                                      : "form-group col-lg-6"
                                  }>
                                 
                                  <h5
                                className="list-group-item-heading label-h5"
                                style={{ color: "#5d9732" }}>
                                Date of Birth
                              </h5>
                                  <input
                                    onChange={handlePreScreenChange}
                                    value={prescreendetails.dob || ""}
                                    name="dob"
                                    className="form-control"
                                    max={getTodaysDate()}
                                    type="date"
                                    {...registerp('dob', { required: { value: true, message: "Date of birth is required" }, onChange: (e) => {handlePreScreenChange(e)} })}
                                  />
                                  {errorsp.dob && (
                                  <p className="errorValidate">{errorsp.dob.message}</p>
                                  )}
                                </div>
                                <div
                                  className={
                                    prescreendetails.english_proficiency === "Yes"
                                      ? "form-group col-lg-4 "
                                      : "form-group col-lg-6"
                                  }>
                                    
                                <h5
                                  className="list-group-item-heading label-h5"
                                  style={{ color: "#5d9732" }}>
                                  {/* Do you speak english fluently ? */}Do you have proof of your English language proficiency ?
                                </h5>
                                  <div className="radio radio-success radio-inline">
                                    <input
                                      type="radio"
                                      id="english_proficiency"
                                      value="Yes"
                                      name="english_proficiency"
                                      checked={prescreendetails.english_proficiency === "Yes"}
                                      {...registerp('english_proficiency', { required: { value: true, message: "This field is required" }, onChange: (e) => {handlePreScreenChange(e)} })}
                                    />
                                    <label htmlFor="english_proficiencyy">Yes </label>
                                  </div>
                                  <div className="radio radio-success radio-inline">
                                    <input
                                      type="radio"
                                      id="english_proficiencyn"
                                      value="No"
                                      name="english_proficiency"
                                      checked={prescreendetails.english_proficiency === "No"}
                                      {...registerp('english_proficiency', { required: { value: true, message: "This field is required" }, onChange: (e) => {handlePreScreenChange(e)} })}
                                    />
                                    <label htmlFor="english_proficiencyn">No</label>
                                  </div>
                                  {errorsp.english_proficiency && (
                                  <p className="errorValidate">This field is required</p>
                                  )}
                                </div>
                                <div
                                  className={
                                    prescreendetails.english_proficiency === "Yes"
                                      ? "form-group col-lg-4 txtareahide collapsin"
                                      : "form-group col-lg-4 txtareahide"
                                  }>
                                 
                                <h5
                                  className="list-group-item-heading label-h5"
                                  style={{ color: "#5d9732" }}>
                                  What level of Proficiency ?
                                </h5>
                                  <select
                                    name="english_proficiency_level"
                                    {...registerp('english_proficiency_level', { required: { value: prescreendetails.english_proficiency === "Yes" ? true : false, message: "This field is required" }, onChange: (e) => {handlePreScreenChange(e)} })}
                                    value={prescreendetails.english_proficiency_level || ""} // Prop: The email input data
                                    onChange={handlePreScreenChange} //
                                    className="form-select m-b-3"
                                    style={{ width: "100%" }}>
                                    <option value=''>select proficiency level</option>
                                    <option value="Beginner">Beginner</option>
                                    <option value="Moderate">Moderate</option>
                                    <option value="Fluent">Fluent</option>
                                    <option value="Native">Native</option>
                                  </select>
                                  {errorsp.english_proficiency_level && (
                                  <p className="errorValidate">This field is required</p>
                                  )}
                                </div> 
                          </div>

                          <div className="row col-bb"
                              style={{
                                justifyContent: "center",
                                marginRight: "calc(-0.5 * 0px)",
                                marginLeft: "calc(-0.5 * 0px)",
                              }}>

                               <div className="col-xs-5 form-group">
                                  <h5
                                  className="list-group-item-heading label-h5"
                                  style={{ color: "#5d9732" }}>
                                 What other languages do you speak ?
                                </h5>
                                  <input
                                    type="text"
                                    value={prescreendetails.other_languages || ""}
                                    {...registerp('other_languages', { required: { value: true, message: "This field is required" }, onChange: (e) => {handlePreScreenChange(e)} })}
                                    className="form-control"
                                    name="other_languages"
                                  />
                                  {errorsp.other_languages && (
                                  <p className="errorValidate">This field is required</p>
                                  )}
                               </div>

                               <div className="col-xs-7 form-group">
                                  <h5 className="list-group-item-heading label-h5">
                                    Have you ever been refused a visa/entry to
                                    any country of residence in the world ?
                                  </h5>

                                  <div
                                    className="radio radio-success radio-inline"
                                    style={{ marginTop: "0" }}>
                                    <input
                                      type="radio"
                                      id="visa_refusaly"
                                      value="Yes"
                                      name="visa_refusal"
                                      checked={prescreendetails.visa_refusal === "Yes"}
                                      {...registerp('visa_refusal', { required: { value: true, message: "This field is required" }, onChange: (e) => {handlePreScreenChange(e)} })}
                                    />
                                    <label htmlFor="visa_refusaly">Yes </label>
                                  </div>
                                  <div
                                    className="radio radio-success radio-inline"
                                    style={{ marginTop: "0" }}>
                                    <input
                                      type="radio"
                                      id="visa_refusaln"
                                      value="No"
                                      name="visa_refusal"
                                      checked={prescreendetails.visa_refusal === "No"}
                                      {...registerp('visa_refusal', { required: { value: true, message: "This field is required" }, onChange: (e) => {handlePreScreenChange(e)} })}
                                    />
                                    <label htmlFor="visa_refusaln">No</label>
                                  </div>
                                  {errorsp.visa_refusal && (
                                  <p className="errorValidate">This field is required</p>
                                  )}
                                </div>

                          </div>

                          <div
                              className={
                                prescreendetails.visa_refusal === "Yes"
                                  ? "row col-bb txtareahide collapsin"
                                  : "row txtareahide"
                              }
                               >
                                <div className="col-xs-12 form-group">
                                <h5 className="list-group-item-heading label-h5">
                                    Please provide type of visa refused/date/reason given
                                  </h5>
                                  <div className="form-outline">
                                    <textarea
                                      className="form-control txtareabb"
                                      {...registerp('visa_refusal_reason', { required: { value: prescreendetails.visa_refusal === "Yes" ? true:false, message: "This field is required" }, onChange: (e) => {handlePreScreenChange(e)} })}
                                      id="textArea"
                                      name="visa_refusal_reason"
                                      maxLength="100"
                                      rows="2"
                                      value={
                                        prescreendetails.visa_refusal_reason || ""
                                      }></textarea>
                                      {errorsp.visa_refusal_reason && (
                                       <p className="errorValidate">This field is required</p>
                                      )}
                                  </div>
                                </div>
                              </div>

                              <div
                                className="row col-bb">
                                <div className="col-xs-12 form-group">
                                <h5 className="list-group-item-heading label-h5">
                                    Please provide in your own words how we can
                                    help you
                                  </h5>
                                  <div className="form-outline">
                                    <textarea
                                      className="form-control txtareabb"
                                      {...registerp('in_your_own_words', { required: { value: true, message: "This field is required" }, onChange: (e) => {handlePreScreenChange(e)} })}
                                      id="textArea"
                                      name="in_your_own_words"
                                      maxLength="200"
                                      value={prescreendetails.in_your_own_words || ""}
                                      rows="2"></textarea>
                                      {errorsp.in_your_own_words && (
                                  <p className="errorValidate">This field is required</p>
                                  )}
                                  </div>
                                </div>
                              </div>

                       </form>
                     
                       <div className="mt-4 text-center">
                            {!preScreenMutation.isLoading ? (
                              <button
                                className="btnR btn-primary waves-effect waves-light"
                                style={{ width: "25%" }}
                                onClick={handlePrescreenSubmit}
                                type="button">
                                <i className="fa fa-snowflake-o"></i> Complete Onboarding
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="btnR waves-effect waves-light">
                                <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                Loading
                              </button>
                            )}
                          </div>
                 </div>
              </div>

          </div>

        </div>
        </div>

        </Modal.Body>
        <Modal.Footer>
         
           <div className="text-right">
              <button
                type="button"
                className="btn btn-default"
                onClick={handleCreateClientClose}>
                <i className="fa fa-trash"></i>  Close
              </button>
          </div>
        </Modal.Footer>
      </Modal>


       {/*  <Modal show={locopen} onHide={handleLocClose} animation={false}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        >
           <div className="color-line"></div>
        <Modal.Header className="modal-header text-center" style={{padding: "10px 10px"}}>
          <Modal.Title>Re-assign Office Location</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{padding: "5px"}}>
         
        <div className="panel-body no-padding">
        <div className="row">
                 <div className="form-group col-lg-6">
                        <label className="label-clr">Current Assigned Office</label>
                        <input
                          type="text"
                          readOnly
                          value={currentoffice || "Not Set"}
                          className="form-control"
                          style={{textAlign:"center"}}
                        />
                  </div>
                  <div className="form-group col-lg-6">
                        <label className="label-clr">Office Location</label>
                        <select
                          name="office_location"
                          {...registerloc('office_location', { required: true , onChange: (e) => {handleChange(e)} })}
                          value={cdetails.office_location || ""} // Prop: The email input data
                          className="form-select m-b-3"
                          style={{ width: "100%" }}>
                          <option value=''>select office location</option>
                          <option value="London">London</option>
                          <option value="South Yorks">South Yorks</option>
                          <option value="Islamabad">Islamabad</option>
                          <option value="Rawalpindi">Rawalpindi</option>
                          <option value="Sindh">Sindh</option>
                          <option value="Kiev">Kiev</option>
                        </select>
                        {errorsloc.office_location && (
                                  <p className="errorValidate">This field is required</p>
                                  )}
                      </div>
                  </div>
        </div>
        </Modal.Body>
        <Modal.Footer> 
         
           <div className="text-right">
           {locLoading ? (
             <>
                <span className="btn">
                  <i className="fa fa-paper-plane" style={{color: "#5ac557"}}></i>
                  &nbsp; Saving...
                  <span className="lds-facebook"><span></span><span></span><span></span></span>
                </span>
                &nbsp; &nbsp; &nbsp; &nbsp;
                </>
                    ) : (
                <button type="button"
                  className="btn btn-success btn-sm"
                  
                  data-clientid={clientID}
                  onClick={handleLoc}
                  >
                  <i className="fa fa-check-square-o"></i> Apply
                </button>
            )} 
              <button
                type="button"
                className="btn btn-default"
                onClick={handleLocClose}>
                <i className="fa fa-trash"></i>  Close
              </button>
          </div>
        </Modal.Footer>
      </Modal>*/}


        <Modal show={viewclientopen} onHide={handleViewClientClose} animation={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        >
           <div className="color-line"></div>
        <Modal.Header className="modal-header text-center" style={{padding: "10px 10px"}}>
          <Modal.Title>View Client</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{padding: "5px"}}>
         
        <div className="panel-body no-padding">
               <div className="row">

               <div className="col-lg-12">

               { getClientDetails.isError &&
                 (
                  <>
                  <div className="hpanel hblue">
                    <div className="panel-body">
                        <div className="row">
                            <div className="col-sm-12">
                                <p className="text-center">Encountered an Issue Loading client details</p>
                            </div>
                        </div>
                      </div>
                  </div>
                  </>
                 )}

                 { getClientDetails.isLoading ? 
                 (
                  <>
                  <div className="hpanel hblue">
                    <div className="panel-body">
                        <div className="row">
                            <div className="col-sm-12">
                                <p className="text-center">Loading...</p>
                            </div>
                        </div>
                      </div>
                  </div>
                  </>
                 ): clientDetails && clientDetails.length > 0 && (
                 <>  
                <div className="hpanel hblue">
                    <div className="panel-body">
                       {/*  <span className="label label-info pull-right">NEW</span> */}
                        <div className="row">
                            <div className="col-sm-12">
                                <h4><a href="#"> {`${clientDetails[0]?.first_name} ${clientDetails[0]?.middle_name} ${clientDetails[0]?.last_name} `} </a></h4>
                              
                                <div className="row">
                                    <div className="col-sm-3 text-center">
                                        <div className="project-label">EMAIL</div>
                                        <small style={{fontWeight: "bold"}}>{clientDetails[0]?.email}</small>
                                    </div>
                                    <div className="col-sm-3 text-center">
                                        <div className="project-label">PHONE</div>
                                        <small style={{fontWeight: "bold"}}>{formatPhoneNumberIntl(clientDetails[0]?.phone)}</small>
                                    </div>
                                    <div className="col-sm-3 text-center">
                                        <div className="project-label">DATE OF BIRTH</div>
                                        <small style={{fontWeight: "bold"}}>{clientDetails[1]?.dob || "Not Set"}</small>
                                    </div>
                                    <div className="col-sm-3 text-center">
                                        <div className="project-label">ONBOARDING</div>

                                      {clientDetails[1]?.prescreened_status === 3 && (
                                         <>
                                        <div className="progress m-t-xs full progress-small">
                                            <div style={{width: "100%"}} aria-valuemax="100" aria-valuemin="0" aria-valuenow="100" role="progressbar" className=" progress-bar progress-bar-success">
                                            </div>
                                        </div>
                                         <span>Completed</span>
                                         </>
                                      )}

                                      {clientDetails[1]?.prescreened_status === 2 && (
                                        <>
                                        <div className="progress m-t-xs full progress-small">
                                            <div style={{width: "75%"}} aria-valuemax="100" aria-valuemin="0" aria-valuenow="75" role="progressbar" className=" progress-bar progress-bar-info">
                                            </div>
                                        </div>
                                        <span>Under Review</span>
                                        </>
                                      )}

                                      {clientDetails[1]?.prescreened_status === 1 && (
                                         <>
                                        <div className="progress m-t-xs full progress-small">
                                            <div style={{width: "45%"}} aria-valuemax="100" aria-valuemin="0" aria-valuenow="45" role="progressbar" className=" progress-bar progress-bar-info">
                                            </div>
                                        </div>
                                        <span>Draft</span>
                                        </>
                                      )}

                                      {clientDetails[1]?.prescreened_status === 0 && (
                                        <>
                                        <div className="progress m-t-xs full progress-small">
                                            <div style={{width: "35%"}} aria-valuemax="100" aria-valuemin="0" aria-valuenow="45" role="progressbar" className=" progress-bar progress-bar-info">
                                            </div>
                                        </div>
                                         <span>Pending</span>
                                         </>
                                      )}
                                       
                                    </div>
                                </div>

                                <hr className="slash-3"/>

                                <div className="row">
                                    <div className="col-sm-3 text-center">
                                        <div className="project-label">COUNTRY OF RESIDENCE</div>
                                        <small style={{fontWeight: "bold"}}>{clientDetails[0]?.country || "Not Set"}</small>
                                    </div>
                                    <div className="col-sm-3 text-center">
                                        <div className="project-label">ASSIGNED OFFICE</div>
                                        <small style={{fontWeight: "bold"}}>{cBranch ?? "Not Set"}</small>
                                    </div>
                                    <div className="col-sm-3 text-center">
                                        <div className="project-label">ENGLISH PROFICIENCY</div>
                                        <small style={{fontWeight: "bold"}}>{clientDetails[1]?.english_proficiency || "Not Set"}</small>
                                    </div>
                                    <div className="col-sm-3 text-center">
                                        <div className="project-label">OTHER LANGUAGE</div>
                                        <small style={{fontWeight: "bold"}}>{clientDetails[1]?.other_languages || "Not Set"}</small>
                                    </div>
                                    
                                </div>


                                <hr className="slash-3"/>

                                <div className="row">
                                    <div className="col-sm-3 text-center">
                                        <div className="project-label">ASSIGNED GROUP</div>
                                        <small style={{fontWeight: "bold"}}>{cGroup ?? "Not Set"}</small>
                                    </div>
                                    <div className="col-sm-3 text-center">
                                        <div className="project-label">ASSIGNED AGENT</div>
                                        <small style={{fontWeight: "bold"}}>{agentName ?? "Not Set"}</small>
                                    </div>
                                    <div className="col-sm-3 text-center">
                                        <div className="project-label">VERIFIED EMAIL</div>
                                        <small style={{fontWeight: "bold"}}>{clientDetails[0]?.email_verified_at ? " Yes" : " No"}</small>
                                    </div>
                                    <div className="col-sm-3 text-center">
                                        <div className="project-label">VERIFIED SMS</div>
                                        <small style={{fontWeight: "bold"}}>{clientDetails[0]?.sms_verified ? " Yes" : " No"}</small>
                                    </div>
                                    
                                </div>

                                <hr className="slash-3"/>

                                <div className="row">
                                    <div className="col-sm-3 text-center">
                                        <div className="project-label">TOTAL APPLICATIONS</div>
                                        <small style={{fontWeight: "bold"}}>{clientDetails[2]?.total_count ?? "Not Set"}</small>
                                    </div>
                                    <div className="col-sm-3 text-center">
                                        <div className="project-label">APPLICATION IN DRAFT</div>
                                        <small style={{fontWeight: "bold"}}>{clientDetails[2]?.draft_count ?? "Not Set"}</small>
                                    </div>
                                    <div className="col-sm-3 text-center">
                                        <div className="project-label">APPLICATION IN REVIEW</div>
                                        <small style={{fontWeight: "bold"}}>{clientDetails[2]?.review_count ?? "Not Set"}</small>
                                    </div>
                                    <div className="col-sm-3 text-center">
                                        <div className="project-label">APPLICATION APPROVED</div>
                                        <small style={{fontWeight: "bold"}}>{clientDetails[2]?.approved_count ?? "Not Set"}</small>
                                    </div>
                                    
                                </div>
                            </div>
                          
                        </div>
                    </div>
                    <div className="panel-footer">
                        last updated on {createdDate(clientDetails[0]?.updated_at)}
                    </div>
                </div>
                </>
                )}
            </div>
                
               </div>
        </div>

        </Modal.Body>
        <Modal.Footer>
         
           <div className="text-right">
              <button
                type="button"
                className="btn btn-default"
                onClick={handleViewClientClose}>
                <i className="fa fa-trash"></i>  Close
              </button>
          </div>
        </Modal.Footer>
      </Modal>


        <Modal show={locopen} onHide={handleLocClose} animation={false}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        >
           <div className="color-line"></div>
        <Modal.Header className="modal-header text-center" style={{padding: "10px 10px"}}>
          <Modal.Title>Re-assign Office Location</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{padding: "5px"}}>
         
        <div className="panel-body no-padding">
        <div className="row">
                 <div className="form-group col-lg-6">
                        <label className="label-clr">Current Assigned Office</label>
                        <input
                          type="text"
                          readOnly
                          value={currentoffice || "Not Set"}
                          className="form-control"
                          style={{textAlign:"center"}}
                        />
                  </div>
                  <div className="form-group col-lg-6">
                        <label className="label-clr">Office Location</label>
                        <select
                          name="office_location"
                          {...registerloc('office_location', { required: true, onChange: (e) => {handleChange(e)} })}
                          value={cdetails.office_location || ""} // Prop: The email input data
                          className="form-select m-b-3"
                          style={{ width: "100%" }}>
                          <option value=''>select office location</option>
                          {officeLocations?.map(loc => 
                          (
                            <option key={loc.id} value={loc.id}>{loc.LOCATION_CODE}</option>
                          ))}
                          
                        </select>
                        {errorsloc.office_location && (
                                  <p className="errorValidate">Office location is required</p>
                                  )}
                      </div>
                  </div>
        </div>
        </Modal.Body>
        <Modal.Footer>
         
           <div className="text-right">
           {locLoading ? (
             <>
                <span className="btn">
                  <i className="fa fa-paper-plane" style={{color: "#5ac557"}}></i>
                  &nbsp; Saving...
                  <span className="lds-facebook"><span></span><span></span><span></span></span>
                </span>
                &nbsp; &nbsp; &nbsp; &nbsp;
                </>
                    ) : (
                <button type="button"
                  className="btn btn-success btn-sm"
                  
                  data-clientid={clientID}
                  onClick={handleLoc}
                  >
                  <i className="fa fa-check-square-o"></i> Apply
                </button>
            )}
              <button
                type="button"
                className="btn btn-default"
                onClick={handleLocClose}>
                <i className="fa fa-trash"></i>  Close
              </button>
          </div>
        </Modal.Footer>
      </Modal>




      <Modal show={groupopen} onHide={handleGroupClose} animation={false}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        >
           <div className="color-line"></div>
        <Modal.Header className="modal-header text-center" style={{padding: "10px 10px"}}>
          <Modal.Title>Add Client to Group</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{padding: "5px"}}>
         
        <div className="panel-body no-padding">
        <div className="row">
                 <div className="form-group col-lg-6">
                                  <label className="label-clr">Current Assigned Group</label>
                                  <input
                                    type="text"
                                    readOnly
                                    value={groupname || ""}
                                    className="form-control"
                                  />
                                </div>
                                <div className="form-group col-lg-6">
                                  <label className="label-clr">Available Groups</label>
                                  <select
                                    name="client_group"
                                    {...registerGrp('client_group', { required: true , onChange: (e) => {handleChange(e)} })}
                                    value={cdetails.client_group || ""} // Prop: The email input data
                                    className="form-select m-b-3"
                                    style={{ width: "100%" }}>
                                    <option value=''>select Group</option>
                                    { 
                                    allgroups.map((grp,i) => (
                                      <option key={i} value={grp.GROUP_ID}>{grp.GROUP_NAME}</option>
                                    ))
                                    }
                                    {/* <option value="Team Ghana">Team Ghana</option>
                                    <option value="IONOS Squard">IONOS Squard</option>
                                    <option value="Chelsea Uni Students">Team ABC</option>
                                    <option value="Chelsea Uni Students">Team London</option> */}
                                  </select>
                                  {errorsGrp.client_group && (
                                  <p className="errorValidate">Group is required</p>
                                  )}
                                </div>
                  </div>
        </div>

        </Modal.Body>
        <Modal.Footer>
         
           <div className="text-right">
           {setClientGroup.isLoading ? (
             <>
                <span className="btn">
                  <i className="fa fa-paper-plane" style={{color: "#5ac557"}}></i>
                  &nbsp; Saving...
                  <span className="lds-facebook"><span></span><span></span><span></span></span>
                </span>
                &nbsp; &nbsp; &nbsp; &nbsp;
                </>
                    ) : (
                <button type="button"
                  className="btn btn-success btn-sm"
                  
                  data-clientid={clientID}
                  onClick={handleGroup}
                  >
                  <i className="fa fa-check-square-o"></i> Apply
                </button>
            )}
              <button
                type="button"
                className="btn btn-default"
                onClick={handleGroupClose}>
                <i className="fa fa-trash"></i>  Close
              </button>
          </div>
        </Modal.Footer>
      </Modal>


      <Modal show={assignuseropen} onHide={handleAssignUserClose} animation={false}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        >
           <div className="color-line"></div>
        <Modal.Header className="modal-header text-center" style={{padding: "10px 10px"}}>
          <Modal.Title>Add Client to an Agent</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{padding: "5px"}}>
         
        <div className="panel-body no-padding">
        <div className="row">
                 <div className="form-group col-lg-6">
                                  <label className="label-clr">Assigned User/Admin/Agent:</label>
                                  <input
                                    type="text"
                                    readOnly
                                    value={agentName || "Samarah"}
                                    className="form-control"
                                  />
                                </div>
                                <div className="form-group col-lg-6">
                                  <label className="label-clr">Re-assign to :</label>
                                  <select
                                    name="admin_user"
                                    {...registerAgent('admin_user', { required: true , onChange: (e) => {handleChange(e)} })}
                                    value={cdetails.admin_user || ""} // Prop: The email input data
                                    className="form-select m-b-3"
                                   
                                    style={{ width: "100%" }}>
                                    <option value=''>select user</option>
                                    {
                                      users.map((user) => 
                                      (
                                        <option key={user.id} value={user.id}>{user.first_name} {user.last_name}</option>
                                      )
                                    )}
                                    {/* <option value="Admin 2">Admin 2</option>
                                    <option value="Admin 3">Admin 3</option>
                                    <option value="Admin 4">Admin 4</option>
                                    <option value="Admin 5">Admin 5</option>
                                    <option value="Admin 6">Admin 6</option> */}
                                  </select>
                                  {errorsAgent.admin_user && (
                                  <p className="errorValidate">User/Agent is required</p>
                                  )}
                                </div>
                  </div>
        </div>
        </Modal.Body>
        <Modal.Footer>
         
           <div className="text-right">
           {setClientAgent.isLoading ? (
             <>
                <span className="btn">
                  <i className="fa fa-paper-plane" style={{color: "#5ac557"}}></i>
                  &nbsp; Saving...
                  <span className="lds-facebook"><span></span><span></span><span></span></span>
                </span>
                &nbsp; &nbsp; &nbsp; &nbsp;
                </>
                    ) : (
                <button type="button"
                  className="btn btn-success btn-sm"
                  
                  data-clientid={clientID}
                  onClick={handleAssignUser}
                  >
                  <i className="fa fa-check-square-o"></i> Apply
                </button>
            )}
              <button
                type="button"
                className="btn btn-default"
                onClick={handleAssignUserClose}>
                <i className="fa fa-trash"></i>  Close
              </button>
          </div>
        </Modal.Footer>
      </Modal>


      <Modal show={creategroupopen} onHide={handleCreateGroupClose} animation={false}
        /* size="sm" */
        style={{width:"650px", margin:"auto"}}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        >
           <div className="color-line"></div>
        <Modal.Header className="modal-header text-center" style={{padding: "10px 10px"}}>
          <Modal.Title>Create Client Group</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{padding: "5px"}}>
         
        <div className="panel-body no-padding">
        <div className="row">
                 <div className="form-group col-lg-12">
                                  <label className="label-clr">Group Name</label>
                                  <input
                                    type="text"
                                    name="GROUP_NAME"
                                    {...registerCGrp('GROUP_NAME', { required: true , onChange: (e) => {handleGroupChange(e)} })}
                                    value={frmGroupstate.GROUP_NAME || ""}
                                    className="form-control"
                                  />
                                   {errorsCGrp.GROUP_NAME && (
                                  <p className="errorValidate">Group Name is required</p>
                                  )}
                                </div>
                               {/*  <div className="form-group col-lg-6">
                                  <label className="label-clr">Assign Clients to Groups</label>
                                  {!!clientslist && (Object.keys(clientslist).length > 0) ?
                                  (
                                    <MultiSelectClient options={optionsL}  handleChange={handleMultiClientChange} /> 
                                  ):(
                                    <input
                                    type="text"
                                    name="CLIENT_ID"
                                    disabled
                                    value=""
                                    className="form-control"
                                  />
                                  )}
                                </div> */}

                  </div>
        </div>

        </Modal.Body>
        <Modal.Footer>
         
           <div className="text-right">
           {createClient.isLoading ? (
             <>
                <span className="btn">
                  <i className="fa fa-paper-plane" style={{color: "#5ac557"}}></i>
                  &nbsp; Saving...
                  <span className="lds-facebook"><span></span><span></span><span></span></span>
                </span>
                &nbsp; &nbsp; &nbsp; &nbsp;
                </>
                    ) : (
                <button type="button"
                  className="btn btn-success btn-sm"
                  
                  data-clientid={clientID}
                  onClick={handleClientGroupCreation}
                  >
                  <i className="fa fa-check-square-o"></i> Apply
                </button>
            )}
              <button
                type="button"
                className="btn btn-default"
                onClick={handleCreateGroupClose}>
                <i className="fa fa-trash"></i>  Close
              </button>
          </div>
        </Modal.Footer>
      </Modal>

      
        </>
    )

}

export default Clients;