import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "react-query";
import { useForm } from "react-hook-form";
import { getAdminUser, removeUserSession } from "../Utils/Common";
import * as api from '../Utils/adminApiCalls';
import "./AdminDashboard.css";
import {
    useMatch,
    useResolvedPath, Link
  } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { formatPhoneNumberIntl } from "react-phone-number-input";

import AdminNav from "./Admin_nav";

const AdminDashboard = () => {

    
  
    const clientList = useQuery('getallclients', () => api.getAllClients());
   
    const { loading: loadingdata, data: clientdata } = clientList;

     

    return (
        <>
        
      
    <div className="content">
        <div className="row">
            <div className="col-lg-12 text-center welcome-message">
                <h2>
                    Welcome Ajaz Sulaman (Admin User)
                </h2>
            </div>
        </div>

        <div className="row">
            <div className="col-lg-3 animate-panel" data-child="appContent" data-effect="fadeInUp">
                <div className="hpanel" >
                    <div className="panel-body text-center h-200">
                        <i className="pe-7s-graph1 fa-4x"></i>

                        <h1 className="m-xs">$1 206,90</h1>

                        <h3 className="font-extra-bold no-margins text-success">
                            All Payments
                        </h3>
                        <small>Received payments as at 18/02/2022.</small>
                    </div>
                    <div className="panel-footer">
                        last updated a minute ago
                    </div>
                </div>
            </div>
            <div className="col-lg-3">
                <div className="hpanel stats">
                    <div className="panel-body h-200">
                        <div className="stats-title pull-left">
                            <h4>CLient Activity</h4>
                        </div>
                        <div className="stats-icon pull-right">
                            <i className="pe-7s-share fa-4x"></i>
                        </div>
                        <div className="m-t-xl">
                            <h3 className="m-b-xs">210</h3>
                    <span className="font-bold no-margins">
                        Active Clients
                    </span>

                            <div className="progress m-t-xs full progress-small">
                                <div style={{width: "55%", ariaValuemax:"100", ariaValuemin:"0", ariaValuenow:"55",
                                     role:"progressbar"}} className=" progress-bar progress-bar-success">
                                    <span className="sr-only">35% Complete (success)</span>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-xs-6">
                                    <small className="stats-label">New Clients</small>
                                    <h4>7.80</h4>
                                </div>

                                <div className="col-xs-6">
                                    <small className="stats-label">% New Clients</small>
                                    <h4>76.43%</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="panel-footer">
                    last updated 2 min minute ago
                    </div>
                </div>
            </div>
            <div className="col-lg-3">
                <div className="hpanel stats">
                    <div className="panel-body h-200">
                        <div className="stats-title pull-left">
                            <h4>Applications</h4>
                        </div>
                        <div className="stats-icon pull-right">
                            <i className="pe-7s-monitor fa-4x"></i>
                        </div>
                        <div className="m-t-xl">
                            <h1 className="text-success">510k+</h1>
                    <span className="font-bold no-margins">
                        Pre-Screened Clients
                    </span>
                            <br/>
                            <small>
                               Make up <strong>75%</strong> of total.
                            </small>
                        </div>
                    </div>
                    <div className="panel-footer">
                    last updated 3 min minute ago
                    </div>
                </div>
            </div>
            <div className="col-lg-3">
                <div className="hpanel stats">
                    <div className="panel-body h-200">
                        <div className="stats-title pull-left">
                            <h4>Today's payment</h4>
                        </div>
                        <div className="stats-icon pull-right">
                            <i className="pe-7s-cash fa-4x"></i>
                        </div>
                        <div className="clearfix"></div>
                        <div className="flot-chart">
                            <div className="flot-chart-content" id="flot-income-chart"></div>
                        </div>
                        <div className="m-t-xs">

                            <div className="row">
                                <div className="col-xs-5">
                                    <small className="stat-label">Today</small>
                                    <h4>$230,00 </h4>
                                </div>
                                <div className="col-xs-7">
                                    <small className="stat-label">Last week</small>
                                    <h4>$7 980,60 <i className="fa fa-level-up text-success"></i></h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="panel-footer">
                    last updated 2 min minute ago
                    </div>
                </div>
            </div>
        </div>

        <div className="row">
            {/* <div className="col-lg-3">
                <div className="hpanel stats">
                    <div className="panel-heading">
                        <div className="panel-tools">
                            <a className="showhide"><i className="fa fa-chevron-up"></i></a>
                            <a className="closebox"><i className="fa fa-times"></i></a>
                        </div>
                        Last active
                    </div>
                    <div className="panel-body list">
                        <div className="stats-title pull-left">
                            <h4>Activity</h4>
                        </div>
                        <div className="stats-icon pull-right">
                            <i className="pe-7s-science fa-4x"></i>
                        </div>
                        <div className="m-t-xl">
                            <span className="font-bold no-margins">
                                Social users
                            </span>
                            <br/>
                            <small>
                                Lorem Ipsum is simply dummy text of the printing simply all dummy text. Lorem Ipsum is
                                simply dummy text of the printing and typesetting industry. Lorem Ipsum has been.
                            </small>
                        </div>
                        <div className="row m-t-md">
                            <div className="col-lg-6">
                                <h3 className="no-margins font-extra-bold text-success">300,102</h3>

                                <div className="font-bold">98% <i className="fa fa-level-up text-success"></i></div>
                            </div>
                            <div className="col-lg-6">
                                <h3 className="no-margins font-extra-bold text-success">280,200</h3>

                                <div className="font-bold">98% <i className="fa fa-level-up text-success"></i></div>
                            </div>
                        </div>
                        <div className="row m-t-md">
                            <div className="col-lg-6">
                                <h3 className="no-margins font-extra-bold ">120,108</h3>

                                <div className="font-bold">38% <i className="fa fa-level-down"></i></div>
                            </div>
                            <div className="col-lg-6">
                                <h3 className="no-margins font-extra-bold ">450,600</h3>

                                <div className="font-bold">28% <i className="fa fa-level-down"></i></div>
                            </div>

                        </div>
                    </div>
                    <div className="panel-footer">
                        This is standard panel footer
                    </div>
                </div>
            </div> */}
            <div className="col-lg-9">
                <div className="hpanel">
                    <div className="panel-heading">
                        {/* <div className="panel-tools">
                            <a className="showhide"><i className="fa fa-chevron-up"></i></a>
                            <a className="closebox"><i className="fa fa-times"></i></a>
                        </div> */}
                        Recent Applications
                    </div>
                    <div className="panel-body list">
                        <div className="table-responsive project-list">
                            <table className="table table-striped">
                                <thead>
                                <tr>

                                    <th colSpan="2">Application ID</th>
                                    <th>Status</th>
                                    <th>Task</th>
                                    <th>Date</th>
                                    <th>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>APP-564567</td>
                                    <td>University placement in the UK - Kelvin Asare
                                        <br/>
                                        <small><i className="fa fa-clock-o"></i> Created 10.02.2022</small>
                                    </td>
                                    <td>
                                        <span className="pie"><span className="label label-warning pull-right">Pending</span></span>
                                    </td>
                                    <td><strong>Billing Stage</strong></td>
                                    <td>Feb 14, 2022</td>
                                    <td><a href={void(0)}><i className="fa fa-eye text-navy"></i> View</a></td>
                                </tr>
                                <tr>
                                    <td>APP-468567</td>
                                    <td>Immigration dispute - Mr Smith 
                                        <br/>
                                        <small><i className="fa fa-clock-o"></i> Created 21.01.2022</small>
                                    </td>
                                    <td>
                                        <span className="pie"><span className="label label-warning pull-right">Pending</span></span>
                                    </td>
                                    <td><strong>Application Review</strong></td>
                                    <td>Feb 02, 2022</td>
                                    <td><a href={void(0)}><i className="fa fa-eye text-navy"></i> View</a></td>
                                </tr>
                                <tr>
                                    <td>APP-184362</td>
                                    <td>UK Visa related matter - Doris Boyle
                                        <br/>
                                        <small><i className="fa fa-clock-o"></i> Created 12.01.2022</small>
                                    </td>
                                    <td>
                                        <span className="pie"><span className="label label-success pull-right">Completed</span></span>
                                    </td>
                                    <td><strong>Delivered</strong></td>
                                    <td>Feb 10, 2022</td>
                                    <td><a href={void(0)}><i className="fa fa-eye text-success"></i> View</a></td>
                                </tr>
                                <tr>
                                    <td>APP-265569</td>
                                    <td>Group Visa acquisition - NGO team
                                        <br/>
                                        <small><i className="fa fa-clock-o"></i> Created 08.02.2022</small>
                                    </td>
                                    <td>
                                        <span className="pie"><span className="label label-warning pull-right">Pending</span></span>
                                    </td>
                                    <td><strong>Pre-Screening Approval</strong></td>
                                    <td>Jan 22, 2022</td>
                                    <td><a href={void(0)}><i className="fa fa-eye text-navy"></i> View</a></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-3">
                <div className="hpanel">
                    <div className="panel-heading">
                        {/* <div className="panel-tools">
                            <a className="showhide"><i className="fa fa-chevron-up"></i></a>
                            <a className="closebox"><i className="fa fa-times"></i></a>
                        </div> */}
                        Activity
                    </div>
                    <div className="panel-body list">

                        <div className="pull-right">
                            <a href={void(0)} className="btn btn-xs btn-default">Today</a>
                            <a href={void(0)} className="btn btn-xs btn-default">Month</a>
                        </div>
                        <div className="panel-title">Latest Activity</div>
                        <small className="fo">update 1 min ago</small>
                        <div className="list-item-container">
                            <div className="list-item">
                                <h3 className="no-margins font-extra-bold text-success">73</h3>
                                <small>Total Pending App</small>
                                <div className="pull-right font-bold">98% <i className="fa fa-level-up text-success"></i></div>
                            </div>
                            <div className="list-item">
                                <h3 className="no-margins font-extra-bold text-color3">4</h3>
                                <small>No Pending Pre-Screens</small>
                                <div className="pull-right font-bold">13% <i className="fa fa-level-down text-color3"></i></div>
                            </div>
                            <div className="list-item">
                                <h3 className="no-margins font-extra-bold text-color3">950</h3>
                                <small>Messages Awaiting</small>
                                <div className="pull-right font-bold">22% <i className="fa fa-bolt text-color3"></i></div>
                            </div>
                            <div className="list-item">
                                <h3 className="no-margins font-extra-bold text-success">50</h3>
                                <small>Awaiting Bills</small>
                                <div className="pull-right font-bold">44% <i className="fa fa-level-up text-success"></i></div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

  {/*   <div id="right-sidebar" className="animated fadeInRight">

        <div className="p-m">
            <button id="sidebar-close" className="right-sidebar-toggle sidebar-button btn btn-default m-b-md"><i className="pe pe-7s-close"></i>
            </button>
            <div>
                <span className="font-bold no-margins"> Analytics </span>
                <br/>
                <small> Lorem Ipsum is simply dummy text of the printing simply all dummy text.</small>
            </div>
            <div className="row m-t-sm m-b-sm">
                <div className="col-lg-6">
                    <h3 className="no-margins font-extra-bold text-success">300,102</h3>

                    <div className="font-bold">98% <i className="fa fa-level-up text-success"></i></div>
                </div>
                <div className="col-lg-6">
                    <h3 className="no-margins font-extra-bold text-success">280,200</h3>

                    <div className="font-bold">98% <i className="fa fa-level-up text-success"></i></div>
                </div>
            </div>
            <div className="progress m-t-xs full progress-small">
                <div style={{width: "25%", ariaValuemax:"100", ariaValuemin:"0", ariaValuenow:"25",
                role:"progressbar"}}
                     className=" progress-bar progress-bar-success">
                    <span className="sr-only">35% Complete (success)</span>
                </div>
            </div>
        </div>
        <div className="p-m bg-light border-bottom border-top">
            <span className="font-bold no-margins"> Social talks </span>
            <br/>
            <small> Lorem Ipsum is simply dummy text of the printing simply all dummy text.</small>
            <div className="m-t-md">
                <div className="social-talk">
                    <div className="media social-profile clearfix">
                        <a className="pull-left">
                            <img src="images/a1.jpg" alt="profile-picture"/>
                        </a>

                        <div className="media-body">
                            <span className="font-bold">John Novak</span>
                            <small className="text-muted">21.03.2015</small>
                            <div className="social-content small">
                                Injected humour, or randomised words which don't look even slightly believable.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="social-talk">
                    <div className="media social-profile clearfix">
                        <a className="pull-left">
                            <img src="images/a3.jpg" alt="profile-picture"/>
                        </a>

                        <div className="media-body">
                            <span className="font-bold">Mark Smith</span>
                            <small className="text-muted">14.04.2015</small>
                            <div className="social-content">
                                Many desktop publishing packages and web page editors.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="social-talk">
                    <div className="media social-profile clearfix">
                        <a className="pull-left">
                            <img src="images/a4.jpg" alt="profile-picture"/>
                        </a>

                        <div className="media-body">
                            <span className="font-bold">Marica Morgan</span>
                            <small className="text-muted">21.03.2015</small>

                            <div className="social-content">
                                There are many variations of passages of Lorem Ipsum available, but the majority have
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="p-m">
            <span className="font-bold no-margins"> Sales in last week </span>
            <div className="m-t-xs">
                <div className="row">
                    <div className="col-xs-6">
                        <small>Today</small>
                        <h4 className="m-t-xs">$170,20 <i className="fa fa-level-up text-success"></i></h4>
                    </div>
                    <div className="col-xs-6">
                        <small>Last week</small>
                        <h4 className="m-t-xs">$580,90 <i className="fa fa-level-up text-success"></i></h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-6">
                        <small>Today</small>
                        <h4 className="m-t-xs">$620,20 <i className="fa fa-level-up text-success"></i></h4>
                    </div>
                    <div className="col-xs-6">
                        <small>Last week</small>
                        <h4 className="m-t-xs">$140,70 <i className="fa fa-level-up text-success"></i></h4>
                    </div>
                </div>
            </div>
            <small> Lorem Ipsum is simply dummy text of the printing simply all dummy text.
                Many desktop publishing packages and web page editors.
            </small>
        </div>

    </div> */}

   {/*  <footer className="footer" style={{ maxHeight: "40px" }}>
          <div className="mt-4 mt-md-5 text-center" style={{ marginTop: "0" }}>
            <p className="footer-p">
              © <script>document.write(new Date().getFullYear())</script>{" "}
              i-visas.com
            </p>
          </div>
        </footer> */}


        
        </>
    )
}

export default AdminDashboard;