//import * as api from './apiCall';
// Hook
import { useState, useEffect } from "react";

export const sleep = (ms) => {
	return new Promise((resolve) => setTimeout(resolve, ms));
  }

  // return the user data from the session storage
export const getAdminUser = () => {
	/* const userStr = sessionStorage.getItem('user');
	if (userStr) return JSON.parse(userStr);
	else return null; */
  
	const userStr = localStorage.getItem('adminuser');
	if (userStr){
	  //console.log("getAdminUser userStr",userStr);
		 return JSON.parse(userStr);
	}
	else return null;
  }

   // return the user data from the session storage
export const getUser = () => {
	/* const userStr = sessionStorage.getItem('user');
	if (userStr) return JSON.parse(userStr);
	else return null; */
  
	const userStr = localStorage.getItem('user');
	if (userStr){
	 // console.log("getUser userStr :::::::::::::::",userStr);
		 return JSON.parse(userStr);
	}
	else return null;
  }


// return the user data from the session storage
/* export const getUser_Api = async () => {

  const userStr = localStorage.getItem('user');

  // if the item doesn't exist, return null
  console.log("getToken itemStr",userStr);

  if (!userStr) {
	  return null
  }
  const item = JSON.parse(userStr);
  const now = new Date();
  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
	  // If the item is expired, delete the item from storage
	  // and return null
	  localStorage.removeItem('user');
	  localStorage.removeItem('token')
	  return null
  }

  if (userStr){
	console.log("getUser userStr",userStr);
     let savedInfo = item.id;
	 console.log("savedInfo",savedInfo);

	const getUserInfo = await api.getClientbyID(savedInfo);
    console.log("getUserInfo::", getUserInfo);

	const userInfo = {
		email: getUserInfo.email,
		expiry: getUserInfo.expiry,
		first_name: getUserInfo.first_name,
		id: getUserInfo.id,
		last_name: getUserInfo.last_name,
		message: "Login Successful",
		middle_name: getUserInfo.middle_name,
		phone: getUserInfo.phone,
		prescreened_status: getUserInfo.prescreened_status,
		session_id: 2
	}

	localStorage.setItem('user', JSON.stringify(userInfo));

	   return JSON.parse(userStr);
  }
  
} */

// return the token from the session storage
export const getAdminToken = () => {

	const itemStr = localStorage.getItem('adminuser')
	const token = localStorage.getItem('admintoken')
	// if the item doesn't exist, return null
	//console.log("getToken itemStr",itemStr);
	if (!itemStr) {
		return null
	}
	const item = JSON.parse(itemStr)
	const now = new Date()
	// compare the expiry time of the item with the current time
	if (now.getTime() > item.expiry) {
		// If the item is expired, delete the item from storage
		// and return null
		localStorage.removeItem('adminuser');
		localStorage.removeItem('admintoken')
		return null
	}
	//console.log("getToken token:",token);
	return token

  ///return sessionStorage.getItem('token') || null;
   
}

// return the token from the session storage
export const getToken = () => {

	const itemStr = localStorage.getItem('user')
	const token = localStorage.getItem('token')
	// if the item doesn't exist, return null
	//console.log("getToken itemStr",itemStr);
	if (!itemStr) {
		return null
	}
	const item = JSON.parse(itemStr)
	const now = new Date()
	// compare the expiry time of the item with the current time
	if (now.getTime() > item.expiry) {
		// If the item is expired, delete the item from storage
		// and return null
		localStorage.removeItem('user');
		localStorage.removeItem('token')
		return null
	}

	//console.log("getToken token:",token);
	return token

  ///return sessionStorage.getItem('token') || null;
   
}

export const UpdatePrescreenStatus = (num) => {
	let userItem = JSON.parse(localStorage.getItem('user'));
	//console.log("userItem ",userItem);

	if (!userItem) {
		return null
	}

	const now = new Date()
	
	if (now.getTime() > userItem.expiry) {
		
		localStorage.removeItem("user");
		localStorage.removeItem("token");
		return null
	}
		
		userItem.prescreened_status = parseInt(num);
	    localStorage.setItem('user', JSON.stringify(userItem));

		return "PreScreen_Status Updated!!";
}


// remove the token and user from the session storage
export const removeUserSession = () => {
  //sessionStorage.removeItem('token');
  //sessionStorage.removeItem('user');
  localStorage.removeItem('token');
  localStorage.removeItem('user');
  //localStorage.clear();
}

// remove the token and user from the session storage
export const removeUserAdminSession = () => {
	//sessionStorage.removeItem('token');
	//sessionStorage.removeItem('user');
	localStorage.removeItem('admintoken');
	localStorage.removeItem('adminuser');
	//localStorage.clear();
  }

// Admin :: set the token and user from the session storage
export const setAdminUserSession = (token, user, ttl) => {
	const now = new Date();
	const item = {
		...user,
		expiry: now.getTime() + ttl,
	}
	localStorage.setItem('admintoken', token);
	localStorage.setItem('adminuser', JSON.stringify(item));
	//console.log(localStorage.getItem("adminuser") + ":::" +localStorage.getItem("admintoken"))
  //sessionStorage.setItem('token', token);
  //sessionStorage.setItem('user', JSON.stringify(user));
}

// set the token and user from the session storage
export const setUserSession = (token, user, ttl) => {
	const now = new Date();
	const item = {
		...user,
		expiry: now.getTime() + ttl,
	}
	localStorage.setItem('token', token);
	localStorage.setItem('user', JSON.stringify(item));
	//console.log(localStorage.getItem("user") + ":::" +localStorage.getItem("token"))
  //sessionStorage.setItem('token', token);
  //sessionStorage.setItem('user', JSON.stringify(user));
}

//set key item in local storage with expiry date

export const setWithExpiry = (key, value, ttl) => {
	const now = new Date()

	// `item` is an object which contains the original value
	// as well as the time when it's supposed to expire
	const item = {
		...value,
		expiry: now.getTime() + ttl,
	}
	localStorage.setItem(key, JSON.stringify(item))
	//console.log("I was called : setWithExpiry");
}

// get key item in local storage
export const getWithExpiry = (key) => {
	///console.log("I was called : getWithExpiry")
	const itemStr = localStorage.getItem(key)
	// if the item doesn't exist, return null
	if (!itemStr) {
		return null
	}
	const item = JSON.parse(itemStr)
	const now = new Date()
	// compare the expiry time of the item with the current time
	if (now.getTime() > item.expiry) {
		// If the item is expired, delete the item from storage
		// and return null
		localStorage.removeItem(key)
		return null
	}
	return item.value
}



export const  useDebounce = (value, delay) => {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(
      () => {
        // Update debounced value after delay
        const handler = setTimeout(() => {
          setDebouncedValue(value);
        }, delay);
        // Cancel the timeout if value changes (also on delay change or unmount)
        // This is how we prevent debounced value from updating if value is changed ...
        // .. within the delay period. Timeout gets cleared and restarted.
        return () => {
          clearTimeout(handler);
        };
      },
      [value, delay] // Only re-call effect if value or delay changes
    );
    return debouncedValue;
  }