import React from "react";
import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import {ReactQueryDevtools} from 'react-query/devtools';
//import 'bootstrap/dist/css/bootstrap.min.css';
import { AuthProvider } from "./useAuth";
import App from "./App";

const queryClient = new QueryClient();
const rootElement = document.getElementById("root");
ReactDOM.render(
  <React.StrictMode>
  <QueryClientProvider client={queryClient} >
    <AuthProvider>
    <App />
    <ReactQueryDevtools />
    </AuthProvider>
    </QueryClientProvider>
  </React.StrictMode>,
  rootElement
);
