import React, { useState, useEffect, useRef } from "react";
import { useQuery, useMutation } from "react-query";
import { useForm } from "react-hook-form";
import { getAdminUser, removeUserAdminSession, useDebounce, sleep} from "../Utils/Common";
import * as api from '../Utils/adminApiCalls';
import "./AdminDashboard.css";
import {
    Link
  } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import AdminNav from "./Admin_nav";
import toast, { Toaster } from 'react-hot-toast';
import { Modal } from 'react-bootstrap';


const pageLimit = 5;
const dataLimit = 10;

const DefaultLocation = () => {

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm({mode: "onChange"});

  const {
    register:register2,
    trigger:trigger2,
    formState: { errors:errorsed },
  } = useForm({mode: "onChange", reValidateMode: 'onChange',shouldFocusError: true});

    const [userData, setUserData] = useState({});
    const [locid, setLocid] = useState("");
   // const [countries, setCountries] = useState([]);
    const[alllocation, setAlllocation]= useState([]);

    const [details, setDetails] = useState({
      COUNTRY:"",
      LOCATION_CODE: "",
      DEFAULT_USER: "",
    });

    useEffect(() => {
  
      function checkUserData() {
        const item = getAdminUser();
    
        if (item) {
          console.log("user item",item);
          setUserData(item);
  
          setUserData((userData) => {
            console.log("updated details", userData);
            return userData;
          });
  
        }
      }
      checkUserData();

      /* const data = require('../Utils/CountryData.json')
        console.log(data)
        setCountries(data)
   */
      window.addEventListener('storage', checkUserData)
    
      return () => {
        window.removeEventListener('storage', checkUserData)
      }
      
    }, []);


    const getLocations = useQuery("locations", api.getDefaultLocations, {
      onSuccess: (data) => {
        console.log("location data", data);
        setAlllocation(data);
        setAlllocation(alllocation => {
          return alllocation;
        })
      },
      onError: (error) => {
        
          console.log("Error Message",error); 
        
          if(error?.response?.status === 401){
            toast.error("Session Expired : Please Re-Login",{
              duration: 6000,
              });
           removeUserAdminSession();
            navigate("/admin_login", {replace: false});
          }
          else {
            toast.error("Something went wrong",{
              duration: 6000,
              });
          }
      }
    })


   /*  const handleChange = (event) => {
      event.persist();
  
      setDetails((details) => ({
        ...details,
        [event.target.name]: event.target.value,
      }));
      console.log(details);
    }; */

///////////////////////////////
const [locationDetails, setLocationDetails] = useState([]);

 const fetchLocation = useRef(false);
 const Locationref = useRef();

 const getLocationDetails = useQuery(["locationdetails", Locationref.current], () => api.getLocationID(Locationref.current), {
  enabled : fetchLocation.current,
  refetchOnWindowFocus:false,
  refetchOnMount:false,
  onSuccess: (data) => {
      console.log("location details: ",data);

      setLocationDetails(data);
      setLocationDetails((locationDetails) => {
        console.log("locationDetails ::: ",locationDetails);
       return locationDetails;
    });

  },
  onError: (error) => {
    
    console.log("Error Message",error); 
  
    if(error.response.status === 401){
      toast.error("Session Expired : Please Re-Login",{
        duration: 6000,
        });
     removeUserAdminSession();
      navigate("/admin_login", {replace: false});
    }
    else {
      toast.error("Something went wrong",{
        duration: 6000,
        });
    }
  
  } 
})



const handleEditChange = (event) => {
      event.persist();

      const locdetails = [...locationDetails];

      if(event.target.name === "BRANCH_ID"){
      const index = event.target.selectedIndex;
      const el = event.target.childNodes[index]
      const option =  el.getAttribute('id');  

      console.log("index:",index, "el:", el, "option:",option)

      locdetails[0].BRANCH_ID = option;
      }

      if(event.target.name === "USER_ID"){
        const index = event.target.selectedIndex;
        const el = event.target.childNodes[index]
        const option =  el.getAttribute('data-userid');  
  
        console.log("index:",index, "el:", el, "option:",option)
  
        locdetails[0].USER_ID = option;
        }

      console.log("locdetails ::", locdetails); 
      
      setLocationDetails(locdetails);
      setLocationDetails((locationDetails) => {
        console.log("locationDetails ::", locationDetails); 
        return locationDetails
      });

    };
  
    
    const createdDate = (datestr, l=0)=> {

      if(l === 0){
      const start = moment(datestr).format("dddd, MMMM Do YYYY, h:mm:ss a");
      
      return start;
    }
    else if(l === 1){
      const start = moment(datestr).format("llll");

      return start;
    }
     
    };

    ///////////Modal view location //////
const [viewlocopen, setViewLocOpen] = useState(false);

const handleViewLocShow = (e) => {
  setLocid(e.target.getAttribute("data-locid"));
  setViewLocOpen(true);
  Locationref.current = e.target.getAttribute("data-locid");
  
  const locationid = e.target.getAttribute("data-locid");

   if(locationid) {
    fetchLocation.current = true;
    getLocationDetails.refetch();
   }
   else return null;
};

const handleViewLocClose= (e) => {
  setViewLocOpen(false);
  fetchLocation.current = false;
};

////////////////

 ///////////Modal Edit location //////
 const [editlocopen, setEditLocOpen] = useState(false);
 
 const handleEditLocShow = (e) => {
   setLocid(e.target.getAttribute("data-locid"));
   setEditLocOpen(true);
   
   const locationid = e.target.getAttribute("data-locid");
   Locationref.current = e.target.getAttribute("data-locid");

   if(locationid) {
      fetchLocation.current = true;
      getLocationDetails.refetch();
   }
   else return null;

 };
 
 const handleEditLocClose= (e) => {
   setEditLocOpen(false);
   fetchLocation.current = false;
 };
 
const handleEditLoc = async (e) => {
    //console.log(e.target.getAttribute("data-locid"));

   // const locationID = e.target.getAttribute("data-locid");
    const locupdate = {...locationDetails};

    const validateMod = await trigger2();
 if(validateMod){    
         /// 
         console.log("locupdate", locupdate);
         updateLocationDetails.mutate(locupdate[0]);
  }
};

///updateLocation

const updateLocationDetails = useMutation("locationdetails", api.updateLocationByID, {

  onSuccess: (data) => {
      console.log("location details: ",data);

      getLocations.refetch();
      toast.success("Location updated Successfully",{
        duration:6000,
      })

      sleep(2000).then(() => { 

        setEditLocOpen(false);
        setLocationDetails({});
        setLocationDetails((locationDetails) => {
          return locationDetails;
        });

      });
  },
  onError: (error) => {
    
    console.log("Error Message",error); 
  
    if(error.response.status === 401){
      toast.error("Session Expired : Please Re-Login",{
        duration: 6000,
        });
     removeUserAdminSession();
      navigate("/admin_login", {replace: false});
    }
    else {
      toast.error("Something went wrong",{
        duration: 6000,
        });
    }
  
  } 
})
 ////////////////


  
const [branch, setBranch] = useState([]);
const getBranches = useQuery("branches", () => api.getOfficeLocations(), {
  enabled: fetchLocation.current,
  refetchOnWindowFocus:false,
  refetchOnMount:false,
  onSuccess: (data) => {
    console.log("users data:::", data);
    setBranch([...data]);
    setBranch(branch => {
      return branch;
    })
  },
  onError: (error) => {
    
      console.log("Error Message ::: ",error); 
    
      if(error?.response?.status === 401){
        toast.error("Session Expired : Please Re-Login",{
          duration: 6000,
          });
       removeUserAdminSession();
        navigate("/admin_login", {replace: false});
      }
      else {
        toast.error("Something went wrong",{
          duration: 6000,
          });
      }
  }
});
////////////////

////////////////////////////

const [users, setUsers] = useState([]);

const getUsers = useQuery("users", () => api.getAllUsers(), {
  enabled: fetchLocation.current,
  refetchOnWindowFocus:false,
  refetchOnMount:false,
  onSuccess: (data) => {
    console.log("users data:::", data);
    setUsers([...data]);
    setUsers(users => {
      return users;
    })
  },
  onError: (error) => {
    
      console.log("Error Message ::: ",error); 
    
      if(error?.response?.status === 401){
        toast.error("Session Expired : Please Re-Login",{
          duration: 6000,
          });
       removeUserAdminSession();
        navigate("/admin_login", {replace: false});
      }
      else {
        toast.error("Something went wrong",{
          duration: 6000,
          });
      }
  }
});

/////////////////////////////

/////////////Pagination ////////////

  //const [pages] = useState(Math.ceil(datamsg?.length / dataLimit) || 1);
  const pages = useRef(Math.ceil(alllocation?.length / dataLimit) || 1);

  //console.log("dataLimit::", dataLimit," datamsg length::", datamsg?.length, " pages::: ", Math.ceil(alllocation?.length / dataLimit));

  const [currentPage, setCurrentPage] = useState(1);

  const goToNextPage = () => {
      if(currentPage <= pages.current) {
      setCurrentPage((page) => page + 1);
      }
  }

  const goToPreviousPage = () => {
      if(currentPage > 1 ){
      setCurrentPage((page) => page - 1);
      }
  }

  const changePage = (event) => {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage(pageNumber);
  }

  const getPaginatedData = () => {
    const startIndex = currentPage * dataLimit - dataLimit;
    const endIndex = startIndex + dataLimit;
    //console.log("getPaginationData", alllocation?.slice(startIndex, endIndex))
    return alllocation?.slice(startIndex, endIndex);
  };

  const getPaginationGroup = () => { 
    let start = Math.floor((currentPage - 1) / pageLimit) * pageLimit;
    
    let page = Math.ceil(alllocation?.length / dataLimit) || 1;
    //console.log("local page ", Math.ceil(alllocation?.length / dataLimit));
    //console.log("Array Return", new Array(pages).fill().map((_, idx) => start + idx + 1), "  pages : ", pages);

    pages.current = page;
    
     return new Array(page).fill().map((_, idx) => start + idx + 1);
  }

  ////////////////////////////////////

  /////////////////Search function ///////////////
const [searchquery, setSearchquery] = useState("");

const searchApplication = (q) => {
 //console.log("query: ", q.target.value);

 const query = q.target.value;

 setSearchquery(query);

 setSearchquery(searchquery => {
   return searchquery;
 });
 
 if(query.length === 0){
  
  console.log("Refetching Application list 2", query, " query length next: ", query.length);
     
  getLocations.refetch();
 }
}

const debouncedFilter = useDebounce(searchquery, 500);

console.log("debouncedFilter", debouncedFilter); 

const { data:searchData, isLoading:searchLoading } = useQuery(
 ['applications', debouncedFilter], 
 () => api.searchLocation(debouncedFilter),
 { enabled: Boolean(debouncedFilter),
 onSuccess: (data) => {
     console.log("data", data);
     const apd = [...data];
     setAlllocation(apd);
     setAlllocation((alllocation) => {
       return alllocation;
     })
 },
 onError: (error) => {
    
  console.log("Error Message",error); 

  if(error.response.status === 401){
    toast.error("Session Expired : Please Re-Login",{
      duration: 6000,
      });
   removeUserAdminSession();
    navigate("/admin_login", {replace: false});
  }
  else {
    toast.error("Something went wrong",{
      duration: 6000,
      });
  }

} 
})

 /////////////////////////////////


    return (
        <>
        <Toaster />
      
<div className="normalheader" id="tpanel">
        <div className="hpanel">
          <div className="panel-body">
            <a className="small-header-action" id="toglepanel" href={void(0)}>
              <div className="clip-header">
                <i className="fa fa-arrow-up"></i>
              </div>
            </a>

            <div id="hbreadcrumb" className="pull-right m-t-lg">
              <ol className="hbreadcrumb breadcrumb">
                <li>
                  <a href={void(0)}>i-visas</a>
                </li>
                <li>
                  <span>Admin</span>
                </li>
                <li className="active">
                  <span>Default Locations </span>
                </li>
              </ol>
            </div>
            <h2 className="font-light m-b-xs">Management Default Locations</h2>
            <small>
              Assign default <strong>Branch/Location</strong> and <strong>Agent</strong> for each country
            </small>
          </div>
        </div>
      </div>

    <div className="content">
        <div className="row">
            <div className="col-lg-12 text-center welcome-message">
                
            <div className="hpanel email-compose">
              
               <>
            <div className="panel-heading hbuilt">
                    <div className="text-center p-xs font-normal">
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={searchApplication}
                          value={searchquery || ""}
                          className="form-control input-sm"
                          placeholder="Search by Country, Location Code and Default User..."
                        />{" "}
                        <span className="input-group-btn">
                          {" "}
                          <button
                            type="button"
                            disabled
                            className="btn btn-sm btn-default">
                            <i className="fa fa-search"></i> 
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="panel-body">

                  {searchLoading ? (
                       <>
                      <div className="row text-center">
                      <div className="col-md-7 m-b-md" style={{textAlign: "left", display: "flex", flexDirection: "row", justifyItems: "flex-start", alignItems: "center", justifyContent: "normal"}}>

                      <button className="btn btn-info btn-sm" >
                            <i className="fa fa-location-arrow"></i> Add Location
                          </button>
                      </div>
                      <div className="col-md-5 mailbox-pagination m-b-md">
                            <span style={{ fontSize: "1.1rem" }}>
                            Showing 0 of 0 pages   &nbsp; &nbsp;
                            </span>
                            <div className="btn-group">
                              <button className="btn btn-default btn-sm previous disabled"
                             
                              disabled
                              >
                                <i className="fa fa-arrow-left"></i>
                              </button>
                              {getPaginationGroup().map((item, index) => (
                              <button 
                              key={index}
                              className="btn btn-default btn-sm disabled"
                              >
                              {item}
                              </button>
                              ))}
                              <button className="btn btn-default btn-sm next disabled"
                              disabled
                              >
                                <i className="fa fa-arrow-right"></i>
                              </button>
                            </div>
                          </div>
                         
                      </div>
                        <div className="table-responsive" style={{overflowX: "inherit"}}>
                        <table className="table table-hover table-mailbox apptable">
                        <thead style={{borderBottom: "solid 2px #64d246"}}>
                        <tr>
                        <th className="text-center" style={{width:"210px"}}>Country</th>
                            <th className="text-center" >Location Name</th>
                            <th className="text-center" >Default User </th>
                            <th className="text-center" >Creation Date </th>
                            <th className="text-right">Action</th>
                           
                          </tr>
                        </thead>
                            <tbody>
                            <tr className="active"><td colSpan="7" style={{textAlign:"center"}}>Searching...</td></tr>
                        </tbody>
                      </table>
                        </div>
                        </>
                    ):(
                      <>
                    <div className="row">
                      <div className="col-md-7 m-b-md" style={{textAlign: "left", display: "flex", flexDirection: "row", justifyItems: "flex-start", alignItems: "center", justifyContent: "normal"}}>
                      </div>

                      <div className="col-md-5 mailbox-pagination m-b-md">
                        <span style={{ fontSize: "1.1rem" }}>
                        {!!alllocation && (Object.keys(alllocation).length > 0) ? `Showing ${currentPage} of ${pages.current} pages` : "Showing 0 of 0 pages"}   &nbsp; &nbsp;
                        </span>
                        <div className="btn-group">
                          <button className={`btn btn-default btn-sm previous ${currentPage === 1 ? 'disabled' : ''}`}
                          onClick={goToPreviousPage}
                          disabled={!!alllocation && (currentPage === 1) ? true : false} 
                          >
                            <i className="fa fa-arrow-left"></i>
                          </button>
                          {getPaginationGroup().map((item, index) => (
                          <button 
                          key={index}
                          onClick={changePage}
                          className={`btn btn-default btn-sm  ${currentPage === item ? 'active' : null}`}
                          >
                           {item}
                          </button>
                          ))}
                          <button className={`btn btn-default btn-sm next ${currentPage === pages.current ? 'disabled' : ''}`}
                          onClick={goToNextPage} 
                          disabled={!!alllocation && (currentPage === pages.current) ? true : false} 
                          >
                            <i className="fa fa-arrow-right"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="table-responsive" style={{overflowX: "inherit"}}>
                      <table className="table table-hover table-mailbox apptable">
                      <thead style={{borderBottom: "solid 2px #64d246"}}>
                          <tr>
                            <th className="text-center" style={{width:"210px"}}>Country</th>
                            <th className="text-center" >Location Name</th>
                            <th className="text-center" >Default User </th>
                            <th className="text-center" >Creation Date </th>
                            <th className="text-right">Action</th>
                           
                          </tr>
                        </thead>
                        <tbody>
                        { getLocations.isLoading ? (

                        <tr className="unread active"><td colSpan="7" style={{textAlign:"center"}}>
                          <span className="btn">
                          <i className="fa fa-paper-plane" style={{color: "#5ac557"}}></i>
                          &nbsp; Loading...
                          <span className="lds-facebook"><span></span><span></span><span></span></span>
                          </span>
                          &nbsp; &nbsp; &nbsp; &nbsp;
                          </td></tr>
                        ) : (
                        
                          !!alllocation && (Object.keys(alllocation).length > 0) ? 
                          getPaginatedData().map((item,i) =>{
                            return (
                              <tr className="unread" key={i}>
                              <td className="text-center" style={{width: "100px"}}>
                             {item.COUNTRY}
                            </td>
                            
                            <td className="text-center">
                             {item.LOCATION_CODE}
                            </td>
                            
                            <td className="text-center">
                             {item.first_name}
                            </td>
                            <td className="text-center mail-date">
                            {createdDate(item.created_at)}
                            </td>
                            <td className="text-left mail-date">
                            <div className="btn-group">
                                    <button data-toggle="dropdown" className="btn btn-default dropdown-toggle">Action <span className="caret"></span></button>
                                    <ul className="dropdown-menu">
                                        <li><Link to="# " data-locid={item.id} onClick={handleViewLocShow} >View Location</Link></li>
                                        <li><Link to="# " data-locid={item.id} onClick={handleEditLocShow} >Edit Location</Link></li> 
                                    </ul>
                          </div>
                            </td>
                          </tr>
                            )
                          }) : (
                           <tr className="unread active"><td colSpan="7" style={{textAlign:"center"}}>No Location found </td></tr>
                         ))}
                           
                        </tbody>
                      </table>
                    </div>

                    </>
                    )}
                 </div>
                 <div className="panel-footer text-left">
                  {!!alllocation && (Object.keys(alllocation).length > 0) ? alllocation.length : 0}  Locations
                 </div>
               </>
               
            </div>
        </div>

    </div>
    </div>


    {/* <footer className="footer" style={{ maxHeight: "40px" }}>
          <div className="mt-4 mt-md-5 text-center" style={{ marginTop: "0" }}>
            <p className="footer-p">
              © <script>document.write(new Date().getFullYear())</script>{" "}
              i-visas.com
            </p>
          </div>
        </footer> */}


        <Modal show={viewlocopen} onHide={handleViewLocClose} animation={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        >
           <div className="color-line"></div>
        <Modal.Header className="modal-header text-center" style={{padding: "10px 10px"}}>
          <Modal.Title>View Location</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{padding: "5px"}}>
         
        <div className="panel-body no-padding">
               <div className="row">

               <div className="col-lg-12">

               { getLocationDetails.isError &&
                 (
                  <>
                  <div className="hpanel horange">
                    <div className="panel-body">
                        <div className="row">
                            <div className="col-sm-12">
                                <p className="text-center">Encountered an Issue Loading client details</p>
                            </div>
                        </div>
                      </div>
                  </div>
                  </>
                 )}

                 { getLocationDetails.isLoading && 
                 (
                  <>
                  <div className="hpanel horange">
                    <div className="panel-body">
                        <div className="row">
                            <div className="col-sm-12">
                                <p className="text-center">Loading...</p>
                            </div>
                        </div>
                      </div>
                  </div>
                  </>
                 )} 

                 {locationDetails ? (
                 <>  
                <div className="hpanel horange">
                    <div className="panel-body">
                       {/*  <span className="label label-info pull-right">NEW</span> */}
                        <div className="row">
                            <div className="col-sm-12">
                                <h4><a href="#"> {locationDetails[0]?.COUNTRY || "Not Set"} </a></h4>
                              
                                <div className="row">                     
                                    <div className="col-sm-6 text-center">
                                        <div className="project-label">LOCATION CODE</div>
                                        <small style={{fontWeight: "bold"}}>{locationDetails[0]?.LOCATION_CODE || "Not Set"}</small>
                                    </div>
                                    <div className="col-sm-6 text-center">
                                    <div className="project-label">ASSIGNED OFFICER</div>
                                        <small style={{fontWeight: "bold"}}>{locationDetails[0]?.first_name || "Not Set"}</small>
                                    </div>
                                </div>

                            </div>
                          
                        </div>
                    </div>
                    <div className="panel-footer">
                        last updated on {createdDate(locationDetails[0]?.created_at)}
                    </div>
                </div>
                </>
                ) : ("Nothing to show")}
            </div>
                
               </div>
        </div>

        </Modal.Body>
        <Modal.Footer>
         
           <div className="text-right">
           
              <button
                type="button"
                className="btn btn-default"
                onClick={handleViewLocClose}>
                <i className="fa fa-trash"></i>  Close
              </button>
          </div>
        </Modal.Footer>
      </Modal>


      <Modal show={editlocopen} onHide={handleEditLocClose} animation={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        >
           <div className="color-line"></div>
        <Modal.Header className="modal-header text-center" style={{padding: "10px 10px"}}>
          <Modal.Title>Edit Location</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{padding: "5px"}}>
         
        
        <div className="panel-body no-padding">
        <div className="row">
                  <div className="col-lg-12">

                  { getLocationDetails.isError &&
                 (
                  <>
                  <div className="hpanel horange">
                    <div className="panel-body">
                        <div className="row">
                            <div className="col-sm-12">
                                <p className="text-center">Encountered an Issue Loading Location details</p>
                            </div>
                        </div>
                      </div>
                  </div>
                  </>
                 )}

                 { getLocationDetails.isLoading && 
                 (
                  <>
                  <div className="hpanel horange">
                    <div className="panel-body">
                        <div className="row">
                            <div className="col-sm-12">
                                <p className="text-center">Loading...</p>
                            </div>
                        </div>
                      </div>
                  </div>
                  </>
                 )} 
                 {locationDetails && (
                      <>
                        <div className="row col-bb">
                          
                           <div className="form-group col-lg-4">
                              <label className="label-clr">
                              Country
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                readOnly
                                name="COUNTRY"
                                {...register2("COUNTRY", { 
                                            required: { value: false, message: "This field is required" }, 
                                            onChange: (e) => {handleEditChange(e)} })}
                                  value={locationDetails[0]?.COUNTRY || ""} // Prop: The email input data
                              />
                              {errorsed.COUNTRY && (
                                <p className="errorValidate">{errorsed.COUNTRY.message}</p>
                                )}
                            </div>

                             <div className="form-group col-lg-4">
                              <label className="label-clr">
                              Location/Branch
                              </label>
                              <select
                                name="BRANCH_ID"
                               {...register2("BRANCH_ID", { 
                                          required: { value: locationDetails[0]?.BRANCH_ID ? false : true, message: "This field is required" }, 
                                          onChange: (e) => {handleEditChange(e)} })}
                                          value={locationDetails[0]?.BRANCH_ID || ""}
                               
                                className="form-select m-b-3"
                                style={{ width: "100%",}}>
                                <option value=''>select location</option>
                                      {
                                       branch.map((item) =>{
                                           return(
                                               <option key={item.id} id={item.id} value={item.id}>
                                                   {item.LOCATION_CODE}
                                               </option>
                                           )
                                       })
                                      }
                              </select>
                              {errorsed.BRANCH_ID && (
                                  <p className="errorValidate">{errorsed.BRANCH_ID.message}</p>
                                  )}
                            </div>

                            <div className="form-group col-lg-4">
                              <label className="label-clr">
                              Default User
                              </label>
                              <select
                                name="USER_ID"
                               {...register2("USER_ID", { 
                                          required: { value: locationDetails[0]?.USER_ID ? false : true, message: "This field is required" }, 
                                          onChange: (e) => {handleEditChange(e)} })}
                                          value={locationDetails[0]?.USER_ID || ""}
                               
                                className="form-select m-b-3"
                                style={{ width: "100%",}}>
                                <option value=''>select default user</option>
                                      {
                                       users.map((item) =>{
                                           return(
                                               <option key={item.id} data-userid={item.id} value={item.id}>
                                                   {item.first_name} {item.last_name}
                                               </option>
                                           )
                                       })
                                      }
                              </select>
                              {errorsed.USER_ID && (
                                  <p className="errorValidate">{errorsed.USER_ID.message}</p>
                                  )}
                            </div>
                        </div>

                        </>
                )} 
                  </div>
              </div>
        </div>


        </Modal.Body>
        <Modal.Footer>
         
           <div className="text-right">
           {getLocationDetails.isLoading ? (
             <>
                <span className="btn">
                  <i className="fa fa-paper-plane" style={{color: "#5ac557"}}></i>
                  &nbsp; Saving...
                  <span className="lds-facebook"><span></span><span></span><span></span></span>
                </span>
                &nbsp; &nbsp; &nbsp; &nbsp;
                </>
                    ) : (
                <button type="button"
                  className="btn btn-success btn-sm"
                  
                  data-locid={locid}
                  onClick={handleEditLoc}
                  >
                   <i className="fa fa-location-arrow"></i>  Apply Changes
                </button>
            )}
              <button
                type="button"
                className="btn btn-default"
                onClick={handleEditLocClose}>
                <i className="fa fa-trash"></i>  Close
              </button>
          </div>
        </Modal.Footer>
      </Modal>
        
        </>
    )

}

export default DefaultLocation;