import React, { useState, useEffect } from "react";
import "react-phone-number-input/style.css";
import {useMutation, useQuery} from 'react-query';
import * as api from './Utils/apiCall';

import PhoneInput, {
  parsePhoneNumber,
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input/max";
import "./Register.css";
import { useNavigate } from "react-router-dom";

const Register = () => {

  //const {data, isLoading, isError, error} = useQuery ('registerclient', api.register);
  //const queryClient = useQueryClient();

  const [errors, setErrors] = useState([]);

  const {isError, isSuccess, error, isLoading, mutate} = useMutation(api.register, {
    onSuccess: (data) => {
      console.log("onSuccess data",data, "id: ", data.id);
      navigate("/verifysms", {
        state: {
          email: frmstate.email,
          mnumber: phone,
          token: data?.id,
        }, 
        replace: true,
      });
    },
    onError: (error) => {
      //console.log("errors",error); 
      //console.log("error response data errors: ",error.response.data.errors?.phone[0]);
      const errlist = []
      const errObject = error.response.data?.errors
      //console.log("typeof: ",typeof error.response.data.errors);
      if(error.response.data.errors !== undefined && typeof error.response.data.errors === 'object')
       {
            for(var propName in errObject) {
              if(errObject.hasOwnProperty(propName)) {
                  //console.log("within iteration",errObject[propName]); 
                  errlist.push(errObject[propName]);
              }
            }
          setErrors(errlist);
       }
       else
       {
         errlist.push(error.message)
         setErrors(errlist);
       }

       console.log("errlist",errlist); 

      //console.log("error response status: ", error.response.status);
      
    }
  });


  ////////Company Information :////////////
  const [companyDetails, setCompanyDetails] = useState({});

  const getCompanyInfoNav = useQuery("get_companyinfo", api.companyInfo, {
    enabled: true,
    refetchOnMount:true,
    refetchOnWindowFocus:true,
    onSuccess: (data) => {
      console.log("users data:::", data);

      const apd = {...data?.Info[0]};
      apd.URL = data?.URL;
      console.log("@@@@@@@@@@@@@@@@@@", apd)
      setCompanyDetails(apd);
      setCompanyDetails(companyDetails => {
        console.log("companyDetails ===>>>",companyDetails);
        return companyDetails;
      })
    },
    onError: (error) => {
        console.log("Error Message ::: ",error); 
    }
  });

  //////////////////////////////////////////

  const [phone, setPhone] = useState("");

  const [loading, setLoading] = useState(false);

  const [passwordShown, setPasswordShown] = useState(false);

  const [cpasswordShown, setCpasswordShown] = useState(false);

  const [frmstate, setFrmstate] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    email: "",
    password: "",
    nationality: "GB",
    country: "United Kingdom",
    password_confirmation: "",
    phone: phone,
    countrycallingcode: "",
  });

  const [errstate, setErrstate] = useState({
    errfirst_name: "",
    errmiddle_name: "",
    errlast_name: "",
    erremail: "",
    errpassword: "",
    errpassword_confirmation: "",
    errphone: "",
    errcountrycallingcode: "",
  });

  const [countries, setCountries] = useState([])

  useEffect(() => {

      //const data = require('./Utils/CountryData.json')
      const data = require('./Utils/countries.json')
        console.log(data)
        setCountries(data)

  }, []);

  useEffect(() => {
    if (phone !== "" && phone !== undefined) {
      const errlist = { ...errstate };
      const PhoneInputInput = document.querySelector("input[type=tel]"); //(".PhoneInputInput");
      if (
        isPossiblePhoneNumber(phone) !== true &&
        isValidPhoneNumber(phone) !== true
      ) {
        errlist.errphone = "Please enter a Valid Phone Number";
        PhoneInputInput.classList.remove("PhoneInputInput");
        PhoneInputInput.classList.add("invalidPhoneInputInput");
        setErrstate(errlist);
        setErrstate((errstate) => {
          console.log("updated errstate", errstate);
          return errstate;
        });
      } else {
        errlist.errphone = "";
        PhoneInputInput.classList.remove("invalidPhoneInputInput");
        PhoneInputInput.classList.add("PhoneInputInput");
        setErrstate(errlist);
        setErrstate((errstate) => {
          console.log("updated errstate", errstate);
          return errstate;
        });
      }
    }
    /* else {
      setPhone(000);
    } */
  }, [phone]);

  const navigate = useNavigate();

  // Password toggle handler
  const togglePassword = (e) => {
    e.preventDefault();
    setPasswordShown(!passwordShown);
  };

  // Password toggle handler
  const toggleCPassword = (e) => {
    e.preventDefault();
    setCpasswordShown(!cpasswordShown);
  };

  const handleSubmit = () => {
    console.log(frmstate);

    const errors = [];
    const errfield = { ...errstate };

    if (!frmstate.first_name.match(/^[a-zA-Z]+$/)) {
      errfield.errfirst_name = "Invalid first_name input";
      setErrstate(errfield);
      setErrstate((errstate) => {
        console.log("updated errstate", errstate);
        return errstate;
      });
      errors.push("Invalid first_name input");
    } else if (frmstate.last_name.length < 3) {
      errfield.errlast_name = "last_name must be at least 3 Characters long";
      setErrstate(errfield);
      setErrstate((errstate) => {
        console.log("updated errstate", errstate);
        return errstate;
      });
      errors.push("last_name must be at least 3 Characters long");
    }

    if (frmstate.middle_name !== "" && !frmstate.middle_name.match(/^[a-zA-Z\s]+$/)) {
      errfield.errmiddle_name = "Invalid middle_name input";
      setErrstate(errfield);
      setErrstate((errstate) => {
        console.log("updated errstate", errstate);
        return errstate;
      });
      errors.push("Invalid middle_name input");
    } else if (frmstate.middle_name !== "" && frmstate.middle_name.length < 3) {
      errfield.errmiddle_name = "middle_name must be at least 3 Characters long";
      setErrstate(errfield);
      setErrstate((errstate) => {
        console.log("updated errstate", errstate);
        return errstate;
      });
      errors.push("middle_name must be at least 3 Characters long");
    }

    if (!frmstate.last_name.match(/^[a-zA-Z]+$/)) {
      errfield.errlast_name = "Invalid first_name input";
      setErrstate(errfield);
      setErrstate((errstate) => {
        console.log("updated errstate", errstate);
        return errstate;
      });
      errors.push("Invalid last_name input");
    } else if (frmstate.last_name.length < 3) {
      errfield.errlast_name = "last_name must be at least 3 Characters long";
      setErrstate(errfield);
      setErrstate((errstate) => {
        console.log("updated errstate", errstate);
        return errstate;
      });
      errors.push("last_name must be at least 3 Characters long");
    }

    if (
      !/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/.test(
        frmstate.email
      )
    ) {
      errfield.erremail = "Invalid Email Address";
      setErrstate(errfield);
      setErrstate((errstate) => {
        console.log("updated errstate", errstate);
        return errstate;
      });
      errors.push("Invalid Email Address");
    }

    if (phone !== "" && phone !== undefined) {
      console.log("phone# is not empty nor undefined");
      if (
        isPossiblePhoneNumber(phone) !== true &&
        isValidPhoneNumber(phone) !== true
      ) {
        console.log("phone# is not valid");
        const PhoneInputInput = document.querySelector("input[type=tel]");
        PhoneInputInput.classList.remove("PhoneInputInput");
        PhoneInputInput.classList.add("invalidPhoneInputInput");
        errfield.errphone = "Invalid phone number";
        setErrstate(errfield);
        setErrstate((errstate) => {
          console.log("updated errstate", errstate);
          return errstate;
        });
        errors.push("Invalid Phone Number");
      }
    } else {
      console.log("phone# empty");
      const PhoneInputInput = document.querySelector("input[type=tel]");
      PhoneInputInput.classList.remove("PhoneInputInput");
      PhoneInputInput.classList.add("invalidPhoneInputInput");
      errfield.errphone = "Phone number cannot be empty";
      setErrstate(errfield);
      setErrstate((errstate) => {
        console.log("updated errstate", errstate);
        return errstate;
      });
      errors.push("Phone number cannot be empty");
    }

    if (frmstate.password.length < 8) {
      errfield.errpassword = "Password should be at least 8 characters long";
      setErrstate(errfield);
      setErrstate((errstate) => {
        console.log("updated errstate", errstate);
        return errstate;
      });
      errors.push("Password should be at least 8 characters long");
    }

    if (frmstate.password !== frmstate.password_confirmation) {
      errfield.errpassword_confirmation = "Passwords do not match";
      setErrstate(errfield);
      setErrstate((errstate) => {
        console.log("updated errstate", errstate);
        return errstate;
      });
      errors.push("Passwords do not match");
    }

    if (errors.length === 0) {

      mutate(frmstate);
      
    }
  };

  const handleOnKeyPress = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault()
      handleSubmit();
      console.log('submit')
    }
  }

  const handleChange = (event) => {
    event.persist();
    const { name, value } = event.target;
    console.log(
      event.target.type,
      "date moved selected value",
      event.target.name +
        "+++++++++++++++" +
        event.target.value +
        "Phone" +
        phone
    );

    const formstate = { ...frmstate };

    if (event.target.type === "select-one") {
      let selectedIndex = event.nativeEvent.target.selectedIndex;
      let selectedText = event.nativeEvent.target[selectedIndex].text;
      console.log("selected text:", selectedText);
      let CountryCode = event.nativeEvent.target[selectedIndex].getAttribute("data-code");
      console.log("selected item data code:", CountryCode);
      formstate.country = selectedText;
      formstate.nationality = event.nativeEvent.target[selectedIndex].getAttribute("data-code");
      console.log("updated formstate in select", formstate);
    }

    formstate[name] = value;

    formstate.phone = phone || "";

    /* const ccalling = parsePhoneNumber(phone)?.countryCallingCode ?? "44";

    console.log(
      "phone number",
      formstate.phone,
      "---",
      typeof phone,
      "parsed number",
      ccalling
    );

    formstate.countrycallingcode = ccalling; */

    console.log("updated formstate", formstate);
    setFrmstate(formstate);

    setFrmstate((frmstate) => {
      console.log("updated frmstate", frmstate);
      return frmstate;
    });
  };

  const handleOnBlur = (e) => {
    e.persist();
    const errlist = { ...errstate };

    //////first_name start
    if (e.target.name === "first_name") {
      if (!frmstate.first_name) {
        errlist.errfirst_name = "first_name is required";
        setErrstate(errlist);
        setErrstate((errstate) => {
          console.log("updated errstate", errstate);
          return errstate;
        });
      } else if (!frmstate.first_name.match(/^[a-zA-Z]+$/)) {
        errlist.errfirst_name = "Please enter Only letters";
        setErrstate(errlist);
        setErrstate((errstate) => {
          console.log("updated errstate", errstate);
          return errstate;
        });
      } else if (frmstate.first_name.length < 3) {
        errlist.errfirst_name = "First name must be at least 3 Characters long";
        setErrstate(errlist);
        setErrstate((errstate) => {
          console.log("updated errstate", errstate);
          return errstate;
        });
      } else {
        errlist.errfirst_name = "";
        setErrstate(errlist);
        setErrstate((errstate) => {
          console.log("updated errstate", errstate);
          return errstate;
        });
      }
    }
    //////first_name end

    //////middle_name start
    if (e.target.name === "middle_name") {
      if( frmstate.middle_name !== ""){

          if (!frmstate.middle_name.match(/^[a-zA-Z\s]+$/)) {
            errlist.errmiddle_name = "Please enter Only letters";
            setErrstate(errlist);
            setErrstate((errstate) => {
              console.log("updated errstate", errstate);
              return errstate;
            });
          } else if (frmstate.middle_name.length < 3) {
            errlist.errmiddle_name =
              "Middle name must be at least 3 Characters long";
            setErrstate(errlist);
            setErrstate((errstate) => {
              console.log("updated errstate", errstate);
              return errstate;
            });
          } else {
            errlist.errmiddle_name = "";
            setErrstate(errlist);
            setErrstate((errstate) => {
              console.log("updated errstate", errstate);
              return errstate;
            });
          }
      }
      else {
        errlist.errmiddle_name = "";
        setErrstate(errlist);
        setErrstate((errstate) => {
          console.log("updated errstate", errstate);
          return errstate;
        });
      }
    }
    //////middle_name end

    //////last_name start
    if (e.target.name === "last_name") {
      if (!frmstate.last_name) {
        errlist.errlast_name = "last_name is required";
        setErrstate(errlist);
        setErrstate((errstate) => {
          console.log("updated errstate", errstate);
          return errstate;
        });
      } else if (!frmstate.last_name.match(/^[a-zA-Z]+$/)) {
        errlist.errlast_name = "Please enter Only letters";
        setErrstate(errlist);
        setErrstate((errstate) => {
          console.log("updated errstate", errstate);
          return errstate;
        });
      } else if (frmstate.last_name.length < 3) {
        errlist.errlast_name = "last_name must be at least 3 Characters long";
        setErrstate(errlist);
        setErrstate((errstate) => {
          console.log("updated errstate", errstate);
          return errstate;
        });
      } else {
        errlist.errlast_name = "";
        setErrstate(errlist);
        setErrstate((errstate) => {
          console.log("updated errstate", errstate);
          return errstate;
        });
      }
    }
    //////last_name end

    //////Email start
    if (e.target.name === "email") {
      if (!frmstate.email) {
        errlist.erremail = "Email is required";
        setErrstate(errlist);
        setErrstate((errstate) => {
          console.log("updated errstate", errstate);
          return errstate;
        });
      } else if (
        !/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/.test(
          frmstate.email
        )
      ) {
        errlist.erremail = "Email is Invalid";
        setErrstate(errlist);
        setErrstate((errstate) => {
          console.log("updated errstate", errstate);
          return errstate;
        });
      } else {
        errlist.erremail = "";
        setErrstate(errlist);
        setErrstate((errstate) => {
          console.log("updated errstate", errstate);
          return errstate;
        });
      }
    }
    //////Email end

    //////password start
    if (e.target.name === "password") {
      if (!frmstate.password) {
        errlist.errpassword = "Password is required";
        setErrstate(errlist);
        setErrstate((errstate) => {
          console.log("updated errstate", errstate);
          return errstate;
        });
      } else if (frmstate.password.length < 8) {
        errlist.errpassword = "Password must be 8 or more  Characters long";
        setErrstate(errlist);
        setErrstate((errstate) => {
          console.log("updated errstate", errstate);
          return errstate;
        });
      } else if (frmstate.password !== frmstate.password_confirmation) {
        errlist.errpassword_confirmation = "Passwords do not match";
        setErrstate(errlist);
        setErrstate((errstate) => {
          console.log("updated errstate", errstate);
          return errstate;
        });
      } else {
        errlist.errpassword = "";
        setErrstate(errlist);
        setErrstate((errstate) => {
          console.log("updated errstate", errstate);
          return errstate;
        });
      }
    }
    //////password end

    //////password_confirmation start
    if (e.target.name === "password_confirmation") {
      if (!frmstate.password_confirmation) {
        errlist.errpassword = "confirm password is required";
        setErrstate(errlist);
        setErrstate((errstate) => {
          console.log("updated errstate", errstate);
          return errstate;
        });
      } else if (frmstate.password !== frmstate.password_confirmation) {
        errlist.errpassword_confirmation = "Passwords do not match";
        setErrstate(errlist);
        setErrstate((errstate) => {
          console.log("updated errstate", errstate);
          return errstate;
        });
      } else {
        errlist.errpassword_confirmation = "";
        setErrstate(errlist);
        setErrstate((errstate) => {
          console.log("updated errstate", errstate);
          return errstate;
        });
      }
    }
    //////password_confirmation end
  };

  return (
    <div className="auth-page">
      <div className="container-fluid p-0">
        <div className="row g-0">
          <div className="col-xxl-9 col-lg-8 col-md-7 fmpane">
            <div className="auth-full-page-content d-flex p-sm-5 p-4">
              <div className="w-100">
                <div className="d-flex flex-column h-100">
                  <div className="mb-4 mb-md-5R text-center">
                    <a href="# " className="d-block auth-logo">
                      {/* <img src="assets/images/logo.png" alt="" height="40%" />{" "} */}
                      <img src={companyDetails?.URL || "assets/images/logo.png"} alt="logo" width="217px" height="92px" /* height="20%" */ />
                      <span className="logo-txt"></span>
                    </a>
                  </div>
                  <div className="auth-content my-auto">
                    <div className="text-center">
                      <h5 className="mb-0">Client Registration</h5>
                    </div>
                    <form
                      className="needs-validation mt-4 pt-2"
                      noValidate
                      id="regform"
                      action="">
                      <div className="row">
                        <div className="mb-3 col-sm-4">
                          <label htmlFor="first_name" className="form-label">
                            First Name
                          </label>
                          <input
                            type="text"
                            pattern="^[a-zA-Z]+"
                            title="Please enter alphabets only."
                            minLength="3"
                            className={
                              errstate.errfirst_name
                                ? "is-invalid"
                                : "form-control"
                            }
                            id="first_name"
                            name="first_name"
                            placeholder="Enter first name"
                            value={frmstate.first_name || ""}
                            onChange={handleChange}
                            onKeyDown={handleOnKeyPress}
                            onBlur={handleOnBlur}
                            required
                          />
                          <div
                            className={
                              errstate.errfirst_name
                                ? "invalid-feedback"
                                : "hide-error"
                            }>
                            {errstate.errfirst_name}
                          </div>
                        </div>

                        <div className="mb-3 col-sm-4">
                          <label htmlFor="lastname" className="form-label">
                            Middle Name
                          </label>
                          <input
                            type="text"
                            /* pattern="^[a-zA-Z]+"
                            minLength="3" */
                            className={
                              errstate.errmiddle_name
                                ? "is-invalid"
                                : "form-control"
                            }
                            id="middle_name"
                            name="middle_name"
                            placeholder="Enter middlename"
                            value={frmstate.middle_name || ""}
                            onBlur={handleOnBlur}
                            onKeyDown={handleOnKeyPress}
                            onChange={handleChange}
                            required
                          />
                          <div
                            className={
                              errstate.errmiddle_name
                                ? "invalid-feedback"
                                : "hide-error"
                            }>
                            {errstate.errmiddle_name}
                          </div>
                        </div>

                        <div className="mb-3 col-sm-4">
                          <label htmlFor="lastname" className="form-label">
                            Last Name
                          </label>
                          <input
                            type="text"
                            pattern="^[a-zA-Z]+"
                            minLength="3"
                            className={
                              errstate.errlast_name
                                ? "is-invalid"
                                : "form-control"
                            }
                            id="last_name"
                            name="last_name"
                            placeholder="Enter surname"
                            value={frmstate.last_name || ""}
                            onBlur={handleOnBlur}
                            onKeyDown={handleOnKeyPress}
                            onChange={handleChange}
                            required
                          />
                          <div
                            className={
                              errstate.errlast_name
                                ? "invalid-feedback"
                                : "hide-error"
                            }>
                            {errstate.errlast_name}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="mb-3 col-sm-4">
                          <label htmlFor="useremail" className="form-label">
                            Email
                          </label>
                          <input
                            type="email"
                            className={
                              errstate.erremail ? "is-invalid" : "form-control"
                            }
                            id="email"
                            name="email"
                            placeholder="Enter email"
                            required
                            value={frmstate.email || ""}
                            onBlur={handleOnBlur}
                            onKeyDown={handleOnKeyPress}
                            onChange={handleChange}
                          />
                          <div
                            className={
                              errstate.erremail
                                ? "invalid-feedback"
                                : "hide-error"
                            }>
                            {errstate.erremail}
                          </div>
                        </div>

                        <div className="mb-3 col-sm-4">
                          <label htmlFor="nationality" className="form-label">
                            Country of Origin
                          </label>
                          <select
                            name="country"
                            value={frmstate.country || ""} // Prop: The email input data
                            onChange={handleChange} //
                            onKeyDown={handleOnKeyPress}
                            className="form-select m-b-3"
                            style={{ width: "100%", color: "#495057" }}>
                              <option value=''>select country</option>
                              {
                                countries.map((item) =>{
                                    return(
                                        <option key={item.Code} data-code={item.Code}>
                                            {item.Name}
                                        </option>
                                    )
                                })
                              }
                          </select>
                        </div>

                        <div className="mb-3 col-sm-4">
                          <label htmlFor="mobilenumber" className="form-label">
                            Mobile Number
                          </label>
                          <PhoneInput
                            placeholder="Enter phone number"
                            international
                            countryCallingCodeEditable={true}
                            defaultCountry={frmstate.nationality}
                            value={phone ?? "+"}
                            name="phone"
                            onChange={setPhone}
                          />
                          <div
                            className={
                              errstate.errphone
                                ? "invalid-feedback"
                                : "hide-error"
                            }>
                            {errstate.errphone}
                          </div>
                  
                        </div>
                      </div>

                      <div className="row">
                        <div className="mb-3 col-sm-6">
                          <label htmlFor="toglepassword" className="form-label">
                            Password
                          </label>
                          <div className="input-groupR">
                            <input
                              type={passwordShown ? "text" : "password"}
                              minLength="8"
                              className={
                                errstate.errpassword
                                  ? "is-invalid"
                                  : "form-control"
                              }
                              id="password"
                              name="password"
                              placeholder="Enter password"
                              required
                              value={frmstate.password || ""}
                              onBlur={handleOnBlur}
                              onKeyDown={handleOnKeyPress}
                              onChange={handleChange}
                            />
                            <button
                              className="btn btn-light shadow-none ms-0"
                              onClick={togglePassword}>
                              <i
                                className={
                                  !passwordShown
                                    ? "mdi mdi-eye-outline"
                                    : "mdi mdi-eye-off-outline"
                                }></i>
                            </button>
                          </div>
                          <div
                            className={
                              errstate.errpassword
                                ? "invalid-feedback"
                                : "hide-error"
                            }>
                            {errstate.errpassword}
                          </div>
                        </div>
                        <div className="mb-3 col-sm-6">
                          <label htmlFor="toglepassword" className="form-label">
                            Confirm Password
                          </label>
                          <div className="input-groupR">
                            <input
                              type={cpasswordShown ? "text" : "password"}
                              minLength="8"
                              className={
                                errstate.errpassword_confirmation
                                  ? "is-invalid"
                                  : "form-control"
                              }
                              id="password"
                              name="password_confirmation"
                              placeholder="Confirm password"
                              required
                              value={frmstate.password_confirmation || ""}
                              onBlur={handleOnBlur}
                              onKeyDown={handleOnKeyPress}
                              onChange={handleChange}
                            />
                            <button
                              className="btn btn-light shadow-none ms-0"
                              onClick={toggleCPassword}>
                              <i
                                className={
                                  !cpasswordShown
                                    ? "mdi mdi-eye-outline"
                                    : "mdi mdi-eye-off-outline"
                                }></i>
                            </button>
                          </div>
                          <div
                            className={
                              errstate.errpassword_confirmation
                                ? "invalid-feedback"
                                : "hide-error"
                            }>
                            {errstate.errpassword_confirmation}
                          </div>
                        </div>
                      </div>

                      <div
                        className="mb-4 reg-btn-wrapper"
                        style={{ marginTop: "1.7rem" }}>
                        <p className="mb-0">
                          By registering you agree to the i-visas
                          <a
                            href="# "
                            className="text-primary"
                            style={{ fontWeight: "700" }}>
                            Terms of Use
                          </a>
                        </p>
                      </div>
                      <div className="mb-3 reg-btn-wrapper">
                        {isLoading ? (
                          <button
                            type="button"
                            className="btn waves-effect waves-light">
                            <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                            Saving your data...
                          </button>
                        ) : (
                          <button
                            className="btn btn-primary waves-effect waves-light reg-btn"
                            onClick={handleSubmit}
                            type="button">
                            Register
                          </button>

                          
                        )}
                      </div>
                    </form>

                    {isError && (
                      <div className="mt-5 text-center">
                        <ul className="errorlist">
                      {/* <p className="text-muted-error mb-0"></p> */}
                        
                        {
                          errors.map((errorItem, i) =>{
                              return(
                                  <li key={i} className="text-muted-error">
                                      {errorItem}
                                  </li>
                              )
                          })
                        }
                      
                      </ul>
                    </div>
                    )
                    
                    }

                    <div className="mt-5 text-center">
                      <p className="text-muted mb-0">
                        Already have an account ?{" "}
                        <a href="/" className="text-primary fw-semibold">
                          {" "}
                          Login{" "}
                        </a>{" "}
                      </p>
                    </div>
                  </div>
                  <div className="text-center" style={{ marginTop: "2.4rem" }}>
                    <p className="mb-0">
                      {/* © {new Date().getFullYear()} i-visas.com */}
                      © {new Date().getFullYear()} {companyDetails?.COMPANY_NAME ?? "i-visas.com"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* end auth full page content */}
          </div>
          {/* end col */}
          <div className="col-xxl-3 col-lg-4 col-md-5">
            <div className="auth-bg pt-md-5 p-4 d-flex">
              <div className="bg-overlay bg-primary"></div>
              <ul className="bg-bubbles">
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
              </ul>
              {/* end bubble effect */}
              <div className="row justify-content-center align-items-center">
                <div className="col-xl-9">
                  <div className="p-0 p-sm-4 px-xl-0">
                    <div
                      id="reviewcarouselIndicators"
                      className="carousel slide"
                      data-bs-ride="carousel">
                      <div className="carousel-indicators carousel-indicators-rounded justify-content-start ms-0 mb-0">
                        <button
                          type="button"
                          data-bs-target="#reviewcarouselIndicators"
                          data-bs-slide-to="0"
                          className="active"
                          aria-current="true"
                          aria-label="Slide 1"></button>
                        <button
                          type="button"
                          data-bs-target="#reviewcarouselIndicators"
                          data-bs-slide-to="1"
                          aria-label="Slide 2"></button>
                        <button
                          type="button"
                          data-bs-target="#reviewcarouselIndicators"
                          data-bs-slide-to="2"
                          aria-label="Slide 3"></button>
                      </div>
                      {/* end carouselIndicators  */}
                      <div className="carousel-inner">
                        <div className="carousel-item active">
                          <div className="testi-contain text-white">
                            <i className="bx bxs-quote-alt-left text-success display-6"></i>

                            <h4 className="mt-4 fw-medium lh-base text-white">
                              “Thank you for your attentive and expertise
                              getting my application for British Citizenship
                              approved. The service you provided was very good.”
                            </h4>
                            <div className="mt-4 pt-3 pb-5">
                              <div className="d-flex align-items-start">
                                <div className="flex-grow-1 ms-3 mb-4">
                                  <h5 className="font-size-18 text-white">
                                    Maureen Papas
                                  </h5>
                                  <p className="mb-0 text-white-50">
                                    Allpress Espresso, UK
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="carousel-item">
                          <div className="testi-contain text-white">
                            <i className="bx bxs-quote-alt-left text-success display-6"></i>

                            <h4 className="mt-4 fw-medium lh-base text-white">
                              “Professional immigration advise and responsible
                              stuff.”
                            </h4>
                            <div className="mt-4 pt-3 pb-5">
                              <div className="d-flex align-items-start">
                                <div className="flex-grow-1 ms-3 mb-4">
                                  <h5 className="font-size-18 text-white">
                                    Wen Qi - China
                                  </h5>
                                  <p className="mb-0 text-white-50">
                                    Tier 1 (Entrepreneur)
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="carousel-item">
                          <div className="testi-contain text-white">
                            <i className="bx bxs-quote-alt-left text-success display-6"></i>

                            <h4 className="mt-4 fw-medium lh-base text-white">
                              “Of course, I am fully satisfied with the services
                              provided by you and would like to continue dealing
                              with you in future.”
                            </h4>
                            <div className="mt-4 pt-3 pb-5">
                              <div className="d-flex align-items-start">
                                <div className="flex-1 ms-3 mb-4">
                                  <h5 className="font-size-18 text-white">
                                    SHAHZAD RAZA - ULTIMATE T LTD
                                  </h5>
                                  <p className="mb-0 text-white-50">
                                    Tier 1 (Entrepreneur)
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/*  end carousel-inner  */}
                    </div>
                    {/* end review carousel  */}
                  </div>
                </div>
              </div>{" "}
              {/* test */}
            </div>
          </div>
          {/* end col */}
        </div>
        {/* end row  */}
      </div>
      {/* end container fluid */}
    </div>
  );
};

export default Register;
