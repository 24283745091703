import React, { useState, useEffect, useRef, Fragment} from "react";
import { useQuery, useMutation } from "react-query";
import toast, { Toaster } from 'react-hot-toast';
import { removeUserSession, sleep } from "./Utils/Common";
import { useNavigate, useParams } from "react-router-dom";
import * as api from './Utils/apiCall';
import moment from "moment";

import "./Messages.css";

const ViewApplication = () => {

const navigate = useNavigate();

const params = useParams();

const [appid, setAppid] = useState('');

const [appdata, setAppdata] = useState([]);

const [permit, setPermit] = useState(false);

const [details, setDetails] = useState({});

const appidRef = useRef();


useEffect(() => {
    if (params?.appid === null || params?.appid=== undefined) {
        console.log("inside sideEffect edit 1", params?.appid,);
      return navigate("/dashboard/application_list", { replace: true });
    }
    else {
      appidRef.current = params?.appid;
            setAppid(params?.appid);

            setAppid((appid) => {
                console.log("returning AppID", appid);
                return appid;
            });      
    }
  }, [params?.appid]);

  
  useEffect(() => {

    if(permit) {
    document
      .getElementById("toglepanel")
      .addEventListener("click", togglePanel);

    }

  }, [permit]); 


  //const editingApp = useQuery(['getApplicationbyID', appid], () => api.editApp(appid), {
    const editingApp = useQuery(['getApplicationbyID', appidRef.current], () => api.editApp(appidRef.current), {
      refetchInterval: false,
      onSuccess: (data) => {
  
        console.log("edit app Successfull", data);
        console.log("edit app Successfull http status", data?.response?.status);
  
        const AppDetails = {...data};

        console.log(" AppDetails:", AppDetails);

        console.log("Other Names:", data?.name_change_details[0]?.ATTRIBUTES);

        //console.log("Other Names length", (JSON.parse(details?.name_change_details[0]?.ATTRIBUTES).length > 0))

        setDetails(AppDetails);

        toast.success('Application loaded Successfully!',{
          duration: 6000,
        });
  
        setDetails((details) => {
          console.log(" edit details value:", details);
          return details;
        }); 
          
        setPermit(true);
  
    },
    onError: (error) => {
        console.log("Error Message",error); 
        setPermit(false);
    
      if(error?.response?.status === 401){
        toast.error("Session Expired : Please Re-Login",{
          duration: 6000,
          });
       removeUserSession();
        navigate("/", {replace: false});
      }
      else {
        toast.error("Something went wrong",{
          duration: 6000,
          });
      }
  
      }
    });
  
    const { loading: loadingedit } = editingApp;

    const getdatedif = (s) => {

      let d = new Date(s);
  
      let oldDateMoment, newDateMoment, numYears, numMonths, numDays;
  
      oldDateMoment = moment(d);
      newDateMoment = moment(new Date());
  
      numYears = newDateMoment.diff(oldDateMoment, "years");
      oldDateMoment = oldDateMoment.add(numYears, "years");
      numMonths = newDateMoment.diff(oldDateMoment, "months");
      oldDateMoment = oldDateMoment.add(numMonths, "months");
      numDays = newDateMoment.diff(oldDateMoment, "days");
      let difcount = numYears + " years |" + numMonths + " months |" + numDays + " days";
        
      return difcount; 
    };

  const togglePanel = () => {
    const hpanel = document.getElementById("tpanel");

    if (hpanel.classList.contains("small-header")) {
      hpanel.classList.remove("small-header");
    } else {
      hpanel.classList.add("small-header");
    }
  };


  const checkFileExtension = (file = null) => {

    if(file){
      console.log("file:",file);
      const extension = file.substring(file.lastIndexOf('.') + 1);
     return extension;
    }
    {
      return null;
    }
  }

  const checkFileType = (file = null) => {

      let icon = '';

      if(file){
        console.log("file:",file);
        const extension = file.substring(file.lastIndexOf('.') + 1);
        console.log("extension:",extension);
        
        switch(extension) {
          case 'png':
            icon = 'fa fa-file-image-o text-success'
            break;
          case 'jpg':
              icon = 'fa fa-file-image-o text-success'
              break;
          case 'jpeg':
                icon = 'fa fa-file-image-o text-success'
                break;
          case 'pdf':
            icon = 'fa fa-file-pdf-o text-success'
            break;
          case 'doc':
              icon = 'fa fa-file-word-o text-success'
              break;
          case 'docx':
                icon = 'fa fa-file-word-o text-success'
                break;
          case 'xls':
                  icon = 'fa fa-file-excel-o text-success'
                  break;
          case 'zip':
            icon = 'fa fa-file-zip-o text-success'
            break;
          case 'pptx':
            icon = 'fa fa-file-powerpoint-o text-success'
            break;
          case 'ppt':
            icon = 'fa fa-file-powerpoint-o text-success'
            break;
          default:
            icon = 'fa fa-file-archive-o text-success'
        }
        return icon;
      }
      else {
        icon = 'fa fa-file-o text-info'
      }
  }

  const uploadFileNameRef = useRef('');

  const HandleFileDownload = (path, filename) => {

   console.log("CLicked path", path, "filename",filename);

   const uploadFIleRef = path;

    if(path && filename){
       uploadFileNameRef.current = filename;
       retrieveFile.mutate({"path":uploadFIleRef});
    }
  }

  const retrieveFile =  useMutation(api.retrieveFile, {
    onSuccess: (data) => {
      console.log("FUll URL of FIle",data);

      //let fileUrl = 'https://attachments.convertkitcdnn2.com/775726/5df451f5-51e3-4413-83fa-72d552357b41/VS%20Code%20Shortcuts.pdf';
      let fileUrl = data?.URL; //'https://i-visas-uploads.s3.eu-west-2.amazonaws.com/public/109747/passport_upload/Screenshot%202022-05-03%20at%201.05.01%20AM.png';
      let fileName = uploadFileNameRef.current || "Download." + checkFileExtension(fileUrl);
      console.log("Derived fileName", fileName);
      let a = document.createElement("a");

     // a.href = fileUrl;
      a.setAttribute('href', fileUrl);
      a.setAttribute('target', '_blank');
      a.setAttribute("download", fileName);
      a.click(); 
      document.body.removeChild(a);
    },
    onError: (error) => {
      
      const errObject = error.response.data?.errors

      console.log("errObject",errObject); 

        if(error.response.status === 401){
          toast.error("Session expired: Kindly re-login",{
            duration: 6000,
            });
          removeUserSession();
          navigate("/", {replace: false});
        }
        else {
          toast.error("Something went wrong :: submission",{
            duration: 6000,
            });
        }

    }
  });

  if( editingApp.isLoading){
    return (
      <div style={{display:"grid", justifyContent: "center", alignContent: "center"}}>
          
          <div className="mt-5 text-center">
                <h4>Loading ...a</h4>    
            </div>
          
      </div>
    )
  }

  if(!permit){
    return (
              <div style={{display:"grid", justifyContent: "center", alignContent: "center"}}>
                {editingApp.isError && (
                    <div className="mt-5 text-center">
                      <h4>Encountered an Error Pulling Application Data</h4>
                      <p>Kindly contact Administrator</p>
                      </div>
                    )}
                    </div>
            )
            
          }


  return (
    <Fragment>
      <Toaster />
      <div className="normalheader" id="tpanel">
        <div className="hpanel">
          <div className="panel-body">
            <a className="small-header-action" id="toglepanel" href="# ">
              <div className="clip-header">
                <i className="fa fa-arrow-up"></i>
              </div>
            </a>

            <div id="hbreadcrumb" className="pull-right m-t-lg">
              <ol className="hbreadcrumb breadcrumb">
                <li>
                  <a href="# ">i-visas</a>
                </li>
                <li>
                  <span>Client</span>
                </li>
                <li className="active">
                  <span>Applications </span>
                </li>
              </ol>
            </div>
            <h2 className="font-light m-b-xs">View Application</h2>
           {/*  <small>
              Read Only mode of existing <strong>Application</strong>
            </small> */}
          </div>
        </div>
      </div>

      <div className="content">
        <div className="row">
         
          <div className="col-md-12">
            <div className="hpanel email-compose">


         { details?.application_details[0]?.APPSTATUS === 1 && (
              <div className="row">
                <div className="col-lg-12">
                    <div className="hpanel">
                    <div className="panel-heading hbuilt" style={{fontWeight: "Normal"}}>
                            <div className="pull-right">
                            <span style={{color:"#7f878d"}}><i className="fa fa-circle-o"></i>  <strong>Draft</strong></span>
                            </div>
                            <i className="fa fa-snowflake-o"></i> Application ID <i className="fa fa-angle-double-right"></i>  <strong>{details?.application_details[0]?.APPTYPE_ID}</strong>
                        </div>
                      
                    </div>
                </div>
            </div>

          )}



          { details?.application_details[0]?.APPSTATUS === 2 && (
              <div className="row">
                <div className="col-lg-12">
                    <div className="hpanel">
                    <div className="panel-heading hbuilt" style={{fontWeight: "Normal"}}>
                            <div className="pull-right">
                            <span style={{color:"#2c8ac9"}}><i className="fa fa-dot-circle-o"></i>  <strong>Review</strong></span>
                            </div>
                            <i className="fa fa-snowflake-o"></i> Application ID <i className="fa fa-angle-double-right"></i>  <strong>{details?.application_details[0]?.APPTYPE_ID}</strong>
                        </div>
                      
                    </div>
                </div>
            </div>

           )}



         { details?.application_details[0]?.APPSTATUS === 3 && (
              <div className="row">
                <div className="col-lg-12">
                    <div className="hpanel">
                        <div className="panel-heading hbuilt" style={{fontWeight: "Normal"}}>
                            <div className="pull-right">
                                <span style={{color:"#5eab31"}}><i className="fa fa-bullseye"></i>  <strong>Approved</strong></span>
                            </div>
                            <i className="fa fa-snowflake-o"></i> Application ID <i className="fa fa-angle-double-right"></i>  <strong>{details?.application_details[0]?.APPTYPE_ID}</strong>
                        </div>
                      
                    </div>
                </div>
            </div>

           )}

{ details?.application_details[0]?.APPSTATUS === 4 && (
              <div className="row">
                <div className="col-lg-12">
                    <div className="hpanel">
                        <div className="panel-heading hbuilt" style={{fontWeight: "Normal"}}>
                            <div className="pull-right">
                                <span style={{color:"#5eab31"}}><i className="fa fa-cart-plus text-info"></i>  <strong>Billed</strong></span>
                            </div>
                            <i className="fa fa-snowflake-o"></i> Application ID <i className="fa fa-angle-double-right"></i>  <strong>{details?.application_details[0]?.APPTYPE_ID}</strong>
                        </div>
                      
                    </div>
                </div>
            </div>

           )}

{ details?.application_details[0]?.APPSTATUS === 5 && (
              <div className="row">
                <div className="col-lg-12">
                    <div className="hpanel">
                        <div className="panel-heading hbuilt" style={{fontWeight: "Normal"}}>
                            <div className="pull-right">
                                <span style={{color:"#5eab31"}}><i className="fa fa-leaf text-success"></i>  <strong>Completed</strong></span>
                            </div>
                            <i className="fa fa-snowflake-o"></i> Application ID <i className="fa fa-angle-double-right"></i>  <strong>{details?.application_details[0]?.APPTYPE_ID}</strong>
                        </div>
                      
                    </div>
                </div>
            </div>

           )}
             
                <>
               
        <div className="hpanel">

            <div className="tabs-left">
            <ul className="nav nav-tabs" style={{width:"13%"}}>
                <li className="active"><a data-toggle="tab" href="#general" style={{color: "#5c9731"}}> General</a></li>
                <li className=""><a data-toggle="tab" href="#accommodation" style={{color: "#5c9731"}}>Accommodation</a></li>
                <li className=""><a data-toggle="tab" href="#family" style={{color: "#5c9731"}}>Family</a></li>
                <li className=""><a data-toggle="tab" href="#education" style={{color: "#5c9731"}}>English Proficiency</a></li>
                <li className=""><a data-toggle="tab" href="#qualification" style={{color: "#5c9731"}}>Qualification</a></li>
                <li className=""><a data-toggle="tab" href="#employment" style={{color: "#5c9731"}}>Employment</a></li>
               {/*  <li className=""><a data-toggle="tab" href="#membership" style={{color: "#5c9731"}}>Membership</a></li> */}
                <li className=""><a data-toggle="tab" href="#maintenance" style={{color: "#5c9731"}}>Maintenance</a></li>
                <li className=""><a data-toggle="tab" href="#travel" style={{color: "#5c9731"}}>Travel</a></li>
                <li className=""><a data-toggle="tab" href="#character" style={{color: "#5c9731"}}>Character</a></li>
                <li className=""><a data-toggle="tab" href="#tracking" style={{color: "#5c9731"}}>Tracking</a></li>
                {/* <li className=""><a data-toggle="tab" href="#invoice" style={{color: "#5c9731"}}>Invoice</a></li> */}
                <li className=""><a data-toggle="tab" href="#documents" style={{color: "#5c9731"}}>Documents</a></li>
            </ul>
            <div className="tab-content ">
                <div id="general" className="tab-pane active">
                <div className="panel-body" style={{marginLeft: "13%", width: "87%"}}>
                
                <div className="container" style={{height:"500px", overflowY:"scroll"}}>
                <div className="row">
                    <div className="col-md-4 border-right"> <div className="contact-stat"><span>First Name: </span> <strong>{details?.application_details[0]?.FIRSTNAME ?? "Not Set"}</strong></div> </div>
                    <div className="col-md-4 border-right"> <div className="contact-stat"><span>Middle Name: </span> <strong>{details?.application_details[0]?.MIDDLENAME ?? "Not Set"}</strong></div> </div>
                    <div className="col-md-4"> <div className="contact-stat"><span>Last Name: </span> <strong>{details?.application_details[0]?.LASTNAME ?? "Not Set"}</strong></div> </div>
                </div>
                 <br />
                <div className="row">
                    <div className="col-md-4 border-right"> <div className="contact-stat"><span>Have you been known by any other names ?: </span> <strong>{details?.application_details[0]?.NAME_CHANGE_QUESTION ?? "Not Set"}</strong></div> </div>
                    <div className="col-md-4 border-right"> <div className="contact-stat"><span>Country of Birth: </span> <strong>{ details?.application_details[0]?.COUNTRY_OF_BIRTH ?? "Not Set"}</strong></div> </div>
                    <div className="col-md-4"> <div className="contact-stat"><span>Place of Birth: </span> <strong>{ details?.application_details[0]?.PLACE_OF_BIRTH ?? "Not Set"}</strong></div> </div>
                </div>

                <br />

                { details?.application_details[0]?.NAME_CHANGE_QUESTION === "Yes" && 
                 (
                 <>
                  <div className="row">
                  <div className="col-md-12"> 
                  <div className="holder">
                  <p className="liner">
                    <span className="superLabels">Other Names</span>
                  </p>
                  </div>
                  </div>
                  </div>
                  </>
                 )
                }

                { details?.application_details[0]?.NAME_CHANGE_QUESTION === "Yes" &&
                 
                 details?.name_change_details[0]?.ATTRIBUTES ? (JSON.parse(details?.name_change_details[0]?.ATTRIBUTES).length > 0) ?
                 
                 JSON.parse(details?.name_change_details[0]?.ATTRIBUTES).map((prevNames,i) => 
                 (
                  <Fragment key={i}>
                  <div className="row" key={i}>
                  <div className="col-md-4 border-right"> <div className="contact-stat"><span>Previous Name: </span> <strong>{prevNames[`oldname_${i}`] ?? "Not Set"}</strong></div> </div>
                  <div className="col-md-4 border-right"> <div className="contact-stat"><span>From: </span> <strong>{ prevNames[`startdate_${i}`] ?? "Not Set"}</strong></div> </div>
                  <div className="col-md-4"> <div className="contact-stat"><span>To: </span> <strong>{ prevNames[`enddate_${i}`] ?? "Not Set"}</strong></div> </div>
                  </div>
                  <br />
                  </Fragment>
                 )
                 )

                 :("")

                 :("")
                }

{ details?.application_details[0]?.NAME_CHANGE_QUESTION === "Yes" && 
                 (
                 <>
                  <div className="row">
                  <div className="col-md-12"> 
                  <div className="holder">
                  <p className="liner"></p>
                  </div>
                  </div>
                  </div>
                  </>
                 )
                }

                <br />
                <div className="row">
                    <div className="col-md-4 border-right"> <div className="contact-stat"><span>Current nationality: </span> <strong>{ details?.application_details[0]?.NATIONALITY ?? "Not Set"}</strong></div> </div>
                    <div className="col-md-4 border-right"> <div className="contact-stat"><span>Any other nationalities ?: </span> <strong>{details?.application_details[0]?.OTHER_NATIONALITY_QUESTION ?? "Not Set"}</strong></div> </div>
                    <div className="col-md-4"> <div className="contact-stat"><span>Current passport number: </span> <strong>{details?.application_details[0]?.PASSPORT_NO ?? "Not Set"}</strong></div> </div>
                </div>
                <br />

               { details?.application_details[0]?.OTHER_NATIONALITY_QUESTION === "Yes" && 
                 (
                 <>
                  <div className="row">
                  <div className="col-md-12"> 
                  <div className="holder">
                  <p className="liner">
                    <span className="superLabels">Other Nationalities</span>
                  </p>
                  </div>
                  </div>
                  </div>
                  </>
                 )
                }

                { details?.application_details[0]?.OTHER_NATIONALITY_QUESTION === "Yes" &&
                 
                 details?.nationality_details[0]?.ATTRIBUTES ? (JSON.parse(details?.nationality_details[0]?.ATTRIBUTES).length > 0) ?
                 
                 JSON.parse(details?.nationality_details[0]?.ATTRIBUTES).map((prevNational,i) => 
                 (
                  <Fragment key={i}>
                  <div className="row" key={i}>
                  <div className="col-md-4 border-right"> <div className="contact-stat"><span>Previous Nationality: </span> <strong>{prevNational[`othernation_${i}`] ?? "Not Set"}</strong></div> </div>
                  <div className="col-md-4 border-right"> <div className="contact-stat"><span>From: </span> <strong>{ prevNational[`otherstartdate_${i}`] ?? "Not Set"}</strong></div> </div>
                  <div className="col-md-4"> <div className="contact-stat"><span>To: </span> <strong>{ prevNational[`otherenddate_${i}`] ?? "Not Set"}</strong></div> </div>
                  </div>
                  <br />
                  </Fragment>
                 )
                 )

                 :("")

                 :("")
                }

               { details?.application_details[0]?.OTHER_NATIONALITY_QUESTION === "Yes" && 
                 (
                 <>
                  <div className="row">
                  <div className="col-md-12"> 
                  <div className="holder">
                  <p className="liner"></p>
                  </div>
                  </div>
                  </div>
                  </>
                 )
                }

                <div className="row">
                    <div className="col-md-4 border-right"> <div className="contact-stat"><span>Passport Issue date: </span> <strong>{details?.application_details[0]?.PASSPORT_ISSUED ?? "Not Set"}</strong></div> </div>
                    <div className="col-md-4 border-right"> <div className="contact-stat"><span>Passport Expiry date : </span> <strong>{details?.application_details[0]?.PASSPORT_EXPIRY ?? "Not Set"}</strong></div> </div>
                    <div className="col-md-4"> <div className="contact-stat"><span>Issuing authority: </span> <strong>{details?.application_details[0]?.ISSUING_AUTHORITY ?? "Not Set"}</strong></div> </div>
                </div>     
               
                 <hr/>

                <div className="row">
                    <div className="col-md-3 border-right"> <div className="contact-stat"><span>Do you have a national ID card for your country of nationality : </span> <strong>{details?.application_details[0]?.NATIONAL_ID_QUESTION ?? "Not Set"}</strong></div> </div>
                    <div className="col-md-3 border-right"> <div className="contact-stat"><span>Do you have BRP? : </span> <strong>{details?.application_details[0]?.BRP_QUESTION ?? "Not Set"}</strong></div> </div>
                    {details?.application_details[0]?.BRP_QUESTION === 'Yes' &&
                    <>
                    <div className="col-md-3 border-right"> <div className="contact-stat"><span>BRP Number: </span> <strong>{details?.application_details[0]?.BRP_NUMBER ?? "Not Set"}</strong></div> </div>
                    <div className="col-md-3"> <div className="contact-stat"><span>BRP Expiry: </span> <strong>{details?.application_details[0]?.BRP_ISSUE_DATE ?? "Not Set"}</strong></div> </div>
                    </>
                    }
                </div>     
                <br />

                <div className="row">
                    <div className="col-md-3 border-right"> <div className="contact-stat"><span>Name of Mother : </span> <strong>{details?.application_details[0]?.NAME_MOTHER ?? "Not Set"}</strong></div> </div>
                    <div className="col-md-3 border-right"> <div className="contact-stat"><span>Date of Birth : </span> <strong>{details?.application_details[0]?.DOB_MOTHER ?? "Not Set"}</strong></div> </div>
                    <div className="col-md-3 border-right"> <div className="contact-stat"><span>Nationality: </span> <strong>{details?.application_details[0]?.NATIONALITY_MOTHER ?? "Not Set"}</strong></div> </div>
                    <div className="col-md-3"> <div className="contact-stat"><span>Mother's Place of birth: </span> <strong>{details?.application_details[0]?.PLACE_OF_BIRTH_MOTHER ?? "Not Set"}</strong></div> </div>
                </div>     
                <br />

                <div className="row">
                    <div className="col-md-3 border-right"> <div className="contact-stat"><span>Name of Father : </span> <strong>{details?.application_details[0]?.NAME_FATHER ?? "Not Set"}</strong></div> </div>
                    <div className="col-md-3 border-right"> <div className="contact-stat"><span>Date of Birth : </span> <strong>{details?.application_details[0]?.DOB_FATHER ?? "Not Set"}</strong></div> </div>
                    <div className="col-md-3 border-right"> <div className="contact-stat"><span>Nationality: </span> <strong>{details?.application_details[0]?.PLACE_OF_BIRTH_FATHER ?? "Not Set"}</strong></div> </div>
                    <div className="col-md-3"> <div className="contact-stat"><span>Father's Place of birth: </span> <strong>{details?.application_details[0]?.NATIONALITY_FATHER ?? "Not Set"}</strong></div> </div>
                </div>     
                <br />

                <div className="row">
                    <div className="col-md-3 border-right"> <div className="contact-stat"><span>Do you have a UK NI Number : </span> <strong>{details?.application_details[0]?.UK_NI_QUESTION ?? "Not Set"}</strong></div> </div>
                    {details?.application_details[0]?.UK_NI_QUESTION === 'Yes' &&
        
                    <>
                    <div className="col-md-3 border-right"> <div className="contact-stat"><span>UK NI : </span> <strong>{details?.application_details[0]?.UK_NI ?? "Not Set"}</strong></div> </div>
                    </>
                    }
                    <div className="col-md-3 border-right"> <div className="contact-stat"><span>Do you have a UK driving licence : </span> <strong>{details?.application_details[0]?.UK_DRIVER_LICENSE_QUESTION ?? "Not Set"}</strong></div> </div>
                    {details?.application_details[0]?.UK_DRIVER_LICENSE_QUESTION === 'Yes' &&
        
                    <>
                    <div className="col-md-3"> <div className="contact-stat"><span>Licence No : </span> <strong>{details?.application_details[0]?.UK_DRIVER_LICENSE ?? "Not Set"}</strong></div> </div>
                    </>
                    }
                </div>     
                <hr/>

                <div className="row">
                    <div className="col-md-6 border-right"> <div className="contact-stat"><span>Email Address : </span> <strong>{details?.application_details[0]?.EMAIL ?? "Not Set"}</strong></div> </div>
                    <div className="col-md-6"> <div className="contact-stat"><span>Mobile Number : </span> <strong>{details?.application_details[0]?.MOBILE ?? "Not Set"}</strong></div> </div>
                </div>     
                <br />
                    </div>
                    </div>
                </div>

                <div id="accommodation" className="tab-pane">
                <div className="panel-body" style={{marginLeft: "13%", width: "87%"}}>
                <div className="container" style={{height:"500px", overflowY:"scroll"}}>

                <div className="row">
                    <div className="col-md-6 border-right"> <div className="contact-stat"><span>Address 1: </span> <strong>{details?.application_details[0]?.ADDRESS1 ?? "Not Set"}</strong></div> </div>
                    <div className="col-md-6"> <div className="contact-stat"><span>Address 2: </span> <strong>{details?.application_details[0]?.ADDRESS2 ?? "Not Set"}</strong></div> </div>
                </div>
                
                <hr/>

                 <div className="row">
                    <div className="col-md-4 border-right"> <div className="contact-stat"><span>Location Name: </span> <strong>{ details?.application_details[0]?.LOCATION_NAME ?? "Not Set"}</strong></div> </div>
                    <div className="col-md-4 border-right"> <div className="contact-stat"><span>Location Code: </span> <strong>{details?.application_details[0]?.LOCATION_CODE ?? "Not Set"}</strong></div> </div>
                    <div className="col-md-4"> <div className="contact-stat"><span>Town: </span> <strong>{details?.application_details[0]?.TOWN ?? "Not Set"}</strong></div> </div>
                </div>
                <br />

                <div className="row">
                    <div className="col-md-4 border-right"> <div className="contact-stat"><span>County: </span> <strong>{ details?.application_details[0]?.COUNTY ?? "Not Set"}</strong></div> </div>
                    <div className="col-md-4 border-right"> <div className="contact-stat"><span>Post Code: </span> <strong>{details?.application_details[0]?.POSTCODE ?? "Not Set"}</strong></div> </div>
                    <div className="col-md-4"> <div className="contact-stat"><span>Country Prefix: </span> <strong>{details?.application_details[0]?.COUNTRY_PREFIX ?? "Not Set"}</strong></div> </div>
                </div>
                <br />

                <div className="row">
                    <div className="col-md-4 border-right"> <div className="contact-stat"><span>Country: </span> <strong>{ details?.application_details[0]?.COUNTRY ?? "Not Set"}</strong></div> </div>
                   {/*  <div className="col-md-4 border-right"> <div className="contact-stat"><span>Fax: </span> <strong>{details?.application_details[0]?.FAX ?? "Not Set"}</strong></div> </div>
                    <div className="col-md-4"> <div className="contact-stat"><span>VAT Rate: </span> <strong>{details?.application_details[0]?.VATRATE ?? "Not Set"}</strong></div> </div> */}
                </div>
                <br />

                <div className="row">
                    <div className="col-md-4 border-right"> <div className="contact-stat"><span>What date did you move in ? : </span> <strong>{ details?.application_details[0]?.DATE_MOVED_IN_ADDRESS ?? "Not Set"}</strong></div> </div>
                    <div className="col-md-4 border-right"> <div className="contact-stat"><span>Time lived at current address : </span> <strong>{details?.application_details[0]?.DATE_MOVED_IN_ADDRESS ? getdatedif(details?.application_details[0]?.DATE_MOVED_IN_ADDRESS) : "Not Set"}</strong></div> </div>
                    <div className="col-md-4"> <div className="contact-stat"><span>Is your home owned/rented/other ? : </span> <strong>{details?.application_details[0]?.HOME_QUESTION_ANSWER ?? "Not Set"}</strong></div> </div>
                </div>
                <br />

                { details?.application_details[0]?.HOME_QUESTION_ANSWER === "rented" && 
                 (
                 <>
                  <div className="row">
                  <div className="col-md-12"> 
                  <div className="holder">
                  <p className="liner">
                    <span className="superLabels">Reented Home Details</span>
                  </p>
                  </div>
                  </div>
                  </div>
                  </>
                 )
                }

                { details?.application_details[0]?.HOME_QUESTION_ANSWER === "rented" &&
                 
                (
                  <Fragment>
                  <div className="row">
                  <div className="col-md-4 border-right"> <div className="contact-stat"><span>Landlord's Name: </span> <strong>{ details?.application_details[0]?.LANDLORD_NAME ?? "Not Set"}</strong></div> </div>
                  <div className="col-md-4 border-right"> <div className="contact-stat"><span>Landlord's Email: </span> <strong>{ details?.application_details[0]?.LANDLORD_EMAIL ?? "Not Set"}</strong></div> </div>
                  <div className="col-md-4"> <div className="contact-stat"><span>Landlord's Telephone Number: </span> <strong>{ details?.application_details[0]?.LANDLORD_MOBILE ?? "Not Set"}</strong></div> </div>
                  </div>

                  <hr/>

                  <div className="row">
                  <div className="col-md-6 border-right"> <div className="contact-stat"><span>Landlord's Address1: </span> <strong>{ details?.application_details[0]?.LANDLORD_ADDRESS1 ?? "Not Set"}</strong></div> </div>
                  <div className="col-md-6"> <div className="contact-stat"><span>Landlord's Address2: </span> <strong>{ details?.application_details[0]?.LANDLORD_ADDRESS2 ?? "Not Set"}</strong></div> </div>
                  </div>
                 
                  <hr/>

                  <div className="row">
                  <div className="col-md-4 border-right"> <div className="contact-stat"><span>Landlord's Location Name: </span> <strong>{ details?.application_details[0]?.LANDLORD_LOCATION_NAME ?? "Not Set"}</strong></div> </div>
                  <div className="col-md-4 border-right"> <div className="contact-stat"><span>Landlord's Location Code: </span> <strong>{ details?.application_details[0]?.LANDLORD_LOCATION_CODE ?? "Not Set"}</strong></div> </div>
                  <div className="col-md-4"> <div className="contact-stat"><span>Town: </span> <strong>{ details?.application_details[0]?.LANDLORD_TOWN ?? "Not Set"}</strong></div> </div>
                  </div>
                  <br />

                  <div className="row">
                  <div className="col-md-4 border-right"> <div className="contact-stat"><span>Landlord's County: </span> <strong>{ details?.application_details[0]?.LANDLORD_COUNTY ?? "Not Set"}</strong></div> </div>
                  <div className="col-md-4 border-right"> <div className="contact-stat"><span>Landlord's Post Code: </span> <strong>{ details?.application_details[0]?.LANDLORD_POSTCODE ?? "Not Set"}</strong></div> </div>
                  <div className="col-md-4"> <div className="contact-stat"><span>Landlord's Country Prefix: </span> <strong>{ details?.application_details[0]?.LANDLORD_COUNTRY_PREFIX ?? "Not Set"}</strong></div> </div>
                  </div>
                  <br />

                  <div className="row">
                  <div className="col-md-4 border-right"> <div className="contact-stat"><span>Landlord's Country: </span> <strong>{ details?.application_details[0]?.LANDLORD_COUNTRY ?? "Not Set"}</strong></div> </div>
                {/*   <div className="col-md-4 border-right"> <div className="contact-stat"><span>Landlord's Fax: </span> <strong>{ details?.application_details[0]?.LANDLORD_FAX ?? "Not Set"}</strong></div> </div>
                  <div className="col-md-4"> <div className="contact-stat"><span>Landlord's VAT Rate: </span> <strong>{ details?.application_details[0]?.LANDLORD_VRATE ?? "Not Set"}</strong></div> </div> */}
                  </div>
                  <br />
                  </Fragment>
                 )
                
                }

               { details?.application_details[0]?.HOME_QUESTION_ANSWER === "rented" && 
                 (
                 <>
                  <div className="row">
                  <div className="col-md-12"> 
                  <div className="holder">
                  <p className="liner"></p>
                  </div>
                  </div>
                  </div>
                  </>
                 )
                }

                    <br />

                    <div className="row">
                        <div className="col-md-4 border-right"> <div className="contact-stat"><span>How many bedrooms are in your house ? : </span> <strong>{ details?.application_details[0]?.NUMBER_OF_BEDROOMS ?? "Not Set"}</strong></div> </div>
                        <div className="col-md-4 border-right"> <div className="contact-stat"><span>How many other rooms are in your house ? : </span> <strong>{details?.application_details[0]?.NUMBER_OF_OTHERROOMS ?? "Not Set"}</strong></div> </div>
                        <div className="col-md-4"> <div className="contact-stat"><span>Who else lives there and what are their names ? : </span> <strong>{details?.application_details[0]?.WHO_LIVES_THERE ?? "Not Set"}</strong></div> </div>
                    </div>
                    <hr/>

                    {/* <div className="row">
                      <div className="col-md-6 border-right"> <div className="contact-stat"><span>Previous Address1: </span> <strong>{ details?.application_details[0]?.PREVIOUS_ADDRESS1 ?? "Not Set"}</strong></div> </div>
                      <div className="col-md-6"> <div className="contact-stat"><span>Previous Address2: </span> <strong>{ details?.application_details[0]?.PREVIOUS_ADDRESS2 ?? "Not Set"}</strong></div> </div>
                    </div>

                    <hr/>

                    <div className="row">
                        <div className="col-md-4 border-right"> <div className="contact-stat"><span>Previous Location name: </span> <strong>{ details?.application_details[0]?.PREVIOUS_LOCATION_NAME ?? "Not Set"}</strong></div> </div>
                        <div className="col-md-4 border-right"> <div className="contact-stat"><span>Previous Location Code : </span> <strong>{details?.application_details[0]?.PREVIOUS_LOCATION_CODE ?? "Not Set"}</strong></div> </div>
                        <div className="col-md-4"> <div className="contact-stat"><span>Previous Town : </span> <strong>{details?.application_details[0]?.TOWN_PREVIOUS ?? "Not Set"}</strong></div> </div>
                    </div>
                    <br />

                    <div className="row">
                        <div className="col-md-4 border-right"> <div className="contact-stat"><span>Previous County: </span> <strong>{ details?.application_details[0]?.COUNTY_PREVIOUS ?? "Not Set"}</strong></div> </div>
                        <div className="col-md-4 border-right"> <div className="contact-stat"><span>Previous Post Code : </span> <strong>{details?.application_details[0]?.POSTCODE_PREVIOUS ?? "Not Set"}</strong></div> </div>
                        <div className="col-md-4"> <div className="contact-stat"><span>Previous Country Prefix : </span> <strong>{details?.application_details[0]?.PREVIOUS_COUNTRY_PREFIX ?? "Not Set"}</strong></div> </div>
                    </div>
                    <br />

                    <div className="row">
                        <div className="col-md-4 border-right"> <div className="contact-stat"><span>Previous Country: </span> <strong>{ details?.application_details[0]?.COUNTRY_PREVIOUS ?? "Not Set"}</strong></div> </div>
                        <div className="col-md-4 border-right"> <div className="contact-stat"><span>Previous Fax : </span> <strong>{details?.application_details[0]?.PREVIOUS_FAX ?? "Not Set"}</strong></div> </div>
                        <div className="col-md-4"> <div className="contact-stat"><span>Previous VAT Rate : </span> <strong>{details?.application_details[0]?.PREVIOUS_VRATE ?? "Not Set"}</strong></div> </div>
                    </div>
                    <br /> */}

                    </div>
                    </div>
                    </div>

                    <div id="family" className="tab-pane">
                    <div className="panel-body" style={{marginLeft: "13%", width: "87%"}}>
                    <div className="container" style={{height:"500px", overflowY:"scroll"}}>
                    
                    <div className="row">
                        <div className="col-md-4"> <div className="contact-stat"><span>Marital Status : </span> <strong>{details?.application_details[0]?.MARITAL_STATUS ?? "Not Set"}</strong></div> </div>
                    </div>
                    <br />

                    { details?.application_details[0]?.MARITAL_STATUS !== "single" &&
                    <>
                    <div className="row">
                        <div className="col-md-4 border-right"> <div className="contact-stat"><span>Name of Spouse: </span> <strong>{ details?.application_details[0]?.NAME_OF_SPOUSE ?? "Not Set"}</strong></div> </div>
                        <div className="col-md-4 border-right"> <div className="contact-stat"><span>Where you got married : </span> <strong>{details?.application_details[0]?.WHERE_YOU_GOT_MARRIED ?? "Not Set"}</strong></div> </div>
                        <div className="col-md-4"> <div className="contact-stat"><span>Date of marriage if married : </span> <strong>{details?.application_details[0]?.DATE_OF_MARRIAGE ?? "Not Set"}</strong></div> </div>
                    </div>
                    <br />

                    <div className="row">
                        <div className="col-md-4 border-right"> <div className="contact-stat"><span>Date of birth of spouse : </span> <strong>{ details?.application_details[0]?.DOB_SPOUSE ?? "Not Set"}</strong></div> </div>
                        <div className="col-md-4 border-right"> <div className="contact-stat"><span>Nationality of spouse : </span> <strong>{details?.application_details[0]?.NATIONALITY_SPOUSE ?? "Not Set"}</strong></div> </div>
                        <div className="col-md-4"> <div className="contact-stat"><span>Passport number for spouse : </span> <strong>{details?.application_details[0]?.PASSPORT_SPOUSE ?? "Not Set"}</strong></div> </div>
                    </div>
                    <br />

                    <div className="row">
                        <div className="col-md-4 border-right"> <div className="contact-stat"><span>Where did you meet : </span> <strong>{ details?.application_details[0]?.WHERE_YOU_MET ?? "Not Set"}</strong></div> </div>
                        <div className="col-md-4 border-right"> <div className="contact-stat"><span>Where did your relationship begin : </span> <strong>{details?.application_details[0]?.WHERE_RELATIONSHIP_BEGAN ?? "Not Set"}</strong></div> </div>
                        <div className="col-md-4"> <div className="contact-stat"><span>When did you last see your partner ? : </span> <strong>{details?.application_details[0]?.WHEN_LAST_YOU_SAW_EACHOTHER ?? "Not Set"}</strong></div> </div>
                    </div>
                  
                    <br/>

                  <div className="row">
                    <div className="col-md-4 border-right"> <div className="contact-stat"><span>Do you live together ? : </span> <strong>{ details?.application_details[0]?.LIVE_TOGETHER_QUESTION ?? "Not Set"}</strong></div> </div>
                    <div className="col-md-4 border-right"> <div className="contact-stat"><span>What date did you start living together ? : </span> <strong>{ details?.application_details[0]?.DATE_LIVING_TOGETHER ?? "Not Set"}</strong></div> </div>
                    <div className="col-md-4"> <div className="contact-stat"><span>Do you have any children ? : </span> <strong>{details?.application_details[0]?.DO_YOU_HAVE_CHILDREN ?? "Not Set"}</strong></div> </div>
                  </div>

                  <br/>


                { details?.application_details[0]?.DO_YOU_HAVE_CHILDREN === "Yes" && 
                 (
                  <>
                   <div className="row">
                    <div className="col-md-4 border-right"> <div className="contact-stat"><span>How many dependant children do you have ? : </span> <strong>{ details?.application_details[0]?.NUMBER_OF_DEPENDENT_CHILDREN ?? "Not Set"}</strong></div> </div>
                  </div>
                  </>
                  )
                 }

                  { details?.application_details[0]?.DO_YOU_HAVE_CHILDREN === "Yes" && 
                 (
                 <>
                  <div className="row">
                  <div className="col-md-12"> 
                  <div className="holder">
                  <p className="liner">
                    <span className="superLabels">Children</span>
                  </p>
                  </div>
                  </div>
                  </div>
                  </>
                 )
                }

                { details?.application_details[0]?.DO_YOU_HAVE_CHILDREN === "Yes" &&
                 
                 details?.children_details[0]?.ATTRIBUTES ? (JSON.parse(details?.children_details[0]?.ATTRIBUTES).length > 0) ?
                 
                 JSON.parse(details?.children_details[0]?.ATTRIBUTES).map((children,i) => 
                 (
                  <Fragment key={i}>
                  <div className="row">
                  <div className="col-md-4 border-right"> <div className="contact-stat"><span>Child's Name: </span> <strong>{children[`childname_${i}`] ?? "Not Set"}</strong></div> </div>
                  <div className="col-md-4 border-right"> <div className="contact-stat"><span>Gender: </span> <strong>{ children[`gender_${i}`] ?? "Not Set"}</strong></div> </div>
                  <div className="col-md-4"> <div className="contact-stat"><span>Date of Birth: </span> <strong>{ children[`dob_${i}`] ?? "Not Set"}</strong></div> </div>
                  </div>
                  <hr />

                  <div className="row">
                  <div className="col-md-3 border-right"> <div className="contact-stat"><span>Nationality of Child : </span> <strong>{children[`childnationality_${i}`] ?? "Not Set"}</strong></div> </div>
                  <div className="col-md-3 border-right"> <div className="contact-stat"><span>Passport Number: </span> <strong>{ children[`passportno_${i}`] ?? "Not Set"}</strong></div> </div>
                  <div className="col-md-3 border-right"> <div className="contact-stat"><span>Passport Issued Date: </span> <strong>{ children[`passportissueddate_${i}`] ?? "Not Set"}</strong></div> </div>
                  <div className="col-md-3"> <div className="contact-stat"><span>Passport Expiry Date: </span> <strong>{ children[`passportexpiry_${i}`] ?? "Not Set"}</strong></div> </div>
                  </div>
                  <hr />

                  <div className="row">
                  <div className="col-md-4 border-right"> <div className="contact-stat"><span>Visa Type : </span> <strong>{children[`visatype_${i}`] ?? "Not Set"}</strong></div> </div>
                  <div className="col-md-4 border-right"> <div className="contact-stat"><span>Visa Issued Date : </span> <strong>{ children[`visaissueddate_${i}`] ?? "Not Set"}</strong></div> </div>
                  <div className="col-md-4"> <div className="contact-stat"><span>Visa Expiry Date : </span> <strong>{ children[`visaexpiry_${i}`] ?? "Not Set"}</strong></div> </div>
                  </div>
                  <br />
                  </Fragment>
                 )
                 )

                 :("")

                 :("")
                }

               { details?.application_details[0]?.DO_YOU_HAVE_CHILDREN === "Yes" && 
                 (
                 <>
                  <div className="row">
                  <div className="col-md-12"> 
                  <div className="holder">
                  <p className="liner"></p>
                  </div>
                  </div>
                  </div>
                  </>
                 )
                }

                <div className="row">
                    <div className="col-md-6"> <div className="contact-stat"><span>Have you been married before ? : </span> <strong>{details?.application_details[0]?.MARRIED_BEFORE_QUESTION ?? "Not Set"}</strong></div> </div>
                </div>
                <br />

                { details?.application_details[0]?.MARRIED_BEFORE_QUESTION === "Yes" && 
                 (
                 <>
                  <div className="row">
                  <div className="col-md-12"> 
                  <div className="holder">
                  <p className="liner">
                    <span className="superLabels">Previous Partner(s)</span>
                  </p>
                  </div>
                  </div>
                  </div>
                  </>
                 )
                }

                { details?.application_details[0]?.MARRIED_BEFORE_QUESTION === "Yes" &&
                 
                 details?.previous_marriage_details[0]?.PREVIOUS_MARRIAGE ? (JSON.parse(details?.previous_marriage_details[0]?.PREVIOUS_MARRIAGE).length > 0) ?
                 
                 JSON.parse(details?.previous_marriage_details[0]?.PREVIOUS_MARRIAGE).map((previousMarriage,i) => 
                 (
                  <Fragment key={i}>
                  <div className="row">
                  <div className="col-md-6 border-right"> <div className="contact-stat"><span>Name of Ex-Partner: </span> <strong>{previousMarriage[`exname_${i}`] ?? "Not Set"}</strong></div> </div>
                  <div className="col-md-6"> <div className="contact-stat"><span>Date of Birth: </span> <strong>{ previousMarriage[`exdob_${i}`] ?? "Not Set"}</strong></div> </div>
                  </div>
                  <hr />

                  <div className="row">
                  <div className="col-md-4 border-right"> <div className="contact-stat"><span>Nationality of Ex-Partner : </span> <strong>{previousMarriage[`exnationality_${i}`] ?? "Not Set"}</strong></div> </div>
                  <div className="col-md-4 border-right"> <div className="contact-stat"><span>Date of Marriage: </span> <strong>{ previousMarriage[`exdateofmarriage_${i}`] ?? "Not Set"}</strong></div> </div>
                  <div className="col-md-4 "> <div className="contact-stat"><span>Date of Divorce: </span> <strong>{ previousMarriage[`exdateofdivorce_${i}`] ?? "Not Set"}</strong></div> </div>
                  </div>
                  <br />
                  </Fragment>
                 )
                 )

                 :("")

                 :("")
                }

               { details?.application_details[0]?.MARRIED_BEFORE_QUESTION === "Yes" && 
                 (
                 <>
                  <div className="row">
                  <div className="col-md-12"> 
                  <div className="holder">
                  <p className="liner"></p>
                  </div>
                  </div>
                  </div>
                  </>
                 )
                }


                <div className="row">
                    <div className="col-md-6"> <div className="contact-stat"><span>Has your current partner been married before ? : </span> <strong>{details?.application_details[0]?.PARTNER_MARRIED_BEFORE ?? "Not Set"}</strong></div> </div>
                </div>
                <br />

                { details?.application_details[0]?.PARTNER_MARRIED_BEFORE === "Yes" && 
                 (
                 <>
                  <div className="row">
                  <div className="col-md-12"> 
                  <div className="holder">
                  <p className="liner">
                    <span className="superLabels">Current Partner's Ex</span>
                  </p>
                  </div>
                  </div>
                  </div>
                  </>
                 )
                }

                { details?.application_details[0]?.PARTNER_MARRIED_BEFORE === "Yes" &&
                 
                 details?.partner_married_before_details[0]?.PARTNERS_EX ? (JSON.parse(details?.partner_married_before_details[0]?.PARTNERS_EX).length > 0) ?
                 
                 JSON.parse(details?.partner_married_before_details[0]?.PARTNERS_EX).map((partnerExes,i) => 
                 (
                  <Fragment key={i}>
                  <div className="row">
                  <div className="col-md-6 border-right"> <div className="contact-stat"><span>Name of Ex-Partner: </span> <strong>{partnerExes[`partnersExName_${i}`] ?? "Not Set"}</strong></div> </div>
                  <div className="col-md-6"> <div className="contact-stat"><span>Date of Birth: </span> <strong>{ partnerExes[`partnersExDob_${i}`] ?? "Not Set"}</strong></div> </div>
                  </div>
                  <hr />

                  <div className="row">
                  <div className="col-md-4 border-right"> <div className="contact-stat"><span>Nationality of Ex-Partner : </span> <strong>{partnerExes[`partnersExNationality_${i}`] ?? "Not Set"}</strong></div> </div>
                  <div className="col-md-4 border-right"> <div className="contact-stat"><span>Date of Marriage: </span> <strong>{ partnerExes[`partnersExDateofmarriage_${i}`] ?? "Not Set"}</strong></div> </div>
                  <div className="col-md-4 "> <div className="contact-stat"><span>Date of Divorce: </span> <strong>{ partnerExes[`partnersExDateofdivorce_${i}`] ?? "Not Set"}</strong></div> </div>
                  </div>
                  <br />
                  </Fragment>
                 )
                 )

                 :("")

                 :("")
                }

               { details?.application_details[0]?.PARTNER_MARRIED_BEFORE === "Yes" && 
                 (
                 <>
                  <div className="row">
                  <div className="col-md-12"> 
                  <div className="holder">
                  <p className="liner"></p>
                  </div>
                  </div>
                  </div>
                  </>
                 )
                }
        
               </>
            }
                <div className="row">
                    <div className="col-md-6"> <div className="contact-stat"><span>Do you have any family/friends in your home country ? : </span> <strong>{details?.application_details[0]?.DO_YOU_HAVE_FAMILY_IN_HOME_COUNTRY ?? "Not Set"}</strong></div> </div>
                </div>
                <br />

                { details?.application_details[0]?.DO_YOU_HAVE_FAMILY_IN_HOME_COUNTRY === "Yes" && 
                 (
                 <>
                  <div className="row">
                  <div className="col-md-12"> 
                  <div className="holder">
                  <p className="liner">
                    <span className="superLabels">Family & Friends at Home Country</span>
                  </p>
                  </div>
                  </div>
                  </div>
                  </>
                 )
                }

                { details?.application_details[0]?.DO_YOU_HAVE_FAMILY_IN_HOME_COUNTRY === "Yes" &&
                 
                 details?.names_of_people_at_address_details[0]?.ATTRIBUTES ? (JSON.parse(details?.names_of_people_at_address_details[0]?.ATTRIBUTES).length > 0) ?
                 
                 JSON.parse(details?.names_of_people_at_address_details[0]?.ATTRIBUTES).map((familyFriends,i) => 
                 (
                  <Fragment key={i}>
                  <div className="row">
                  <div className="col-md-6 border-right"> <div className="contact-stat"><span>Name of Relative: </span> <strong>{familyFriends[`relativeName_${i}`] ?? "Not Set"}</strong></div> </div>
                  <div className="col-md-6"> <div className="contact-stat"><span>Relationship: </span> <strong>{ familyFriends[`relationship_${i}`] ?? "Not Set"}</strong></div> </div>
                  </div>              
                  <br />
                  </Fragment>
                 )
                 )

                 :("")

                 :("")
                }

               { details?.application_details[0]?.DO_YOU_HAVE_FAMILY_IN_HOME_COUNTRY === "Yes" && 
                 (
                 <>
                  <div className="row">
                  <div className="col-md-12"> 
                  <div className="holder">
                  <p className="liner"></p>
                  </div>
                  </div>
                  </div>
                  </>
                 )
                }
                </div>
                </div>
                </div>

                <div id="education" className="tab-pane">
                <div className="panel-body" style={{marginLeft: "13%", width: "87%"}}>
                
                <div className="container" style={{height:"500px", overflowY:"scroll"}}>

                <div className="row">
                  <div className="col-md-4 border-right"> <div className="contact-stat"><span>Do you have a degree taught in English ? : </span> <strong>{details?.application_details[0]?.HAVE_DEGREE_IN_ENGLISH ?? "Not Set"}</strong></div> </div>
                  <div className="col-md-4 border-right"> <div className="contact-stat"><span>Have you passed any English Test ? : </span> <strong>{ details?.application_details[0]?.PASSED_RECOGNIZED_TEST ?? "Not Set"}</strong></div> </div>
                  <div className="col-md-4 "> <div className="contact-stat"><span>Test passed ? : </span> <strong>{ details?.application_details[0]?.WHAT_TEST_DID_YOU_PASS ?? "Not Set"}</strong></div> </div>
                  </div>
                  <br />

                { details?.application_details[0]?.WHAT_TEST_DID_YOU_PASS === "Other" && 
                 (
                 <>
                  <div className="row">
                  <div className="col-md-4 border-right"> <div className="contact-stat"><span>Other Test passed : </span> <strong>{details?.application_details[0]?.OTHER_CERTIFICATE ?? "Not Set"}</strong></div> </div>
                  </div>
                  <br />
                  </>
                 )
                }

                </div>
                </div>
                </div>

                <div id="qualification" className="tab-pane">
                <div className="panel-body" style={{marginLeft: "13%", width: "87%"}}>
                
                <div className="container" style={{height:"500px", overflowY:"scroll"}}>

                <div className="row">
                  <div className="col-md-4 "> <div className="contact-stat"><span>Do you have any other qualification ? : </span> <strong>{ details?.application_details[0]?.QUALIFICATION_QUESTION ?? "Not Set"}</strong></div> </div>
                  </div>
                  <br />



                { details?.application_details[0]?.QUALIFICATION_QUESTION === "Yes" && 
                 (
                 <>
                  <div className="row">
                  <div className="col-md-12"> 
                  <div className="holder">
                  <p className="liner">
                    <span className="superLabels">Qualification(s)</span>
                  </p>
                  </div>
                  </div>
                  </div>
                  </>
                 )
                }

                { details?.application_details[0]?.QUALIFICATION_QUESTION === "Yes" &&
                
                details?.qualification_details[0]?.QUALIFICATION ? (JSON.parse(details?.qualification_details[0]?.QUALIFICATION).length > 0) ?
                 
                 JSON.parse(details?.qualification_details[0]?.QUALIFICATION).map((qualify,i) => 
                 (
                  <Fragment key={i}>
                  <div className="row">
                  <div className="col-md-4 border-right"> <div className="contact-stat"><span>Qualification : </span> <strong>{qualify[`qualification_${i}`] ?? "Not Set"}</strong></div> </div>
                  <div className="col-md-4 border-right"> <div className="contact-stat"><span>Awarding Institute : </span> <strong>{qualify[`awardinginstitute_${i}`] ?? "Not Set"}</strong></div> </div>
                  <div className="col-md-4 "> <div className="contact-stat"><span>Grade : </span> <strong>{qualify[`grade_${i}`] ?? "Not Set"}</strong></div> </div>
                  </div>
                  <hr />

                  <div className="row">
                  <div className="col-md-4 border-right"> <div className="contact-stat"><span>Course Subject : </span> <strong>{qualify[`coursesubj_${i}`] ?? "Not Set"}</strong></div> </div>
                  <div className="col-md-4 border-right"> <div className="contact-stat"><span>Course Length : </span> <strong>{qualify[`courselen_${i}`] ?? "Not Set"}</strong></div> </div>
                  <div className="col-md-4 "> <div className="contact-stat"><span>Year of Award : </span> <strong>{qualify[`yearofaward_${i}`] ?? "Not Set"}</strong></div> </div>
                  </div>
                  <hr />
                  
                  <div className="row">
                  <div className="col-md-6 border-right"> <div className="contact-stat"><span>Country of Award : </span> <strong>{qualify[`countryofaward_${i}`] ?? "Not Set"}</strong></div> </div>
                  <div className="col-md-6"> <div className="contact-stat"><span>State : </span> <strong>{ qualify[`countrystate_${i}`] ?? "Not Set"}</strong></div> </div>
                  </div>  

                  {i < JSON.parse(details?.qualification_details[0]?.QUALIFICATION).length ? <hr /> : <br /> }
                  </Fragment>
                 )
                 )

                 :("")

                 :("")
                }

                { details?.application_details[0]?.QUALIFICATION_QUESTION === "Yes" && 
                (
                  <>
                   <div className="row">
                   <div className="col-md-12"> 
                   <div className="holder">
                   <p className="liner"></p>
                   </div>
                   </div>
                   </div>
                   </>
                  )
                }

                </div>
                </div>
                </div>

                <div id="employment" className="tab-pane">
                <div className="panel-body" style={{marginLeft: "13%", width: "87%"}}>
                
                <div className="container" style={{height:"500px", overflowY:"scroll"}}>

                <div className="row">
                    <div className="col-md-6"> <div className="contact-stat"><span>Employment Status : </span> <strong>{details?.employment_details[0]?.EMPLOYMENT_STATUS ?? "Not Set"}</strong></div> </div>
                </div>
                <br />

                { (details?.employment_details[0]?.EMPLOYMENT_STATUS === "Employed" || details?.employment_details[0]?.EMPLOYMENT_STATUS === "Self-Employed")   && 
                 (
                 <>
                  <div className="row">
                  <div className="col-md-12"> 
                  <div className="holder">
                  <p className="liner">
                    <span className="superLabels">Employment Details</span>
                  </p>
                  </div>
                  </div>
                  </div>
                  </>
                 )
                }

                { (details?.employment_details[0]?.EMPLOYMENT_STATUS === "Employed" || details?.employment_details[0]?.EMPLOYMENT_STATUS === "Self-Employed")   && 
                 
                
                  <Fragment>
                  <div className="row">
                  <div className="col-md-4 border-right"> <div className="contact-stat"><span> When did you start your job ? : </span> <strong>{details?.employment_details[0]?.JOB_START_DATE ?? "Not Set"}</strong></div> </div>
                  <div className="col-md-4 border-right"> <div className="contact-stat"><span> What is the name of your employer ?: </span> <strong>{ details?.employment_details[0]?.EMPLOYER_NAME ?? "Not Set"}</strong></div> </div>
                  <div className="col-md-4 "> <div className="contact-stat"><span> Telephone number of your employer : </span> <strong>{ details?.employment_details[0]?.EMPLOYER_PHONE ?? "Not Set"}</strong></div> </div>
                  </div>
                  <br />

                  <div className="row">
                  <div className="col-md-4 border-right"> <div className="contact-stat"><span> Email address of your employer : </span> <strong>{details?.employment_details[0]?.EMPLOYER_EMAIL ?? "Not Set"}</strong></div> </div>
                  <div className="col-md-4 border-right"> <div className="contact-stat"><span> What is your annual salary : </span> <strong>{ details?.employment_details[0]?.SALARY ?? "Not Set"}</strong></div> </div>
                  <div className="col-md-4 "> <div className="contact-stat"><span>What is your job title ? : </span> <strong>{ details?.employment_details[0]?.JOB_TITLE ?? "Not Set"}</strong></div> </div>
                  </div>
                  <hr />

                  <div className="row">
                  <div className="col-md-6 border-right"> <div className="contact-stat"><span>Address 1 : </span> <strong>{details?.employment_details[0]?.EMPLOYER_ADDRESS1 ?? "Not Set"}</strong></div> </div>
                  <div className="col-md-6"> <div className="contact-stat"><span>Address 2 : </span> <strong>{ details?.employment_details[0]?.EMPLOYER_ADDRESS2 ?? "Not Set"}</strong></div> </div>
                  </div>
                  <hr />

                  <div className="row">
                  <div className="col-md-4 border-right"> <div className="contact-stat"><span>Location Name : </span> <strong>{details?.employment_details[0]?.EMPLOYER_LOCATION ?? "Not Set"}</strong></div> </div>
                  <div className="col-md-4 border-right"> <div className="contact-stat"><span>Location Code : </span> <strong>{ details?.employment_details[0]?.EMPLOYER_LOCATION_CODE ?? "Not Set"}</strong></div> </div>
                  <div className="col-md-4 "> <div className="contact-stat"><span>Town : </span> <strong>{ details?.employment_details[0]?.EMPLOYER_TOWN ?? "Not Set"}</strong></div> </div>
                  </div>
                  <br />

                  <div className="row">
                  <div className="col-md-4 border-right"> <div className="contact-stat"><span>County : </span> <strong>{details?.employment_details[0]?.EMPLOYER_COUNTY ?? "Not Set"}</strong></div> </div>
                  <div className="col-md-4 border-right"> <div className="contact-stat"><span>Post Code : </span> <strong>{ details?.employment_details[0]?.EMPLOYER_POSTCODE ?? "Not Set"}</strong></div> </div>
                  <div className="col-md-4 "> <div className="contact-stat"><span>Country Prefix : </span> <strong>{ details?.employment_details[0]?.EMPLOYER_COUNTRYPREFIX ?? "Not Set"}</strong></div> </div>
                  </div>
                  <br />

                  <div className="row">
                  <div className="col-md-4 border-right"> <div className="contact-stat"><span>Country : </span> <strong>{details?.employment_details[0]?.EMPLOYER_COUNTRY ?? "Not Set"}</strong></div> </div>
                 {/*  <div className="col-md-4 border-right"> <div className="contact-stat"><span>Fax : </span> <strong>{ details?.employment_details[0]?.EMPLOYER_FAX ?? "Not Set"}</strong></div> </div>
                  <div className="col-md-4 "> <div className="contact-stat"><span>VAT Rate : </span> <strong>{ details?.employment_details[0]?.EMPLOYER_VATRATE ?? "Not Set"}</strong></div> </div> */}
                  </div>
                  <br />
                  </Fragment>
                
                }

                { (details?.employment_details[0]?.EMPLOYMENT_STATUS === "Employed" || details?.employment_details[0]?.EMPLOYMENT_STATUS === "Self-Employed")   && 
                 (
                 <>
                  <div className="row">
                  <div className="col-md-12"> 
                  <div className="holder">
                  <p className="liner"></p>
                  </div>
                  </div>
                  </div>
                  </>
                 )
                }

                </div>
                </div>
                </div>

               {/*  <div id="membership" className="tab-pane">
                <div className="panel-body" style={{marginLeft: "13%", width: "87%"}}>
                
                <div className="container" style={{height:"500px", overflowY:"scroll"}}>

                {details?.membership_details[0]?.ATTRIBUTES ? (JSON.parse(details?.membership_details[0]?.ATTRIBUTES).length > 0) ?
                 
                 JSON.parse(details?.membership_details[0]?.ATTRIBUTES).map((member,i) => 
                 (
                  <Fragment key={i}>
                  <div className="row">
                  <div className="col-md-6 border-right"> <div className="contact-stat"><span>Membership Type : </span> <strong>{member[`memshiptype_${i}`] ?? "Not Set"}</strong></div> </div>
                  <div className="col-md-6 "> <div className="contact-stat"><span>Membership Name : </span> <strong>{member[`memshipname_${i}`] ?? "Not Set"}</strong></div> </div>
                  </div>
                  <br />

                  <div className="row">
                  <div className="col-md-6 border-right"> <div className="contact-stat"><span>Issued Date : </span> <strong>{member[`memshipissued_${i}`] ?? "Not Set"}</strong></div> </div>
                  <div className="col-md-6 "> <div className="contact-stat"><span>Expiry Date : </span> <strong>{member[`memshipexpiry_${i}`] ?? "Not Set"}</strong></div> </div>
                  </div>

                  {i < JSON.parse(details?.membership_details[0]?.ATTRIBUTES).length ? <hr /> : <br /> }
                  </Fragment>
                 )
                 )

                 :("")

                 :("")
                }

                </div>
                </div>
                </div> */}

                <div id="maintenance" className="tab-pane">
                <div className="panel-body" style={{marginLeft: "13%", width: "87%"}}>
                
                <div className="container" style={{height:"500px", overflowY:"scroll"}}>

                <div className="row">
                  <div className="col-md-4 border-right"> <div className="contact-stat"><span> Name of Bank : </span> <strong>{details?.maintenance_details[0]?.BANK_NAME ?? "Not Set"}</strong></div> </div>
                  <div className="col-md-4 border-right"> <div className="contact-stat"><span> Is it a registered and recognized financial institute ? : </span> <strong>{ details?.maintenance_details[0]?.REGISTERED ?? "Not Set"}</strong></div> </div>
                  <div className="col-md-4 "> <div className="contact-stat"><span> Country funds held in : </span> <strong>{ details?.maintenance_details[0]?.HELD_COUNTRY ?? "Not Set"}</strong></div> </div>
                  </div>
                  <br />

                  <div className="row">
                  <div className="col-md-4 border-right"> <div className="contact-stat"><span> Currency funds held in : </span> <strong>{details?.maintenance_details[0]?.HELD_CURRENCY ?? "Not Set"}</strong></div> </div>
                  <div className="col-md-4 border-right"> <div className="contact-stat"><span> Amount held : </span> <strong>{ details?.maintenance_details[0]?.HELD_AMOUNT ?? "Not Set"}</strong></div> </div>
                  <div className="col-md-4 "> <div className="contact-stat"><span>Date the funds have been held from : </span> <strong>{ details?.maintenance_details[0]?.HELD_DATE ?? "Not Set"}</strong></div> </div>
                  </div>
                  <hr />

                </div>
                </div>
                </div>

                <div id="travel" className="tab-pane">
                <div className="panel-body" style={{marginLeft: "13%", width: "87%"}}>
                
                <div className="container" style={{height:"500px", overflowY:"scroll"}}>

                <div className="row">
                    <div className="col-md-6"> <div className="contact-stat"><span>Are you currently in the UK ? : </span> <strong>{details?.application_details[0]?.ARE_YOU_IN_UK ?? "Not Set"}</strong></div> </div>
                </div>
                <br />

                { details?.application_details[0]?.ARE_YOU_IN_UK === "Yes" && 
                 (
                 <>
                  <div className="row">
                  <div className="col-md-12"> 
                  <div className="holder">
                  <p className="liner">
                    <span className="superLabels">UK Details</span>
                  </p>
                  </div>
                  </div>
                  </div>
                  </>
                 )
                }

               
                  <Fragment>
                  <div className="row">
                  <div className="col-md-6 border-right"> <div className="contact-stat"><span> When did you enter the UK if you are applying in-country ? : </span> <strong>{details?.application_details[0]?.WHEN_DID_YOU_ENTER_UK ?? "Not Set"}</strong></div> </div>
                  <div className="col-md-6"> <div className="contact-stat"><span>Did you enter legally ? : </span> <strong>{ details?.application_details[0]?.DID_YOU_ENTER_LEGALLY ?? "Not Set"}</strong></div> </div>
                  </div>              
                  <hr />

                  <div className="row">
                  <div className="col-md-6 border-right"> <div className="contact-stat"><span> When did your visa start ? : </span> <strong>{details?.application_details[0]?.VISA_START_DATE ?? "Not Set"}</strong></div> </div>
                  <div className="col-md-6"> <div className="contact-stat"><span>When does your visa expire ? : </span> <strong>{ details?.application_details[0]?.VISA_END_DATE ?? "Not Set"}</strong></div> </div>
                  </div>              
                  <hr />

                  <div className="row">
                  <div className="col-md-6 border-right"> <div className="contact-stat"><span> What is your current visa status ? : </span> <strong>{details?.application_details[0]?.VISA_STATUS ?? "Not Set"}</strong></div> </div>
                  <div className="col-md-6"> <div className="contact-stat"><span>Have you left the UK since you have been living in the UK ? : </span> <strong>{ details?.application_details[0]?.OUT_OF_THE_UK_BEFORE ?? "Not Set"}</strong></div> </div>
                  </div>              
                  <br />
                  </Fragment>

                { details?.application_details[0]?.OUT_OF_THE_UK_BEFORE === "Yes" && 
                 (
                 <>
                  <div className="row">
                  <div className="col-md-12"> 
                  <div className="holder">
                  <p className="liner">
                    <span className="superLabels">Countries Visited</span>
                  </p>
                  </div>
                  </div>
                  </div>
                  </>
                 )
                }

                 {details?.location_details[0]?.COUNTRIES_TRAVELLED ? (JSON.parse(details?.location_details[0]?.COUNTRIES_TRAVELLED).length > 0) ?
                 
                 JSON.parse(details?.location_details[0]?.COUNTRIES_TRAVELLED).map((ctravel,i) => 
                 (
                  <Fragment key={i}>
                  <div className="row">
                  <div className="col-md-4 border-right"> <div className="contact-stat"><span>Country Visited : </span> <strong>{ctravel[`countryname_${i}`] ?? "Not Set"}</strong></div> </div>
                  <div className="col-md-4 border-right"> <div className="contact-stat"><span>What date did you leave the UK ? : </span> <strong>{ctravel[`dateoftravel_${i}`] ?? "Not Set"}</strong></div> </div>
                  <div className="col-md-4"> <div className="contact-stat"><span>What date did you return : </span> <strong>{ctravel[`dateofreturn_${i}`] ?? "Not Set"}</strong></div> </div>
                  </div>
                  <br />

                  <div className="row">
                  <div className="col-md-12 "> <div className="contact-stat"><span>Reason for your visit : </span> <strong>{ctravel[`reasonfortravel_${i}`] ?? "Not Set"}</strong></div> </div>
                  </div>

                  {(i+1) < JSON.parse(details?.location_details[0]?.COUNTRIES_TRAVELLED).length ? <hr /> : <br /> }
                  </Fragment>
                 )
                 )

                 :("")

                 :("")
                }
                

               { details?.application_details[0]?.ARE_YOU_IN_UK === "Yes" && 
                 (
                 <>
                  <div className="row">
                  <div className="col-md-12"> 
                  <div className="holder">
                  <p className="liner"></p>
                  </div>
                  </div>
                  </div>
                  </>
                 )
                }

                <br />

              { details?.location_details[0]?.LAST_FIVE_VISITS !== "" && 
                 (
                 <>
                  <div className="row">
                  <div className="col-md-12"> 
                  <div className="holder">
                  <p className="liner">
                    <span className="superLabels">Last Five Visits to the UK</span>
                  </p>
                  </div>
                  </div>
                  </div>
                  </>
                 )
                }

                 {details?.location_details[0]?.LAST_FIVE_VISITS ? (JSON.parse(details?.location_details[0]?.LAST_FIVE_VISITS).length > 0) ?
                 
                 JSON.parse(details?.location_details[0]?.LAST_FIVE_VISITS).map((lastfivevisits,i) => 
                 (
                  <Fragment key={i}>
                  <div className="row">
                  <div className="col-md-6 border-right"> <div className="contact-stat"><span>Date of Entry : </span> <strong>{lastfivevisits[`dateofentry_${i}`] ?? "Not Set"}</strong></div> </div>
                  <div className="col-md-6 border-right"> <div className="contact-stat"><span>Date of Departure ? : </span> <strong>{lastfivevisits[`dateofdeparture_${i}`] ?? "Not Set"}</strong></div> </div>
                  </div>
                  <hr />

                  <div className="row">
                  <div className="col-md-12 "> <div className="contact-stat"><span>Reason for Visit : </span> <strong>{lastfivevisits[`reasonforvisit_${i}`] ?? "Not Set"}</strong></div> </div>
                  </div>

                  {(i+1) < JSON.parse(details?.location_details[0]?.LAST_FIVE_VISITS).length ? <hr /> : <br /> }
                  </Fragment>
                 )
                 )

                 :("")

                 :("")
                }
                

                { details?.location_details[0]?.LAST_FIVE_VISITS !== "" && 
                 (
                 <>
                  <div className="row">
                  <div className="col-md-12"> 
                  <div className="holder">
                  <p className="liner"></p>
                  </div>
                  </div>
                  </div>
                  </>
                 )
                }


                <div className="row">
                  <div className="col-md-5 border-right"> <div className="contact-stat"><span>Have you ever entered the UK illegally ? : </span> <strong>{details?.application_details[0]?.ENTERED_UK_MEANS ?? "Not Set"}</strong></div> </div>
                  <div className="col-md-7 border-right"> <div className="contact-stat"><span>Reason ? : </span> <strong>{details?.application_details[0]?.REASON_FOR_ILEGAL_ENTRY ?? "Not Set"}</strong></div> </div>
                </div>
                  <hr />

                  <div className="row">
                  <div className="col-md-5 border-right"> <div className="contact-stat"><span>Have you ever stayed in any country, including the UK, without a VISA or after your VISA has expired ? : </span> <strong>{details?.application_details[0]?.EVER_STAYED_BEYOND_EXPIRY ?? "Not Set"}</strong></div> </div>
                  <div className="col-md-7 border-right"> <div className="contact-stat"><span>Reason ? : </span> <strong>{details?.application_details[0]?.REASON_FOR_STAYING_BEYOND_EXPIRY ?? "Not Set"}</strong></div> </div>
                </div>
                  <hr />

                  <div className="row">
                  <div className="col-md-5 border-right"> <div className="contact-stat"><span>Have you ever breached the conditions of your leave ? : </span> <strong>{details?.application_details[0]?.BREACHED_CONDITION_FOR_LEAVE ?? "Not Set"}</strong></div> </div>
                  <div className="col-md-7 border-right"> <div className="contact-stat"><span>Reason ? : </span> <strong>{details?.application_details[0]?.REASON_FOR_BREACH ?? "Not Set"}</strong></div> </div>
                </div>
                  <hr />

                  <div className="row">
                  <div className="col-md-5 border-right"> <div className="contact-stat"><span>Have you ever worked without permission ? : </span> <strong>{details?.application_details[0]?.WORK_WITHOUT_PERMIT ?? "Not Set"}</strong></div> </div>
                  <div className="col-md-7 border-right"> <div className="contact-stat"><span>Reason ? : </span> <strong>{details?.application_details[0]?.REASON_FOR_WORK_WITHOUT_PERMIT ?? "Not Set"}</strong></div> </div>
                </div>
                  <hr />

                  <div className="row">
                  <div className="col-md-5 border-right"> <div className="contact-stat"><span>Have you ever received public funds ? : </span> <strong>{details?.application_details[0]?.RECEIVED_PUBLIC_FUNDS ?? "Not Set"}</strong></div> </div>
                  <div className="col-md-7 border-right"> <div className="contact-stat"><span>Reason ? : </span> <strong>{details?.application_details[0]?.REASON_RECEIVING_FUNDS ?? "Not Set"}</strong></div> </div>
                </div>
                  <hr />

                  <div className="row">
                  <div className="col-md-5 border-right"> <div className="contact-stat"><span>Have you ever given false information when applying for a visa ? : </span> <strong>{details?.application_details[0]?.GIVE_FALSE_INFO ?? "Not Set"}</strong></div> </div>
                  <div className="col-md-7 border-right"> <div className="contact-stat"><span>Reason ? : </span> <strong>{details?.application_details[0]?.REASON_FOR_FALSE_INFO ?? "Not Set"}</strong></div> </div>
                </div>
                  <hr />

                  <div className="row">
                  <div className="col-md-5 border-right"> <div className="contact-stat"><span>Have you ever used deception in a previous visa application ? : </span> <strong>{details?.application_details[0]?.USED_DECEPTION ?? "Not Set"}</strong></div> </div>
                  <div className="col-md-7 border-right"> <div className="contact-stat"><span>Reason ? : </span> <strong>{details?.application_details[0]?.REASON_FOR_DECEPTION ?? "Not Set"}</strong></div> </div>
                </div>
                  <hr />

                  <div className="row">
                  <div className="col-md-5 border-right"> <div className="contact-stat"><span>Have you ever breached any other immigration law ? : </span> <strong>{details?.application_details[0]?.BREACHED_OTHER_LAWS ?? "Not Set"}</strong></div> </div>
                  <div className="col-md-7 border-right"> <div className="contact-stat"><span>Reason ? : </span> <strong>{details?.application_details[0]?.REASON_FOR_BREACHING__LAWS ?? "Not Set"}</strong></div> </div>
                </div>
                  <hr />

                  <div className="row">
                  <div className="col-md-5 border-right"> <div className="contact-stat"><span>Have you ever been refused a visa or refused entry at the border ? : </span> <strong>{details?.application_details[0]?.VISA_REFUSAL_QUESTION ?? "Not Set"}</strong></div> </div>
                  <div className="col-md-7 border-right"> <div className="contact-stat"><span>Reason ? : </span> <strong>{details?.application_details[0]?.REASON_FOR_REFUSAL ?? "Not Set"}</strong></div> </div>
                </div>
                  <hr />

                  <div className="row">
                  <div className="col-md-5 border-right"> <div className="contact-stat"><span>Have you ever been refused permission to stay or remain ? : </span> <strong>{details?.application_details[0]?.PERMISSION_REFUSAL ?? "Not Set"}</strong></div> </div>
                  <div className="col-md-7 border-right"> <div className="contact-stat"><span>Reason ? : </span> <strong>{details?.application_details[0]?.REASON_FOR_PERMISSION_REFUSAL ?? "Not Set"}</strong></div> </div>
                </div>
                  <hr />

                  <div className="row">
                  <div className="col-md-5 border-right"> <div className="contact-stat"><span>Have you ever been refused asylum ? : </span> <strong>{details?.application_details[0]?.ASYLUM_REFUSAL ?? "Not Set"}</strong></div> </div>
                  <div className="col-md-7 border-right"> <div className="contact-stat"><span>Reason ? : </span> <strong>{details?.application_details[0]?.REASON_FOR_ASYLUM_REFUSAL ?? "Not Set"}</strong></div> </div>
                </div>
                  <hr />

                  <div className="row">
                  <div className="col-md-5 border-right"> <div className="contact-stat"><span>Have you ever been deported, removed or been required to leave any country ? : </span> <strong>{details?.application_details[0]?.EVER_DEPORTED ?? "Not Set"}</strong></div> </div>
                  <div className="col-md-7 border-right"> <div className="contact-stat"><span>Reason ? : </span> <strong>{details?.application_details[0]?.REASON_FOR_DEPORTATION ?? "Not Set"}</strong></div> </div>
                </div>
                  <hr />

                  <div className="row">
                  <div className="col-md-5 border-right"> <div className="contact-stat"><span>Have you been excluded or banned from any country ? : </span> <strong>{details?.application_details[0]?.EVER_BANNED ?? "Not Set"}</strong></div> </div>
                  <div className="col-md-7 border-right"> <div className="contact-stat"><span>Reason ? : </span> <strong>{details?.application_details[0]?.REASON_FOR_BAN ?? "Not Set"}</strong></div> </div>
                </div>
                  <hr />

                </div>
                </div>
                </div>

                <div id="character" className="tab-pane">
                <div className="panel-body" style={{marginLeft: "13%", width: "87%"}}>
                
                <div className="container" style={{height:"500px", overflowY:"scroll"}}>

                <div className="row">
                  <div className="col-md-5 border-right"> <div className="contact-stat"><span>Have you ever been charged with a criminal offence ? : </span> <strong>{details?.character_details[0]?.CRIMINAL_OFFENSE ?? "Not Set"}</strong></div> </div>
                  <div className="col-md-7 border-right"> <div className="contact-stat"><span>Reason ? : </span> <strong>{details?.character_details[0]?.CRIMINAL_OFFENCE_ANSWER ?? "Not Set"}</strong></div> </div>
                </div>
                  <hr />

                  <div className="row">
                  <div className="col-md-5 border-right"> <div className="contact-stat"><span>Do you have any pending prosecutions ? : </span> <strong>{details?.character_details[0]?.PENDING_PERSECUTION ?? "Not Set"}</strong></div> </div>
                  <div className="col-md-7 border-right"> <div className="contact-stat"><span>Reason ? : </span> <strong>{details?.character_details[0]?.DETAILS_OF_PROSECUTIONS ?? "Not Set"}</strong></div> </div>
                </div>
                  <hr />

                  <div className="row">
                  <div className="col-md-5 border-right"> <div className="contact-stat"><span>Have you ever had any terrorist views or charged with or been questioned in relation to terrorist charges ? : </span> <strong>{details?.character_details[0]?.TERRORIST_VIEW ?? "Not Set"}</strong></div> </div>
                  <div className="col-md-7 border-right"> <div className="contact-stat"><span>Reason ? : </span> <strong>{details?.character_details[0]?.DETAILS_OF_TERRORIST_CHARGES ?? "Not Set"}</strong></div> </div>
                </div>
                  <hr />

                  <div className="row">
                  <div className="col-md-5 border-right"> <div className="contact-stat"><span>Have you ever worked for the Judiciary/Security Services/Media/Intelligence Agencies/Armed Forces ? : </span> <strong>{details?.character_details[0]?.WORKED_FOR_SECURITY ?? "Not Set"}</strong></div> </div>
                  <div className="col-md-7 border-right"> <div className="contact-stat"><span>Reason ? : </span> <strong>{details?.character_details[0]?.DETAILS_OF_WORK ?? "Not Set"}</strong></div> </div>
                </div>
                  <hr />

                </div>
                </div>
                </div>

                <div id="documents" className="tab-pane">
                <div className="panel-body" style={{marginLeft: "13%", width: "87%"}}>
                
                <div className="container" style={{height:"500px", overflowY:"scroll"}}>

                <div className="row">

                { details?.application_details[0]?.PASSPORT_UPLOAD && 
                  <div className="col-md-3">
                    <label>Passport Upload</label>
                      <div className="hpanel">
                          <div className="panel-body file-body">
                              <i className={details?.application_details[0]?.PASSPORT_UPLOAD ? checkFileType(details?.application_details[0]?.PASSPORT_UPLOAD) : 'fa fa-file-o text-info'}></i>
                          </div>
                          <div className="panel-footer">
                            {details?.application_details[0]?.PASSPORT_UPLOAD ?
                              (<a href={void(0)} onClick={() => HandleFileDownload(details?.application_details[0]?.PASSPORT_UPLOAD, 'PASSPORT_UPLOAD')}>Download File</a>)
                              :(<a href={void(0)}>File not uploaded yet</a>)
                            }
                          </div>
                      </div>
                      </div>
                  }

 
                  { details?.application_details[0]?.DEPENDENT_PASSPORT_UPLOAD &&
                    <div className="col-md-3">
                   <label>Dependent Passport Upload</label>
                    <div className="hpanel">
                    <div className="panel-body file-body">
                            <i className={details?.application_details[0]?.DEPENDENT_PASSPORT_UPLOAD ? checkFileType(details?.application_details[0]?.DEPENDENT_PASSPORT_UPLOAD) : 'fa fa-file-o text-info'}></i>
                        </div>
                        <div className="panel-footer">
                          {details?.application_details[0]?.DEPENDENT_PASSPORT_UPLOAD  ?
                            (<a href={void(0)} onClick={() => HandleFileDownload(details?.application_details[0]?.DEPENDENT_PASSPORT_UPLOAD, 'DEPENDENT_PASSPORT_UPLOAD')}>Download File</a>)
                            :(<a href={void(0)}>File not uploaded yet</a>)
                          }
                        </div>
                    </div>
                    </div>
                  }

                  { details?.application_details[0]?.UTILITY_BILL_UPLOAD &&
                    <div className="col-md-3">
                      <label>Utility Bill Upload</label>
                    <div className="hpanel">
                    <div className="panel-body file-body">
                            <i className={details?.application_details[0]?.UTILITY_BILL_UPLOAD ? checkFileType(details?.application_details[0]?.UTILITY_BILL_UPLOAD) : 'fa fa-file-o text-info'}></i>
                        </div>
                        <div className="panel-footer">
                          {details?.application_details[0]?.UTILITY_BILL_UPLOAD ?
                            (<a href={void(0)} onClick={() => HandleFileDownload(details?.application_details[0]?.UTILITY_BILL_UPLOAD, 'UTILITY_BILL_UPLOAD')}>Download File</a>)
                            :(<a href={void(0)}>File not uploaded yet</a>)
                          }
                        </div>
                    </div>
                    </div>
                  }

                  { details?.application_details[0]?.BRP_UPLOAD &&
                    <div className="col-md-3">
                      <label>BRP Upload</label>
                    <div className="hpanel">
                    <div className="panel-body file-body">
                            <i className={details?.application_details[0]?.BRP_UPLOAD ? checkFileType(details?.application_details[0]?.BRP_UPLOAD) : 'fa fa-file-o text-info'}></i>
                        </div>
                        <div className="panel-footer">
                          {details?.application_details[0]?.BRP_UPLOAD ?
                            (<a href={void(0)} onClick={() => HandleFileDownload(details?.application_details[0]?.BRP_UPLOAD, 'BRP_UPLOAD')}>Download File</a>)
                            :(<a href={void(0)}>File not uploaded yet</a>)
                          }
                        </div>
                    </div>
                    </div>
                  }
                 
               {/*  </div>
                  <hr />

                  <div className="row"> */}


               { details?.application_details[0]?.PREVIOUS_VISA_UPLOAD &&

                <div className="col-md-3">
                  <label>Previous VISA Upload</label>
                    <div className="hpanel">
                    <div className="panel-body file-body">
                            <i className={details?.application_details[0]?.PREVIOUS_VISA_UPLOAD ? checkFileType(details?.application_details[0]?.PREVIOUS_VISA_UPLOAD) : 'fa fa-file-o text-info'}></i>
                        </div>
                        <div className="panel-footer">
                          {details?.application_details[0]?.PREVIOUS_VISA_UPLOAD ?
                            (<a href={void(0)} onClick={() => HandleFileDownload(details?.application_details[0]?.PREVIOUS_VISA_UPLOAD, 'PREVIOUS_VISA_UPLOAD')}>Download File</a>)
                            :(<a href={void(0)}>File not uploaded yet</a>)
                          }
                        </div>
                    </div>
                    </div>
              }


              { details?.application_details[0]?.REFUSAL_LETTER_UPLOAD &&
                    <div className="col-md-3">
                   <label>Refusal Letter Upload</label>
                    <div className="hpanel">
                    <div className="panel-body file-body">
                            <i className={details?.application_details[0]?.REFUSAL_LETTER_UPLOAD ? checkFileType(details?.application_details[0]?.REFUSAL_LETTER_UPLOAD) : 'fa fa-file-o text-info'}></i>
                        </div>
                        <div className="panel-footer">
                          {details?.application_details[0]?.REFUSAL_LETTER_UPLOAD ?
                            (<a href={void(0)} onClick={() => HandleFileDownload(details?.application_details[0]?.REFUSAL_LETTER_UPLOAD, 'REFUSAL_LETTER_UPLOAD')}>Download File</a>)
                            :(<a href={void(0)}>File not uploaded yet</a>)
                          }
                        </div>
                    </div>
                    </div>
              }


              { details?.application_details[0]?.EDUCATIONAL_CERT_UPLOAD &&
                    <div className="col-md-3">
                      <label>Educational Certificate Upload</label>
                    <div className="hpanel">
                    <div className="panel-body file-body">
                            <i className={details?.application_details[0]?.EDUCATIONAL_CERT_UPLOAD ? checkFileType(details?.application_details[0]?.EDUCATIONAL_CERT_UPLOAD) : 'fa fa-file-o text-info'}></i>
                        </div>
                        <div className="panel-footer">
                          {details?.application_details[0]?.EDUCATIONAL_CERT_UPLOAD ?
                            (<a href={void(0)} onClick={() => HandleFileDownload(details?.application_details[0]?.EDUCATIONAL_CERT_UPLOAD, 'EDUCATIONAL_CERT_UPLOAD')}>Download File</a>)
                            :(<a href={void(0)}>File not uploaded yet</a>)
                          }
                        </div>
                    </div>
                    </div> 
              }

              { details?.application_details[0]?.ENGLISH_CERT_UPLOAD &&
                    <div className="col-md-3">
                      <label>English Certificate Upload</label>
                    <div className="hpanel">
                    <div className="panel-body file-body">
                            <i className={details?.application_details[0]?.ENGLISH_CERT_UPLOAD ? checkFileType(details?.application_details[0]?.ENGLISH_CERT_UPLOAD) : 'fa fa-file-o text-info'}></i>
                        </div>
                        <div className="panel-footer">
                          {details?.application_details[0]?.ENGLISH_CERT_UPLOAD ?
                            (<a href={void(0)} onClick={() => HandleFileDownload(details?.application_details[0]?.ENGLISH_CERT_UPLOAD, 'ENGLISH_CERT_UPLOAD')}>Download File</a>)
                            :(<a href={void(0)}>File not uploaded yet</a>)
                          }
                        </div>
                    </div>
                    </div>
              }

               {/*   
                </div>
                  <hr />

               <div className="row"> */}


           { details?.application_details[0]?.MARRIAGE_CERT_UPLOAD &&

                  <div className="col-md-3">
                      <label>Marriage Certificate Upload</label>
                    <div className="hpanel">
                    <div className="panel-body file-body">
                            <i className={details?.application_details[0]?.MARRIAGE_CERT_UPLOAD ? checkFileType(details?.application_details[0]?.MARRIAGE_CERT_UPLOAD) : 'fa fa-file-o text-info'}></i>
                        </div>
                        <div className="panel-footer">
                          {details?.application_details[0]?.MARRIAGE_CERT_UPLOAD ?
                            (<a href={void(0)} onClick={() => HandleFileDownload(details?.application_details[0]?.MARRIAGE_CERT_UPLOAD, 'MARRIAGE_CERT_UPLOAD')}>Download File</a>)
                            :(<a href={void(0)}>File not uploaded yet</a>)
                          }
                        </div>
                    </div>
                    </div>

            }

            { details?.application_details[0]?.BANK_STATEMENT_UPLOAD &&
                    <div className="col-md-3">
                      <label>Bank Statement Upload</label>
                    <div className="hpanel">
                    <div className="panel-body file-body">
                            <i className={details?.application_details[0]?.BANK_STATEMENT_UPLOAD ? checkFileType(details?.application_details[0]?.BANK_STATEMENT_UPLOAD) : 'fa fa-file-o text-info'}></i>
                        </div>
                        <div className="panel-footer">
                          {details?.application_details[0]?.BANK_STATEMENT_UPLOAD ?
                            (<a href={void(0)} onClick={() => HandleFileDownload(details?.application_details[0]?.BANK_STATEMENT_UPLOAD, 'BANK_STATEMENT_UPLOAD')}>Download File</a>)
                            :(<a href={void(0)}>File not uploaded yet</a>)
                          }
                        </div>
                    </div>
                    </div>
              } 

            { details?.application_details[0]?.MOTIVATIONAL_LETTER_UPLOAD &&
                    <div className="col-md-3">
                      <label>Motivational Letter Upload</label>
                    <div className="hpanel">
                    <div className="panel-body file-body">
                            <i className={details?.application_details[0]?.MOTIVATIONAL_LETTER_UPLOAD ? checkFileType(details?.application_details[0]?.MOTIVATIONAL_LETTER_UPLOAD) : 'fa fa-file-o text-info'}></i>
                        </div>
                        <div className="panel-footer">
                          {details?.application_details[0]?.MOTIVATIONAL_LETTER_UPLOAD ?
                            (<a href={void(0)} onClick={() => HandleFileDownload(details?.application_details[0]?.MOTIVATIONAL_LETTER_UPLOAD, 'MOTIVATIONAL_LETTER_UPLOAD')}>Download File</a>)
                            :(<a href={void(0)}>File not uploaded yet</a>)
                          }
                        </div>
                    </div>
                    </div>
              } 

             { details?.application_details[0]?.RESUME_UPLOAD &&
                    <div className="col-md-3">
                      <label>Resume/Curriculum Vitae (CV) Upload</label>
                    <div className="hpanel">
                    <div className="panel-body file-body">
                            <i className={details?.application_details[0]?.RESUME_UPLOAD ? checkFileType(details?.application_details[0]?.RESUME_UPLOAD) : 'fa fa-file-o text-info'}></i>
                        </div>
                        <div className="panel-footer">
                          {details?.application_details[0]?.RESUME_UPLOAD ?
                            (<a href={void(0)} onClick={() => HandleFileDownload(details?.application_details[0]?.RESUME_UPLOAD, 'RESUME_UPLOAD')}>Download File</a>)
                            :(<a href={void(0)}>File not uploaded yet</a>)
                          }
                        </div>
                    </div>
                    </div>
              } 

              { details?.application_details[0]?.DEGREE_CERTIFICATE_UPLOAD &&
                    <div className="col-md-3">
                      <label>Degree Certificate Upload</label>
                    <div className="hpanel">
                    <div className="panel-body file-body">
                            <i className={details?.application_details[0]?.DEGREE_CERTIFICATE_UPLOAD ? checkFileType(details?.application_details[0]?.DEGREE_CERTIFICATE_UPLOAD) : 'fa fa-file-o text-info'}></i>
                        </div>
                        <div className="panel-footer">
                          {details?.application_details[0]?.DEGREE_CERTIFICATE_UPLOAD ?
                            (<a href={void(0)} onClick={() => HandleFileDownload(details?.application_details[0]?.DEGREE_CERTIFICATE_UPLOAD, 'DEGREE_CERTIFICATE_UPLOAD')}>Download File</a>)
                            :(<a href={void(0)}>File not uploaded yet</a>)
                          }
                        </div>
                    </div>
                    </div>
              } 

              { details?.application_details[0]?.ACADEMIC_TRANSCRIPTS_UPLOAD &&
                    <div className="col-md-3">
                      <label>Academic Transcript Upload</label>
                    <div className="hpanel">
                    <div className="panel-body file-body">
                            <i className={details?.application_details[0]?.ACADEMIC_TRANSCRIPTS_UPLOAD ? checkFileType(details?.application_details[0]?.ACADEMIC_TRANSCRIPTS_UPLOAD) : 'fa fa-file-o text-info'}></i>
                        </div>
                        <div className="panel-footer">
                          {details?.application_details[0]?.ACADEMIC_TRANSCRIPTS_UPLOAD ?
                            (<a href={void(0)} onClick={() => HandleFileDownload(details?.application_details[0]?.ACADEMIC_TRANSCRIPTS_UPLOAD, 'ACADEMIC_TRANSCRIPTS_UPLOAD')}>Download File</a>)
                            :(<a href={void(0)}>File not uploaded yet</a>)
                          }
                        </div>
                    </div>
                    </div>
              } 

              { details?.application_details[0]?.CAS_LETTERS_UPLOAD &&
                    <div className="col-md-3">
                      <label>Previous CAS letters Upload</label>
                    <div className="hpanel">
                    <div className="panel-body file-body">
                            <i className={details?.application_details[0]?.CAS_LETTERS_UPLOAD ? checkFileType(details?.application_details[0]?.CAS_LETTERS_UPLOAD) : 'fa fa-file-o text-info'}></i>
                        </div>
                        <div className="panel-footer">
                          {details?.application_details[0]?.CAS_LETTERS_UPLOAD ?
                            (<a href={void(0)} onClick={() => HandleFileDownload(details?.application_details[0]?.CAS_LETTERS_UPLOAD, 'CAS_LETTERS_UPLOAD')}>Download File</a>)
                            :(<a href={void(0)}>File not uploaded yet</a>)
                          }
                        </div>
                    </div>
                    </div>
              } 


              { details?.application_details[0]?.RECOMMENDATION_LETTERS_UPLOAD &&
                    <div className="col-md-3">
                      <label>Recommendation Letter Upload</label>
                    <div className="hpanel">
                    <div className="panel-body file-body">
                            <i className={details?.application_details[0]?.RECOMMENDATION_LETTERS_UPLOAD ? checkFileType(details?.application_details[0]?.RECOMMENDATION_LETTERS_UPLOAD) : 'fa fa-file-o text-info'}></i>
                        </div>
                        <div className="panel-footer">
                          {details?.application_details[0]?.RECOMMENDATION_LETTERS_UPLOAD ?
                            (<a href={void(0)} onClick={() => HandleFileDownload(details?.application_details[0]?.RECOMMENDATION_LETTERS_UPLOAD, 'RECOMMENDATION_LETTERS_UPLOAD')}>Download File</a>)
                            :(<a href={void(0)}>File not uploaded yet</a>)
                          }
                        </div>
                    </div>
                    </div>
              } 

             { details?.application_details[0]?.RESEARCH_PROPOSAL_UPLOAD &&
                    <div className="col-md-3">
                      <label>Research Proposal Upload</label>
                    <div className="hpanel">
                    <div className="panel-body file-body">
                            <i className={details?.application_details[0]?.RESEARCH_PROPOSAL_UPLOAD ? checkFileType(details?.application_details[0]?.RESEARCH_PROPOSAL_UPLOAD) : 'fa fa-file-o text-info'}></i>
                        </div>
                        <div className="panel-footer">
                          {details?.application_details[0]?.RESEARCH_PROPOSAL_UPLOAD ?
                            (<a href={void(0)} onClick={() => HandleFileDownload(details?.application_details[0]?.RESEARCH_PROPOSAL_UPLOAD, 'RESEARCH_PROPOSAL_UPLOAD')}>Download File</a>)
                            :(<a href={void(0)}>File not uploaded yet</a>)
                          }
                        </div>
                    </div>
                    </div>
              } 

{ details?.application_details[0]?.OTHER_UPLOAD &&
                    <div className="col-md-3">
                      <label>Other Upload</label>
                    <div className="hpanel">
                    <div className="panel-body file-body">
                            <i className={details?.application_details[0]?.OTHER_UPLOAD ? checkFileType(details?.application_details[0]?.OTHER_UPLOAD) : 'fa fa-file-o text-info'}></i>
                        </div>
                        <div className="panel-footer">
                          {details?.application_details[0]?.OTHER_UPLOAD ?
                            (<a href={void(0)} onClick={() => HandleFileDownload(details?.application_details[0]?.OTHER_UPLOAD, 'OTHER_UPLOAD')}>Download File</a>)
                            :(<a href={void(0)}>File not uploaded yet</a>)
                          }
                        </div>
                    </div>
                    </div>
              } 

               {/*  </div>
                  <hr />
                        */}
                </div> 
                </div>
                </div>
                </div>

                <div id="tracking" className="tab-pane">
                <div className="panel-body" style={{marginLeft: "13%", width: "87%"}}>
                
                <div className="container" style={{height:"500px", overflowY:"scroll"}}>
                
                {details?.tracking_details ? details?.tracking_details.length > 0 ?
                 
                 details?.tracking_details.map((track,i) => 
                 (
                  <Fragment key={i}>

                <div className="row">
                  <div className="col-md-4 border-right"> <div className="contact-stat"><span>APP No. : </span> <strong>{track?.APPLICATION_ID ?? "Not Set"}</strong></div> </div>
                  <div className="col-md-4 border-right"> <div className="contact-stat"><span>Courier : </span> <strong>{track?.TRACK_CODE ?? "Not Set"}</strong></div> </div>
                  <div className="col-md-4 border-right"> <div className="contact-stat"><span>Track ID : </span> <strong>{track?.TRACKING_ID ?? "Not Set"}</strong></div> </div>
                </div>
                  <hr />

                <div className="row">
                  <div className="col-md-4 border-right"> <div className="contact-stat"><span> Track Date: </span> <strong>{track?.TRACKING_DATE ?? "Not Set"}</strong></div> </div>
                  <div className="col-md-4 border-right"> <div className="contact-stat"><span>Direction: </span> <strong>{track?.DIRECTION ?? "Not Set"}</strong></div> </div>
                  <div className="col-md-4"> <div className="contact-stat"><span>Creation Date : </span> <strong>{track?.created_at ?? "Not Set"}</strong></div> </div>
                </div>
                <hr />

                <div className="row">
                <div className="col-md-9"> <div className="contact-stat"><span>Note : </span> <strong>{track?.TRACKING_NOTE ?? "Not Set"}</strong></div> </div>
                <div className="col-md-1 text-right">
                    <p className="text-right">
                      <a href={`${track?.TRACK_URL?.replace("[TRACKING_ID]", track?.TRACKING_ID)}`} target="_blank" className="btn w-xs btn-default" style={{backgroundColor: "#dfe7e9", borderColor: "#5c9731", color: "#54585d"}}><i className="fa fa-location-arrow"></i> Track Item</a>
                    </p>
                </div>
              </div>

              { ((i+1) < details?.tracking_details.length) &&
                <hr className="slash-2" style={{marginTop:"18px"}}/>
              }

                </Fragment>

                ))

                :("")

                :("")
                }

                </div>
                </div>
                </div>

               {/*  <div id="invoice" className="tab-pane">
                <div className="panel-body" style={{marginLeft: "13%", width: "87%"}}>
                
                <div className="container" style={{height:"500px", overflowY:"scroll"}}>
                </div>
                </div>
                </div> */}

            </div>

            </div>

        </div>
    
                </>
                
            </div>
          </div>
        </div>
      </div>

      
       
      

    </Fragment>
  );
};
export default ViewApplication; ///withRouter(Master);
